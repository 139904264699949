import React from 'react'

import ReviewPageTemplate from '../../templates/ReviewPageTemplate'
import {
  ContainerBlock,
  Container
} from '../../templates/ReviewPageTemplate/ReviewContent'
import { getFormatEnum } from '../../types/format'
import Breadcrumb from '../../components/BreadCrumb'
import { StrategyEnum } from '../../types/strategy'
import LabelledItemsList from '../../components/LabelledItemsList'
import RejectModal from './AdvertiserModals/RejectModal'
import ValidateModal from './AdvertiserModals/ValidateModal'
import { Status } from '../../types/status'
import FontWeight from '../../constants/fontWeight'
import CO2Modal from '../../components/Modal/CO2Modal'
import { getAudienceByMediaPlanIdAndName } from '../../helpers/queries/audiences/getAudienceByName'
import ModalAudienceDetails from '../../components/ModalAudienceDetails'
import { linkedinDevices } from '../../constants/linkedin/linkedinDevices'
import { PlatformAPIEnum } from '../../types/platform'
import { floorToTwoDecimals } from '../../helpers/rounds'
import { AdvertiserMediaPlanReviewProps } from './types/advertiser'
import handleExportDownload from '../../helpers/handleExportDownload'

const AdvertiserMediaPlanReview = ({
  reviewData,
  availableBudget,
  mediaPlanId,
  startDateMediaPlan,
  endDateMediaPlan,
  navigate,
  dataAudienceModal,
  setDataAudienceModal,
  campaignIdCO2ModalOpen,
  setCampaignIdCO2ModalOpen,
  isValidateModalOpen,
  setIsValidateModalOpen,
  isRejectModalOpen,
  setIsRejectModalOpen,
  reasonForRejection,
  handleChangeReasonForRejection,
  validateValidationMessage,
  handleSubmitValidate,
  isSubmitValidateLoading,
  validateError,
  rejectValidationMessage,
  handleSubmitReject,
  isSubmitRejectLoading,
  hasRejectBeenSubmitted,
  rejectError,
  submitRejectError,
  paymentType,
  handleChangePaymentType,
  handleViewMore
}: AdvertiserMediaPlanReviewProps) => {
  const tipsBudget = (
    <>
      {`Il vous reste `}
      <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>
        {floorToTwoDecimals(availableBudget)} €
      </span>
      {` à allouer sur votre budget total. Vous pouvez ajouter des campagnes ou augmenter le budget de vos campagnes existantes.`}
    </>
  )

  const tipsEcology = (
    <>
      {`Votre plan média va génerer une quantité d'environ `}
      <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>
        {reviewData.mediaPlan.co2}
      </span>
      {` de CO2.`}
    </>
  )

  const tipsMultipleInfos = [
    'Passez en revue le budget, les élements du plan média et de ses campagnes puis validez pour permettre sa diffusion.',
    'Votre agence peut alors apporter des modifications au plan média et vous envoyer une nouvelle demande de validation.'
  ]

  const container: Container = {
    column1: (
      <LabelledItemsList
        items={[
          {
            label: 'Budget total ',
            value: `${reviewData.mediaPlan.budget.toString()}` + ` €`
          },
          {
            label: 'Budget disponible ',
            value: `${availableBudget.toString()}` + ` €`
          },
          {
            label: 'Période ',
            value: `${startDateMediaPlan} au ${endDateMediaPlan}`
          },
          {
            label: 'Objectif(s) ',
            value: reviewData.mediaPlan.goals.toString()
          },
          {
            label: 'Audience(s) ',
            value: reviewData.mediaPlan.audiences.join('\n'),
            onClick: async (audienceName) => {
              const result = await getAudienceByMediaPlanIdAndName({
                name: audienceName,
                mediaPlanId: mediaPlanId
              })
              if (result) {
                setDataAudienceModal(result)
              }
            }
          }
        ]}
      />
    ),
    items: reviewData.campaigns.map((campaign) => ({
      id: campaign.id,
      title: campaign.name,
      platform: campaign.platform,
      isPlatformSynced: campaign.isPlatformSynced,
      syncError: campaign.syncError,
      container: {
        column1: (
          <LabelledItemsList
            items={[
              {
                label: 'Plateforme ',
                value:
                  campaign.platform.charAt(0).toUpperCase() +
                  campaign.platform.slice(1)
              },
              { label: 'Budget ', value: campaign.budget.toString() + ` €` },
              { label: 'Objectif(s) ', value: campaign.goal }
              // ...(campaign.bidStrategy
              //   ? [{ label: 'Enchères', value: campaign.bidStrategy }]
              //   : [])
            ]}
          />
        ),
        column2: (
          <LabelledItemsList
            items={[
              // TODO : calculate co2 for linkedin
              ...(campaign.adsCount > 0
                ? parseFloat(campaign.co2) > 0
                  ? [
                      {
                        id: campaign.id,
                        label: 'CO2 estimé ',
                        value: campaign.co2,
                        onTooltipClick: (campaignId: string) => {
                          setCampaignIdCO2ModalOpen(campaignId)
                        }
                      }
                    ]
                  : [
                      {
                        id: campaign.id,
                        label: 'CO2 estimé ',
                        value: 'A venir'
                      }
                    ]
                : [
                    {
                      id: campaign.id,
                      label: 'CO2 estimé ',
                      value: '-'
                    }
                  ]),
              {
                label: 'Période ',
                value: `${campaign.period.start.toString()} au ${campaign.period.end.toString()}`
              },
              { label: 'Format ', value: getFormatEnum(campaign.format) },
              { label: 'Annonce(s) ', value: campaign.adsCount.toString() }
            ]}
          />
        )
      }
    }))
  }

  const containerBlock: ContainerBlock = {
    title: reviewData.mediaPlan.name,
    container
  }

  const validation:
    | {
        label: string
        action: () => void
        disabled: boolean
        disabledMessage?: string
        type?: 'primary' | 'secondary'
      }
    | undefined =
    reviewData.mediaPlan.status !== Status.PENDING &&
    reviewData.mediaPlan.status !== Status.REJECTED
      ? undefined
      : {
          label: 'Valider le plan média',
          action: () => {
            setIsValidateModalOpen(true)
          },
          disabled:
            reviewData.campaigns.length === 0 ||
            !reviewData.campaigns.every((campaign) => campaign.adsCount > 0) ||
            !reviewData.campaigns.every(
              (campaign) => campaign.isPlatformSynced
            ),
          disabledMessage: `Pour valider votre plan média, veuillez vous assurer que toutes vos
            campagnes ont été synchronisées et ont chacune au moins une annonce.`,
          type: reviewData.campaigns.length === 0 ? 'secondary' : 'primary'
        }

  const cancel =
    reviewData.mediaPlan.status === Status.PENDING
      ? {
          label: 'Rejeter le plan média',
          action: () => {
            setIsRejectModalOpen(true)
          },
          disabled: false
        }
      : undefined

  const download = {
    label: 'Télécharger le plan média en PDF',
    action: () => {
      handleExportDownload(mediaPlanId)
    },
    disabled: false
  }

  const items = [
    {
      label: 'Plan média'
    },
    {
      label: reviewData.navigation.mediaPlan.name
    }
  ]

  const currentCampaign = reviewData.campaigns.find(
    (campaign) => campaign.id === campaignIdCO2ModalOpen
  )

  const devices =
    currentCampaign?.platform === PlatformAPIEnum.GOOGLE
      ? currentCampaign?.devices
      : linkedinDevices

  return (
    <>
      <Breadcrumb items={items} />
      <ReviewPageTemplate
        name={reviewData.mediaPlan.name}
        title={'Revue du plan média'}
        tipsBudget={tipsBudget}
        tipsMultipleInfos={tipsMultipleInfos}
        type={StrategyEnum.MEDIA_PLAN}
        validation={validation}
        cancel={cancel}
        download={download}
        container={containerBlock}
        handleViewMore={handleViewMore}
        isEditor={false}
        co2={reviewData.mediaPlan.co2}
        co2Header={true}
      />
      {dataAudienceModal && (
        <ModalAudienceDetails
          title={"Détails de l'audience"}
          data={dataAudienceModal}
          handleClose={() => {
            setDataAudienceModal(null)
          }}
        />
      )}
      {isRejectModalOpen && (
        <RejectModal
          onClose={() => {
            if (rejectValidationMessage !== '') {
              navigate('/')
            } else {
              setIsRejectModalOpen(false)
            }
          }}
          handleChangeReasonForRejection={handleChangeReasonForRejection}
          reasonForRejection={reasonForRejection}
          error={
            hasRejectBeenSubmitted && !rejectValidationMessage
              ? rejectError
              : submitRejectError
          }
          validationMessage={rejectValidationMessage}
          handleSubmit={handleSubmitReject}
          isLoading={isSubmitRejectLoading}
          mediaPlan={reviewData.mediaPlan.name}
          editor={reviewData.mediaPlan.editor.companyName}
        ></RejectModal>
      )}
      {isValidateModalOpen && (
        <ValidateModal
          onClose={() => {
            if (validateValidationMessage !== '') {
              navigate('/')
            } else {
              setIsValidateModalOpen(false)
            }
          }}
          handleChangePaymentType={handleChangePaymentType}
          paymentType={paymentType}
          error={
            !isSubmitValidateLoading && !validateValidationMessage
              ? validateError
              : ''
          }
          validationMessage={validateValidationMessage}
          handleSubmit={handleSubmitValidate}
          isLoading={isSubmitValidateLoading}
          name={reviewData.mediaPlan.name}
          budget={reviewData.mediaPlan.budget}
        ></ValidateModal>
      )}
      {campaignIdCO2ModalOpen && currentCampaign && (
        <CO2Modal
          onClose={() => {
            setCampaignIdCO2ModalOpen('')
          }}
          campaignId={campaignIdCO2ModalOpen}
          format={currentCampaign.format}
          campaignDevices={devices}
        />
      )}
    </>
  )
}

export default AdvertiserMediaPlanReview
