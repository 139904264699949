import React from 'react'

import Colors from '../../../../../constants/colors'
import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import { ArrowRight } from '../../../../Icon'
import {
  AdButton,
  AdCompany,
  AdContent,
  AdDescription,
  AdImage,
  AdLink,
  AdTitle,
  marginLeftStyle
} from './styles'
import DisplayAdWebpage from '../../../../../templates/DisplayAdTemplate/DisplayAdWebPage'

type WebpagePreview1Props = {
  title: string
  description: string
  businessName: string
  adImageRectangle: string
  buttonColor: string
}

const WebpagePreview1 = ({
  title,
  description,
  businessName,
  adImageRectangle,
  buttonColor
}: WebpagePreview1Props) => {
  return (
    <DisplayAdWebpage>
      <DisplayAdInfoTemplate
        width={'300px'}
        height={'255px'}
        bgColor={Colors.YETIC_WHITE}
        style={{ borderColor: '#E9E9E9' }}
      >
        <AdImage src={adImageRectangle} alt="image paysage" loading="lazy" />
        <AdContent>
          <AdTitle>{title}</AdTitle>
          <AdDescription>{description}</AdDescription>
          <AdLink>
            <AdCompany>{businessName}</AdCompany>
            <AdButton primaryColor={buttonColor}>
              Ouvrir
              <ArrowRight
                color={buttonColor}
                width="18px"
                height="18px"
                style={marginLeftStyle}
              />
            </AdButton>
          </AdLink>
        </AdContent>
      </DisplayAdInfoTemplate>
    </DisplayAdWebpage>
  )
}

export default WebpagePreview1
