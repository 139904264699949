import { LocationData } from '../../../../types/mediaPlanAudience'

export const checkName = (name: string) => {
  return name.length > 1
}

export const checkMinAge = (minAge: number) => {
  return minAge > 17 && minAge <= 100
}

export const checkMaxAge = (maxAge: number) => {
  return maxAge > 17 && maxAge <= 100
}

export const checkValidAges = ({
  minAge,
  maxAge
}: {
  minAge: number
  maxAge: number
}) => {
  return minAge <= maxAge
}

export const checkLocations = (selectedLocations: LocationData[]) => {
  return selectedLocations.length > 0
}

// export const checkInterests = (selectedInterests: string[]) => {
//   return selectedInterests.length > 0
// }

export const checkBusinessSegments = (selectedBusinessSegments: string[]) => {
  return selectedBusinessSegments.length > 0
}

export const checkJobFunctions = (
  selectedJobFunctions: string[],
  selectedJobTitles: string[]
) => {
  return (
    selectedJobFunctions.length === 0 ||
    (selectedJobFunctions.length > 0 && selectedJobTitles.length === 0) ||
    (selectedJobFunctions.length === 0 && selectedJobTitles.length > 0)
  )
}

export const checkJobTitles = (
  selectedJobTitles: string[],
  selectedJobFunctions: string[]
) => {
  return (
    selectedJobTitles.length === 0 ||
    (selectedJobTitles.length > 0 && selectedJobFunctions.length === 0) ||
    (selectedJobTitles.length === 0 && selectedJobFunctions.length > 0)
  )
}
