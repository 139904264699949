import React from 'react'

import { TableListFilter } from '.'
import Dropdown from '../Dropdown'
import { DivDropdowns } from './styles'

type TableListDropdownsProps = {
  filters: TableListFilter[] | undefined
}

const TableListDropdowns = (props: TableListDropdownsProps) => {
  const { filters } = props
  if (filters && filters.length > 0) {
    return (
      <DivDropdowns>
        {filters.map((filter, index) => {
          return (
            <Dropdown
              key={index}
              isDropdownFilter={filter.seeAll}
              width="200px"
              onChange={filter.handleFilterChange}
              options={filter.options.map((option, index) => ({
                id: index,
                label: option
              }))}
              value={filter.filterState ?? ''}
              label={filter.title}
            />
          )
        })}
      </DivDropdowns>
    )
  } else {
    return null
  }
}

export default TableListDropdowns
