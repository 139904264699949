import React, { useState } from 'react'

import { ClickImpressionCostTabContainer } from './styles'
import { formatClickImpressionCostData } from './helpers/formatData'
import Table, { ColumnItem } from '../../../../components/Table'
import { TableContainer, chartGap } from '../../styles'
import { AlignEnum, SortEnum } from '../../../../types/common'
import { GetCampaignOverallReportingOutputType } from '../../../../types/reporting/campaign/output'
import { formatAnalyticsHeaderData } from '../../Chart/helpers/formatHeaderData'
import LineChart from '../../../../components/Chart/LineChart'
import DefaultLineChartTooltip from '../../../../components/Chart/LineChart/Tooltips/Default'
import { renderDefaultTooltip } from '../../../../components/Chart/LineChart/Tooltips/Default/renderer'

type ClickImpressionCostTabProps = {
  reportingData: GetCampaignOverallReportingOutputType
  contentWidth: number
}

const ClickImpressionCostTab = ({
  reportingData,
  contentWidth
}: ClickImpressionCostTabProps) => {
  const { kpi, tableData, globalCTR } =
    formatClickImpressionCostData(reportingData)
  const initialSelectionNames = ['Clics', 'Impressions']
  const [tablePage, setTablePage] = useState(1)

  const tableColumns: ColumnItem[] = [
    {
      name: 'day',
      label: 'Jour',
      isSortable: true,
      width: '28%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'ctr',
      label: 'CTR',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'cost',
      label: 'Coût',
      unit: '€',
      isSortable: true,
      width: '18%'
    }
  ]

  const defaultTableSort = {
    name: 'day',
    order: SortEnum.DESC
  }

  const chartWidth =
    screen.width > 1300 ? (contentWidth - chartGap * 2) / 2 : contentWidth

  const chartId = `id${Math.random().toString(36).substring(2)}`

  const TooltipComponent = DefaultLineChartTooltip
  const renderTooltip = renderDefaultTooltip

  return (
    <ClickImpressionCostTabContainer>
      <LineChart
        data={kpi}
        initialSelectionNames={initialSelectionNames}
        width={chartWidth}
        formatHeaderData={(data, initialSelectionNames) =>
          formatAnalyticsHeaderData(data, initialSelectionNames, globalCTR)
        }
        rangeSelectorData={null}
        tooltipComponent={TooltipComponent}
        renderTooltip={renderTooltip}
        chartId={chartId}
      />
      <TableContainer>
        <Table
          title="Performances des annonces par jour"
          columns={tableColumns}
          elements={tableData}
          sort={defaultTableSort}
          pagination={{
            totalItems: tableData.length,
            itemsByPage: 5,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
        />
      </TableContainer>
    </ClickImpressionCostTabContainer>
  )
}

export default ClickImpressionCostTab
