import { backendURL } from '../../constants/urls'
import { Status } from '../../types/status'
import { checkIfDate } from '../nativeTypes'
import { axiosGet, checkIsAxiosGetOutputSuccess } from './axios/get'

export type EditorHomeDataType = {
  mediaPlans?: {
    id: string
    name: string
    customerName: string
    updatedAt: Date
  }[]
  campaigns: {
    id: string
    name: string
    mediaPlanId: string
    status: Status
    customerName: string
    updatedAt: Date
    inProgressType?: string
    feedback?: string
  }[]
  co2: number
  customerCount: number
  adCount: number
  campaignCount: number
  mediaPlanCount: number
  budget: {
    spent: number
    estimated: number
  }
}

export type AdvertiserHomeDataType = {
  mediaPlans: {
    id: string
    name: string
    editorName: string
    updatedAt: Date
    status: Status
  }[]
  campaigns?: {
    id: string
    name: string
    mediaPlanId: string
    status: Status
    editorName: string
    updatedAt: Date
    inProgressType?: string
  }[]
  co2: number
  adCount: number
  campaignCount: number
  mediaPlanCount: number
  budget: {
    spent: number
    estimated: number
  }
}

export type SelfEditorHomeDataType = {
  mediaPlans: {
    id: string
    name: string
    budget: number
    updatedAt: Date
    status: Status
  }[]
  campaigns?: {
    id: string
    name: string
    mediaPlanId: string
    status: Status
    budget: number
    updatedAt: Date
    inProgressType?: string
  }[]
  co2: number
  adCount: number
  campaignCount: number
  mediaPlanCount: number
  budget: {
    spent: number
    estimated: number
  }
}

export type GetHomeDataOutputType =
  | EditorHomeDataType
  | AdvertiserHomeDataType
  | null

export const getHomeDataFromDateRange = async (
  daterange: string
): Promise<GetHomeDataOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/home/data?daterange=${daterange}`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfEditorHomeData = (
  value: any
): value is EditorHomeDataType => {
  return (
    value &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.customerCount !== undefined &&
    typeof value.customerCount === 'number' &&
    value.adCount !== undefined &&
    typeof value.adCount === 'number' &&
    value.campaignCount !== undefined &&
    typeof value.campaignCount === 'number' &&
    value.mediaPlanCount !== undefined &&
    typeof value.mediaPlanCount === 'number' &&
    value.budget &&
    value.budget.spent !== undefined &&
    typeof value.budget.spent === 'number' &&
    value.budget.estimated !== undefined &&
    typeof value.budget.estimated === 'number' &&
    Array.isArray(value.campaigns) &&
    value.campaigns.every(
      (campaign: any) =>
        campaign &&
        campaign.id &&
        typeof campaign.id === 'string' &&
        campaign.name &&
        typeof campaign.name === 'string' &&
        campaign.customerName &&
        typeof campaign.customerName === 'string' &&
        checkIfDate(new Date(campaign.updatedAt))
    )
  )
}

export const checkIfAdvertiserHomeData = (
  value: any
): value is AdvertiserHomeDataType => {
  return (
    value &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.adCount !== undefined &&
    typeof value.adCount === 'number' &&
    value.campaignCount !== undefined &&
    typeof value.campaignCount === 'number' &&
    value.mediaPlanCount !== undefined &&
    typeof value.mediaPlanCount === 'number' &&
    value.budget &&
    value.budget.spent !== undefined &&
    typeof value.budget.spent === 'number' &&
    value.budget.estimated !== undefined &&
    typeof value.budget.estimated === 'number' &&
    Array.isArray(value.mediaPlans) &&
    value.mediaPlans.every(
      (mediaPlan: any) =>
        mediaPlan &&
        mediaPlan.id &&
        typeof mediaPlan.id === 'string' &&
        mediaPlan.name &&
        typeof mediaPlan.name === 'string' &&
        mediaPlan.editorName &&
        typeof mediaPlan.editorName === 'string' &&
        checkIfDate(new Date(mediaPlan.updatedAt))
    )
  )
}

export const checkIfSelfEditorHomeData = (
  value: any
): value is SelfEditorHomeDataType => {
  return (
    value &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.adCount !== undefined &&
    typeof value.adCount === 'number' &&
    value.campaignCount !== undefined &&
    typeof value.campaignCount === 'number' &&
    value.mediaPlanCount !== undefined &&
    typeof value.mediaPlanCount === 'number' &&
    value.budget &&
    value.budget.spent !== undefined &&
    typeof value.budget.spent === 'number' &&
    value.budget.estimated !== undefined &&
    typeof value.budget.estimated === 'number' &&
    Array.isArray(value.mediaPlans) &&
    value.mediaPlans.every(
      (mediaPlan: any) =>
        mediaPlan &&
        mediaPlan.id &&
        typeof mediaPlan.id === 'string' &&
        mediaPlan.name &&
        typeof mediaPlan.name === 'string' &&
        mediaPlan.budget !== undefined &&
        typeof mediaPlan.budget === 'number' &&
        checkIfDate(new Date(mediaPlan.updatedAt))
    )
  )
}
