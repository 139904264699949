import React from 'react'

import { extractDomainFromUrl } from '../../../../helpers/extractDomainFromUrl'
import {
  AdContainer,
  Sponsorised,
  DivHead,
  DivImg,
  DivText,
  Name,
  Url,
  Title,
  Description
} from './styles'

type PropsGoogle = {
  url: string
  title1: string
  title2?: string
  title3?: string
  description: string
  description2?: string
  description3?: string
  style?: React.CSSProperties
  scale?: number
}

const GooglePreview = ({
  url,
  title1,
  title2,
  title3,
  description,
  description2,
  description3,
  style,
  scale = 1
}: PropsGoogle) => {
  const name = extractDomainFromUrl(url)
  const urlPreview = url.split('?')[0]
  const sentenceRegex = /[\w\s]+[.!?]/g
  return (
    <AdContainer style={style} scale={scale}>
      <Sponsorised>Sponsorisé</Sponsorised>
      <DivHead>
        <DivImg>
          <img
            src="/images/googleLogo.png"
            alt=""
            width={'18px'}
            height={'18px'}
          />
        </DivImg>
        <DivText>
          <Name>{name}</Name>
          <Url href="https://www.example.com">{urlPreview}</Url>
        </DivText>
      </DivHead>
      <Title>
        {title1}
        {title2 ? ` | ${title2}` : ''}
        {title3 ? ` | ${title3}` : ''}
      </Title>
      <Description>
        {description
          ? `${description}${sentenceRegex.test(description) ? '' : '.'} `
          : ''}
        {description2
          ? `${description2}${sentenceRegex.test(description2) ? '' : '.'} `
          : ''}
        {description3
          ? `${description3}${sentenceRegex.test(description3) ? '' : '.'}`
          : ''}
      </Description>
    </AdContainer>
  )
}

export default GooglePreview
