import React, { useContext, useEffect, useState, MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CampaignType } from '../../types/campaign/campaign'
import { Status, getIsEditable } from '../../types/status'
import LoadingPage from '../LoadingPage'
import { getCampaignById } from '../../helpers/queries/campaign/getCampaignById'
import { AdNavigation } from '../../types/ad/ad'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import { MediaPlanAudience } from '../../types/mediaPlanAudience'
import { checkIfGoogleCampaign } from '../../types/campaign/google/googleCampaign'
import { checkIfLinkedinCampaign } from '../../types/campaign/linkedin/linkedinCampaign'
import EditorCampaignDetails from './editor'
import { ModalKeywordsType } from './types'
import SelfEditorCampaignDetails from './selfEditor'

const CampaignDetails = () => {
  const [campaign, setCampaign] = useState<CampaignType | null>(null)
  const [navigation, setNavigation] = useState<AdNavigation | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [dataAudienceModal, setDataAudienceModal] =
    useState<MediaPlanAudience | null>(null)
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false)
  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [mediaPlanName, setMediaPlanName] = useState('')
  const [dataKeywordsModal, setDataKeywordsModal] = useState<
    ModalKeywordsType | undefined
  >(undefined)
  const [skip, setSkip] = useState(0)

  const navigate = useNavigate()

  const { campaignId } = useParams()

  const { isEditor, isSelfEditor } = useContext(AppContext)

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignById({
          campaignId: campaignId,
          skip
        })
        if (result != null) {
          const campaignResult = result.campaign
          let newCampaign: CampaignType | null = null

          if (checkIfGoogleCampaign(campaignResult)) {
            newCampaign = {
              id: campaignResult.id,
              name: campaignResult.name,
              platform: campaignResult.platform,
              format: campaignResult.format,
              status: campaignResult.status,
              goal: campaignResult.goal,
              period: campaignResult.period,
              budget: campaignResult.budget,
              spentBudget: campaignResult.spentBudget,
              isBudgetPerDay: campaignResult.isBudgetPerDay,
              feedback: campaignResult.feedback,
              audiences: campaignResult.audiences,
              lang: campaignResult.lang,
              os: campaignResult.os,
              devices: campaignResult.devices,
              bidStrategy: campaignResult.bidStrategy,
              mediaPlanId: campaignResult.mediaPlanId,
              userId: campaignResult.userId,
              createdAt: campaignResult.createdAt,
              updatedAt: campaignResult.updatedAt,
              keywordsCount: campaignResult.keywordsCount,
              ads: campaignResult.ads,
              co2: campaignResult.co2,
              consumedCo2: Math.round(campaignResult.consumedCo2 * 100) / 100,
              isPlatformSynced: campaignResult.isPlatformSynced
            }
          } else if (checkIfLinkedinCampaign(campaignResult)) {
            newCampaign = {
              id: campaignResult.id,
              name: campaignResult.name,
              platform: campaignResult.platform,
              format: campaignResult.format,
              status: campaignResult.status,
              goal: campaignResult.goal,
              period: campaignResult.period,
              budget: campaignResult.budget,
              spentBudget: campaignResult.spentBudget,
              isBudgetPerDay: campaignResult.isBudgetPerDay,
              feedback: campaignResult.feedback,
              audiences: campaignResult.audiences,
              mediaPlanId: campaignResult.mediaPlanId,
              userId: campaignResult.userId,
              createdAt: campaignResult.createdAt,
              updatedAt: campaignResult.updatedAt,
              ads: campaignResult.ads,
              co2: campaignResult.co2,
              isPlatformSynced: campaignResult.isPlatformSynced
            }
          }

          setCampaign(newCampaign)
          setNavigation(result.navigation)
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }, [campaignId])

  if (hasFetchError || (!isEditor && !isSelfEditor)) {
    return <ErrorPage />
  }

  if (!campaign || !campaignId) {
    return <LoadingPage />
  }

  const handleShowReason =
    campaign.status === Status.REJECTED
      ? (event: MouseEvent) => {
          event.stopPropagation()
          if (navigation && campaign) {
            setIsModalReasonOpen(true)
            setRejectionReason(campaign.feedback ?? 'Aucune raison donnée')
            setMediaPlanName(navigation.mediaPlan.name)
          }
        }
      : undefined

  const handleEdit = getIsEditable({ status: campaign.status, isEditor })
    ? () => {
        navigate(`/campaign/${campaignId}/edit?flow=${FlowEnum.view}`)
      }
    : undefined

  const handleAdd = getIsEditable({ status: campaign.status, isEditor })
    ? () => {
        navigate(
          `/campaign/${campaignId}/ad/${campaign.platform}/${campaign.format}/create?flow=${FlowEnum.view}`
        )
      }
    : undefined

  const handleChangePage = async (page: number) => {
    const newSkip = page - 1
    const result = await getCampaignById({
      campaignId,
      skip: newSkip
    })
    if (result != null) {
      setCampaign(result.campaign)
    }
  }

  const CampaignDetailsPage = isEditor
    ? EditorCampaignDetails
    : SelfEditorCampaignDetails

  return (
    <CampaignDetailsPage
      campaign={campaign}
      mediaPlanName={mediaPlanName}
      skip={skip}
      handleChangePage={handleChangePage}
      dataAudienceModal={dataAudienceModal}
      setDataAudienceModal={setDataAudienceModal}
      isKeywordsModalOpen={isKeywordsModalOpen}
      setIsKeywordsModalOpen={setIsKeywordsModalOpen}
      dataKeywordsModal={dataKeywordsModal}
      setDataKeywordsModal={setDataKeywordsModal}
      isModalReasonOpen={isModalReasonOpen}
      setIsModalReasonOpen={setIsModalReasonOpen}
      handleShowReason={handleShowReason}
      rejectionReason={rejectionReason}
      navigation={navigation}
      handleAdd={handleAdd}
      handleEdit={handleEdit}
      navigate={navigate}
    />
  )
}

export default CampaignDetails
