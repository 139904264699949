import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import {
  GetCampaignDevicesReportingOutputType,
  checkIfGetCampaignDevicesReportingRowType
} from '../../../../types/reporting/campaign/output'

type GetCampaignDevicesReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignDevicesReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignDevicesReportingInputType): Promise<GetCampaignDevicesReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/devices${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignDevicesReporting(response.data.devices)) {
        return response.data.devices
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignDevicesReporting = (
  value: any,
  withCo2 = true
): value is GetCampaignDevicesReportingOutputType => {
  if (withCo2) {
    return (
      value &&
      value.smartphone &&
      checkIfGetCampaignDevicesReportingRowType(value.smartphone) &&
      value.tablet &&
      checkIfGetCampaignDevicesReportingRowType(value.tablet) &&
      value.desktop &&
      checkIfGetCampaignDevicesReportingRowType(value.desktop) &&
      value.tv &&
      checkIfGetCampaignDevicesReportingRowType(value.tv)
    )
  } else {
    return (
      value &&
      value.smartphone &&
      checkIfGetCampaignDevicesReportingRowType(value.smartphone, false) &&
      value.tablet &&
      checkIfGetCampaignDevicesReportingRowType(value.tablet, false) &&
      value.desktop &&
      checkIfGetCampaignDevicesReportingRowType(value.desktop, false) &&
      value.tv &&
      checkIfGetCampaignDevicesReportingRowType(value.tv, false)
    )
  }
}
