type Cookies = Record<string, string>

export const getCookie = (name: string): string | undefined => {
  const cookieString = decodeURIComponent(document.cookie)
  const cookies: Cookies = cookieString
    // TODO : edit this to have a split by ";" with a remove of all the beginning spaces
    .split('; ')
    .map((cookie) => cookie.split('='))
    .reduce(
      (acc, [cookieName, cookieValue]) => ({
        ...acc,
        [cookieName]: cookieValue
      }),
      {}
    )

  return cookies[name]
}

export const clearAllCookies = () => {
  const cookiesNames = ['token', 'user']
  const pastDate = 'Thu, 01 Jan 1970 00:00:01 GMT'

  cookiesNames.forEach((cookieName) => {
    document.cookie = `${cookieName}=; expires=${pastDate}; path=/; domain=".yetic.org"`
    document.cookie = `${cookieName}=; expires=${pastDate}; path=/; domain=".preprod.yetic.org"`
    document.cookie = `${cookieName}=; expires=${pastDate}; path=/`
  })
}

/* Doesn't work for browser which doesn't support cookieStore
export const clearAllCookies = () => {
  // @ts-expect-error
  cookieStore.getAll().then((cookies) =>
    cookies.forEach((cookie: any) => {
      // @ts-expect-error
      cookieStore.delete({ name: cookie.name, domain: cookie.domain })
    })
  )
}
*/

export const getIsUserEditor = (): boolean => {
  const cookie = getCookie('user')
  return JSON.parse(cookie || '{}').isEditor
}
