import React from 'react'

import { TextAdDetails } from '../../../../types/ad/google/textAd'
import {
  AdDetailsListContainer,
  AdPreviewContainer,
  AdPreviewTitle
} from './styles'
import GooglePreview from '../../../../components/PreviewAds/Google/Text'
import Colors from '../../../../constants/colors'
import { LabelledItemType } from '../../../../components/LabelledItem'
import LabelledItemsList from '../../../../components/LabelledItemsList'

type AdDetailsContentProps = {
  title: string
  adDetails: TextAdDetails
  listItems: LabelledItemType[]
}

const AdDetailsContentText = ({
  title,
  adDetails,
  listItems
}: AdDetailsContentProps) => {
  return (
    <>
      <AdDetailsListContainer>
        <LabelledItemsList items={listItems} />
      </AdDetailsListContainer>
      <AdPreviewContainer>
        <AdPreviewTitle>{title}</AdPreviewTitle>
        <GooglePreview
          style={{
            maxWidth: '95%',
            borderRadius: '10px',
            border: `1px solid ${Colors.YETIC_GREY_DARK}`
          }}
          scale={0.9}
          url={adDetails.url}
          title1={adDetails.title1}
          title2={adDetails.title2}
          title3={adDetails.title3}
          description={adDetails.description1}
          description2={adDetails.description2}
          description3={adDetails.description3}
        />
      </AdPreviewContainer>
    </>
  )
}

export default AdDetailsContentText
