import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { getAdById } from '../../helpers/queries/ad/getAdById'

const AdDetails = () => {
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { adId } = useParams()

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const result = await getAdById({
          adId
        })
        if (result != null) {
          navigate(`/ad/${adId}/${result.ad.format}/details`)
        }
        setHasFetchError(true)
      })()
    } else {
      setHasFetchError(true)
    }
  }, [adId])

  if (hasFetchError) {
    return <ErrorPage />
  }

  return <LoadingPage />
}

export default AdDetails
