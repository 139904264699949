import { backendURL } from '../../../constants/urls'
import {
  RejectMediaPlanErrorEnum,
  RejectMediaPlanErrorTranslation,
  checkIfRejectMediaPlanErrorCodeValid
} from '../../../types/error/mediaPlan/rejectMediaPlan'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'

// TODO : manage error
export type RejectMediaPlanOutputType = {
  mediaPlanId: string
}

export type RejectMediaPlanErrorType = {
  error: string
}

export type RejectMediaPlanInput = {
  mediaPlan: {
    id: string
    name: string
  }
  receiverEmail: string
  advertiser: {
    id: string
    companyName: string
    email: string
  }
  reasonOfRejection: string
}

export const rejectMediaPlan = async ({
  mediaPlan,
  receiverEmail,
  advertiser,
  reasonOfRejection
}: RejectMediaPlanInput): Promise<
  RejectMediaPlanOutputType | RejectMediaPlanErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/media-plans/${mediaPlan.id}/reject`,
      data: {
        mediaPlanName: mediaPlan.name,
        receiverEmail,
        advertiser,
        reasonOfRejection
      }
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.mediaPlanId) {
        return {
          mediaPlanId: response.data.mediaPlanId
        }
      }
    } else {
      const errorCode =
        (response as any)?.error.data?.code ?? (response as any)?.error.code
      if (errorCode && checkIfRejectMediaPlanErrorCodeValid(errorCode)) {
        return { error: RejectMediaPlanErrorTranslation[errorCode] }
      }
    }

    return {
      error: RejectMediaPlanErrorTranslation[RejectMediaPlanErrorEnum.UNKNOWN]
    }
  } catch (_) {
    return {
      error: RejectMediaPlanErrorTranslation[RejectMediaPlanErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfRejectMediaPlan = (
  result: any
): result is RejectMediaPlanOutputType => {
  return result.mediaPlanId !== undefined
}
