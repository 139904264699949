import { exportMediaPlanById } from './queries/mediaPlan/export'

const handleExportDownload = async (mediaPlanId: string) => {
  const result = await exportMediaPlanById({ mediaPlanId })

  if (result != null) {
    const newWindow = window.open()
    newWindow?.document.write(result.html)

    const pageStyle = document.createElement('style')
    pageStyle.innerHTML = '@page { size: A4; margin: 0mm; }'
    newWindow?.document.head.appendChild(pageStyle)

    const pageContentStyle = document.createElement('style')
    pageContentStyle.innerHTML = 'div { -webkit-print-color-adjust:exact; }'
    newWindow?.document.head.appendChild(pageContentStyle)

    const waitForAllImagesToLoad = async (
      images: NodeListOf<HTMLImageElement>
    ) => {
      return Promise.all(
        Array.from(images).map((img) => {
          return new Promise((resolve, reject) => {
            img.onload = resolve
            img.onerror = reject
          })
        })
      )
    }

    const images = document.querySelectorAll('img')
    waitForAllImagesToLoad(images)
      .then(() => {
        newWindow?.print()
      })
      .finally(() => {
        newWindow?.close()
      })
  } else {
    console.error('Impossible to download the media plan')
  }
}

export default handleExportDownload
