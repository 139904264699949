import React, { useEffect, useRef } from 'react'

import Button from '../../../components/Button'
import { buttonStyle } from './styles'
import ModalKeywords from '../../parts/ModalKeywords'
import { CampaignKeyword } from '../types'

type KeywordsProps = {
  count: number
  campaignName: string
  keywords: CampaignKeyword[] | undefined
  setIsOpen: (isOpen: boolean) => void
  isEditing: boolean
  onAddKeyword: (keyword: string) => void
  onRemoveKeyword: (keyword: string) => void
}

const Keywords = ({
  count,
  keywords,
  setIsOpen,
  isEditing,
  onAddKeyword,
  onRemoveKeyword,
  campaignName
}: KeywordsProps) => {
  const listRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    scrollToBottomList()
  }, [keywords])

  const scrollToBottomList = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
        type="secondary"
        style={buttonStyle}
      >
        Gérer mes mots-clés ({keywords === undefined ? count : keywords.length})
      </Button>
      {isEditing && (
        <ModalKeywords
          setIsOpen={setIsOpen}
          campaignName={campaignName}
          keywords={keywords}
          onAddKeyword={onAddKeyword}
          onRemoveKeyword={onRemoveKeyword}
          isEditable
        />
      )}
    </>
  )
}

export default Keywords
