import { backendURL } from '../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'
import {
  checkIfMediaPlanAudience,
  MediaPlanAudience
} from '../../../types/mediaPlanAudience'

export type GetAudienceInput = {
  mediaPlanId?: string
  name: string
}

export type GetAudienceByNameAndCompanyIdInput = {
  companyId: string
  name: string
}

export const getAudienceByMediaPlanIdAndName = async ({
  mediaPlanId,
  name
}: GetAudienceInput): Promise<MediaPlanAudience | null> => {
  name = encodeURIComponent(name)
  try {
    if (mediaPlanId) {
      const response = await axiosGet({
        url: `${backendURL}/media-plans/${mediaPlanId}/audiences?name=${name}`
      })
      if (
        checkIsAxiosGetOutputSuccess(response) &&
        checkIfMediaPlanAudience(response.data)
      ) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const getAudienceByNameAndCompanyId = async ({
  name,
  companyId
}: GetAudienceByNameAndCompanyIdInput): Promise<MediaPlanAudience | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/companies/${companyId}/audience?name=${name}`
    })
    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfMediaPlanAudience(response.data)
    ) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}
