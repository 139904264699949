import axios from 'axios'
import { AxiosResponseType } from '../../../types/axios/common'

export type AxiosPutType = {
  url: string
  // eslint-disable-next-line
  data: any
  contentType?: string
}

type AxiosPutOutputSuccessType = {
  type: AxiosResponseType.SUCCESS
  data: any
}

type AxiosPutOutputErrorType = {
  type: AxiosResponseType.ERROR
  error: string
}

export const checkIsAxiosPutOutputSuccess = (
  value: any
): value is AxiosPutOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === AxiosResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export type AxiosPutOutputType =
  | AxiosPutOutputSuccessType
  | AxiosPutOutputErrorType

export const axiosPut = async ({
  url,
  data,
  contentType
}: AxiosPutType): Promise<AxiosPutOutputType> => {
  try {
    const response = await axios.put(url, data, {
      withCredentials: true,
      headers: {
        'Content-Type': contentType ?? 'application/json'
        // Authorization: `Bearer ${token}`
        // using withCredentials seems more adapted
      }
    })
    // console.log(response);
    if (
      checkIsAxiosPutOutputSuccess({
        type: AxiosResponseType.SUCCESS,
        data: response.data
      })
    ) {
      return {
        type: AxiosResponseType.SUCCESS,
        data: response.data
      }
    }
    return {
      type: AxiosResponseType.ERROR,
      error: 'Une erreur est survenue' // use an enum for the errors
    }
  } catch (error: any) {
    return {
      type: AxiosResponseType.ERROR,
      error: error.response ?? error.request ?? error
    }
  }
}
