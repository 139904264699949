import { backendURL } from '../../../constants/urls'
import { axiosDelete, checkIsAxiosDeleteOutputSuccess } from '../axios/delete'

// TODO : manage error
export type ArchiveAdOutputType = {
  adId: string
}

export type ArchiveAdErrorType = {
  error: string
}

export type ArchiveAdInput = {
  adId: string
}

export const archiveAd = async ({
  adId
}: ArchiveAdInput): Promise<ArchiveAdOutputType | ArchiveAdErrorType> => {
  try {
    const response = await axiosDelete({
      url: `${backendURL}/ads/${adId}`
    })

    if (checkIsAxiosDeleteOutputSuccess(response)) {
      if (response.data && response.data.adId) {
        return {
          adId: response.data.adId
        }
      }
    }

    return {
      error: "Une erreur inconnue est survenue durant l'archivage de l'annonce"
    }
  } catch (_) {
    return {
      error: "Une erreur inconnue est survenue durant l'archivage de l'annonce"
    }
  }
}

export const checkIfArchiveAd = (
  result: any
): result is ArchiveAdOutputType => {
  return result.adId !== undefined
}
