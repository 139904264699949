import { backendURL } from '../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type LinkedinAuthOutputType = boolean

export const getLinkedinAuth = async (
  customerId: string
): Promise<LinkedinAuthOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/linkedin/auth?companyId=${customerId}`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      const { authorizationUrl } = response.data
      if (authorizationUrl) {
        window.location.href = authorizationUrl
      }
      return true
    }

    return null
  } catch (_) {
    return null
  }
}
