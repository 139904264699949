import React, { useEffect, useState } from 'react'

import { getCO2andUnit } from '../../../helpers/getCO2andUnit'
import { GetCampaignCO2DetailsOutputType } from '../../../helpers/queries/campaign/getCampaignCO2Details'
import AnimatedLoader from '../../AnimatedLoader'
import {
  CO2Total,
  Container,
  ListItem,
  Value,
  Content,
  co2TotalTextStyle,
  Footer,
  knowMoreTextStyle,
  leafTotalStyle,
  KnowMoreContainer,
  arrowStyle
} from './styles'
import {
  DevicesEnumTranslation,
  getKeysOfDevicesNumber
} from '../../../types/campaign/devices'
import DefaultText from '../../DefaultText'
import { InputError } from '../../Input/Styles'
import { FormatAPIEnum } from '../../../types/format'
import { roundToTwoDecimals } from '../../../helpers/rounds'
import DetailsTabItem from './DetailsTabItem'
import LeafIcon from '../../Icon/leaf'
import Colors from '../../../constants/colors'
import { ArrowNext } from '../../Icon'

type DetailsTabProps = {
  data: GetCampaignCO2DetailsOutputType | null
  hasFetchError: boolean
  format: FormatAPIEnum
}

const DetailsTab = ({ data, hasFetchError, format }: DetailsTabProps) => {
  const co2andUnit = data
    ? getCO2andUnit(data.co2) ?? { co2: 0, co2Unit: 'g' }
    : { co2: 0, co2Unit: 'g' }

  const [networkCo2, setNetworkCo2] = useState<number>(0)

  useEffect(() => {
    setNetworkCo2(data?.network || 0)
  }, [data])

  const logoWeight = data?.weight?.logo || 0
  const totalImagesWeight = data?.weight?.images.reduce((a, b) => a + b, 0) || 0
  const averageImagesWeight = data?.weight?.images.length
    ? totalImagesWeight / data.weight.images.length
    : 0
  const picturesTotalWeight = roundToTwoDecimals(
    (logoWeight + averageImagesWeight) / 1000
  )

  const totalDevicesCO2 =
    data != null
      ? roundToTwoDecimals(
          getKeysOfDevicesNumber().reduce(
            (acc, key) => getCO2andUnit(data.details[key].value).co2 + acc,
            0
          )
        )
      : 0
  const totalDevicesCO2andUnit = getCO2andUnit(totalDevicesCO2)

  return (
    <Container>
      <Content>
        <DetailsTabItem
          title="CO2 serveur"
          description={`Les serveurs consomment de l'énergie pour stocker et traiter les données des publicités,
          générant des émissions de CO2 proportionnelles à la consommation d'électricité`}
          comment="Non calculé pour le moment"
        />
        <DetailsTabItem
          title="CO2 réseau électrique"
          description={`Les émissions dépendent de la source d'énergie (fossile ou renouvelable) utilisée pour
            alimenter l'infrastructure numérique, y compris les centres de données et les réseaux de transmission.
            `}
          comment={
            format === FormatAPIEnum.TEXT
              ? `Négligeable pour les annonces texte`
              : `Données transmises par le réseau : ${
                  picturesTotalWeight > 0
                    ? `environ ${picturesTotalWeight}`
                    : picturesTotalWeight
                } Ko`
          }
          value={
            format === FormatAPIEnum.TEXT
              ? undefined
              : `${roundToTwoDecimals(networkCo2)} g`
          }
          percentage={
            format === FormatAPIEnum.TEXT
              ? undefined
              : roundToTwoDecimals((networkCo2 / co2andUnit.co2) * 100)
          }
        />
        <DetailsTabItem
          title="CO2 périphériques"
          description={`Les appareils utilisés émettent du CO2 pour visualiser les publicités.
          Les émissions dépendent de la consommation électrique de l'appareil et de la source d'énergie.`}
          value={`${totalDevicesCO2andUnit.co2} ${totalDevicesCO2andUnit.co2Unit}`}
          withBorder={false}
          percentage={roundToTwoDecimals(
            (totalDevicesCO2 / co2andUnit.co2) * 100
          )}
        >
          <Value style={{ marginTop: '10px', width: '100%' }}>
            {data == null && hasFetchError ? (
              <InputError>
                Une erreur est survenue durant la récupération des données.
              </InputError>
            ) : data == null && !hasFetchError ? (
              <AnimatedLoader />
            ) : data != null ? (
              getKeysOfDevicesNumber().map((key) =>
                data.details[key].portion !== 0 ? (
                  <ListItem key={key}>
                    <DefaultText size="medium">
                      {DevicesEnumTranslation[key]}s (
                      {data.details[key].portion * 100}% du flux)
                    </DefaultText>
                    <DefaultText size="medium" bold>
                      {`${getCO2andUnit(data.details[key].value).co2} ${
                        getCO2andUnit(data.details[key].value).co2Unit
                      }`}
                    </DefaultText>
                  </ListItem>
                ) : null
              )
            ) : null}
          </Value>
        </DetailsTabItem>
      </Content>
      <Footer>
        <CO2Total>
          <DefaultText size="big" style={co2TotalTextStyle}>
            <LeafIcon
              color={Colors.NEW_YETIC_GREEN}
              height="20px"
              style={leafTotalStyle}
            />
            Empreinte CO2 de la campagne
          </DefaultText>
          <DefaultText
            size="big"
            bold
            style={{ fontSize: '40px', lineHeight: '40px' }}
          >{`${co2andUnit.co2} ${co2andUnit.co2Unit}`}</DefaultText>
        </CO2Total>
        <KnowMoreContainer>
          <DefaultText size="medium" style={knowMoreTextStyle}>
            {`En savoir plus sur le calcul de l'empreinte carbone`}
          </DefaultText>
          <ArrowNext width="8px" color="#737f94" style={arrowStyle} />
        </KnowMoreContainer>
      </Footer>
    </Container>
  )
}

export default DetailsTab
