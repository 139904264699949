import styled from '@emotion/styled'

export const Wrapper = styled.button`
  bottom: 0;
  right: 0;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
`

export const HiddenText = styled.div<{ visible: boolean; width: string }>`
  width: ${(props) => props.width};
  overflow: hidden;
  padding: 0 20px;
  height: 100%;
  border-radius: 100px;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 20;
  background-color: white;
  box-shadow: ${(props) => (props.visible ? '0px 0px 10px #142a4c40' : '')};
  transition: 0.5s;
`

export const OpenButtonImage = styled.img`
  width: 90%;
  height: 90%;
  object-fit: cover;
  position: relative;
  z-index: 40;
  border: solid white 6px;
  border-radius: 100px;
  box-shadow: 0px 0px 10px #142a4c40;
  background-color: white;

  &:hover {
    filter: brightness(0.97);
  }
`
