import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AudienceCreationPage from './AudienceCreationPageEditor'
import AudiencePanel from './UI/Audience'
import { AppContext } from '../../contexts/AppContext'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { MediaPlanGoal } from '../../types/goals'
import { PanelType } from '../../types/templates/panels'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import Input from '../../components/Input'
import { CheckboxListTmp } from '../../tmpComponents/CheckboxList'
import Period from '../components/Period'
import { createMediaPlan } from '../../helpers/queries/mediaPlan/create'
import { FormMediaPlanType } from '../../types/pages/mediaPlanCreation'
import {
  checkIfCreateMediaPlanErrorCodeValid,
  CreateMediaPlanErrorFromApi
} from '../../types/error/mediaPlan/create'
import {
  checkIfGetMediaPlanCreationDataOutputType,
  getMediaPlanCreationData
} from '../../helpers/queries/mediaPlanAudience/getMediaPlanCreationData'
import Breadcrumb from '../../components/BreadCrumb'
import {
  MediaPlanNavigation,
  emptyMediaPlanNavigation
} from '../../types/mediaPlan/navigation'
import { SectionType } from '../../types/templates/section'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { getAudienceByNameAndCompanyId } from '../../helpers/queries/audiences/getAudienceByName'
import { MediaPlanAudience } from '../../types/mediaPlanAudience'
import ModalAudienceDetails from '../../components/ModalAudienceDetails'
import Dropdown from '../../components/Dropdown'
import ConfirmationModal from '../../components/Modal/ConfirmationModal'
import DefaultText from '../../components/DefaultText'
import Colors from '../../constants/colors'
import { ModalContent, textStyle, warningTextStyle } from './styles'
import { deepClone } from '../../helpers/clone'
import AudienceImport from './UI/Audience/AudienceImportButton'
import ExistingAudiencesModal from './UI/Audience/ExistingAudiencesModal'
import {
  GetCompanyAudiencesOutput,
  checkIfGetCompanyAudiencesOutput,
  getCompanyAudiences
} from '../../helpers/queries/audiences/getCompanyAudiences'
import { getAudienceById } from '../../helpers/queries/audiences/getAudienceById'
import {
  DuplicateAudienceOutputError,
  DuplicateAudienceOutputType,
  checkIfDuplicateAudienceOutputTypeArray,
  duplicateExistingAudience
} from '../../helpers/queries/mediaPlanAudience/duplicate'
import { getErrors } from './helpers/errors'
import RenamingSelectedAudiencesModal from './UI/Audience/RenamingSelectedAudiencesModal'
import { PeriodTips } from './PeriodTips'
import { AudienceTipsEditor } from './AudienceTips/editor'

const emptyFormMediaPlan = {
  name: '',
  isEditorPaying: true,
  startDate: '',
  endDate: '',
  amountBudget: 0,
  selectedGoals: [],
  selectedAudiences: []
}

export type ExistingAudiences = GetCompanyAudiencesOutput

const EditorMediaPlanCreation = () => {
  const [formMediaPlan, setFormMediaPlan] =
    useState<FormMediaPlanType>(emptyFormMediaPlan)
  const [isEditAudience, setIsEditAudience] = useState<boolean>(false)
  const [dataAudienceModal, setDataAudienceModal] =
    useState<MediaPlanAudience | null>(null)
  const [audiences, setAudiences] = useState<string[]>([])
  const [isExistingAudiencesModalOpen, setIsExistingAudiencesModalOpen] =
    useState(false)
  const [existingAudiences, setExistingAudiences] =
    useState<ExistingAudiences | null>(null)
  const [selectedAudiencesToImport, setSelectedAudiencesToImport] = useState<
    Record<string, boolean>[] | null
  >([])
  const [isRenamingAudiencesModalOpen, setIsRenamingAudiencesModalOpen] =
    useState(false)
  const [selectedAudiencesNewNames, setSelectedAudiencesNewNames] = useState<
    Record<string, string>[]
  >([])
  const [errorFromApi, setErrorFromApi] = useState<string>('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [navigation, setNavigation] = useState<MediaPlanNavigation>(
    emptyMediaPlanNavigation
  )
  const [hasCustomerUsers, setHasCustomerUsers] = useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [duplicateAudienceError, setDuplicateAudienceError] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isLeaveModalOpen, setIsModalLeaveOpen] = useState(false)

  const pageRef = useRef<HTMLDivElement>(null)

  const { companyId } = useParams()

  const { isEditor, isSelfEditor, user } = useContext(AppContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (companyId) {
      ;(async function () {
        const mediaPlanAudiences = await getMediaPlanCreationData(companyId)
        const newExistingAudiences = await getCompanyAudiences({
          companyId
        })

        if (checkIfGetMediaPlanCreationDataOutputType(mediaPlanAudiences)) {
          setAudiences(mediaPlanAudiences.audienceNames)
          setNavigation(mediaPlanAudiences.navigation)
          setHasCustomerUsers(mediaPlanAudiences.hasCustomerUsers)
          setHasFetchError(false)
        } else {
          setHasFetchError(true)
        }

        if (checkIfGetCompanyAudiencesOutput(newExistingAudiences)) {
          setExistingAudiences(newExistingAudiences)
          setSelectedAudiencesToImport(
            newExistingAudiences.map((audience) => ({ [audience.id]: false }))
          )
        }
      })()
    } else {
      setHasFetchError(true)
    }
    scrollTo(0, 0)
  }, [companyId])

  useEffect(() => {
    if (!isEditAudience && pageRef.current) {
      pageRef.current.scrollTo(0, pageRef.current.scrollHeight)
    }
  }, [isEditAudience])

  useEffect(() => {
    if (isSubmitLoading) {
      if (companyId) {
        ;(async function () {
          const result = await createMediaPlan({
            ...formMediaPlan,
            companyId
          })

          if (result.error) {
            setErrorFromApi(
              checkIfCreateMediaPlanErrorCodeValid(result.error)
                ? CreateMediaPlanErrorFromApi[result.error]
                : CreateMediaPlanErrorFromApi.UNKNOWN
            )
            setTimeout(() => {
              setErrorFromApi('')
            }, 5000)
          } else {
            navigate(`/media-plan/${result.mediaPlanId as string}/review`)
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  if (hasFetchError || !companyId) {
    return <ErrorPage />
  }

  if (
    !audiences ||
    !navigation ||
    !existingAudiences ||
    !selectedAudiencesToImport
  ) {
    return <LoadingPage />
  }

  const selectedAudiencesIds = selectedAudiencesToImport
    ?.filter((obj) => Object.values(obj)[0])
    .map((obj) => Object.keys(obj)[0])

  const selectedAudienceNames = existingAudiences
    ?.filter((obj) => selectedAudiencesIds?.includes(obj.id))
    .map((obj) => obj.name)

  const addToPanels = (
    panels: (JSX.Element | PanelType)[],
    title: string,
    sections: (SectionType | JSX.Element)[]
  ) => {
    panels.push({
      title,
      disabled: false,
      sections,
      isDropdown: false
    })
  }

  const errors = getErrors(formMediaPlan, errorFromApi)

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // TODO : to see if debounce is needed
    setFormMediaPlan({
      ...formMediaPlan,
      name: event.target.value
    })
  }

  const handlePaymentResponsibleChange = (
    responsibleId: number | string | null
  ) => {
    if (responsibleId === 1) {
      setFormMediaPlan({
        ...deepClone(formMediaPlan),
        isEditorPaying: true
      })
    }
    if (responsibleId === 2) {
      setFormMediaPlan({
        ...deepClone(formMediaPlan),
        isEditorPaying: false
      })
    }
  }

  // Add the goal with checkboxId if not selected,
  // else remove it from selected goals
  const handleChangeGoal = (checkboxId: string) => {
    if (Object.keys(MediaPlanGoal).includes(checkboxId)) {
      const typedCheckboxId = checkboxId as keyof typeof MediaPlanGoal
      if (formMediaPlan.selectedGoals.includes(typedCheckboxId)) {
        setFormMediaPlan({
          ...formMediaPlan,
          selectedGoals: [...formMediaPlan.selectedGoals].filter(
            (goal) => goal !== typedCheckboxId
          )
        })
      } else {
        setFormMediaPlan({
          ...formMediaPlan,
          selectedGoals: [...formMediaPlan.selectedGoals, typedCheckboxId]
        })
      }
    }
  }

  const handleChangeStartDate = (startDate: string) => {
    setFormMediaPlan({
      ...formMediaPlan,
      startDate
    })
  }

  const handleChangeEndDate = (endDate: string) => {
    setFormMediaPlan({
      ...formMediaPlan,
      endDate
    })
  }

  const handleChangeAmountBudget = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // TODO : to see if debounce is needed
    if (event.target.value && !Number.isNaN(parseFloat(event.target.value))) {
      setFormMediaPlan({
        ...formMediaPlan,
        amountBudget: parseFloat(event.target.value)
      })
    }
  }

  const addSelectedAudience = (newSelectedAudience: string) => {
    setFormMediaPlan({
      ...formMediaPlan,
      selectedAudiences: [
        ...formMediaPlan.selectedAudiences,
        newSelectedAudience
      ]
    })
  }

  const removeSelectedAudience = (indexToDelete: number) => {
    setFormMediaPlan({
      ...formMediaPlan,
      selectedAudiences: [
        ...formMediaPlan.selectedAudiences.filter(
          (_, index: number) => index !== indexToDelete
        )
      ]
    })
  }

  const addAudience = (newAudience: string) => {
    setAudiences([...audiences, newAudience])
    setFormMediaPlan({
      ...formMediaPlan,
      selectedAudiences: [...formMediaPlan.selectedAudiences, newAudience]
    })
  }

  if (isEditAudience) {
    return (
      <AudienceCreationPage
        customerId={companyId}
        back={() => {
          setIsEditAudience(false)
        }}
        validate={(newAudience: string) => {
          addAudience(newAudience)
          setIsEditAudience(false)
        }}
      />
    )
  }

  const handleOpenAudienceListModal = () => {
    setIsExistingAudiencesModalOpen(true)
  }

  const handleChangeSelectedAudiencesToImport = (audienceId: string) => {
    const newSelectedAudiencesToImport = deepClone(selectedAudiencesToImport)

    const currentIndex = newSelectedAudiencesToImport
      .map((obj) => Object.keys(obj)[0])
      .indexOf(audienceId)

    newSelectedAudiencesToImport[currentIndex] = {
      [audienceId]: !newSelectedAudiencesToImport[currentIndex][audienceId]
    }

    setSelectedAudiencesToImport(newSelectedAudiencesToImport)
  }

  const handleOpenRenamingAudiencesModal = () => {
    setSelectedAudiencesNewNames(
      selectedAudiencesToImport
        ?.map((obj) => ({
          [Object.keys(obj)[0]]:
            existingAudiences?.find(
              (audience) => audience.id === Object.keys(obj)[0]
            )?.name ?? ''
        }))
        .filter((obj) => selectedAudiencesIds.includes(Object.keys(obj)[0]))
    )
    setIsRenamingAudiencesModalOpen(true)
  }

  const handleChangeSelectedAudienceName = (
    event: ChangeEvent<HTMLInputElement>,
    audienceId: string
  ) => {
    const newSelectedAudiencesNewNames = deepClone(selectedAudiencesNewNames)

    const currentIndex = newSelectedAudiencesNewNames
      .map((obj) => Object.keys(obj)[0])
      .indexOf(audienceId)

    newSelectedAudiencesNewNames[currentIndex] = {
      [audienceId]: event.target.value
    }

    setSelectedAudiencesNewNames(newSelectedAudiencesNewNames)
  }

  const handleImportAudiences = async () => {
    if (selectedAudiencesNewNames) {
      for (let i = 0; i < selectedAudiencesNewNames.length; i++) {
        if (
          selectedAudienceNames?.includes(
            Object.values(selectedAudiencesNewNames[i])[0].trim()
          ) ||
          audiences.includes(
            Object.values(selectedAudiencesNewNames[i])[0].trim()
          )
        ) {
          setDuplicateAudienceError(
            'Vous ne pouvez pas importer une audience avec un nom déjà existant. Veuillez renommer les audiences concernées.'
          )
          setTimeout(() => {
            setDuplicateAudienceError('')
          }, 5000)
          return
        }
      }

      if (selectedAudienceNames && selectedAudiencesNewNames.length > 0) {
        const results: (
          | DuplicateAudienceOutputType
          | DuplicateAudienceOutputError
        )[] = []

        for (let i = 0; i < selectedAudiencesNewNames.length; i++) {
          const result = await duplicateExistingAudience({
            id: Object.keys(selectedAudiencesNewNames[i])[0],
            newName: Object.values(selectedAudiencesNewNames[i])[0]
          })

          results.push(result)
        }

        if (checkIfDuplicateAudienceOutputTypeArray(results)) {
          setIsRenamingAudiencesModalOpen(false)
          setIsExistingAudiencesModalOpen(false)
          setAudiences([
            ...audiences,
            ...results.map((result) => result.audienceName)
          ])
          setFormMediaPlan({
            ...formMediaPlan,
            selectedAudiences: [
              ...formMediaPlan.selectedAudiences,
              ...results.map((result) => result.audienceName)
            ]
          })
          setSelectedAudiencesToImport([
            ...selectedAudiencesToImport.map((obj) => ({
              [Object.keys(obj)[0]]: false
            }))
          ])
        } else {
          setDuplicateAudienceError(
            "Une erreur est survenue lors de l'importation des audiences. Veuillez réessayer."
          )
          setTimeout(() => {
            setDuplicateAudienceError('')
          }, 5000)
        }
      }
    }
  }

  const importedAudiences = selectedAudiencesToImport
    .filter((audience) => audiences.includes(Object.keys(audience)?.[0]))
    .map((audience) => Object.keys(audience)?.[0])

  const handleOpenSubmitModal = () => {
    if (Object.values(errors).filter((e) => e).length < 1) {
      setIsPaymentModalOpen(true)
    } else {
      setHasBeenSubmitted(true)
    }
  }

  const handleSubmitMediaPlan = () => {
    setIsSubmitLoading(true)
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    navigate(`/customer/${companyId}/details`)
  }

  const panels: (JSX.Element | PanelType)[] = []

  const paymentResponsibleOptions = hasCustomerUsers
    ? [
        { id: 1, label: 'Votre agence' },
        { id: 2, label: 'Votre client' }
      ]
    : [{ id: 1, label: 'Votre agence' }]

  const inputName = {
    description: 'Donnez un nom à votre plan média',
    content: (
      <Input
        type="text"
        onChange={handleChangeName}
        placeholder="Nom du plan média"
        value={formMediaPlan.name}
        error={hasBeenSubmitted ? errors.name : ''}
        withBorder
      />
    )
  }

  const goalsSelection = {
    description: 'Définissez le ou les objectifs de votre plan média',
    content: (
      <CheckboxListTmp
        values={(
          Object.keys(MediaPlanGoal) as (keyof typeof MediaPlanGoal)[]
        ).map((key) => ({
          id: key,
          label: MediaPlanGoal[key],
          selected: formMediaPlan.selectedGoals.includes(key)
        }))}
        onChange={handleChangeGoal}
        error={hasBeenSubmitted ? errors.goals : ''}
        withBorder
      />
    )
  }

  const periodTips = <PeriodTips mediaPlanTranslation="plan média" />

  const dayAfterTomorrow = new Date()
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 3)

  const periods = {
    description: 'Définissez la période de diffusion de votre plan média',
    content: (
      <Period
        startDate={formMediaPlan.startDate}
        endDate={formMediaPlan.endDate}
        minStartDate={dayAfterTomorrow.toISOString().split('T')[0]}
        minEndDate={dayAfterTomorrow.toISOString().split('T')[0]}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        startDateError={hasBeenSubmitted ? errors.startDate : ''}
        endDateError={hasBeenSubmitted ? errors.endDate : ''}
      />
    )
  }

  const handleClickAudienceTag = async (audienceName: string) => {
    const result = await getAudienceByNameAndCompanyId({
      name: audienceName,
      companyId
    })
    if (result) {
      setDataAudienceModal(result)
    }
  }

  const handleSeeExistingAudience = async (audienceId: string) => {
    const result = await getAudienceById({
      id: audienceId
    })
    if (result) {
      setDataAudienceModal(result)
    }
  }

  const audienceTips = <AudienceTipsEditor />

  const audiencePanel = {
    cornerAction: (
      <AudienceImport handleOpenModal={handleOpenAudienceListModal} />
    ),
    description:
      'Choisissez une ou des audiences parmi votre liste ou créez en une nouvelle',
    content: (
      <>
        <AudiencePanel
          audiences={audiences}
          selectedAudiences={formMediaPlan.selectedAudiences}
          addSelectedAudience={addSelectedAudience}
          removeAudience={removeSelectedAudience}
          textButtonAudienceCreation="Créer une nouvelle audience"
          openAudienceCreation={() => {
            setIsEditAudience(true)
          }}
          onClickTag={(audienceName: string) => {
            handleClickAudienceTag(audienceName)
          }}
          error={hasBeenSubmitted ? errors.audience : ''}
        />
        {isExistingAudiencesModalOpen && (
          <ExistingAudiencesModal
            onClose={() => {
              setIsExistingAudiencesModalOpen(false)
            }}
            data={existingAudiences}
            selectedAudiences={selectedAudiencesToImport}
            importedAudiences={importedAudiences}
            handleCheckboxChange={handleChangeSelectedAudiencesToImport}
            handleSeeAudienceDetails={handleSeeExistingAudience}
            handleSubmit={handleOpenRenamingAudiencesModal}
          />
        )}
        {isRenamingAudiencesModalOpen && (
          <RenamingSelectedAudiencesModal
            onClose={() => {
              setIsRenamingAudiencesModalOpen(false)
            }}
            audiencesData={existingAudiences}
            newNames={selectedAudiencesNewNames}
            handleNameChange={handleChangeSelectedAudienceName}
            handleSubmit={() => {
              handleImportAudiences()
            }}
            error={duplicateAudienceError}
          />
        )}
      </>
    )
  }

  const inputBudget = {
    description: 'Définissez le budget de votre plan média',
    content: (
      <Input
        type="devise"
        onChange={handleChangeAmountBudget}
        placeholder="Budget en €"
        value={
          formMediaPlan.amountBudget ? String(formMediaPlan.amountBudget) : ''
        }
        style={{ paddingRight: '30px' }}
        error={hasBeenSubmitted ? errors.budget : ''}
        withBorder
      />
    )
  }

  const handleClickOnCompany = () => {
    setIsModalLeaveOpen(true)
  }

  const handleCloseLeaveModal = () => {
    setIsModalLeaveOpen(false)
  }

  const items = [
    {
      onClick: () => {
        handleClickOnCompany()
      },
      label: navigation.company.name
    },
    { label: 'Nouveau plan média' }
  ]

  addToPanels(panels, 'Nom du plan média', [inputName])
  addToPanels(panels, 'Objectif(s)', [goalsSelection])
  panels.push(periodTips)
  addToPanels(panels, 'Période de diffusion', [periods])
  panels.push(audienceTips)
  addToPanels(panels, 'Audience(s)', [audiencePanel])
  addToPanels(panels, 'Budget', [inputBudget])

  const errorsNotEmpty = Object.values(errors).filter((e) => e)

  const errorsToDisplay =
    errorsNotEmpty.length > 0
      ? [errorsNotEmpty[0]]
      : errorFromApi
      ? [errorFromApi]
      : []

  return (
    <AuthenticatedTemplate
      navigate={navigate}
      user={user}
      isEditor={isEditor != null ? isEditor : true}
      pageRef={pageRef}
    >
      <Breadcrumb items={items} />
      <CreationPageTemplate
        title={'Nouveau plan média'}
        subTitle="Définissez les caractéristiques globales de votre plan média"
        panels={panels}
        errors={{
          errors: hasBeenSubmitted ? errorsToDisplay : []
        }}
        validation={{
          wording: 'Enregistrer et continuer',
          action: hasCustomerUsers
            ? handleOpenSubmitModal
            : handleSubmitMediaPlan,
          disabled: hasBeenSubmitted && errorsNotEmpty.length > 0,
          isLoading: isSubmitLoading
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
      />
      {dataAudienceModal && (
        <ModalAudienceDetails
          title={"Détail de l'audience"}
          data={dataAudienceModal}
          handleClose={() => {
            setDataAudienceModal(null)
          }}
        />
      )}
      {isPaymentModalOpen && (
        <ConfirmationModal
          title="Facturation du plan média"
          onClose={() => {
            setIsPaymentModalOpen(false)
          }}
          onClickReject={() => {
            setIsPaymentModalOpen(false)
          }}
          onClickConfirm={() => {
            handleSubmitMediaPlan()
          }}
          textConfirm="Valider"
          isConfirmLoading={isSubmitLoading}
          styleButtonConfirm={{
            backgroundColor: Colors.YETIC_YELLOW_DARK,
            color: Colors.YETIC_BLUE_DARK
          }}
        >
          <DefaultText style={textStyle}>
            Veuillez définir qui sera facturé pour ce plan média.
          </DefaultText>
          <Dropdown
            options={paymentResponsibleOptions}
            onChange={(e) => {
              handlePaymentResponsibleChange(e?.id || null)
            }}
            value={
              formMediaPlan.isEditorPaying ? 'Votre agence' : 'Votre client'
            }
          />
          <DefaultText size="small" style={warningTextStyle}>
            {hasCustomerUsers
              ? 'Attention : votre sélection est définitive.'
              : "Ce client n'a pas de responsable défini."}
          </DefaultText>
        </ConfirmationModal>
      )}
      {isCancelModalOpen && (
        <ConfirmationModal
          title="Votre plan média est en cours de création"
          textConfirm="Oui, annuler"
          textReject="Non, continuer"
          onClickReject={handleCloseCancelModal}
          onClickConfirm={handleCancelConfirm}
          onClose={handleCloseCancelModal}
        >
          <ModalContent>
            <DefaultText>
              {
                'Êtes-vous sûr de vouloir annuler la création de votre plan média ?'
              }
            </DefaultText>
            <DefaultText>
              {'Toutes les informations saisies seront perdues.'}
            </DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
      {isLeaveModalOpen && (
        <ConfirmationModal
          onClose={handleCloseLeaveModal}
          textConfirm="Quitter"
          textReject="Annuler et continuer"
          onClickConfirm={() => {
            navigate(`/customer/${companyId}/details`)
          }}
          onClickReject={handleCloseLeaveModal}
          title="Êtes-vous sûr de vouloir voir la fiche client ?"
        >
          <ModalContent>
            <DefaultText>{`Voulez-vous vraiment voir la fiche client ?`}</DefaultText>
            <DefaultText>{`Vous quitterez la création. Toutes les données saisies seront perdues.`}</DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default EditorMediaPlanCreation
