import { backendURL } from '../../../../../constants/urls'
import { GetMediaPlanCampaignsReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../../axios/get'

type GetMediaPlanCampaignsReportingInputType = {
  mediaPlanId: string
  startDate?: string
  endDate?: string
}

export const getMediaPlanCampaignsReporting = async ({
  mediaPlanId,
  startDate,
  endDate
}: GetMediaPlanCampaignsReportingInputType): Promise<GetMediaPlanCampaignsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/media-plan/${mediaPlanId}/sections/campaigns${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetMediaPlanCampaignsReporting(response.data.campaigns)) {
        return response.data.campaigns
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetMediaPlanCampaignsReporting = (
  val: any
): val is GetMediaPlanCampaignsReportingOutputType => {
  return (
    val &&
    Array.isArray(val) &&
    val.every((item: any) => {
      return (
        item &&
        item.campaignId &&
        typeof item.campaignId === 'string' &&
        item.campaignName &&
        typeof item.campaignName === 'string' &&
        item.clicks !== undefined &&
        typeof item.clicks === 'number' &&
        item.impressions !== undefined &&
        typeof item.impressions === 'number'
      )
    })
  )
}
