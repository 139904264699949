import styled from '@emotion/styled'

import FontSize from '../../../../constants/fontSize'
import FontWeight from '../../../../constants/fontWeight'
import Colors from '../../../../constants/colors'

export const TitleCountContainer = styled.div`
  font-size: ${FontSize.YETIC_TITLE_THIN};
  font-weight: ${FontWeight.YETIC_BOLD};
  text-transform: uppercase;
  color: ${Colors.YETIC_BLUE_DARK};
`
