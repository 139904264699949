import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'

import Input from '../../../../components/Input'
import Colors from '../../../../constants/colors'
import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import { Tags } from '../../../../components/Tag'
import { LocationData } from '../../../../types/mediaPlanAudience'

const DivContainer = styled.div`
  margin-top: ${Margin.m6};
`

export const TagSpan = styled.span`
  background-color: ${Colors.YETIC_GREEN_LIGHTER};
  color: white;
  padding: 4px 8px;
  margin-right: ${Margin.m2};
  display: block;
  white-space: nowrap;
`

export const CloseIcon = styled.span`
  cursor: pointer;
  margin-left: ${Margin.m2};
  font-size: ${FontSize.YETIC_TEXT};
  color: white;

  &:hover {
    color: ${Colors.YETIC_BLUE_DARK};
  }
`

const PlaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.YETIC_WHITE};
  box-shadow: 0px 0px 3px #142a4c;
  border-radius: 10px;
  margin-top: 6px;
  max-height: 200px;
  overflow-y: auto;
`

const PlaceItem = styled.span`
  display: flex;
  align-items: center;
  color: ${Colors.YETIC_BLUE_DARK};
  padding: 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.NEW_YETIC_GREY};
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const PlaceLabel = styled.span`
  margin-right: ${Margin.m2};
`

type LocationProps = {
  selectedTags: LocationData[]
  removeTag: (index: number) => void
  addNewTag: (tag: LocationData) => void
  data: LocationData[]
  error?: string
}

export function convertLocationDataToString(locationData: LocationData) {
  return `${locationData.canonicalName}`
}

const replaceSpecialCharacters = (string: string) =>
  string
    .toLowerCase()
    .replace(/[àâ]/g, 'a')
    .replace(/[ç]/g, 'c')
    .replace(/[éèêë]/g, 'e')
    .replace(/[îï]/g, 'i')
    .replace(/[ôö]/g, 'o')
    .replace(/[ùûü]/g, 'u')
    .replace(/['-_ /;,\s]+/g, ' ')

const Location = ({
  selectedTags,
  removeTag,
  addNewTag,
  data,
  error
}: LocationProps) => {
  const [inputValue, setInputValue] = useState('')
  const [showList, setShowList] = useState(false)

  const placeContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        placeContainerRef.current &&
        !placeContainerRef.current.contains(event.target as Node)
      ) {
        setShowList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (selectedTags.length > 0) {
      setInputValue('')
    }
  }, [selectedTags])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setShowList(true)
  }

  const filteredData = data.filter((item) => {
    const itemString = replaceSpecialCharacters(item.canonicalName)
    const inputRegex = new RegExp(`^${replaceSpecialCharacters(inputValue)}`)
    return inputRegex.test(itemString) && !selectedTags.includes(item)
  })

  return (
    <DivContainer>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Pays, région, département, ville etc..."
        label="Zones géographiques"
        error={error}
        withBorder
      />
      {showList && inputValue && (
        <PlaceContainer ref={placeContainerRef}>
          {filteredData.map((item, index) => (
            <PlaceItem
              key={index}
              onClick={() => {
                addNewTag(item)
              }}
            >
              <PlaceLabel>
                {item.canonicalName.replaceAll(',', ', ')}
              </PlaceLabel>
            </PlaceItem>
          ))}
        </PlaceContainer>
      )}

      <Tags
        labels={selectedTags.map((tag) =>
          tag.canonicalName.replaceAll(',', ', ')
        )}
        removeElements={removeTag}
      />
    </DivContainer>
  )
}

export default Location
