export enum VerifyTokenErrorEnum {
  MISSING_PARAMS = 'MISSING_PARAMS',
  UNKNOWN = 'UNKNOWN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_NOT_RECEIVED = 'TOKEN_NOT_RECEIVED'
}

export const VerifyTokenErrorTranslation: Record<VerifyTokenErrorEnum, string> =
  {
    [VerifyTokenErrorEnum.MISSING_PARAMS]: `Le token n'a pas été reçu.`,
    [VerifyTokenErrorEnum.UNKNOWN]: `Un erreur inconnue est survenue 
                                    durant la vérification du token.`,
    [VerifyTokenErrorEnum.USER_NOT_FOUND]: `Aucun utilisateur correspondant 
                                            à ce token n'a été trouvé.`,
    [VerifyTokenErrorEnum.INTERNAL_SERVER_ERROR]: `Le serveur a rencontré une 
                                      erreur durant la vérification du token.`,
    [VerifyTokenErrorEnum.TOKEN_EXPIRED]: `Le token a expiré.`,
    [VerifyTokenErrorEnum.TOKEN_NOT_RECEIVED]: `Le token n'a pas été reçu.`
  }

export const checkIfVerifyTokenErrorCodeValid = (
  errorCode: string
): errorCode is VerifyTokenErrorEnum => {
  return Object.keys(VerifyTokenErrorEnum).includes(errorCode)
}
