import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'
import { backendURL } from '../../../constants/urls'
import {
  PauseCampaignErrorEnum,
  PauseCampaignErrorTranslation,
  checkIfPauseCampaignErrorCodeValid
} from '../../../types/error/campaign/pauseAndRestart'

export type PauseCampaignOutputType = {
  campaignId: string
}

export type PauseCampaignErrorType = {
  error: string
}

export type PauseCampaignInput = {
  campaignId: string
}

export const pauseCampaign = async ({
  campaignId
}: PauseCampaignInput): Promise<
  PauseCampaignOutputType | PauseCampaignErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/campaigns/${campaignId}/pause`,
      data: {}
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (checkIfPauseCampaign(response.data)) {
        return response.data
      }
    }

    const errorCode =
      (response as any)?.error.data?.code ?? (response as any)?.error.code
    if (errorCode && checkIfPauseCampaignErrorCodeValid(errorCode)) {
      return { error: PauseCampaignErrorTranslation[errorCode] }
    }

    return {
      error: PauseCampaignErrorTranslation[PauseCampaignErrorEnum.UNKNOWN]
    }
  } catch (_) {
    return {
      error: PauseCampaignErrorTranslation[PauseCampaignErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfPauseCampaign = (
  result: any
): result is PauseCampaignOutputType => {
  return result && result.campaignId && typeof result.campaignId === 'string'
}
