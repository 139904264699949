import styled from '@emotion/styled'

import Colors from '../../../../../constants/colors'
import Padding from '../../../../../constants/padding'

export const Wrapper = styled.div`
  background-color: ${Colors.NEW_YETIC_GREY};
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 400px;
  height: 500px;
  overflow: hidden;
  box-shadow: 0px 0px 25px #142a4c40;

  display: flex;
  flex-direction: column;
`

export const CloseButton = styled.button`
  padding: 5px;
  border-radius: 100px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(30%, -30%);
  z-index: 1;
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.YETIC_BLUE_DARK};
  margin: 25px 0;
`

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  height: 100%;
`

export const PageContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 ${Padding.p5} ${Padding.p5};

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.NEW_YETIC_BLUE_LIGHT};
    border-radius: 4px;
  }
`
