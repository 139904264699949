import React from 'react'

import { TableListDataType } from '.'
import { NoResult, TableListRow, TableListRowsContainer } from './styles'
import TableListRowContent from './TableListRowContent'
import OpenRowDetails from './OpenRowDetails'
import { LabelledItemType } from '../LabelledItem'
import { ActionItem } from '../ActionsButton'

type TableListContentProps = {
  data: TableListDataType
  actions?: {
    label: string
    handleAction: (id: string) => void
  }[]
  handleClick: (id: string) => void
  seeMoreText?: string
  handleSeeMoreSubItem?: (id: string) => void
  noRowOpen?: string
  subtitle?: string
}

const TableListContent = ({
  data,
  actions,
  handleClick,
  seeMoreText,
  handleSeeMoreSubItem,
  noRowOpen,
  subtitle
}: TableListContentProps) => {
  if (data.items.length === 0) {
    return <NoResult>Aucun résultat</NoResult>
  }

  return (
    <TableListRowsContainer>
      {data.items.map((item) => {
        return (
          <div key={item.id} style={{ width: '100%' }}>
            <TableListRow rowType={item.type} selected={noRowOpen === item.id}>
              <TableListRowContent
                widths={data.widths}
                item={item}
                actions={(item.actions ?? actions)?.map((action) => {
                  return {
                    ...action,
                    handleAction: () => {
                      action.handleAction(item.id)
                    }
                  }
                })}
                handleClick={handleClick}
                noRowOpen={noRowOpen}
                rowType={item.type}
              />
            </TableListRow>

            {noRowOpen === item.id && item.details && (
              <OpenRowDetails
                id={item.id}
                items={item.details}
                subtitle={subtitle}
                subItems={item.subItems ?? []}
                seeMoreText={seeMoreText}
                handleSeeMore={handleSeeMoreSubItem}
              />
            )}
          </div>
        )
      })}
    </TableListRowsContainer>
  )
}

export default TableListContent
