import { ElementItem } from '../../../../../components/Table'
import { GetCampaignAdsReportingOutputType } from '../../../../../types/reporting/campaign/output'

type FormatAdsDataOutput = {
  tableData: ElementItem[]
}

export const formatAdsData = (
  data: GetCampaignAdsReportingOutputType
): FormatAdsDataOutput => {
  const tableData = data.ads.map((adData) => {
    const { adName, clicks, impressions } = adData

    return {
      id: adName,
      adName,
      clicks,
      impressions
    }
  })

  return { tableData }
}
