import {
  BarChartData,
  Point as BarChartPoint
} from '../../../../../components/Chart/BarChart'
import {
  HeatmapData,
  Point as HeatmapPoint
} from '../../../../../components/Chart/Heatmap'
import {
  LineChartData,
  Point as LineChartPoint,
  Point
} from '../../../../../components/Chart/LineChart/LineChartDrawer'
import Colors from '../../../../../constants/colors'
import { AnalyticsCaptionWordingEnum } from '../../../../../types/analytics/captionsWording'
import { LineChartAggregatorEnum } from '../../../../../types/lineChart'
import {
  AgeRangeEnum,
  getAgeRangeFromEnum
} from '../../../../../types/reporting/age'
import { GetCampaignOverallReportingOutputType } from '../../../../../types/reporting/campaign/output'

type FormatOverallDataOutput = {
  kpiLineChartData: LineChartData<Point>
  genderAgeHeatmapData: HeatmapData<HeatmapPoint>
  performancesPerDeviceBarchartData: BarChartData<BarChartPoint>[]
  globalCTR?: number
}

export const formatOverallData = (
  data: GetCampaignOverallReportingOutputType
): FormatOverallDataOutput => {
  const totalClicks = data.kpi.clicks.reduce(
    (acc, click) => acc + click.value,
    0
  )
  const totalImpressions = data.kpi.impressions.reduce(
    (acc, impression) => acc + impression.value,
    0
  )
  const globalCTR =
    totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0

  const kpiLineChartData: LineChartData<Point> = [
    {
      name: 'Clics',
      color: 'blue',
      values: data.kpi.clicks.map((click) => ({
        time: new Date(click.day),
        value: click.value
      })),
      caption: 'nombre de clics',
      isAugmentationGood: true
    },
    {
      name: 'Impressions',
      color: 'deeppink',
      values: data.kpi.impressions.map((impression) => ({
        time: new Date(impression.day),
        value: impression.value
      })),
      caption: AnalyticsCaptionWordingEnum.IMPRESSIONS,
      isAugmentationGood: true
    },
    {
      name: 'CTR',
      color: 'green',
      unit: '%',
      values: data.kpi.ctr.map((ctr) => ({
        time: new Date(ctr.day),
        value: ctr.value
      })),
      caption: AnalyticsCaptionWordingEnum.CTR,
      isAugmentationGood: true,
      aggregator: LineChartAggregatorEnum.AVERAGE
    },
    {
      name: 'Coût',
      unit: '€',
      color: 'orange',
      values: data.kpi.cost.map((cost) => ({
        time: new Date(cost.day),
        value: cost.value
      })),
      caption: 'coût',
      isAugmentationGood: false
    }
  ]

  const genderAgeHeatmapData: HeatmapData<HeatmapPoint> = [
    {
      name: 'Clics',
      categories: Object.values(AgeRangeEnum)
        .filter((el) => el !== AgeRangeEnum.default)
        .map((ageRange) => ({
          x: getAgeRangeFromEnum(ageRange),
          values: [
            {
              y: 'Femmes',
              value:
                data.genderAge.clicks.find(
                  (row) => row.age === ageRange && row.gender === 'female'
                )?.value !== undefined
                  ? data.genderAge.clicks.find(
                      (row) => row.age === ageRange && row.gender === 'female'
                    )?.value ?? 0
                  : -1
            },
            {
              y: 'Hommes',
              value:
                data.genderAge.clicks.find(
                  (row) => row.age === ageRange && row.gender === 'male'
                )?.value !== undefined
                  ? data.genderAge.clicks.find(
                      (row) => row.age === ageRange && row.gender === 'male'
                    )?.value ?? 0
                  : -1
            }
          ]
        }))
    },
    {
      name: 'Impressions',
      categories: Object.values(AgeRangeEnum).map((ageRange) => ({
        x: getAgeRangeFromEnum(ageRange),
        values: [
          {
            y: 'Femmes',
            value:
              data.genderAge.impressions.find(
                (row) => row.age === ageRange && row.gender === 'female'
              )?.value !== undefined
                ? data.genderAge.impressions.find(
                    (row) => row.age === ageRange && row.gender === 'female'
                  )?.value ?? 0
                : -1
          },
          {
            y: 'Hommes',
            value:
              data.genderAge.impressions.find(
                (row) => row.age === ageRange && row.gender === 'male'
              )?.value !== undefined
                ? data.genderAge.impressions.find(
                    (row) => row.age === ageRange && row.gender === 'male'
                  )?.value ?? 0
                : -1
          }
        ]
      }))
    }
  ]

  const performancesPerDeviceBarchartData: BarChartData<BarChartPoint>[] = [
    {
      name: 'Clics',
      color: 'blue',
      values: [
        {
          category: 'Smartphone',
          value: data.devices.smartphone.clicks
        },
        {
          category: 'Tablette',
          value: data.devices.tablet.clicks
        },
        {
          category: 'Ordinateur',
          value: data.devices.desktop.clicks
        },
        {
          category: 'TV',
          value: data.devices.tv.clicks
        }
      ]
    },
    {
      name: 'Impressions',
      color: 'deeppink',
      values: [
        {
          category: 'Smartphone',
          value: data.devices.smartphone.impressions
        },
        {
          category: 'Tablette',
          value: data.devices.tablet.impressions
        },
        {
          category: 'Ordinateur',
          value: data.devices.desktop.impressions
        },
        {
          category: 'TV',
          value: data.devices.tv.impressions
        }
      ]
    },
    {
      name: 'Coût',
      color: 'orange',
      unit: '€',
      values: [
        {
          category: 'Smartphone',
          value: data.devices.smartphone.cost
        },
        {
          category: 'Tablette',
          value: data.devices.tablet.cost
        },
        {
          category: 'Ordinateur',
          value: data.devices.desktop.cost
        },
        {
          category: 'TV',
          value: data.devices.tv.cost
        }
      ]
    },
    {
      name: 'CO₂',
      color: Colors.NEW_YETIC_CO2,
      unit: 'g',
      values: [
        {
          category: 'Smartphone',
          value: data.devices.smartphone.co2
        },
        {
          category: 'Tablette',
          value: data.devices.tablet.co2
        },
        {
          category: 'Ordinateur',
          value: data.devices.desktop.co2
        },
        {
          category: 'TV',
          value: data.devices.tv.co2
        }
      ]
    }
  ]

  return {
    kpiLineChartData,
    genderAgeHeatmapData,
    performancesPerDeviceBarchartData,
    globalCTR: Math.round(globalCTR * 100) / 100 ?? 0
  }
}
