import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import { GetCampaignHighlightReportingOutputType } from '../../../../types/reporting/campaign/output'

type GetCampaignHighlightReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignHighlightReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignHighlightReportingInputType): Promise<GetCampaignHighlightReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/highlight${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignHighlightReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignHighlightReporting = (
  val: any
): val is GetCampaignHighlightReportingOutputType => {
  return (
    val &&
    val.clicks !== undefined &&
    typeof val.clicks === 'number' &&
    val.impressions !== undefined &&
    typeof val.impressions === 'number' &&
    val.co2 !== undefined &&
    typeof val.co2 === 'number' &&
    val.cost !== undefined &&
    typeof val.cost === 'number' &&
    val.impact !== undefined &&
    typeof val.impact === 'number'
  )
}
