import { backendURL } from '../../../constants/urls'
import {
  InseeApiError,
  InseeApiErrorTranslation
} from '../../../types/error/inseeApiError'
import { getErrorCode } from '../../getErrorCode'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type CompanyInfo = {
  name: string
  activity: string
  vat: string
  address: string
}

export type GetCompanyInfoOutputType = CompanyInfo | { error: string }

export const getInseeCompanyInfo = async (
  siret: string
): Promise<GetCompanyInfoOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/companies?siret=${siret.replaceAll(' ', '')}`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCompanyInfo(response.data)) {
        return response.data
      }
    }
    return { error: InseeApiErrorTranslation[InseeApiError.API_ERROR] }
  } catch (error) {
    const errorCode = getErrorCode(error) ?? null
    if (errorCode && Object.values<string>(InseeApiError).includes(errorCode)) {
      return { error: InseeApiErrorTranslation[errorCode as InseeApiError] }
    }
    return { error: InseeApiErrorTranslation[InseeApiError.API_ERROR] }
  }
}

export const checkIfCompanyInfo = (value: any): value is CompanyInfo => {
  return (
    value &&
    value.name &&
    typeof value.name === 'string' &&
    value.activity &&
    typeof value.activity === 'string' &&
    value.vat &&
    typeof value.vat === 'string' &&
    value.address &&
    typeof value.address === 'string'
  )
}
