import React, { CSSProperties } from 'react'

import { CheckBoxContainer } from './styles'

type CheckboxItem = {
  checkbox: React.ReactNode
  selected: boolean
}

type CheckboxListProps = {
  values: CheckboxItem[]
  hasBorder?: boolean
  transparent?: boolean
  style?: CSSProperties
  withBorder?: boolean
  height?: string
}

const CheckboxList = ({
  values,
  style,
  height,
  withBorder
}: CheckboxListProps) => {
  return (
    <div>
      {values.map((item, index) => (
        // TODO key index
        <CheckBoxContainer
          key={index}
          style={{
            ...(style ?? undefined)
          }}
          withBorder={withBorder}
          height={height}
        >
          {item.checkbox}
        </CheckBoxContainer>
      ))}
    </div>
  )
}

export default CheckboxList
