import { checkIfFormatApiEnum } from '../../format'
import { checkIfStatus } from '../../status'
import { AdNavigation, AdType } from '../ad'

export type TextAdType = {
  id: string
  url: string
  title1: string
  title2: string
  title3: string
  title4?: string
  title5?: string
  title6?: string
  title7?: string
  title8?: string
  description1: string
  description2: string
  description3?: string
}

export type FullTextAdType = AdType & Omit<TextAdType, 'id'>

export type TextAdDetails = FullTextAdType & {
  startDate?: string
  feedback?: string
  navigation: AdNavigation
}

export type TextAdUpdate = {
  adId: string
  title1: string
  title2: string
  title3: string
  title4?: string
  title5?: string
  title6?: string
  title7?: string
  title8?: string
  description1: string
  description2: string
  description3?: string
}

export const checkIfTextAdArray = (data: any): data is TextAdType[] => {
  return (
    Array.isArray(data) && data.every((textAd: any) => checkIfTextAd(textAd))
  )
}

export const checkIfTextAd = (value: any): value is TextAdType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.title1 &&
    typeof value.title1 === 'string' &&
    value.title2 &&
    typeof value.title2 === 'string' &&
    value.title3 &&
    typeof value.title3 === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    value.description2 &&
    typeof value.description2 === 'string' &&
    (value.description3 == null || typeof value.description3 === 'string') &&
    (value.startDate == null || typeof value.startDate === 'string')
  )
}

export const checkIfTextAdDetails = (value: any): value is TextAdDetails => {
  return (
    value.name &&
    typeof value.name === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.title1 &&
    typeof value.title1 === 'string' &&
    value.title2 &&
    typeof value.title2 === 'string' &&
    value.title3 &&
    typeof value.title3 === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    value.description2 &&
    typeof value.description2 === 'string' &&
    (!value.description3 || typeof value.description3 === 'string') &&
    value.status &&
    checkIfStatus(value.status)
  )
}

export const checkIfTextAdUpdate = (value: any): value is TextAdUpdate => {
  return (
    value &&
    value.adId &&
    typeof value.adId === 'string' &&
    value.title1 &&
    typeof value.title1 === 'string' &&
    value.title2 &&
    typeof value.title2 === 'string' &&
    value.title3 &&
    typeof value.title3 === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    value.description2 &&
    typeof value.description2 === 'string' &&
    (value.description3 === undefined || typeof value.description3 === 'string')
  )
}

export const checkIfFullTextAd = (value: any): value is FullTextAdType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    checkIfStatus(value.status) &&
    value.url &&
    typeof value.url === 'string' &&
    value.title1 &&
    typeof value.title1 === 'string' &&
    value.title2 &&
    typeof value.title2 === 'string' &&
    value.title3 &&
    typeof value.title3 === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    value.description2 &&
    typeof value.description2 === 'string' &&
    (value.description3 === undefined || typeof value.description3 === 'string')
  )
}
