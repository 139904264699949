import { ReportingAPISectionEnum } from './apiSection'

export type ReportingTabItem = {
  key: ReportingTabKeyEnum
  name: string
  fetch?: ReportingAPISectionEnum
}

export enum ReportingTabKeyEnum {
  overall = 'overall',
  recommendations = 'recommendations',
  clickImpressionCost = 'clickImpressionCost',
  keywords = 'keywords',
  devices = 'devices',
  audiences = 'audiences',
  ads = 'ads',
  impact = 'impact',
  campaigns = 'campaigns'
}

export const getReportingTabKeyEnumTranslation = (
  key: ReportingTabKeyEnum
): string => {
  switch (key) {
    case ReportingTabKeyEnum.overall:
      return "Vue d'ensemble"
    case ReportingTabKeyEnum.impact:
      return 'Impact'
    case ReportingTabKeyEnum.recommendations:
      return 'Recommandations'
    case ReportingTabKeyEnum.clickImpressionCost:
      return 'Clic / Impression / Coût'
    case ReportingTabKeyEnum.keywords:
      return 'Mots clés'
    case ReportingTabKeyEnum.devices:
      return 'Appareils'
    case ReportingTabKeyEnum.audiences:
      return 'Audiences'
    case ReportingTabKeyEnum.ads:
      return 'Annonces'
    case ReportingTabKeyEnum.campaigns:
      return 'Campagnes'
    // TODO always have a default in a switch
  }
}
