import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const Page = styled.div`
  width: 100%;
`

export const HeaderDiv = styled.div`
  marginbottom: ${Margin.m7};
  padding: ${Padding.p7};
  width: 80%;
  margin: auto;
`

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-items: center;
`
