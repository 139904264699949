import axios from 'axios'

import { backendURL } from '../../../../constants/urls'
import {
  CreatePasswordErrorEnum,
  CreatePasswordErrorTranslation
} from '../../../../types/error/auth/password'
import { getErrorCode } from '../../../getErrorCode'

export const handleCreatePassword = async (
  token: string,
  password: string
): Promise<{ userId: string } | { error: string }> => {
  try {
    const url = `${backendURL}/password/init/${token}`
    const response = await axios.put(url, { password })

    if (checkIfHandleCreatePasswordResult(response.data)) {
      return response.data
    }

    return { error: CreatePasswordErrorEnum.UNKNOWN }
  } catch (error) {
    const errorCode = getErrorCode(error) ?? null

    if (
      errorCode &&
      Object.values<string>(CreatePasswordErrorEnum).includes(errorCode)
    ) {
      return {
        error:
          CreatePasswordErrorTranslation[errorCode as CreatePasswordErrorEnum]
      }
    }

    return {
      error: CreatePasswordErrorTranslation[CreatePasswordErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfHandleCreatePasswordResult = (
  data: any
): data is { userId: string } => {
  return data && data.userId && typeof data.userId === 'string'
}
