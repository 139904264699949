import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import routes from './routes'
import { AppContext, PartialAppContextType } from './contexts/AppContext'

const App = () => {
  const [user, setUser] = useState<PartialAppContextType | null>(null)

  return (
    <AppContext.Provider
      value={{
        user: user?.user,
        setUser,
        isEditor: user?.isEditor ?? null,
        isSelfEditor: user?.isSelfEditor ?? null,
        companyId: user?.companyId ?? null,
        companyName: user?.companyName ?? null
      }}
    >
      <BrowserRouter>
        <Routes>
          {routes.map((element, index) => {
            return (
              <Route
                path={element.path}
                element={element.component}
                key={element.path + index}
              />
            )
          })}
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App
