import React from 'react'

import { IconType } from '../../types/icon'

const DevicesIcon = ({
  height = '36px',
  width = '36px',
  style,
  color = '#fff'
}: IconType) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 36 36"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <title>devices-solid</title>{' '}
        <path
          className="clr-i-solid clr-i-solid-path-1"
          d="M32,13H24a2,2,0,0,0-2,2V30a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V15A2,2,0,0,0,32,13Zm0,2V28H24V15Z"
        ></path>
        <path
          className="clr-i-solid clr-i-solid-path-2"
          d="M28,4H4A2,2,0,0,0,2,6V22a2,2,0,0,0,2,2h8v2H9.32A1.2,1.2,0,0,0,8,27a1.2,1.2,0,0,0,1.32,1H19.92v-.37H20V22H4V6H28v5h2V6A2,2,0,0,0,28,4Z"
        ></path>{' '}
        <rect x="0" y="0" width={width} height={height} fillOpacity="0"></rect>{' '}
      </g>
    </svg>
  )
}

export default DevicesIcon
