export enum GenderEnum {
  male = 'male',
  female = 'female',
  undetermined = 'undetermined'
}

export const getGenderFromEnum = (value: GenderEnum): string => {
  switch (value) {
    case GenderEnum.male:
      return 'male'
    case GenderEnum.female:
      return 'female'
    case GenderEnum.undetermined:
      return 'undetermined'
    default:
      return 'undetermined'
  }
}

export const getGenderEnumList = (): GenderEnum[] => {
  return [GenderEnum.male, GenderEnum.female, GenderEnum.undetermined]
}
