// TODO : check linkedin translation
export enum LinkedinCTA {
  APPLY = 'Postuler',
  DOWNLOAD = 'Télécharger',
  VIEW_QUOTE = 'Voir la citation',
  LEARN_MORE = 'En savoir plus',
  SIGN_UP = "S'inscrire",
  SUBSCRIBE = "S'abonner",
  REGISTER = "S'inscrire",
  JOIN = 'Rejoindre',
  ATTEND = 'Participer',
  REQUEST_DEMO = 'Demander une démo'
}

export const checkIfLinkedinCTA = (value: any): value is LinkedinCTA => {
  return (
    value &&
    typeof value === 'string' &&
    (Object.values(LinkedinCTA) as string[]).includes(value)
  )
}

export const linkedinCTAOptions = Object.values(LinkedinCTA).map(
  (cta, index) => ({
    id: index,
    label: cta
  })
)
