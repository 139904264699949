export enum RejectMediaPlanErrorEnum {
  UNKNOWN = 'UNKNOWN',
  MISSING_PARAMS = 'MISSING_PARAMS',
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_REJECTED = 'ALREADY_REJECTED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_MATCHING = 'USER_NOT_MATCHING'
}

export const RejectMediaPlanErrorTranslation = {
  [RejectMediaPlanErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [RejectMediaPlanErrorEnum.MISSING_PARAMS]: 'Des paramètres sont manquants',
  [RejectMediaPlanErrorEnum.NOT_FOUND]: 'Le media plan est introuvable',
  [RejectMediaPlanErrorEnum.ALREADY_REJECTED]:
    'Ce média plan a déjà été rejeté',
  [RejectMediaPlanErrorEnum.USER_NOT_FOUND]:
    "Vos données utilisateur n'ont pas été trouvées",
  [RejectMediaPlanErrorEnum.USER_NOT_MATCHING]:
    'Vos données utilisateur ne correspondent pas à celles du média plan'
}

export const checkIfRejectMediaPlanErrorCodeValid = (
  errorCode: string
): errorCode is RejectMediaPlanErrorEnum => {
  return Object.keys(RejectMediaPlanErrorEnum).includes(errorCode)
}
