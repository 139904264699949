export enum GetWidgetPageErrorEnum {
  MISSING_PARAMS = 'At least one param is missing',
  UNKNOWN = 'An unknown error has occured',
  AUTH_FAILED = 'The authentication failed',
  PAGE_NOT_FOUND = 'The widget page could not be found'
}

export enum GetWidgetPageErrorEnumFromApi {
  'MISSING_PARAMS' = 'At least one param is missing',
  'UNKNOWN' = 'An unknown error has occured',
  'AUTH_FAILED' = 'The authentication failed',
  'PAGE_NOT_FOUND' = 'The widget page could not be found'
}

export const getWidgetPageErrorCodes: (keyof typeof GetWidgetPageErrorEnum)[] =
  ['UNKNOWN', 'MISSING_PARAMS', 'AUTH_FAILED', 'PAGE_NOT_FOUND']

export const checkIfGetWidgetPageErrorCodeValid = (
  errorCode: string
): errorCode is keyof typeof GetWidgetPageErrorEnum => {
  return Object.keys(GetWidgetPageErrorEnumFromApi).includes(errorCode)
}

export const GetWidgetPageErrorEnumTranslation = {
  [GetWidgetPageErrorEnum.AUTH_FAILED]: `L'authentification a échoué`,
  [GetWidgetPageErrorEnum.MISSING_PARAMS]: `Au moins un paramètre est manquant`,
  [GetWidgetPageErrorEnum.PAGE_NOT_FOUND]: `La page demandée n'a pas été trouvée`,
  [GetWidgetPageErrorEnum.UNKNOWN]: `Une erreur inconnue est survenue.`
}
