import React, { ReactNode, useEffect, useRef, useState } from 'react'

import calcRightDistance from './helpers/calcRightDistance'
import { WidgetWrapper } from './styles'
import WidgetOpenButton from './OpenButton'
import WidgetContent from './Content'
import { getWidgetPage } from '../../../helpers/queries/widget/getPage'
import { WidgetPageType, checkIfWidgetPageType } from '../../../types/widget'
import getWidgetRootPage from '../../../helpers/queries/widget/getRoot'
import { HelpWidgetContext } from '../../../contexts/HelpWidgetContext'

type PropsType = {
  openWording?: string
  closeWording?: string
  children: ReactNode
}

const Widget = ({
  openWording = `Besoin d'aide ?`,
  closeWording = `Fermer`,
  children
}: PropsType) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentPageData, setCurrentPageData] = useState<
    WidgetPageType | { error: string } | null
  >(null)
  const [history, setHistory] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    window.addEventListener('resize', setWidgetPosition)

    return () => {
      window.removeEventListener('resize', setWidgetPosition)
    }
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [currentPageData])

  const handleBack = () => {
    getAndSetBackPage()
  }

  const getAndSetBackPage = async () => {
    if (history.length > 1) {
      setLoading(true)
      const previousPageId = history[history.length - 2]

      const res = await getWidgetPage(previousPageId, window.location.pathname)

      setCurrentPageData(res)
      setHistory(history.slice(0, history.length - 1))
    }
  }

  const handleOpenClick = () => {
    setIsOpen(!isOpen)

    if (history.length < 1) {
      setLoading(true)
      getAndSetRootPage()
    }
  }

  const handleCloseClick = () => {
    isOpen && setIsOpen(false)
  }

  const getAndSetRootPage = async () => {
    const res = await getWidgetRootPage({ location: window.location.pathname })

    if (checkIfWidgetPageType(res) && res.page) {
      setHistory([res.page.id])
    }

    setCurrentPageData(res)
  }

  const handleRetry = () => {
    setLoading(true)

    if (history.length < 1) {
      getAndSetRootPage()
    } else {
      const currentPageId = history[history.length - 1]
      getAndSetPage(currentPageId, false)
    }
  }

  const getAndSetPage = async (pageId: string, pushHistory: boolean) => {
    const res = await getWidgetPage(pageId, window.location.pathname)

    if (pushHistory) {
      const newHistory = [...history, pageId]
      setHistory(newHistory)
    }

    setCurrentPageData(res)
  }

  const getAndPushRootInHistory = async () => {
    const res = await getWidgetRootPage({ location: window.location.pathname })

    if (checkIfWidgetPageType(res)) {
      setHistory([...history, res.page.id])

      return true
    } else {
      setCurrentPageData(res)

      return false
    }
  }

  const getAndSetPageFromExt = async (pageId: string) => {
    !open && setIsOpen(true)
    setLoading(true)

    if (history.length < 1) {
      const pushRootResult = await getAndPushRootInHistory()

      if (pushRootResult) {
        getAndSetPage(pageId, true)
      }
    } else {
      getAndSetPage(pageId, true)
    }
  }

  const setWidgetPosition = () => {
    const position = calcRightDistance() + 'px'

    if (wrapperRef.current) {
      wrapperRef.current.style.right = position
    }
  }

  const handleWidgetNavigate = (pageId: string) => {
    setLoading(true)
    getAndSetPage(pageId, true)
  }

  const initialRightPosition = calcRightDistance() + 'px'

  return (
    <>
      {process.env.REACT_APP_HELP_WIDGET_FLAG === '1' && (
        <WidgetWrapper right={initialRightPosition} ref={wrapperRef}>
          <WidgetContent
            isOpen={isOpen}
            loading={loading}
            currentPageData={currentPageData}
            history={history}
            setHistory={setHistory}
            handleBack={handleBack}
            handleCloseClick={handleCloseClick}
            handleRetry={handleRetry}
            handleWidgetNavigate={handleWidgetNavigate}
          />
          <WidgetOpenButton
            openWording={openWording}
            closeWording={closeWording}
            handleOpenClick={handleOpenClick}
            isOpen={isOpen}
          />
        </WidgetWrapper>
      )}
      <HelpWidgetContext.Provider
        value={{
          getAndSetWidgetDataPage: getAndSetPageFromExt,
          isWidgetOpen: isOpen,
          setWidgetOpen: setIsOpen
        }}
      >
        {children}
      </HelpWidgetContext.Provider>
    </>
  )
}

export default Widget
