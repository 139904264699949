import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: auto;
  padding: ${Padding.p8} ${Padding.p11};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 15px;
  text-align: center;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
`

export const ModalContent = styled.div`
  text-align: left;
  margin-top: ${Margin.m7};
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Margin.m7};
  margin: auto;
  margin-top: ${Margin.m7};
  width: 100%;
`
