import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  DocumentLink,
  ChoiceContainer,
  DivContainer,
  DivInputs,
  DivRegisterContainer,
  HiddenInput,
  InputRadioContainer,
  LabelContainer,
  LabelContent,
  LabelTitle,
  StructureContainer,
  StyledInput,
  SubTitle,
  Title,
  infoTextStyle,
  checkboxListStyle,
  ContainerText,
  PassswordCheckContainer
} from './styles'

import Input from '../../../components/Input'
import DefaultText from '../../../components/DefaultText'
import { InputError } from '../../../components/Input/Styles'
import Button from '../../../components/Button'
import {
  DivErrors,
  DivErrorsContainer
} from '../../../templates/CreationPageTemplate/styles'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

import {
  checkIfCompanyInfo,
  getInseeCompanyInfo
} from '../../../helpers/queries/company/getInseeCompanyInfo'
import handleRegister, {
  checkIfRegisterReturnsError
} from '../../../helpers/queries/auth/register'

import { RegisterUserType, emptyRegisterUser } from '../../../types/user'
import {
  RegisterCompanyType,
  emptyRegisterCompany
} from '../../../types/company'
import {
  RegisterErrorEnum as ErrorEnum,
  RegisterErrorTranslation as ErrorTranslation
} from '../../../types/error/auth/register'
import { ErrorOutput } from '../../../types/error/generic'
import AnimatedLoader from '../../../components/AnimatedLoader'
import {
  DivTitle,
  LoginRegisterLink,
  SeparationBar,
  titleTextStyle
} from '../styles'
import { CheckboxListTmp } from '../../../tmpComponents/CheckboxList'
import { backendURL } from '../../../constants/urls'
import Colors from '../../../constants/colors'

type RegisterErrors = {
  firstName: string | null
  lastName: string | null
  email: string | null
  password: string | null
  confirmPassword: string | null
  phoneNumber: string | null
  siren: string | null
  billingAddress: string | null
  companyName: string | null
  activity: string | null
  siret: string | null
  vat: string | null
  terms: string | null
}

type RegisterProps = {
  onLoginClick: () => void
}

const Register = ({ onLoginClick }: RegisterProps) => {
  const [user, setUser] = useState<RegisterUserType>(emptyRegisterUser)
  const [acceptTCU, setAcceptTCU] = useState(false)
  const [acceptTCS, setAcceptTCS] = useState(false)
  const [acceptEthicsCharter, setAcceptEthicsCharter] = useState(false)
  const [acceptDataPrivacy, setAcceptDataPrivacy] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [company, setCompany] =
    useState<RegisterCompanyType>(emptyRegisterCompany)
  const [isSelfEditor, setIsSelfEditor] = useState<boolean>(true)
  const [isValidMessage, setIsValidMessage] = useState<boolean>(false)
  const [errorFromInseeApi, setErrorFromInseeApi] = useState<string>('')
  const [errorFromApi, setErrorFromApi] = useState<string>('')
  const [isSiretErrorDisplayed, setIsSiretErrorDisplayed] =
    useState<boolean>(false)
  const [isGetCompanyInfoLoading, setIsGetCompanyInfoLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const companyType = searchParams.get('companyType')

    if (companyType === 'agency') {
      setIsSelfEditor(false)
    } else {
      setIsSelfEditor(true)
    }
  }, [])

  useEffect(() => {
    if (isGetCompanyInfoLoading) {
      if (!company.siret || errors.siret) {
        setCompany({
          ...company,
          name: '',
          activity: '',
          siren: '',
          vat: '',
          isDataFromApi: !errorFromApi,
          termsOfUse: false,
          salesTerms: false,
          charter: false
        })
        setIsSiretErrorDisplayed(true)
        setIsGetCompanyInfoLoading(false)
      } else {
        ;(async function () {
          const result = await getInseeCompanyInfo(company.siret)
          if (checkIfCompanyInfo(result)) {
            const companyInfo = result
            setCompany({
              ...company,
              name: companyInfo.name,
              activity: companyInfo.activity,
              siren: company.siret.slice(0, 9),
              vat: companyInfo.vat,
              billingAddress: companyInfo.address,
              isDataFromApi: !errorFromApi
            })
          } else {
            setCompany({
              ...company,
              name: '',
              activity: '',
              siren: '',
              vat: ''
            })
            setErrorFromInseeApi(result.error)
            setIsGetCompanyInfoLoading(false)
            return null
          }
          setIsSiretErrorDisplayed(false)
          setErrorFromInseeApi('')

          setIsGetCompanyInfoLoading(false)
        })()
      }
    }
  }, [isGetCompanyInfoLoading])

  useEffect(() => {
    if (isSubmitLoading) {
      ;(async function () {
        if (Object.values(errors).filter((e) => e != null).length < 1) {
          const result = await handleRegister({
            user,
            company: {
              ...company,
              isSelfEditor,
              salesTerms: acceptTCS,
              termsOfUse: acceptTCU,
              charter: acceptEthicsCharter
            }
          })
          if (checkIfRegisterReturnsError(result)) {
            setErrorFromApi((result as ErrorOutput).error)
            setTimeout(() => {
              setErrorFromApi('')
            }, 6000)
          } else {
            setErrorFromApi('')
            setIsValidMessage(true)
          }
        }
        if (!hasBeenSubmitted) {
          setHasBeenSubmitted(true)
        }

        setIsSubmitLoading(false)
      })()
    }
  }, [isSubmitLoading])

  const checkEmail = () => {
    const regex = /^([+a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    return regex.test(user.email)
  }

  const checkPhoneNumber = () => {
    const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
    return !user.phoneNumber || regex.test(user.phoneNumber)
  }

  const checkPassword = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{12,}$/
    return regex.test(user.password)
  }

  const checkFirstName = () => user.firstName.length > 1
  const checkLastName = () => user.lastName.length > 1
  const checkSiren = () => company.siren.length === 9
  const checkBillingAddress = () => company.billingAddress.length > 1
  const checkCompanyName = () => company.name.length > 1
  const checkActivity = () => company.activity.length > 1
  const checkSiret = () => company.siret.length === 14
  const checkVat = () =>
    company.vat.length === 13 && company.vat.startsWith('FR')

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({ ...user, firstName: event.target.value })
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, lastName: event.target.value })
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, email: event.target.value.trim() })
  }

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({ ...user, phoneNumber: event.target.value })
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, password: event.target.value })
  }

  const checkConfirmPassword = () => {
    return user.confirmPassword === user.password
  }

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({ ...user, confirmPassword: event.target.value })
  }

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, role: event.target.value })
  }

  const handleStructureChange = () => {
    setIsSelfEditor(!isSelfEditor)
  }

  const handleSirenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany({ ...company, siren: event.target.value })
  }

  const handleBillingAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompany({ ...company, billingAddress: event.target.value })
  }

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompany({ ...company, name: event.target.value })
  }

  const handleActivityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany({ ...company, activity: event.target.value })
  }

  const handleSiretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany({ ...company, siret: event.target.value })
  }

  const handleVatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany({ ...company, vat: event.target.value })
  }

  const handleTCSChange = () => {
    setAcceptTCS(!acceptTCS)
  }

  const handleTCUChange = () => {
    setAcceptTCU(!acceptTCU)
  }

  const handleEthicsCharterChange = () => {
    setAcceptEthicsCharter(!acceptEthicsCharter)
  }

  const handleAcceptDataPrivacyChange = () => {
    setAcceptDataPrivacy(!acceptDataPrivacy)
  }

  const handleGetCompanyInfo = () => {
    setIsGetCompanyInfoLoading(true)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleKeyDownSiret = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleGetCompanyInfo()
    }
  }

  const isLengthValid = user.password ? user.password.length >= 12 : false
  const hasUpperCase = user.password ? /[A-Z]/.test(user.password) : false
  const hasNumber = user.password ? /\d/.test(user.password) : false
  const hasSpecialChar = user.password
    ? /[!@#$%^&*(),.?":{}|<>]/.test(user.password)
    : false

  const errors: RegisterErrors = {
    firstName: checkFirstName() ? null : ErrorTranslation[ErrorEnum.FIRST_NAME],
    lastName: checkLastName() ? null : ErrorTranslation[ErrorEnum.LAST_NAME],
    email: checkEmail() ? null : ErrorTranslation[ErrorEnum.EMAIL],
    password: checkPassword() ? null : ErrorTranslation[ErrorEnum.PASSWORD],
    confirmPassword: checkConfirmPassword()
      ? null
      : ErrorTranslation[ErrorEnum.CONFIRM_PASSWORD],
    phoneNumber: checkPhoneNumber()
      ? null
      : ErrorTranslation[ErrorEnum.PHONE_NUMBER],
    siren: checkSiren() ? null : ErrorTranslation[ErrorEnum.SIREN],
    billingAddress: checkBillingAddress()
      ? null
      : ErrorTranslation[ErrorEnum.BILLING_ADDRESS],
    companyName: checkCompanyName()
      ? null
      : ErrorTranslation[ErrorEnum.COMPANY_NAME],
    activity: checkActivity() ? null : ErrorTranslation[ErrorEnum.ACTIVITY],
    siret: checkSiret() ? null : ErrorTranslation[ErrorEnum.SIRET],
    vat: checkVat() ? null : ErrorTranslation[ErrorEnum.VAT],
    terms:
      acceptTCU && acceptTCS && acceptEthicsCharter
        ? null
        : ErrorTranslation[ErrorEnum.TERMS]
  }

  const handleSubmit = () => {
    if (!isSubmitLoading) {
      setIsSubmitLoading(true)
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  // TODO refacto needed to move all the view into several files
  const terms = [
    {
      id: 'termsOfUse',
      label: (
        <DefaultText size="small" style={{ margin: '0px' }}>
          J’accepte les{' '}
          <DocumentLink
            href={`${backendURL}/documents/CGU.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            conditions générales d’utilisation
          </DocumentLink>
          *
        </DefaultText>
      ),
      selected: acceptTCU
    },
    {
      id: 'salesTerms',
      label: (
        <DefaultText size="small" style={{ margin: '0px' }}>
          J’accepte les{' '}
          <DocumentLink
            href={`${backendURL}/documents/CGV.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            conditions générales de vente
          </DocumentLink>
          *
        </DefaultText>
      ),
      selected: acceptTCS
    },
    {
      id: 'charter',
      label: (
        <DefaultText size="small" style={{ margin: '0px' }}>
          J’accepte la{' '}
          <DocumentLink
            href={`${backendURL}/documents/Charte-ethique.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            charte éthique
          </DocumentLink>
          *
        </DefaultText>
      ),
      selected: acceptEthicsCharter
    },
    {
      id: 'dataPrivacy',
      label: (
        <ContainerText>
          <DefaultText size="small" style={{ margin: '0px' }}>
            {`J’accepte que mes coordonnées (email et numéro de téléphone) soient utilisées pour me contacter pour raisons relatives 
          au fonctionnement du Yetic Ad Manager et des campagnes publicitaires créées par ce dernier*`}
          </DefaultText>
        </ContainerText>
      ),
      selected: acceptDataPrivacy
    }
  ]

  const handleCheckboxChange = (id: string) => {
    switch (id) {
      case 'termsOfUse':
        handleTCUChange()
        break
      case 'salesTerms':
        handleTCSChange()
        break
      case 'charter':
        handleEthicsCharterChange()
        break
      case 'dataPrivacy':
        handleAcceptDataPrivacyChange()
        break
      default:
        break
    }
  }

  const renderValidationIndicator = (isValid: boolean) =>
    isValid ? (
      <span style={{ color: 'green' }}>✔</span>
    ) : (
      <span style={{ color: 'red' }}>✘</span>
    )

  const companyType = searchParams.get('companyType')

  return (
    <DivRegisterContainer>
      <DivTitle>
        <DefaultText style={titleTextStyle}>Inscription</DefaultText>
      </DivTitle>
      <LoginRegisterLink onClick={onLoginClick}>
        Vous avez déjà un compte ? Connectez-vous
      </LoginRegisterLink>
      <Title>Profil utilisateur</Title>
      <SubTitle>
        Renseignez vos données personnelles afin de commencer à diffuser vos
        annonces publicitaires
      </SubTitle>
      <DivInputs>
        <Input
          type="text"
          label={'Prénom'}
          placeholder="Prénom"
          containerStyle={{ marginBottom: Margin.m6, paddingRight: Padding.p2 }}
          value={user.firstName}
          onChange={handleFirstNameChange}
          onKeyDown={handleKeyDown}
          containerWidth="100%"
          error={hasBeenSubmitted && errors.firstName ? errors.firstName : ''}
        />
        <Input
          type="text"
          label={'Nom'}
          placeholder="Nom de famille"
          containerStyle={{ marginBottom: Margin.m6, paddingLeft: Padding.p2 }}
          value={user.lastName}
          onChange={handleLastNameChange}
          onKeyDown={handleKeyDown}
          containerWidth="100%"
          error={hasBeenSubmitted && errors.lastName ? errors.lastName : ''}
        />
      </DivInputs>
      <Input
        type="text"
        label={'Email'}
        placeholder="Email"
        containerStyle={{ marginBottom: Margin.m6 }}
        value={user.email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown}
        error={hasBeenSubmitted && errors.email ? errors.email : ''}
      />
      <Input
        type="text"
        label={'Numéro de téléphone'}
        placeholder="Numéro de téléphone"
        containerStyle={{ marginBottom: Margin.m6 }}
        value={user.phoneNumber}
        onChange={handlePhoneNumberChange}
        onKeyDown={handleKeyDown}
        isRequired={false}
        error={hasBeenSubmitted && errors.phoneNumber ? errors.phoneNumber : ''}
      />
      <Input
        type="text"
        label={`Poste occupé`}
        placeholder="Poste occupé"
        containerStyle={{ marginBottom: Margin.m6 }}
        value={user.role}
        onChange={handleRoleChange}
        onKeyDown={handleKeyDown}
        isRequired={false}
      />
      <DivInputs>
        <Input
          type="password"
          label={'Mot de passe'}
          placeholder="Mot de passe"
          containerStyle={{ marginBottom: Margin.m2, paddingRight: Padding.p2 }}
          value={user.password}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyDown}
          containerWidth="100%"
          showPassword={showPassword}
          setShowPassword={handleTogglePassword}
        />
        <Input
          type="password"
          label={'Confirmez votre mot de passe'}
          placeholder="Confirmez votre mot de passe"
          containerStyle={{ marginBottom: Margin.m2, paddingLeft: Padding.p2 }}
          value={user.confirmPassword}
          onChange={handleConfirmPasswordChange}
          onKeyDown={handleKeyDown}
          containerWidth="100%"
          showPassword={showConfirmPassword}
          setShowPassword={handleToggleConfirmPassword}
          isRequired={false}
        />
      </DivInputs>

      {!hasBeenSubmitted && (
        <>
          <PassswordCheckContainer>
            <DefaultText
              size="small"
              style={{
                textAlign: 'left',
                opacity: '0.8',
                marginBottom: Margin.m3
              }}
            >
              {renderValidationIndicator(isLengthValid)} Au moins 12 caractères
            </DefaultText>
            <DefaultText
              size="small"
              style={{
                textAlign: 'left',
                opacity: '0.8',
                marginBottom: Margin.m3
              }}
            >
              {renderValidationIndicator(hasUpperCase)} Au moins 1 majuscule
            </DefaultText>
            <DefaultText
              size="small"
              style={{
                textAlign: 'left',
                opacity: '0.8',
                marginBottom: Margin.m3
              }}
            >
              {renderValidationIndicator(hasNumber)} Au moins 1 chiffre
            </DefaultText>
            <DefaultText
              size="small"
              style={{
                textAlign: 'left',
                opacity: '0.8',
                marginBottom: Margin.m3
              }}
            >
              {renderValidationIndicator(hasSpecialChar)} Au moins 1 caractère
              spécial
            </DefaultText>
          </PassswordCheckContainer>
        </>
      )}

      {hasBeenSubmitted && errors.password && (
        <InputError>{errors.password}</InputError>
      )}
      {hasBeenSubmitted && errors.confirmPassword && (
        <InputError>{errors.confirmPassword}</InputError>
      )}
      <SeparationBar />

      <Title>Choix de la structure</Title>
      <StructureContainer>
        <ChoiceContainer>
          <InputRadioContainer>
            <HiddenInput
              type="radio"
              name="structure"
              id="self-editor"
              defaultChecked={companyType !== 'agency'}
              onChange={handleStructureChange}
            />
            <StyledInput />
          </InputRadioContainer>
          <LabelContainer htmlFor="self-editor" checked={isSelfEditor}>
            <LabelTitle>Annonceur</LabelTitle>
            <LabelContent>
              Promouvoir en ligne votre propre entreprise
            </LabelContent>
          </LabelContainer>
        </ChoiceContainer>
        <ChoiceContainer>
          <InputRadioContainer>
            <HiddenInput
              type="radio"
              name="structure"
              id="agency"
              defaultChecked={companyType === 'agency'}
              onChange={handleStructureChange}
            />
            <StyledInput />
          </InputRadioContainer>
          <LabelContainer htmlFor="agency" checked={!isSelfEditor}>
            <LabelTitle>Agence de communication</LabelTitle>
            <LabelContent>
              Gérer des campagnes digitales pour vos clients
            </LabelContent>
          </LabelContainer>
        </ChoiceContainer>
      </StructureContainer>

      <SeparationBar />
      <Title>Profil entreprise</Title>
      <SubTitle>Renseignez vos informations entreprise</SubTitle>
      <DivInputs style={{ justifyContent: 'flex-start' }}>
        <Input
          type="number"
          label={'SIRET'}
          placeholder="90855325808382"
          containerStyle={{ paddingRight: Padding.p2, marginBottom: Margin.m6 }}
          containerWidth="50%"
          value={company.siret}
          onChange={handleSiretChange}
          onKeyDown={handleKeyDownSiret}
          error={
            (hasBeenSubmitted || isSiretErrorDisplayed) && errors.siret
              ? errors.siret
              : ''
          }
        />
        <Button
          type="tertiary"
          style={{
            marginTop: Margin.m7,
            marginLeft: Margin.m5,
            height: '40px',
            width: '80px',
            alignSelf: 'flex-start'
          }}
          onClick={handleGetCompanyInfo}
        >
          {isGetCompanyInfoLoading ? <AnimatedLoader /> : 'Valider'}
        </Button>
      </DivInputs>
      {errorFromInseeApi ? (
        <InputError style={{ marginTop: Margin.m4, marginBottom: Margin.m6 }}>
          {errorFromInseeApi}
        </InputError>
      ) : null}
      <DefaultText style={infoTextStyle}>
        Une fois le SIRET renseigné, vos données entreprise ci-dessous seront
        remplies automatiquement.
      </DefaultText>
      <Input
        type="text"
        label={`Nom de l'entreprise`}
        placeholder={errorFromInseeApi ? "Nom de l'entreprise" : ''}
        containerStyle={{ marginBottom: Margin.m6 }}
        value={company.name}
        isRequired={false}
        disabled={!errorFromInseeApi}
        onChange={handleCompanyNameChange}
        error={hasBeenSubmitted && errors.companyName ? errors.companyName : ''}
      />
      <Input
        type="text"
        label={`Activité`}
        placeholder={errorFromInseeApi ? '73.11Z' : ''}
        containerStyle={
          !errorFromInseeApi
            ? { marginBottom: Margin.m6 }
            : { marginBottom: Margin.m2 }
        }
        value={company.activity}
        isRequired={false}
        disabled={!errorFromInseeApi}
        onChange={handleActivityChange}
        error={hasBeenSubmitted && errors.activity ? errors.activity : ''}
      />
      {errorFromInseeApi && (
        <DefaultText
          size="small"
          style={{ opacity: '0.8', marginBottom: Margin.m6 }}
        >
          {`Veuillez renseigner uniquement le code NAF de l'activité de votre
          entreprise.`}
        </DefaultText>
      )}
      <Input
        type="number"
        label={`SIREN`}
        placeholder={errorFromInseeApi ? '908553258' : ''}
        containerStyle={{ marginBottom: Margin.m6 }}
        value={company.siren}
        isRequired={false}
        disabled={!errorFromInseeApi}
        onChange={handleSirenChange}
        error={hasBeenSubmitted && errors.siren ? errors.siren : ''}
      />
      <Input
        type="text"
        label={`TVA intracom`}
        placeholder={errorFromInseeApi ? 'FR41908553258' : ''}
        containerStyle={{ marginBottom: Margin.m6 }}
        value={company.vat}
        isRequired={false}
        disabled={!errorFromInseeApi}
        onChange={handleVatChange}
        error={hasBeenSubmitted && errors.vat ? errors.vat : ''}
      />
      <Input
        type="text"
        label={`Adresse de facturation`}
        placeholder={`Adresse de facturation`}
        containerStyle={{ marginBottom: Margin.m6 }}
        value={company.billingAddress}
        onChange={handleBillingAddressChange}
        error={
          hasBeenSubmitted && errors.billingAddress ? errors.billingAddress : ''
        }
      />

      <SeparationBar />
      <CheckboxListTmp
        values={terms}
        onChange={handleCheckboxChange}
        error={hasBeenSubmitted && errors.terms ? errors.terms : ''}
        style={checkboxListStyle}
      />
      {errorFromApi && (
        <DivContainer>
          <DivErrorsContainer>
            <DivErrors>{errorFromApi}</DivErrors>
          </DivErrorsContainer>
        </DivContainer>
      )}
      {isValidMessage && (
        <DivContainer>
          <DefaultText bold>
            Votre inscription a été validée ! Merci de consulter votre boîte de
            réception pour confirmer votre adresse e-mail.
          </DefaultText>
        </DivContainer>
      )}
      <Button
        width="170px"
        style={{ marginTop: Margin.m8, marginBottom: Margin.m10 }}
        onClick={() => {
          handleSubmit()
        }}
        disabled={
          hasBeenSubmitted &&
          Object.values(errors).filter((e) => e != null).length > 0
        }
      >
        {isSubmitLoading ? (
          <AnimatedLoader color={Colors.YETIC_WHITE} />
        ) : (
          "S'inscrire"
        )}
      </Button>
    </DivRegisterContainer>
  )
}
export default Register
