import React, { useEffect, useState } from 'react'

import { IconContainer, SyncStateContainer } from './styles'
import SyncIcon from '../Icon/sync'
import Colors from '../../constants/colors'
import CheckIcon from '../Icon/check'
import { CustomTooltip } from '../CustomToolTip'

type SyncStateProps = {
  isSynced: boolean
  onSyncClick: () => Promise<boolean>
}

const SyncState = ({ isSynced, onSyncClick }: SyncStateProps) => {
  const [isSyncing, setIsSyncing] = useState(false)

  useEffect(() => {
    if (isSyncing) {
      ;(async () => {
        const syncResult = await onSyncClick()

        // We wait 500ms to inform the user with the rotate anim that the sync action is done
        setTimeout(() => {
          if (syncResult !== undefined) {
            setIsSyncing(false)
          }
        }, 500)
      })()
    }
  }, [isSyncing])

  const handleClick = () => {
    if (!isSyncing && !isSynced) {
      setIsSyncing(true)
    }
  }

  const tooltipText = 'Votre campagne a été synchronisée avec la plateforme'

  return (
    <SyncStateContainer onClick={handleClick} isSynced={isSynced}>
      <IconContainer isSyncing={isSyncing}>
        {isSynced ? (
          <CheckIcon height="20px" color={Colors.YETIC_WHITE} />
        ) : (
          <SyncIcon height="18px" color={Colors.YETIC_WHITE} />
        )}
      </IconContainer>
      {isSynced ? (
        <CustomTooltip content={tooltipText} hover hasIcon={false} />
      ) : null}
    </SyncStateContainer>
  )
}

export default SyncState
