import React from 'react'

import { ArrowBackContainer, HeaderImg, Wrapper } from './styles'
import DefaultText from '../../../../../components/DefaultText'
import { ArrowBack } from '../../../../../components/Icon'
import Colors from '../../../../../constants/colors'

type PropsType = {
  handleBack: () => void
  history: string[]
}

const WidgetHeader = ({ handleBack, history }: PropsType) => {
  return (
    <Wrapper>
      {history.length > 1 && (
        <ArrowBackContainer onClick={handleBack}>
          <ArrowBack />
        </ArrowBackContainer>
      )}
      <HeaderImg src={'/images/Widget/yetic_logo.webp'} alt="Yeti" />
      <DefaultText
        style={{
          marginBottom: 0,
          color: Colors.YETIC_WHITE,
          backgroundColor: Colors.YETIC_BLUE_DARK,
          padding: '4px 20px',
          borderRadius: '4px'
        }}
        bold
        size="big"
      >
        Aide du Yeti
      </DefaultText>
    </Wrapper>
  )
}

export default WidgetHeader
