import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Container, TextDiv } from './styles'
import DefaultText from '../../components/DefaultText'
import Button from '../../components/Button'
import {
  getCustomersWithBudgets,
  GetCustomersWithBudgetsOutputType
} from '../../helpers/queries/customer/getCustomers'
import TableList, { TableListDataType } from '../../components/TableList'
import { CustomersWithBudgets } from '../../types/customer'
import { buttonStyle } from '../../components/TableList/styles'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import Breadcrumb from '../../components/BreadCrumb'
import { AppContext } from '../../contexts/AppContext'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

const CustomersList = () => {
  const [customers, setCustomers] = useState<CustomersWithBudgets | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { isEditor } = useContext(AppContext)

  useEffect(() => {
    ;(async () => {
      const data = await getCustomersWithBudgets({ skip: undefined })
      if (data != null) {
        setCustomers(data)
      } else {
        setHasFetchError(true)
      }
    })()

    window.scrollTo(0, 0)
  }, [])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!customers) {
    return <LoadingPage />
  }

  const handleClick = () => {
    navigate('/customer/create')
  }

  const handleChangePage = async (page: number) => {
    const data: GetCustomersWithBudgetsOutputType =
      await getCustomersWithBudgets({ skip: undefined })
    if (data != null) {
      setCustomers(data)
    } else {
      setHasFetchError(true)
    }
  }

  const dataList: TableListDataType = {
    titles: [
      'Nom',
      'Budget dépensé',
      'Budget prévu'
      // 'Revenus réalisés',
      // 'Revenus estimés'
    ],
    // widths: ['31%', '19%', '17%', '17%', '16%'],
    widths: ['50%', '25%', '25%'],
    items: []
  }

  if (customers) {
    dataList.items = customers.customers.map((item) => ({
      id: item.id,
      row: [
        item.name,
        `${item.spentBudget} €`,
        `${item.expectedBudget} €`
        // `${item.realizedBenefits} €`,
        // `${item.expectedBenefits} €`
      ]
    }))
  }

  const breadcrumbItems = [
    {
      onClick: () => {
        navigate(`/`)
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate(`/customers`)
      },
      label: 'Clients'
    }
  ]
  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumbItems} />
      {dataList.items.length > 0 ? (
        <Container>
          <TableList
            title="Clients"
            data={dataList}
            itemsByPage={customers.navigation.customersCount}
            handleAdd={handleClick}
            addText="Nouveau client"
            handleOnRowClick={(id) => {
              navigate(`/customer/${id}/details`)
            }}
            totalItems={customers.navigation.customersCount}
            handleChangePage={(page) => {
              handleChangePage(page)
            }}
            currentPage={1}
          ></TableList>
        </Container>
      ) : (
        <TextDiv>
          <DefaultText size="big">{`Vous n'avez aucun client`}</DefaultText>
          <Button style={buttonStyle} onClick={handleClick}>
            Nouveau client
          </Button>
        </TextDiv>
      )}
    </AuthenticatedTemplate>
  )
}

export default CustomersList
