import React from 'react'

import {
  HeaderSelectionContainer,
  SelectionItem,
  SelectionItemName,
  SelectionItemTotalValue,
  totalValueTextStyle
} from './styles'
import { DatasetsSelection } from '../LineChartDrawer'
import DefaultText from '../../../DefaultText'
import Colors from '../../../../constants/colors'

type LineChartHeaderSelectionProps = {
  handleDatasetClick: (datasetIndex: number) => void
  datasets: DatasetsSelection
  width: number
  globalCTR?: number
}

const LineChartHeaderSelection = ({
  handleDatasetClick,
  datasets,
  width
}: LineChartHeaderSelectionProps) => {
  const getTextStyle = (isSelected: boolean, isTotal: boolean) => {
    if (isTotal) {
      return isSelected
        ? {
            color: Colors.YETIC_WHITE,
            ...totalValueTextStyle
          }
        : totalValueTextStyle
    }
    return isSelected ? { color: Colors.YETIC_WHITE } : undefined
  }

  return (
    <HeaderSelectionContainer width={width}>
      {datasets.map((dataset, index) => {
        return (
          <SelectionItem
            key={index}
            isSelected={dataset.isSelected}
            color={dataset.color}
            onClick={() => {
              handleDatasetClick(index)
            }}
          >
            <SelectionItemName>
              <DefaultText style={getTextStyle(dataset.isSelected, false)}>
                {dataset.name}
              </DefaultText>
            </SelectionItemName>
            <SelectionItemTotalValue>
              <DefaultText style={getTextStyle(dataset.isSelected, true)}>
                {dataset.headerKpi !== undefined ? dataset.headerKpi : '-'}{' '}
                {dataset.unit && dataset.unit}
              </DefaultText>
            </SelectionItemTotalValue>
          </SelectionItem>
        )
      })}
    </HeaderSelectionContainer>
  )
}

export default LineChartHeaderSelection
