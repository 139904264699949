import { Gender, isGender } from './gender'
import { isStringArray } from '../helpers/nativeTypes'

export type ShortAudience = {
  name: string
  locations: LocationData[]
}

export type GoogleAudience = ShortAudience & {
  gender: Gender
  minAge: number
  maxAge: number
  interests?: string[]
}

export type LinkedinAudience = ShortAudience & {
  businessSegments: string[]
  jobFunctions: string[]
  jobTitles: string[]
}

export type FullAudience = ShortAudience & {
  gender: Gender
  minAge: number
  maxAge: number
  interests: string[]
  businessSegments?: string[]
  jobFunctions: string[]
  jobTitles: string[]
}

export type MediaPlanAudience =
  | ShortAudience
  | GoogleAudience
  | LinkedinAudience
  | FullAudience

export type LocationData = {
  criteriaId: string
  canonicalName: string
  name: string
}

export const checkIfGoogleAudience = (value: any): value is GoogleAudience => {
  return (
    value &&
    value.gender &&
    isGender(value.gender) &&
    value.minAge &&
    typeof value.minAge === 'number' &&
    value.maxAge &&
    typeof value.maxAge === 'number' &&
    (value.interests === undefined ||
      (value.interests && isStringArray(value.interests)))
  )
}

export const checkIfLinkedinAudience = (
  value: any
): value is LinkedinAudience => {
  return (
    value &&
    value.businessSegments &&
    isStringArray(value.businessSegments) &&
    value.jobFunctions &&
    isStringArray(value.jobFunctions) &&
    value.jobTitles &&
    isStringArray(value.jobTitles)
  )
}

export const checkIfFullAudience = (value: any): value is FullAudience => {
  return (
    value &&
    value.gender &&
    isGender(value.gender) &&
    value.minAge &&
    typeof value.minAge === 'number' &&
    value.maxAge &&
    typeof value.maxAge === 'number' &&
    (value.interests === undefined ||
      (value.interests && isStringArray(value.interests))) &&
    value.businessSegments &&
    isStringArray(value.businessSegments) &&
    value.jobFunctions &&
    isStringArray(value.jobFunctions) &&
    value.jobTitles &&
    isStringArray(value.jobTitles)
  )
}

export const checkIfShortAudience = (value: any): value is ShortAudience => {
  return (
    value &&
    value.name &&
    typeof value.name === 'string' &&
    value.locations &&
    Array.isArray(value.locations)
  )
}

export const checkIfMediaPlanAudience = (
  value: any
): value is MediaPlanAudience => {
  return (
    checkIfShortAudience(value) ||
    checkIfGoogleAudience(value) ||
    checkIfLinkedinAudience(value) ||
    checkIfFullAudience(value)
  )
}
