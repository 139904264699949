import React, { useContext, useState } from 'react'

import DefaultText from '../../../components/DefaultText'
import { AppContext } from '../../../contexts/AppContext'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { modalStyle, modalTextStyle } from '../styles'
import {
  checkIfHandleSendResetPasswordEmailResult,
  handleSendResetPasswordEmail
} from '../../../helpers/queries/auth/password/sendResetPasswordEmail'
import { InputError } from '../../../components/Input/Styles'

type ResetPasswordProps = {
  onClose: () => void
}

const ResetPasswordModal = ({ onClose }: ResetPasswordProps) => {
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const { user } = useContext(AppContext)

  const handleResetPassword = async () => {
    if (!user || !user.email) {
      setError('Une erreur est survenue. Essayez de vous reconnecter.')
      return
    }

    const sendEmailResult = await handleSendResetPasswordEmail(user.email)
    if (checkIfHandleSendResetPasswordEmailResult(sendEmailResult)) {
      setSuccess('Un email vous a bien été envoyé.')
    } else {
      setError(sendEmailResult.error)
    }
  }

  const textReject = success ? 'Fermer' : 'Annuler'

  return (
    <ConfirmationModal
      title="Modifier mon mot de passe"
      onClose={onClose}
      textConfirm="Réinitialiser mon mot de passe"
      textReject={textReject}
      onClickConfirm={() => {
        handleResetPassword()
      }}
      onClickReject={onClose}
      style={modalStyle}
      confirmHidden={!!success}
    >
      <DefaultText size="medium">
        {
          "Vous allez recevoir un lien pour réinitialiser votre mot de passe à l'adresse suivante :"
        }{' '}
        {user?.email}.
      </DefaultText>
      <DefaultText size="medium" style={modalTextStyle}>
        {'Ce lien sera valide 24 heures.'}
      </DefaultText>
      <InputError>{error}</InputError>
      {success && (
        <DefaultText size="medium" bold style={{ textAlign: 'center' }}>
          {success}
        </DefaultText>
      )}
    </ConfirmationModal>
  )
}

export default ResetPasswordModal
