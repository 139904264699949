import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import {
  GetCampaignAudienceAndLocationReportingOutputType,
  GetCampaignAudiencesReportingOutputType,
  checkIfAudienceLocationReportingRowType,
  checkIfGetCampaignAudiencesReportingRowType
} from '../../../../types/reporting/campaign/output'

type GetCampaignAudiencesReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignAudiencesReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignAudiencesReportingInputType): Promise<GetCampaignAudienceAndLocationReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/audiences${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfAudienceLocationAndReportingOutputType(response.data)
    ) {
      return {
        audiences: response.data.audiences,
        locations: response.data.locations
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignAudiencesReporting = (
  value: any
): value is GetCampaignAudiencesReportingOutputType => {
  return (
    value &&
    value.clicks &&
    Array.isArray(value.clicks) &&
    value.clicks.every((value: any) => {
      return checkIfGetCampaignAudiencesReportingRowType(value)
    }) &&
    value.impressions &&
    Array.isArray(value.impressions) &&
    value.impressions.every((value: any) => {
      return checkIfGetCampaignAudiencesReportingRowType(value)
    }) &&
    value.ctr &&
    Array.isArray(value.ctr) &&
    value.ctr.every((value: any) => {
      return checkIfGetCampaignAudiencesReportingRowType(value)
    })
  )
}

export const checkIfAudienceLocationAndReportingOutputType = (
  value: any
): value is GetCampaignAudienceAndLocationReportingOutputType => {
  return (
    value &&
    checkIfGetCampaignAudiencesReporting(value.audiences) &&
    value.locations &&
    Array.isArray(value.locations) &&
    value.locations.every((el: any) =>
      checkIfAudienceLocationReportingRowType(el)
    )
  )
}
