import React from 'react'

import SearchIcon from '../../../components/Icon/search'
import {
  DisplayMobileWebPageTemplate,
  DivContainer,
  DivHeadBanner,
  DivLine,
  DivMenu,
  marginBottomStyle,
  marginTopStyle
} from './styles'

type DisplayWebpageAdTemplateType = {
  children: React.ReactNode
  scale?: number
}

const DisplayAdWebpage = ({
  children,
  scale
}: DisplayWebpageAdTemplateType) => {
  return (
    <DisplayMobileWebPageTemplate scale={scale}>
      <DivHeadBanner>
        <DivMenu />
        <DivLine url />
        <SearchIcon />
      </DivHeadBanner>
      <DivContainer>
        <DivLine />
        <DivLine />
        <DivLine />
        <DivLine small style={marginBottomStyle} />
        {children}
        <DivLine style={marginTopStyle} />
        <DivLine />
        <DivLine />
        <DivLine small />
        <DivLine />
        <DivLine small />
      </DivContainer>
    </DisplayMobileWebPageTemplate>
  )
}

export default DisplayAdWebpage
