import React from 'react'

import DefaultText from '../../DefaultText'
import { DivContainer, descriptionStyle, labelStyle } from './styles'

type CheckboxLabelProps = {
  label: string | JSX.Element
  description?: string
}

const CheckboxLabel = ({ label, description }: CheckboxLabelProps) => {
  return (
    <DivContainer>
      {description ? (
        <>
          <DefaultText style={labelStyle} bold>
            {label}
          </DefaultText>
          <DefaultText style={descriptionStyle} size="small">
            {description}
          </DefaultText>
        </>
      ) : (
        <DefaultText style={labelStyle}>{label}</DefaultText>
      )}
    </DivContainer>
  )
}

export default CheckboxLabel
