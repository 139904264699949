export type OptionLink = {
  title: string
  description1?: string
  description2?: string
  url: string
}

export type FormAdType = {
  name: string
  url: string
  titles: string[]
  descriptions: string[]
  siteLinks: OptionLink[]
  catchphrase?: string[]
}

export enum FormAdError {
  URL = `L'url de l'annonce n'est pas valide`,
  NAME = `Le nom doit faire au moins 2 caractères`,
  TITLE = `Les titres doivent faire au moins 2 caractères`,
  DESCRIPTION = `Les descriptions doivent faire au moins 2 caractères`,
  MISSING_SITE_LINKS = `Vous devez ajouter au moins un lien annexe`,
  SITE_LINKS_TITLE = `Le titre des liens annexes doit faire au moins 2 caractères`,
  SITE_LINKS_URL = `Au moins un url des liens annexes n'est pas valide`
}

export enum SelfEditorFormAdError {
  URL = `L'url de la publicité n'est pas valide`,
  NAME = `Le nom doit faire au moins 2 caractères`,
  TITLE = `Les titres doivent faire au moins 2 caractères`,
  DESCRIPTION = `Les descriptions doivent faire au moins 2 caractères`,
  MISSING_SITE_LINKS = `Vous devez ajouter au moins un lien annexe`,
  SITE_LINKS_TITLE = `Le titre des liens annexes doit faire au moins 2 caractères`,
  SITE_LINKS_URL = `Au moins un url des liens annexes n'est pas valide`
}
