import styled from '@emotion/styled'

import Margin from '../../../../../../constants/margin'
import Padding from '../../../../../../constants/padding'
import { CSSProperties } from 'react'
import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'

export const divStyle: CSSProperties = {
  paddingBottom: Padding.p6,
  paddingTop: Padding.p3,
  boxSizing: 'border-box',
  color: Colors.YETIC_WHITE,
  backgroundColor: '#263238'
}

export const BusinessName = styled.div`
  font-size: 18px;
  margin-top: ${Margin.m2};
`

export const Logo = styled.img`
  width: 67px;
  height: 67px;
  margin-top: ${Margin.m2};
`

export const Title = styled.div`
  font-size: 48px;
  font-weight: 400;
  margin: ${Margin.m5} 0px;
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 270px;
  margin: auto;
`

export const Description = styled.div`
  font-size: 18px;
  margin: ${Margin.m4} 0px;
  height: 110px;
`

export const DivContent = styled.div`
  padding: ${Padding.p4} ${Padding.p10} ${Padding.p8} ${Padding.p6};
  height: 450px;
`

export const Button = styled.button`
  border: 1px solid white;
  border-radius: 60px;
  cursor: pointer;
  padding: 14px 20px;
  font-size: 15px;
  text-transform: uppercase;
  width: 120px;
`

export const ButtonClose = styled(Button)`
  color: ${Colors.YETIC_WHITE};
  background-color: transparent;
`

export const ButtonOpen = styled(Button)`
  background-color: ${Colors.YETIC_WHITE};
  color: black;
`
