import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'
import FontSize from '../../../constants/fontSize'
import FontWeight from '../../../constants/fontWeight'
import { YETIC_FONT_FAMILY } from '../../../constants/fontFamily'
import Padding from '../../../constants/padding'

export const DropdownContainer = styled.div<{
  width?: string
  disabled?: boolean
}>`
  position: relative;
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  background-color: ${Colors.YETIC_WHITE};
  padding: 0 ${Padding.p5};
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
`

export const DropdownButton = styled.button<{
  width?: string
  isOpen: boolean
  disabled?: boolean
}>`
  background-color: ${Colors.YETIC_WHITE};
  border: none;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  width: 100%;
  text-align: left;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  font-family: ${YETIC_FONT_FAMILY};

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${Colors.YETIC_BLUE_DARK};
  z-index: 101;
  margin: 0;
  padding: 0;
`

export const DropDownScroll = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  left: 0px;
  top: 0;
  right: 0;
  background-color: ${Colors.YETIC_WHITE};
  box-shadow: 0px 0px 2px #142a4c;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 10px;
  padding-top: 40px;
  z-index: 100;
`

export const DropdownContent = styled.ul`
  color: white;
  max-height: 200px;
  list-style-type: none;
  margin: 0;
  padding: ${Padding.p4} ${Padding.p4} ${Padding.p2};
  padding-top: 0px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.NEW_YETIC_GREY};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${Colors.YETIC_WHITE};
  }
`

export const OptionItem = styled.li`
  cursor: pointer;
  padding: 0 20px;
  height: 35px;
  margin-bottom: 5px;
  display: flex;
  background-color: ${Colors.YETIC_WHITE};
  color: ${Colors.YETIC_BLUE_DARK};
  border-radius: 10px;
  align-items: center;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  font-weight: ${FontWeight.YETIC_MEDIUM};

  &:hover {
    background-color: ${Colors.NEW_YETIC_GREY};
  }
`

export const DivLabel = styled.div`
  display: flex;
  margin-bottom: ${Margin.m2};
`

export const LabelText = styled.div`
  font-weight: 500;
  font-size: ${FontSize.YETIC_TEXT};
  color: ${Colors.YETIC_BLUE_DARK};
  margin: 0;
  margin-bottom: ${Margin.m2};
`

export const DivContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
`

export const Separation = styled.div`
  margin: 5px 0;
  border-bottom: 1px solid ${Colors.YETIC_GREY_DARK};
`
