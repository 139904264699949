import React from 'react'
import styled from '@emotion/styled'
import Colors from '../../../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../../../constants/fontFamily'
import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import FontWeight from '../../../../constants/fontWeight'

const PanelSubTitle = styled.div`
  color: ${Colors.NEW_YETIC_DARK};
  font-size: ${FontSize.YETIC_PANEL_SUBTITLE};
  font-weight: ${FontWeight.YETIC_MEDIUM};
  font-family: ${YETIC_FONT_FAMILY};
  margin: 0;
  margin-bottom: ${Margin.m3};
`

export default PanelSubTitle
