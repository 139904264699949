export const convertBase64ToUrl = (base64: string, sliceSize = 512): string => {
  const contentTypeMatch = base64.match(/^data:(.*?);base64,/)
  let contentType = 'image/png'

  if (contentTypeMatch && contentTypeMatch[1]) {
    contentType = contentTypeMatch[1]
    base64 = base64.replace(/^data:.*?;base64,/, '')
  }

  try {
    const byteCharacters = atob(base64)
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = Array.from(slice, (char) => char.charCodeAt(0))
      byteArrays.push(new Uint8Array(byteNumbers))
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return URL.createObjectURL(blob)
  } catch (error) {
    console.error(error)
    return ''
  }
}
