import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'
import Colors from '../../../../constants/colors'
import FontSize from '../../../../constants/fontSize'
import Padding from '../../../../constants/padding'

export const SummaryContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${Colors.YETIC_GREY_DARK};
  overflow: hidden;
  margin: auto;
  margin-top: 60px;
`

export const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.NEW_YETIC_GREY_BACKGROUND};
  padding: 0 ${Padding.p6};
  border-bottom: 1px solid ${Colors.YETIC_GREY_DARK};
`

export const SummaryTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${FontSize.YETIC_SUBTITLE};
  padding: ${Padding.p4} 0;
`

export const SummaryTitleIcon = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`

export const SummaryType = styled.div`
  font-size: ${FontSize.YETIC_HOME_BOX_TITLE};
  font-weight: lighter;
  text-transform: none;
  margin: ${Margin.m1} 0;
`

export const CtaContainer = styled.div`
  display: flex;
  gap: ${Margin.m3};
`

export const CO2 = styled.div`
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CO2Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
  position: relative;
`

export const CO2Number = styled.span`
  margin-right: 8px;
  font-weight: bold;
  font-size: 30px;
`

export const SummaryContent = styled.div`
  padding: ${Padding.p1} 0 0 ${Padding.p6};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`

export const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`

export const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Tooltip = styled.div`
  cursor: pointer;
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  font-weight: bold;
  background-color: ${Colors.NEW_YETIC_BOX_INFO_TIP};
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
`
