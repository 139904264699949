import React, { useContext } from 'react'

import { TableListDataType, TableListFilter } from '../../components/TableList'
import KPI from './UI/KPI'
import { DivHeadBanner, Hr } from './styles'
import { AdvertiserHomeDataType } from '../../helpers/queries/getHomeData'
import HomeTableList from './UI/HomeTableList'
import { Status } from '../../types/status'
import { formatDateToString } from '../../helpers/date'
import { BoxInfoProps } from './UI/BoxInfo'
import Benefits from './UI/BoxInfo/Benefits'
import { PieChartProps } from '../../components/ProgressionChart'
import Infos from './UI/BoxInfo/Infos/editor'
import Colors from '../../constants/colors'
import StatusWithColor from '../../components/StatusDot'
import DefaultText from '../../components/DefaultText'
import { AdvertiserItemType } from './types'
import Title from '../../components/Title'
import { AppContext } from '../../contexts/AppContext'

type HomeAdvertiserContentProps = {
  data: AdvertiserHomeDataType
  filteredData: AdvertiserItemType[]
  selectedInProgressType: string
  filters: TableListFilter[]
  dateRangeValue: string
  setDateRangeValue: (newValue: string) => void
  fetchDataOnClickDate: (dateRangeValue: string) => Promise<void>
  handleOnRowClick: (id: string) => void
  totalItems: number
  onChangePage: (page: number) => void
  currentPage: number
}

const HomeAdvertiserContent = ({
  data,
  filteredData,
  selectedInProgressType,
  filters,
  dateRangeValue,
  setDateRangeValue,
  fetchDataOnClickDate,
  handleOnRowClick,
  totalItems,
  onChangePage,
  currentPage
}: HomeAdvertiserContentProps) => {
  const { user } = useContext(AppContext)

  const pieChart: PieChartProps = {
    total: {
      value: data.budget.estimated,
      color: Colors.NEW_YETIC_BOX_INFO_TIP
    },
    partial: { value: data.budget.spent, color: Colors.YETIC_YELLOW_DARK }
  }

  const formattedData: TableListDataType = {
    titles: ['Nom', 'Agence', 'Statut', 'Modifié le'],
    widths: ['31%', '25%', '26%', '18%'],
    items: filteredData.map((item) => ({
      id: item.id,
      type: selectedInProgressType,
      row: [
        item.name,
        item.editorName,
        <StatusWithColor status={item.status as Status} key={item.status} />,
        formatDateToString(new Date(item.updatedAt))
      ]
    }))
  }

  const boxInfos: BoxInfoProps[] = [
    {
      title: 'Dépenses',
      content: (
        <Benefits
          estimatedLabel="Estimées"
          accomplishedLabel="Réalisées"
          pieChart={pieChart}
        />
      )
    },
    {
      title: 'Impact',
      // content: <Impact value={data.co2} />
      content: <DefaultText size="small">En cours de développement</DefaultText>
    },
    {
      title: '',
      content: (
        <Infos
          ads={data.adCount}
          campaigns={data.campaignCount}
          mediaPlans={data.mediaPlanCount}
        />
      ),
      style: {
        justifyContent: 'center'
      }
    }
  ]

  return (
    <>
      <DivHeadBanner>
        <Title style={{ marginBottom: 0 }}>Bonjour, {user?.firstName}.</Title>
      </DivHeadBanner>
      <KPI
        selectedFilter={dateRangeValue}
        setSelectedFilter={(newValue: string) => {
          setDateRangeValue(newValue)
        }}
        boxInfos={boxInfos}
        handleClick={fetchDataOnClickDate}
      />
      <Hr />
      <HomeTableList
        data={formattedData}
        filters={filters}
        handleOnRowClick={handleOnRowClick}
        totalItems={totalItems}
        handleChangePage={onChangePage}
        currentPage={currentPage}
      />
    </>
  )
}

export default HomeAdvertiserContent
