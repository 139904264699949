export function isBoolean(value: any): value is boolean {
  try {
    return ['true', 'false'].includes(value.toString().toLowerCase())
  } catch (_) {
    return false
  }
}

export function isStringArray(values: any): values is string[] {
  return (
    Array.isArray(values) &&
    (values.length === 0 || values.every((val) => typeof val[0] === 'string'))
  )
}

export function canBeInteger(value: string): boolean {
  try {
    return !Number.isNaN(parseInt(value.toString()))
  } catch (_) {
    return false
  }
}

export const checkIfDate = (date: string | Date): boolean => {
  const parsedDate = new Date(date)
  return parsedDate instanceof Date && !isNaN(parsedDate.getTime())
}
