export enum CreatePasswordErrorEnum {
  UNKNOWN = 'UNKNOWN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  MISSING_PARAMS = 'MISSING_PARAMS',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  PASSWORD_ALREADY_CREATED = 'PASSWORD_ALREADY_CREATED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  FAILED_PASSWORD_UPDATE = 'FAILED_PASSWORD_UPDATE',
  FAILED_STATUS_UPDATE = 'FAILED_STATUS_UPDATE'
}

export const CreatePasswordErrorTranslation: Record<
  CreatePasswordErrorEnum,
  string
> = {
  [CreatePasswordErrorEnum.UNKNOWN]: `Une erreur inconnue est survenue`,
  [CreatePasswordErrorEnum.USER_NOT_FOUND]: `Aucun utilisateur correspondant
  à ce token n'a été trouvé.`,
  [CreatePasswordErrorEnum.MISSING_PARAMS]: `Au moins un des paramètres
  (token, password) n'a pas été reçu.`,
  [CreatePasswordErrorEnum.INVALID_PASSWORD]: `Le format du mot de passe
  est incorrect.`,
  [CreatePasswordErrorEnum.PASSWORD_ALREADY_CREATED]: `Votre mot de passe
  a déjà été créé.`,
  [CreatePasswordErrorEnum.INTERNAL_SERVER_ERROR]: `Le serveur a rencontré
  une erreur pendant la création du mot de passe.`,
  [CreatePasswordErrorEnum.TOKEN_NOT_FOUND]: `Le token n'a pas été trouvé.`,
  [CreatePasswordErrorEnum.TOKEN_EXPIRED]: `Le token a expiré.`,
  [CreatePasswordErrorEnum.FAILED_PASSWORD_UPDATE]: `La mise à jour du mot de
  passe a échoué.`,
  [CreatePasswordErrorEnum.FAILED_STATUS_UPDATE]: `La mise à jour du status
  a échoué.`
}

export const checkIfCreatePasswordErrorCodeValid = (
  errorCode: string
): errorCode is CreatePasswordErrorEnum => {
  return Object.keys(CreatePasswordErrorEnum).includes(errorCode)
}

export enum SendResetPasswordEmailErrorEnum {
  MISSING_EMAIL = 'The email was not received',
  INCORRECT_PASSWORD = `The password doesn't respect the rules`,
  USER_NOT_FOUND = 'The user was not found',
  UNKNOWN = 'Unknown error during reset password',
  EMAIL_NOT_SENT = 'Error during the sending of the email'
}

export const SendResetPasswordEmailErrorTranslation: Record<
  SendResetPasswordEmailErrorEnum,
  string
> = {
  [SendResetPasswordEmailErrorEnum.MISSING_EMAIL]: `L'adresse email n'a pas été reçue par le serveur.`,
  [SendResetPasswordEmailErrorEnum.INCORRECT_PASSWORD]: `Le mot de passe ne respecte pas les règles de sécurité.`,
  [SendResetPasswordEmailErrorEnum.USER_NOT_FOUND]: `Aucun utilisateur n'a été trouvé avec cette adresse email.`,
  [SendResetPasswordEmailErrorEnum.UNKNOWN]: `Une erreur inconnue est survenue pendant l'envoi de l'email de réinitialisation du mot de passe.`,
  [SendResetPasswordEmailErrorEnum.EMAIL_NOT_SENT]: `L'email n'a pas pu être envoyé.`
}

export const checkIfSendResetPasswordEmailErrorCodeValid = (
  errorCode: string
): errorCode is SendResetPasswordEmailErrorEnum => {
  return Object.keys(SendResetPasswordEmailErrorEnum).includes(errorCode)
}

export enum UpdatePasswordFormErrorEnum {
  PASSWORD = "Le mot de passe n'est pas valide. Il doit contenir au moins 12 caractères, dont 1 lettre majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial (@, $, !, %, *, ?, &, _, -)",
  EMAIL = "Le format de l'adresse email n'est pas valide.",
  CONFIRM_PASSWORD = 'Les deux mots de passe ne correspondent pas.'
}
