import { getCO2andUnit } from '../../../helpers/getCO2andUnit'
import { GetCampaignReviewOutputType } from '../../../helpers/queries/campaign/getCampaignReview'
import { getBidStrategyEnum } from '../../../types/bidStrategy'
import {
  checkIfGoogleCampaignReviewApi,
  checkIfGoogleImageCampaignReviewApi,
  checkIfGoogleTextCampaignReviewApi
} from '../../../types/campaign/google/googleCampaignReview'
import {
  checkIfLinkedinCampaignReviewApi,
  checkIfLinkedinImageCampaignReviewApi
} from '../../../types/campaign/linkedin/linkedinCampaignReview'
import { CampaignReviewPageDataType } from '../../../types/pages/campaignReview'

export const formatReviewData = (
  data: GetCampaignReviewOutputType
): CampaignReviewPageDataType | null => {
  let newReviewData: CampaignReviewPageDataType | null = null
  if (checkIfGoogleCampaignReviewApi(data)) {
    if (checkIfGoogleTextCampaignReviewApi(data)) {
      newReviewData = {
        campaign: {
          name: data.campaign.name,
          platform: data.campaign.platform,
          budget: data.campaign.budget,
          goal: data.campaign.goal,
          period: {
            start: data.campaign.period.start,
            end: data.campaign.period.end
          },
          format: data.campaign.format,
          status: data.campaign.status,
          devices: data.campaign.devices,
          bidStrategy: getBidStrategyEnum(data.campaign.bidStrategy),
          co2:
            getCO2andUnit(data.campaign.co2).co2 +
            ` ${getCO2andUnit(data.campaign.co2).co2Unit}`
        },
        ads: data.ads.map((ad) => ({
          id: ad.id,
          format: ad.format,
          name: ad.name,
          url: ad.url,
          title1: ad.title1,
          title2: ad.title2,
          title3: ad.title3,
          description1: ad.description1,
          description2: ad.description2,
          description3: ad.description3
        })),
        navigation: data.navigation
      }
    } else if (checkIfGoogleImageCampaignReviewApi(data)) {
      newReviewData = {
        campaign: {
          name: data.campaign.name,
          platform: data.campaign.platform,
          budget: data.campaign.budget,
          goal: data.campaign.goal,
          period: {
            start: data.campaign.period.start,
            end: data.campaign.period.end
          },
          format: data.campaign.format,
          status: data.campaign.status,
          devices: data.campaign.devices,
          bidStrategy: getBidStrategyEnum(data.campaign.bidStrategy),
          co2:
            getCO2andUnit(data.campaign.co2).co2 +
            ` ${getCO2andUnit(data.campaign.co2).co2Unit}`
        },
        ads: data.ads.map((ad) => ({
          id: ad.id,
          format: ad.format,
          name: ad.name,
          businessName: ad.businessName,
          url: ad.url,
          logo: ad.logo,
          headline1: ad.headline1,
          headline2: ad.headline2,
          headline3: ad.headline3,
          longHeadline: ad.longHeadline,
          description1: ad.description1,
          description2: ad.description2,
          description3: ad.description3,
          marketingImage1: ad.marketingImage1,
          marketingImage2: ad.marketingImage2,
          marketingImage3: ad.marketingImage3,
          squareMarketingImage1: ad.squareMarketingImage1,
          squareMarketingImage2: ad.squareMarketingImage2,
          squareMarketingImage3: ad.squareMarketingImage3
        })),
        navigation: data.navigation
      }
    }
  } else if (checkIfLinkedinCampaignReviewApi(data)) {
    if (checkIfLinkedinImageCampaignReviewApi(data)) {
      newReviewData = {
        campaign: {
          name: data.campaign.name,
          platform: data.campaign.platform,
          budget: data.campaign.budget,
          goal: data.campaign.goal,
          period: {
            start: data.campaign.period.start,
            end: data.campaign.period.end
          },
          format: data.campaign.format,
          status: data.campaign.status,
          co2:
            getCO2andUnit(data.campaign.co2).co2 +
            ` ${getCO2andUnit(data.campaign.co2).co2Unit}`
        },
        ads: data.ads.map((ad) => ({
          id: ad.id,
          format: ad.format,
          name: ad.name,
          introText: ad.introText,
          url: ad.url,
          headline: ad.headline,
          imageId: ad.imageId,
          cta: ad.cta
        })),
        navigation: data.navigation
      }
    }
  }

  return newReviewData
}
