import { backendURL } from '../../../constants/urls'
import { DevicesBoolean, DevicesEnum } from '../../../types/campaign/devices'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetCO2TheoreticalDevicePortionsOutputType = {
  portions: {
    [key in DevicesEnum]: number
  }
}

export type GetCO2TheoreticalDevicePortionsInput = {
  devices: DevicesBoolean
}

// TODO response.data includes ads, which will be {format: string, id: string, name: string}[]
export const getCO2TheoreticalDevicePortions = async ({
  devices
}: GetCO2TheoreticalDevicePortionsInput): Promise<GetCO2TheoreticalDevicePortionsOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/co2/theoretical-portions?${Object.keys(devices)
        .map((key) => `${key}=${devices[key as keyof DevicesBoolean] ? 1 : 0}`)
        .join('&')}`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCO2TheoreticalDevicePortionsOutputType(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

const checkIfCO2TheoreticalDevicePortionsOutputType = (
  result: any
): result is GetCO2TheoreticalDevicePortionsOutputType => {
  return (
    result.portions &&
    Object.keys(result.portions).every((key: any) =>
      Object.values(DevicesEnum).includes(key)
    ) &&
    Object.values(result.portions).every(
      (value: any) => value !== undefined && typeof value === 'number'
    )
  )
}
