import React, { CSSProperties } from 'react'
import { CloseIcon, LabelSpan, TagSpan } from './styles'
import Margin from '../../constants/margin'

export interface TagProps {
  label: string
  removeElement?: () => void
  handleClick?: (label: string) => void
  style?: CSSProperties
}

export const Tag = ({
  label,
  removeElement,
  handleClick,
  style = {}
}: TagProps) => (
  <TagSpan key={label} style={{ ...style, marginBottom: Margin.m2 }}>
    <LabelSpan
      hasAction={!!handleClick}
      onClick={
        handleClick
          ? () => {
              handleClick(label)
            }
          : undefined
      }
    >
      {label}
    </LabelSpan>
    {removeElement && <CloseIcon onClick={removeElement}>x</CloseIcon>}
  </TagSpan>
)

export interface TagsProps {
  labels: string[]
  removeElements?: (index: number) => void
  nonRemovableElements?: string[]
  onClickTag?: (label: string) => void
}

export const Tags = ({
  labels,
  removeElements,
  nonRemovableElements,
  onClickTag
}: TagsProps) => (
  <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: Margin.m5 }}>
    {labels.map((label, index) => (
      <Tag
        key={label}
        label={label}
        removeElement={
          removeElements &&
          (!nonRemovableElements || !nonRemovableElements.includes(label))
            ? () => {
                removeElements(index)
              }
            : undefined
        }
        handleClick={onClickTag}
      />
    ))}
  </div>
)
