import React from 'react'

import MultipleSelectionInput from '../../../../../components/Input/MultipleSelectionInput'

type JobTitlesProps = {
  data: string[]
  selectedTags: string[]
  error?: string
  removeJobTitle: (index: number) => void
  addNewJobTitle: (tag: string) => void
}

const JobTitles = ({
  data,
  selectedTags,
  error,
  removeJobTitle,
  addNewJobTitle
}: JobTitlesProps) => {
  return (
    <MultipleSelectionInput
      data={data}
      selectedTags={selectedTags}
      error={error}
      removeItem={removeJobTitle}
      addNewItem={addNewJobTitle}
      label="Intitulés de poste"
      placeholder="Directeur commercial, Responsable marketing, Consultant..."
      isRequired={false}
    />
  )
}

export default JobTitles
