import { AdNavigation } from '../../../../types/ad/ad'

type BreadcrumbParams = {
  navigation: AdNavigation
  navigate: (path: string) => void
}

export const getBreadcrumb = ({ navigation, navigate }: BreadcrumbParams) => {
  const items = [
    {
      label: navigation.company.name
    },
    {
      onClick: () => {
        navigate(`/media-plan/${navigation.mediaPlan.id}/review`)
      },
      label: navigation.mediaPlan.name
    },
    {
      onClick: () => {
        navigate(`/campaign/${navigation.campaign.id}/review`)
      },
      label: `Campagne « ${navigation.campaign.name} »`
    },
    { label: `Création d'annonce` }
  ]

  return items
}
