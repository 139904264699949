import React from 'react'

import { ImageAdCreationFormDataType } from '..'
import Input from '../../../../../components/Input'
import { PanelType } from '../../../../../types/templates/panels'

type GetUrlPanelProps = {
  formData: ImageAdCreationFormDataType
  handleInputChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  hasBeenSubmitted: boolean
  error: string
}

const getUrlPanel = ({
  formData,
  handleInputChange,
  hasBeenSubmitted,
  error
}: GetUrlPanelProps): PanelType => {
  const urlPanel: PanelType = {
    title: 'URL',
    disabled: false,
    isDropdown: false,
    description: `Renseignez l'URL de destination.`,
    sections: [
      {
        content: (
          <Input
            type="text"
            placeholder="https://example.com"
            isRequired={true}
            label={'URL de destination'}
            value={formData.url}
            onChange={(e) => {
              handleInputChange('url', e)
            }}
            error={hasBeenSubmitted ? error : ''}
            withBorder
          />
        )
      }
    ]
  }

  return urlPanel
}

export default getUrlPanel
