export enum ValidateCampaignErrorEnum {
  AUTH_FAILED = 'AUTH_FAILED',
  NOT_FOUND = 'NOT_FOUND',
  USER_NOT_MATCHING = 'USER_NOT_MATCHING',
  MISSING_CAMPAIGN_ID = 'MISSING_CAMPAIGN_ID',
  DRAFT_MEDIA_PLAN = 'DRAFT_MEDIA_PLAN',
  UNKNOWN = 'UNKNOWN',
  NO_AD = 'NO_AD'
}

export const ValidateCampaignErrorTranslation = {
  [ValidateCampaignErrorEnum.AUTH_FAILED]: 'Authentification échouée',
  [ValidateCampaignErrorEnum.NOT_FOUND]: 'La campagne est introuvable',
  [ValidateCampaignErrorEnum.USER_NOT_MATCHING]:
    'Vos données utilisateur ne correspondent pas à celles de la campagne',
  [ValidateCampaignErrorEnum.MISSING_CAMPAIGN_ID]:
    "L'identifiant de la campagne est manquant",
  [ValidateCampaignErrorEnum.DRAFT_MEDIA_PLAN]:
    'Le plan média est en brouillon',
  [ValidateCampaignErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [ValidateCampaignErrorEnum.NO_AD]: "La campagne ne contient pas d'annonce"
}

export const checkIfValidateCampaignErrorCodeValid = (
  errorCode: string
): errorCode is ValidateCampaignErrorEnum => {
  return Object.keys(ValidateCampaignErrorEnum).includes(errorCode)
}
