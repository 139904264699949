import axios from 'axios'

import { backendURL } from '../../../constants/urls'
import { ErrorOutput } from '../../../types/error/generic'
import {
  CreateCustomerErrorEnum,
  CreateCustomerErrorTranslation
} from '../../../types/error/customer/create'
import { getErrorCode } from '../../getErrorCode'

export type CreateCustomerInputType = {
  user?: {
    firstName: string
    lastName: string
    email: string
    role: string
  }
  company: {
    siret: string
    name: string
    activity: string
    vat: string
    billingAddress: string
    isDataFromApi: boolean
  }
}

export type CreateCustomerOutputType =
  | { userId: string; companyId: string }
  | ErrorOutput

const handleCreateCustomer = async (data: CreateCustomerInputType) => {
  const url = `${backendURL}/customers`
  try {
    const res = await axios.post(url, data, { withCredentials: true })
    if (
      res.status === 200 &&
      res.data.customerId != null &&
      typeof res.data.customerId === 'string'
    ) {
      if (res.data.userId != null && typeof res.data.userId === 'string') {
        return { userId: res.data.customerId, companyId: res.data.customerId }
      } else {
        return { companyId: res.data.customerId }
      }
    }
    return {
      error: CreateCustomerErrorTranslation[CreateCustomerErrorEnum.UNKNOWN]
    }
  } catch (error) {
    const errorCode = getErrorCode(error) ?? null
    if (
      errorCode &&
      Object.values<string>(CreateCustomerErrorEnum).includes(errorCode)
    ) {
      return {
        error:
          CreateCustomerErrorTranslation[errorCode as CreateCustomerErrorEnum]
      }
    }
    return {
      error: CreateCustomerErrorTranslation[CreateCustomerErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfCreateCustomerReturnsError = (
  result: CreateCustomerOutputType
) => {
  return !!(result as ErrorOutput)?.error
}

export default handleCreateCustomer
