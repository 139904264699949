import React from 'react'

import { RadioListTmp } from '../../../../tmpComponents/RadioList'
import { FormatEnum } from '../../../../types/format'
import { BidStrategyEnum } from '../../../../types/bidStrategy'
import { bidStrategiesByFormat } from './bidStrategiesByFormat'

export type BidStrategyProps = {
  bidStrategy: BidStrategyEnum
  handleChangeBidStrategy: (value: BidStrategyEnum) => void
  campaignFormat: FormatEnum
}

const BidStrategy = ({
  bidStrategy,
  handleChangeBidStrategy,
  campaignFormat
}: BidStrategyProps) => {
  const radioValues = bidStrategiesByFormat[campaignFormat]
  const selectedValue = radioValues.find((item) => item.label === bidStrategy)

  return (
    <RadioListTmp
      values={radioValues}
      selectedValue={selectedValue ? selectedValue.id : 0}
      onChange={(index) => {
        handleChangeBidStrategy(radioValues[index].label)
      }}
    />
  )
}

export default BidStrategy
