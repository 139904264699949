import React, { useState } from 'react'

import { LabelledItemType } from '../../components/LabelledItem'
import LabelledItemsList from '../../components/LabelledItemsList'
import DetailsPageTemplate from '../../templates/DetailsPageTemplate'
import TableList from '../../components/TableList'
import Team from '../../components/Team'
import { formatDateToString } from '../../helpers/date'
import { FormatAPIEnum, getFormatEnum } from '../../types/format'
import { getBidStrategyEnum } from '../../types/bidStrategy'
import { getLangEnum } from '../../types/lang'
import { getAudienceByMediaPlanIdAndName } from '../../helpers/queries/audiences/getAudienceByName'
import ModalAudienceDetails from '../../components/ModalAudienceDetails'
import ModalKeywords from '../parts/ModalKeywords'
import { getCampaignKeywords } from '../../helpers/queries/campaign/getCampaignKeywords'
import StatusWithColor from '../../components/StatusDot'
import ModalReasonOfReject from '../parts/ModalReasonOfReject'
import { PlatformDiv } from './styles'
import PlatformLogo from '../../components/PlatformLogo'
import { checkIfGoogleCampaign } from '../../types/campaign/google/googleCampaign'
import AdPreview from './AdPreview'
import { FullTextAdType, checkIfFullTextAd } from '../../types/ad/google/textAd'
import {
  FullGoogleImageAdType,
  checkIfFullGoogleImageAd
} from '../../types/ad/google/imageAd'
import { MediaPlanDetailsDataTable } from '.'
import { MediaPlanDetailsProps } from './types'
import statusHasStarted from '../../helpers/statusHasStarted'
import { Status } from '../../types/status'
import { ButtonPropsType } from '../../components/Button'

const EditorMediaPlanDetails = ({
  mediaPlanId,
  mediaPlan,
  selectedCampaign,
  displayedAdImages,
  skip,
  handleChangePage,
  noRowOpen,
  handleOnRowClick,
  dataAudienceModal,
  setDataAudienceModal,
  dataKeywordsModal,
  setDataKeywordsModal,
  isKeywordsModalOpen,
  setIsKeywordsModalOpen,
  isModalReasonOpen,
  setIsModalReasonOpen,
  handleShowReason,
  rejectionReason,
  handleAdd,
  handleEdit,
  handlePauseRestartCampaign,
  navigate
}: MediaPlanDetailsProps) => {
  const [campaignName, setCampaignName] = useState('')

  const hideCO2 = !statusHasStarted(mediaPlan.status)

  const breadcrumbItems = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate(`/customers`)
      },
      label: 'Clients'
    },
    {
      onClick: () => {
        navigate(`/customer/${mediaPlan.navigation.company.id}/details`)
      },
      label: mediaPlan.navigation.company.name
    },
    {
      label: mediaPlan.name
    }
  ]

  const startDate = formatDateToString(new Date(mediaPlan.startDate))
  const endDate = formatDateToString(new Date(mediaPlan.endDate))

  const summaryItems: LabelledItemType[] = [
    {
      label: 'Statut',
      value: (
        <StatusWithColor
          status={mediaPlan.status}
          handleClick={handleShowReason}
        />
      )
    },
    { label: 'Période', value: `du ${startDate} au ${endDate}` },
    {
      label: 'Budget dépensé / prévu',
      value: `${Math.round(mediaPlan.spentBudget * 100) / 100}€ / ${
        Math.round(mediaPlan.expectedBudget * 100) / 100
      }€`
    },
    {
      label: 'Budget non attribué',
      value: `${
        Math.round((mediaPlan.expectedBudget - mediaPlan.spentBudget) * 100) /
        100
      }€`
    },
    {
      label: 'Audience(s)',
      value: mediaPlan.audiences.join('\n'),
      onClick: async (audienceName) => {
        const result = await getAudienceByMediaPlanIdAndName({
          name: audienceName,
          mediaPlanId: mediaPlanId
        })
        if (result) {
          setDataAudienceModal(result)
        }
      }
    },
    {
      label: 'Objectif(s)',
      value: mediaPlan.goals.join('\n')
    }
  ]

  const summaryContentColumn1 = <LabelledItemsList items={summaryItems} />

  const summaryContentColumn2 =
    mediaPlan.users.length !== 0 ? <Team users={mediaPlan.users} /> : undefined

  let mediaPlanCampaigns: MediaPlanDetailsDataTable[] = mediaPlan.campaigns
    ? mediaPlan.campaigns.map((c) => {
        return {
          id: c.id,
          actions:
            c.status === Status.IN_PROGRESS || c.status === Status.PAUSED
              ? [
                  {
                    label:
                      c.status === Status.IN_PROGRESS
                        ? 'Mettre en pause'
                        : 'Reprendre',
                    handleAction: () => {
                      handlePauseRestartCampaign(c.id, c.status)
                    }
                  }
                ]
              : [],
          row: [
            <PlatformDiv key={c.platform}>
              <PlatformLogo platform={c.platform} height="20px" width="20px" />
              {c.name}
            </PlatformDiv>,
            <StatusWithColor
              status={c.status}
              key={c.status}
              handleClick={handleShowReason}
            />,
            `${Math.round(c.spentBudget * 100) / 100}€ / ${
              Math.round(c.expectedBudget * 100) / 100
            }€`,
            getFormatEnum(c.format),
            <div
              key={c.id}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div>{formatDateToString(new Date(c.startDate))}</div>
              <div>{formatDateToString(new Date(c.endDate))}</div>
            </div>,
            ...(hideCO2 ? [] : [`${Math.round(c.consumedCo2 * 100) / 100} g`])
          ],
          details: {
            column1: []
          },
          subItems: []
        }
      })
    : []

  const currentCampaign = mediaPlan.campaigns.find((c) => c.id === noRowOpen)
  if (selectedCampaign != null) {
    const cardDetails = {
      column1: [
        {
          label: 'Période',
          value:
            currentCampaign?.startDate && currentCampaign?.endDate
              ? `du ${formatDateToString(
                  new Date(currentCampaign.startDate)
                )} au ${formatDateToString(new Date(currentCampaign.endDate))}`
              : ''
        },
        {
          label: 'Objectif',
          value: selectedCampaign.goal
        },
        {
          label: 'Format',
          value: currentCampaign?.format
            ? getFormatEnum(currentCampaign.format)
            : ''
        }
        // ...(checkIfGoogleCampaign(selectedCampaign)
        //   ? [
        //       {
        //         label: 'Enchères',
        //         value: getBidStrategyEnum(selectedCampaign.bidStrategy)
        //       }
        //     ]
        //   : [])
      ],
      column2: [
        {
          label: 'Budget dépensé / prévu',
          value:
            currentCampaign?.spentBudget !== undefined &&
            currentCampaign?.expectedBudget !== undefined
              ? `${Math.round(currentCampaign.spentBudget * 100) / 100}€ / ${
                  Math.round(currentCampaign.expectedBudget * 100) / 100
                }€`
              : ''
        },
        ...(checkIfGoogleCampaign(selectedCampaign)
          ? [
              {
                label: 'Langue',
                value: getLangEnum(selectedCampaign.lang)
              },
              {
                label: 'Mots clés',
                hidden: selectedCampaign.format !== FormatAPIEnum.TEXT,
                value: selectedCampaign.keywordsCount
                  ? selectedCampaign.keywordsCount.toString()
                  : '-',
                onClick: async () => {
                  const data = await getCampaignKeywords({
                    campaignId: selectedCampaign.id
                  })
                  if (data) {
                    const dataKeywords = data.keywords.map((k) => ({
                      value: k,
                      status: 'initial' as const
                    }))
                    setDataKeywordsModal(dataKeywords)
                  }
                  setIsKeywordsModalOpen(true)
                  setCampaignName(selectedCampaign.name)
                }
              }
            ]
          : []),
        {
          label: 'Audience',
          value: selectedCampaign.audiences.map((a) => a.name).join('\n'),
          onClick: async (audienceName: string) => {
            const result = await getAudienceByMediaPlanIdAndName({
              name: audienceName,
              mediaPlanId
            })
            if (result) {
              setDataAudienceModal(result)
            }
          }
        }
      ]
    }

    const getDisplayedAdData = (
      ad: FullTextAdType | FullGoogleImageAdType
    ): { label: string; value: string }[] => {
      if (checkIfFullTextAd(ad)) {
        return [
          { label: 'Format', value: getFormatEnum(ad.format) },
          { label: 'URL', value: ad.url }
        ]
      } else if (checkIfFullGoogleImageAd(ad)) {
        return [
          { label: 'Titre long', value: ad.longHeadline },
          { label: 'URL', value: ad.url }
        ]
      } else {
        return []
      }
    }

    // TODO : handle linkedin ads
    const subItems =
      selectedCampaign.ads && checkIfGoogleCampaign(selectedCampaign)
        ? selectedCampaign.ads.map((a) => ({
            title: a.name,
            data: getDisplayedAdData(a),
            preview: <AdPreview ad={a} images={displayedAdImages} />,
            onSeeMore: () => {
              navigate(`/ad/${a.id}/details`)
            }
          }))
        : []

    mediaPlanCampaigns = mediaPlanCampaigns.map((campaign) => {
      if (campaign.id === noRowOpen) {
        return {
          ...campaign,
          details: cardDetails,
          subItems
        }
      }
      return campaign
    })
  }

  const totalConsumedCo2 =
    Math.round(
      mediaPlan.campaigns.reduce((prev, c) => prev + c.consumedCo2, 0) * 100
    ) / 100

  const mediaPlanListData = {
    titles: [
      'Nom',
      'Statut',
      <>
        <div>Budget</div>
        <div>dépensé / prévu</div>
      </>,
      'Format',
      'Période',
      ...(hideCO2 ? [] : ['CO₂ consommé'])
    ],
    widths: [
      hideCO2 ? '34%' : '27%',
      '18%',
      '18%',
      '12%',
      '14%',
      ...(hideCO2 ? [] : ['7%'])
    ],
    items: mediaPlanCampaigns
  }

  const cta:
    | { label: string; action: () => void; btnType: ButtonPropsType['type'] }[]
    | undefined =
    mediaPlan.status === Status.DRAFT
      ? [
          {
            label: 'Reprendre la création',
            action: () => {
              navigate(`/media-plan/${mediaPlanId}/review`)
            },
            btnType: 'secondary'
          }
        ]
      : undefined

  return (
    <DetailsPageTemplate
      navigate={navigate}
      breadcrumbItems={breadcrumbItems}
      isEditor={true}
      summary={{
        title: mediaPlan.name,
        type: 'Plan média',
        co2InG: statusHasStarted(mediaPlan.status) ? totalConsumedCo2 : null,
        column1: summaryContentColumn1,
        column2: summaryContentColumn2,
        onEdit: handleEdit,
        cta
      }}
    >
      <>
        <TableList
          title="Campagnes"
          data={mediaPlanListData}
          itemsByPage={5}
          handleAdd={handleAdd}
          addText="Nouvelle campagne"
          handleOnRowClick={(id: string) => {
            handleOnRowClick(id)
          }}
          noRowOpen={noRowOpen}
          subtitle="Liste des annonces"
          seeMoreText="Voir la campagne"
          handleSeeMoreSubItem={(id: string) => {
            navigate(`/campaign/${id}/details`)
          }}
          totalItems={mediaPlan.navigation.campaignsCount}
          handleChangePage={(page) => {
            handleChangePage(page)
          }}
          currentPage={skip / 5 + 1}
        />
        {dataAudienceModal && (
          <ModalAudienceDetails
            title={"Détails de l'audience"}
            data={dataAudienceModal}
            handleClose={() => {
              setDataAudienceModal(null)
            }}
          />
        )}
        {isKeywordsModalOpen && (
          <ModalKeywords
            setIsOpen={setIsKeywordsModalOpen}
            campaignName={campaignName}
            keywords={dataKeywordsModal}
            isEditable={false}
          />
        )}
        {isModalReasonOpen && (
          <ModalReasonOfReject
            onClose={() => {
              setIsModalReasonOpen(!isModalReasonOpen)
            }}
            mediaPlanName={mediaPlan.name}
            rejectionReason={rejectionReason}
          />
        )}
      </>
    </DetailsPageTemplate>
  )
}

export default EditorMediaPlanDetails
