import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../../constants/fontFamily'
import FontSize from '../../../constants/fontSize'
import FontWeight from '../../../constants/fontWeight'
import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  ${(props: { width?: string }) =>
    props.width ? `width: ${props.width}` : ''};
`

export const LabelText = styled.span`
  font-weight: 500;
  color: ${(props: { colorLabel?: string }) =>
    props.colorLabel ? props.colorLabel : Colors.YETIC_BLUE_DARK};
`

export const InputContainer = styled.div`
  position: relative;
`

export const StyledInput = styled.input<{
  width?: string
  withBorder?: boolean
  error?: string
}>`
  width: ${(props: { width?: string }) => (props.width ? props.width : '100%')};
  height: 40px;
  padding: 0 ${Padding.p5};
  border: none;
  border-radius: 10px;
  color: ${Colors.YETIC_BLUE_DARK};
  box-sizing: border-box;
  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.YETIC_INPUT_TEXT};
  background-color: ${Colors.YETIC_WHITE};
  font-weight: 500;

  ::placeholder {
    color: ${Colors.YETIC_BLUE_DARK};
    opacity: 0.3;
  }
  ::focus-visible {
    border: 1px ${Colors.YETIC_BLUE_LIGHT};
  }

  ${(props: { withBorder?: boolean; error?: string }) =>
    props.error
      ? 'border: 1px solid red;'
      : props.withBorder
      ? 'border: 1px solid #C4C9D2;'
      : ''})
`

export const BudgetCurrency = styled.span`
  text-transform: uppercase;
  font-weight: ${FontWeight.YETIC_BOLD};
  color: ${Colors.NEW_YETIC_DARK};
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`

export const KeywordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const KeywordLabel = styled.span`
  background-color: ${Colors.YETIC_GREEN_LIGHT};
  color: white;
  border-radius: 5px;
  padding: 4px 8px;
  margin-right: ${Margin.m2};
  margin-bottom: ${Margin.m2};
`

export const Close = styled.span`
  cursor: pointer;
  margin-left: ${Margin.m2};
  font-size: ${FontSize.YETIC_TEXT};
  color: ${Colors.YETIC_BLUE_DARK};

  &:hover {
    color: ${Colors.YETIC_BLUE_DARK};
  }
`

export const DivLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Margin.m2};
`

export const Textarea = styled.textarea<{
  withBorder?: boolean
  width?: string
  error?: string
}>`
  width: ${(props: { width?: string }) => (props.width ? props.width : '100%')};
  padding: ${Padding.p5};

  height: 100px;

  border: none;
  border-radius: 5px;

  color: ${Colors.YETIC_BLUE_DARK};
  box-sizing: border-box;
  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  background-color: ${Colors.YETIC_WHITE};
  font-weight: 500;

  ::placeholder {
    color: ${Colors.YETIC_BLUE_DARK};
    opacity: 0.3;
  }
  ::focus-visible {
    border: 1px ${Colors.YETIC_BLUE_LIGHT};
  }
  resize: none;

  ${(props: { withBorder?: boolean; error?: string }) =>
    props.error
      ? 'border: 1px solid red;'
      : props.withBorder
      ? 'border: 1px solid #C4C9D2;'
      : ''})
`

export const InputError = styled.div`
  color: ${Colors.YETIC_RED};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  margin: ${Margin.m1} 0;
  margin-left: ${Margin.m3};
`

export const PasswordContainer = styled.div`
  position: relative;
`

export const DivEyes = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
export const RequiredLabel = styled.span`
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  color: rgb(154, 160, 170);
  margin-left: ${Margin.m2};
`
