import React from 'react'

import { UserType } from '../types/user'

export type PartialAppContextType = {
  user?: UserType | null
  isEditor: boolean | null
  isSelfEditor: boolean | null
  companyId: string | null
  companyName: string | null
}

export type AppContextType = PartialAppContextType & {
  setUser: (input: PartialAppContextType | null) => void
}

export const AppContext = React.createContext<AppContextType>({
  setUser: (input: PartialAppContextType | null) => {},
  isEditor: null,
  isSelfEditor: null,
  companyId: null,
  companyName: null
})
