import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import { GetCampaignAdsReportingOutputType } from '../../../../types/reporting/campaign/output'

type GetCampaignAdsReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignAdsReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignAdsReportingInputType): Promise<GetCampaignAdsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/ads${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignAdsReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignAdsReporting = (
  value: any
): value is GetCampaignAdsReportingOutputType => {
  return (
    value &&
    value.ads &&
    Array.isArray(value.ads) &&
    value.ads.every((value: any) => {
      return (
        value &&
        value.adName &&
        typeof value.adName === 'string' &&
        value.clicks !== undefined &&
        typeof value.clicks === 'number' &&
        value.impressions !== undefined &&
        typeof value.impressions === 'number'
      )
    })
  )
}
