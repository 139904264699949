import React from 'react'

import { IconType } from '../../types/icon'

const YeticLogo = ({ height, width, style, color, handleClick }: IconType) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      style={style}
      viewBox="0 0 3969.000000 1829.000000"
      preserveAspectRatio="xMidYMid meet"
      onClick={handleClick}
    >
      <g
        transform="translate(0.000000,1829.000000) scale(0.100000,-0.100000)"
        fill={color ?? '#000000'}
        stroke="none"
      >
        <path
          d="M7305 18169 c-610 -365 -981 -904 -1309 -1904 -40 -121 -77 -235 -82
-254 -9 -34 -12 -29 -266 390 -142 233 -267 439 -278 458 l-21 33 -117 -113
c-64 -63 -168 -161 -229 -218 -129 -119 -255 -260 -328 -365 -104 -150 -157
-261 -361 -748 -27 -65 -52 -118 -55 -118 -6 0 -639 203 -671 215 -13 5 -18 1
-22 -17 -8 -42 -47 -548 -61 -798 -8 -135 -19 -263 -25 -285 -37 -123 -114
-224 -281 -370 -660 -574 -1222 -1320 -1585 -2106 -952 -2061 -766 -4477 489
-6354 174 -260 391 -538 584 -748 138 -152 176 -206 208 -292 54 -152 128
-724 200 -1555 45 -516 39 -480 73 -480 15 0 83 13 150 30 385 94 722 293
1182 699 63 55 117 101 120 101 3 0 290 -439 639 -975 349 -536 636 -975 637
-975 1 0 134 226 295 502 161 276 294 497 296 492 21 -62 1014 -2339 1021
-2342 5 -1 50 20 98 49 508 297 873 757 1152 1454 63 157 182 501 222 643 13
45 26 82 29 82 3 0 130 -200 281 -445 151 -245 278 -444 282 -443 16 6 346
319 467 443 69 72 156 173 193 225 85 119 200 351 313 632 48 120 93 226 100
235 12 16 40 8 351 -95 186 -61 342 -108 345 -104 4 4 12 81 18 172 23 319 72
886 86 993 14 109 40 184 85 248 12 16 85 84 163 152 370 319 651 617 924 978
1250 1654 1628 3874 1006 5904 -100 328 -220 624 -384 955 -313 627 -645 1091
-1155 1610 -127 129 -243 256 -257 281 -72 124 -86 227 -177 1344 -30 373 -40
455 -56 455 -38 0 -256 -53 -351 -85 -318 -109 -657 -327 -986 -635 -37 -35
-69 -61 -71 -59 -2 2 -261 409 -576 904 -315 495 -576 904 -580 908 -4 5 -108
-164 -230 -375 -402 -691 -368 -636 -378 -608 -28 71 -1004 2326 -1010 2332
-4 4 -52 -20 -107 -53z m566 -3869 c1077 -83 2068 -499 2895 -1215 145 -125
427 -411 543 -550 407 -488 703 -1002 923 -1603 133 -365 237 -826 283 -1252
22 -201 30 -666 16 -865 -63 -865 -337 -1663 -814 -2380 -624 -936 -1545
-1644 -2618 -2013 -783 -270 -1665 -341 -2494 -201 -1398 235 -2649 1067
-3414 2272 -456 718 -724 1532 -791 2396 -13 173 -13 591 0 757 114 1429 834
2734 2000 3619 763 579 1703 945 2630 1025 69 5 141 12 160 14 87 8 559 5 681
-4z"
        />
        <path
          d="M3880 10380 l0 -260 557 -2 558 -3 7 -56 c32 -257 241 -439 503 -439
136 0 256 50 351 145 72 71 118 155 134 246 7 35 14 74 16 87 l4 22 223 0
c246 0 292 7 399 61 151 76 267 256 268 417 l0 42 -1510 0 -1510 0 0 -260z"
        />
        <path
          d="M8020 10618 c1 -55 32 -164 66 -228 43 -83 137 -175 216 -214 100
-48 154 -56 393 -56 l221 0 15 -82 c23 -117 60 -191 138 -269 102 -102 214
-149 356 -149 252 0 459 176 497 423 l13 77 557 0 558 0 0 260 0 260 -1515 0
-1515 0 0 -22z"
        />
        <path
          d="M5715 8844 c-258 -234 -407 -356 -607 -494 l-76 -53 -61 53 c-150
131 -261 316 -261 437 l0 33 -414 0 -415 0 5 -57 c23 -277 135 -513 351 -743
469 -498 1397 -863 2473 -974 402 -42 1034 -47 1405 -11 1349 128 2383 593
2777 1248 85 142 148 344 148 480 l0 57 -409 0 -408 0 -7 -55 c-13 -111 -83
-238 -196 -353 -43 -44 -67 -62 -78 -57 -59 22 -323 234 -572 458 -107 97
-198 173 -202 169 -4 -4 -19 -41 -34 -82 -88 -251 -103 -572 -40 -850 14 -58
27 -112 31 -121 5 -14 -7 -21 -62 -37 -546 -164 -1064 -231 -1713 -219 -394 7
-633 30 -970 93 -149 28 -451 101 -462 112 -4 4 3 41 16 82 61 193 79 503 42
715 -18 99 -83 315 -95 315 -3 -1 -78 -66 -166 -146z"
        />
        <path
          d="M233 13748 c-72 -108 -118 -234 -143 -393 -15 -91 -14 -345 0 -460
52 -422 255 -892 522 -1210 42 -49 100 -114 131 -144 l55 -53 20 43 c10 24 78
175 150 335 234 523 343 728 645 1212 93 150 167 275 164 278 -4 4 -1414 432
-1482 450 -16 4 -29 -8 -62 -58z"
        />
        <path
          d="M13925 13586 c-401 -122 -736 -224 -744 -227 -10 -3 34 -82 154 -274
314 -504 418 -702 663 -1251 72 -160 136 -303 144 -318 l14 -27 91 94 c318
328 544 807 614 1297 30 209 11 492 -43 660 -37 114 -126 272 -152 269 -6 -1
-339 -101 -741 -223z"
        />
        <path
          d="M36619 12000 c-1295 -91 -2262 -917 -2523 -2158 -54 -257 -61 -333
-61 -702 1 -366 10 -474 66 -725 162 -729 587 -1339 1193 -1710 366 -224 753
-353 1207 -401 189 -20 630 -14 804 10 347 50 652 144 925 287 579 304 998
791 1211 1408 41 118 47 158 28 193 -25 47 -34 48 -742 48 -457 0 -674 -3
-696 -11 -23 -8 -40 -27 -68 -76 -84 -151 -237 -320 -370 -411 -132 -90 -302
-161 -473 -198 -120 -27 -458 -27 -579 -1 -278 60 -483 169 -671 358 -305 305
-450 705 -450 1236 0 346 60 619 191 874 196 379 526 631 930 710 576 111
1129 -115 1401 -574 29 -49 61 -95 72 -103 17 -12 135 -14 714 -14 l694 0 29
29 c41 41 38 77 -17 229 -131 364 -328 676 -589 937 -314 314 -689 532 -1133
659 -134 38 -360 81 -507 95 -153 15 -446 21 -586 11z"
        />
        <path
          d="M17126 11914 c-56 -56 -106 49 914 -1923 l940 -1818 0 -897 0 -897
25 -24 24 -25 626 0 c430 0 633 3 647 11 48 26 48 17 48 962 l0 883 934 1807
c514 994 937 1816 941 1827 4 11 1 36 -5 55 -23 69 20 65 -750 65 -531 -1
-699 -4 -711 -13 -23 -17 -15 0 -508 -1074 -247 -538 -461 -1004 -476 -1036
-41 -85 -106 -112 -159 -64 -17 15 -185 367 -504 1057 -526 1138 -508 1100
-531 1117 -12 9 -183 12 -722 13 l-706 0 -27 -26z"
        />
        <path
          d="M22873 11916 l-28 -24 0 -2757 0 -2757 28 -24 28 -24 1659 0 c912 0
1666 3 1675 6 43 17 45 37 45 539 0 488 -1 503 -39 532 -12 10 -238 12 -979
13 -834 0 -967 2 -992 15 -63 32 -60 7 -60 598 0 575 0 580 49 607 13 7 301
10 856 10 893 0 888 0 914 48 7 14 11 172 11 487 l0 467 -29 29 -29 29 -852 0
-852 0 -34 34 -34 34 0 494 c0 374 3 499 13 518 7 14 28 33 47 42 33 17 101
18 992 18 526 0 964 3 973 6 43 17 45 37 45 539 0 488 -1 503 -39 532 -12 10
-387 12 -1678 13 l-1662 0 -28 -24z"
        />
        <path
          d="M26895 11915 l-25 -24 0 -494 c0 -472 1 -496 19 -518 l19 -24 691 -5
691 -5 27 -28 28 -27 5 -2206 5 -2206 28 -24 28 -24 627 0 c662 1 645 -1 670
47 9 16 12 564 12 2203 0 1960 2 2184 16 2211 30 60 19 59 753 59 l669 0 26
31 26 31 0 485 0 485 -29 29 -29 29 -2117 0 -2116 0 -24 -25z"
        />
        <path
          d="M31926 11914 l-26 -27 0 -2754 0 -2754 25 -24 24 -25 626 0 c430 0
633 3 647 11 51 27 48 -124 48 2794 0 2918 3 2767 -48 2794 -14 8 -217 11
-645 11 l-624 0 -27 -26z"
        />
      </g>
    </svg>
  )
}

export default YeticLogo
