import { AdNavigation, checkAdNavigation, checkIfAdArray } from '../../ad/ad'
import {
  FullGoogleImageAdType,
  checkIfGoogleImageAdArray
} from '../../ad/google/imageAd'
import { FullTextAdType, checkIfTextAdArray } from '../../ad/google/textAd'
import {
  GoogleCampaignType,
  checkIfGoogleCampaign
} from '../google/googleCampaign'

export type GoogleCampaignReviewApiType =
  | GoogleTextCampaignReviewApiType
  | GoogleImageCampaignReviewApiType

export type GoogleTextCampaignReviewApiType = {
  campaign: GoogleCampaignType & { co2: number }
  ads: FullTextAdType[]
  navigation: AdNavigation
}

export type GoogleImageCampaignReviewApiType = {
  campaign: GoogleCampaignType & { co2: number }
  ads: FullGoogleImageAdType[]
  navigation: AdNavigation
}

export const checkIfGoogleCampaignReviewApi = (
  value: any
): value is GoogleCampaignReviewApiType => {
  return (
    value.campaign &&
    checkIfGoogleCampaign(value.campaign) &&
    value.campaign.co2 !== undefined &&
    typeof value.campaign.co2 === 'number' &&
    checkIfAdArray(value.ads) &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfGoogleTextCampaignReviewApi = (
  value: any
): value is GoogleTextCampaignReviewApiType => {
  return (
    value.campaign &&
    checkIfGoogleCampaign(value.campaign) &&
    value.campaign.co2 !== undefined &&
    typeof value.campaign.co2 === 'number' &&
    checkIfTextAdArray(value.ads) &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfGoogleImageCampaignReviewApi = (
  value: any
): value is GoogleImageCampaignReviewApiType => {
  return (
    value.campaign &&
    checkIfGoogleCampaign(value.campaign) &&
    value.campaign.co2 !== undefined &&
    typeof value.campaign.co2 === 'number' &&
    checkIfGoogleImageAdArray(value.ads) &&
    checkAdNavigation(value.navigation)
  )
}
