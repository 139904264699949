import { FormatEnum } from '../../types/format'
import { GoalsEnum } from '../../types/goals'

export const campaignCreationFormatTranslation = {
  [FormatEnum.TEXT]: {
    label: 'Texte',
    description: 'Format texte',
    disabled: false
  },
  [FormatEnum.IMAGE]: {
    label: 'Image',
    description: 'Format image',
    disabled: false
  },
  [FormatEnum.VIDEO]: {
    label: 'Vidéo',
    description: 'Format vidéo (URL Youtube)',
    disabled: true
  }
}

export const campaignCreationGoalTranslation = {
  [GoalsEnum.LEAD]: {
    label: 'Prospection',
    description: `Attirer les prospects et générer d'autres conversions`
  },
  [GoalsEnum.SALES]: {
    label: 'Ventes',
    description: `Générer des ventes en ligne`
  },
  [GoalsEnum.TRAFFIC]: {
    label: 'Trafic',
    description: `Générer du trafic sur votre site`
  },
  [GoalsEnum.AWARENESS]: {
    label: 'Notoriété',
    description: `Augmenter la notoriété de votre marque`
  },
  [GoalsEnum.UNKNOWN]: {
    label: 'Je ne sais pas',
    description: `Pas d'objectifs particuliers de définis`
  }
}
