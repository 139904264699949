import styled from '@emotion/styled'

import FontSize from '../../../../constants/fontSize'
import Padding from '../../../../constants/padding'
import Colors from '../../../../constants/colors'
import FontWeight from '../../../../constants/fontWeight'

export const ContainerMenu = styled.div`
  position: relative;
`

export const PopupMenu = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  right: 0;
  top: 70px;
  list-style: none;
  margin: 0;
  padding: ${Padding.p7} ${Padding.p4};
  background: #fff;
  border-radius: 10px;
  text-align: right;
  box-shadow: 0px 0px 5px #142a4c40;
  font-size: ${FontSize.YETIC_TEXT};
  z-index: 200;
`

export const PopupMenuItem = styled.li`
  color: ${Colors.YETIC_BLUE_DARK};
  border-radius: 8px;
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.NEW_YETIC_GREY_LIGHT};
  cursor: pointer;
  font-weight: ${FontWeight.YETIC_MEDIUM};
  text-align: center;

  &:hover {
    background-color: ${Colors.YETIC_GREY_DARK};
  }
`
