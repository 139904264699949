import styled from '@emotion/styled'

import FontSize from '../../../../../../constants/fontSize'
import FontWeight from '../../../../../../constants/fontWeight'
import Colors from '../../../../../../constants/colors'

export const ArrowBackContainer = styled.div`
  top: 10px;
  position: absolute;
  left: 0;
`

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  border-bottom: solid ${Colors.YETIC_BLUE_DARK} 1px;
  background-color: ${Colors.NEW_YETIC_GREY_BACKGROUND};
`

export const HomeButton = styled.button`
  font-size: ${FontSize.YETIC_TEXT_MEDIUM};
  font-weight: ${FontWeight.YETIC_BOLD};
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`

export const HeaderImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
`
