import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../../constants/margin'
import Colors from '../../../../../constants/colors'
import FontSize from '../../../../../constants/fontSize'
import Padding from '../../../../../constants/padding'
import FontWeight from '../../../../../constants/fontWeight'

export const Tip = styled.div`
  font-size: ${FontSize.YETIC_HOME_BOX_TITLE};
  background-color: ${Colors.YETIC_BLUE_EXTRA_LIGHT};
  border-radius: 10px 0;
  width: fit-content;
  align-self: flex-end;
  padding: ${Padding.p3} ${Padding.p4};
  margin-top: -10px;
  text-decoration: underline;
  cursor: pointer;
`

export const launchDateStyle: CSSProperties = {
  marginRight: Margin.m5,
  marginTop: Margin.m5
}

export const AdDetailsListContainer = styled.div`
  border: 1px solid ${Colors.YETIC_GREY_DARK};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: ${Margin.m6};
  padding: ${Padding.p1} ${Padding.p6} ${Padding.p6};
  box-sizing: border-box;
  width: 49%;
  max-height: 500px;
`

export const AdPreviewContainer = styled.div`
  border-radius: 10px;
  background-color: ${Colors.NEW_YETIC_GREY};
  margin-top: ${Margin.m6};
  padding: ${Padding.p1} 0 ${Padding.p5};
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const AdPreviewTitle = styled.h2`
  font-size: ${FontSize.YETIC_TITLE_THIN};
  font-weight: ${FontWeight.YETIC_MEDIUM};
  margin-bottom: ${Margin.m6};
  text-align: center;
`

export const PreviewImage = styled.img`
  max-width: 40vw;
  max-height: 50vh;
`
