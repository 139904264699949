import { backendURL } from '../../../constants/urls'
import {
  CampaignReviewApiType,
  checkIfCampaignReviewApi
} from '../../../types/campaign/review'
import { Status } from '../../../types/status'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : manage error
export type GetCampaignReviewOutputType = CampaignReviewApiType | null

export type GetCampaignReviewInput = {
  campaignId: string
}

export const getCampaignReview = async ({
  campaignId
}: GetCampaignReviewInput): Promise<GetCampaignReviewOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/campaigns/${campaignId}/review?navigation=1`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      try {
        const { campaign, ads, navigation } = response.data
        const adsWithStatus = Array.isArray(ads)
          ? ads.map((ad) => ({ ...ad, status: Status.DRAFT }))
          : []
        const reviewOutput = {
          campaign: {
            ...campaign,
            consumedCo2: 0
          },
          ads: adsWithStatus,
          navigation
        }
        if (checkIfCampaignReviewApi(reviewOutput)) {
          return reviewOutput
        }
      } catch (_) {
        return null
      }
    }
    return null
  } catch (_) {
    return null
  }
}
