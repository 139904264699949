export enum InseeApiError {
  MISSING_TOKEN = 'MISSING_TOKEN',
  MISSING_SIREN = 'MISSING_SIREN',
  NO_DATA_FOUND = 'NO_DATA_FOUND',
  API_ERROR = 'API_ERROR'
}

export const InseeApiErrorTranslation: Record<InseeApiError, string> = {
  [InseeApiError.MISSING_TOKEN]: "Le token de l'API est manquant",
  [InseeApiError.MISSING_SIREN]: 'Le SIREN est manquant',
  [InseeApiError.NO_DATA_FOUND]: 'Aucune donnée trouvée',
  [InseeApiError.API_ERROR]:
    "Une erreur inconnue provenant de l'API Sirene est survenue. Veuillez entrer manuellement les informations de votre entreprise."
}
