import { AdNavigation, AdType } from '../ad'
import { checkIfStatus } from '../../status'
import { checkIfFormatApiEnum } from '../../format'

export type GoogleImageAdType = {
  id: string
  name: string
  businessName: string
  url: string
  logo: string
  headline1: string
  headline2: string
  headline3: string
  longHeadline: string
  description1: string
  description2: string
  description3: string
  marketingImage1: string
  marketingImage2: string
  marketingImage3: string
  squareMarketingImage1: string
  squareMarketingImage2: string
  squareMarketingImage3: string
}

export type FullGoogleImageAdType = AdType &
  Omit<GoogleImageAdType, 'id' | 'name'>

export type GoogleImageAdDetails = FullGoogleImageAdType & {
  startDate?: string
  feedback?: string
  navigation: AdNavigation
}

export type ImageAdFiles = {
  logo: File | null
  marketingImage1: File | null
  marketingImage2: File | null
  marketingImage3: File | null
  squareMarketingImage1: File | null
  squareMarketingImage2: File | null
  squareMarketingImage3: File | null
}

export type ImageAdCreationFormDataType = {
  name: string
  businessName: string
  campaignId: string
  url: string
  headline1: string
  headline2: string
  headline3: string
  longHeadline: string
  description1: string
  description2: string
  description3: string
} & ImageAdFiles

export const checkIfGoogleImageAd = (
  value: any
): value is GoogleImageAdType => {
  return (
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.businessName &&
    typeof value.businessName === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.logo &&
    typeof value.logo === 'string' &&
    value.headline1 &&
    typeof value.headline1 === 'string' &&
    (value.headline2 === undefined || typeof value.headline2 === 'string') &&
    (value.headline3 === undefined || typeof value.headline3 === 'string') &&
    value.longHeadline &&
    typeof value.longHeadline === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    (value.description2 === undefined ||
      typeof value.description2 === 'string') &&
    (value.description3 === undefined ||
      typeof value.description3 === 'string') &&
    value.marketingImage1 &&
    typeof value.marketingImage1 === 'string' &&
    (value.marketingImage2 === undefined ||
      typeof value.marketingImage2 === 'string') &&
    (value.marketingImage3 === undefined ||
      typeof value.marketingImage3 === 'string') &&
    value.squareMarketingImage1 &&
    typeof value.squareMarketingImage1 === 'string' &&
    (value.squareMarketingImage2 === undefined ||
      typeof value.squareMarketingImage2 === 'string') &&
    (value.squareMarketingImage3 === undefined ||
      typeof value.squareMarketingImage3 === 'string')
  )
}

export const checkIfGoogleImageAdDetails = (
  value: any
): value is GoogleImageAdDetails => {
  return (
    value.name &&
    typeof value.name === 'string' &&
    value.businessName &&
    typeof value.businessName === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.logo &&
    typeof value.logo === 'string' &&
    value.headline1 &&
    typeof value.headline1 === 'string' &&
    (value.headline2 === undefined || typeof value.headline2 === 'string') &&
    (value.headline3 === undefined || typeof value.headline3 === 'string') &&
    value.longHeadline &&
    typeof value.longHeadline === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    value.marketingImage1 &&
    typeof value.marketingImage1 === 'string' &&
    (value.marketingImage2 === undefined ||
      typeof value.marketingImage1 === 'string') &&
    (value.marketingImage3 === undefined ||
      typeof value.marketingImage1 === 'string') &&
    value.squareMarketingImage1 &&
    typeof value.squareMarketingImage1 === 'string' &&
    (value.squareMarketingImage2 === undefined ||
      typeof value.squareMarketingImage1 === 'string') &&
    (value.squareMarketingImage3 === undefined ||
      typeof value.squareMarketingImage1 === 'string') &&
    value.status &&
    checkIfStatus(value.status)
  )
}

export const checkIfGoogleImageAdArray = (
  data: any
): data is GoogleImageAdType[] => {
  return (
    Array.isArray(data) &&
    data.every((imageAd: any) => checkIfGoogleImageAd(imageAd))
  )
}

export const checkIfFullGoogleImageAd = (
  value: any
): value is FullGoogleImageAdType => {
  return (
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    checkIfStatus(value.status) &&
    value.businessName &&
    typeof value.businessName === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.logo &&
    typeof value.logo === 'string' &&
    value.headline1 &&
    typeof value.headline1 === 'string' &&
    (value.headline2 === undefined || typeof value.headline2 === 'string') &&
    (value.headline3 === undefined || typeof value.headline3 === 'string') &&
    value.longHeadline &&
    typeof value.longHeadline === 'string' &&
    value.description1 &&
    typeof value.description1 === 'string' &&
    (value.description2 === undefined ||
      typeof value.description2 === 'string') &&
    (value.description3 === undefined ||
      typeof value.description3 === 'string') &&
    value.marketingImage1 &&
    typeof value.marketingImage1 === 'string' &&
    (value.marketingImage2 === undefined ||
      typeof value.marketingImage2 === 'string') &&
    (value.marketingImage3 === undefined ||
      typeof value.marketingImage3 === 'string') &&
    value.squareMarketingImage1 &&
    typeof value.squareMarketingImage1 === 'string' &&
    (value.squareMarketingImage2 === undefined ||
      typeof value.squareMarketingImage2 === 'string') &&
    (value.squareMarketingImage3 === undefined ||
      typeof value.squareMarketingImage3 === 'string')
  )
}

export const checkIfFullGoogleImageAdArray = (
  data: any
): data is FullGoogleImageAdType[] => {
  return (
    data &&
    Array.isArray(data) &&
    data.every((fullImageAd: any) => checkIfFullGoogleImageAd(fullImageAd))
  )
}

export type GoogleAdImages = {
  logo: string | null
  marketingImage1: string | null
  marketingImage2: string | null
  marketingImage3: string | null
  squareMarketingImage1: string | null
  squareMarketingImage2: string | null
  squareMarketingImage3: string | null
}

export const emptyImages: GoogleAdImages = {
  logo: null,
  marketingImage1: null,
  marketingImage2: null,
  marketingImage3: null,
  squareMarketingImage1: null,
  squareMarketingImage2: null,
  squareMarketingImage3: null
}

export const getGoogleAdImagesKeys = (): (keyof GoogleAdImages)[] => [
  'logo',
  'marketingImage1',
  'marketingImage2',
  'marketingImage3',
  'squareMarketingImage1',
  'squareMarketingImage2',
  'squareMarketingImage3'
]
