import { ReportingAPISectionEnum } from '../../../types/reporting/apiSection'
import {
  ReportingTabKeyEnum,
  getReportingTabKeyEnumTranslation
} from '../../../types/reporting/tabs'

export const getMediaPlanReportingTabs = () => {
  return [
    {
      key: ReportingTabKeyEnum.impact,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.impact),
      fetch: ReportingAPISectionEnum.impact
    },
    {
      key: ReportingTabKeyEnum.overall,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.overall),
      fetch: ReportingAPISectionEnum.overall
    },
    {
      key: ReportingTabKeyEnum.clickImpressionCost,
      name: getReportingTabKeyEnumTranslation(
        ReportingTabKeyEnum.clickImpressionCost
      ),
      fetch: ReportingAPISectionEnum.overall
    },
    {
      key: ReportingTabKeyEnum.devices,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.devices),
      fetch: ReportingAPISectionEnum.devices
    },
    {
      key: ReportingTabKeyEnum.audiences,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.audiences),
      fetch: ReportingAPISectionEnum.audiences
    },
    {
      key: ReportingTabKeyEnum.campaigns,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.campaigns),
      fetch: ReportingAPISectionEnum.campaigns
    }
  ]
}
