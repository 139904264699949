import React from 'react'
import { TipsFormatProps } from '../../components/Tips/TipsFormat'

import { SectionType, checkIfSectionType } from './section'

export const isJSXSection = (
  section: SectionType | JSX.Element
): section is JSX.Element => {
  return React.isValidElement(section)
}

export type PanelType = {
  title: string
  description?: string
  disabled: boolean
  titleTooltip?: string | JSX.Element
  sections: (SectionType | JSX.Element)[]
  isDropdown: boolean
  isChecked?: boolean
  setIsChecked?: (value: boolean) => void
  isShown?: boolean
  tip?: { title: string; content: string }
  tipsFormat?: TipsFormatProps
}

export const checkIfPanelType = (element: any): element is PanelType => {
  return (
    element &&
    element.title !== undefined &&
    typeof element.title === 'string' &&
    ((element.description !== undefined &&
      typeof element.description === 'string') ||
      element.description === undefined) &&
    element.disabled !== undefined &&
    typeof element.disabled === 'boolean' &&
    element.sections !== undefined &&
    Array.isArray(element.sections) &&
    element.sections.every(
      (section: any) => checkIfSectionType(section) || isJSXSection(section)
    ) &&
    element.isDropdown !== undefined &&
    typeof element.isDropdown === 'boolean' &&
    ((element.isShown !== undefined && typeof element.isShown === 'boolean') ||
      element.isShown === undefined)
  )
}
