import React from 'react'
import TipsFormat from '../../../components/Tips/TipsFormat'

export const AudienceTipsSelfEditor = () => (
  <TipsFormat
    title={`Définition du public cible`}
    items={[
      {
        title: 'Public cible',
        content: [
          ` Le public cible est le public visé par vos campagnes publicitaires. Il est défini par différentes contraintes
        (par exemple, où se situent les personnes que vous souhaitez atteindre). Chaque campagne appartenant à votre plan
        publicitaire sera rattachée à un public cible. Il est nécessaire de définir pour le plan publicitaire tous les
        publics cibles qui seront utilisés par vos campagnes.`
        ]
      },
      {
        title: 'Campagnes ciblées',
        content: [
          `Alignez chaque campagne avec un public cible spécifique. Une segmentation efficace et une planification basée sur des objectifs clairs conduisent à des résultats supérieurs.`
        ]
      }
    ]}
  />
)

// Le public cible est le public ciblé par vos campagnes publicitaires. Il est défini par différentes contraintes
//       (par exemple où se situent les personnes que vous souhaitez toucher). Chaque campagne appartenant à votre plan
//       publicitaire sera rattaché à un public cible. Il est nécessaire de définir pour le plan publicitaire tous les
//       public cibles qui seront utilisés par vos campagnes.
