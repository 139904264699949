import { backendURL } from '../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'
import {
  checkIfMediaPlanAudience,
  MediaPlanAudience
} from '../../../types/mediaPlanAudience'

export type GetAudienceByIdInput = {
  id: string
}

export const getAudienceById = async ({
  id
}: GetAudienceByIdInput): Promise<MediaPlanAudience | null> => {
  try {
    if (id) {
      const response = await axiosGet({
        url: `${backendURL}/audiences/${id}`
      })
      if (
        checkIsAxiosGetOutputSuccess(response) &&
        checkIfMediaPlanAudience(response.data)
      ) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}
