import React from 'react'

import Modal from '../../../components/Modal'
import DefaultText from '../../../components/DefaultText'
import {
  DivReasonOfReject,
  modalReasonOfRejectStyle,
  reasonStyle,
  titleStyle
} from './styles'

export type ModalReasonOfRejectType = {
  onClose: () => void
  mediaPlanName: string
  rejectionReason: string
}

const ModalReasonOfReject = ({
  onClose,
  mediaPlanName,
  rejectionReason
}: ModalReasonOfRejectType) => {
  return (
    <>
      <Modal onClose={onClose} style={modalReasonOfRejectStyle}>
        <DefaultText
          size="big"
          style={titleStyle}
        >{`Rejet du plan média "${mediaPlanName}"`}</DefaultText>
        <DivReasonOfReject>
          <DefaultText>
            {`Le plan média a été rejeté par l'annonceur. Vous pouvez éditer le plan média et son contenu et l'envoyer à nouveau en validation. Les raisons du rejet sont les suivantes :`}
          </DefaultText>
          <DefaultText style={reasonStyle} bold>
            {rejectionReason}
          </DefaultText>
        </DivReasonOfReject>
      </Modal>
    </>
  )
}

export default ModalReasonOfReject
