import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import LoadingPage from '../../../LoadingPage'
import { PanelType } from '../../../../types/templates/panels'
import { SectionType } from '../../../../types/templates/section'
import DisabledSection from '../../../../templates/EditPageTemplate/DisabledSection'
import Input from '../../../../components/Input'
import Margin from '../../../../constants/margin'
import { TextAdDetails } from '../../../../types/ad/google/textAd'
import {
  getAdById,
  checkIfGetAdOutputGoogleTextAdDetails
} from '../../../../helpers/queries/ad/getAdById'
import { FormatAPIEnum } from '../../../../types/format'
import { AppContext } from '../../../../contexts/AppContext'
import ErrorPage from '../../../ErrorPage'
import GooglePreview from '../../../../components/PreviewAds/Google/Text'
import { PlatformAPIEnum } from '../../../../types/platform'
import EditorTextAdReview from './editor'
import SelfEditorTextAdReview from './selfEditor'
import AdvertiserTextAdReview from './advertiser'

export type TextAdEditErrors = {
  titles: string[]
  descriptions: string[]
  errorFromApi: string
}

type AddToPanelsParams = {
  title: string
  sections: (SectionType | JSX.Element)[]
  isDropdown?: boolean
  disabled?: boolean
}

const TextAdReview = () => {
  const [textAd, setTextAd] = useState<TextAdDetails | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { adId } = useParams()
  const { isEditor, isSelfEditor } = useContext(AppContext)

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const data = await getAdById({
          adId,
          adType: FormatAPIEnum.TEXT,
          platform: PlatformAPIEnum.GOOGLE
        })
        if (data && checkIfGetAdOutputGoogleTextAdDetails(data)) {
          const textAdData = {
            id: data.ad.id,
            format: data.ad.format,
            name: data.ad.name,
            url: data.ad.url,
            title1: data.ad.title1,
            title2: data.ad.title2,
            title3: data.ad.title3,
            title4: data.ad.title4,
            title5: data.ad.title5,
            title6: data.ad.title6,
            title7: data.ad.title7,
            title8: data.ad.title8,
            description1: data.ad.description1,
            description2: data.ad.description2,
            description3: data.ad.description3,
            status: data.ad.status,
            navigation: data.navigation
          }

          setTextAd(textAdData)
        } else {
          console.error(
            `Something went wrong during request to get ads/${adId}`
          )
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    scrollTo(0, 0)
  }, [adId])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!textAd || !adId) {
    return <LoadingPage />
  }

  const panels: PanelType[] = []
  const addToPanels = ({
    title,
    sections,
    isDropdown = false,
    disabled = true
  }: AddToPanelsParams) => {
    panels.push({
      title,
      sections,
      isDropdown,
      disabled
    })
  }

  const titles = [
    textAd.title1,
    textAd.title2,
    textAd.title3,
    textAd.title4 ?? '',
    textAd.title5 ?? '',
    textAd.title6 ?? '',
    textAd.title7 ?? '',
    textAd.title8 ?? ''
  ]

  const descriptions = [
    textAd.description1,
    textAd.description2,
    textAd.description3 ?? ''
  ]

  type EditableTextAdFields =
    | 'title1'
    | 'title2'
    | 'title3'
    | 'title4'
    | 'title5'
    | 'title6'
    | 'title7'
    | 'title8'
    | 'description1'
    | 'description2'
    | 'description3'

  const handleChangeString = (
    value: string,
    attribute: EditableTextAdFields
  ) => {
    setTextAd({
      ...textAd,
      [attribute]: value
    })
  }

  const adPreview = (
    <GooglePreview
      url={textAd.url}
      title1={textAd.title1}
      title2={textAd.title2}
      title3={textAd.title3}
      description={textAd.description1}
      description2={textAd.description2}
      description3={textAd.description3}
    />
  )

  const nameSection = <DisabledSection value={textAd.name} />
  const urlSection = <DisabledSection value={textAd.url} />
  const titlesSection = {
    content: (
      <>
        {titles.map((title, index) => (
          <Input
            key={index}
            type="text"
            placeholder={`Titre ${index + 1}`}
            value={textAd[`title${index + 1}` as EditableTextAdFields]}
            onChange={(event) => {
              handleChangeString(
                event.target.value,
                `title${index + 1}` as EditableTextAdFields
              )
            }}
            label={`Titre ${index + 1}`}
            maxLength={30}
            showCharacterCount
            containerStyle={{ marginBottom: Margin.m6 }}
            disabled
            withBorder
            isRequired={index <= 2}
          />
        ))}
      </>
    )
  }
  const descriptionsSection = {
    content: (
      <>
        {descriptions.map((description, index) => (
          <Input
            key={index}
            type="textarea"
            placeholder={`Description ${index + 1}`}
            value={textAd[`description${index + 1}` as EditableTextAdFields]}
            onChange={(event) => {
              handleChangeString(
                event.target.value,
                `description${index + 1}` as EditableTextAdFields
              )
            }}
            label={`Description ${index + 1}`}
            maxLength={90}
            showCharacterCount
            containerStyle={{ marginBottom: Margin.m6 }}
            isRequired={index !== 2}
            disabled
            withBorder
          />
        ))}
      </>
    )
  }

  addToPanels({ title: 'Nom', sections: [nameSection] })
  addToPanels({ title: 'URL', sections: [urlSection] })
  addToPanels({ title: 'Titres', sections: [titlesSection] })
  addToPanels({
    title: 'Descriptions',
    sections: [descriptionsSection]
  })

  const TextAdReviewContent = isEditor
    ? EditorTextAdReview
    : isSelfEditor
    ? SelfEditorTextAdReview
    : AdvertiserTextAdReview

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <TextAdReviewContent
        textAd={textAd}
        adPreview={adPreview}
        panels={panels}
      />
    </AuthenticatedTemplate>
  )
}

export default TextAdReview
