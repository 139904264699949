import styled from '@emotion/styled'

import Colors from '../../../constants/colors'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px #142a4c40;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`

export const Header = styled.div`
  width: 100%;
  border: solid ${Colors.NEW_YETIC_GREY_LIGHT} 1px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
`

export const Body = styled.div<{ open: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  display: ${(props) => (props.open ? 'flex' : 'none')};
`
