import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../../constants/colors'
import FontSize from '../../../../constants/fontSize'
import FontWeight from '../../../../constants/fontWeight'
import Margin from '../../../../constants/margin'
import Padding from '../../../../constants/padding'

export const DivContainer = styled.div`
  margin: 0 ${Margin.m10};
  margin-top: ${Margin.m8};
`

export const DivRegisterContainer = styled.div`
  margin: 0 ${Margin.m10};

  @media (max-width: 1000px) {
    margin: 10px 20px;
  }
`

export const DivInputs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: self-end;
`

export const Title = styled.div`
  color: ${Colors.YETIC_BLUE_DARK};
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: bold;
  margin: 32px 0 0 0;
  text-align: left;
`

export const SubTitle = styled.div`
  color: ${Colors.YETIC_BLUE_DARK};
  opacity: 0.5;
  font-size: ${FontSize.YETIC_INPUT_TEXT};
  line-height: 12px;
  font-weight: ${FontWeight.YETIC_MEDIUM};
  margin: ${Margin.m2} 0 ${Margin.m5} 0;
  text-align: left;
`

export const StructureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Margin.m8};
`

export const ChoiceContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  width: 48%;
  margin-top: ${Margin.m5};
  text-align: left;
`

export const InputRadioContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  &:checked + div::after {
    content: '';
    position: absolute;
    background-color: ${Colors.YETIC_BLUE_DARK};
    border-radius: 50px;
    width: 9px;
    height: 9px;
    top: 3px;
    left: 3px;
  }
`

export const StyledInput = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50px;
  border: solid 1px #9aa0aa;
  background-color: ${Colors.YETIC_WHITE};
`

export const LabelContainer = styled.label<{ checked?: boolean }>`
  margin-left: ${Margin.m3};
  padding: ${Padding.p3};
  flex: 1;
  border-radius: 10px;
  border: ${(props) =>
    props.checked
      ? `solid 1px ${Colors.YETIC_BLUE_DARK}`
      : `solid 1px transparent`};
  cursor: pointer;
`

export const LabelTitle = styled.div`
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: ${FontWeight.YETIC_BOLD};
  color: ${Colors.YETIC_BLUE_DARK};
  margin-bottom: ${Margin.m2};
`

export const LabelContent = styled.div`
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  color: ${Colors.YETIC_BLUE_DARK};
`

export const DocumentLink = styled.a`
  color: currentColor;
  font-weight: ${FontWeight.YETIC_BOLD};
`

export const ContainerText = styled.div`
  @media (max-width: 1000px) {
    margin-top: ${Margin.m5};
  }
`

export const infoTextStyle: CSSProperties = {
  marginTop: Margin.m2,
  marginBottom: Margin.m10,
  textAlign: 'left'
}

export const checkboxListStyle: CSSProperties = {
  background: 'none',
  height: '40px',
  padding: '0',
  textAlign: 'left'
}

export const PassswordCheckContainer = styled.div`
  margin-top: ${Margin.m5};
`
