import React, { CSSProperties } from 'react'

import { DivContainer, DivInfo, Info } from './styles'

type DisplayAdInfoTemplateType = {
  children: React.ReactNode
  style?: CSSProperties
  width?: string
  height?: string
  bgColor?: string
  bgBlur?: string
}

const DisplayAdInfoTemplate = ({
  children,
  style,
  width = '320px',
  height = '570px',
  bgColor,
  bgBlur
}: DisplayAdInfoTemplateType) => {
  return (
    <DivContainer
      style={style}
      width={width}
      height={height}
      bgColor={bgColor}
      bgBlur={bgBlur}
    >
      <DivInfo>
        <Info>i</Info>
      </DivInfo>
      {children}
    </DivContainer>
  )
}

export default DisplayAdInfoTemplate
