export enum GetMediaPlanCreationDataErrorEnum {
  UNKNOWN = 'UNKNOWN',
  NO_RESPONSE = 'NO_RESPONSE',
  WRONG_RESPONSE_FORMAT = 'WRONG_RESPONSE_FORMAT'
}

export const CreateAudienceCreationDataErrorTranslation = {
  [GetMediaPlanCreationDataErrorEnum.UNKNOWN]:
    'Une erreur inconnue est survenue',
  [GetMediaPlanCreationDataErrorEnum.NO_RESPONSE]: `Nous rencontrons 
     des difficultés pour communiquer avec le serveur. 
     Merci de bien vouloir vérifier votre connexion internet`,
  [GetMediaPlanCreationDataErrorEnum.WRONG_RESPONSE_FORMAT]:
    'Erreur de format concernant une ou plusieurs audiences reçues.'
}
