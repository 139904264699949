export enum ValidateMediaPlanErrorEnum {
  UNKNOWN = 'UNKNOWN',
  MISSING_PARAMS = 'MISSING_PARAMS',
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_VALIDATED = 'ALREADY_VALIDATED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_MATCHING = 'USER_NOT_MATCHING'
}

export const ValidateMediaPlanErrorTranslation = {
  [ValidateMediaPlanErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [ValidateMediaPlanErrorEnum.MISSING_PARAMS]: 'Des paramètres sont manquants',
  [ValidateMediaPlanErrorEnum.NOT_FOUND]: 'Le media plan est introuvable',
  [ValidateMediaPlanErrorEnum.ALREADY_VALIDATED]:
    'Ce média plan a déjà été validé',
  [ValidateMediaPlanErrorEnum.USER_NOT_FOUND]:
    "Vos données utilisateur n'ont pas été trouvées",
  [ValidateMediaPlanErrorEnum.USER_NOT_MATCHING]:
    'Vos données utilisateur ne correspondent pas à celles du média plan'
}

export const checkIfValidateMediaPlanErrorCodeValid = (
  errorCode: string
): errorCode is ValidateMediaPlanErrorEnum => {
  return Object.keys(ValidateMediaPlanErrorEnum).includes(errorCode)
}
