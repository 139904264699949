import React, { ChangeEvent, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthenticatedTemplate from '../../../templates/AuthenticatedTemplate'
import { CreationPageTemplate } from '../../../templates/CreationPageTemplate'
import { AppContext } from '../../../contexts/AppContext'
import {
  LocationData,
  MediaPlanAudience
} from '../../../types/mediaPlanAudience'
import {
  checkIfGetAudienceCreationDataOutputType,
  getAudienceCreationData
} from '../../../helpers/queries/mediaPlanAudience/getAudienceCreationData'
import {
  checkIfCreateAudienceOutput,
  createAudience
} from '../../../helpers/queries/mediaPlanAudience/create'
import { genders } from '../../../constants/gender'
import {
  CreateAudiencenErrorFromApi,
  checkIfCreateAudienceErrorCodeValid
} from '../../../types/error/audience/createAudience'
import {
  AudienceCreationErrors,
  getAudienceCreationErrors
} from './helpers/errors'
import getAllPanels from './panels'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import DefaultText from '../../../components/DefaultText'
import ErrorPage from '../../ErrorPage'

export type AudienceDataType = {
  name: string
  minAge: number
  maxAge: number
  indexSelectedGender: number
  selectedLocations: LocationData[]
  // selectedInterests: string[]
  selectedBusinessSegments: string[]
  selectedJobFunctions: string[]
  selectedJobTitles: string[]
}

export type AudienceProps = {
  customerId: string
  back: () => void
  validate: (newName: string) => void
}

const AudienceCreationPage = ({
  customerId,
  back,
  validate
}: AudienceProps) => {
  const [audienceData, setAudienceData] = useState<AudienceDataType>({
    name: '',
    indexSelectedGender: 0,
    minAge: 18,
    maxAge: 99,
    selectedLocations: [],
    // selectedInterests: [],
    selectedBusinessSegments: [],
    selectedJobFunctions: [],
    selectedJobTitles: []
  })
  const [fetchError, setFetchError] = useState('')
  // const [interests, setInterests] = useState<string[]>([])
  const [locations, setLocations] = useState<LocationData[]>([])
  const [businessSegments, setBusinessSegments] = useState<string[]>([])
  const [jobFunctions, setJobFunctions] = useState<string[]>([])
  const [jobTitles, setJobTitles] = useState<string[]>([])
  const [isGoogleChecked, setIsGoogleChecked] = useState(false)
  const [isLinkedinChecked, setIsLinkedinChecked] = useState(false)
  const [errorFromApi, setErrorFromApi] = useState<string>('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false)
  const [isWarningSubmitModalOpen, setIsWarningSubmitModalOpen] =
    useState(false)
  const [ignoreWarning, setIgnoreWarning] = useState(false)

  const { isEditor, user } = useContext(AppContext)

  useEffect(() => {
    ;(async function () {
      const data = await getAudienceCreationData()
      if (!checkIfGetAudienceCreationDataOutputType(data)) {
        setFetchError('Erreur lors de la récupération des données')
        return
      }

      // setInterests(data.interests)
      setLocations(data.locations)
      setBusinessSegments(data.businessSegments.map((segment) => segment.name))
      setJobFunctions(data.jobFunctions.map((jobFunction) => jobFunction.name))
      setJobTitles(data.jobTitles.map((jobTitle) => jobTitle.name))
    })()

    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()

  const errors = getAudienceCreationErrors({
    audienceData,
    errorFromApi,
    isGoogleSelected: isGoogleChecked,
    isLinkedinSelected: isLinkedinChecked
  })

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAudienceData({
      ...audienceData,
      name: event.target.value
    })
  }

  const handleMinAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newMinAge = parseInt(event.target.value)
    setAudienceData({
      ...audienceData,
      minAge: newMinAge
    })
  }

  const handleMaxAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newMaxAge = parseInt(event.target.value)
    setAudienceData({
      ...audienceData,
      maxAge: newMaxAge
    })
  }

  const handleChangeGender = (index: number) => {
    setAudienceData({
      ...audienceData,
      indexSelectedGender: index
    })
  }

  /* const handleAddInterests = (tag: string) => {
    setAudienceData({
      ...audienceData,
      selectedInterests: [...audienceData.selectedInterests, tag]
    })
  } */

  /* const handleRemoveInterests = (index: number) => {
    setAudienceData({
      ...audienceData,
      selectedInterests: [
        ...audienceData.selectedInterests.filter((_, i) => i !== index)
      ]
    })
  } */

  const handleNewLocationTag = (location: LocationData) => {
    setAudienceData({
      ...audienceData,
      selectedLocations: [...audienceData.selectedLocations, location]
    })
  }

  const handleRemoveLocationTags = (index: number) => {
    setAudienceData({
      ...audienceData,
      selectedLocations: [
        ...audienceData.selectedLocations.filter((_, i) => i !== index)
      ]
    })
  }

  const handleNewBusinessSegment = (tag: string) => {
    setAudienceData({
      ...audienceData,
      selectedBusinessSegments: [...audienceData.selectedBusinessSegments, tag]
    })
  }

  const handleRemoveBusinessSegment = (index: number) => {
    setAudienceData({
      ...audienceData,
      selectedBusinessSegments: [
        ...audienceData.selectedBusinessSegments.filter((_, i) => i !== index)
      ]
    })
  }

  const handleNewJobFunction = (tag: string) => {
    setAudienceData({
      ...audienceData,
      selectedJobFunctions: [...audienceData.selectedJobFunctions, tag]
    })
  }

  const handleRemoveJobFunction = (index: number) => {
    setAudienceData({
      ...audienceData,
      selectedJobFunctions: [
        ...audienceData.selectedJobFunctions.filter((_, i) => i !== index)
      ]
    })
  }

  const handleNewJobTitle = (tag: string) => {
    setAudienceData({
      ...audienceData,
      selectedJobTitles: [...audienceData.selectedJobTitles, tag]
    })
  }

  const handleRemoveJobTitle = (index: number) => {
    setAudienceData({
      ...audienceData,
      selectedJobTitles: [
        ...audienceData.selectedJobTitles.filter((_, i) => i !== index)
      ]
    })
  }

  const handleSubmit = async () => {
    const {
      minAge,
      maxAge,
      name,
      indexSelectedGender,
      selectedLocations,
      // selectedInterests,
      selectedBusinessSegments,
      selectedJobFunctions,
      selectedJobTitles
    } = audienceData

    if (!hasBeenSubmitted) {
      setHasBeenSubmitted(true)
    }

    if (Object.values(errors).filter((error) => error).length === 0) {
      if (isGoogleChecked || isLinkedinChecked || ignoreWarning) {
        let audience: MediaPlanAudience = {
          name,
          locations: selectedLocations
        }

        if (isLinkedinChecked) {
          audience = {
            ...audience,
            businessSegments: selectedBusinessSegments,
            jobFunctions: selectedJobFunctions,
            jobTitles: selectedJobTitles
          }
        }

        if (isGoogleChecked) {
          audience = {
            ...audience,
            gender: genders[indexSelectedGender].id,
            minAge,
            maxAge
            // interests: selectedInterests
          }
        }

        const result = await createAudience({
          customerId,
          audience
        })

        if (!checkIfCreateAudienceOutput(result)) {
          if (checkIfCreateAudienceErrorCodeValid(result.error)) {
            setErrorFromApi(CreateAudiencenErrorFromApi[result.error])
          } else {
            setErrorFromApi(CreateAudiencenErrorFromApi.UNKNOWN)
          }
          setTimeout(() => {
            setErrorFromApi('')
          }, 5000)
        } else {
          validate(audienceData.name)
          return true
        }
      } else {
        setIsWarningSubmitModalOpen(true)
      }
    }
  }

  if (fetchError) {
    return (
      <ErrorPage
        message={fetchError}
        action={{
          text: 'Retour au plan média',
          onClick: () => {
            back()
          }
        }}
      />
    )
  }

  const panels = getAllPanels({
    locations,
    /*     interests, */
    businessSegments,
    jobFunctions,
    jobTitles,
    audienceData,
    handleChangeName,
    handleMinAgeChange,
    handleMaxAgeChange,
    /*  handleAddInterests,
    handleRemoveInterests, */
    handleNewLocationTag,
    handleRemoveLocationTags,
    handleChangeGender,
    handleNewBusinessSegment,
    handleRemoveBusinessSegment,
    handleNewJobFunction,
    handleRemoveJobFunction,
    handleNewJobTitle,
    handleRemoveJobTitle,
    hasBeenSubmitted,
    errors,
    isGoogleChecked,
    setIsGoogleChecked,
    isLinkedinChecked,
    setIsLinkedinChecked
  })

  return (
    <AuthenticatedTemplate
      navigate={navigate}
      user={user}
      isEditor={isEditor != null ? isEditor : true}
    >
      <CreationPageTemplate
        title="Créer une audience"
        subTitle="Définissez les caractéristiques de votre nouvelle audience"
        panels={panels}
        errors={{
          errors:
            hasBeenSubmitted && errors.errorFromApi ? [errors.errorFromApi] : []
        }}
        validation={{
          wording: 'Créer mon audience',
          action: () => {
            handleSubmit()
          },
          disabled:
            hasBeenSubmitted &&
            Object.values(errors).filter((error) => error).length > 0
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            back()
          },
          disabled: false
        }}
      />
      {isWarningSubmitModalOpen && (
        <ConfirmationModal
          title="Attention !"
          onClickConfirm={() => {
            setIsWarningSubmitModalOpen(false)
            setIgnoreWarning(true)
            handleSubmit()
          }}
          onClickReject={() => {
            setIsWarningSubmitModalOpen(false)
          }}
          onClose={() => {
            setIsWarningSubmitModalOpen(false)
          }}
          textConfirm="Ignorer et créer l'audience"
          textReject="Continuer ma création"
        >
          <DefaultText style={{ textAlign: 'center' }}>
            Etes-vous certain de vouloir créer cette audience ?
          </DefaultText>
          <DefaultText>
            Cette audience sera inutilisable si vous ne sélectionnez aucun
            paramètre lié aux plateformes.
          </DefaultText>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default AudienceCreationPage
