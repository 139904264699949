import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { AppContext } from '../../contexts/AppContext'
import AnimatedLoader from '../../components/AnimatedLoader'
import { LoadingPageContainer } from './styles'

const LoadingPage = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const { isEditor } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      setIsLoaderVisible(true)
    }, 200)
  }, [])

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <LoadingPageContainer>
        {isLoaderVisible && <AnimatedLoader size={40} />}
      </LoadingPageContainer>
    </AuthenticatedTemplate>
  )
}

export default LoadingPage
