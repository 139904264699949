import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import Colors from '../../../../constants/colors'

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const labelStyle = {
  margin: '0',
  fontSize: FontSize.YETIC_TEXT_SMALL,
  color: Colors.YETIC_BLUE_DARK
}

export const descriptionStyle = {
  opacity: 0.5,
  marginTop: Margin.m1
}
