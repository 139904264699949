import React from 'react'

import DefaultText from '../../../../../components/DefaultText'
import { Co2TooltipTitle } from './styles'

const Co2Tooltip = () => {
  return (
    <div>
      <Co2TooltipTitle>
        <DefaultText bold>Détail des émissions de CO₂</DefaultText>
      </Co2TooltipTitle>
      <DefaultText bold>Réseau : -g</DefaultText>
      <DefaultText size="small">
        {`Les émissions dépendent de la source d'énergie (fossile ou renouvelable) utilisée pour
          alimenter l'infrastructure numérique, y compris les centres de données et les réseaux de transmission.`}
      </DefaultText>
      <DefaultText bold>Périphériques : -g</DefaultText>
      <DefaultText size="small">
        {`Les appareils utilisés émettent du CO2 pour visualiser les publicités.
          Les émissions dépendent de la consommation électrique de l'appareil et de la source d'énergie.`}
      </DefaultText>
    </div>
  )
}

export default Co2Tooltip
