import React, { useEffect, useState } from 'react'

import DefaultText from '../../DefaultText'
import {
  DivTips,
  DivContent,
  DivText,
  DivLine,
  TipHeader,
  TipHeaderTitle,
  TipHeaderDropdownButton
} from '../styles'
import Colors from '../../../constants/colors'
import { TipsProps } from '../../../types/tips'
import InfoIcon from '../../Icon/info'
import Margin from '../../../constants/margin'
import ArrowDown from '../../Icon/arrowDown'

export const TipsInfos = ({
  texts,
  text,
  style,
  title,
  isDropdown
}: TipsProps) => {
  const [dropdownPosition, setDropdownPosition] = useState<boolean | null>(
    isDropdown ? false : null
  )

  const handleDropDownButtonClick = () => {
    isDropdown === true &&
      dropdownPosition !== null &&
      setDropdownPosition(!dropdownPosition)
  }

  return (
    <DivTips style={style} bgColor={Colors.YETIC_PURPLE_TIPS}>
      <DivContent>
        <DivText>
          {title && (
            <TipHeader>
              <TipHeaderTitle>
                <InfoIcon />
                <DefaultText
                  style={{
                    fontWeight: 700,
                    margin: '0px',
                    marginLeft: Margin.m3
                  }}
                >
                  {title}
                </DefaultText>
              </TipHeaderTitle>
              {dropdownPosition !== null ? (
                <TipHeaderDropdownButton onClick={handleDropDownButtonClick}>
                  <DefaultText
                    style={{
                      fontWeight: 500,
                      margin: '0px',
                      marginLeft: Margin.m3,
                      color: Colors.YETIC_PURPLE_INFO_ICON
                    }}
                  >
                    {dropdownPosition ? 'Masquer' : 'Afficher'}
                  </DefaultText>
                  <ArrowDown
                    style={{
                      transform: `rotate(${
                        dropdownPosition ? '180deg' : '0deg'
                      })`
                    }}
                    color={Colors.YETIC_PURPLE_INFO_ICON}
                  />
                </TipHeaderDropdownButton>
              ) : (
                ''
              )}
            </TipHeader>
          )}
          {text && (
            <DivLine hidden={isDropdown && dropdownPosition === false}>
              <DefaultText
                size="small"
                style={{ margin: '0', marginTop: Margin.m4, width: '100%' }}
              >
                {text}
              </DefaultText>
            </DivLine>
          )}
          {texts &&
            texts?.map((text, index) => (
              <DivLine
                hidden={isDropdown && dropdownPosition === false}
                key={index}
                style={index === 0 ? { marginTop: Margin.m4 } : {}}
              >
                <DefaultText size="small" style={{ margin: '0' }}>
                  {text}
                </DefaultText>
              </DivLine>
            ))}
        </DivText>
      </DivContent>
    </DivTips>
  )
}
