import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import FontSize from '../../../constants/fontSize'
import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const Container = styled.div`
  display: flex;
  background-color: ${Colors.NEW_YETIC_GREY};
  border-radius: 10px;
  position: relative;
  height: 280px;
  margin-bottom: ${Margin.m6};
`

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const DivImage = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 0.4;
`

export const Image = styled.img<{ isNotBottom?: boolean }>`
  max-width: 80%;
  max-height: 80%;
  position: ${(props) => (props.isNotBottom ? 'static' : 'absolute')};
  bottom: 0;
  left: 50%;
  ${(props) => (props.isNotBottom ? '' : `transform: translateX(-50%);`)}
`

export const DivPagination = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  flex: 0.6;
  padding: ${Padding.p8};
  box-sizing: border-box;
`

export const Title = styled.div`
  font-size: ${FontSize.YETIC_TITLE};
  font-weight: 600;
  margin-bottom: ${Margin.m6};
`

export const Description = styled.div`
  font-size: ${FontSize.YETIC_TEXT_MEDIUM};
  margin-bottom: ${Margin.m6};
`

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Margin.m6};
`

export const buttonCancelStyle: CSSProperties = {
  marginRight: `${Margin.m4}`,
  padding: `${Padding.p3} ${Padding.p5}`,
  height: '30px'
}

export const buttonConfirmStyle: CSSProperties = {
  backgroundColor: `${Colors.YETIC_GREEN_DARK}`,
  padding: `${Padding.p3} ${Padding.p5}`,
  height: '30px'
}

export const buttonSkipStyle: CSSProperties = {
  textAlign: 'left',
  padding: '0px',
  opacity: 0.5
}
