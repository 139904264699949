import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Keywords from './Keywords'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { getCampaignById } from '../../helpers/queries/campaign/getCampaignById'
import { checkIfGoogleCampaign } from '../../types/campaign/google/googleCampaign'
import {
  GetCampaignCreationDataOutputType,
  checkGetCampaignCreationData,
  getCampaignCreationData
} from '../../helpers/queries/campaign/getCampaignCreationData'
import LoadingPage from '../LoadingPage'
import { PanelType } from '../../types/templates/panels'
import {
  formatDateToEnglishString,
  getNumberOfDaysBetweenDates,
  parseStringDate,
  verifyStringIsValidDate
} from '../../helpers/date'
import { FormatAPIEnum, getFormatEnum } from '../../types/format'
import { getLangEnum } from '../../types/lang'
import { getBidStrategyEnum } from '../../types/bidStrategy'
import { CampaignCreationError } from '../../types/pages/campaignCreation'
import { deepClone } from '../../helpers/clone'
import Period from '../components/Period'
import Budget from '../CampaignCreation/UI/Budget'
import Devices from '../CampaignCreation/UI/Devices'
import { Option } from '../../components/Dropdown'
import { DevicesEnum } from '../../types/campaign/devices'
import DisabledSection from '../../templates/EditPageTemplate/DisabledSection'
import {
  checkIfUpdateCampaign,
  updateCampaign
} from '../../helpers/queries/campaign/updateCampaign'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import { getCampaignKeywords } from '../../helpers/queries/campaign/getCampaignKeywords'
import ErrorPage from '../ErrorPage'
import { StatusTranslation, getIsEditable } from '../../types/status'
import { floorToTwoDecimals } from '../../helpers/rounds'
import InProgress from '../InProgress'
import EditorCampaignEdit from './editor'
import SelfEditorCampaignEdit from './selfEditor'
import {
  AddToPanelsParams,
  CampaignEditErrors,
  CampaignKeyword,
  CampaignType
} from './types'

const CampaignEdit = () => {
  const [campaign, setCampaign] = useState<CampaignType | null>(null)
  const [mediaPlan, setMediaPlan] =
    useState<GetCampaignCreationDataOutputType | null>(null)
  const [initialBudget, setInitialBudget] = useState(0)
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false)
  const [errorFromApi, setErrorFromApi] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [isPagePending, setIsPagePending] = useState(false)

  const navigate = useNavigate()
  const { campaignId } = useParams()
  const location = useLocation()

  const { isEditor } = useContext(AppContext)

  const startDateFromApi = useRef('')
  const endDateFromApi = useRef('')

  const params = new URLSearchParams(location.search)
  const flow = params.get('flow') as FlowEnum

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const data = await getCampaignById({
          campaignId
        })

        if (data) {
          if (checkIfGoogleCampaign(data.campaign)) {
            const campaignData = {
              name: data.campaign.name,
              format: data.campaign.format,
              goal: data.campaign.goal,
              period: data.campaign.period,
              audience: data.campaign.audiences.map((a) => a.name)[0],
              isBudgetPerDay: data.campaign.isBudgetPerDay,
              budget: data.campaign.budget,
              keywordsCount: data.campaign.keywordsCount ?? 0,
              lang: data.campaign.lang,
              devices: data.campaign.devices,
              bidStrategy: data.campaign.bidStrategy,
              status: data.campaign.status,
              platform: data.campaign.platform
            }

            const initialBudget = campaignData.budget
            campaignData.budget = campaignData.isBudgetPerDay
              ? floorToTwoDecimals(
                  campaignData.budget /
                    getNumberOfDaysBetweenDates(
                      campaignData.period.start,
                      campaignData.period.end
                    )
                )
              : campaignData.budget

            campaignData.period = {
              start: formatDateToEnglishString(
                new Date(campaignData.period.start)
              ),
              end: formatDateToEnglishString(new Date(campaignData.period.end))
            }
            startDateFromApi.current = formatDateToEnglishString(
              new Date(campaignData.period.start)
            )
            endDateFromApi.current = formatDateToEnglishString(
              new Date(campaignData.period.end)
            )
            setInitialBudget(initialBudget)
            setCampaign(campaignData)
          } else {
            setIsPagePending(true)
          }
        } else {
          console.error(
            `Something went wrong during request to /campaign/${campaignId}`
          )
          setHasFetchError(true)
          return
        }

        const creationData = await getCampaignCreationData({
          mediaPlanId: data.campaign.mediaPlanId,
          platform: data.campaign.platform
        })
        if (checkGetCampaignCreationData(creationData)) {
          setMediaPlan(creationData)
        } else {
          console.error(
            'Something went wrong during request to /campaign-creation/data'
          )
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [campaignId])

  useEffect(() => {
    if (
      isKeywordsModalOpen &&
      campaignId &&
      campaign &&
      campaign.keywords === undefined
    ) {
      ;(async function () {
        const data = await getCampaignKeywords({
          campaignId
        })
        if (!data) {
          return
        }
        setCampaign({
          ...campaign,
          keywords: data.keywords.map((k) => ({ value: k, status: 'initial' }))
        })
      })()
    }
  }, [isKeywordsModalOpen])

  useEffect(() => {
    if (isSubmitLoading) {
      ;(async function () {
        try {
          if (
            campaign &&
            campaignId &&
            campaign.period.start &&
            campaign.period.end
          ) {
            const newKeywords =
              campaign.keywords
                ?.filter((k) => k.status === 'new')
                .map((k) => k.value) ?? []

            const deletedKeywords =
              campaign.keywords
                ?.filter((k) => k.status === 'deleted')
                .map((k) => k.value) ?? []

            const dataToSubmit = {
              campaignId,
              budget: campaign.budget,
              isBudgetPerDay: campaign.isBudgetPerDay,
              startDate: new Date(campaign.period.start),
              endDate: new Date(campaign.period.end),
              devices: campaign.devices,
              audience: campaign.audience,
              newKeywords: newKeywords,
              deletedKeywords: deletedKeywords
            }

            const result = await updateCampaign(dataToSubmit)
            if (!checkIfUpdateCampaign(result)) {
              setErrorFromApi(result.error)
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            } else {
              flow === FlowEnum.creation
                ? navigate(`/campaign/${campaignId}/review`)
                : navigate(`/campaign/${campaignId}/details`)
            }
          }
        } catch (error) {
          console.error(error)
        }
        if (!hasBeenSubmitted) {
          setHasBeenSubmitted(true)
        }
        setIsSubmitLoading(false)
      })()
    }
  }, [isSubmitLoading])

  const startDate = campaign?.period.start
  const endDate = campaign?.period.end

  if (isPagePending) {
    return <InProgress />
  }

  if (
    !campaign ||
    !campaignId ||
    !mediaPlan ||
    !startDate ||
    !endDate ||
    !mediaPlan.mediaPlanStartDate ||
    !mediaPlan.mediaPlanEndDate
  ) {
    return <LoadingPage />
  }

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!getIsEditable({ status: campaign.status, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas modifier cette campagne au statut ${StatusTranslation[
          campaign.status
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Retour à la campagne',
          onClick: () => {
            navigate(`/campaign/${campaignId}/details`)
          }
        }}
      />
    )
  }

  const panels: PanelType[] = []
  const addToPanels = ({
    title,
    sections,
    isDropdown = false,
    disabled = true
  }: AddToPanelsParams) => {
    panels.push({
      title,
      sections,
      isDropdown,
      disabled
    })
  }

  const checkAudience = () => campaign.audience && campaign.audience.length > 0

  const checkBudget = () => campaign.budget > 0

  const checkCorrectBudget = () => {
    if (!campaign.isBudgetPerDay) {
      return campaign.budget <= mediaPlan.availableBudget + initialBudget
    } else {
      return (
        campaign.budget * getNumberOfDaysBetweenDates(startDate, endDate) <=
        mediaPlan.availableBudget + initialBudget
      )
    }
  }

  const checkStartDate = () => {
    const isStartDateValid = verifyStringIsValidDate(startDate)

    if (isStartDateValid) {
      const parsedStartDate = parseStringDate(startDate)
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      const isStartGreaterThanToday =
        parsedStartDate.getTime() > tomorrow.getTime()
      return isStartGreaterThanToday
    } else {
      return false
    }
  }

  const checkEndDate = () => {
    const isStartDateValid = verifyStringIsValidDate(startDate)
    const isEndDateValid = verifyStringIsValidDate(endDate)

    if (isStartDateValid && isEndDateValid) {
      const parsedStartDate = parseStringDate(startDate)
      const parsedEndDate = parseStringDate(endDate)
      const isEndGreaterThanStart =
        parsedEndDate.getTime() > parsedStartDate.getTime()
      return isEndGreaterThanStart
    } else {
      return isEndDateValid
    }
  }

  const checkKeyword = () => {
    if (campaign.format === FormatAPIEnum.TEXT) {
      return campaign.keywords
        ? campaign.keywords.filter((k) => k.status !== 'deleted').length > 0
        : campaign.keywordsCount > 0
    }
    return true
  }

  const availableDailyBudget =
    Math.floor(
      ((mediaPlan.availableBudget + initialBudget) /
        getNumberOfDaysBetweenDates(startDate, endDate)) *
        10
    ) / 10

  const errors: CampaignEditErrors = {
    audience: checkAudience() ? '' : CampaignCreationError.AUDIENCE,
    budget: checkBudget()
      ? checkCorrectBudget()
        ? ''
        : `${CampaignCreationError.AMOUNT_BUDGET_MAX} ${
            campaign.isBudgetPerDay && startDate !== endDate
              ? 'un budget journalier de ' + availableDailyBudget
              : mediaPlan.availableBudget + initialBudget
          }€.`
      : CampaignCreationError.AMOUNT_BUDGET_MIN,
    startDate: checkStartDate() ? '' : CampaignCreationError.START_DATE,
    endDate: checkEndDate() ? '' : CampaignCreationError.END_DATE,
    keyword: checkKeyword() ? '' : CampaignCreationError.KEYWORDS,
    errorFromApi: errorFromApi ?? ''
  }

  const handleChangeAudience = (newAudience: Option | null) => {
    if (newAudience == null) {
      return
    }
    setCampaign({
      ...deepClone(campaign),
      audience: newAudience.label
    })
  }

  const handleChangeDate = (date: string, attribute: 'start' | 'end') => {
    setCampaign((prevCampaign) => {
      if (!prevCampaign) return null
      const updatedCampaign = deepClone(prevCampaign)
      if (date) {
        updatedCampaign.period[attribute] = date
        if (attribute === 'start') {
          startDateFromApi.current = date
        } else {
          endDateFromApi.current = date
        }
      } else {
        updatedCampaign.period[attribute] =
          attribute === 'start'
            ? startDateFromApi.current
            : endDateFromApi.current
      }
      return updatedCampaign
    })
  }

  const handleBudgetTypeChange = (isBudgetOnPeriod: boolean) => {
    setCampaign({
      ...deepClone(campaign),
      isBudgetPerDay: !isBudgetOnPeriod
    })
  }

  const handleBudgetChange = (amount: string) => {
    // TODO check the format of amount
    setCampaign({
      ...deepClone(campaign),
      budget: parseFloat(amount)
    })
  }

  const handleRemoveKeyword = (tag: string) => {
    if (campaign.keywords !== undefined) {
      const keywordToDelete = campaign.keywords.find(
        (k) => k.value === tag
      ) as CampaignKeyword
      const updatedKeywords: CampaignKeyword[] = [
        ...campaign.keywords.filter((k) => k.value !== tag),
        { value: keywordToDelete.value, status: 'deleted' }
      ]
      setCampaign({
        ...campaign,
        keywords: updatedKeywords
      })
    }
  }

  const handleAddKeyword = (tag: string) => {
    if (
      campaign.keywords !== undefined &&
      (!campaign.keywords.map((k) => k.value).includes(tag) ||
        campaign.keywords.some(
          (k) => k.value === tag && k.status === 'deleted'
        ))
    ) {
      setCampaign({
        ...campaign,
        keywords: [...campaign.keywords, { value: tag, status: 'new' }]
      })
    }
  }

  const handleChangeDevice = (attribute: string) => {
    setCampaign({
      ...deepClone(campaign),
      devices: {
        ...campaign.devices,
        [attribute]: !campaign.devices[attribute as DevicesEnum]
      }
    })
  }

  const handleSubmitCampaign = () => {
    setIsSubmitLoading(true)
  }

  const nameSection = <DisabledSection value={campaign.name} />
  const formatSection = (
    <DisabledSection value={getFormatEnum(campaign.format)} />
  )
  const goalSection = <DisabledSection value={campaign.goal} />

  const periodSection = {
    description: 'Période de diffusion de la campagne',
    content: (
      <Period
        startDate={startDate.split('T')[0]}
        endDate={endDate.split('T')[0]}
        onChangeStartDate={(date) => {
          handleChangeDate(date, 'start')
        }}
        onChangeEndDate={(date) => {
          handleChangeDate(date, 'end')
        }}
        startDateError={hasBeenSubmitted ? errors.startDate : ''}
        endDateError={hasBeenSubmitted ? errors.endDate : ''}
        minStartDate={mediaPlan.mediaPlanStartDate.split('T')[0]}
        maxStartDate={mediaPlan.mediaPlanEndDate.split('T')[0]}
        minEndDate={mediaPlan.mediaPlanStartDate.split('T')[0]}
        maxEndDate={mediaPlan.mediaPlanEndDate.split('T')[0]}
      />
    )
  }

  const totalEnteredBudget = campaign.isBudgetPerDay
    ? campaign.budget * getNumberOfDaysBetweenDates(startDate, endDate)
    : campaign.budget

  const dynamicAvailableBudget = campaign.budget
    ? Math.floor(
        (mediaPlan.availableBudget + initialBudget - totalEnteredBudget) * 10
      ) / 10
    : mediaPlan.availableBudget + initialBudget

  const numberOfDaysBetweenDates = getNumberOfDaysBetweenDates(
    startDate,
    endDate
  )

  const totalBudgetCampaign =
    dynamicAvailableBudget + campaign.budget * numberOfDaysBetweenDates

  const maxBudgetPerPeriod = floorToTwoDecimals(
    totalBudgetCampaign / numberOfDaysBetweenDates
  )

  const totalBudget =
    floorToTwoDecimals(
      campaign.budget * getNumberOfDaysBetweenDates(startDate, endDate)
    ) || 0

  const budgetSection = {
    description: 'Budget de la campagne',
    content: (
      <Budget
        isBudgetOnPeriod={!campaign.isBudgetPerDay}
        budget={campaign.budget}
        handleBudgetTypeChange={handleBudgetTypeChange}
        handleBudgetChange={(event) => {
          handleBudgetChange(event.target.value)
        }}
        handleBudgetInputChange={handleBudgetChange}
        maxBudgetPerPeriod={maxBudgetPerPeriod}
        availableBudget={dynamicAvailableBudget}
        error={hasBeenSubmitted ? errors.budget : ''}
        totalBudget={totalBudget}
      />
    )
  }

  const keywordsSection =
    campaign.format === FormatAPIEnum.TEXT ? (
      {
        description: `Trouvez des mots-clés adaptés à la zone géographique définie`,
        content: (
          <Keywords
            count={campaign.keywordsCount}
            setIsOpen={setIsKeywordsModalOpen}
            isEditing={isKeywordsModalOpen}
            keywords={campaign.keywords?.filter((k) => k.status !== 'deleted')}
            campaignName={campaign.name}
            onAddKeyword={handleAddKeyword}
            onRemoveKeyword={handleRemoveKeyword}
          />
        )
      }
    ) : (
      <></>
    )

  const langSection = {
    title: `Langue de diffusion`,
    content: (
      <DisabledSection
        value={getLangEnum(campaign.lang)}
        style={{ marginTop: 0 }}
      />
    ),
    disabled: true
  }

  const devicesSection = {
    title: 'Types de périphériques',
    description: `Choisissez les types de périphérique
    auxquels vous souhaitez diffuser votre campagne`,
    content: (
      <Devices
        isDesktopSelected={campaign.devices.desktop}
        isSmartphoneSelected={campaign.devices.smartphone}
        isTabletSelected={campaign.devices.tablet}
        isTVSelected={campaign.devices.tv}
        handleChangeDevice={handleChangeDevice}
      />
    )
  }

  // const bidStrategySection = {
  //   title: `Type d'enchère`,
  //   content: (
  //     <DisabledSection
  //       value={getBidStrategyEnum(campaign.bidStrategy)}
  //       style={{ marginTop: 0 }}
  //     />
  //   ),
  //   disabled: true
  // }

  const CampaignEditContent = isEditor
    ? EditorCampaignEdit
    : SelfEditorCampaignEdit

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <CampaignEditContent
        campaign={campaign}
        nameSection={nameSection}
        goalSection={goalSection}
        periodSection={periodSection}
        budgetSection={budgetSection}
        errors={errors}
        flow={flow}
        campaignId={campaignId}
        isSubmitLoading={isSubmitLoading}
        handleSubmitCampaign={handleSubmitCampaign}
        formatSection={formatSection}
        keywordsSection={keywordsSection}
        langSection={langSection}
        devicesSection={devicesSection}
        // bidStrategySection={bidStrategySection}
        mediaPlan={mediaPlan}
        onChange={handleChangeAudience}
      />
    </AuthenticatedTemplate>
  )
}

export default CampaignEdit
