enum FontSize {
  YETIC_TITLE = '24px',
  YETIC_SUBTITLE = '21px',
  YETIC_TITLE_THIN = '18px',
  YETIC_TEXT = '14px',
  YETIC_TEXT_MEDIUM = '14px',
  YETIC_TEXT_SMALL = '12px',
  YETIC_PANEL_TITLE = '14px',
  YETIC_PANEL_SUBTITLE = '12px',
  YETIC_INPUT_TEXT = '12px',
  YETIC_TEXT_VERY_SMALL = '10px',
  YETIC_HOME_BOX_TITLE = '16px',
  YETIC_TITLE_H2 = '16px',
  YETIC_TITLE_HUGE = '78px'
}

export default FontSize
