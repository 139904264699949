import axios from 'axios'

import { backendURL } from '../../../constants/urls'

export type GetCompanyOutputType = {
  activity: string
  name: string
  siret: string
  address: string
  vat: string
}

type GetCompanyFromSiretInput = {
  siret: string
}

export const GetCompanyFromSiret = async ({
  siret
}: GetCompanyFromSiretInput): Promise<GetCompanyOutputType | null> => {
  try {
    const response = await axios.get(`${backendURL}/companies?siret=${siret}`)
    if (checkIfCompanyInfo(response.data)) {
      return response.data
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

const checkIfCompanyInfo = (value: any): value is GetCompanyOutputType => {
  return (
    value &&
    value.name != null &&
    typeof value.name === 'string' &&
    value.activity != null &&
    typeof value.activity === 'string' &&
    value.address != null &&
    typeof value.address === 'string' &&
    value.siret != null &&
    typeof value.siret === 'string' &&
    value.vat != null &&
    typeof value.vat === 'string'
  )
}
