import React from 'react'

import { ImageAdCreationFormDataType } from '..'
import { Option } from '../../../../../components/Dropdown'
import { linkedinCTAOptions } from '../../../../../types/ad/linkedin/linkedinCTA'
import { PanelType } from '../../../../../types/templates/panels'
import ImagesTip from '../Tips/ImagesTip'
import InfoTip from '../Tips/InfoTip'
import { ImageAdCreationErrorsType } from '../helpers/formErrors'
import getCTAPanel from './ctaPanel'
import getHeadlinePanel from './headlinePanel'
import getImagePanel from './imagePanel'
import getIntroTextPanel from './introTextPanel'
import getNamePanel from './namePanel'
import getUrlPanel from './urlPanel'
import getPreviewPanel from './previewPanel'

type GetAllPanelsProps = {
  companyName: string
  formData: ImageAdCreationFormDataType
  handleInputChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleTextAreaChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleImageDelete: () => void
  handleCTAChange: (option: Option | null) => void
  hasBeenSubmitted: boolean
  errors: ImageAdCreationErrorsType
  imageError: string
  imageUrl: string
}

const getAllPanels = ({
  companyName,
  formData,
  handleInputChange,
  handleTextAreaChange,
  handleImageChange,
  handleImageDelete,
  handleCTAChange,
  hasBeenSubmitted,
  errors,
  imageError,
  imageUrl
}: GetAllPanelsProps) => {
  const namePanel = getNamePanel({
    formData,
    handleInputChange,
    hasBeenSubmitted,
    error: errors.name
  })

  const infoTip = <InfoTip />

  const urlPanel = getUrlPanel({
    formData,
    handleInputChange,
    hasBeenSubmitted,
    error: errors.url
  })

  const headlinePanel = getHeadlinePanel({
    formData,
    handleInputChange,
    hasBeenSubmitted,
    error: errors.headline
  })

  const introTextPanel: PanelType = getIntroTextPanel({
    formData,
    handleTextAreaChange,
    hasBeenSubmitted,
    error: errors.introText
  })

  const imagesTip = <ImagesTip />

  const imageSelectionPanel: PanelType = getImagePanel({
    formData,
    handleImageChange,
    handleImageDelete,
    hasBeenSubmitted,
    error: imageError,
    imageUrl
  })

  const ctaValue = linkedinCTAOptions.find(
    (option) => option.label === formData.ctaLabel
  )
  const ctaPanel: PanelType = getCTAPanel({
    ctaValue,
    handleCTAChange,
    hasBeenSubmitted,
    error: errors.ctaLabel
  })

  const previewPanel: PanelType = getPreviewPanel({
    companyName,
    formData,
    imageUrl
  })

  const panels: (JSX.Element | PanelType)[] = [
    namePanel,
    previewPanel,
    infoTip,
    introTextPanel,
    headlinePanel,
    urlPanel,
    ctaPanel,
    imagesTip,
    imageSelectionPanel
  ]

  return panels
}

export default getAllPanels
