import React from 'react'
import Colors from '../../constants/colors'
import { IconType } from '../../types/icon'

const ArrowNext = ({
  height = '20px',
  width = '20px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{ cursor: 'pointer', ...style }}
    >
      <path
        stroke={color ?? '#000000'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 10h16m0 0l-7-7m7 7l-7 7"
      />
    </svg>
  )
}

export default ArrowNext
