import { axiosPost, checkIsAxiosPostOutputSuccess } from '../axios/post'
import { backendURL } from '../../../constants/urls'
import {
  CreatePlatformConnectionEnum,
  CreatePlatformConnectionTranslation,
  checkIfCreatePlatformConnectionCodeValid
} from '../../../types/error/platformConnection/connection'

type CreateGooglePlatformConnectionInputType = {
  email: string
}

type CreateGooglePlatformConnectionOutputType = {
  companyId: string
}

type CreateGooglePlatformConnectionErrorType = {
  error: string
}

export const createGooglePlatformConnection = async ({
  email
}: CreateGooglePlatformConnectionInputType): Promise<
  | CreateGooglePlatformConnectionOutputType
  | CreateGooglePlatformConnectionErrorType
> => {
  try {
    const response = await axiosPost({
      url: `${backendURL}/platforms/google`,
      data: {
        email
      }
    })

    if (checkIsAxiosPostOutputSuccess(response)) {
      if (checkIfCreateGoogleConnectionOutput(response.data)) {
        return response.data
      }
    } else {
      const errorCode =
        (response as any)?.error.data?.code ?? (response as any)?.error.code
      if (errorCode && checkIfCreatePlatformConnectionCodeValid(errorCode)) {
        return { error: CreatePlatformConnectionTranslation[errorCode] }
      }
    }

    return {
      error:
        CreatePlatformConnectionTranslation[
          CreatePlatformConnectionEnum.UNKNOWN
        ]
    }
  } catch (_) {
    return {
      error:
        CreatePlatformConnectionTranslation[
          CreatePlatformConnectionEnum.UNKNOWN
        ]
    }
  }
}

export const checkIfCreateGoogleConnectionOutput = (
  value: any
): value is CreateGooglePlatformConnectionOutputType => {
  return value && value.companyId && typeof value.companyId === 'string'
}
