export enum CreateMediaPlanErrorEnum {
  UNKNOWN = 'unknown',
  NAME_NOT_AVAILABLE = 'name-not-available',
  NAME_TOO_SHORT = 'name-too-short',
  NO_AUDIENCE = 'no-audience',
  WRONG_AUDIENCE = 'wrong-audience',
  START_DATE = 'min-age',
  END_DATE = 'max-age',
  BUDGET = 'location',
  NO_GOAL = 'creation-failed'
}

export const CreateMediaPlanErrorTranslation = {
  [CreateMediaPlanErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [CreateMediaPlanErrorEnum.NAME_NOT_AVAILABLE]:
    "Le nom du plan média n'est pas disponible",
  [CreateMediaPlanErrorEnum.NAME_TOO_SHORT]:
    'Le nom du plan média doit faire au minimum 3 caractères',
  [CreateMediaPlanErrorEnum.NO_AUDIENCE]:
    'Le plan média doit avoir au moins une audience',
  [CreateMediaPlanErrorEnum.WRONG_AUDIENCE]:
    'Un problème est survenu avec une des audiences sélectionnées',
  [CreateMediaPlanErrorEnum.START_DATE]: `L'âge minimal doit être supérieur ou égal à 18 ans 
    et inférieur ou égal à l'âge maximal`,
  [CreateMediaPlanErrorEnum.END_DATE]:
    "L'âge maximal doit être supérieur ou égal à l'âge minimal",
  [CreateMediaPlanErrorEnum.BUDGET]:
    'Vous devez sélectionner un budget supérieur ou égal à 1 euro.',
  [CreateMediaPlanErrorEnum.NO_GOAL]:
    'Vous devez sélectionner au moins un objectif de campagne.'
}

export enum CreateMediaPlanErrorFromApi {
  'CREATE' = `Une erreur est survenue. 
  Votre plan média n'a par conséquent pas été enregistre`,
  'MISSING_VALUE' = `Une erreur est survenue. Il manque un ou plusieurs paramètres
  à votre plan média`,
  'VALUE_ALREADY_USED' = `Le nom du plan média est déjà utilisé`,
  'UNKNOWN' = 'Une erreur inconnue est survenue'
}

export type CreateMediaPlanErrorFromApiKeys =
  keyof typeof CreateMediaPlanErrorFromApi

export const checkIfCreateMediaPlanErrorCodeValid = (
  errorCode: string
): errorCode is CreateMediaPlanErrorFromApiKeys => {
  return Object.keys(CreateMediaPlanErrorFromApi).includes(errorCode)
}
