import React from 'react'

import Margin from '../../constants/margin'
import Button from '../../components/Button'
import Title from '../../components/Title'
import { TipsEcology, TipsInfos } from '../../components/Tips'
import ReviewContent, { ReviewContentProps } from './ReviewContent'
import {
  DivButtons,
  DivError,
  DivSummary,
  DivSummaryTitle,
  Edit,
  ReviewPageContainer,
  titleStyle,
  DivTipsInfo,
  DivCO2,
  DivCO2Text,
  DivCO2Value,
  DivCO2OpenModalText
} from './styles'
import { StrategyEnum, getSubStrategy } from '../../types/strategy'
import { InputError } from '../../components/Input/Styles'
import { FormatAPIEnum } from '../../types/format'
import AnimatedLoader from '../../components/AnimatedLoader'

export type ReviewPageTemplateProps = {
  name: string
  title: string
  type: StrategyEnum
  tipsBudget?: string | JSX.Element
  tipsEcology?: string | JSX.Element | null
  tipsInfo?: string
  tipsMultipleInfos?: string[]
  isEditor?: boolean | null
  isSelfEditor?: boolean | null
  footer?: JSX.Element
  co2Header?: boolean
  co2?: string
  adCount?: number
  download?: {
    label: string
    action: () => void
    disabled: boolean
  }
  validation?: {
    label: string
    action: () => void
    disabled: boolean
    disabledMessage?: string
    type?: 'primary' | 'secondary'
    isLoading?: boolean
  }
  cancel?: {
    label: string
    action: () => void
    disabled: boolean
  }
  format?: FormatAPIEnum
  bottomMessage?: string
  handleEdit?: () => void
  handleSubEdit?: (id: string) => void
  handleSubSync?: (id: string) => Promise<boolean>
  handleDelete?: (id: string) => Promise<void>
  handleOpenModal?: () => void
} & Omit<ReviewContentProps, 'listType'>

const ReviewPageTemplate = ({
  name,
  title,
  type,
  tipsBudget,
  tipsEcology,
  tipsInfo,
  tipsMultipleInfos,
  isEditor,
  isSelfEditor,
  footer,
  co2Header,
  co2,
  adCount,
  download,
  validation,
  cancel,
  handleEdit,
  handleSubEdit,
  handleSubSync,
  container,
  handleViewMore,
  handleDelete,
  format,
  handleOpenModal,
  bottomMessage
}: ReviewPageTemplateProps) => {
  const isMediaPlan = type === StrategyEnum.MEDIA_PLAN
  const displayCo2Header = co2Header && adCount !== 0

  return (
    <ReviewPageContainer>
      <Title style={titleStyle}>{`${title} « ${name} »`}</Title>
      <DivSummary>
        {displayCo2Header && (
          <DivCO2 co2Header={co2Header}>
            <DivCO2Text>
              {isMediaPlan
                ? isSelfEditor
                  ? 'Votre plan publicitaire va générer'
                  : 'Votre plan média va générer'
                : 'Votre campagne va générer'}{' '}
              environ <DivCO2Value>{co2}</DivCO2Value> de CO2
            </DivCO2Text>
            {!isMediaPlan && (
              <DivCO2OpenModalText onClick={handleOpenModal}>
                Comprendre et diminuer mon empreinte carbone
              </DivCO2OpenModalText>
            )}
          </DivCO2>
        )}

        <DivSummaryTitle>
          {handleEdit && (isEditor || isSelfEditor) && (
            <Edit onClick={handleEdit}>
              {isMediaPlan
                ? isSelfEditor
                  ? 'Modifier mon plan publicitaire'
                  : 'Modifier mon plan média'
                : 'Modifier ma campagne'}
            </Edit>
          )}
        </DivSummaryTitle>
        {container?.container?.column1 ?? null}
        {container?.container?.column2 ?? null}
        {tipsEcology && (
          <TipsEcology
            text={tipsEcology}
            title="EMPREINTE CARBONE"
            style={{ marginBottom: Margin.m8 }}
          />
        )}
        <ReviewContent
          container={container}
          listType={getSubStrategy(type)}
          handleViewMore={handleViewMore}
          handleSync={handleSubSync}
          handleDelete={handleDelete}
          handleEdit={handleSubEdit}
          isEditor={isEditor}
          isSelfEditor={isSelfEditor}
          footer={footer}
          tipsBudget={tipsBudget}
          format={format}
          bottomMessage={bottomMessage}
        />
      </DivSummary>
      {validation?.disabled && validation?.disabledMessage && (
        <DivError>
          <InputError>{validation?.disabledMessage}</InputError>
        </DivError>
      )}
      {(isEditor || isSelfEditor) && tipsInfo && (
        <DivTipsInfo>
          <TipsInfos
            text={tipsInfo}
            title="INFORMATION"
            style={{ marginBottom: Margin.m4 }}
          />
        </DivTipsInfo>
      )}
      {!isEditor && tipsMultipleInfos && (
        <DivTipsInfo>
          <TipsInfos
            texts={tipsMultipleInfos}
            title="INFORMATIONS"
            style={{ marginBottom: Margin.m4 }}
          />
        </DivTipsInfo>
      )}
      <DivButtons>
        {cancel && cancel.label && (
          <Button type="secondary" width="auto" onClick={cancel?.action}>
            {cancel.label}
          </Button>
        )}
        {download && (
          <Button
            type="tertiary"
            width="auto"
            onClick={download?.action}
            disabled={download?.disabled}
          >
            {download?.label}
          </Button>
        )}
        {validation && (
          <Button
            type={validation.type ?? 'primary'}
            width="auto"
            style={{ minWidth: '150px' }}
            onClick={validation?.action}
            disabled={validation?.disabled}
          >
            {validation.isLoading ? (
              <AnimatedLoader color="white" />
            ) : (
              validation?.label
            )}
          </Button>
        )}
      </DivButtons>
    </ReviewPageContainer>
  )
}

export default ReviewPageTemplate
