import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  ConnectionStatus,
  ContentContainer,
  Header,
  PlatformTitle,
  PlatformsPageContainer,
  PlatformsPageRow,
  RowTextContainer,
  textStyle,
  titleStyle
} from './styles'
import GoogleConnectionForm from './GoogleConnectionForm'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { AppContext } from '../../contexts/AppContext'
import Button from '../../components/Button'
import Title from '../../components/Title'
import { PlatformAPIEnum } from '../../types/platform'
import { getPlatformsConnection } from '../../helpers/queries/platformConnection/getPlatformsConnection'
import { checkEmail, checkIfEmptyErrors } from '../../helpers/checkers'
import { ConnectToPlatformError } from '../../types/error/platformConnection/connection'
import { getCustomers } from '../../helpers/queries/customer/getCustomers'
import { ViewEnum } from '../../types/axios/common'
import { CustomerNames } from '../../types/customer'
import DefaultText from '../../components/DefaultText'
import PlatformLogo from '../../components/PlatformLogo'
import { StatusDot } from '../../components/StatusDot/styles'
import {
  GoogleStatus,
  GoogleStatusColor,
  LinkedinStatus,
  LinkedinStatusColor,
  checkIfGoogleStatus,
  getGoogleStatusTranslation,
  getLinkedinStatusTranslation
} from '../../types/platformConnection'
import {
  checkIfCreateGoogleConnectionOutput,
  createGooglePlatformConnection
} from '../../helpers/queries/platformConnection/createGooglePlatformConnection'
import { getLinkedinAuth } from '../../helpers/queries/platformConnection/linkedinAuth'

type ContentType = {
  platform: PlatformAPIEnum
  title: string
  rows: {
    label?: string
    value: boolean | GoogleStatus | LinkedinStatus
    btnText?: string
    btnAction?: () => void
    disabled?: boolean
  }[]
}[]

type PlatformsConnection = {
  google: boolean | GoogleStatus
  linkedin: Record<string, boolean | LinkedinStatus>
}

export type ErrorsType = {
  email: string
}

const PlatformsPage = () => {
  const [openForm, setOpenForm] = useState<PlatformAPIEnum | null>(null)
  const [platformsConnection, setPlatformsConnection] =
    useState<PlatformsConnection | null>(null)
  const [customerNames, setCustomerNames] = useState<CustomerNames[] | null>(
    null
  )
  const [hasFetchError, setHasFetchError] = useState(false)
  const [email, setEmail] = useState('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [submitValidationMessage, setSubmitValidationMessage] = useState('')
  const [submitError, setSubmitError] = useState('')

  const { isEditor, user } = useContext(AppContext)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    ;(async function () {
      const platformsConnectionResult = await getPlatformsConnection()
      if (platformsConnectionResult == null) {
        setHasFetchError(true)
        return
      }

      const customerNamesResult = await getCustomers({ view: ViewEnum.NAME })
      if (customerNamesResult == null) {
        setHasFetchError(true)
        return
      }

      setPlatformsConnection(platformsConnectionResult)
      setCustomerNames(customerNamesResult)
    })()

    setEmail(user?.email ?? '')
  }, [])

  useEffect(() => {
    if (submitValidationMessage) {
      ;(async function () {
        const platformsConnectionResult = await getPlatformsConnection()
        if (platformsConnectionResult == null) {
          setHasFetchError(true)
          return
        }

        setPlatformsConnection(platformsConnectionResult)
      })()
    }
  }, [submitValidationMessage])

  useEffect(() => {
    if (isSubmitLoading) {
      ;(async function () {
        if (openForm === PlatformAPIEnum.GOOGLE) {
          const createGoogleConnectionResult =
            await createGooglePlatformConnection({
              email
            })
          if (
            checkIfCreateGoogleConnectionOutput(createGoogleConnectionResult)
          ) {
            setSubmitValidationMessage(
              'Votre demande a bien été prise en compte. Vous recevrez un email de confirmation dès que votre compte sera connecté.'
            )
          } else {
            setSubmitError(createGoogleConnectionResult.error)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
          setIsSubmitLoading(false)
        }
        // TODO : handle linkedin
      })()
    }
  }, [isSubmitLoading])

  const params = new URLSearchParams(location.search)

  const fromId = params.get('from')

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (platformsConnection == null) {
    return <LoadingPage />
  }

  const errors = {
    email: checkEmail(email) ? '' : ConnectToPlatformError.EMAIL
  }

  const handleBackToFromId = () => {
    fromId && navigate(`/media-plan/${fromId}/review`)
  }

  const handleSubmit = () => {
    if (checkIfEmptyErrors(errors)) {
      setIsSubmitLoading(true)
    }
    setHasBeenSubmitted(true)
  }

  const handleClose = () => {
    setOpenForm(null)
    setSubmitValidationMessage('')
    setHasBeenSubmitted(false)
  }

  const getStatusTranslation = (
    status: GoogleStatus | LinkedinStatus | boolean
  ) => {
    if (typeof status === 'boolean') {
      return 'Non connecté'
    } else if (checkIfGoogleStatus(status)) {
      return getGoogleStatusTranslation(status)
    } else {
      return getLinkedinStatusTranslation(status)
    }
  }

  const getStatusColor = (status: GoogleStatus | LinkedinStatus | boolean) => {
    if (typeof status === 'boolean') {
      return '#AFAFAF'
    } else if (checkIfGoogleStatus(status)) {
      return GoogleStatusColor[status]
    } else {
      return LinkedinStatusColor[status]
    }
  }

  const platformsContent: ContentType = [
    {
      platform: PlatformAPIEnum.GOOGLE,
      title: 'Google Ads',
      rows: [
        {
          value: platformsConnection.google,
          btnAction: () => {
            setOpenForm(PlatformAPIEnum.GOOGLE)
          }
        }
      ]
    },
    ...(process.env.REACT_APP_DISABLE_LINKEDIN === '1'
      ? []
      : [
          {
            platform: PlatformAPIEnum.LINKEDIN,
            title: 'Linkedin',
            rows:
              // TODO : uncomment when several linkedin connections for one account is ready
              // isEditor ? customerNames?.map((customer) => {
              //     return {
              //       label: customer.name,
              //       value: platformsConnection.linkedin[customer.id],
              //       btnText: 'Connecter ce client',
              //       btnAction: () => {
              //         getLinkedinAuth(customer.id)
              //       }
              //     }
              //   }) ?? []
              isEditor
                ? customerNames?.map((customer) => {
                    return {
                      label: customer.name,
                      value: platformsConnection.linkedin[customer.id],
                      btnText: 'Connexion à venir',
                      btnAction: () => {},
                      disabled: true
                    }
                  }) ?? []
                : [
                    {
                      value: Object.values(platformsConnection.linkedin)[0],
                      btnAction: () => {
                        getLinkedinAuth(
                          Object.keys(platformsConnection.linkedin)[0]
                        )
                      }
                    }
                  ]
          }
        ])
  ]

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <PlatformsPageContainer>
        {fromId && (
          <Button
            onClick={handleBackToFromId}
            width={'auto'}
            type={'primary'}
            marginTop={'28px'}
          >
            Retour au plan média
          </Button>
        )}
        <Header>
          <Title>Connexion aux plateformes</Title>
        </Header>

        <ContentContainer>
          {platformsContent.map((item) => (
            <React.Fragment key={item.platform}>
              <PlatformTitle>
                <PlatformLogo platform={item.platform} />
                <DefaultText size="big" style={titleStyle}>
                  {item.title}
                </DefaultText>
              </PlatformTitle>
              {item.rows.map((row, index) => (
                <PlatformsPageRow key={index}>
                  <RowTextContainer isConnected={!!row.value}>
                    {row.label && (
                      <DefaultText size="medium" style={textStyle} bold>
                        {row.label}
                      </DefaultText>
                    )}
                    <ConnectionStatus>
                      <DefaultText
                        size="medium"
                        style={{ ...textStyle, justifySelf: 'flex-end' }}
                      >
                        {getStatusTranslation(row.value)}
                      </DefaultText>
                      <StatusDot color={getStatusColor(row.value)} />
                    </ConnectionStatus>
                  </RowTextContainer>
                  {row.btnAction && !row.value && (
                    <Button
                      type="secondary"
                      onClick={row.btnAction}
                      width="auto"
                      disabled={row.disabled ?? false}
                    >
                      {row.btnText ?? 'Me connecter'}
                    </Button>
                  )}
                </PlatformsPageRow>
              ))}
            </React.Fragment>
          ))}
        </ContentContainer>

        {openForm && openForm === PlatformAPIEnum.GOOGLE && (
          <GoogleConnectionForm
            onClose={handleClose}
            handleSubmit={handleSubmit}
            email={email}
            setEmail={setEmail}
            formErrors={errors}
            isSubmitLoading={isSubmitLoading}
            hasBeenSubmitted={hasBeenSubmitted}
            submitValidationMessage={submitValidationMessage}
            submitError={submitError}
          />
        )}
      </PlatformsPageContainer>
    </AuthenticatedTemplate>
  )
}

export default PlatformsPage
