const calcRightDistance = () => {
  const maxWidth = 1600
  let marginWidth = (window.innerWidth - maxWidth) / 2

  if (marginWidth < 0) {
    marginWidth = 0
  }

  const distanceToRight = marginWidth + 20

  return distanceToRight
}

export default calcRightDistance
