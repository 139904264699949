import React from 'react'

import AdDetailsContentImage from './AdDetailsContentImage'
import { PreviewImage, launchDateStyle } from './styles'
import { BreadcrumbProps } from '../../../../components/BreadCrumb'
import { formatDateToFrench } from '../../../../helpers/date'
import DetailsPageTemplate from '../../../../templates/DetailsPageTemplate'
import LabelledItem, {
  LabelledItemType
} from '../../../../components/LabelledItem'
import LabelledItemsList from '../../../../components/LabelledItemsList'
import { PlatformEnum } from '../../../../types/platform'
import StatusWithColor from '../../../../components/StatusDot'
import ModalReasonOfReject from '../../../parts/ModalReasonOfReject'
import Modal from '../../../../components/Modal'
import { GoogleImageAdDetailsPageProps } from './types'

const SelfEditorImageAdDetailsPage = ({
  adDetails,
  isReasonModalOpen,
  setIsReasonModalOpen,
  handleShowReason,
  handleImageClick,
  openModalImage,
  handleCloseImageModal,
  handleEdit,
  navigate
}: GoogleImageAdDetailsPageProps) => {
  const formattedStartDate = adDetails.startDate
    ? formatDateToFrench(new Date(adDetails.startDate))
    : ''

  const breadcrumbItems: BreadcrumbProps['items'] = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      label: 'Plan publicitaire'
    },
    {
      onClick: () => {
        navigate(`/media-plan/${adDetails.navigation.mediaPlan.id}/details`)
      },
      label: `${adDetails.navigation.mediaPlan.name}`
    },
    {
      onClick: () => {
        navigate(`/campaign/${adDetails.navigation.campaign.id}/details`)
      },
      label: `Campagne « ${adDetails.navigation.campaign.name} »`
    },
    {
      label: `Publicité « ${adDetails.name} »`
    }
  ]

  const summaryItems: LabelledItemType[] = [
    {
      label: 'Statut',
      value: (
        <StatusWithColor
          status={adDetails.status}
          handleClick={handleShowReason}
        />
      )
    },
    {
      label: 'Campagne associée',
      value: adDetails.navigation.campaign.name,
      onClick: () => {
        navigate(`/campaign/${adDetails.navigation.campaign.id}/details`)
      }
    },
    // TODO : update when the platform will be dynamic
    { label: 'Plateforme', value: PlatformEnum.GOOGLE },
    { label: 'Format', value: 'Image' }
  ]

  const summaryContentColumn1 = <LabelledItemsList items={summaryItems} />
  const summaryContentColumn2 = (
    <LabelledItem
      style={launchDateStyle}
      label="Date de lancement"
      value={formattedStartDate}
    />
  )

  let listItems: LabelledItemType[] = [
    { label: 'Nom', value: adDetails.name },
    { label: 'Entreprise', value: adDetails.businessName },
    { label: 'URL', value: adDetails.url },
    { label: 'Titre 1', value: adDetails.headline1 },
    { label: 'Titre 2', value: adDetails.headline2 ?? '' },
    { label: 'Titre 3', value: adDetails.headline3 ?? '' },
    { label: 'Titre long', value: adDetails.longHeadline },
    { label: 'Description 1', value: adDetails.description1 },
    { label: 'Description 2', value: adDetails.description2 ?? '' },
    { label: 'Description 3', value: adDetails.description3 ?? '' },
    {
      label: 'Logo',
      value: adDetails.logo ? 'Voir image' : '',
      onClick: () => {
        handleImageClick(adDetails.logo)
      }
    },
    {
      label: 'Image carrée 1',
      value: 'Voir image',
      onClick: () => {
        handleImageClick(adDetails.squareMarketingImage1)
      }
    },
    {
      label: 'Image carrée 2',
      value: adDetails.squareMarketingImage2 ? 'Voir image' : '',
      onClick: () => {
        handleImageClick(adDetails.squareMarketingImage2 ?? '')
      }
    },
    {
      label: 'Image carrée 3',
      value: adDetails.squareMarketingImage3 ? 'Voir image' : '',
      onClick: () => {
        handleImageClick(adDetails.squareMarketingImage3 ?? '')
      }
    },
    {
      label: 'Image horizontale 1',
      value: 'Voir image',
      onClick: () => {
        handleImageClick(adDetails.marketingImage1)
      }
    },
    {
      label: 'Image horizontale 2',
      value: adDetails.marketingImage2 ? 'Voir image' : '',
      onClick: () => {
        handleImageClick(adDetails.marketingImage2 ?? '')
      }
    },
    {
      label: 'Image horizontale 3',
      value: adDetails.marketingImage3 ? 'Voir image' : '',
      onClick: () => {
        handleImageClick(adDetails.marketingImage3 ?? '')
      }
    }
  ]

  listItems = listItems.filter((item) => item.value)

  return (
    <DetailsPageTemplate
      navigate={navigate}
      breadcrumbItems={breadcrumbItems}
      isEditor={true}
      summary={{
        title: adDetails.name,
        type: 'Publicité',
        column1: summaryContentColumn1,
        column2: summaryContentColumn2,
        onEdit: handleEdit
      }}
    >
      <>
        <AdDetailsContentImage
          title="Aperçu de vos publicités"
          adDetails={adDetails}
          listItems={listItems}
        />
        {isReasonModalOpen && (
          <ModalReasonOfReject
            onClose={() => {
              setIsReasonModalOpen(false)
            }}
            mediaPlanName={adDetails.navigation.mediaPlan.name}
            rejectionReason={adDetails.feedback ?? ''}
          />
        )}
        {openModalImage && (
          <Modal onClose={handleCloseImageModal}>
            <PreviewImage src={openModalImage} alt="Modale Image" />
          </Modal>
        )}
      </>
    </DetailsPageTemplate>
  )
}

export default SelfEditorImageAdDetailsPage
