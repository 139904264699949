import { backendURL } from '../../../constants/urls'
import { ViewEnum } from '../../../types/axios/common'
import { checkIfCampaignNameOnlyArray } from '../../../types/campaign/campaign'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetCampaignsByMediaPlanOutputType =
  | { id: string; name: string }[]
  | null

export type GetCampaignsByMediaPlanInput = {
  mediaPlanId: string
  view?: ViewEnum
}

export const getCampaignsByMediaPlan = async ({
  mediaPlanId,
  view
}: GetCampaignsByMediaPlanInput): Promise<GetCampaignsByMediaPlanOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/media-plans/${mediaPlanId}/campaigns${
        view ? `?view=${view}` : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCampaignNameOnlyArray(response.data.campaigns)) {
        return response.data.campaigns
      }
    }
    return null
  } catch (_) {
    return null
  }
}
