import axios from 'axios'

import { backendURL } from '../../../../constants/urls'
import {
  SendResetPasswordEmailErrorEnum,
  SendResetPasswordEmailErrorTranslation,
  checkIfSendResetPasswordEmailErrorCodeValid
} from '../../../../types/error/auth/password'

export const handleSendResetPasswordEmail = async (
  email: string
): Promise<{ userId: string } | { error: string }> => {
  try {
    const url = `${backendURL}/password/reset`
    const response = await axios.post(url, { email })

    if (checkIfHandleSendResetPasswordEmailResult(response.data)) {
      return response.data
    }

    return { error: SendResetPasswordEmailErrorEnum.UNKNOWN }
  } catch (error) {
    const errorCode =
      (error as any)?.response?.data?.code ?? (error as any)?.response?.code
    if (errorCode && checkIfSendResetPasswordEmailErrorCodeValid(errorCode)) {
      return { error: SendResetPasswordEmailErrorTranslation[errorCode] }
    }
    return { error: SendResetPasswordEmailErrorEnum.UNKNOWN }
  }
}

export const checkIfHandleSendResetPasswordEmailResult = (
  data: any
): data is { userId: string } => {
  return data && typeof data.userId === 'string'
}
