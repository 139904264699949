import axios from 'axios'
import { backendURL } from '../../../constants/urls'
import {
  RegisterErrorEnum,
  RegisterErrorTranslation
} from '../../../types/error/auth/register'
import { ErrorOutput } from '../../../types/error/generic'
import { getErrorCode } from '../../getErrorCode'

export type RegisterInputType = {
  user: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    password: string
    confirmPassword: string
    role?: string
  }
  company: {
    name: string
    siren: string
    siret: string
    activity: string
    vat: string
    billingAddress: string
    isSelfEditor?: boolean
    termsOfUse: boolean
    salesTerms: boolean
    charter: boolean
  }
}

export type RegisterOutputType = { userId: string } | ErrorOutput

async function handleRegister(
  data: RegisterInputType
): Promise<RegisterOutputType> {
  try {
    const res = await axios.post(`${backendURL}/register`, data)

    if (
      res.status === 200 &&
      res.data.userId &&
      typeof res.data.userId === 'string'
    ) {
      return { userId: res.data.userId }
    }
    return { error: RegisterErrorTranslation[RegisterErrorEnum.UNKNOWN] }
  } catch (error) {
    const errorCode = getErrorCode(error) ?? null
    if (
      errorCode &&
      Object.values<string>(RegisterErrorEnum).includes(errorCode)
    ) {
      return { error: RegisterErrorTranslation[errorCode as RegisterErrorEnum] }
    }
    return { error: RegisterErrorTranslation[RegisterErrorEnum.UNKNOWN] }
  }
}

export default handleRegister

export const checkIfRegisterReturnsError = (result: RegisterOutputType) => {
  return !!(result as ErrorOutput)?.error
}
