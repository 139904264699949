import React from 'react'

import {
  HighlightsContainer,
  boxStyle,
  loaderStyle,
  HighlightsRow,
  StyledBox,
  downloadIconStyle,
  DivTitle,
  titleStyle,
  nameCampaignStyle
} from './styles'
import {
  GetCampaignDownloadReportingOutputType,
  GetCampaignHighlightReportingOutputType
} from '../../../types/reporting/campaign/output'
import Box from '../../../components/Box'
import DefaultText from '../../../components/DefaultText'
import Colors from '../../../constants/colors'
import AnimatedLoader from '../../../components/AnimatedLoader'
import { formatNumber } from '../../../helpers/rounds'
import { CustomTooltip } from '../../../components/CustomToolTip'
import ImpactTooltip from './Tooltips/ImpactTooltip'
import Co2Tooltip from './Tooltips/co2Tooltip'
import Button from '../../../components/Button'
import { generateCsv } from '../../../helpers/generateCsv'
import { DownloadIcon } from '../../../components/Icon/download'
import Title from '../../../components/Title'
import { Campaign } from '../types'
import { getFormatEnum } from '../../../types/format'
import { getPlatformEnum } from '../../../types/platform'

type PropsType = {
  data: GetCampaignHighlightReportingOutputType | null
  downloadData: GetCampaignDownloadReportingOutputType | null
  headerCsv: string[]
  currentCampaign: Campaign | null
}

const AnalyticsHighlights = ({
  data,
  downloadData,
  headerCsv,
  currentCampaign
}: PropsType) => {
  const { clicks, impressions, co2, cost, impact } = data || {}

  const co2PerEuro =
    co2 !== undefined && cost !== undefined && cost !== 0 ? co2 / cost : 0

  const ctr = clicks && impressions ? (clicks / impressions) * 100 : undefined

  return (
    <HighlightsContainer>
      {currentCampaign && (
        <DefaultText style={nameCampaignStyle}>
          {currentCampaign.name}
          {` (${getPlatformEnum(currentCampaign.platform)} ${getFormatEnum(
            currentCampaign.format
          )})`}
        </DefaultText>
      )}
      <DivTitle>
        <Title style={titleStyle}>Chiffres clés</Title>
        <Button
          type="textButton"
          width="fit-content"
          onClick={() => {
            generateCsv(downloadData, headerCsv, 'reporting')
          }}
        >
          <DownloadIcon style={downloadIconStyle} />
        </Button>
      </DivTitle>
      <HighlightsRow>
        <Box
          backgroundColor={Colors.NEW_YETIC_CO2}
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  CO₂
                </DefaultText>
                <div>
                  <CustomTooltip light hasIcon content={<Co2Tooltip />} />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {co2 !== undefined ? (
                  formatNumber(co2) + ' g'
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
        <Box
          backgroundColor={Colors.YETIC_GREEN_DARK}
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  Impact
                </DefaultText>
                <div>
                  <CustomTooltip light hasIcon content={<ImpactTooltip />} />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {impact !== undefined ? (
                  formatNumber(impact * 100) + ' %'
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
        <Box
          backgroundColor={Colors.YETIC_PURPLE}
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  CO₂ /€ dépensé
                </DefaultText>
                <div>
                  <CustomTooltip
                    light
                    hasIcon
                    content={
                      <DefaultText size="small">
                        {`La quantité totale de CO₂ émise par euro dépensé pour diffuser la campagne publicitaire`}
                      </DefaultText>
                    }
                  />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {co2PerEuro !== undefined ? (
                  Math.floor(co2PerEuro * 100) / 100 + ' g/€'
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
      </HighlightsRow>
      <HighlightsRow>
        <Box
          backgroundColor="blue"
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  Clics
                </DefaultText>
                <div>
                  <CustomTooltip
                    light
                    hasIcon
                    content={
                      <DefaultText size="small">
                        {`Le nombre total de fois où les internautes ont cliqué sur les annonces`}
                      </DefaultText>
                    }
                  />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {clicks !== undefined ? (
                  formatNumber(clicks)
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
        <Box
          backgroundColor="deeppink" // TO CHANGE deeppink
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  Impressions
                </DefaultText>
                <div>
                  <CustomTooltip
                    light
                    hasIcon
                    content={
                      <DefaultText size="small">
                        {`Le nombre total de fois où les annonces ont été visualisées ou affichées aux internautes`}
                      </DefaultText>
                    }
                  />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {impressions !== undefined ? (
                  formatNumber(impressions)
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
        <Box
          backgroundColor="orange"
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  Coût
                </DefaultText>
                <div>
                  <CustomTooltip
                    light
                    hasIcon
                    content={
                      <DefaultText size="small">
                        {`Le montant total dépensé pour diffuser la campagne publicitaire`}
                      </DefaultText>
                    }
                  />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {cost !== undefined ? (
                  formatNumber(cost) + ' €'
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
      </HighlightsRow>

      <HighlightsRow>
        <Box
          backgroundColor="blue"
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  CPC
                </DefaultText>
                <div>
                  <CustomTooltip
                    light
                    hasIcon
                    content={
                      <DefaultText size="small">
                        {`Le coût par clic, soit le montant moyen dépensé pour chaque clic sur les annonces`}
                      </DefaultText>
                    }
                  />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {cost !== undefined && cost !== 0 && clicks ? (
                  formatNumber(cost / clicks) + ' €'
                ) : cost === 0 || clicks === 0 ? (
                  'N/A'
                ) : (
                  <AnimatedLoader
                    style={loaderStyle}
                    color={Colors.YETIC_WHITE}
                  />
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />

        <Box
          backgroundColor="green"
          width="20%"
          style={boxStyle}
          elements={[
            <React.Fragment key={Math.random()}>
              <StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="medium"
                  key={Math.random()}
                >
                  CTR
                </DefaultText>
                <div>
                  <CustomTooltip
                    light
                    hasIcon
                    content={
                      <DefaultText size="small">
                        {`Le nombre de clics par impression`}
                      </DefaultText>
                    }
                  />
                </div>
              </StyledBox>
              <DefaultText
                style={{ color: 'white' }}
                size="big"
                key={Math.random()}
              >
                {impressions !== 0 ? (
                  ctr ? (
                    formatNumber(ctr) + ' %'
                  ) : (
                    <AnimatedLoader
                      style={loaderStyle}
                      color={Colors.YETIC_WHITE}
                    />
                  )
                ) : (
                  'N/A'
                )}
              </DefaultText>
            </React.Fragment>
          ]}
        />
      </HighlightsRow>
    </HighlightsContainer>
  )
}

export default AnalyticsHighlights
