import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Colors from '../../../constants/colors'
import FontSize from '../../../constants/fontSize'

export const InputFileContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

export const StyledInputFile = styled.input`
  display: none;
`

export const InputFileLabel = styled.label`
  border: solid;
  width: fit-content;
  padding: ${Padding.p3} ${Padding.p4};
  border-radius: 6px;
  cursor: pointer;
  height: fit-content;
  border-color: ${Colors.YETIC_BLUE_DARK};
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &:hover {
    background-color: ${Colors.YETIC_BLUE_DARK};
    color: white;
  }
`

export const InputFileInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  width: 90%;
`

export const textStyle = {
  marginBottom: '0px'
}

export const fileNameTextStyle = {
  marginBottom: '0px',
  maxWidth: '80%'
}

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const InputImagePreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const InputImagePreview = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
`

export const DeleteImageButton = styled.button`
  display: flex;
  position: absolute;
  top: -5px;
  left: -5px;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  border: solid ${Colors.YETIC_BLUE_DARK} 1px;
  cursor: pointer;
  background-color: white;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${Colors.YETIC_GREEN_LIGHT};
  }
`

export const Tooltip = styled.div`
  cursor: pointer;
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  font-weight: bold;
  background-color: ${Colors.NEW_YETIC_BOX_INFO_TIP};
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -15px;
  top: -4px;
`
