import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getMediaPlanById } from '../../helpers/queries/mediaPlan/getMediaPlanById'
import { MediaPlanDetailsType } from '../../types/mediaPlan/mediaPlan'
import { Status, getIsEditable } from '../../types/status'
import LoadingPage from '../LoadingPage'
import { getCampaignById } from '../../helpers/queries/campaign/getCampaignById'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import { MediaPlanAudience } from '../../types/mediaPlanAudience'
import { checkIfImageGoogleCampaign } from '../../types/campaign/google/googleCampaign'
import { checkIfFullGoogleImageAdArray } from '../../types/ad/google/imageAd'
import getImageById from '../../helpers/queries/ad/getImageById'
import { CampaignType } from '../../types/campaign/campaign'
import EditorMediaPlanDetails from './editor'
import SelfEditorMediaPlanDetails from './selfEditor'
import { ModalKeywordsType } from './types'
import CampaignCreationPlatformModal from '../parts/CampaignCreationPlatformModal'
import {
  checkIfPauseCampaign,
  pauseCampaign
} from '../../helpers/queries/campaign/pauseCampaign'
import {
  checkIfRestartCampaign,
  restartCampaign
} from '../../helpers/queries/campaign/restartCampaign'

export type MediaPlanDetailsDataTable = {
  id: string
  actions?: {
    label: string
    handleAction: (id: string) => void
  }[]
  row: (string | JSX.Element)[]
  details: {
    column1: {
      label: string
      value: string
    }[]
    column2?: (
      | {
          label: string
          value: string
          onClick?: undefined
        }
      | {
          label: string
          value: string
          onClick: (audienceName: string) => void
        }
    )[]
  }
  subItems: {
    title: string
    data: {
      label: string
      value: string
    }[]
    preview: JSX.Element
    onSeeMore: () => void
  }[]
}

const MediaPlanDetails = () => {
  const [noRowOpen, setNoRowOpen] = useState<string>('')
  const [mediaPlan, setMediaPlan] = useState<MediaPlanDetailsType | null>(null)
  // TODO : handle linkedin campaign
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignType | null>(
    null
  )
  const [displayedAdImages, setDisplayedAdImages] = useState<Record<
    string,
    string
  > | null>(null)

  const [hasFetchError, setHasFetchError] = useState(false)
  const [dataAudienceModal, setDataAudienceModal] =
    useState<MediaPlanAudience | null>(null)
  const [dataKeywordsModal, setDataKeywordsModal] = useState<
    ModalKeywordsType | undefined
  >(undefined)
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false)
  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [skip, setSkip] = useState(0)
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] =
    useState(false)
  const [campaignIdToPause, setCampaignIdToPause] = useState('')
  const [campaignIdToRestart, setCampaignIdToRestart] = useState('')
  const [updateCampaignStatusError, setUpdateCampaignStatusError] = useState('')

  const navigate = useNavigate()

  const { mediaPlanId } = useParams()

  const { isEditor, isSelfEditor } = useContext(AppContext)

  useEffect(() => {
    if (mediaPlanId) {
      ;(async function () {
        const result = await getMediaPlanById({
          mediaPlanId: mediaPlanId ?? '',
          skip
        })
        if (result != null) {
          const newMediaPlan = {
            id: result.mediaPlan.id,
            name: result.mediaPlan.name,
            startDate: result.mediaPlan.startDate,
            endDate: result.mediaPlan.endDate,
            goals: result.mediaPlan.goals,
            audiences: result.mediaPlan.audiences,
            status: result.mediaPlan.status,
            expectedBudget: result.mediaPlan.expectedBudget,
            spentBudget: result.mediaPlan.spentBudget,
            expectedBenefits: result.mediaPlan.expectedBenefits,
            realizedBenefits: result.mediaPlan.realizedBenefits,
            feedback: result.mediaPlan.feedback,
            co2: result.mediaPlan.co2,
            users: result.mediaPlan.users,
            campaigns: result.mediaPlan.campaigns,
            navigation: result.mediaPlan.navigation
          }

          setMediaPlan(newMediaPlan)
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }, [mediaPlanId])

  useEffect(() => {
    if (selectedCampaign) {
      const ads = selectedCampaign.ads

      if (
        checkIfImageGoogleCampaign(selectedCampaign) &&
        ads &&
        checkIfFullGoogleImageAdArray(ads)
      ) {
        ;(async function () {
          const imageResults: Record<string, string> = {}
          for (let i = 0; i < ads.length; i++) {
            const imageAd = ads[i]
            const imageResult = await getImageById(imageAd.marketingImage1)
            if (imageResult) {
              imageResults[imageAd.id] = imageResult
            }
          }

          setDisplayedAdImages(imageResults)
        })()
      }
    }
  }, [selectedCampaign])

  useEffect(() => {
    if (campaignIdToPause) {
      ;(async function () {
        const pauseResult = await pauseCampaign({
          campaignId: campaignIdToPause
        })
        if (checkIfPauseCampaign(pauseResult)) {
          setCampaignIdToPause('')
          window.location.reload()
        } else {
          setUpdateCampaignStatusError(pauseResult.error)
        }
      })()
    }
  }, [campaignIdToPause])

  useEffect(() => {
    if (campaignIdToRestart) {
      ;(async function () {
        const restartResult = await restartCampaign({
          campaignId: campaignIdToRestart
        })
        if (checkIfRestartCampaign(restartResult)) {
          setCampaignIdToRestart('')
          window.location.reload()
        } else {
          setUpdateCampaignStatusError(restartResult.error)
        }
      })()
    }
  }, [campaignIdToRestart])

  if (!mediaPlanId || !mediaPlan || campaignIdToPause || campaignIdToRestart) {
    return <LoadingPage />
  }

  if (hasFetchError || (!isEditor && !isSelfEditor)) {
    return <ErrorPage />
  }

  if (updateCampaignStatusError) {
    return <ErrorPage message={updateCampaignStatusError} />
  }

  const handleShowReason =
    mediaPlan.status === Status.REJECTED
      ? (event: React.MouseEvent) => {
          event.stopPropagation()
          if (mediaPlan.feedback) {
            setRejectionReason(mediaPlan.feedback)
            setIsModalReasonOpen(true)
          }
        }
      : undefined

  const handleOnRowClick = async (id: string) => {
    if (noRowOpen === id) {
      setNoRowOpen('')
    } else {
      const currentCampaign = mediaPlan.campaigns.find((c) => c.id === id)

      if (currentCampaign) {
        const result = await getCampaignById({
          campaignId: id,
          adType: currentCampaign.format
        })
        if (result != null) {
          const campaign = result.campaign
          setSelectedCampaign(campaign)
        }
        setNoRowOpen(id)
      }
    }
  }

  const handleEdit = getIsEditable({ status: mediaPlan.status, isEditor })
    ? () => {
        navigate(`/media-plan/${mediaPlanId}/edit?flow=${FlowEnum.view}`)
      }
    : undefined

  const handleAdd = getIsEditable({ status: mediaPlan.status, isEditor })
    ? () => {
        setIsCreateCampaignModalOpen(true)
      }
    : undefined

  const handleChangePage = async (page: number) => {
    const newSkip = page - 1
    const result = await getMediaPlanById({
      mediaPlanId: mediaPlanId ?? '',
      skip: newSkip
    })
    if (result != null) {
      const newMediaPlan = {
        id: result.mediaPlan.id,
        name: result.mediaPlan.name,
        startDate: result.mediaPlan.startDate,
        endDate: result.mediaPlan.endDate,
        goals: result.mediaPlan.goals,
        audiences: result.mediaPlan.audiences,
        status: result.mediaPlan.status,
        expectedBudget: result.mediaPlan.expectedBudget,
        spentBudget: result.mediaPlan.spentBudget,
        expectedBenefits: result.mediaPlan.expectedBenefits,
        realizedBenefits: result.mediaPlan.realizedBenefits,
        feedback: result.mediaPlan.feedback,
        co2: result.mediaPlan.co2,
        users: result.mediaPlan.users,
        campaigns: result.mediaPlan.campaigns,
        navigation: result.mediaPlan.navigation
      }
      setMediaPlan(newMediaPlan)
    } else {
      setHasFetchError(true)
    }
  }

  const handlePauseRestartCampaign = (id: string, currentStatus: Status) => {
    if (currentStatus === Status.IN_PROGRESS) {
      setCampaignIdToPause(id)
    } else if (currentStatus === Status.PAUSED) {
      setCampaignIdToRestart(id)
    }
  }

  const MediaPlanDetailsPage = isEditor
    ? EditorMediaPlanDetails
    : SelfEditorMediaPlanDetails

  return (
    <>
      <MediaPlanDetailsPage
        mediaPlanId={mediaPlanId}
        mediaPlan={mediaPlan}
        selectedCampaign={selectedCampaign}
        displayedAdImages={displayedAdImages}
        skip={skip}
        handleChangePage={handleChangePage}
        noRowOpen={noRowOpen}
        handleOnRowClick={handleOnRowClick}
        dataAudienceModal={dataAudienceModal}
        setDataAudienceModal={setDataAudienceModal}
        dataKeywordsModal={dataKeywordsModal}
        setDataKeywordsModal={setDataKeywordsModal}
        isKeywordsModalOpen={isKeywordsModalOpen}
        setIsKeywordsModalOpen={setIsKeywordsModalOpen}
        isModalReasonOpen={isModalReasonOpen}
        setIsModalReasonOpen={setIsModalReasonOpen}
        handleShowReason={handleShowReason}
        rejectionReason={rejectionReason}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        handlePauseRestartCampaign={handlePauseRestartCampaign}
        navigate={navigate}
      />
      {isCreateCampaignModalOpen && (
        <CampaignCreationPlatformModal
          mediaPlanId={mediaPlanId}
          flow={FlowEnum.view}
          onClose={() => {
            setIsCreateCampaignModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export default MediaPlanDetails
