import React from 'react'

import TableList, {
  TableListDataType,
  TableListFilter
} from '../../../../components/TableList'
import FontSize from '../../../../constants/fontSize'

type HomeTableListProps = {
  data: TableListDataType
  filters: TableListFilter[]
  handleRowAction?: (id: string) => void
  handleOnRowClick: (id: string) => void
  handleChangePage: (page: number) => void
  totalItems: number
  currentPage: number
}

const HomeTableList = ({
  data,
  filters,
  handleRowAction,
  handleOnRowClick,
  handleChangePage,
  totalItems,
  currentPage
}: HomeTableListProps) => {
  return (
    <TableList
      data={data}
      filters={filters}
      itemsByPage={5}
      actions={
        handleRowAction && [
          {
            label: 'Modifier',
            handleAction: handleRowAction
          }
        ]
      }
      handleOnRowClick={handleOnRowClick}
      style={{ fontSize: FontSize.YETIC_TEXT_SMALL }}
      totalItems={totalItems}
      handleChangePage={handleChangePage}
      currentPage={currentPage}
    ></TableList>
  )
}

export default HomeTableList
