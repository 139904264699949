import React from 'react'
import DetailsTabItem from './DetailsTabItem'
import { Container, Content } from './styles'

const EmptyTab = () => {
  return (
    <Container>
      <Content>
        <DetailsTabItem
          title="Contenu bientôt disponible"
          description={`Contenu bientôt disponible`}
          withBorder={false}
        />
      </Content>
    </Container>
  )
}

export default EmptyTab
