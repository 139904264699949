import { backendURL } from '../../../constants/urls'
import {
  GoogleStatus,
  LinkedinStatus,
  checkIfGoogleStatus,
  checkIfLinkedinStatus
} from '../../../types/platformConnection'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetPlatformsConnectionOutputType = {
  google: boolean | GoogleStatus
  linkedin: Record<string, boolean | LinkedinStatus>
}

export const getPlatformsConnection =
  async (): Promise<GetPlatformsConnectionOutputType | null> => {
    try {
      const response = await axiosGet({
        url: `${backendURL}/platforms`
      })

      if (checkIsAxiosGetOutputSuccess(response)) {
        if (checkIfPlatformsConnection(response.data)) {
          return response.data
        }
      }

      return null
    } catch (_) {
      return null
    }
  }

export const checkIfPlatformsConnection = (
  value: any
): value is GetPlatformsConnectionOutputType => {
  return (
    value &&
    value.google !== undefined &&
    (typeof value.google === 'boolean' || checkIfGoogleStatus(value.google)) &&
    value.linkedin &&
    typeof value.linkedin === 'object' &&
    Object.values(value.linkedin).every(
      (value) => typeof value === 'boolean' || checkIfLinkedinStatus(value)
    )
  )
}
