import { backendURL } from '../../../constants/urls'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'
import {
  ValidateCampaignErrorEnum,
  ValidateCampaignErrorTranslation,
  checkIfValidateCampaignErrorCodeValid
} from '../../../types/error/campaign/validateCampaign'

// TODO : manage error
export type ValidateCampaignOutputType = {
  campaignId: string
}

export type ValidateCampaignErrorType = {
  error: string
}

export type ValidateCampaignInput = {
  campaignId: string
}

export const validateCampaign = async ({
  campaignId
}: ValidateCampaignInput): Promise<
  ValidateCampaignOutputType | ValidateCampaignErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/campaigns/${campaignId}/validate`,
      data: {}
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.campaignId) {
        return {
          campaignId: response.data.campaignId
        }
      }
    } else {
      const errorCode =
        (response as any)?.error.data?.code ?? (response as any)?.error.code
      if (errorCode && checkIfValidateCampaignErrorCodeValid(errorCode)) {
        return { error: ValidateCampaignErrorTranslation[errorCode] }
      }
    }

    return {
      error: ValidateCampaignErrorTranslation[ValidateCampaignErrorEnum.UNKNOWN]
    }
  } catch (_) {
    return {
      error: ValidateCampaignErrorTranslation[ValidateCampaignErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfValidateCampaign = (
  result: any
): result is ValidateCampaignOutputType => {
  return result.campaignId !== undefined
}
