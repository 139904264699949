import { backendURL } from '../../../constants/urls'
// eslint-disable-next-line
import { CreateAudienceErrorEnum } from '../../../types/error/audience/createAudience'
import { MediaPlanAudience } from '../../../types/mediaPlanAudience'
import { axiosPost, checkIsAxiosPostOutputSuccess } from '../axios/post'

type CreateAudienceInputType = {
  customerId: string
  audience: MediaPlanAudience
}

type CreateAudienceOutputType = {
  audienceId: string
}

type CreateAudienceOutputError = {
  error: CreateAudienceErrorEnum
}

export const createAudience = async ({
  customerId,
  audience
}: CreateAudienceInputType): Promise<
  CreateAudienceOutputType | CreateAudienceOutputError
> => {
  const response = await axiosPost({
    url: `${backendURL}/audiences`,
    data: {
      customerId,
      audience
    }
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (response.data.audienceId) {
      return response.data.audienceId
    } else {
      return {
        error: CreateAudienceErrorEnum.UNKNOWN
      }
    }
  } else {
    return {
      error: (response.error as any).data.code
    }
  }
}

export const checkIfCreateAudienceOutput = (
  result: any
): result is CreateAudienceOutputType => {
  return result.audienceId && typeof result.audienceId === 'string'
}
