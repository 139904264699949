export const lineChartData = [
  {
    name: 'Clics',
    color: 'blue',
    values: [
      {
        time: new Date(Date.parse('28 Dec 2023 00:12:00 GMT')),
        value: 2
      },
      {
        time: new Date(Date.parse('29 Dec 2023 00:12:00 GMT')),
        value: 5
      },
      {
        time: new Date(Date.parse('30 Dec 2023 00:12:00 GMT')),
        value: 1
      },
      {
        time: new Date(Date.parse('31 Dec 2023 00:12:00 GMT')),
        value: 7
      },
      {
        time: new Date(Date.parse('1 Jan 2024 00:12:00 GMT')),
        value: 8
      },
      {
        time: new Date(Date.parse('2 Jan 2024 00:12:00 GMT')),
        value: 8
      },
      {
        time: new Date(Date.parse('3 Jan 2024 00:12:00 GMT')),
        value: 5
      }
    ]
  },
  {
    name: 'Impressions',
    color: 'red',
    values: [
      {
        time: new Date(Date.parse('28 Dec 2023 00:12:00 GMT')),
        value: 1122
      },
      {
        time: new Date(Date.parse('29 Dec 2023 00:12:00 GMT')),
        value: 978
      },
      {
        time: new Date(Date.parse('30 Dec 2023 00:12:00 GMT')),
        value: 1432
      },
      {
        time: new Date(Date.parse('31 Dec 2023 00:12:00 GMT')),
        value: 1098
      },
      {
        time: new Date(Date.parse('1 Jan 2024 00:12:00 GMT')),
        value: 1289
      },
      {
        time: new Date(Date.parse('2 Jan 2024 00:12:00 GMT')),
        value: 1432
      },
      {
        time: new Date(Date.parse('3 Jan 2024 00:12:00 GMT')),
        value: 1321
      }
    ]
  },
  {
    name: 'Coût',
    color: 'green',
    unit: '€',
    values: [
      {
        time: new Date(Date.parse('28 Dec 2023 00:12:00 GMT')),
        value: 13
      },
      {
        time: new Date(Date.parse('29 Dec 2023 00:12:00 GMT')),
        value: 14
      },
      {
        time: new Date(Date.parse('30 Dec 2023 00:12:00 GMT')),
        value: 16
      },
      {
        time: new Date(Date.parse('31 Dec 2023 00:12:00 GMT')),
        value: 12
      },
      {
        time: new Date(Date.parse('1 Jan 2024 00:12:00 GMT')),
        value: 7
      },
      {
        time: new Date(Date.parse('2 Jan 2024 00:12:00 GMT')),
        value: 9
      },
      {
        time: new Date(Date.parse('3 Jan 2024 00:12:00 GMT')),
        value: 3
      }
    ]
  }
]

export const heatmapData = [
  {
    name: 'Clics',
    categories: [
      {
        x: '18-24',
        values: [
          { value: 2, y: 'Hommes' },
          { value: 3, y: 'Femmes' }
        ]
      },
      {
        x: '25-34',
        values: [
          { value: 4, y: 'Hommes' },
          { value: 1, y: 'Femmes' }
        ]
      },
      {
        x: '35-44',
        values: [
          { value: 3, y: 'Hommes' },
          { value: 2, y: 'Femmes' }
        ]
      },
      {
        x: '45-54',
        values: [
          { value: 2, y: 'Hommes' },
          { value: 3, y: 'Femmes' }
        ]
      },
      {
        x: '55-64',
        values: [
          { value: 4, y: 'Hommes' },
          { value: 7, y: 'Femmes' }
        ]
      },
      {
        x: '65+',
        values: [
          { value: 5, y: 'Hommes' },
          { value: 2, y: 'Femmes' }
        ]
      }
    ]
  },
  {
    name: 'Impressions',
    categories: [
      {
        x: '18-24',
        values: [
          { value: 520, y: 'Hommes' },
          { value: 522, y: 'Femmes' }
        ]
      },
      {
        x: '25-34',
        values: [
          { value: 770, y: 'Hommes' },
          { value: 208, y: 'Femmes' }
        ]
      },
      {
        x: '35-44',
        values: [
          { value: 900, y: 'Hommes' },
          { value: 532, y: 'Femmes' }
        ]
      },
      {
        x: '45-54',
        values: [
          { value: 500, y: 'Hommes' },
          { value: 598, y: 'Femmes' }
        ]
      },
      {
        x: '55-64',
        values: [
          { value: 689, y: 'Hommes' },
          { value: 600, y: 'Femmes' }
        ]
      },
      {
        x: '65+',
        values: [
          { value: 900, y: 'Hommes' },
          { value: 532, y: 'Femmes' }
        ]
      }
    ]
  }
]

export const barChartData = [
  {
    name: 'Age',
    color: 'blue',
    unit: 'ans',
    values: [
      {
        category: '18-24',
        value: 2
      },
      {
        category: '25-34',
        value: 5
      },
      {
        category: '35-44',
        value: 1
      },
      {
        category: '45-54',
        value: 7
      },
      {
        category: '55-64',
        value: 8
      },
      {
        category: '65+',
        value: 8
      }
    ]
  },
  {
    name: 'Genre',
    color: 'red',
    horizontal: true,
    values: [
      {
        category: 'Hommes',
        value: 1122
      },
      {
        category: 'Femmes',
        value: 997
      },
      {
        category: 'Autre',
        value: 86
      }
    ]
  },
  {
    name: 'Revenus',
    color: 'green',
    unit: '€',
    values: [
      {
        category: '0-10k',
        value: 13
      },
      {
        category: '10-20k',
        value: 14
      },
      {
        category: '20-30k',
        value: 16
      },
      {
        category: '30-40k',
        value: 30
      },
      {
        category: '40-50k',
        value: 34
      },
      {
        category: '50-60k',
        value: 28
      },
      {
        category: '60-70k',
        value: 19
      },
      {
        category: '70-80k',
        value: 8
      },
      {
        category: '80k+',
        value: 4
      }
    ]
  }
]
