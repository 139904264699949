import React from 'react'

import Dropdown, { Option } from '../../../components/Dropdown'
import { Campaign, MediaPlan } from '../types'
import DatesFilters from '../DatesFilters'
import CampaignStatus from '../CampaignStatus'
import { DatesFiltersContainer, FilterContainer } from '../styles'

export type FiltersProps = {
  isEditor: boolean
  customerOptions: { id: string; label: string }[]
  mediaPlanOptions: { id: string; label: string }[]
  campaignOptions: { id: string; label: string }[]
  handleChangeCurrentCustomer: (option: Option | null) => void
  handleChangeCurrentMediaPlan: (option: Option | null) => void
  handleChangeCurrentCampaign: (option: Option | null) => void
  currentCustomerName: string | null
  currentMediaPlan: MediaPlan | null
  currentCampaign: Campaign | null
  startDate?: string
  endDate?: string
  today: string
  handleChangeStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Filters = ({
  isEditor,
  customerOptions,
  mediaPlanOptions,
  campaignOptions,
  handleChangeCurrentCustomer,
  handleChangeCurrentMediaPlan,
  handleChangeCurrentCampaign,
  currentCustomerName,
  currentMediaPlan,
  currentCampaign,
  startDate,
  endDate,
  today,
  handleChangeStartDate,
  handleChangeEndDate
}: FiltersProps) => {
  return (
    <>
      <FilterContainer>
        {isEditor && customerOptions && (
          <Dropdown
            label="Client"
            options={customerOptions}
            value={customerOptions.find(
              (customer) => customer.label === currentCustomerName
            )}
            onChange={handleChangeCurrentCustomer}
            width="250px"
            disabled={customerOptions.length < 1}
          />
        )}
        {mediaPlanOptions && (
          <Dropdown
            label="Plan média"
            options={mediaPlanOptions}
            value={mediaPlanOptions.find(
              (mp) => mp.id === currentMediaPlan?.id
            )}
            onChange={handleChangeCurrentMediaPlan}
            width="250px"
            disabled={mediaPlanOptions.length < 1}
          />
        )}
        <Dropdown
          label="Campagne"
          options={campaignOptions}
          value={
            campaignOptions.find((cp) => cp.id === currentCampaign?.id) ||
            'Toutes les campagnes'
          }
          onChange={handleChangeCurrentCampaign}
          width="250px"
          disabled={!currentMediaPlan}
          isDropdownFilter
          viewAllText="Toutes les campagnes"
        />
      </FilterContainer>
      {currentCampaign ? (
        <DatesFiltersContainer>
          <DatesFilters
            current={currentCampaign}
            endDate={endDate}
            startDate={startDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeStartDate={handleChangeStartDate}
            today={today}
          />
        </DatesFiltersContainer>
      ) : currentMediaPlan ? (
        <DatesFiltersContainer>
          <DatesFilters
            current={currentMediaPlan}
            endDate={endDate}
            startDate={startDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeStartDate={handleChangeStartDate}
            today={today}
          />
        </DatesFiltersContainer>
      ) : null}
    </>
  )
}
