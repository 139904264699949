import { Gender, GenderTranslation } from '../types/gender'

export const genders: { id: Gender; label: string }[] = [
  {
    id: Gender.ALL,
    label: GenderTranslation[Gender.ALL]
  },
  {
    id: Gender.MALE,
    label: GenderTranslation[Gender.MALE]
  },
  {
    id: Gender.FEMALE,
    label: GenderTranslation[Gender.FEMALE]
  },
  {
    id: Gender.OTHER,
    label: GenderTranslation[Gender.OTHER]
  }
]
