export function getYearMonthFromStringDate(myDate: string) {
  let separator = '-'
  if (myDate.includes('/')) {
    separator = '/'
  }
  const parts = myDate.split(separator)
  if (parts[0].length > 2) {
    return `${parts[0]}-${parts[1]}`
  } else {
    return `${parts[2]}-${parts[1]}`
  }
}
export function verifyStringIsValidDate(dateString: string): boolean {
  return new Date(dateString).toString() !== 'Invalid Date'
}

export function parseStringDate(dateString: string): Date {
  return new Date(dateString)
}

export function formatDateToFrench(date: Date, short?: boolean): string {
  const months = short
    ? [
        'janv.',
        'févr.',
        'mars',
        'avril',
        'mai',
        'juin',
        'juil.',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.'
      ]
    : [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
      ]

  const [day, month, year] = date.toLocaleDateString().split('/')
  if (!day || !month || !year) {
    return ''
  }
  const formattedDate = `${
    parseInt(day, 10) !== 1 ? parseInt(day, 10) : '1er'
  } ${months[parseInt(month, 10) - 1]} ${year}`
  return formattedDate
}

export function formatDateToString(date: Date): string {
  const day =
    date.getDate().toString().length === 1
      ? `0${date.getDate()}`
      : date.getDate().toString()
  const month =
    (date.getMonth() + 1).toString().length === 1
      ? `0${date.getMonth() + 1}`
      : (date.getMonth() + 1).toString()
  return `${day}/${month}/${date.getFullYear()}`
}

export function formatDateToEnglishString(date: Date): string {
  const day =
    date.getDate().toString().length === 1
      ? `0${date.getDate()}`
      : date.getDate().toString()
  const month =
    (date.getMonth() + 1).toString().length === 1
      ? `0${date.getMonth() + 1}`
      : (date.getMonth() + 1).toString()
  return `${date.getFullYear()}-${month}-${day}`
}

export function getMinDate(
  date1: string | undefined,
  date2: string | undefined
) {
  if (!date1 && !date2) {
    return undefined
  }
  if (!date1) {
    return date2
  }
  if (!date2) {
    return date1
  }
  return parseStringDate(date1).getTime() < parseStringDate(date2).getTime()
    ? date1
    : date2
}

export function getMaxDate(
  date1: string | undefined,
  date2: string | undefined
) {
  if (!date1 && !date2) {
    return undefined
  }
  if (!date1) {
    return date2
  }
  if (!date2) {
    return date1
  }
  return parseStringDate(date1).getTime() > parseStringDate(date2).getTime()
    ? date1
    : date2
}

export function getNumberOfDaysBetweenDates(
  startDate: string,
  endDate: string
): number {
  if (
    !verifyStringIsValidDate(startDate) ||
    !verifyStringIsValidDate(endDate)
  ) {
    return 0
  }
  return (
    Math.round(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        (1000 * 60 * 60 * 24)
    ) + 1
  )
}
