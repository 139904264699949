import React from 'react'

import Modal from '../../../../../components/Modal'
import Title from '../../../../../components/Title'
import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { ExistingAudiences } from '../../../'
import {
  Item,
  Column,
  ColumnContent,
  ModalContentContainer,
  audienceNameStyle,
  checkboxStyle,
  columnTitleStyle,
  seeDetailsButtonStyle,
  mediaPlanNameStyle,
  BottomModal,
  BottomTextContainer,
  disabledAudienceNameStyle,
  disabledMediaPlanNameStyle,
  modalStyle,
  modalOverlayStyle,
  loaderStyle
} from './styles'
import DefaultText from '../../../../../components/DefaultText'
import { Checkbox } from '../../../../../components/CheckBox'
import Button from '../../../../../components/Button'
import Margin from '../../../../../constants/margin'

type ExistingAudiencesModalProps = {
  selectedAudiences: Record<string, boolean>[] | null
  importedAudiences: string[]
  data: ExistingAudiences | null
  onClose: () => void
  handleCheckboxChange: (audienceId: string) => void
  handleSeeAudienceDetails: (audienceId: string) => Promise<void>
  handleSubmit: () => void
}

const ExistingAudiencesModal = ({
  selectedAudiences,
  importedAudiences,
  data,
  onClose,
  handleCheckboxChange,
  handleSeeAudienceDetails,
  handleSubmit
}: ExistingAudiencesModalProps) => {
  const selectedAudienceIds = selectedAudiences
    ?.filter((obj) => Object.values(obj)[0])
    .map((obj) => Object.keys(obj)[0])

  const selectedAudienceNames = data
    ?.filter((obj) => selectedAudienceIds?.includes(obj.id))
    .map((obj) => obj.name)

  const isAudienceSelected = (audienceId: string, audienceName: string) => {
    return selectedAudiences
      ? selectedAudiences.find((audience) => audience[audienceId])?.[
          audienceId
        ] ??
          importedAudiences.includes(audienceName) ??
          false
      : false
  }

  const isAudienceDisabled = (audienceName: string) => {
    return importedAudiences.includes(audienceName)
  }

  return (
    <Modal
      onClose={onClose}
      style={modalStyle}
      overlayStyle={modalOverlayStyle}
    >
      <Title type="big">Importer une audience existante</Title>
      <ModalContentContainer>
        {data ? (
          <>
            <Column>
              <ColumnContent style={{ marginTop: '39px' }}>
                {data.map((item, index) => (
                  <Item key={index}>
                    <Button
                      key={index}
                      type="secondary"
                      width="fit-content"
                      onClick={() => {
                        handleSeeAudienceDetails(item.id)
                      }}
                      style={seeDetailsButtonStyle}
                    >
                      Détails
                    </Button>
                  </Item>
                ))}
              </ColumnContent>
            </Column>
            <Column style={{ marginRight: Margin.m8 }}>
              <DefaultText style={columnTitleStyle}>Audience</DefaultText>
              <ColumnContent>
                {data.map((item, index) => (
                  <Item key={index}>
                    <Checkbox
                      key={index}
                      selected={isAudienceSelected(item.id, item.name)}
                      style={checkboxStyle}
                      onChange={() => {
                        handleCheckboxChange(item.id)
                      }}
                      disabled={isAudienceDisabled(item.name)}
                    />
                    <DefaultText
                      style={{
                        ...(isAudienceDisabled(item.name)
                          ? disabledAudienceNameStyle
                          : audienceNameStyle)
                      }}
                    >
                      {item.name}
                    </DefaultText>
                  </Item>
                ))}
              </ColumnContent>
            </Column>
            <Column>
              <DefaultText style={columnTitleStyle}>Plan média</DefaultText>
              <ColumnContent>
                {data.map((item, index) => (
                  <Item key={index}>
                    <DefaultText
                      key={index}
                      style={{
                        ...(isAudienceDisabled(item.name)
                          ? disabledMediaPlanNameStyle
                          : mediaPlanNameStyle)
                      }}
                    >
                      {item.mediaPlanName}
                    </DefaultText>
                  </Item>
                ))}
              </ColumnContent>
            </Column>
            <div>{selectedAudiences?.map((obj) => obj.id)}</div>
          </>
        ) : (
          <AnimatedLoader style={loaderStyle} />
        )}
      </ModalContentContainer>
      <BottomModal>
        <BottomTextContainer>
          <DefaultText size="small">
            Vous pouvez sélectionner plusieurs audiences.
          </DefaultText>
          <DefaultText size="small">
            Audiences sélectionnées :{' '}
            {selectedAudienceNames && selectedAudienceNames.length > 0
              ? selectedAudienceNames.join(', ')
              : '-'}
          </DefaultText>
        </BottomTextContainer>
        <Button
          type="primary"
          width="fit-content"
          onClick={handleSubmit}
          disabled={selectedAudienceIds?.length === 0}
        >
          Importer la sélection
        </Button>
      </BottomModal>
    </Modal>
  )
}

export default ExistingAudiencesModal
