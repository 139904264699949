import React, { useEffect, useRef, useState } from 'react'

import {
  InputSearch,
  Item,
  ListItems,
  ListItemsContainer,
  Search,
  SearchArrow,
  SearchComponent,
  SearchList,
  SearchListCategories,
  SearchListCategory,
  SearchListSelected
} from './styles'
import { HeaderTypeType } from '../'
import { getMediaPlansForHeader } from '../../../helpers/queries/mediaPlan/getMediaPlans'
import { getCampaignsForHeader } from '../../../helpers/queries/campaign/getCampaigns'
import { getAds } from '../../../helpers/queries/ad/getAds'
import { getCustomers } from '../../../helpers/queries/customer/getCustomers'
import { ViewEnum } from '../../../types/axios/common'

type ItemType = {
  id: string
  name: string
}

type CategoriesType = {
  id: string
  label: string
  fetchData: () => Promise<{ id: string; name: string }[] | null>
  items: ItemType[]
  setItems: (newItems: ItemType[]) => void
  getRoute: (itemId: string) => string
}[]

const HeaderSearch = ({ callback, navigate }: HeaderTypeType) => {
  const [showCategories, setShowCategories] = useState(false)
  const [showFilteredList, setShowFilteredList] = useState(false)

  const [selectedCategoryId, setSelectedCategoryId] = useState('mediaPlans')

  const [customers, setCustomers] = useState<ItemType[]>([])
  const [mediaPlans, setMediaPlans] = useState<ItemType[]>([])
  const [campaigns, setCampaigns] = useState<ItemType[]>([])
  const [ads, setAds] = useState<ItemType[]>([])

  const [searchString, setSearchString] = useState('')
  const [filteredItems, setFilteredItems] = useState<ItemType[]>([])
  const searchComponentRef = useRef<HTMLDivElement | null>(null)

  const [hasBeenClicked, setHasBeenClicked] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const clickedElement = event.target as Node
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(clickedElement)
      ) {
        setShowCategories(false)
        setShowFilteredList(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
        hasBeenClicked &&
        selectedCategory?.items &&
        selectedCategory.items.length < 1
      ) {
        const newItems = await selectedCategory.fetchData()
        newItems && selectedCategory.setItems(newItems)
      }
    })()
  }, [selectedCategoryId, hasBeenClicked])

  useEffect(() => {
    const filtered = selectedCategory?.items
      .filter((item) => {
        return item.name.toLowerCase().includes(searchString.toLowerCase())
      })
      .slice(0, 5)

    filtered && setFilteredItems(filtered)
  }, [searchString, selectedCategoryId, customers, ads, campaigns, mediaPlans])

  const categories: CategoriesType = [
    {
      id: 'customers',
      label: 'Clients',
      fetchData: async () => getCustomers({ view: ViewEnum.NAME }),
      items: customers,
      setItems: setCustomers,
      getRoute: (itemId: string) => `/customer/${itemId}/details`
    },
    {
      id: 'mediaPlans',
      label: 'Plan médias',
      fetchData: async () => getMediaPlansForHeader({ view: ViewEnum.NAME }),
      items: mediaPlans,
      setItems: setMediaPlans,
      getRoute: (itemId: string) => `/media-plan/${itemId}/details`
    },
    {
      id: 'campaigns',
      label: 'Campagnes',
      fetchData: async () => getCampaignsForHeader({ view: ViewEnum.NAME }),
      items: campaigns,
      setItems: setCampaigns,
      getRoute: (itemId: string) => `/campaign/${itemId}/details`
    },
    {
      id: 'ads',
      label: 'Annonces',
      fetchData: async () => getAds({ view: ViewEnum.NAME }),
      items: ads,
      setItems: setAds,
      getRoute: (itemId: string) => `/ad/${itemId}/details`
    }
  ]

  const selectedCategory = categories.find(
    (cat) => cat.id === selectedCategoryId
  )

  const handleSearchClick = () => {
    !hasBeenClicked && setHasBeenClicked(true)
  }

  const handleSelectCategory = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    categoryId: string
  ) => {
    event.preventDefault()
    setSelectedCategoryId(categoryId)
    setShowCategories(false)
  }

  const handleInputClick = () => {
    !showFilteredList && setShowFilteredList(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (callback) {
        callback()
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchString = e.target.value
    setSearchString(newSearchString)
    showCategories && setShowCategories(false)
  }

  const handleNavigate = (itemId: string) => {
    const url = selectedCategory?.getRoute(itemId)
    url && navigate(url)
  }

  return (
    <Search onClick={handleSearchClick}>
      <SearchComponent ref={searchComponentRef}>
        <SearchList
          onClick={() => {
            setShowCategories(!showCategories)
          }}
        >
          <SearchListSelected>{selectedCategory?.label}</SearchListSelected>
          <SearchArrow>▾</SearchArrow>
          <SearchListCategories show={showCategories}>
            {categories.map((category) => (
              <SearchListCategory
                key={category.id}
                onClick={(e) => {
                  handleSelectCategory(e, category.id)
                }}
              >
                {category.label}
              </SearchListCategory>
            ))}
          </SearchListCategories>
        </SearchList>
        <InputSearch
          placeholder="Rechercher un élément"
          value={searchString}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onClick={handleInputClick}
        />
      </SearchComponent>
      {searchString.trim() !== '' && filteredItems.length > 0 && (
        <ListItemsContainer show={showFilteredList}>
          <ListItems>
            {filteredItems.map((item) => (
              <Item
                key={item.id}
                onClick={() => {
                  handleNavigate(item.id)
                }}
              >
                {item.name}
              </Item>
            ))}
          </ListItems>
        </ListItemsContainer>
      )}
    </Search>
  )
}

export default HeaderSearch
