import * as d3 from 'd3'

const drawXAxis = (
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  xAxisValues: string[],
  widthWithoutMargin: number,
  height: number
) => {
  const x = d3.scaleBand().domain(xAxisValues).range([0, widthWithoutMargin])

  const displayedXAxisValues = d3
    .scaleBand()
    .domain([xAxisValues[0], xAxisValues[xAxisValues.length - 1]])
    .range([0, widthWithoutMargin])

  svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .attr('class', 'x-axis')
    .call(
      d3
        .axisBottom(displayedXAxisValues)
        .tickSize(0)
        .tickPadding(14)
        .tickSizeOuter(0)
    )

  svg.selectAll('.x-axis').selectAll('text').style('font-size', '14px')

  const xAxisElements = svg.selectAll('.x-axis g.tick')
  const firstXAxisElement = xAxisElements.filter((_, i) => i === 0)
  const secondXAxisElement = xAxisElements.filter((_, i) => i === 1)

  secondXAxisElement.style(
    'transform',
    `translateX(${widthWithoutMargin - 100}px`
  )
  firstXAxisElement.style('transform', `translateX(${50}px`)

  return x
}

export default drawXAxis
