import React, { CSSProperties } from 'react'

import {
  BoxInfoContainer,
  BoxTitle,
  BoxTitleInfoTip,
  BoxTitleInfo,
  BoxContent,
  BoxPercent
} from './styles'
import Colors from '../../../../constants/colors'

export enum boxColor {
  BLUE = Colors.YETIC_GREEN_DARK,
  RED = Colors.YETIC_RED
}

export type percentType = {
  color: boxColor
  value: string
}

export type BoxInfoProps = {
  title?: string
  titleInfo?: string
  percent?: percentType
  content?: string | JSX.Element
  icon?: JSX.Element
  style?: CSSProperties
}

const BoxInfo = ({
  title,
  titleInfo,
  percent,
  content,
  icon,
  style
}: BoxInfoProps) => {
  return (
    <BoxInfoContainer style={style}>
      <BoxTitle>
        {title}
        {titleInfo ? (
          <BoxTitleInfoTip>
            i{titleInfo ? <BoxTitleInfo>{titleInfo}</BoxTitleInfo> : ''}
          </BoxTitleInfoTip>
        ) : (
          ''
        )}
      </BoxTitle>
      <BoxContent>
        {icon}
        <span
          style={{
            width: icon ? '60%' : '100%',
            height: icon ? 'auto' : '100%',
            alignItems: icon ? 'baseline' : 'center'
          }}
        >
          {content}
        </span>
      </BoxContent>
      {percent && (
        <BoxPercent>
          <span style={{ color: percent?.color }}>↗</span> {percent?.value}
        </BoxPercent>
      )}
    </BoxInfoContainer>
  )
}

export default BoxInfo
