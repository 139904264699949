import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../../../constants/margin'
import Colors from '../../../../../../constants/colors'

export const modalStyle: CSSProperties = {
  width: '30vw'
}

export const InputListDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${Margin.m7} 0 ${Margin.m7};
`

export const messageStyle: CSSProperties = {
  textAlign: 'center',
  margin: 'auto',
  width: '80%'
}

export const inputStyle: CSSProperties = {
  border: `1px solid ${Colors.YETIC_GREY_DARK}`
}

export const inputContainerStyle: CSSProperties = {
  width: '80%',
  margin: `${Margin.m4} 0`
}
