import React from 'react'

import {
  RadioButton,
  RadioButtonLabel,
  RadioList
} from '../../../../../components/RadioButton'
import DefaultText from '../../../../../components/DefaultText'
import { Gender } from '../../../../../types/gender'
import { genders } from '../../../../../constants/gender'
import { recoStyle } from './styles'

type radioProps = {
  radioSelectedGender: number
  onChangeGender: (index: number) => void
}

const GenderComponent = ({
  radioSelectedGender,
  onChangeGender
}: radioProps) => {
  const rblistValues = genders.map(
    ({ id, label }: { id: Gender; label: string }, index: number) => (
      <RadioButton
        checked={radioSelectedGender === index}
        onChange={() => {
          onChangeGender(index)
        }}
        key={id}
        withBorder
      >
        <RadioButtonLabel label={label} />
      </RadioButton>
    )
  )

  return (
    <>
      <DefaultText>Genre*</DefaultText>
      <DefaultText size="small" style={recoStyle}>
        Favorisez des publicités inclusives.
      </DefaultText>
      <RadioList values={rblistValues} selected={radioSelectedGender} />
    </>
  )
}

export default GenderComponent
