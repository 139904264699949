import React from 'react'

import { IconType } from '../../types/icon'

const ArrowBack = ({ width }: IconType) => {
  return (
    <svg
      width={width ?? '20px'}
      height={width ?? '20px'}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{ cursor: 'pointer' }}
    >
      <path
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 10H2m0 0l7-7m-7 7l7 7"
      />
    </svg>
  )
}

export default ArrowBack
