import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../../constants/margin'
import Padding from '../../../../../constants/padding'

export const PreviewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${Padding.p7} 0 ${Padding.p2};
`

export const ImagesSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TextInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: ${Margin.m4};
`

export const previewMessageStyle: CSSProperties = {
  marginTop: Margin.m8,
  marginBottom: Margin.m8,
  textAlign: 'center'
}
