import { backendURL } from '../../../../../constants/urls'
import { GetMediaPlanOverallReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../../axios/get'
import { checkIfGetCampaignOverallReporting } from '../overall'

type GetMediaPlanOverallReportingInputType = {
  mediaPlanId: string
  startDate?: string
  endDate?: string
}

export const getMediaPlanOverallReporting = async ({
  mediaPlanId,
  startDate,
  endDate
}: GetMediaPlanOverallReportingInputType): Promise<GetMediaPlanOverallReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/media-plan/${mediaPlanId}/sections/overall${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetMediaPlanOverallReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetMediaPlanOverallReporting = (
  val: any
): val is GetMediaPlanOverallReportingOutputType => {
  return checkIfGetCampaignOverallReporting(val)
}
