import { parseStringDate, verifyStringIsValidDate } from '../../../helpers/date'
import { MediaPlanGoal } from '../../../types/goals'

export const checkName = (name: string) => {
  const isNameValid = name.length > 1
  return isNameValid
}

export const checkGoals = (selectedGoals: (keyof typeof MediaPlanGoal)[]) => {
  const areGoalsValid = selectedGoals.length > 0
  return areGoalsValid
}

export const checkAudience = (selectedAudiences: string[]) => {
  const areAudiencesValid = selectedAudiences.length > 0
  return areAudiencesValid
}

export const checkBudget = (amountBudget: number) => {
  const isAmountBudgetValid = amountBudget > 0
  return isAmountBudgetValid
}

export const checkStartDate = (startDate: string) => {
  const isStartDateValid = verifyStringIsValidDate(startDate)

  if (isStartDateValid) {
    const parsedStartDate = parseStringDate(startDate)
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0)
    const isStartGreaterThanToday =
      parsedStartDate.getTime() > tomorrow.getTime()

    return isStartGreaterThanToday
  } else {
    return false
  }
}

// TODO: check endDate after startDate
export const checkEndDate = (startDate: string, endDate: string) => {
  const isStartDateValid = verifyStringIsValidDate(startDate)
  const isEndDateValid = verifyStringIsValidDate(endDate)

  if (isStartDateValid && isEndDateValid) {
    const parsedStartDate = parseStringDate(startDate)
    const parsedEndDate = parseStringDate(endDate)
    const isEndGreaterThanStart =
      parsedEndDate.getTime() > parsedStartDate.getTime()
    return isEndGreaterThanStart
  } else {
    return isEndDateValid
  }
}
