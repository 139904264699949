import React from 'react'

import Dropdown, { Option } from '../../../../components/Dropdown'

const options: Option[] = [
  { id: 1, label: 'Français' }
  // { id: 2, label: 'Anglais' }
]

export type LanguageProps = {
  language: string
  handleLanguageChange: (language: string) => void
}

const Language = ({ language, handleLanguageChange }: LanguageProps) => {
  const getLanguageFromOption = (option: Option | null) => {
    // if (option == null || option.label === options[0].label) {
    return 'fr'
    // } else {
    //   return 'en'
    // }
  }

  const getOptionFromLanguage = (currentLanguage: string) => {
    // if (currentLanguage === 'en') {
    //   return options[1]
    // } else {
    return options[0]
    // }
  }

  return (
    <Dropdown
      onChange={(option: Option | null) => {
        handleLanguageChange(getLanguageFromOption(option))
      }}
      options={options}
      width="315px"
      value={getOptionFromLanguage(language)}
      placeholder="Choisissez une langue"
    />
  )
}

export default Language
