import React, { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'

import SubTitle from './SubTitle'
import ThinTitle from './ThinTitle'
import Colors from '../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'
import FontSize from '../../constants/fontSize'
import Margin from '../../constants/margin'

export interface TitleProps {
  children: ReactNode
  type?: 'subTitle' | 'big' | 'thin'
  style?: CSSProperties
}

const StyledTitle = styled.h1`
  color: ${Colors.YETIC_BLUE_DARK};

  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.YETIC_TITLE};
  font-weight: 600;

  margin-top: ${Margin.m0};
`

const Title = ({ children, type = undefined, style = {} }: TitleProps) =>
  type === 'subTitle' ? (
    <SubTitle style={style}>{children}</SubTitle>
  ) : type === 'thin' ? (
    <ThinTitle style={style}>{children}</ThinTitle>
  ) : (
    <StyledTitle style={style}>{children}</StyledTitle>
  )

export default Title
