import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Input from '../../components/Input'
import Margin from '../../constants/margin'
import Padding from '../../constants/padding'
import Button from '../../components/Button'
import DefaultText from '../../components/DefaultText'
import {
  DivPasswordContainer,
  ResetPasswordInputContainer,
  inputStyle,
  successMessageStyle
} from './styles'
import { AppContext } from '../../contexts/AppContext'
import {
  checkEmail,
  checkIfEmptyErrors,
  checkPassword
} from '../../helpers/checkers'
import { checkPasswordConfirmation } from './helpers/checkers'
import { InputError } from '../../components/Input/Styles'
import { UpdatePasswordFormErrorEnum } from '../../types/error/auth/password'
import {
  checkIfHandleResetPasswordResult,
  handleResetPassword
} from '../../helpers/queries/auth/password/resetPassword'

type ErrorType = {
  email: string
  password: string
  passwordConfirmation: string
}

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const navigate = useNavigate()
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email)
    }
  }, [user])

  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const errors: ErrorType = {
    email: checkEmail(email) ? '' : UpdatePasswordFormErrorEnum.EMAIL,
    password: checkPassword(password)
      ? ''
      : UpdatePasswordFormErrorEnum.PASSWORD,
    passwordConfirmation: checkPasswordConfirmation(
      password,
      passwordConfirmation
    )
      ? ''
      : UpdatePasswordFormErrorEnum.CONFIRM_PASSWORD
  }

  const errorToDisplay = Object.values(errors).filter((err) => err !== '')[0]

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value)
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleTogglePasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    if (checkIfEmptyErrors(errors)) {
      if (token) {
        const resetPasswordResult = await handleResetPassword({
          token,
          email,
          password
        })

        if (checkIfHandleResetPasswordResult(resetPasswordResult)) {
          setSuccess(true)
        } else {
          setSubmitError(resetPasswordResult.error)
        }
      } else {
        setSubmitError(
          `La mise à jour de votre mot de passe n'a pas pu être effectuée. Le token est manquant.`
        )
      }
    }
    setHasBeenSubmitted(true)
  }

  const btnText = success
    ? 'Retour à la page de connexion'
    : 'Réinitialiser mon mot de passe'
  const btnAction = success
    ? () => {
        navigate('/logout')
      }
    : () => {
        handleSubmit()
      }

  return (
    <>
      <ResetPasswordInputContainer>
        <DefaultText
          size="big"
          style={{
            marginBottom: Margin.m6,
            padding: Padding.p2
          }}
        >
          Modification de votre mot de passe
        </DefaultText>
        <DivPasswordContainer>
          {(!user || !user.email) && (
            <Input
              type="text"
              label={'Adresse e-mail'}
              placeholder="E-mail"
              style={inputStyle}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
            />
          )}
          <Input
            type={'password'}
            label={'Mot de passe'}
            placeholder="Mot de passe"
            style={inputStyle}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
            showPassword={showPassword}
            setShowPassword={handleTogglePassword}
            value={password}
          />
          <Input
            type="password"
            label={'Confirmer le mot de passe'}
            placeholder="Confirmer le mot de passe"
            style={inputStyle}
            onChange={handlePasswordConfirmationChange}
            onKeyDown={handleKeyDown}
            showPassword={showPasswordConfirmation}
            setShowPassword={handleTogglePasswordConfirmation}
            value={passwordConfirmation}
          />
          {success && (
            <DefaultText size="medium" bold style={successMessageStyle}>
              Votre mot de passe a bien été modifié.
            </DefaultText>
          )}
          {hasBeenSubmitted && !success && (
            <InputError style={{ textAlign: 'left' }}>
              {errorToDisplay}
            </InputError>
          )}
          {submitError && !success && !errorToDisplay && (
            <InputError style={{ textAlign: 'left' }}>{submitError}</InputError>
          )}
          <Button
            type="primary"
            width="fit-content"
            style={{ marginTop: Margin.m8 }}
            onClick={btnAction}
            disabled={hasBeenSubmitted && !checkIfEmptyErrors(errors)}
          >
            {btnText}
          </Button>
        </DivPasswordContainer>
      </ResetPasswordInputContainer>
    </>
  )
}

export default ResetPassword
