import React from 'react'

import {
  ModalOverlay,
  ModalWrapper,
  CloseButton,
  DivButtons,
  ModalContent
} from './styles'
import CrossClose from '../Icon/crossClose'
import Button from '../Button'
import { ModalType } from '../../types/modal'
import DefaultText from '../DefaultText'
import AnimatedLoader from '../AnimatedLoader'
import Colors from '../../constants/colors'

const ConfirmationModal = ({
  onClose,
  children,
  onClickReject,
  onClickConfirm,
  styleButtonConfirm,
  styleButtonRefuse,
  textConfirm,
  textReject,
  isConfirmLoading,
  confirmHidden,
  title,
  style,
  divButtonsStyle
}: ModalType) => {
  return (
    <>
      <ModalOverlay onClick={onClose} />
      <ModalWrapper style={style}>
        <CloseButton onClick={onClose}>
          <CrossClose />
        </CloseButton>
        {title && (
          <DefaultText size="big" style={{ textAlign: 'center' }}>
            {title}
          </DefaultText>
        )}
        <ModalContent>{children ?? null}</ModalContent>
        <DivButtons style={divButtonsStyle}>
          <Button
            width="fit-content"
            onClick={onClickReject}
            type="secondary"
            style={{ minWidth: '80px', ...styleButtonRefuse }}
          >
            {textReject ?? 'Annuler'}
          </Button>
          {!confirmHidden && (
            <Button
              width="fit-content"
              onClick={onClickConfirm}
              style={{ minWidth: '80px', ...styleButtonConfirm }}
            >
              {isConfirmLoading ? (
                <AnimatedLoader
                  color={styleButtonConfirm?.color ?? Colors.YETIC_WHITE}
                />
              ) : (
                textConfirm ?? 'Oui'
              )}
            </Button>
          )}
        </DivButtons>
      </ModalWrapper>
    </>
  )
}

export default ConfirmationModal
