import { MediaPlanStatusEnum } from './status'
import { GoalsEnum, checkIfGoalsEnum } from '../goals'
import { checkIfDate, isStringArray } from '../../helpers/nativeTypes'
import { UserType, checkIfUser } from '../user'
import { Status, checkIfStatus } from '../status'
import { FormatAPIEnum, checkIfFormatApiEnum } from '../format'
import { PlatformAPIEnum, checkIfPlatformApiEnum } from '../platform'

export type MediaPlanType = {
  id: string
  name: string
  budget: number
  period: {
    start: Date
    end: Date
  }
  status?: MediaPlanStatusEnum
  goals: GoalsEnum[]
  audiences: string[]
  advertiserId: string
  editorId: string
}

export const checkIfMediaPlan = (data: any): data is MediaPlanType => {
  return (
    data.name &&
    typeof data.name === 'string' &&
    data.budget &&
    typeof data.budget === 'number' &&
    data.status &&
    typeof data.status === 'string' &&
    data.goals &&
    data.goals.reduce((prev: boolean, currentGoal: GoalsEnum) => {
      return prev && checkIfGoalsEnum(currentGoal)
    }, true) &&
    data.audiences &&
    isStringArray(data.audiences) &&
    data.startDate &&
    checkIfDate(data.startDate) &&
    data.endDate &&
    checkIfDate(data.endDate)
  )
}

export const checkIfMediaPlanArray = (data: any): data is MediaPlanType[] => {
  return (
    Array.isArray(data) &&
    data.every((mediaPlan: any) => checkIfMediaPlan(mediaPlan))
  )
}

export const checkIfMediaPlanNameOnlyArray = (
  data: any
): data is { id: string; name: string }[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (mediaPlan: any) =>
        mediaPlan.id &&
        mediaPlan.name &&
        typeof mediaPlan.id === 'string' &&
        typeof mediaPlan.name === 'string'
    )
  )
}

export const checkIfExportOutput = (data: any): data is { html: string } => {
  return typeof data.html === 'string'
}

export type MediaPlanCampaign = {
  id: string
  name: string
  status: Status
  format: FormatAPIEnum
  platform: PlatformAPIEnum
  startDate: string
  endDate: string
  adsCount: number
  dailyBudget: number
  expectedBudget: number
  spentBudget: number
  expectedBenefits: number
  realizedBenefits: number
  feedback?: string
  co2: number
  consumedCo2: number
}

type MediaPlanNavigation = {
  company: {
    id: string
    name: string
  }
  skip: number
  campaignsCount: number
}

export type MediaPlanDetailsType = {
  id: string
  name: string
  startDate: string
  endDate: string
  goals: GoalsEnum[]
  audiences: string[]
  status: Status
  expectedBudget: number
  spentBudget: number
  feedback?: string
  expectedBenefits: number
  realizedBenefits: number
  co2: number
  users: UserType[]
  campaigns: MediaPlanCampaign[]
  navigation: MediaPlanNavigation
}

const checkIfMediaPlanCampaign = (value: any): value is MediaPlanCampaign => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.status &&
    checkIfStatus(value.status) &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.platform &&
    checkIfPlatformApiEnum(value.platform) &&
    value.startDate &&
    typeof value.startDate === 'string' &&
    value.endDate &&
    typeof value.endDate === 'string' &&
    value.adsCount !== undefined &&
    typeof value.adsCount === 'number' &&
    value.expectedBudget !== undefined &&
    value.dailyBudget !== undefined &&
    typeof value.dailyBudget === 'number' &&
    typeof value.expectedBudget === 'number' &&
    value.spentBudget !== undefined &&
    typeof value.spentBudget === 'number' &&
    value.expectedBenefits !== undefined &&
    typeof value.expectedBenefits === 'number' &&
    value.realizedBenefits !== undefined &&
    typeof value.realizedBenefits === 'number' &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.consumedCo2 !== undefined &&
    typeof value.consumedCo2 === 'number'
  )
}

const checkIfMediaPlanNavigation = (
  value: any
): value is MediaPlanNavigation => {
  return (
    value &&
    value.company &&
    value.company.id &&
    typeof value.company.id === 'string' &&
    value.company.name &&
    typeof value.company.name === 'string' &&
    value.skip !== undefined &&
    typeof value.skip === 'number' &&
    value.campaignsCount !== undefined &&
    typeof value.campaignsCount === 'number'
  )
}

export const checkIfMediaPlanDetails = (
  value: any
): value is MediaPlanDetailsType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.startDate &&
    typeof value.startDate === 'string' &&
    value.endDate &&
    typeof value.endDate === 'string' &&
    value.goals &&
    Array.isArray(value.goals) &&
    value.goals.every((goal: any) => checkIfGoalsEnum(goal)) &&
    value.audiences &&
    isStringArray(value.audiences) &&
    value.status &&
    checkIfStatus(value.status) &&
    value.expectedBudget !== undefined &&
    typeof value.expectedBudget === 'number' &&
    value.spentBudget !== undefined &&
    typeof value.spentBudget === 'number' &&
    value.expectedBenefits !== undefined &&
    typeof value.expectedBenefits === 'number' &&
    value.realizedBenefits !== undefined &&
    typeof value.realizedBenefits === 'number' &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.users &&
    Array.isArray(value.users) &&
    value.users.every((user: any) => checkIfUser(user)) &&
    value.campaigns &&
    Array.isArray(value.campaigns) &&
    value.campaigns.every((campaign: any) =>
      checkIfMediaPlanCampaign(campaign)
    ) &&
    value.navigation &&
    checkIfMediaPlanNavigation(value.navigation)
  )
}

export type MediaPlanTypeReviewPage = {
  id: string
  name: string
  isEditorPaying: boolean
  budget: number
  period: {
    start: Date
    end: Date
  }
  status: Status
  goals: GoalsEnum[]
  audiences: string[]
  advertiserId: string
  editor: {
    id: string
    companyName: string
    authorEmail: string
  }
}

export const checkIfMediaPlanReviewPage = (
  data: any
): data is MediaPlanTypeReviewPage => {
  return (
    data.id &&
    typeof data.id === 'string' &&
    data.name &&
    typeof data.name === 'string' &&
    data.isEditorPaying !== undefined &&
    data.budget &&
    typeof data.budget === 'number' &&
    data.status &&
    typeof data.status === 'string' &&
    data.goals &&
    data.goals.reduce((prev: boolean, currentGoal: GoalsEnum) => {
      return prev && checkIfGoalsEnum(currentGoal)
    }, true) &&
    data.audiences &&
    isStringArray(data.audiences) &&
    data.editor &&
    data.editor.id &&
    typeof data.editor.id === 'string' &&
    data.editor.companyName &&
    typeof data.editor.companyName === 'string' &&
    data.editor.authorEmail &&
    typeof data.editor.authorEmail === 'string'
  )
}
