import React from 'react'

import {
  HeatmapTooltipContainer,
  HeatmapTooltipItem,
  HeatmapTooltipItemLegend,
  HeatmapTooltipItemName,
  HeatmapTooltipItemValue,
  HeatmapTooltipTitle
} from './styles'
import { FormattedPoint, HeatmapData } from '..'

type HeatmapTooltipProps = {
  chartId: string
  data: HeatmapData<FormattedPoint>
}

const HeatmapTooltip = ({ chartId, data }: HeatmapTooltipProps) => {
  return (
    <HeatmapTooltipContainer id={`${chartId}-tooltip`}>
      <HeatmapTooltipTitle
        id={`${chartId}-tooltip-title`}
      ></HeatmapTooltipTitle>
      {data.map((d) => (
        <HeatmapTooltipItem id={`${chartId}-tooltip-${d.name}`} key={d.name}>
          <HeatmapTooltipItemLegend>
            <HeatmapTooltipItemName>{d.name}</HeatmapTooltipItemName>
          </HeatmapTooltipItemLegend>
          <HeatmapTooltipItemValue
            className={`${chartId}-tooltip-${d.name}-value`}
          ></HeatmapTooltipItemValue>
        </HeatmapTooltipItem>
      ))}
    </HeatmapTooltipContainer>
  )
}

export default HeatmapTooltip
