// TODO : get data from the route /tutorial/table
// data will have the following shape :
/*
  {
    mediaPlans: {
        name: string
        co2: number
        campaigns: {
            name: string
            co2: number
        }[]
    }[]
  }
*/

const tableData: GetTableDataType[] = [
  {
    id: '1',
    name: 'Mediaplan 1',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 1',
        co2: 0.1
      },
      {
        name: 'Campaign 2',
        co2: 0.1
      },
      {
        name: 'Campaign 865',
        co2: 0.1
      },
      {
        name: 'Campaign 464',
        co2: 0.1
      }
    ]
  },
  {
    id: '2',
    name: 'Mediaplan 2',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 3',
        co2: 0.1
      },
      {
        name: 'Campaign 4',
        co2: 0.1
      }
    ]
  },
  {
    id: '3',
    name: 'Mediaplan 3',
    co2: 0.2,
    campaigns: []
  },
  {
    id: '4',
    name: 'Mediaplan 4',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 1',
        co2: 0.1
      },
      {
        name: 'Campaign 2',
        co2: 0.1
      },
      {
        name: 'Campaign 865',
        co2: 0.1
      },
      {
        name: 'Campaign 464',
        co2: 0.1
      }
    ]
  },
  {
    id: '5',
    name: 'Mediaplan 5',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 3',
        co2: 0.1
      },
      {
        name: 'Campaign 4',
        co2: 0.1
      }
    ]
  },
  {
    id: '6',
    name: 'Mediaplan 6',
    co2: 0.2,
    campaigns: []
  },
  {
    id: '7',
    name: 'Mediaplan 7',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 1',
        co2: 0.1
      },
      {
        name: 'Campaign 2',
        co2: 0.1
      },
      {
        name: 'Campaign 865',
        co2: 0.1
      },
      {
        name: 'Campaign 464',
        co2: 0.1
      }
    ]
  },
  {
    id: '8',
    name: 'Mediaplan 8',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 3',
        co2: 0.1
      },
      {
        name: 'Campaign 4',
        co2: 0.1
      }
    ]
  },
  {
    id: '9',
    name: 'Mediaplan 9',
    co2: 0.2,
    campaigns: []
  },
  {
    id: '10',
    name: 'Mediaplan 10',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 1',
        co2: 0.1
      },
      {
        name: 'Campaign 2',
        co2: 0.1
      },
      {
        name: 'Campaign 865',
        co2: 0.1
      },
      {
        name: 'Campaign 464',
        co2: 0.1
      }
    ]
  },
  {
    id: '11',
    name: 'Mediaplan 11',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 3',
        co2: 0.1
      },
      {
        name: 'Campaign 4',
        co2: 0.1
      }
    ]
  },
  {
    id: '12',
    name: 'Mediaplan 12',
    co2: 0.2,
    campaigns: []
  },
  {
    id: '13',
    name: 'Mediaplan 13',
    co2: 0.2,
    campaigns: [
      {
        name: 'Campaign 3',
        co2: 0.1
      },
      {
        name: 'Campaign 4',
        co2: 0.1
      }
    ]
  }
]

export type GetTableDataOutputType = {
  mediaPlans: GetTableDataType[]
  count: number
} | null

export type GetTableCampaignsType = {
  name: string
  co2: number
}

export type GetTableDataType = {
  id: string
  name: string
  co2: number
  campaigns: GetTableCampaignsType[]
}

export type MediaPlanDetailsDataTable = {
  id: string
  row: (string | JSX.Element)[]
  details: {
    column1: {
      label: string
      value: string
    }[]
  }
}

export const getTableData = async (skip: number = 0, limit: number = 5) => {
  return new Promise<GetTableDataOutputType>((resolve, reject) => {
    if (skip < tableData.length) {
      const indexOfFirstMediaPlanToDisplay: number = skip

      const indexOfLastMediaPlanToDisplay: number =
        indexOfFirstMediaPlanToDisplay + limit

      const slicedData = tableData.slice(
        indexOfFirstMediaPlanToDisplay,
        indexOfLastMediaPlanToDisplay > tableData.length
          ? tableData.length
          : indexOfLastMediaPlanToDisplay
      )
      resolve({
        mediaPlans: slicedData,
        count: tableData.length
      })
    } else {
      resolve({
        mediaPlans: [],
        count: 0
      })
    }
  })
}
