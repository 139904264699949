import React, { useState } from 'react'

import { CampaignsTabContainer } from './styles'
import { formatCampaignsData } from './helpers/formatData'
import { TableContainer } from '../../styles'
import Table, { ColumnItem } from '../../../../components/Table'
import { AlignEnum, SortEnum } from '../../../../types/common'
import { GetMediaPlanCampaignsReportingOutputType } from '../../../../types/reporting/mediaPlan/output'

type CampaignsTabProps = {
  reportingData: GetMediaPlanCampaignsReportingOutputType
}

const CampaignsTab = ({ reportingData }: CampaignsTabProps) => {
  const { tableData } = formatCampaignsData(reportingData)
  const [tablePage, setTablePage] = useState(1)

  const tableColumns: ColumnItem[] = [
    {
      name: 'campaignName',
      label: 'Nom de la campagne',
      isSortable: true,
      width: '60%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '20%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '20%'
    }
  ]

  const defaultTableSort = {
    name: 'campaignName',
    order: SortEnum.ASC
  }

  return (
    <CampaignsTabContainer>
      <TableContainer>
        <Table
          title="Clics et impressions par campagne"
          columns={tableColumns}
          elements={tableData}
          sort={defaultTableSort}
          pagination={{
            totalItems: tableData.length,
            itemsByPage: 10,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
        />
      </TableContainer>
    </CampaignsTabContainer>
  )
}

export default CampaignsTab
