import { maxPageWidth } from '../../../../constants/width'
import { leftMenuWidthWithPadding } from '../../../parts/LeftMenu/styles'

export const getPageContentWidth = (pagePadding: number) => {
  return (
    Math.min(maxPageWidth, screen.width) -
    leftMenuWidthWithPadding -
    pagePadding * 2
  )
}
