import React from 'react'
import TipsFormat from '../../../components/Tips/TipsFormat'

export const PeriodTips = ({
  mediaPlanTranslation
}: {
  mediaPlanTranslation: string
}) => (
  <TipsFormat
    title={`Choix de la période du ${mediaPlanTranslation}`}
    items={[
      {
        title:
          'Il est important de considérer 2 points pour bien définir cette période : ',
        content: [
          `Le ${mediaPlanTranslation} comprendra une ou plusieurs campagnes, et chacune de ces campagnes devra avoir
          une période incluse dans la période du ${mediaPlanTranslation}. Choisissez donc une période suffisamment large
          pour inclure toutes les campagnes que vous souhaitez mettre dans ce ${mediaPlanTranslation}.`,
          `La date de début du plan média doit être au moins 2 jours plus tard que la date actuelle. La raison est que
          certaines plateformes, comme Google Ads, ont besoin de temps pour vérifier certains contenus (par exemple le contenu
            des images pour les campagnes images sur Google Ads).`
        ]
      }
    ]}
  />
)
