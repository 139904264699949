import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ActionIdEnum } from '../../../../../types/widget'
import Button from '../../../../../components/Button'

type PropsType = {
  label: string
  actionId: ActionIdEnum
}

const CTA = ({ label, actionId }: PropsType) => {
  const navigate = useNavigate()

  const widgetCtaActions: Record<ActionIdEnum, () => void> = {
    [ActionIdEnum.NAVIGATE_HOME]: () => {
      navigate('/')
    }
  }

  return (
    <Button
      type="primary"
      style={{ width: 'fit-content', margin: '0 auto' }}
      onClick={widgetCtaActions[actionId]}
    >
      {label}
    </Button>
  )
}

export default CTA
