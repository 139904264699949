import React from 'react'

import { BoxsContainer, Wrapper } from './styles'
import { WidgetPageType } from '../../../../../types/widget'
import BoxButton from '../../../../../components/Widget/BoxButton'
import DefaultText from '../../../../../components/DefaultText'

type PropsType = {
  currentPageData: WidgetPageType
  iconMapping: Record<string, JSX.Element>
  handleWidgetNavigate: (pageId: string) => void
}

const WidgetNav = ({
  currentPageData,
  iconMapping,
  handleWidgetNavigate
}: PropsType) => {
  let navContent: JSX.Element[] = []

  if (currentPageData) {
    navContent = currentPageData.page.nav.map((n) => {
      const handleClick = () => {
        handleWidgetNavigate(n.redirectId)
      }

      let icon

      if (Object.keys(iconMapping).includes(n.icon)) {
        icon = iconMapping[n.icon]
      } else {
        icon = <></>
      }

      return (
        <BoxButton
          key={n.label}
          label={n.label}
          comingSoon={n.comingSoon}
          Icon={icon}
          handleClick={handleClick}
          height="100px"
        />
      )
    })
  }

  return (
    <Wrapper>
      <DefaultText bold size="medium">
        Sur quoi voulez-vous être informé ?
      </DefaultText>
      <BoxsContainer>{navContent}</BoxsContainer>
    </Wrapper>
  )
}

export default WidgetNav
