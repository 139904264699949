import React from 'react'

import {
  ImageAdCreationFormDataType,
  ImageAdFiles,
  ImageAdFilesUrls
} from '../types'
import InputFile from '../../../components/InputFile'
import { googleSquareRatio } from '../../../constants/image'
import { ImageAdFilesErrors } from '../../../helpers/imageAd/formErrors'
import { GoogleFormImageAdError } from '../../../types/pages/adImageCreation'
import { PanelType } from '../../../types/templates/panels'
import { ImagesSelectionContainer } from '../styles'

export type GetSquareMarketingImagesSelectionPanelInput = {
  formData: ImageAdCreationFormDataType
  imageUrls: ImageAdFilesUrls
  imageErrors: ImageAdFilesErrors
  handleImageChange: (
    attribute: keyof ImageAdFiles,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleImageDelete: (attribute: keyof ImageAdFiles) => void
  hasBeenSubmitted: boolean
}

export const getSquareMarketingImagesSelectionPanel = ({
  formData,
  imageUrls,
  imageErrors,
  handleImageChange,
  handleImageDelete,
  hasBeenSubmitted
}: GetSquareMarketingImagesSelectionPanelInput): PanelType => {
  const squareMarketingImages: Partial<ImageAdFiles> = {
    squareMarketingImage1: formData.squareMarketingImage1,
    squareMarketingImage2: formData.squareMarketingImage2,
    squareMarketingImage3: formData.squareMarketingImage3
  }

  const squareMarketingImagesKeys = Object.keys(
    squareMarketingImages
  ) as (keyof typeof squareMarketingImages)[]

  return {
    title: 'Sélection des images carrées',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez jusqu'à 3 images (une obligatoire).`,
    sections: [
      {
        title: 'Formats carrés*',
        description: `Choisissez des images au format ${googleSquareRatio}:1.`,
        content: (
          <ImagesSelectionContainer>
            {squareMarketingImagesKeys.map(
              (img, index) =>
                (index === 0 ||
                  squareMarketingImages[squareMarketingImagesKeys[index - 1]] ||
                  Object.values(squareMarketingImages).find((_, i) => {
                    return (
                      i >= index &&
                      squareMarketingImages[squareMarketingImagesKeys[i]]
                    )
                  })) && (
                  <InputFile
                    key={index}
                    uniqueKey={`squareMarketingImage${index + 1}`}
                    selectedFile={formData[squareMarketingImagesKeys[index]]}
                    fileUrl={imageUrls[squareMarketingImagesKeys[index]]}
                    handleChange={(e) => {
                      handleImageChange(squareMarketingImagesKeys[index], e)
                    }}
                    handleDelete={() => {
                      handleImageDelete(squareMarketingImagesKeys[index])
                    }}
                    error={
                      hasBeenSubmitted
                        ? imageErrors[squareMarketingImagesKeys[index]]
                        : imageErrors[squareMarketingImagesKeys[index]] !==
                          GoogleFormImageAdError.IMAGE_MISSING
                        ? imageErrors[squareMarketingImagesKeys[index]]
                        : ''
                    }
                  />
                )
            )}
          </ImagesSelectionContainer>
        )
      }
    ]
  }
}
