import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Margin.m3};
  padding: ${Padding.p5} ${Padding.p6} ${Padding.p6};
`

export const TeamMembers = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Margin.m6};
`

export const AvatarContainer = styled.div`
  width: 26px;
`
