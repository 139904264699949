import React from 'react'

import { ImageAdCreationFormDataType } from '..'
import Input from '../../../../../components/Input'
import { PanelType } from '../../../../../types/templates/panels'
import {
  linkedinRectangleRatio,
  linkedinSquareRatio
} from '../../../../../constants/image'
import { ImagesSelectionContainer } from '../styles'
import InputFile from '../../../../../components/InputFile'
import { LinkedinFormImageAdError } from '../../../../../types/pages/adImageCreation'

type GetImagePanelProps = {
  formData: ImageAdCreationFormDataType
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleImageDelete: () => void
  imageUrl: string
  hasBeenSubmitted: boolean
  error: string
}

const getImagePanel = ({
  formData,
  handleImageChange,
  handleImageDelete,
  imageUrl,
  hasBeenSubmitted,
  error
}: GetImagePanelProps): PanelType => {
  const imagePanel: PanelType = {
    title: "Sélection de l'image",
    disabled: false,
    isDropdown: false,
    description: 'Ajoutez votre image (obligatoire).',
    sections: [
      {
        title: 'Image*',
        description: `Choisissez votre image au format ${linkedinRectangleRatio}:1 ou ${linkedinSquareRatio}:1.`,
        content: (
          <ImagesSelectionContainer>
            <InputFile
              uniqueKey="imageInput"
              handleChange={(e) => {
                handleImageChange(e)
              }}
              handleDelete={() => {
                handleImageDelete()
              }}
              selectedFile={formData.image}
              fileUrl={imageUrl}
              error={
                hasBeenSubmitted
                  ? error
                  : error !== LinkedinFormImageAdError.IMAGE_MISSING
                  ? error
                  : ''
              }
            />
          </ImagesSelectionContainer>
        )
      }
    ]
  }

  return imagePanel
}

export default getImagePanel
