import React from 'react'

import { ImageAdCreationFormDataType } from '..'
import Input from '../../../../../components/Input'
import { PanelType } from '../../../../../types/templates/panels'
import { TextInputsContainer } from '../styles'

type GetNamePanelProps = {
  formData: ImageAdCreationFormDataType
  handleInputChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  hasBeenSubmitted: boolean
  error: string
}

const getNamePanel = ({
  formData,
  handleInputChange,
  hasBeenSubmitted,
  error
}: GetNamePanelProps): PanelType => {
  const namePanel: PanelType = {
    title: 'Nom',
    disabled: false,
    isDropdown: false,
    isShown: true,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="text"
              value={formData.name}
              isRequired={true}
              placeholder="Nom de mon annonce"
              onChange={(e) => {
                handleInputChange('name', e)
              }}
              maxLength={20}
              showCharacterCount
              label={`Nom de l'annonce`}
              error={hasBeenSubmitted ? error : ''}
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }

  return namePanel
}

export default getNamePanel
