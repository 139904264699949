import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../constants/margin'
import Padding from '../../../../constants/padding'

export const DivContainer = styled.div`
  margin: 0 ${Margin.m10};

  @media (max-width: 1000px) {
    margin: 10px 20px;
  }
`

export const errorMessageStyle: CSSProperties = {
  color: 'red',
  marginTop: '20px',
  marginBottom: '30px'
}

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${Padding.p3} 0;
  text-align: center;
`
