import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, DivContainer, messageStyle } from '../styles'
import DefaultText from '../../../../components/DefaultText'
import {
  checkIfHandleUserActivationResult,
  handleUserActivation
} from '../../../../helpers/queries/auth/userActivation'
import { MessageButton } from '../MessageButton'

type UserActivationProps = {
  token: string
}

const UserActivation = ({ token }: UserActivationProps) => {
  const [activated, setActivated] = useState(false)
  const [error, setError] = useState('')

  const isMountedRef = useRef<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!activated && !isMountedRef.current) {
      isMountedRef.current = true
      ;(async () => {
        const res = await handleUserActivation(token)
        if (checkIfHandleUserActivationResult(res)) {
          setActivated(true)
        } else {
          setError(res.error)
        }
      })()
    }
  }, [token])

  const handleGoToLogin = () => {
    navigate('/auth')
  }

  return (
    <DivContainer>
      <Content>
        {error ? (
          <MessageButton
            message={error}
            buttonLabel={`Revenir à l'écran de connexion`}
            buttonAction={handleGoToLogin}
          />
        ) : activated ? (
          <MessageButton
            message="Votre compte est en attente de validation par un administrateur. Vous recevrez un email dès que votre compte sera activé."
            buttonLabel="Revenir à l'écran de connexion"
            buttonAction={handleGoToLogin}
          />
        ) : (
          <DefaultText style={messageStyle}>
            {`Votre compte est en cours d'activation...`}
          </DefaultText>
        )}
      </Content>
    </DivContainer>
  )
}

export default UserActivation
