import { SurveyContentType } from '../types'

export const mockedRetailSurveyData: SurveyContentType = [
  {
    id: '1',
    group: 'sellType',
    text: 'Je veux promouvoir',
    next: [
      {
        id: '2',
        text: 'Mes produits'
      },
      {
        id: 'notHandled',
        text: 'Mes services'
      },
      {
        id: 'notHandled',
        text: 'Autre'
      }
    ]
  },
  {
    id: '2',
    text: 'Ces produits sont vendus majoritairement',
    group: 'productSellType',
    next: [
      {
        id: '3',
        text: 'En magasin'
      },
      {
        id: '3',
        text: 'En ligne'
      }
    ]
  },
  {
    id: '3',
    text: 'Je souhaite mettre en avant',
    group: 'adGoal',
    next: [
      {
        id: '4',
        text: 'Ma notoriété'
      },
      {
        id: '5',
        text: 'Un concept'
      },
      {
        id: '6',
        text: 'Un événement ou promotion'
      }
    ]
  },
  {
    id: '4',
    text: 'Je viens de lancer mon activité ou ma marque',
    group: 'activityLaunch',
    next: [
      {
        id: '7',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '7',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '5',
    text: 'Le concept de mon entreprise est innovant grâce à',
    group: 'innovativeConcept',
    next: [
      {
        id: '8',
        text: 'Mes produits vendus'
      },
      {
        id: '9',
        text: 'Les locaux autour de mes produits'
      }
    ]
  },
  {
    id: '6',
    text: 'Je veux mettre en avant',
    group: 'eventToPromote',
    next: [
      {
        id: '10',
        text: 'Un événement spécial'
      },
      {
        id: '10',
        text: 'Des promotions exclusives'
      },
      {
        id: '10',
        text: 'De nouveaux produits'
      }
    ]
  },
  {
    id: '7',
    text: 'Je cherche à élargir ma clientèle',
    group: 'customerGrowth',
    next: [
      {
        id: '11',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '11',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '8',
    text: 'Je souhaite mettre en avant le fait que mes produits soient',
    group: 'productUniqueness',
    next: [
      {
        id: '12',
        text: 'Uniques'
      },
      // TODO: another branch to ask if bio, fairtrade, etc.
      {
        id: '12',
        text: 'De grande qualité'
      },
      {
        id: '12',
        text: 'Proposés dans une large gamme'
      }
    ]
  },
  {
    id: '9',
    text: 'L’atmosphère de mes locaux propose',
    group: 'storeAtmosphere',
    next: [
      {
        id: '12',
        text: "Une expérience d'achat unique"
      },
      {
        id: '12',
        text: 'Des espaces de détente et de rencontre'
      },
      {
        id: '12',
        text: 'Des technologies innovantes'
      },
      {
        id: '12',
        text: 'Des éléments de design créatifs'
      }
    ]
  },
  {
    id: '10',
    text: "Je souhaite créer un sentiment d'urgence en mettant en avant une durée limitée",
    group: 'eventUrgency',
    next: [
      {
        id: '12',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '12',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '11',
    text: 'Mon objectif est de renforcer ma présence en ligne',
    group: 'onlinePresence',
    next: [
      {
        id: '13',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '13',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '12',
    text: 'Mes produits à mettre en avant sont des produits alimentaires ou des boissons',
    group: 'productTypeFoodOrDrink',
    next: [
      {
        id: '14',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '15',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '13',
    text: 'Je souhaite me démarquer de mes concurrents grâce à',
    group: 'standOut',
    next: [
      {
        id: '12',
        text: 'Une histoire ou des valeurs uniques'
      },
      {
        id: '12',
        text: 'Des prix imbattables'
      },
      {
        id: '12',
        text: 'La grande qualité de mes produits'
      },
      {
        id: '12',
        text: 'Un service client exceptionnel'
      },
      {
        id: '12',
        text: 'Un programme de fidélité'
      },
      {
        id: '12',
        text: 'Une personnalisation des produits'
      }
    ]
  },
  {
    id: '14',
    text: 'Ils sont destinés à être portés (vêtements, chaussures, accessoires)',
    group: 'productTypeWearable',
    next: [
      {
        id: '16',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '17',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '15',
    text: 'Est-ce des produits frais ?',
    group: 'productTypeFoodAndDrinkFresh',
    next: [
      {
        id: '18',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '19',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '16',
    text: 'Ce sont des appareils électroniques',
    group: 'productTypeElectronic',
    next: [
      {
        id: '20',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '21',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '17',
    text: 'Je veux promouvoir des vêtements pour hommes',
    group: 'productTypeClothesMen',
    next: [
      {
        id: '22',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '22',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '18',
    text: 'Est-ce des produits secs ou en conserve ?',
    group: 'productTypeFoodAndDrinkDry',
    next: [
      {
        id: '23',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '24',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '19',
    text: 'Est-ce des fruits et légumes frais ?',
    group: 'productTypeFoodAndDrinkFreshFruits',
    next: [
      {
        id: '25',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '25',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '20',
    text: 'Ces produits sont liés aux loisirs, sports ou activités de plein air',
    group: 'productTypeSport',
    next: [
      {
        id: '26',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '27',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '21',
    text: 'Je souhaite promouvoir des appareils éléctroniques grand public (téléphones, ordinateurs, tablettes)',
    group: 'productTypeElectronicConsumer',
    next: [
      {
        id: '28',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '29',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '22',
    text: 'Je veux promouvoir des vêtements pour femmes',
    group: 'productTypeClothesWomen',
    next: [
      {
        id: '30',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '30',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '23',
    text: 'Est-ce des boissons ?',
    group: 'productTypeDrink',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '32',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '24',
    text: 'Je veux promouvoir du pain, des sandwiches ou des pâtisseries',
    group: 'productTypeFoodAndDrinkDryBread',
    next: [
      {
        id: '33',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '25',
    text: 'Est-ce de la viande, du poisson ou des produits laitiers ?',
    group: 'productTypeFoodAndDrinkFreshMeat',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '26',
    text: 'Ce sont des produits de beauté, des cosmétiques ou de soins personnels',
    group: 'productTypeBeauty',
    next: [
      {
        id: '37',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '38',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '27',
    text: 'Je souhaite promouvoir des équipements pour des sports spécifiques (football, natation, tennis, etc.)',
    group: 'productTypeSportSpecific',
    next: [
      {
        id: '39',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '28',
    text: 'Je souhaite promouvoir des accessoires ou des consommables (piles, chargeurs, souris, clés USB)',
    group: 'productTypeElectronicAccessory',
    next: [
      {
        id: '0-28',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-28',
    text: 'Je souhaite plutôt promouvoir des appareils électroménagers',
    group: 'productTypeHomeAppliance',
    next: [
      {
        id: '41',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '29',
    text: 'Mes produits à mettre en avant sont des smartphones ou des tablettes',
    group: 'productTypeElectronicSmartphone',
    next: [
      {
        id: '43',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '30',
    text: 'Je veux promouvoir des vêtements pour enfants ou bébés',
    group: 'productTypeClothesKids',
    next: [
      {
        id: '45',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '45',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '32',
    text: 'Je veux promouvoir des boissons non alcoolisées',
    group: 'productTypeDrinkNonAlcoholic',
    next: [
      {
        id: '46',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '33',
    text: 'Je veux promouvoir des pâtes, du riz ou des céréales',
    group: 'productTypeFoodAndDrinkDryPasta',
    next: [
      {
        id: '48',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '48',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '37',
    text: 'Ces produits sont destinés à la maison, à la décoration ou au bricolage',
    group: 'productTypeHome',
    next: [
      {
        id: '49',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '50',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '38',
    text: 'Je veux promouvoir des produits de maquillage ou de soins de la peau',
    group: 'productTypeBeautyMakeup',
    next: [
      {
        id: '0-51',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '51',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '39',
    text: 'Mes produits à mettre en avant sont destinés aux activités de plein air (randonnée, camping, etc.)',
    group: 'productTypeSportOutdoor',
    next: [
      {
        id: '52',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '53',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '41',
    text: 'Je souhaite promouvoir des consoles de jeu ou des jeux vidéo',
    group: 'productTypeElectronicAudioVideo',
    next: [
      {
        id: '0-41',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-41',
    text: 'Je souhaite promouvoir des produits audio ou vidéo',
    group: 'productTypeElectronicAudioVideo',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '55',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '43',
    text: 'Ce sont des ordinateurs portables ou de bureau',
    group: 'productTypeElectronicComputer',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '45',
    text: 'Mes vêtements sont',
    group: 'productTypeClothesPrice',
    next: [
      {
        id: '58',
        text: 'Haut de gamme'
      },
      {
        id: '59',
        text: 'À prix abordables'
      }
    ]
  },
  {
    id: '46',
    text: 'Je veux promouvoir des vins, des bières ou des spiritueux',
    group: 'productTypeDrinkAlcoholic',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '48',
    text: 'Je veux promouvoir des conserves, des plats préparés ou des soupes',
    group: 'productTypeFoodAndDrinkDryCanned',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '49',
    text: 'Mes produits sont des jeux, jouets ou articles pour enfants',
    group: 'productTypeToys',
    next: [
      {
        id: '63',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '64',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '50',
    text: "Je veux promouvoir des meubles ou des articles d'ameublement",
    group: 'productTypeHomeFurniture',
    next: [
      {
        id: '65',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '66',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-51',
    text: 'Je veux promouvoir des parfums ou des eaux de toilette',
    group: 'productTypeBeautyPerfume',
    next: [
      {
        id: '0-67',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '0-68',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '51',
    text: 'Je veux promouvoir des fonds de teint, des rouges à lèvres, des mascaras ou vernis à ongles',
    group: 'productTypeBeautyMakeup',
    next: [
      {
        id: '67',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '52',
    text: 'Mes produits sont des articles de musculation ou de fitness',
    group: 'productTypeSportFitness',
    next: [
      {
        id: '69',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '70',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '53',
    text: 'Je veux promouvoir des tentes, des sacs de couchage ou des réchauds',
    group: 'productTypeSportOutdoorTent',
    next: [
      {
        id: '71',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '55',
    text: 'Je souhaite promouvoir des enceintes, des casques audio ou des écouteurs',
    group: 'productTypeElectronicAudio',
    next: [
      {
        id: '73',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '73',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '58',
    text: 'Ces vêtements sont de marque créateur',
    group: 'productTypeClothesBrand',
    next: [
      {
        id: '75',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '75',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '59',
    text: 'Est-ce des vêtements de sport ?',
    group: 'productTypeClothesSport',
    next: [
      {
        id: '75',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '63',
    text: 'Ce sont des produits liés à la santé, au bien-être ou à la pharmacie',
    group: 'productTypeHealth',
    next: [
      {
        id: '77',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '78',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '64',
    text: 'Pour des enfants en bas âge (0-3 ans)',
    group: 'productTypeToysKids',
    next: [
      {
        id: '79',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '80',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '65',
    text: 'Mes produits sont destinés à la décoration intérieure',
    group: 'productTypeHomeDecoration',
    next: [
      {
        id: '81',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '82',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '66',
    text: 'Je veux promouvoir de la literie',
    group: 'productTypeHomeFurnitureSofa',
    next: [
      {
        id: '83',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '83',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-67',
    text: 'Je veux promouvoir des produits pour les cheveux',
    group: 'productTypeBeautyHairCare',
    next: [
      {
        id: '0-84',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '0-85',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '67',
    text: 'Je veux promouvoir des crèmes hydratantes, des sérums ou des nettoyants pour le visage',
    group: 'productTypeBeautySkinCare',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-68',
    text: 'Je veux promouvoir des parfums',
    group: 'productTypeBeautyPerfume',
    next: [
      {
        id: 'todo',
        text: 'Pour femmes'
      },
      {
        id: 'todo',
        text: 'Pour hommes'
      },
      {
        id: 'todo',
        text: 'Les deux'
      }
    ]
  },
  {
    id: '69',
    text: 'Je veux promouvoir des articles de musique (instruments, vinyles, partitions)',
    group: 'productTypeLeisureMusic',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '70',
    text: 'Je veux promouvoir des haltères, tapis de sol ou du matériel de fitness',
    group: 'productTypeSportFitnessSmallEquipment',
    next: [
      {
        id: '89',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '71',
    text: 'Je veux promouvoir des chaussures de randonnée, des sacs à dos ou des bâtons de marche',
    group: 'productTypeSportOutdoorShoes',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '73',
    text: "Je veux promouvoir des téléviseurs, des vidéo-projecteurs ou des écrans d'ordinateur",
    group: 'productTypeElectronicTV',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '75',
    text: 'Est-ce des chaussures ou des accessoires ?',
    group: 'productTypeShoesAndAccessories',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '77',
    text: 'Ces produits sont des livres, des journaux, des magazines ou des articles de papeterie',
    group: 'productTypeBooks',
    next: [
      {
        id: '95',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '96',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '78',
    text: 'Je veux mettre en avant des compléments alimentaires ou des vitamines',
    group: 'productTypeHealthSupplementsOrVitamins',
    next: [
      {
        id: '97',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '98',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '79',
    text: 'Pour des enfants plus âgés (4-12 ans)',
    group: 'productTypeToysKidsOlder',
    next: [
      {
        id: '99',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '100',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '80',
    text: 'Je vends des hochets, des peluches ou des doudous',
    group: 'productTypeToysKidsPlush',
    next: [
      {
        id: '101',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '81',
    text: 'Je veux promouvoir des articles de cuisine',
    group: 'productTypeHomeKitchen',
    next: [
      {
        id: '103',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '104',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '82',
    text: 'Je veux promouvoir des tableaux, posters, des tapis ou des luminaires',
    group: 'productTypeHomeDecoration',
    next: [
      {
        id: '105',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '83',
    text: 'Je veux promouvoir des tables, chaises, des étagères ou commodes',
    group: 'productTypeHomeFurnitureChair',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-84',
    text: "Je veux promouvoir des produits d'hygiène personnelle",
    group: 'productTypeBeautyPersonalHygiene',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-85',
    text: 'Est-ce des shampooings, des après-shampooings ou des masques capillaires ?',
    group: 'productTypeBeautyHairCareHygiene',
    next: [
      {
        id: '0-105',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '89',
    text: 'Je veux promouvoir des tapis de course, des vélos elliptiques ou des rameurs',
    group: 'productTypeSportFitnessEquipment',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '95',
    text: "Ils sont liés à l'automobile, aux pièces détachées ou aux accessoires automobiles",
    group: 'productTypeAuto',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '109',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '96',
    text: 'Je souhaite mettre en avant',
    group: 'productTypeBooksSubType',
    next: [
      {
        id: '110',
        text: 'Des livres'
      },
      {
        id: '111',
        text: 'Des magazines ou journaux'
      },
      {
        id: '112',
        text: 'Des articles de papeterie'
      },
      {
        id: 'todo',
        text: 'Des fournitures de bureau'
      },
      {
        id: 'todo',
        text: 'Des fournitures scolaires'
      }
    ]
  },
  {
    id: '97',
    text: 'Je souhaite mettre en avant des produits liés à la santé naturelle ou à la phytothérapie',
    group: 'productTypeHealthNatural',
    next: [
      {
        id: '115',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '116',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '98',
    text: 'Je veux promouvoir des compléments alimentaires de type vitamines, minéraux, oméga 3 ou probiotiques',
    group: 'productTypeHealthSupplements',
    next: [
      {
        id: '117',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '99',
    text: 'Est-ce des jeux de société ou puzzles ?',
    group: 'productTypeToysBoardGames',
    next: [
      {
        id: '119',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '100',
    text: 'Je veux promouvoir des figurines, des poupées ou des véhicules miniatures',
    group: 'productTypeToysKidsOlderFigurines',
    next: [
      {
        id: '121',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '101',
    text: "Je vends des jeux d'éveil, des trotteurs ou des porteurs",
    group: 'productTypeToysKidsAwakening',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '103',
    text: 'Je veux vendre des articles pour le jardin ou l\'extérieur',
    group: 'productTypeHomeGarden',
    next: [
      {
        id: '125',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '126',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '104',
    text: 'Je veux promouvoir des ustensiles de cuisine, des robots ou des poêles et casseroles',
    group: 'productTypeHomeKitchenTools',
    next: [
      {
        id: '127',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '0-105',
    text: 'Est-ce plutôt des colorations, des défrisants ou des produits coiffants ?',
    group: 'productTypeBeautyHairCareAppearance',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '105',
    text: 'Je veux promouvoir des coussins, des tapis ou des rideaux',
    group: 'productTypeHomeDecorationCushion',
    next: [
      {
        id: '129',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '109',
    text: 'Je veux promouvoir des pièces détachées pour voiture',
    group: 'productTypeAutoAccessories',
    next: [
      {
        id: '131',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '131',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '110',
    text: 'Je veux vendre des romans, des essais, des biographies ou livres de poche',
    group: 'productTypeBooksPrimary',
    next: [
      {
        id: '133',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '133',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '111',
    text: "Je veux vendre des magazines d'actualité, de mode ou de décoration",
    group: 'productTypeBooksMagazinesNews',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '112',
    text: 'Je veux vendre des carnets, des agendas ou blocs-notes',
    group: 'productTypeBooksStationery',
    next: [
      {
        id: '137',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '137',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '115',
    text: 'Je veux promouvoir des articles bien-être ou relaxation',
    group: 'productTypeHealthRelaxation',
    next: [
      {
        id: '139',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '140',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '116',
    text: 'Je veux promouvoir des huiles essentielles, végétales ou des eaux florales',
    group: 'productTypeHealthEssentialOils',
    next: [
      {
        id: '141',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '141',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '117',
    text: 'Je veux promouvoir des protéines, des gainers ou des brûleurs de graisse',
    group: 'productTypeHealthProteins',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '119',
    text: 'Est-ce des articles reliés au gaming (jeux vidéo, consoles, accessoires)',
    group: 'productTypeToysVideoGames',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '146',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '121',
    text: 'Je veux promouvoir des jeux de construction, des puzzles ou des jeux éducatifs',
    group: 'productTypeToysKidsOlderEducational',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '125',
    text: ' Je veux vendre des outils de bricolage',
    group: 'productTypeHomeGardenTools',
    next: [
      {
        id: '147',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '126',
    text: 'Je veux promouvoir des plantes, des fleurs, des graines ou des pots',
    group: 'productTypeHomeGardenPlants',
    next: [
      {
        id: '125',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '127',
    text: 'Je veux promouvoir des verres, assiettes ou couverts',
    group: 'productTypeHomeKitchenToolsTableware',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '129',
    text: "Je veux vendre des plantes d'intérieur, des pots ou des cache-pots",
    group: 'productTypeHomeDecorationIndoorPlants',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '131',
    text: 'Je veux promouvoir des pneus, des batteries ou des accessoires pour la voiture',
    group: 'productTypeAutoAccessories',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '133',
    text: 'Je veux vendre des bandes dessinées ou des mangas',
    group: 'productTypeBooksComics',
    next: [
      {
        id: '148',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '148',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '137',
    text: ' Je veux vendre des stylos, des crayons ou de la peinture',
    group: 'productTypeBooksStationeryPens',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '139',
    text: "Je veux promouvoir des produits d'orthopédie, de contention ou de maintien",
    group: 'productTypeHealthOrthopedics',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '140',
    text: 'Je veux promouvoir des diffuseurs, des bougies ou des encens',
    group: 'productTypeHealthRelaxationDiffuser',
    next: [
      {
        id: '150',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '141',
    text: 'Je veux vendre des tisanes, des infusions ou plantes médicinales',
    group: 'productTypeHealthHerbalTea',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '146',
    text: 'Je veux promouvoir des consoles de jeux, des manettes ou des jeux vidéo',
    group: 'productTypeToysVideoGamesConsole',
    next: [
      {
        id: '153',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: '153',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '147',
    text: 'Je veux vendre des salons de jardin, des barbecues ou des parasols',
    group: 'productTypeHomeGardenOutdoorFurniture',
    next: [
      {
        id: 'notHandled',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '148',
    text: 'Je veux vendre des livres pour enfants ou des livres de coloriage',
    group: 'productTypeBooksChildren',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '150',
    text: 'Je souhaite promouvoir des coussins de méditation, des tapis de yoga ou des bols tibétains',
    group: 'productTypeHealthRelaxationMeditation',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: '153',
    text: 'Je veux promouvoir des accessoires (peluches, figurines, tasses) liés à la culture geek',
    group: 'productTypeToysVideoGamesAccessories',
    next: [
      {
        id: 'todo',
        text: 'Non',
        activeBorderColor: '#bf060630',
        activeBackgroundColor: '#ffe0e050'
      },
      {
        id: 'todo',
        text: 'Oui',
        activeBorderColor: '#0cab0340',
        activeBackgroundColor: '#dbedd850'
      }
    ]
  },
  {
    id: 'notHandled',
    group: 'notHandled',
    text: 'Nous ne sommes pas en mesure de vous aider pour le moment',
    btn: {
      text: 'Recommencer',
      onClickId: 'restart'
    }
  },
  {
    id: 'todo',
    group: 'todo',
    text: 'Inputs libre / dropdown à mettre',
    btn: {
      text: 'Revenir à la question précédente',
      onClickId: 'previous'
    }
  },
  {
    id: 'end',
    group: 'end',
    text: "Merci d'avoir répondu à notre questionnaire !",
    btn: {
      text: 'Fermer',
      onClickId: 'close'
    }
  }
]
