import { backendURL } from '../../../constants/urls'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'

// TODO : manage error
export type UpdateTextAdOutputType = {
  adId: string
}

export type UpdateTextAdErrorType = {
  error: string
}

export type UpdateTextAdInput = {
  adId: string
  title1: string
  title2: string
  title3: string
  title4?: string
  title5?: string
  title6?: string
  title7?: string
  title8?: string
  description1: string
  description2: string
  description3?: string
}

export const updateTextAd = async ({
  adId,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  title8,
  description1,
  description2,
  description3
}: UpdateTextAdInput): Promise<
  UpdateTextAdOutputType | UpdateTextAdErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/ads/text/${adId}`,
      data: {
        title1,
        title2,
        title3,
        title4,
        title5,
        title6,
        title7,
        title8,
        description1,
        description2,
        description3
      }
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.adId) {
        return {
          adId: response.data.adId
        }
      }
    }

    return {
      error:
        "Une erreur inconnue est survenue durant la mise à jour de l'annonce"
    }
  } catch (_) {
    return {
      error:
        "Une erreur inconnue est survenue durant la mise à jour de l'annonce"
    }
  }
}

export const checkIfUpdateTextAd = (
  result: any
): result is UpdateTextAdOutputType => {
  return result.adId !== undefined
}
