import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from '../../types/icon'

const YoutubeMedia = ({ height, width }: IconType) => {
  return (
    <svg
      fill={Colors.YETIC_GREY_DARK}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="13 15 13 23 13 23 20 19 13 15" />
      <path d="M26,28H6a2.0021,2.0021,0,0,1-2-2V12a2.0021,2.0021,0,0,1,2-2H26a2.0021,2.0021,0,0,1,2,2V26A2.0021,2.0021,0,0,1,26,28ZM6,12V26H26V12Z" />
      <rect x="6" y="6" width="20" height="2" />
      <rect x="8" y="2" width="16" height="2" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        width="32"
        height="32"
        style={{ fill: 'none' }}
      />
    </svg>
  )
}

export default YoutubeMedia
