import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../../../constants/margin'
import FontSize from '../../../../../../constants/fontSize'
import Colors from '../../../../../../constants/colors'

export const DivContainer = styled.div`
  margin-top: ${Margin.m6};
`

export const CloseIcon = styled.span`
  cursor: pointer;
  margin-left: ${Margin.m2};
  font-size: ${FontSize.YETIC_TEXT};
  color: white;

  &:hover {
    color: ${Colors.YETIC_BLUE_DARK};
  }
`

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

export const loaderStyle: CSSProperties = {
  position: 'absolute',
  top: '32px',
  right: '15px'
}

export const AudienceImportContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const importTextStyle: CSSProperties = {
  marginTop: '4px'
}

export const errorStyle: CSSProperties = {
  textAlign: 'center',
  marginTop: Margin.m5,
  marginBottom: Margin.m7
}

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${Margin.m6};
`
