export type RegisterCompanyType = {
  siren: string
  name: string
  activity: string
  siret: string
  vat: string
  billingAddress: string
  termsOfUse: boolean
  salesTerms: boolean
  charter: boolean
  isDataFromApi?: boolean
  isSelfEditor?: boolean
}

export const emptyRegisterCompany: RegisterCompanyType = {
  siren: '',
  name: '',
  activity: '',
  siret: '',
  vat: '',
  billingAddress: '',
  termsOfUse: false,
  salesTerms: false,
  charter: false
}
