import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'
import Padding from '../../../../constants/padding'

export const modalStyle: CSSProperties = {
  maxWidth: '400px',
  paddingBottom: Padding.p9
}

export const SummaryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ${Margin.m4};
  margin: ${Margin.m8} 0 ${Margin.m8};
`

export const FormContainer = styled.div<{ hasBottomMessage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m6};
  margin-bottom: ${({ hasBottomMessage }) =>
    hasBottomMessage ? Margin.m8 : Margin.m10};
`
