import React from 'react'

import { IconType } from '../../types/icon'

const KpiCustomersPicto = ({ height = '51px', width = '65px' }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 79.737 62"
    >
      <g transform="translate(-488.006 -249.742)">
        <path
          d="M30.407,39.752h-27A4.415,4.415,0,0,1-1,35.34V32.147A11.042,11.042,0,0,1,10.035,21.1h1.439l.144.066a12.616,12.616,0,0,0,10.577,0l.145-.066h1.44A11.042,11.042,0,0,1,34.815,32.147V35.34A4.415,4.415,0,0,1,30.407,39.752ZM16.908,20.1A10.547,10.547,0,0,1,6.363,9.552a10.545,10.545,0,1,1,18,7.461A10.472,10.472,0,0,1,16.908,20.1Z"
          transform="translate(532.927 262.22)"
          fill="#ffce00"
        />
        <path
          d="M43.7,57H5.275A6.284,6.284,0,0,1-1,50.721V46.177A15.715,15.715,0,0,1,14.706,30.46h2.048l.205.094a17.956,17.956,0,0,0,15.053,0l.206-.095h2.049A15.715,15.715,0,0,1,49.974,46.177v4.544A6.284,6.284,0,0,1,43.7,57ZM24.487,29.036A15.012,15.012,0,0,1,9.479,14.018,15.008,15.008,0,1,1,35.1,24.637,14.9,14.9,0,0,1,24.487,29.036Z"
          transform="translate(491.006 252.742)"
          fill="#495a74"
        />
        <path
          d="M43.7,57a6.284,6.284,0,0,0,6.275-6.279V46.177A15.715,15.715,0,0,0,34.268,30.46H32.219l-.206.095a17.956,17.956,0,0,1-15.053,0l-.205-.094H14.706A15.715,15.715,0,0,0-1,46.177v4.544A6.284,6.284,0,0,0,5.275,57H43.7M24.487,29.036A15.012,15.012,0,0,0,39.495,14.018a15.008,15.008,0,1,0-25.62,10.62,14.9,14.9,0,0,0,10.612,4.4M43.7,59H5.275A8.286,8.286,0,0,1-3,50.721V46.177A17.715,17.715,0,0,1,14.706,28.46h.772a17.146,17.146,0,0,1-3.018-2.409,17.027,17.027,0,0,1,0-24.067A17.008,17.008,0,0,1,41.495,14.018a16.912,16.912,0,0,1-4.981,12.033A17.146,17.146,0,0,1,33.5,28.46h.772A17.715,17.715,0,0,1,51.974,46.177v4.544A8.286,8.286,0,0,1,43.7,59Z"
          transform="translate(491.006 252.742)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default KpiCustomersPicto
