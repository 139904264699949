import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import DefaultText from '../../components/DefaultText'
import { AppContext } from '../../contexts/AppContext'
import Button from '../../components/Button'

type ErrorPageProps = {
  message?: string
  action?: {
    text: string
    onClick: () => void
  }
}

const ErrorPage = ({ message, action }: ErrorPageProps) => {
  const { isEditor } = useContext(AppContext)
  const navigate = useNavigate()

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <DefaultText
        size="big"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          flexDirection: 'column',
          gap: '20px',
          textAlign: 'center',
          maxWidth: '800px',
          margin: 'auto'
        }}
      >
        <div>{message ?? 'Une erreur est survenue.'}</div>
        <Button
          onClick={
            action
              ? () => {
                  action.onClick()
                }
              : () => {
                  window.location.reload()
                }
          }
          style={{ width: 'fit-content' }}
        >
          {action ? action.text : 'Recharger la page'}
        </Button>
      </DefaultText>
    </AuthenticatedTemplate>
  )
}

export default ErrorPage
