export enum Status {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PAUSED = 'PAUSED',
  REJECTED = 'REJECTED',
  TO_PAY = 'TO_PAY',
  SCHEDULED = 'SCHEDULED',
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
  ENDED = 'ENDED'
}

export const editorStatus = [
  Status.DRAFT,
  Status.PENDING,
  Status.SCHEDULED,
  Status.IN_PROGRESS,
  Status.PAUSED,
  Status.REJECTED,
  Status.TO_PAY,
  Status.ENDED,
  Status.CANCELLED
]
export const advertiserStatus = [
  Status.PENDING,
  Status.SCHEDULED,
  Status.IN_PROGRESS,
  Status.PAUSED,
  Status.REJECTED,
  Status.TO_PAY,
  Status.ENDED,
  Status.CANCELLED
]
export const selfEditorStatus = [
  Status.DRAFT,
  Status.SCHEDULED,
  Status.IN_PROGRESS,
  Status.PAUSED,
  Status.TO_PAY,
  Status.ENDED,
  Status.CANCELLED
]

export type EditorStatus = typeof editorStatus
export type AdvertiserStatus = typeof advertiserStatus
export type SelfEditorStatus = typeof selfEditorStatus

export const getStatusTranslationByRole = ({
  isEditor,
  isSelfEditor
}: {
  isEditor: boolean
  isSelfEditor: boolean
}): string[] => {
  if (isEditor) {
    return editorStatus.map((status) => StatusTranslation[status])
  }
  if (isSelfEditor) {
    return selfEditorStatus.map((status) => StatusTranslation[status])
  }
  return advertiserStatus.map((status) => StatusTranslation[status])
}

export const StatusTranslation = {
  [Status.DRAFT]: 'Brouillon',
  [Status.PENDING]: 'En attente de validation',
  [Status.SCHEDULED]: 'Planifié',
  [Status.IN_PROGRESS]: 'En cours',
  [Status.PAUSED]: 'En pause',
  [Status.REJECTED]: 'Refusé',
  [Status.TO_PAY]: 'En attente de paiement',
  [Status.CANCELLED]: 'Archivé',
  [Status.ENDED]: 'Terminé'
}

export const getIsEditable = ({
  status,
  isEditor
}: {
  status: Status | undefined
  isEditor: boolean | null
}): boolean => {
  if (!status || isEditor === null) {
    return false
  }

  if (isEditor) {
    return (
      status === Status.PAUSED ||
      status === Status.REJECTED ||
      status === Status.DRAFT
    )
  } else {
    return (
      status === Status.PAUSED ||
      status === Status.REJECTED ||
      status === Status.DRAFT ||
      status === Status.IN_PROGRESS ||
      status === Status.SCHEDULED
    )
  }
}

export const getAddSubitemsStatuses = ({
  isSelfEditor
}: {
  isSelfEditor: boolean
}) => {
  if (isSelfEditor) {
    return [Status.DRAFT, Status.SCHEDULED, Status.IN_PROGRESS, Status.PAUSED]
  } else {
    return [Status.DRAFT]
  }
}

export const getStatusKey = (statusTranslation: string): Status | null => {
  const status = Object.keys(StatusTranslation).find(
    (key) => StatusTranslation[key as Status] === statusTranslation
  )
  if (status) {
    return status as Status
  }
  return null
}

export const checkIfStatus = (value: any): value is Status => {
  return (
    typeof value === 'string' &&
    (Object.values(Status) as string[]).includes(value)
  )
}
