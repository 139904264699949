import React from 'react'

import { AudienceDataType } from '..'
import { PanelType } from '../../../../types/templates/panels'
import Name from '../Name'

type GetNamePanelProps = {
  audienceData: AudienceDataType
  handleChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void
  hasBeenSubmitted: boolean
  error: string
}

const getNamePanel = ({
  audienceData,
  handleChangeName,
  hasBeenSubmitted,
  error
}: GetNamePanelProps) => {
  const namePanel: PanelType = {
    title: 'Nom',
    description: 'Donnez un nom à votre public cible',
    disabled: false,
    sections: [
      <Name
        key="audience-name"
        value={audienceData.name}
        onChange={handleChangeName}
        error={hasBeenSubmitted ? error : ''}
      />
    ],
    isDropdown: false
  }

  return namePanel
}

export default getNamePanel
