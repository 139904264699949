import React, { CSSProperties } from 'react'

import { DivStatus, StatusDot, StatusText } from './styles'
import { StatusTranslation, Status as StatusEnum } from '../../types/status'

const StatusColor = {
  [StatusEnum.DRAFT]: '#FFCE00',
  [StatusEnum.PENDING]: '#FF40B5',
  [StatusEnum.TO_PAY]: '#FF8900',
  [StatusEnum.SCHEDULED]: '#C400FF',
  [StatusEnum.IN_PROGRESS]: '#09DE00',
  [StatusEnum.PAUSED]: '#AFAFAF',
  [StatusEnum.ENDED]: '#0089FF',
  [StatusEnum.REJECTED]: '#000000',
  [StatusEnum.CANCELLED]: '#DB1818'
}

type StatusProps = {
  status: StatusEnum
  handleClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  style?: CSSProperties
}

const StatusWithColor = ({ status, handleClick }: StatusProps) => {
  const color = StatusColor[status]
  return (
    <DivStatus onClick={handleClick}>
      <StatusDot color={color} />
      <StatusText>{StatusTranslation[status]}</StatusText>
    </DivStatus>
  )
}

export default StatusWithColor
