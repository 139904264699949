import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DivContainer, errorMessageStyle, ModalContent } from '../Login/styles'
import Input from '../../../components/Input'
import Margin from '../../../constants/margin'
import Button from '../../../components/Button'
import {
  LoginErrorEnum,
  LoginErrorTranslation
} from '../../../types/error/auth/connection'
import DefaultText from '../../../components/DefaultText'
import handleLogin, {
  checkIfHandleLoginResult
} from '../../../helpers/queries/auth/login'
import { AppContext } from '../../../contexts/AppContext'
import { DivTitle, LoginRegisterLink, titleTextStyle } from '../styles'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'

type LoginProps = {
  onRegisterClick: () => void
}

const Login = ({ onRegisterClick }: LoginProps) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isModalConnectionMobileOpen, setIsModalConnectionMobileOpen] =
    useState(false)

  const { user, setUser } = useContext(AppContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (user != null) {
      navigate('/')
    }
  }, [user])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const isEmpty = () => {
    if (!email || !password) {
      return true
    }
  }

  const handleCloseModalConnectionMobile = () => {
    setIsModalConnectionMobileOpen(false)
  }

  const handleSubmit = async () => {
    handleCloseModalConnectionMobile()
    if (isEmpty()) {
      setErrorMessage(LoginErrorTranslation[LoginErrorEnum.MISSING_PARAMS])
      return
    }
    const value = await handleLogin({ email, password })
    if (checkIfHandleLoginResult(value)) {
      setUser({
        user: {
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email
        },
        isEditor: value.isEditor,
        isSelfEditor: value.isSelfEditor,
        companyId: value.companyId,
        companyName: value.companyName
      })
    } else {
      setErrorMessage(LoginErrorTranslation[value])
    }
  }

  return (
    <DivContainer>
      <DivTitle>
        <DefaultText style={titleTextStyle}>Connexion</DefaultText>
      </DivTitle>
      <DefaultText style={errorMessageStyle}>{errorMessage}</DefaultText>
      <Input
        type="text"
        label={'Adresse e-mail'}
        placeholder="E-mail"
        style={{ marginBottom: Margin.m6 }}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown}
        isRequired={false}
      />
      <Input
        type={'password'}
        label={'Mot de passe'}
        placeholder="Mot de passe"
        style={{ marginBottom: Margin.m2 }}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
        showPassword={showPassword}
        setShowPassword={handleTogglePassword}
        isRequired={false}
      />
      <Button
        width="170px"
        style={{ marginTop: Margin.m8, marginBottom: Margin.m5 }}
        onClick={() => {
          if (window.innerWidth < 1200) {
            setIsModalConnectionMobileOpen(true)
          } else {
            handleSubmit()
          }
        }}
      >
        Se connecter
      </Button>
      <LoginRegisterLink onClick={onRegisterClick}>
        Pas encore de compte ? Inscrivez-vous
      </LoginRegisterLink>
      {isModalConnectionMobileOpen && (
        <ConfirmationModal
          title={`Êtes-vous sûr de vouloir vous connecter depuis cet appareil ?`}
          onClose={handleCloseModalConnectionMobile}
          onClickConfirm={handleCloseModalConnectionMobile}
          onClickReject={() => {
            handleSubmit()
          }}
          textConfirm="Annuler"
          textReject="Continuer"
        >
          <ModalContent>
            <DefaultText>
              {`Le YETIC Ad Manager n'est pas adapté aux appareils mobiles et peut ne pas fonctionner correctement. Pour une expérience complète, nous vous recommandons d'utiliser un ordinateur.`}
            </DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
    </DivContainer>
  )
}

export default Login
