import { backendURL } from '../../../constants/urls'
import {
  MediaPlanReviewType,
  checkIfMediaPlanReview
} from '../../../types/mediaPlan/review'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetMediaPlanReviewOutputType = MediaPlanReviewType | null

export type GetMediaPlanReviewInput = {
  mediaPlanId: string
}

export const getMediaPlanReview = async ({
  mediaPlanId
}: GetMediaPlanReviewInput): Promise<GetMediaPlanReviewOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/media-plans/${mediaPlanId}/review?navigation=1`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfMediaPlanReview(response.data)) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}
