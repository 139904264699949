export enum CustomerCreationError {
  NAME = `Le nom doit faire au moins 2 caractères`,
  EMAIL = `Le format de l'email est invalide`,
  ROLE = `Le poste occupé doit faire au moins 2 caractères`,
  COMPANY_NAME = `Le nom de l'entreprise doit faire au moins 1 caractère`,
  ACTIVITY = `L'activité doit faire au moins 1 caractère`,
  SIRET = `Le numéro de SIRET n'est pas valide. Veuillez vous assurer qu'il contient 14 chiffres.`,
  VAT = `Le format de la TVA intracom est invalide`,
  BILLING_ADDRESS = `Le format de l'addresse est invalide`,
  DEFAULT_ERROR = `Il y a une erreur`
}
