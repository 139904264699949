import React, { ReactNode } from 'react'

import { CardContent, Container, titleStyle } from './styles'
import DefaultText from '../DefaultText'
import Title from '../Title'

type PropsType = {
  unit: string
  value: string
  width: number
  icon?: ReactNode
  height?: string
  color?: string
  title?: string
}

const StatCard = ({
  icon,
  width = 1000,
  height,
  value,
  unit,
  color,
  title
}: PropsType) => {
  return (
    <Container width={width} height={height}>
      {title && <Title style={titleStyle}>{title}</Title>}
      <CardContent>
        <DefaultText style={{ color }} size={'huge'}>
          {value}
          {unit}
        </DefaultText>
        {icon}
      </CardContent>
    </Container>
  )
}

export default StatCard
