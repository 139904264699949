import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'
import Padding from '../../../../../../constants/padding'

export const AdContainer = styled.div`
  background-color: ${Colors.YETIC_WHITE};
  box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  margin: 15px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px ${Padding.p3};
`
export const AdImage = styled.img`
  background-color: #e9ebec;
  width: 50px;
  height: 50px;
  margin: 10px auto 10px auto;
`
export const AdTitle = styled.div`
  font-weight: bold;
  font-size: ${FontSize.YETIC_TITLE_H2};
  color: #363636;
  margin-bottom: 12px;
`

export const AdDescription = styled.div`
  font-size: ${FontSize.YETIC_TEXT_MEDIUM};
  margin-bottom: 20px;
  color: ${Colors.YETIC_GREY_AD};
  font-weight: 500;
`

export const AdButton = styled.div<{ buttonColor?: string }>`
  background-color: ${(props) => props.buttonColor};
  font-size: ${FontSize.YETIC_TITLE_H2};
  color: white;
  font-weight: 600;
  width: 240px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`
