export type CampaignNavigation = {
  company: {
    name: string
    id: string
  }
  mediaPlan: {
    name: string
    id: string
  }
}

export const checkCampaignNavigation = (
  value: any
): value is CampaignNavigation => {
  return (
    value &&
    value.company &&
    typeof value.company.id === 'string' &&
    typeof value.company.name === 'string' &&
    value.mediaPlan &&
    typeof value.mediaPlan.id === 'string' &&
    typeof value.mediaPlan.name === 'string'
  )
}
