import React from 'react'

import {
  ImageAdCreationFormDataType,
  ImageAdFiles,
  ImageAdFilesUrls
} from '../types'
import InputFile from '../../../components/InputFile'
import { googleLogoRatio } from '../../../constants/image'
import { ImageAdFilesErrors } from '../../../helpers/imageAd/formErrors'
import { GoogleFormImageAdError } from '../../../types/pages/adImageCreation'
import { PanelType } from '../../../types/templates/panels'
import { ImagesSelectionContainer } from '../styles'

export type GetLogoSelectionPanelInput = {
  formData: ImageAdCreationFormDataType
  imageUrls: ImageAdFilesUrls
  imageErrors: ImageAdFilesErrors
  handleImageChange: (
    attribute: keyof ImageAdFiles,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleImageDelete: (attribute: keyof ImageAdFiles) => void
  hasBeenSubmitted: boolean
}

export const getLogoSelectionPanel = ({
  formData,
  imageUrls,
  imageErrors,
  handleImageChange,
  handleImageDelete,
  hasBeenSubmitted
}: GetLogoSelectionPanelInput): PanelType => {
  return {
    title: 'Sélection du logo',
    disabled: false,
    isDropdown: false,
    description: 'Ajoutez votre logo (obligatoire).',
    sections: [
      {
        title: 'Logo*',
        description: `Choisissez votre logo au format ${googleLogoRatio}:1.`,
        content: (
          <ImagesSelectionContainer>
            <InputFile
              uniqueKey="logoInput"
              handleChange={(e) => {
                handleImageChange('logo', e)
              }}
              handleDelete={() => {
                handleImageDelete('logo')
              }}
              selectedFile={formData.logo}
              fileUrl={imageUrls.logo}
              error={
                hasBeenSubmitted
                  ? imageErrors.logo
                  : imageErrors.logo !== GoogleFormImageAdError.IMAGE_MISSING
                  ? imageErrors.logo
                  : ''
              }
            />
          </ImagesSelectionContainer>
        )
      }
    ]
  }
}
