import {
  BarChartData,
  Point as BarChartPoint
} from '../../../../../components/Chart/BarChart'
import {
  HeatmapData,
  Point as HeatmapPoint
} from '../../../../../components/Chart/Heatmap'
import { ElementItem } from '../../../../../components/Table'
import { deepClone } from '../../../../../helpers/clone'
import {
  AgeRangeEnum,
  getAgeRangeEnumList,
  getAgeRangeFromEnum
} from '../../../../../types/reporting/age'
import { GenderEnum } from '../../../../../types/reporting/gender'
import {
  GetCampaignAudienceAndLocationReportingOutputType,
  GetCampaignAudiencesReportingOutputType
} from '../../../../../types/reporting/campaign/output'

type FormatAudiencesDataOutput = {
  genderAgeHeatmap: HeatmapData<HeatmapPoint>
  clickAgeBarChart: BarChartData<BarChartPoint>[]
  impressionAgeBarChart: BarChartData<BarChartPoint>[]
  clickGenderBarChart: BarChartData<BarChartPoint>[]
  impressionGenderBarChart: BarChartData<BarChartPoint>[]
  genderAgeTableData: ElementItem[]
  locationTableData: ElementItem[]
}

type ClickAndImpression = {
  clicks?: number
  impressions?: number
  ctr?: number
}

type KpiByAgeRange = Record<string, ClickAndImpression>

export const formatAudiencesData = (
  data: GetCampaignAudienceAndLocationReportingOutputType
): FormatAudiencesDataOutput => {
  const emptyAgeRanges: KpiByAgeRange = getAgeRangeEnumList().reduce(
    (acc, kpi) => ({
      ...acc,
      [kpi]: { clicks: undefined, impressions: undefined, ctr: undefined }
    }),
    {}
  )

  const valuesByGenderAndAge: Record<string, KpiByAgeRange> = {
    male: deepClone(emptyAgeRanges),
    female: deepClone(emptyAgeRanges),
    undetermined: deepClone(emptyAgeRanges)
  }

  const kpis = Array<keyof GetCampaignAudiencesReportingOutputType>(
    'clicks',
    'impressions',
    'ctr'
  )

  for (let i = 0; i < kpis.length; i++) {
    const kpiName = kpis[i]

    data.audiences[kpiName].forEach((kpi) => {
      if ('gender' in kpi && 'age' in kpi) {
        const currentValue = valuesByGenderAndAge[kpi.gender][kpi.age][kpiName]
        valuesByGenderAndAge[kpi.gender][kpi.age][kpiName] =
          (currentValue ?? 0) + kpi.value
      }
    })
  }

  type LocationAccumulator = Record<
    string,
    { totalClicks: number; totalImpressions: number }
  >

  const valuesByLocation = data.locations.reduce(
    (acc: LocationAccumulator, { location, totalClicks, totalImpressions }) => {
      if (!acc[location]) {
        acc[location] = { totalClicks: 0, totalImpressions: 0 }
      }
      acc[location].totalClicks += totalClicks
      acc[location].totalImpressions += totalImpressions
      return acc
    },
    {}
  )

  const locationTableData = Object.entries(valuesByLocation).map(
    ([location, { totalClicks, totalImpressions }], index) => ({
      id: `location-${index}`,
      name: location,
      clicks: totalClicks,
      impressions: totalImpressions
    })
  )

  const genderAgeHeatmap: HeatmapData<HeatmapPoint> = [
    {
      name: 'Clics',
      categories: Object.values(AgeRangeEnum)
        .filter((el) => el !== AgeRangeEnum.default)
        .map((ageRange) => ({
          x: getAgeRangeFromEnum(ageRange),
          values: [
            {
              y: 'Femmes',
              value:
                valuesByGenderAndAge[GenderEnum.female][ageRange].clicks !==
                undefined
                  ? (valuesByGenderAndAge[GenderEnum.female][ageRange]
                      .clicks as number)
                  : -1
            },
            {
              y: 'Hommes',
              value:
                valuesByGenderAndAge[GenderEnum.male][ageRange].clicks !==
                undefined
                  ? (valuesByGenderAndAge[GenderEnum.male][ageRange]
                      .clicks as number)
                  : -1
            }
          ]
        }))
    },
    {
      name: 'Impressions',
      categories: Object.values(AgeRangeEnum).map((ageRange) => ({
        x: getAgeRangeFromEnum(ageRange),
        values: [
          {
            y: 'Femmes',
            value:
              valuesByGenderAndAge[GenderEnum.female][ageRange].impressions !==
              undefined
                ? (valuesByGenderAndAge[GenderEnum.female][ageRange]
                    .impressions as number)
                : -1
          },
          {
            y: 'Hommes',
            value:
              valuesByGenderAndAge[GenderEnum.male][ageRange].impressions !==
              undefined
                ? (valuesByGenderAndAge[GenderEnum.male][ageRange]
                    .impressions as number)
                : -1
          }
        ]
      }))
    }
  ]

  const clickAgeBarChart: BarChartData<BarChartPoint>[] = [
    {
      name: 'Clics',
      color: 'blue',
      values: Object.values(AgeRangeEnum)
        .filter((el) => el !== AgeRangeEnum.default)
        .map((ageRange) => ({
          category: getAgeRangeFromEnum(ageRange),
          value:
            (valuesByGenderAndAge[GenderEnum.female][ageRange].clicks ?? 0) +
            (valuesByGenderAndAge[GenderEnum.male][ageRange].clicks ?? 0)
        }))
    }
  ]

  const impressionAgeBarChart: BarChartData<BarChartPoint>[] = [
    {
      name: 'Impressions',
      color: 'deeppink',
      values: Object.values(AgeRangeEnum)
        .filter((el) => el !== AgeRangeEnum.default)
        .map((ageRange) => ({
          category: getAgeRangeFromEnum(ageRange),
          value:
            (valuesByGenderAndAge[GenderEnum.female][ageRange].impressions ??
              0) +
            (valuesByGenderAndAge[GenderEnum.male][ageRange].impressions ?? 0)
        }))
    }
  ]

  const clickGenderBarChart: BarChartData<BarChartPoint>[] = [
    {
      name: 'Clics',
      color: 'blue',
      values: [
        {
          category: 'Hommes',
          value: Object.values(valuesByGenderAndAge[GenderEnum.male]).reduce(
            (acc, kpi) => acc + (kpi.clicks ?? 0),
            0
          )
        },
        {
          category: 'Femmes',
          value: Object.values(valuesByGenderAndAge[GenderEnum.female]).reduce(
            (acc, kpi) => acc + (kpi.clicks ?? 0),
            0
          )
        }
      ]
    }
  ]

  const impressionGenderBarChart: BarChartData<BarChartPoint>[] = [
    {
      name: 'Impressions',
      color: 'deeppink',
      values: [
        {
          category: 'Hommes',
          value: Object.values(valuesByGenderAndAge[GenderEnum.male]).reduce(
            (acc, kpi) => acc + (kpi.impressions ?? 0),
            0
          )
        },
        {
          category: 'Femmes',
          value: Object.values(valuesByGenderAndAge[GenderEnum.female]).reduce(
            (acc, kpi) => acc + (kpi.impressions ?? 0),
            0
          )
        }
      ]
    }
  ]

  const genderAgeTableData: ElementItem[] = Object.values(AgeRangeEnum)
    .map((ageRange) => {
      const clicksByGender = {
        male: valuesByGenderAndAge[GenderEnum.male][ageRange].clicks,
        female: valuesByGenderAndAge[GenderEnum.female][ageRange].clicks
      }
      const impressionsByGender = {
        male: valuesByGenderAndAge[GenderEnum.male][ageRange].impressions,
        female: valuesByGenderAndAge[GenderEnum.female][ageRange].impressions
      }
      const ctrByGender = {
        male: valuesByGenderAndAge[GenderEnum.male][ageRange].ctr,
        female: valuesByGenderAndAge[GenderEnum.female][ageRange].ctr
      }

      return [
        {
          id: `${ageRange}-male`,
          gender: 'Hommes',
          age: `${getAgeRangeFromEnum(ageRange)}${
            ageRange !== AgeRangeEnum.default ? ' ans' : ''
          }`,
          clicks: clicksByGender.male,
          impressions: impressionsByGender.male,
          ctr: ctrByGender.male
        },
        {
          id: `${ageRange}-female`,
          gender: 'Femmes',
          age: `${getAgeRangeFromEnum(ageRange)}${
            ageRange !== AgeRangeEnum.default ? ' ans' : ''
          }`,
          clicks: clicksByGender.female,
          impressions: impressionsByGender.female,
          ctr: ctrByGender.female
        }
      ]
    })
    .flat()

  return {
    genderAgeHeatmap,
    clickAgeBarChart,
    impressionAgeBarChart,
    clickGenderBarChart,
    impressionGenderBarChart,
    genderAgeTableData,
    locationTableData
  }
}
