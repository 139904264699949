import React from 'react'
import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import DefaultText from '../../DefaultText'
import FontSize from '../../../constants/fontSize'

interface RadioLabelProps {
  label: string
  description?: string
}

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Margin.m3} 0;
`

const RadioLabel = ({ label, description }: RadioLabelProps) => {
  return (
    <DivContainer>
      <DefaultText style={{ fontSize: FontSize.YETIC_TEXT_SMALL }}>
        {label}
      </DefaultText>
      {description && (
        <DefaultText
          style={{ opacity: '0.5', fontSize: FontSize.YETIC_TEXT_VERY_SMALL }}
        >
          {description}
        </DefaultText>
      )}
    </DivContainer>
  )
}

export default RadioLabel
