import React from 'react'

import { PanelType } from '../../../../../types/templates/panels'
import {
  LinkedinCTA,
  linkedinCTAOptions
} from '../../../../../types/ad/linkedin/linkedinCTA'
import { InputError } from '../../../../../components/Input/Styles'
import Dropdown, { Option } from '../../../../../components/Dropdown'

type GetCTAPanelProps = {
  ctaValue?: { id: number; label: LinkedinCTA }
  handleCTAChange: (option: Option | null) => void
  hasBeenSubmitted: boolean
  error: string
}

const getCTAPanel = ({
  ctaValue,
  handleCTAChange,
  hasBeenSubmitted,
  error
}: GetCTAPanelProps): PanelType => {
  const ctaPanel: PanelType = {
    title: 'Intitulé du bouton',
    disabled: false,
    isDropdown: false,
    description: `Renseignez l'intitulé du bouton.`,
    sections: [
      {
        content: (
          <>
            <Dropdown
              options={linkedinCTAOptions}
              onChange={handleCTAChange}
              placeholder="Choisissez l'intitulé du bouton"
              label={`Intitulé du bouton`}
              value={ctaValue}
            />
            {hasBeenSubmitted && error && <InputError>{error}</InputError>}
          </>
        )
      }
    ]
  }

  return ctaPanel
}

export default getCTAPanel
