import React, { useContext } from 'react'

import EditorGoogleTextAdCreation from './editor'
import SelfEditorGoogleTextAdCreation from './selfEditor'
import { AppContext } from '../../../../contexts/AppContext'
import ErrorPage from '../../../ErrorPage'
import Widget from '../../../parts/Widget'

const TextAdCreation = () => {
  const { isEditor, isSelfEditor } = useContext(AppContext)

  return (
    <Widget>
      {isEditor ? (
        <EditorGoogleTextAdCreation />
      ) : isSelfEditor ? (
        <SelfEditorGoogleTextAdCreation />
      ) : (
        <ErrorPage />
      )}
    </Widget>
  )
}

export default TextAdCreation
