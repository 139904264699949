import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

const BAR_HEIGHT = 12
const THUMB_HEIGHT = 30
const THUMB_WIDTH = 30

export const SliderWrapper = styled.div`
  width: 90%;
  height: ${BAR_HEIGHT}px;
  display: flex;
  position: relative;
  padding: 20px 0px;
  margin: ${Margin.m3} 0 ${Margin.m6} ${Margin.m7};
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const Bar = styled.div`
  display: flex;
  height: 100%;
  border-radius: 10px;
`

export const BarSegment = styled.div<{ width: string; color: string }>`
  width: ${({ width }) => width};
  background-color: ${({ color }) => color};
  padding-top: ${Padding.p1};
  position: relative;
  border-radius: 10px;
`

export const RangeInput = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
`

export const Thumb = styled.div`
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: col-resize;

  &:before {
    content: '';
    background-color: inherit;
    position: absolute;
    width: ${THUMB_WIDTH}px;
    height: ${THUMB_HEIGHT}px;
    border-radius: 50%;
    background-color: ${Colors.NEW_YETIC_DARK};
    z-index: 1;
    margin: -${(THUMB_HEIGHT - BAR_HEIGHT) / 2}px -${THUMB_WIDTH / 2}px;
    cursor: col-resize;
  }
`

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${BAR_HEIGHT * 5}px;
  position: absolute;
  z-index: 0;
  top: -${BAR_HEIGHT * 1.8}px;
  left: 50%;
  transform: translateX(-50%);
`
