import axios from 'axios'

import { backendURL } from '../../../constants/urls'
import {
  GetWidgetPageErrorEnum,
  GetWidgetPageErrorEnumTranslation,
  checkIfGetWidgetPageErrorCodeValid
} from '../../../types/error/widget/getWidgetPage'
import { getErrorCode } from '../../getErrorCode'
import { checkIfWidgetPageType } from '../../../types/widget'

export type GetWidgetRootPageInputType = {
  location: string
}

export const getWidgetRootPage = async ({
  location
}: GetWidgetRootPageInputType) => {
  const url = `${backendURL}/widget/root?url=${encodeURI(location)}`

  try {
    const res = await axios.get(url, { withCredentials: true })

    if (res.status === 200 && checkIfWidgetPageType(res.data)) {
      return res.data
    }

    return {
      error: GetWidgetPageErrorEnumTranslation[GetWidgetPageErrorEnum.UNKNOWN]
    }
  } catch (error) {
    const errorCode = getErrorCode(error) ?? null
    if (errorCode && checkIfGetWidgetPageErrorCodeValid(errorCode)) {
      return {
        error:
          GetWidgetPageErrorEnumTranslation[GetWidgetPageErrorEnum[errorCode]]
      }
    }
    return {
      error: GetWidgetPageErrorEnumTranslation[GetWidgetPageErrorEnum.UNKNOWN]
    }
  }
}

export default getWidgetRootPage
