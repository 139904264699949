enum Colors {
  YETIC_BLUE_DARK = '#142A4C',
  YETIC_BLUE_DARKER = '#021628',
  YETIC_BLUE = '#4B5B74',
  YETIC_BLUE_LIGHT = '#5F81B2',
  YETIC_BLUE_LIGHTER = '#A2BEE0',
  YETIC_BLUE_EXTRA_LIGHT = '#E6F0F9',
  YETIC_GREEN_DARK = '#00B2B2',
  YETIC_GREEN_LIGHT = '#59CECE',
  YETIC_GREEN_LIGHTER = '#9CE2E2',
  YETIC_YELLOW_DARK = '#FFCE00',
  YETIC_YELLOW_LIGHT = '#FFE060',
  YETIC_YELLOW_LIGHTER = '#FFF3BF',
  YETIC_BLUE_BACK = '#F2F9F9',
  YETIC_RED = 'red',
  YOUTUBE_RED = '#C5221F',
  YETIC_WHITE = 'white',
  YETIC_GREY_DARK = '#dde0e5',
  NEW_YETIC_GREY = '#F3F4F6',
  NEW_YETIC_GREY_LIGHT = '#F9FAFB',
  NEW_YETIC_DARK = '#192A4A',
  NEW_YETIC_BOX_INFO_TIP = '#495A74',
  NEW_YETIC_GREY_BACKGROUND = '#F1F3F4',
  YETIC_GREY_AD = '#545454',
  YETIC_BACKGROUND_BUTTON = '#E7E9ED',
  YETIC_PAGINATION_DEFAULT = '#C4C9D2',
  YETIC_PURPLE_TIPS = '#FAF2FF',
  YETIC_PURPLE_INFO_ICON = '#9300FF',
  YETIC_BLUE_INFO_ICON = '#00aecd',
  NEW_YETIC_GREEN_LIGHT = '#D9F3F3',
  NEW_YETIC_GREEN = ' #00B2B2',
  NEW_YETIC_RED = '#EE0022',
  NEW_YETIC_RED_LIGHT = '#FFDADA',
  NEW_YETIC_BLUE_DARK = '#142A4C',
  NEW_YETIC_BLUE_DARK_DISABLED = '#848FA1',
  NEW_YETIC_BLUE_LIGHT = '#97a1b0',
  NEW_YETIC_YELLOW = '#FFCE00',
  NEW_YETIC_WARNING = '#FC8600',
  NEW_YETIC_WARNING_LIGHT = '#f2f1eb',
  NEW_YETIC_CO2 = '#50514F',
  YETIC_PURPLE = '#765883',
  YETIC_CO2_BLUE = '#43AADD'
}

export default Colors
