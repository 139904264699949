import { backendURL } from '../../../constants/urls'
import { isStringArray } from '../../nativeTypes'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : manage error
export type GetCampaignKeywordsOutputType = {
  keywords: string[]
}

export type GetCampaignKeywordsInput = {
  campaignId: string
}

// TODO response.data includes ads, which will be {format: string, id: string, name: string}[]
export const getCampaignKeywords = async ({
  campaignId
}: GetCampaignKeywordsInput): Promise<GetCampaignKeywordsOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/campaigns/${campaignId}/keywords`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (isStringArray(response.data.keywords)) {
        return {
          keywords: response.data.keywords
        }
      }
    }
    return null
  } catch (_) {
    return null
  }
}
