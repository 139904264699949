import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { getAdById } from '../../helpers/queries/ad/getAdById'

const AdReview = () => {
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { adId } = useParams()
  const location = useLocation()

  const platform = new URLSearchParams(location.search).get('platform')

  useEffect(() => {
    if (adId && platform) {
      ;(async function () {
        const result = await getAdById({
          adId
        })
        if (result != null) {
          navigate(`/ad/${adId}/${platform}/${result.ad.format}/review`)
        }
        setHasFetchError(true)
      })()
    } else {
      setHasFetchError(true)
    }
  }, [adId])

  if (hasFetchError) {
    return <ErrorPage />
  }

  return <LoadingPage />
}

export default AdReview
