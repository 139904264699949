import { backendURL } from '../../../constants/urls'
import { ViewEnum } from '../../../types/axios/common'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type Ads = {
  id: string
  name: string
}

export type GetAdsOutputType = Ads[] | null

export type GetAdsInput = {
  view?: ViewEnum
}

export const getAds = async ({
  view
}: GetAdsInput): Promise<GetAdsOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/ads${view ? `?view=${view}` : ''}`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      return response.data.ads
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfAds = (value: any): value is Ads[] => {
  return (
    Array.isArray(value) &&
    value.every(
      (ad: any) =>
        ad &&
        ad.id &&
        typeof ad.id === 'string' &&
        ad.name &&
        typeof ad.name === 'string'
    )
  )
}
