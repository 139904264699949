export type NamedResource = {
  id: string
  name: string
}

export const checkIfNamedResource = (value: any): value is NamedResource => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string'
  )
}

export const checkIfNamedResourceArray = (
  value: any
): value is NamedResource[] => {
  return (
    value &&
    Array.isArray(value) &&
    value.every((item) => checkIfNamedResource(item))
  )
}

export const checkIfEventTargetIsSVGElement = (
  eventTarget: EventTarget | null
): eventTarget is SVGElement => {
  return !!eventTarget && (eventTarget as SVGElement)?.ownerSVGElement != null
}

export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum AlignEnum {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}
