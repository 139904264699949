import { AudienceDataType } from '..'
import { FormMediaPlanAudienceError } from '../../../../types/pages/mediaPlanCreation'
import {
  checkBusinessSegments,
  checkJobFunctions,
  checkJobTitles,
  checkLocations,
  checkMaxAge,
  checkMinAge,
  checkName,
  checkValidAges
} from './checkers'

export type AudienceCreationErrors = {
  name: string
  minAge: string
  maxAge: string
  locations: string
  businessSegments: string
  jobFunctions: string
  jobTitles: string
  errorFromApi: string
}

type GetAudienceCreationErrorsParams = {
  audienceData: AudienceDataType
  errorFromApi: string
  isGoogleSelected: boolean
  isLinkedinSelected: boolean
}

export const getAudienceCreationErrors = ({
  audienceData,
  errorFromApi,
  isGoogleSelected,
  isLinkedinSelected
}: GetAudienceCreationErrorsParams): AudienceCreationErrors => {
  return {
    name: checkName(audienceData.name) ? '' : FormMediaPlanAudienceError.NAME,
    minAge:
      checkMinAge(audienceData.minAge) || !isGoogleSelected
        ? ''
        : FormMediaPlanAudienceError.MIN_AGE,
    maxAge: isGoogleSelected
      ? checkMaxAge(audienceData.maxAge)
        ? checkValidAges({
            minAge: audienceData.minAge,
            maxAge: audienceData.maxAge
          })
          ? ''
          : FormMediaPlanAudienceError.VALID_AGES
        : FormMediaPlanAudienceError.MAX_AGE
      : '',
    locations: checkLocations(audienceData.selectedLocations)
      ? ''
      : FormMediaPlanAudienceError.LOCATIONS,
    businessSegments:
      checkBusinessSegments(audienceData.selectedBusinessSegments) ||
      !isLinkedinSelected
        ? ''
        : FormMediaPlanAudienceError.BUSINESS_SEGMENTS,
    jobFunctions:
      checkJobFunctions(
        audienceData.selectedJobFunctions,
        audienceData.selectedJobTitles
      ) || !isLinkedinSelected
        ? ''
        : FormMediaPlanAudienceError.JOB_FUNCTIONS,
    jobTitles:
      checkJobTitles(
        audienceData.selectedJobTitles,
        audienceData.selectedJobFunctions
      ) || !isLinkedinSelected
        ? ''
        : FormMediaPlanAudienceError.JOB_TITLES,
    errorFromApi: errorFromApi ?? ''
  }
}
