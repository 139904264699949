import { backendURL } from '../../../constants/urls'
import {
  SendToValidateMediaPlanErrorEnum,
  SendToValidateMediaPlanErrorTranslation,
  checkIfSendToValidateMediaPlanErrorCodeValid
} from '../../../types/error/mediaPlan/sendToValidateMediaPlan'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'

export type SendToValidateMediaPlanOutputType = {
  mediaPlanId: string
}

export type SendToValidateMediaPlanErrorType = {
  error: string
}

export type SendToValidateMediaPlanInput = {
  mediaPlanId: string
  mediaPlanName: string
  receiverEmail: string
}

export const sendToValidateMediaPlan = async ({
  mediaPlanId,
  mediaPlanName,
  receiverEmail
}: SendToValidateMediaPlanInput): Promise<
  SendToValidateMediaPlanOutputType | SendToValidateMediaPlanErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/media-plans/${mediaPlanId}/send-to-validate`,
      data: {
        mediaPlanName,
        receiverEmail
      }
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.mediaPlanId) {
        return {
          mediaPlanId: response.data.mediaPlanId
        }
      }
    } else {
      const errorCode =
        (response as any)?.error.data?.code ?? (response as any)?.error.code
      if (
        errorCode &&
        checkIfSendToValidateMediaPlanErrorCodeValid(errorCode)
      ) {
        return { error: SendToValidateMediaPlanErrorTranslation[errorCode] }
      }
    }
    return {
      error:
        SendToValidateMediaPlanErrorTranslation[
          SendToValidateMediaPlanErrorEnum.UNKNOWN
        ]
    }
  } catch (_) {
    return {
      error:
        SendToValidateMediaPlanErrorTranslation[
          SendToValidateMediaPlanErrorEnum.UNKNOWN
        ]
    }
  }
}

export const checkIfSendToValidateMediaPlan = (
  result: any
): result is SendToValidateMediaPlanOutputType => {
  return result.mediaPlanId !== undefined
}
