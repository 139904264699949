import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'
import FontWeight from '../../../../../../constants/fontWeight'
import Margin from '../../../../../../constants/margin'
import Padding from '../../../../../../constants/padding'

export const DivWindow = styled.div`
  width: 380px;
  padding: ${Padding.p3};
  border: solid 1px ${Colors.NEW_YETIC_GREY};
  font-family: 'system-ui';
  background: ${Colors.YETIC_WHITE};
`
export const DivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const DivAccountImage = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: ${Colors.NEW_YETIC_GREY};
`

export const DivOtherContent = styled.div`
  background: ${Colors.NEW_YETIC_GREY};
  height: 50px;
  margin: ${Margin.m3} ${Margin.m0};
`

export const DivAdContainer = styled.div`
  border-radius: 10px;
  border: solid 1px ${Colors.NEW_YETIC_GREY};
  background-color: ${Colors.YETIC_WHITE};
`

export const DivAdHeader = styled.div`
  display: flex;
  padding: ${Padding.p3};
`

export const DivHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-right: ${Padding.p3};
`

export const Company = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
`

export const Logo = styled.img<{ small?: boolean }>`
  width: ${(props) => (props.small ? '30px' : '40px')};
  height: ${(props) => (props.small ? '30px' : '40px')};
  margin-right: ${Margin.m3};
`

export const BusinessName = styled.div`
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: ${FontWeight.YETIC_BOLD};
`

export const DivSentence = styled.div`
  margin-top: ${Margin.m1};
  position: relative;
  background: ${Colors.NEW_YETIC_GREY};
  width: 70px;
  height: 7px;
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    background: ${Colors.NEW_YETIC_GREY};
    width: 70px;
    height: 7px;
  }
`

export const Button = styled.div<{ border?: boolean }>`
  color: #0a66c2;
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: ${FontWeight.YETIC_BOLD};
  border: ${(props) => (props.border ? 'solid 1px #0a66c2' : 'none')};
  border-radius: ${(props) => (props.border ? '20px' : '0')};
  padding: ${(props) =>
    props.border ? `${Padding.p1} ${Padding.p3}` : `${Padding.p0}`};
  display: inline-block;
  text-align: center;
  min-height: 15px;
  min-width: 25px;
`

export const DivContent = styled.div<{ isExpanded: boolean }>`
  position: relative;
  padding: ${Padding.p3};
  margin: ${Margin.m0} ${Margin.m0} ${Margin.m3} ${Margin.m0};
  font-size: 12px;
  line-height: 16px;
  height: ${(props) => (props.isExpanded ? 'auto' : '40px')};
  overflow: hidden;
  box-sizing: border-box;
`

export const SeeMore = styled.div`
  background: ${Colors.YETIC_WHITE};
  color: ${Colors.YETIC_GREY_AD};
  font-size: 12px;
  font-weight: ${FontWeight.YETIC_MEDIUM};
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding-right: ${Padding.p5};
  text-align: right;
  width: 25%;
  cursor: pointer;
`

export const AdImage = styled.img`
  width: 100%;
  min-height: 100px;
`

export const DivSubImage = styled.div`
  background: #edf3f9;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${Padding.p3};
`

export const DivDescription = styled.div`
  max-width: 70%;
`

export const Headline = styled.div`
  font-weight: ${FontWeight.YETIC_BOLD};
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  margin-bottom: ${Margin.m2};
  width: 100%;
`

export const Url = styled.div`
  color: ${Colors.YETIC_GREY_AD};
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${Padding.p5};
`

export const DivPostButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

export const PostButton = styled.div`
  display: flex;
  align-items: center;
  padding: ${Padding.p4} ${Padding.p3} ${Padding.p3};
  justify-self: center;
  align-self: center;
`

export const DivImage = styled.div`
  width: 30px;
  height: 30px;
  background: ${Colors.NEW_YETIC_GREY};
`

export const PostButtonLabel = styled.div`
  font-weight: ${FontWeight.YETIC_MEDIUM};
  font-size: ${FontSize.YETIC_INPUT_TEXT};
  color: ${Colors.YETIC_GREY_AD};
  margin-left: ${Margin.m2};
`
export const linkedinLikeStyle: CSSProperties = {
  marginRight: `${Margin.m1}`
}

export const DivCommentBar = styled.div`
  display: flex;
  align-items: center;
  padding: ${Padding.p3};
`

export const CommentBar = styled.div`
  border: solid 1px ${Colors.YETIC_GREY_AD};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-radius: 20px;
  padding: ${Padding.p3};
`

export const Placeholder = styled.div`
  color: ${Colors.YETIC_GREY_AD};
  font-size: ${FontSize.YETIC_INPUT_TEXT};
`

export const CommentBarIcons = styled.div`
  display: flex;
`

export const pictureIconStyle: CSSProperties = {
  width: '15px',
  height: '15px',
  color: `${Colors.YETIC_GREY_AD}`,
  marginLeft: `${Margin.m2}`
}
