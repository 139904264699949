import React from 'react'

import {
  Description,
  DivContent,
  ImageAd,
  Logo,
  Title,
  DivButtons,
  DivImage,
  CloseButton,
  DivContainer
} from './styles'
import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import Button from '../../../../Button'

type SmartphonePreview3Props = {
  adImageSquare: string
  logoImage: string
  title: string
  description: string
  buttonColor: string
}

const SmartphonePreview3 = ({
  adImageSquare,
  logoImage,
  title,
  description,
  buttonColor
}: SmartphonePreview3Props) => {
  return (
    <DivContainer>
      <DisplayAdInfoTemplate bgBlur={adImageSquare}>
        <DivImage>
          <ImageAd src={adImageSquare} alt="image carrée" loading="lazy" />
          <Logo src={logoImage} alt="logo" loading="lazy" />
        </DivImage>
        <DivContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <DivButtons>
            <CloseButton secondaryColor={buttonColor}>Fermer</CloseButton>
            <Button
              type="primary"
              width="115px"
              style={{ backgroundColor: buttonColor }}
            >
              Ouvrir
            </Button>
          </DivButtons>
        </DivContent>
      </DisplayAdInfoTemplate>
    </DivContainer>
  )
}

export default SmartphonePreview3
