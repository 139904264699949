import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  getAdById,
  checkIfGetAdOutputAdWithoutDetails,
  checkIfGetAdOutputGoogleImageAdDetails
} from '../../../../helpers/queries/ad/getAdById'
import { PlatformAPIEnum } from '../../../../types/platform'
import LoadingPage from '../../../LoadingPage'
import { FlowEnum } from '../../../../types/flow'
import { AppContext } from '../../../../contexts/AppContext'
import ErrorPage from '../../../ErrorPage'
import { Status, getIsEditable } from '../../../../types/status'
import { GoogleImageAdDetails } from '../../../../types/ad/google/imageAd'
import getImageById from '../../../../helpers/queries/ad/getImageById'
import EditorImageAdDetailsPage from './editor'
import SelfEditorImageAdDetailsPage from './selfEditor'

const ImageAdDetailsPage = () => {
  const [adDetails, setAdDetails] = useState<GoogleImageAdDetails | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [openModalImage, setOpenModalImage] = useState<string | null>(null)

  const [isReasonModalOpan, setIsReasonModalOpen] = useState(false)

  const navigate = useNavigate()

  const { adId } = useParams()

  const { isEditor, isSelfEditor } = useContext(AppContext)

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const result = await getAdById({
          adId
        })

        if (result && checkIfGetAdOutputAdWithoutDetails(result)) {
          if (result.ad.status === Status.DRAFT) {
            navigate(
              `/ad/${adId}/review?flow=${FlowEnum.creation}&platform=${PlatformAPIEnum.GOOGLE}`
            )
          }

          const resultDetails = await getAdById({
            adId,
            adType: result.ad.format,
            platform: PlatformAPIEnum.GOOGLE
          })
          if (resultDetails) {
            if (checkIfGetAdOutputGoogleImageAdDetails(resultDetails)) {
              const newAdDetails = {
                id: resultDetails.ad.id,
                format: resultDetails.ad.format,
                name: resultDetails.ad.name,
                startDate: resultDetails.ad.startDate,
                url: resultDetails.ad.url,
                headline1: resultDetails.ad.headline1,
                headline2: resultDetails.ad.headline2,
                headline3: resultDetails.ad.headline3,
                longHeadline: resultDetails.ad.longHeadline,
                description1: resultDetails.ad.description1,
                description2: resultDetails.ad.description2,
                description3: resultDetails.ad.description3,
                businessName: resultDetails.ad.businessName,
                logo: resultDetails.ad.logo,
                squareMarketingImage1: resultDetails.ad.squareMarketingImage1,
                squareMarketingImage2: resultDetails.ad.squareMarketingImage2,
                squareMarketingImage3: resultDetails.ad.squareMarketingImage3,
                marketingImage1: resultDetails.ad.marketingImage1,
                marketingImage2: resultDetails.ad.marketingImage2,
                marketingImage3: resultDetails.ad.marketingImage3,
                status: resultDetails.ad.status,
                feedback: resultDetails.ad.feedback,
                navigation: result.navigation
              }
              setAdDetails(newAdDetails)
            }
          }
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }, [adId])

  if (hasFetchError || (!isEditor && !isSelfEditor)) {
    return <ErrorPage />
  }

  if (!adId || !adDetails) {
    return <LoadingPage />
  }

  const handleShowReason =
    adDetails.status === Status.REJECTED
      ? (event: React.MouseEvent) => {
          event.stopPropagation()
          if (adDetails.feedback) {
            setIsReasonModalOpen(true)
          }
        }
      : undefined

  const handleEdit = getIsEditable({ status: adDetails.status, isEditor })
    ? () => {
        navigate(`/ad/image/${adId}/edit?flow=${FlowEnum.view}`)
      }
    : undefined

  const handleImageClick = async (imageId: string) => {
    const result = await getImageById(imageId)
    if (result !== null) {
      setOpenModalImage(result)
    }
  }

  const handleCloseImageModal = () => {
    setOpenModalImage(null)
  }

  const ImageAdDetailsPageContent = isEditor
    ? EditorImageAdDetailsPage
    : SelfEditorImageAdDetailsPage

  return (
    <ImageAdDetailsPageContent
      adDetails={adDetails}
      isReasonModalOpen={isReasonModalOpan}
      setIsReasonModalOpen={setIsReasonModalOpen}
      handleShowReason={handleShowReason}
      handleImageClick={(imageId) => {
        handleImageClick(imageId)
      }}
      openModalImage={openModalImage}
      handleCloseImageModal={handleCloseImageModal}
      handleEdit={handleEdit}
      navigate={navigate}
    />
  )
}

export default ImageAdDetailsPage
