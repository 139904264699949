import React from 'react'

import {
  BoxType,
  FilledArrow,
  IconContainer,
  RowContent,
  RowItem,
  actionButtonSize
} from './styles'
import ActionsButton, { ActionItem } from '../ActionsButton'

type TableListRowContentProps = {
  item: { id: string; type?: string; row: (string | JSX.Element)[] }
  widths?: string[]
  actions?: ActionItem[]
  handleClick?: (id: string) => void
  noRowOpen?: string
  rowType?: string
}

const TableListRowContent = ({
  item,
  widths,
  actions,
  handleClick,
  noRowOpen,
  rowType
}: TableListRowContentProps) => {
  return (
    <>
      {item.type ? <BoxType type={item.type}>{item.type}</BoxType> : null}
      <RowContent hoverable={noRowOpen === undefined}>
        {item.row.map((v, index) => {
          return (
            <RowItem
              key={index}
              index={index}
              width={widths && widths[index] ? widths[index] : '31%'}
              rowType={rowType}
              onClick={() => {
                if (item.id && handleClick) {
                  handleClick(item.id)
                }
              }}
            >
              {index === 0 && noRowOpen !== undefined && (
                <FilledArrow selected={noRowOpen === item.id} />
              )}
              {v}
            </RowItem>
          )
        })}
        {actions && actions.length > 0 && item.id ? (
          <IconContainer>
            <ActionsButton actions={actions} size={actionButtonSize} />
          </IconContainer>
        ) : null}
      </RowContent>
    </>
  )
}

export default TableListRowContent
