import {
  LineChartData,
  Point as LineChartPoint,
  Point
} from '../../../../../components/Chart/LineChart/LineChartDrawer'
import { ElementItem } from '../../../../../components/Table'
import { formatDateToFrench } from '../../../../../helpers/date'
import { AnalyticsCaptionWordingEnum } from '../../../../../types/analytics/captionsWording'
import { LineChartAggregatorEnum } from '../../../../../types/lineChart'
import { GetCampaignOverallReportingOutputType } from '../../../../../types/reporting/campaign/output'

type FormatClickImpressionCostDataOutput = {
  kpi: LineChartData<LineChartPoint>
  tableData: ElementItem[]
  globalCTR?: number
}

export const formatClickImpressionCostData = (
  data: GetCampaignOverallReportingOutputType
): FormatClickImpressionCostDataOutput => {
  const totalClicks = data.kpi.clicks.reduce(
    (acc, click) => acc + click.value,
    0
  )
  const totalImpressions = data.kpi.impressions.reduce(
    (acc, impression) => acc + impression.value,
    0
  )
  const globalCTR =
    totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0

  const kpi: LineChartData<Point> = [
    {
      name: 'Clics',
      color: 'blue',
      values: data.kpi.clicks.map((click) => ({
        time: new Date(click.day),
        value: click.value
      })),
      caption: 'nombre de clics',
      isAugmentationGood: true
    },
    {
      name: 'Impressions',
      color: 'deeppink',
      values: data.kpi.impressions.map((impression) => ({
        time: new Date(impression.day),
        value: impression.value
      })),
      caption: AnalyticsCaptionWordingEnum.IMPRESSIONS,
      isAugmentationGood: true
    },
    {
      name: 'CTR',
      color: 'green',
      unit: '%',
      values: data.kpi.ctr.map((ctr) => ({
        time: new Date(ctr.day),
        value: ctr.value
      })),
      caption: AnalyticsCaptionWordingEnum.CTR,
      isAugmentationGood: true,
      aggregator: LineChartAggregatorEnum.AVERAGE
    },
    {
      name: 'Coût',
      color: 'orange',
      unit: '€',
      values: data.kpi.cost.map((cost) => ({
        time: new Date(cost.day),
        value: cost.value
      })),
      caption: 'coût',
      isAugmentationGood: false
    }
  ]

  const days = new Set([
    ...data.kpi.clicks.map((click) => click.day),
    ...data.kpi.impressions.map((impression) => impression.day),
    ...data.kpi.ctr.map((ctr) => ctr.day),
    ...data.kpi.cost.map((cost) => cost.day)
  ])

  const tableData = Array.from(days).map((day) => {
    const clicks = data.kpi.clicks.find((click) => click.day === day)
    const impressions = data.kpi.impressions.find(
      (impression) => impression.day === day
    )
    const ctr = data.kpi.ctr.find((ctr) => ctr.day === day)
    const cost = data.kpi.cost.find((cost) => cost.day === day)

    return {
      id: day,
      day: formatDateToFrench(new Date(day), true),
      clicks: clicks !== undefined ? clicks.value : '-',
      impressions: impressions !== undefined ? impressions.value : '-',
      ctr: ctr !== undefined ? ctr.value : '-',
      cost: cost !== undefined ? cost.value : '-'
    }
  })

  return { kpi, tableData, globalCTR: Math.round((globalCTR ?? 0) * 100) / 100 }
}
