import React, { useState } from 'react'

import { SurveyContentType } from './types'
import DefaultText from '../DefaultText'
import {
  SurveyButton,
  SurveyChoiceButton,
  SurveyChoiceButtonContent,
  SurveyChoiceButtonsWrapper,
  SurveyContent,
  SurveyNavigation,
  SurveyWrapper,
  surveyChoiceDescriptionStyle,
  surveyChoiceTitleStyle,
  surveyQuestionStyle
} from './styles'
import Button from '../Button'
import Navigation from '../Navigation'
import { ArrowBack } from '../Icon'

type SurveyProps = {
  content: SurveyContentType
  currentQuestionId: string
  updateCurrentQuestionId: (questionId: string) => void
  onAnswerClick: (answerId: string) => void
  onClose: () => void
}

const Survey = ({
  content,
  currentQuestionId,
  updateCurrentQuestionId,
  onAnswerClick,
  onClose
}: SurveyProps) => {
  const [history, setHistory] = useState<string[]>([content[0].id])
  const pageContent = content.find(
    (question) => question.id === currentQuestionId
  )
  const numberOfAnswers = pageContent?.next?.length ?? 0
  const choiceButtonFlex = numberOfAnswers ? 1 / numberOfAnswers : 1

  const renderChoiceText = (
    text: string | { title: string; description: string }
  ) => {
    if (typeof text === 'string') {
      return text
    }

    return (
      <SurveyChoiceButtonContent>
        <DefaultText style={surveyChoiceTitleStyle}>{text.title}</DefaultText>
        <DefaultText style={surveyChoiceDescriptionStyle}>
          {text.description}
        </DefaultText>
      </SurveyChoiceButtonContent>
    )
  }

  const updatedOnAnswerClick = (answerId: string) => {
    setTimeout(() => {
      onAnswerClick(answerId)
      setHistory([...history, answerId])
    }, 100)
  }

  const handleOnPreviousClick = () => {
    const previousQuestionId = history[history.length - 2]
    updateCurrentQuestionId(previousQuestionId)
    setHistory(history.slice(0, -1))
  }

  const onBtnClickActions: Record<string, () => void> = {
    restart: () => {
      updateCurrentQuestionId(content[0].id)
      setHistory([content[0].id])
    },
    close: onClose,
    previous: handleOnPreviousClick
  }

  return (
    <SurveyWrapper>
      <SurveyNavigation>
        <Button
          type="textButton"
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          onClick={handleOnPreviousClick}
        >
          <ArrowBack width="14px" />
          Revenir à la question précédente
        </Button>
      </SurveyNavigation>
      {pageContent && (
        <SurveyContent hasAnswers={numberOfAnswers > 0}>
          <DefaultText style={surveyQuestionStyle}>
            {pageContent.text}
          </DefaultText>
          <SurveyChoiceButtonsWrapper>
            {pageContent.next?.map((answer) => (
              <SurveyChoiceButton
                onClick={() => {
                  updatedOnAnswerClick(answer.id)
                }}
                activeBorderColor={answer.activeBorderColor}
                activeBackgroundColor={answer.activeBackgroundColor}
                flex={choiceButtonFlex}
                key={`${Math.random()}-${answer.id}`}
              >
                {renderChoiceText(answer.text)}
              </SurveyChoiceButton>
            ))}
          </SurveyChoiceButtonsWrapper>
          {pageContent.btn && (
            <SurveyButton
              onClick={onBtnClickActions[pageContent.btn.onClickId]}
            >
              {pageContent.btn.text}
            </SurveyButton>
          )}
        </SurveyContent>
      )}
    </SurveyWrapper>
  )
}

export default Survey
