import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import { GetCampaignKeywordsReportingOutputType } from '../../../../types/reporting/campaign/output'

type GetCampaignKeywordsReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignKeywordsReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignKeywordsReportingInputType): Promise<GetCampaignKeywordsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/keywords${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignKeywordsReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignKeywordsReporting = (
  value: any
): value is GetCampaignKeywordsReportingOutputType => {
  return (
    value &&
    value.keywords &&
    Array.isArray(value.keywords) &&
    value.keywords.every((value: any) => {
      return (
        value &&
        value.keyword &&
        typeof value.keyword === 'string' &&
        value.clicks !== undefined &&
        typeof value.clicks === 'number' &&
        value.impressions !== undefined &&
        typeof value.impressions === 'number'
      )
    }) &&
    value.searchTerms &&
    Array.isArray(value.searchTerms) &&
    value.searchTerms.every((value: any) => {
      return (
        value &&
        value.searchTerm &&
        typeof value.searchTerm === 'string' &&
        value.clicks !== undefined &&
        typeof value.clicks === 'number' &&
        value.impressions !== undefined &&
        typeof value.impressions === 'number'
      )
    })
  )
}
