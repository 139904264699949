import React, { useState } from 'react'

import { AudiencesTabContainer, Tip } from './styles'
import { formatAudiencesData } from './helpers/formatData'
import { TableContainer, chartGap } from '../../styles'
import { GetCampaignAudienceAndLocationReportingOutputType } from '../../../../types/reporting/campaign/output'
import Heatmap from '../../../../components/Chart/Heatmap'
import BarChart from '../../../../components/Chart/BarChart'
import Table from '../../../../components/Table'
import { AlignEnum, SortEnum } from '../../../../types/common'

type AudiencesTabProps = {
  reportingData: GetCampaignAudienceAndLocationReportingOutputType
  contentWidth: number
}

const AudiencesTab = ({ reportingData, contentWidth }: AudiencesTabProps) => {
  const {
    genderAgeHeatmap,
    clickAgeBarChart,
    impressionAgeBarChart,
    clickGenderBarChart,
    impressionGenderBarChart,
    genderAgeTableData,
    locationTableData
  } = formatAudiencesData(reportingData)

  const [tablePage, setTablePage] = useState(1)

  const tableColumns = [
    {
      name: 'gender',
      label: 'Genre',
      isSortable: true,
      align: AlignEnum.LEFT,
      width: '26%'
    },
    {
      name: 'age',
      label: 'Age',
      isSortable: true,
      align: AlignEnum.LEFT,
      width: '26%'
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '16%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '16%'
    },
    {
      name: 'ctr',
      label: 'CTR',
      isSortable: true,
      width: '16%'
    }
  ]

  const tableLocationColumns = [
    {
      name: 'name',
      label: 'Noms',
      isSortable: true,
      align: AlignEnum.LEFT,
      width: '60%'
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '20%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '20%'
    }
  ]

  const defaultTableSort = {
    name: 'age',
    order: SortEnum.ASC
  }

  const defaultLocationTableSort = {
    name: 'clicks',
    order: SortEnum.DESC
  }

  const chartWidth =
    screen.width > 1300 ? (contentWidth - chartGap * 2) / 2 : contentWidth

  return (
    <AudiencesTabContainer>
      <Heatmap
        data={genderAgeHeatmap}
        displayedIntensityIndex={0}
        title="Clics et impressions par âge et genre"
        width={chartWidth}
      />
      {/* TODO: add tip content */}
      <Tip width={chartWidth} style={{ backgroundColor: 'white' }}></Tip>
      <BarChart
        data={clickAgeBarChart}
        title="Clics par âge"
        width={chartWidth}
      />
      <BarChart
        data={impressionAgeBarChart}
        title="Impressions par âge"
        width={chartWidth}
      />
      <BarChart
        data={clickGenderBarChart}
        title="Clics par genre"
        horizontal
        width={chartWidth}
      />
      <BarChart
        data={impressionGenderBarChart}
        title="Impressions par genre"
        horizontal
        width={chartWidth}
      />
      <TableContainer>
        <Table
          title="Performances des annonces par localisation"
          columns={tableLocationColumns}
          elements={locationTableData}
          sort={defaultLocationTableSort}
          pagination={{
            totalItems: locationTableData.length,
            itemsByPage: 10,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
        />
      </TableContainer>
      <TableContainer>
        <Table
          title="Performances des annonces par âge et genre"
          columns={tableColumns}
          elements={genderAgeTableData}
          sort={defaultTableSort}
          download
        />
      </TableContainer>
    </AudiencesTabContainer>
  )
}

export default AudiencesTab
