import React, { useEffect, useRef, useState } from 'react'

import { Dots } from '../Icon'
import {
  Action,
  ActionsButtonContainer,
  ActionsButtonIconContainer,
  ActionsListContainer
} from './styles'
import Colors from '../../constants/colors'

export type ActionItem = {
  label: string
  handleAction: () => void
}

type ActionsButtonProps = {
  actions?: ActionItem[]
  size: number
}

const ActionsButton = ({ actions, size }: ActionsButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ActionsButtonContainer ref={containerRef}>
      {isOpen && (
        <ActionsListContainer
          style={{
            right: containerRef.current
              ? containerRef.current.offsetWidth - size
              : 0
          }}
        >
          {actions?.map((action, index) => {
            return (
              <Action
                key={index}
                onClick={() => {
                  action.handleAction()
                  setIsOpen(false)
                }}
              >
                {action.label}
              </Action>
            )
          })}
        </ActionsListContainer>
      )}
      <ActionsButtonIconContainer onClick={toggleOpen} size={size}>
        <Dots width="15px" height="15px" color={Colors.YETIC_BLUE} />
      </ActionsButtonIconContainer>
    </ActionsButtonContainer>
  )
}

export default ActionsButton

export const checkIfActionItemArray = (value: any): value is ActionItem[] => {
  return (
    value &&
    Array.isArray(value) &&
    value.every(
      (item) =>
        item.label &&
        typeof item.label === 'string' &&
        item.handleAction &&
        typeof item.handleAction === 'function'
    )
  )
}
