import React, { useContext } from 'react'

import EditorCampaignCreation from './editor'
import SelfEditorCampaignCreation from './selfeditor'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import Widget from '../parts/Widget'

const CampaignCreation = () => {
  const { isEditor, isSelfEditor } = useContext(AppContext)

  return (
    <Widget>
      {isEditor ? (
        <EditorCampaignCreation />
      ) : isSelfEditor ? (
        <SelfEditorCampaignCreation />
      ) : (
        <ErrorPage />
      )}
    </Widget>
  )
}

export default CampaignCreation
