import axios from 'axios'
import { AxiosResponseType } from '../../../types/axios/common'

export type AxiosGetType = {
  url: string
}

type AxiosGetOutputSuccessType = {
  type: AxiosResponseType.SUCCESS
  data: any
}

type AxiosGetOutputErrorType = {
  type: AxiosResponseType.ERROR
  error: string
}

export const checkIsAxiosGetOutputSuccess = (
  value: any
): value is AxiosGetOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === AxiosResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export type AxiosGetOutputType =
  | AxiosGetOutputSuccessType
  | AxiosGetOutputErrorType

export const axiosGet = async ({
  url
}: AxiosGetType): Promise<AxiosGetOutputType> => {
  try {
    const response = await axios.get(url, {
      withCredentials: true
    })

    // console.log(response);
    if (
      checkIsAxiosGetOutputSuccess({
        type: AxiosResponseType.SUCCESS,
        data: response.data
      })
    ) {
      return {
        type: AxiosResponseType.SUCCESS,
        data: response.data
      }
    }
    return {
      type: AxiosResponseType.ERROR,
      error: 'Une erreur est survenue' // use an enum for the errors
    }
  } catch (error: any) {
    return {
      type: AxiosResponseType.ERROR,
      error: error.response ?? error.request ?? error
    }
  }
}
