import styled from '@emotion/styled'
import Colors from '../../../../constants/colors'

export const Search = styled.div``

export const SearchComponent = styled.div`
  display: flex;
`
export const SearchList = styled.div`
  background-color: #142a4c;
  font: 12px 'Montserrat';
  height: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  display: flex;
  width: 140px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
`
export const SearchListSelected = styled.span`
  color: #fff;
`
export const SearchArrow = styled.span`
  color: #fff;
  margin-left: auto;
  font-size: 12px;
`
export const SearchListCategories = styled.ul`
  box-shadow: 3px 3px 5px #142a4c40;
  border-radius: 10px 0px;
  background: #fff;
  position: absolute;
  width: 140px;
  left: 0px;
  top: 40px;
  list-style: none;
  padding: 0px;
  margin: 0px;
  z-index: 110;
  box-sizing: border-box;
  display: ${(props: { show: boolean }) => (props.show ? 'block' : 'none')};
  overflow: hidden;
`

export const SearchListCategory = styled.li`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;

  &:hover {
    background: #ffce00;
  }
`

export const InputSearch = styled.input`
  border: 0;
  border-radius: 10px;
  height: 40px;
  font: normal 12px 'Montserrat';
  letter-spacing: 0px;
  color: #142a4c;
  padding: 0px 10px;
  box-sizing: border-box;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 300px;
`

export const ListItemsContainer = styled.div<{ show: boolean }>`
  width: 299px;
  position: absolute;
  top: 50px;
  background-color: #fff;
  border: 1px solid ${Colors.NEW_YETIC_GREY};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  margin-left: 140px;
  z-index: 2;
  padding: 0;
  display: ${(props) => (props.show ? 'block' : 'none')};
`

export const ListItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const Item = styled.li`
  padding: 10px 5px;
  cursor: pointer;
  font-size: 14px;
  margin: 5px 0;
  &:hover {
    background-color: ${Colors.NEW_YETIC_GREY};
  }
`
