export enum FormatEnum {
  TEXT = 'Texte',
  IMAGE = 'Image',
  VIDEO = 'Vidéo'
}

export enum FormatAPIEnum {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video'
}

export const getFormatAPIEnum = (value: FormatEnum): FormatAPIEnum => {
  switch (value) {
    case FormatEnum.TEXT:
      return FormatAPIEnum.TEXT
    case FormatEnum.IMAGE:
      return FormatAPIEnum.IMAGE
    case FormatEnum.VIDEO:
      return FormatAPIEnum.VIDEO
    default:
      return FormatAPIEnum.TEXT
  }
}

export const getFormatEnum = (value: FormatAPIEnum): FormatEnum => {
  switch (value) {
    case FormatAPIEnum.TEXT:
      return FormatEnum.TEXT
    case FormatAPIEnum.IMAGE:
      return FormatEnum.IMAGE
    case FormatAPIEnum.VIDEO:
      return FormatEnum.VIDEO
    default:
      return FormatEnum.TEXT
  }
}

export const checkIfFormatEnum = (value: any): value is FormatEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(FormatEnum) as string[]).includes(value)
  )
}

export const checkIfFormatApiEnum = (value: any): value is FormatAPIEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(FormatAPIEnum) as string[]).includes(value)
  )
}
