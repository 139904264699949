export enum ConnectToPlatformError {
  EMAIL = "L'adresse email n'est pas valide"
}

export enum CreatePlatformConnectionEnum {
  AUTH_FAILED = 'AUTH_FAILED',
  UNKNOWN = 'UNKNOWN',
  MISSING_CUSTOMER_ID = 'MISSING_CUSTOMER_ID',
  GOOGLE_CONNECTION_FAILED = 'GOOGLE_CONNECTION_FAILED',
  LINKEDIN_CONNECTION_FAILED = 'LINKEDIN_CONNECTION_FAILED',
  CONNECTION_ALREADY_EXISTS = 'CONNECTION_ALREADY_EXISTS',
  CUSTOMERS_NOT_FOUND = 'CUSTOMERS_NOT_FOUND',
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  MISSING_EMAIL = 'MISSING_EMAIL',
  EMAIL_NOT_SENT = 'EMAIL_NOT_SENT',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND'
}

export const CreatePlatformConnectionTranslation = {
  [CreatePlatformConnectionEnum.AUTH_FAILED]: "L'authentification a échoué",
  [CreatePlatformConnectionEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [CreatePlatformConnectionEnum.MISSING_CUSTOMER_ID]:
    "L'id du client est manquant",
  [CreatePlatformConnectionEnum.GOOGLE_CONNECTION_FAILED]:
    'La connexion Google a échoué',
  [CreatePlatformConnectionEnum.LINKEDIN_CONNECTION_FAILED]:
    'La connexion Linkedin a échoué',
  [CreatePlatformConnectionEnum.CONNECTION_ALREADY_EXISTS]:
    'La connexion a déjà été demandée',
  [CreatePlatformConnectionEnum.CUSTOMERS_NOT_FOUND]:
    "Vos clients n'ont pas été trouvés",
  [CreatePlatformConnectionEnum.CUSTOMER_NOT_FOUND]:
    "Le client n'a pas été trouvé",
  [CreatePlatformConnectionEnum.MISSING_EMAIL]: "L'email est manquant",
  [CreatePlatformConnectionEnum.EMAIL_NOT_SENT]:
    "L'email n'a pas pu être envoyé",
  [CreatePlatformConnectionEnum.COMPANY_NOT_FOUND]:
    "Votre agence n'a pas été trouvée dans notre base de données"
}

export const checkIfCreatePlatformConnectionCodeValid = (
  errorCode: string
): errorCode is CreatePlatformConnectionEnum => {
  return Object.keys(CreatePlatformConnectionEnum).includes(errorCode)
}
