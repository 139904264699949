import * as d3 from 'd3'

import {
  formatNumber,
  roundToTwoSignificantDigits
} from '../../../../helpers/rounds'
import { checkIfEventTargetIsSVGElement } from '../../../../types/common'
import { DrawChartInput } from './types'

export const drawVerticalChart = ({
  chartId,
  chartRef,
  data,
  width
}: DrawChartInput) => {
  // Clear existing SVG
  d3.select(chartRef.current).selectAll('svg').remove()

  // Set the dimensions and margins of the chart
  const margin = {
    top: 50,
    right: Math.max(20, width / 10),
    bottom: 30,
    left: Math.max(60, width / 7)
  }
  const widthWithoutMargin = width - margin.left - margin.right
  const height = width / 2 - margin.top - margin.bottom
  const spaceBetweenBars = width / 4 / data.values.length

  // append the svg object to the body of the page
  const svg = d3
    .select(chartRef.current)
    .append('svg')
    .attr('width', widthWithoutMargin + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const xAxisValues = data.values.map((d) => d.category)

  // Add X axis
  const x = d3.scaleBand().domain(xAxisValues).range([0, widthWithoutMargin])

  svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .attr('class', 'x-axis')
    .call(d3.axisBottom(x).tickSize(0).tickPadding(14).tickSizeOuter(0))

  // Add Y axis
  const maxYValue = roundToTwoSignificantDigits(
    Math.max(...data.values.map((v) => v.value))
  )
  const y = d3.scaleLinear().domain([0, maxYValue]).range([height, 0])

  svg
    .append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).tickSize(0).tickPadding(10).tickArguments([5]))

  const yTicks = y.ticks(5)

  // Add horizontal lines for the y-axis ticks
  svg
    .selectAll('.y-axis-line')
    .data(yTicks)
    .enter()
    .append('line')
    .attr('class', 'y-axis-line')
    .attr('x1', 0)
    .attr('x2', widthWithoutMargin)
    .attr('y1', (d) => y(d))
    .attr('y2', (d) => y(d))
    .attr('stroke', '#e8e4e3')

  // Style the axises ticks text
  svg.selectAll('.y-axis').selectAll('text').style('font-size', '14px')
  svg.selectAll('.x-axis').selectAll('text').style('font-size', '14px')

  // Get the tooltip
  const tooltip = d3.select(`#${chartId}-tooltip`)
  const tooltipTitle = tooltip.select(`#${chartId}-tooltip-title`)

  // Add the bars with height animation
  svg
    .selectAll('.bars')
    .data(data.values)
    .join('rect')
    .attr('x', (d) => (x(d.category) ?? 0) + spaceBetweenBars / 2)
    .on('mousemove', (e: MouseEvent, d) => {
      const hoveredElement = e.currentTarget

      if (checkIfEventTargetIsSVGElement(hoveredElement)) {
        tooltip
          .style('opacity', 1)
          .style('left', `${e.pageX}px`)
          .style('top', `${e.pageY}px`)
        tooltipTitle.text(d.category)
        tooltip
          .select(`.${chartId}-tooltip-${data.name}-value`)
          .text(
            data.unit
              ? `${formatNumber(d.value)} ${data.unit}`
              : formatNumber(d.value)
          )
      }
    })
    .on('mouseleave', () => {
      tooltip.transition().duration(100).style('opacity', 0)
    })
    .attr('width', x.bandwidth() - spaceBetweenBars)
    .attr('y', height)
    .attr('height', 0)
    .attr('fill', data.color)
    .transition()
    .duration(600)
    .attr('y', (d) => y(d.value))
    .attr('height', (d) => height - y(d.value))
}
