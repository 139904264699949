import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../../../../constants/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
  background-color: ${Colors.NEW_YETIC_GREY};
  box-sizing: border-box;
  border-radius: 4px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${Colors.YETIC_WHITE};
  padding: 10px 20px;
`

export const ContentImage = styled.img`
  height: 150px;
  object-fit: contain;
  object-position: left;
`

export const titleStyle: CSSProperties = {
  textAlign: 'center',
  fontSize: '18px',
  marginTop: '5px',
  marginBottom: '0',
  color: Colors.NEW_YETIC_BLUE_DARK
}

export const ParagraphContainer = styled.span`
  margin-top: 5px;
`

export const paragraphStyle: CSSProperties = {
  fontSize: '16px',
  lineHeight: '22px',
  color: Colors.NEW_YETIC_BLUE_DARK
}
