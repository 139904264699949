import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'
import { backendURL } from '../../../constants/urls'
import {
  RestartCampaignErrorEnum,
  RestartCampaignErrorTranslation,
  checkIfRestartCampaignErrorCodeValid
} from '../../../types/error/campaign/pauseAndRestart'

export type RestartCampaignOutputType = {
  campaignId: string
}

export type RestartCampaignErrorType = {
  error: string
}

export type RestartCampaignInput = {
  campaignId: string
}

export const restartCampaign = async ({
  campaignId
}: RestartCampaignInput): Promise<
  RestartCampaignOutputType | RestartCampaignErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/campaigns/${campaignId}/restart`,
      data: {}
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (checkIfRestartCampaign(response.data)) {
        return response.data
      }
    }

    const errorCode =
      (response as any)?.error.data?.code ?? (response as any)?.error.code
    if (errorCode && checkIfRestartCampaignErrorCodeValid(errorCode)) {
      return { error: RestartCampaignErrorTranslation[errorCode] }
    }

    return {
      error: RestartCampaignErrorTranslation[RestartCampaignErrorEnum.UNKNOWN]
    }
  } catch (_) {
    return {
      error: RestartCampaignErrorTranslation[RestartCampaignErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfRestartCampaign = (
  result: any
): result is RestartCampaignOutputType => {
  return result && result.campaignId && typeof result.campaignId === 'string'
}
