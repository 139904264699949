export const makeSum = (values: number[]) => {
  return values.reduce((sum, val) => sum + val, 0)
}

export const makeAverage = (values: number[]) => {
  if (values.length > 0) {
    return makeSum(values) / values.length
  } else {
    return 0
  }
}

export const calculPercentageEvolution = (val1: number, val2: number) => {
  const res = ((val2 - val1) / val1) * 100
  return Math.round(res * 100) / 100
}
