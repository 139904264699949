import styled from '@emotion/styled'
import FontSize from '../../../../../../constants/fontSize'
import Colors from '../../../../../../constants/colors'
import FontWeight from '../../../../../../constants/fontWeight'

export const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 60%;
`

export const ListLi = styled.li`
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`

export const Title = styled.span`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    background-color: ${Colors.NEW_YETIC_BOX_INFO_TIP};
    border-radius: 100%;
    display: block;
    margin-right: 5px;
    width: 5px;
    height: 5px;
  }
`

export const Data = styled.span`
  font-weight: ${FontWeight.YETIC_BOLD};
  font-size: ${FontSize.YETIC_TEXT};
  color: ${Colors.NEW_YETIC_DARK};
`
