import React from 'react'

import Card from '../../Card'
import Colors from '../../../constants/colors'
import LabelledItemsList from '../../LabelledItemsList'
import { rowLabelledItemsListStyle } from './styles'
import { TableListSubItem } from '..'
import PlatformLogo from '../../PlatformLogo'

type SubItemCardProps = {
  index: number
  item: TableListSubItem
}

const SubItemCard = ({ index, item }: SubItemCardProps) => {
  return (
    <Card
      key={index}
      title={item.title}
      titleSize="medium"
      width={'100%'}
      headerColor={Colors.YETIC_BLUE}
      titleStyle={{ color: Colors.YETIC_WHITE }}
      seeMore={item.onSeeMore}
      titleIcon={
        item.platform && (
          <PlatformLogo
            platform={item.platform}
            color={Colors.YETIC_WHITE}
            width="20px"
            height="20px"
          />
        )
      }
    >
      <LabelledItemsList style={rowLabelledItemsListStyle} items={item.data} />
      {item.preview}
    </Card>
  )
}

export default SubItemCard
