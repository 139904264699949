import { BidStrategyEnum } from '../../../../types/bidStrategy'
import { FormatEnum } from '../../../../types/format'

export const bidStrategiesByFormat: Record<
  string,
  { id: number; label: BidStrategyEnum }[]
> = {
  [FormatEnum.TEXT]: [
    { id: 0, label: BidStrategyEnum.CPC }
    // { id: 1, label: BidStrategyEnum.CALL_CONVERSION }
  ],
  [FormatEnum.IMAGE]: [{ id: 0, label: BidStrategyEnum.CPM }]
}
