import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'
import Colors from '../../../../constants/colors'

export const DivList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: ${Margin.m7};
  margin-bottom: ${Margin.m7};
`

export const buttonConfirmStyle: CSSProperties = {
  backgroundColor: Colors.YETIC_YELLOW_DARK,
  color: Colors.YETIC_BLUE_DARK
}

export const messageStyle: CSSProperties = {
  textAlign: 'center',
  marginTop: 0,
  marginBottom: Margin.m8
}
