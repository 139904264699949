import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Container,
  NamesDiv,
  SiretContainer,
  siretButtonStyle,
  ModalContent,
  RadioButtonsContainer,
  customerTipStyle,
  CancelModalContent
} from './styles'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import Input from '../../components/Input'
import handleCreateCustomer, {
  CreateCustomerInputType
} from '../../helpers/queries/customer/create'
import DefaultText from '../../components/DefaultText'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import { PanelType } from '../../types/templates/panels'
import { AppContext } from '../../contexts/AppContext'
import { SectionType } from '../../types/templates/section'
import { CustomerCreationError } from '../../types/pages/customerCreation'
import { GetCompanyFromSiret } from '../../helpers/queries/company/getCompanyFromSiret'
import { ErrorOutput } from '../../types/error/generic'
import Button from '../../components/Button'
import ConfirmationModal from '../../components/Modal/ConfirmationModal'
import AnimatedLoader from '../../components/AnimatedLoader'
import { RadioButton } from '../../components/RadioButton'
import { TipsInfos } from '../../components/Tips'

type CustomerCreationErrors = {
  firstName: string
  lastName: string
  email: string
  role: string
  siret: string
  companyName: string
  activity: string
  vat: string
  billingAddress: string
  errorFromApi: string
}

type SuccessCreationInformations = {
  userId: string
  companyId: string
}

const CreateCustomer = () => {
  const [isModalSaveOpen, setIsModalSaveOpen] = useState(false)
  const [creationInformations, setCreationInformations] =
    useState<SuccessCreationInformations | null>(null)

  const [errorFromApi, setErrorFromApi] = useState('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)

  const [hasCustomerResponsible, setHasCustomerResponsible] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [siret, setSiret] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [activity, setActivity] = useState('')
  const [vat, setVat] = useState('')
  const [billingAddress, setAddress] = useState('')

  const [siretDisabledCompletion, setSiretDisabledCompletion] =
    useState<boolean>(true)
  const [siretHasBeenSubmitted, setSiretHasBeenSubmitted] =
    useState<boolean>(false)
  const [isGetCompanyInfoLoading, setIsGetCompanyInfoLoading] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const { isEditor, user } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (isGetCompanyInfoLoading) {
      if (checkSiret()) {
        ;(async function () {
          const company = await GetCompanyFromSiret({ siret })
          if (company) {
            setCompanyName(company.name)
            setActivity(company.activity)
            setVat(company.vat)
            setAddress(company.address)
            if (!siretDisabledCompletion) {
              setSiretDisabledCompletion(true)
            }
          } else {
            setCompanyName('')
            setActivity('')
            setVat('')
            setSiretDisabledCompletion(false)
          }

          if (!siretHasBeenSubmitted) {
            setSiretHasBeenSubmitted(true)
          }
          setIsGetCompanyInfoLoading(false)
        })()
      } else {
        if (!siretHasBeenSubmitted) {
          setSiretHasBeenSubmitted(true)
        }
        setIsGetCompanyInfoLoading(false)
      }
    }
  }, [isGetCompanyInfoLoading])

  const handleCloseModal = () => {
    setIsCancelModalOpen(false)
    setIsModalSaveOpen(false)
  }

  const handleConfirmAction = () => {
    navigate('/')
  }

  const handleCreateMediaPlan = () => {
    creationInformations?.companyId &&
      navigate(`/company/${creationInformations.companyId}/media-plan/create`)
  }

  const handleSeeCustomer = () => {
    creationInformations?.companyId &&
      navigate(`/customer/${creationInformations.companyId}/details`)
  }

  const handleHasCustomerResponsibleChange = (checked: boolean) => {
    setHasCustomerResponsible(checked)
  }

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  const handleSiretChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSiret(event.target.value)
  }
  const handleActivityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setActivity(event.target.value)
  }
  const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value)
  }
  const handleVatChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVat(event.target.value)
  }
  const handleBillingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value)
  }
  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value)
  }

  const checkFirstName = () => !hasCustomerResponsible || firstName.length >= 2
  const checkLastName = () => !hasCustomerResponsible || lastName.length >= 2
  const checkEmail = () => {
    const regex = /^([+a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    return !hasCustomerResponsible || regex.test(email)
  }
  const checkRole = () => !hasCustomerResponsible || role.length >= 2
  const checkBillingAddress = () => billingAddress.length > 1
  const checkCompanyName = () => companyName.length > 1
  const checkActivity = () => activity.length > 1
  const checkSiret = () => siret.length === 14
  const checkVat = () => vat.length === 13 && vat.startsWith('FR')

  const errors: CustomerCreationErrors = {
    firstName: checkFirstName() ? '' : CustomerCreationError.NAME,
    lastName: checkLastName() ? '' : CustomerCreationError.NAME,
    email: checkEmail() ? '' : CustomerCreationError.EMAIL,
    role: checkRole() ? '' : CustomerCreationError.ROLE,
    companyName: checkCompanyName() ? '' : CustomerCreationError.COMPANY_NAME,
    activity: checkActivity() ? '' : CustomerCreationError.ACTIVITY,
    siret: checkSiret() ? '' : CustomerCreationError.SIRET,
    vat: checkVat() ? '' : CustomerCreationError.VAT,
    billingAddress: checkBillingAddress()
      ? ''
      : CustomerCreationError.BILLING_ADDRESS,
    errorFromApi: errorFromApi ?? ''
  }

  const handleSubmitCustomer = async () => {
    if (Object.values(errors).filter((err) => err !== '').length < 1) {
      const inputData: CreateCustomerInputType = hasCustomerResponsible
        ? {
            user: {
              firstName,
              lastName,
              email,
              role
            },
            company: {
              siret,
              name: companyName,
              activity,
              vat,
              billingAddress,
              isDataFromApi: siretDisabledCompletion
            }
          }
        : {
            company: {
              siret,
              name: companyName,
              activity,
              vat,
              billingAddress,
              isDataFromApi: siretDisabledCompletion
            }
          }
      const result = await handleCreateCustomer(inputData)
      if (result.error) {
        setErrorFromApi((result as ErrorOutput).error)
        setTimeout(() => {
          setErrorFromApi('')
        }, 5000)
      } else {
        setCreationInformations({
          userId: result.userId,
          companyId: result.companyId
        })
        setErrorFromApi('')
        setIsModalSaveOpen(true)
      }
    }
    if (!hasBeenSubmitted) {
      setHasBeenSubmitted(true)
    }
    if (!siretHasBeenSubmitted) {
      setSiretHasBeenSubmitted(true)
    }
  }

  const handleGetCompanyInfo = () => {
    setIsGetCompanyInfoLoading(true)
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    navigate(`/`)
  }

  const addToPanels = (
    panels: (PanelType | JSX.Element)[],
    title: string,
    sections: (SectionType | JSX.Element)[],
    isDropdown?: boolean
  ) => {
    panels.push({
      title,
      sections,
      isDropdown: isDropdown ?? false,
      disabled: false
    })
  }

  const panels: (PanelType | JSX.Element)[] = []

  const CompanySection: SectionType = {
    title: '',
    description: `Renseignez ici les informations concernant le client.`,
    content: (
      <Container>
        <SiretContainer>
          <Input
            containerWidth="50%"
            type="number"
            label={'SIRET'}
            isRequired={true}
            onChange={handleSiretChange}
            value={siret}
            error={
              hasBeenSubmitted || siretHasBeenSubmitted ? errors.siret : ''
            }
            placeholder="90855325808382"
            withBorder
          />
          <Button
            type="tertiary"
            style={siretButtonStyle}
            onClick={handleGetCompanyInfo}
          >
            {isGetCompanyInfoLoading ? <AnimatedLoader /> : 'Valider'}
          </Button>
        </SiretContainer>
        <DefaultText
          style={{
            color: 'red',
            width: '100%',
            textAlign: 'center',
            display: siretDisabledCompletion ? 'none' : 'block'
          }}
          size="small"
        >
          {`Une erreur inconnue provenant de l'API Siret est survenue. Veuillez
          entrer manuellement les informations de votre entreprise.`}
        </DefaultText>
        <Input
          type="text"
          label={`Nom de l'entreprise`}
          isRequired={false}
          value={companyName}
          onChange={handleCompanyNameChange}
          error={hasBeenSubmitted ? errors.companyName : ''}
          disabled={siretDisabledCompletion}
          withBorder
        />
        <Input
          type="text"
          label={`Activité`}
          isRequired={false}
          value={activity}
          onChange={handleActivityChange}
          error={hasBeenSubmitted ? errors.activity : ''}
          disabled={siretDisabledCompletion}
          withBorder
        />
        <Input
          type="text"
          label={`Numéro de TVA intracommunautaire`}
          isRequired={false}
          value={vat}
          onChange={handleVatChange}
          error={hasBeenSubmitted ? errors.vat : ''}
          disabled={siretDisabledCompletion}
          placeholder={siretDisabledCompletion ? '' : 'FR32123456789'}
          withBorder
        />
        <Input
          type="text"
          label={`Adresse de facturation`}
          isRequired={true}
          value={billingAddress}
          onChange={handleBillingAddressChange}
          error={hasBeenSubmitted ? errors.billingAddress : ''}
          withBorder
        />
      </Container>
    )
  }

  const HasCustomerResponsibleSection: SectionType = {
    title: '',
    description: 'Souhaitez-vous renseigner un responsable pour ce client ?',
    content: (
      <RadioButtonsContainer>
        <RadioButton
          checked={hasCustomerResponsible}
          onChange={() => {
            handleHasCustomerResponsibleChange(true)
          }}
          withBorder
        >
          <DefaultText style={{ margin: 0 }}>Oui</DefaultText>
        </RadioButton>
        <RadioButton
          checked={!hasCustomerResponsible}
          onChange={() => {
            handleHasCustomerResponsibleChange(false)
          }}
          withBorder
        >
          <DefaultText style={{ margin: 0 }}>Non</DefaultText>
        </RadioButton>
      </RadioButtonsContainer>
    )
  }

  const CustomerInfoTip = (
    <TipsInfos
      title="Information sur le client"
      text="Si vous ne renseignez pas de responsable,
      votre agence sera responsable du paiement des plans média de ce client."
      style={customerTipStyle}
    />
  )

  const CustomerSection: SectionType = {
    title: '',
    description:
      'Renseignez ici les informations de contact de la personne qui sera chargée de valider et payer les plans média.',
    content: (
      <Container>
        <NamesDiv>
          <Input
            type="text"
            label={'Prénom'}
            value={firstName}
            onChange={handleFirstNameChange}
            error={hasBeenSubmitted ? errors.firstName : ''}
            containerWidth="50%"
            isRequired={true}
            withBorder
          />
          <Input
            type="text"
            label={'Nom'}
            value={lastName}
            onChange={handleLastNameChange}
            error={hasBeenSubmitted ? errors.lastName : ''}
            containerWidth="50%"
            isRequired={true}
            withBorder
          />
        </NamesDiv>
        <Input
          type="text"
          label={'Email'}
          value={email}
          onChange={handleEmailChange}
          error={hasBeenSubmitted ? errors.email : ''}
          isRequired={true}
          withBorder
        />
        <Input
          type="text"
          label={'Poste occupé'}
          value={role}
          onChange={handleRoleChange}
          error={hasBeenSubmitted ? errors.role : ''}
          isRequired={true}
          withBorder
        />
      </Container>
    )
  }

  addToPanels(panels, `Information sur le client`, [CompanySection])
  addToPanels(panels, `Responsable client`, [HasCustomerResponsibleSection])
  hasCustomerResponsible
    ? addToPanels(panels, `Contact et chargé de validation`, [CustomerSection])
    : panels.push(CustomerInfoTip)

  return (
    <AuthenticatedTemplate
      navigate={navigate}
      user={user}
      isEditor={isEditor != null ? isEditor : true}
    >
      <CreationPageTemplate
        title="Ajouter un nouveau client"
        subTitle="Entrez les informations nécessaires à la création d'un client"
        panels={panels}
        validation={{
          wording: 'Créer',
          action: () => {
            handleSubmitCustomer()
          },
          disabled: false
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
        errors={
          hasBeenSubmitted
            ? {
                errors: [
                  [
                    errorFromApi,
                    errors.siret,
                    errors.companyName,
                    errors.activity,
                    errors.vat,
                    errors.billingAddress,
                    errors.firstName,
                    errors.lastName,
                    errors.email,
                    errors.role
                  ].filter((e) => e)[0]
                ]
              }
            : undefined
        }
      />
      {isModalSaveOpen && (
        <ConfirmationModal
          title="Votre client a bien été créé."
          textConfirm="Créer un plan média"
          textReject="Voir la page client"
          onClickReject={handleSeeCustomer}
          onClickConfirm={handleCreateMediaPlan}
          onClose={handleCloseModal}
        >
          <ModalContent>Que souhaitez-vous faire à présent ?</ModalContent>
        </ConfirmationModal>
      )}
      {isCancelModalOpen && (
        <ConfirmationModal
          title="Le client est en cours de création"
          textConfirm="Oui, annuler"
          textReject="Non, continuer"
          onClickReject={handleCloseCancelModal}
          onClickConfirm={handleCancelConfirm}
          onClose={handleCloseCancelModal}
        >
          <CancelModalContent>
            <DefaultText>
              {'Êtes-vous sûr de vouloir annuler la création du client ?'}
            </DefaultText>
            <DefaultText>
              {'Toutes les informations saisies seront perdues.'}
            </DefaultText>
          </CancelModalContent>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default CreateCustomer
