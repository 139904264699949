export type AdminUserDataType = {
  firstName: string
  lastName: string
  email: string
}

export const checkAdminUserData = (
  value: any
): value is AdminUserDataType | null => {
  return (
    value == null ||
    (value.firstName !== undefined &&
      typeof value.firstName === 'string' &&
      value.lastName !== undefined &&
      typeof value.lastName === 'string' &&
      value.email !== undefined &&
      typeof value.email === 'string')
  )
}

export const checkAdminUserDataNotNull = (
  value: any
): value is AdminUserDataType => {
  return (
    value.firstName !== undefined &&
    typeof value.firstName === 'string' &&
    value.lastName !== undefined &&
    typeof value.lastName === 'string' &&
    value.email !== undefined &&
    typeof value.email === 'string'
  )
}
