import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ContentContainer,
  MessageContainer,
  analyticsContentPadding
} from './styles'
import AnalyticsTabs from './AnalyticsTabs'
import TabMapping from './CampaignTabMapping'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { AppContext } from '../../contexts/AppContext'
import {
  checkIfGetCampaignReportingOutputError,
  getCampaignReportingBySection
} from '../../helpers/queries/reporting/getCampaignReportingBySection'
import {
  checkIfCampaignImpactReportingIsEmpty,
  GetCampaignDownloadReportingOutputType,
  GetCampaignHighlightReportingOutputType,
  GetCampaignReportingBySectionOutputType
} from '../../types/reporting/campaign/output'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { Option } from '../../components/Dropdown'
import DefaultText from '../../components/DefaultText'
import { getPageContentWidth } from './styles/helpers/getPageContentWidth'
import { getReportingData } from '../../helpers/queries/getReportingData'
import { Filters } from './Filters'
import { Campaign, MediaPlan, ReportingDataType } from './types'
import { getMinDate } from '../../helpers/date'
import AnalyticsHighlights from './AnalyticsHighlights'
import HorizontalSeparator from '../../components/HorizontalSeparator'
import Padding from '../../constants/padding'
import Colors from '../../constants/colors'
import { ReportingAPISectionEnum } from '../../types/reporting/apiSection'
import { checkIfGetCampaignHighlightReporting } from '../../helpers/queries/reporting/sections/highlight'
import { FormatAPIEnum } from '../../types/format'
import { getReportingTabs } from './helpers/getReportingTabs'
import { checkIfGetCampaignDownloadReporting } from '../../helpers/queries/reporting/sections/download'
import { roundToTwoDecimals } from '../../helpers/rounds'
import {
  checkIfGetMediaPlanReportingOutputError,
  getMediaPlanReportingBySection
} from '../../helpers/queries/reporting/getMediaPlanReportingBySection'
import {
  GetMediaPlanDownloadReportingOutputType,
  GetMediaPlanHighlightReportingOutputType,
  GetMediaPlanReportingBySectionOutputType
} from '../../types/reporting/mediaPlan/output'
import { getMediaPlanReportingTabs } from './helpers/getMediaPlanReportingTabs'
import { checkIfGetMediaPlanDownloadReporting } from '../../helpers/queries/reporting/sections/mediaPlan/download'
import MediaPlanTabMapping from './MediaPlanTabMapping'

const AnalyticsPage = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [activeMediaPlanTabIndex, setActiveMediaPlanTabIndex] = useState(0)
  const [data, setData] = useState<ReportingDataType | null>(null)
  const [reportingResult, setReportingResult] = useState<
    | GetCampaignReportingBySectionOutputType
    | GetMediaPlanReportingBySectionOutputType
    | null
  >(null)
  const [isContentLoading, setIsContentLoading] = useState(true)
  const [isMediaPlanContentLoading, setIsMediaPlanContentLoading] =
    useState(true)
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [currentCustomerName, setCurrentCustomerName] = useState<string | null>(
    null
  )
  const [currentMediaPlan, setCurrentMediaPlan] = useState<MediaPlan | null>(
    null
  )
  const [currentCampaign, setCurrentCampaign] = useState<Campaign | null>(null)
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [analyticsPageContentWidth, setAnalyticsPageContentWidth] = useState(
    getPageContentWidth(analyticsContentPadding)
  )
  const [highlightsData, setHighlightsData] = useState<
    | GetCampaignHighlightReportingOutputType
    | GetMediaPlanHighlightReportingOutputType
    | null
  >(null)
  const [downloadData, setDownloadData] = useState<
    | GetCampaignDownloadReportingOutputType
    | GetMediaPlanDownloadReportingOutputType
    | null
  >(null)

  const previousActiveTabIndex = useRef(0)
  const previousActiveMediaPlanTabIndex = useRef(0)
  const navigate = useNavigate()
  const { isEditor } = useContext(AppContext)

  useEffect(() => {
    ;(async () => {
      const data = await getReportingData()
      if (data == null) {
        setFetchError('Une erreur est survenue lors du chargement des données.')
      } else {
        // filter campaigns by date. e.g : new Date(cp.startDate) < new Date()
        setData({
          customers: data.customers,
          mediaPlans: data.mediaPlans,
          campaigns: data.campaigns
        })
      }
    })()
  }, [])
  const tabs = getReportingTabs(currentCampaign?.format || FormatAPIEnum.TEXT)
  const mediaPlanTabs = getMediaPlanReportingTabs()

  useEffect(() => {
    if (currentMediaPlan && !currentCampaign) {
      ;(async () => {
        const fetchingMediaPlanSection =
          mediaPlanTabs[activeMediaPlanTabIndex].fetch

        if (fetchingMediaPlanSection) {
          const reportingResult = await getMediaPlanReportingBySection({
            mediaPlanId: currentMediaPlan.id,
            section: fetchingMediaPlanSection,
            startDate: startDate || undefined,
            endDate: endDate || undefined
          })

          if (
            previousActiveMediaPlanTabIndex.current === activeMediaPlanTabIndex
          ) {
            const highlightReportingResult =
              await getMediaPlanReportingBySection({
                mediaPlanId: currentMediaPlan.id,
                section: ReportingAPISectionEnum.highlight,
                startDate: startDate || undefined,
                endDate: endDate || undefined
              })

            if (
              checkIfGetCampaignHighlightReporting(highlightReportingResult)
            ) {
              setHighlightsData(highlightReportingResult)
            } else {
              setHighlightsData({
                clicks: 0,
                impressions: 0,
                co2: 0,
                cost: 0,
                impact: 0
              })
            }

            const downloadReportingResult =
              await getMediaPlanReportingBySection({
                mediaPlanId: currentMediaPlan.id,
                section: ReportingAPISectionEnum.download,
                startDate: startDate || undefined,
                endDate: endDate || undefined
              })
            if (checkIfGetMediaPlanDownloadReporting(downloadReportingResult)) {
              const downloadData = downloadReportingResult.map((row) => {
                return {
                  ...row,
                  co2PerEuro: roundToTwoDecimals(row.co2 / row.cost)
                }
              })
              setDownloadData(downloadData)
            }
          }

          if (!checkIfGetMediaPlanReportingOutputError(reportingResult)) {
            setReportingResult(reportingResult)
          } else {
            setFetchError(
              'Une erreur est survenue lors du chargement des KPI du plan média'
            )
          }
        }

        if (currentCustomerName !== currentMediaPlan.customerName) {
          setCurrentCustomerName(currentMediaPlan.customerName)
        }
        setIsMediaPlanContentLoading(false)

        previousActiveMediaPlanTabIndex.current = activeMediaPlanTabIndex
      })()
    } else {
      setReportingResult(null)
    }
  }, [
    activeMediaPlanTabIndex,
    currentCampaign,
    currentMediaPlan,
    startDate,
    endDate
  ])

  useEffect(() => {
    if (currentCampaign) {
      ;(async () => {
        const fetchingSection = tabs[activeTabIndex].fetch

        if (fetchingSection) {
          const reportingResult = await getCampaignReportingBySection({
            campaignId: currentCampaign.id,
            section: fetchingSection,
            startDate: startDate || undefined,
            endDate: endDate || undefined
          })

          if (previousActiveTabIndex.current === activeTabIndex) {
            const highlightReportingResult =
              await getCampaignReportingBySection({
                campaignId: currentCampaign.id,
                section: ReportingAPISectionEnum.highlight,
                startDate: startDate || undefined,
                endDate: endDate || undefined
              })

            if (
              checkIfGetCampaignHighlightReporting(highlightReportingResult)
            ) {
              setHighlightsData(highlightReportingResult)
            } else {
              setHighlightsData({
                clicks: 0,
                impressions: 0,
                co2: 0,
                cost: 0,
                impact: 0
              })
            }

            const downloadReportingResult = await getCampaignReportingBySection(
              {
                campaignId: currentCampaign.id,
                section: ReportingAPISectionEnum.download,
                startDate: startDate || undefined,
                endDate: endDate || undefined
              }
            )
            if (checkIfGetCampaignDownloadReporting(downloadReportingResult)) {
              const downloadData = downloadReportingResult.map((row) => {
                return {
                  ...row,
                  co2PerEuro: roundToTwoDecimals(row.co2 / row.cost)
                }
              })
              setDownloadData(downloadData)
            }
          }

          if (!checkIfGetCampaignReportingOutputError(reportingResult)) {
            setReportingResult(reportingResult)
          } else {
            setFetchError('Une erreur est survenue lors du chargement des KPI')
          }
        }

        if (
          currentMediaPlan == null ||
          currentCampaign.mediaPlanName !== currentMediaPlan.name
        ) {
          const mediaPlan = mediaPlans.filter(
            (mp) => mp.name === currentCampaign.mediaPlanName
          )[0]
          if (mediaPlan) {
            if (currentCustomerName !== mediaPlan.customerName) {
              setCurrentCustomerName(mediaPlan.customerName)
            }
            setCurrentMediaPlan(mediaPlan)
          }
        }
        setIsContentLoading(false)

        previousActiveTabIndex.current = activeTabIndex
      })()
    } else {
      setReportingResult(null)
    }
  }, [activeTabIndex, currentCampaign, startDate, endDate])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [analyticsContentPadding])

  const handleWindowResize = () => {
    setAnalyticsPageContentWidth(getPageContentWidth(analyticsContentPadding))
  }

  const handleChangeTabIndex = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index)
      setIsContentLoading(true)
    }
  }

  const handleChangeMediaPlanTabIndex = (index: number) => {
    if (index !== activeMediaPlanTabIndex) {
      setActiveMediaPlanTabIndex(index)
      setIsMediaPlanContentLoading(true)
    }
  }

  const handleChangeCurrentCustomer = (option: Option | null) => {
    if (data == null || option == null) return

    if (option.label !== currentCustomerName) {
      if (option.label !== currentMediaPlan?.customerName) {
        setCurrentMediaPlan(null)
        setCurrentCampaign(null)
      }
      setCurrentCustomerName(option.label)
    }
  }

  const handleChangeCurrentMediaPlan = (option: Option | null) => {
    if (data == null || option == null) return

    if (option.id !== currentMediaPlan?.id) {
      const selectedMediaPlan = data.mediaPlans.filter(
        (mp) => mp.id === option.id
      )[0]

      if (selectedMediaPlan == null) {
        return
      }

      if (currentCustomerName !== selectedMediaPlan.customerName) {
        setCurrentCustomerName(selectedMediaPlan.customerName)
      }

      if (currentCampaign?.mediaPlanName !== selectedMediaPlan.name) {
        setCurrentCampaign(null)
      }
      setCurrentMediaPlan(selectedMediaPlan)
    }
  }

  const handleChangeCurrentCampaign = (option: Option | null) => {
    if (option === null) {
      setCurrentCampaign(null)
      setActiveTabIndex(0)
      setStartDate(null)
      setEndDate(null)
    } else if (option && option.id !== currentCampaign?.id) {
      setCurrentCampaign(campaigns.find((cp) => cp.id === option.id) || null)
      setActiveTabIndex(0)
      setStartDate(null)
      setEndDate(null)
    }
  }

  if (fetchError) {
    return <ErrorPage message={fetchError} />
  }

  if (data == null) {
    return <LoadingPage />
  }

  const { customers, mediaPlans, campaigns } = data

  const customerOptions = customers.map((customer) => ({
    id: customer.id,
    label: customer.name
  }))

  const availableMediaPlan = mediaPlans.filter((mp) =>
    currentCustomerName ? mp.customerName === currentCustomerName : true
  )

  const availableMediaPlanNames = currentMediaPlan
    ? [currentMediaPlan.name]
    : availableMediaPlan.map((mp) => mp.name)

  const mediaPlanOptions = availableMediaPlan.map((mp) => ({
    id: mp.id,
    label: mp.name
  }))

  const campaignOptions = campaigns
    .filter((cp) =>
      currentCustomerName || currentMediaPlan
        ? availableMediaPlanNames.includes(cp.mediaPlanName)
        : true
    )
    .map((campaign) => ({
      id: campaign.id,
      label: campaign.name
    }))

  const today = new Date().toISOString().split('T')[0]

  const headerCsv = [
    'date',
    'clicks',
    'impressions',
    'cost',
    'co2',
    'impact',
    'co2PerEuro'
  ]

  const hasNoData = checkIfCampaignImpactReportingIsEmpty(reportingResult)

  const defaultEndDate = currentCampaign
    ? getMinDate(currentCampaign.endDate, today)
    : currentMediaPlan
    ? getMinDate(currentMediaPlan.endDate, today)
    : ''

  const effectiveEndDate = endDate || defaultEndDate
  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Filters
        isEditor={isEditor ?? true}
        customerOptions={customerOptions}
        mediaPlanOptions={mediaPlanOptions}
        campaignOptions={campaignOptions}
        handleChangeCurrentCustomer={handleChangeCurrentCustomer}
        handleChangeCurrentMediaPlan={handleChangeCurrentMediaPlan}
        handleChangeCurrentCampaign={handleChangeCurrentCampaign}
        currentCustomerName={currentCustomerName}
        currentMediaPlan={currentMediaPlan}
        currentCampaign={currentCampaign}
        startDate={startDate || undefined}
        endDate={effectiveEndDate}
        today={today}
        handleChangeStartDate={(e) => {
          setStartDate(e.target.value)
        }}
        handleChangeEndDate={(e) => {
          setEndDate(e.target.value)
        }}
      />
      {currentMediaPlan && reportingResult && (
        <>
          <HorizontalSeparator
            padding={`${Padding.p9} ${Padding.p9} 0 ${Padding.p9}`}
            color={Colors.YETIC_GREY_DARK}
          />
          <AnalyticsHighlights
            data={highlightsData}
            downloadData={downloadData}
            headerCsv={headerCsv}
            currentCampaign={currentCampaign}
          />
          {currentCampaign ? (
            <>
              {hasNoData ? (
                <MessageContainer>
                  <DefaultText size="medium">
                    {`Cette campagne n'a aucune donnée à afficher`}
                  </DefaultText>
                </MessageContainer>
              ) : (
                <>
                  <AnalyticsTabs
                    tabs={tabs.map((tab) => tab.name)}
                    activeTabIndex={activeTabIndex}
                    handleChangeTabIndex={handleChangeTabIndex}
                  />
                  <ContentContainer>
                    <TabMapping
                      activeTab={tabs[activeTabIndex]}
                      isLoading={isContentLoading}
                      reportingData={
                        reportingResult as GetCampaignReportingBySectionOutputType
                      }
                      contentWidth={analyticsPageContentWidth}
                    />
                  </ContentContainer>
                </>
              )}
            </>
          ) : (
            <>
              <AnalyticsTabs
                tabs={mediaPlanTabs.map((tab) => tab.name)}
                activeTabIndex={activeMediaPlanTabIndex}
                handleChangeTabIndex={handleChangeMediaPlanTabIndex}
              />
              <ContentContainer>
                <MediaPlanTabMapping
                  activeTab={mediaPlanTabs[activeMediaPlanTabIndex]}
                  isLoading={isMediaPlanContentLoading}
                  reportingData={
                    reportingResult as GetMediaPlanReportingBySectionOutputType
                  }
                  contentWidth={analyticsPageContentWidth}
                />
              </ContentContainer>
            </>
          )}
        </>
      )}
      {!reportingResult &&
        (campaignOptions.length > 0 ? (
          <MessageContainer>
            <DefaultText size="medium">
              {currentCampaign
                ? `Cette campagne n'a aucune donnée à afficher`
                : `Ce plan média n'a aucune donnée à afficher`}
            </DefaultText>
          </MessageContainer>
        ) : (
          <MessageContainer>
            <DefaultText size="medium">
              {campaigns.length > 0
                ? currentCustomerName
                  ? `Aucune campagne lancée n'a été trouvée pour ce client.`
                  : currentMediaPlan
                  ? `Aucune campagne lancée n'a été trouvée pour ce plan média.`
                  : ``
                : `Aucune campagne lancée n'a été trouvée.`}
            </DefaultText>
          </MessageContainer>
        ))}
    </AuthenticatedTemplate>
  )
}

export default AnalyticsPage
