export type KeywordDetails = {
  name: string
  avgMonthlySearches: number
  competition: 'LOW' | 'MEDIUM' | 'HIGH'
  lowBid: number
  highBid: number
}

export const getCompetitionTranslation = (
  competition: 'LOW' | 'MEDIUM' | 'HIGH'
) => {
  switch (competition) {
    case 'LOW':
      return 'Faible'
    case 'MEDIUM':
      return 'Moyenne'
    case 'HIGH':
      return 'Élevée'
  }
}

export const checkIfKeywordDetails = (value: any): value is KeywordDetails => {
  return (
    value &&
    value.name &&
    typeof value.name === 'string' &&
    value.avgMonthlySearches &&
    typeof value.avgMonthlySearches === 'number' &&
    value.competition &&
    ['LOW', 'MEDIUM', 'HIGH'].includes(value.competition) &&
    value.lowBid != null &&
    typeof value.lowBid === 'number' &&
    value.highBid != null &&
    typeof value.highBid === 'number'
  )
}
