import { FormatAPIEnum, checkIfFormatApiEnum } from '../format'
import { Status } from '../status'
import { FullGoogleImageAdType, checkIfGoogleImageAd } from './google/imageAd'
import { FullTextAdType, checkIfTextAd } from './google/textAd'

export type AdType = {
  id: string
  name: string
  format: FormatAPIEnum
  status: Status
}

export const checkIfAd = (value: any): value is AdType => {
  return (
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    typeof value.status === 'string'
  )
}

export const checkIfAdArray = (data: any): data is AdType[] => {
  return Array.isArray(data) && data.every((ad: any) => checkIfAd(ad))
}

export const checkIfFullAdArray = (
  data: any
): data is (FullTextAdType | FullGoogleImageAdType)[] => {
  return (
    Array.isArray(data) &&
    data.every((ad: any) => checkIfTextAd(ad) || checkIfGoogleImageAd(ad))
  )
}

export const checkIfAdNameOnlyArray = (
  data: any
): data is { id: string; name: string }[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (ad: any) =>
        ad.id &&
        ad.name &&
        typeof ad.id === 'string' &&
        typeof ad.name === 'string'
    )
  )
}

export type AdNavigation = {
  company: {
    name: string
    id: string
  }
  mediaPlan: {
    name: string
    id: string
  }
  campaign: {
    name: string
    id: string
    adsCount?: number
  }
}

export const emptyAdNavigation = {
  company: { name: '', id: '' },
  mediaPlan: { name: '', id: '' },
  campaign: { name: '', id: '' }
}

export const checkAdNavigation = (value: any): value is AdNavigation => {
  return (
    value &&
    value.company &&
    typeof value.company.id === 'string' &&
    typeof value.company.name === 'string' &&
    value.mediaPlan &&
    typeof value.mediaPlan.id === 'string' &&
    typeof value.mediaPlan.name === 'string' &&
    value.campaign &&
    typeof value.campaign.id === 'string' &&
    typeof value.campaign.name === 'string'
  )
}
