import React, { ReactNode, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearAllCookies, getCookie } from '../helpers/cookies'
import { AppContext } from '../contexts/AppContext'
import handleLogout from '../helpers/queries/auth/logout'
import { getDataFromCookieInHOC } from './helpers/cookies'
import NonAuthLoadingPage from '../pages/LoadingPage/NonAuthLoadingPage'

const RequiredAuthRoute = ({
  children,
  noRedirection
}: {
  children: ReactNode
  noRedirection?: boolean
}) => {
  const navigate = useNavigate()
  const { companyName, isEditor, user, setUser } = useContext(AppContext)

  // TODO review this
  useEffect(() => {
    ;(async function () {
      if (companyName == null || isEditor == null || user == null) {
        const dataFromCookies = getDataFromCookieInHOC()

        if (dataFromCookies != null) {
          const {
            companyId,
            firstName,
            lastName,
            email,
            companyName,
            isEditor,
            isSelfEditor
          } = dataFromCookies

          if (firstName && lastName && companyName && isEditor != null) {
            setUser({
              user: {
                firstName,
                lastName,
                email
              },
              isEditor,
              isSelfEditor,
              companyId,
              companyName
            })
          }
        } else if (noRedirection == null || !noRedirection) {
          clearAllCookies()
          await handleLogout()
          navigate('/auth')
        }
      }
    })()
  }, [])

  if (user == null) {
    return <NonAuthLoadingPage />
  }

  return children
}

export default RequiredAuthRoute
