import React from 'react'

import WidgetNav from './Nav'
import WidgetFaq from './FAQ'
import WidgetComments from './Paragraphs'
import {
  CloseButton,
  ErrorContainer,
  PageContent,
  LoaderContainer,
  Wrapper
} from './styles'
import WidgetHeader from './Header'
import LeafIcon from '../../../../components/Icon/leaf'
import HomePicto from '../../../../components/Icon/homePicto'
import CrossClose from '../../../../components/Icon/crossClose'
import DefaultText from '../../../../components/DefaultText'
import Button from '../../../../components/Button'
import Colors from '../../../../constants/colors'
import AnimatedLoader from '../../../../components/AnimatedLoader'
import { WidgetPageType, checkIfWidgetPageType } from '../../../../types/widget'

type PropsType = {
  isOpen: boolean
  loading: boolean
  currentPageData: WidgetPageType | null | { error: string }
  history: string[]
  setHistory: React.Dispatch<React.SetStateAction<string[]>>
  handleBack: () => void
  handleCloseClick: () => void
  handleRetry: () => void
  handleWidgetNavigate: (pageId: string) => void
}

const iconMapping = {
  leaf: <LeafIcon style={{ width: '30px', height: '30px' }} />,
  house: <HomePicto style={{ width: '30px', height: '30px' }} />
}

const WidgetContent = ({
  isOpen,
  loading,
  currentPageData,
  history,
  setHistory,
  handleBack,
  handleCloseClick,
  handleRetry,
  handleWidgetNavigate
}: PropsType) => {
  if (!isOpen) {
    return <></>
  } else if (loading) {
    return (
      <Wrapper>
        <LoaderContainer>
          <AnimatedLoader size={30} />
        </LoaderContainer>
      </Wrapper>
    )
  } else if (currentPageData && !checkIfWidgetPageType(currentPageData)) {
    return (
      <Wrapper>
        <CloseButton onClick={handleCloseClick}>
          <CrossClose width="25px" height="25px" />
        </CloseButton>
        <WidgetHeader history={history} handleBack={handleBack} />
        <ErrorContainer>
          <DefaultText size="medium" style={{ color: Colors.YETIC_RED }}>
            {currentPageData.error}
          </DefaultText>
          <Button type="primary" width="fit-content" onClick={handleRetry}>
            Réessayer
          </Button>
        </ErrorContainer>
      </Wrapper>
    )
  } else if (checkIfWidgetPageType(currentPageData)) {
    return (
      <Wrapper>
        <CloseButton onClick={handleCloseClick}>
          <CrossClose width="25px" height="25px" />
        </CloseButton>
        <WidgetHeader history={history} handleBack={handleBack} />
        <PageContent>
          {currentPageData.page.id !== 'root' && (
            <DefaultText
              size="big"
              bold
              style={{ textAlign: 'center', marginTop: '20px' }}
            >
              {currentPageData.page.title}
            </DefaultText>
          )}
          {currentPageData.page.nav.length > 0 && (
            <WidgetNav
              currentPageData={currentPageData}
              iconMapping={iconMapping}
              handleWidgetNavigate={handleWidgetNavigate}
            />
          )}
          {currentPageData.page.elements.faq.length > 0 && (
            <WidgetFaq currentPageData={currentPageData} />
          )}
          {currentPageData.page.elements.content.length > 0 && (
            <WidgetComments currentPageData={currentPageData} />
          )}
        </PageContent>
      </Wrapper>
    )
  }
}

export default WidgetContent
