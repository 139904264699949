import React from 'react'

import MultipleSelectionInput from '../../../../../components/Input/MultipleSelectionInput'

type BusinessSegmentsProps = {
  data: string[]
  selectedTags: string[]
  error?: string
  removeBusinessSegment: (index: number) => void
  addNewBusinessSegment: (tag: string) => void
}

const BusinessSegments = ({
  data,
  selectedTags,
  error,
  removeBusinessSegment,
  addNewBusinessSegment
}: BusinessSegmentsProps) => {
  return (
    <MultipleSelectionInput
      data={data}
      selectedTags={selectedTags}
      error={error}
      removeItem={removeBusinessSegment}
      addNewItem={addNewBusinessSegment}
      label="Secteurs d'activité"
      placeholder="Banque, Assurance, Automobile..."
      isRequired={true}
    />
  )
}

export default BusinessSegments
