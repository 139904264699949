import styled from '@emotion/styled'

export const CheckBoxContainer = styled.div<{
  height?: string
  withBorder?: boolean
}>`
  background: #fff;
  height: ${(props: { height?: string }) => props.height ?? '40px'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 5px;
  font-size: 12px;

  ${(props: { withBorder?: boolean }) =>
    props.withBorder ? 'border: 1px solid #C4C9D2;' : ''}
`
