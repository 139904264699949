import React from 'react'
import styled from '@emotion/styled'
import Colors from '../../../constants/colors'

const RadioContainer = styled.label<{
  withBorder?: boolean
  disabled: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  margin: 5px 0;
  padding: 0 15px;
  background-color: ${(props) =>
    props.disabled ? Colors.YETIC_GREY_DARK : Colors.YETIC_WHITE};
  border-radius: 10px;
  min-height: 40px;
  box-sizing: border-box;
  ${(props: { withBorder?: boolean }) =>
    props.withBorder ? 'border: 1px solid #C4C9D2;' : ''})
`

const RadioInput = styled.input`
  appearance: none;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 0;
  border: 1px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s;

  &:checked {
    border-color: #ccc;
    background-color: ${Colors.YETIC_BLUE_DARK};
  }
`

interface RadioProps {
  checked: boolean
  onChange?: ((checked: boolean) => void) | (() => void)
  children?: React.ReactNode
  disabled?: boolean
  withBorder?: boolean
}

const RadioButton = ({
  checked = false,
  onChange,
  children,
  disabled = false,
  withBorder
}: RadioProps) => {
  const handleRadioChange = () => {
    if (!disabled && onChange) {
      onChange(!checked)
    }
  }

  return (
    <RadioContainer disabled={disabled} withBorder={withBorder}>
      <RadioInput type="radio" onChange={handleRadioChange} checked={checked} />
      {children}
    </RadioContainer>
  )
}

export default RadioButton
