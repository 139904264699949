import React, { useState } from 'react'

import { HiddenText, OpenButtonImage, Wrapper } from './styles'
import DefaultText from '../../../../components/DefaultText'

type PropsType = {
  handleOpenClick: () => void
  isOpen: boolean
  openWording: string
  closeWording: string
}

const WidgetOpenButton = ({
  handleOpenClick,
  isOpen,
  openWording,
  closeWording
}: PropsType) => {
  const [hovered, setHovered] = useState(false)

  const handleMouseOver = () => {
    !hovered && setHovered(true)
  }

  const handleMouseOut = () => {
    hovered && setHovered(false)
  }

  const wording = isOpen ? closeWording : openWording

  const hiddenTextWidth = hovered ? wording.length * 6.5 + 100 + 'px' : '0'

  return (
    <Wrapper
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={handleOpenClick}
    >
      <HiddenText visible={hovered} width={hiddenTextWidth}>
        <DefaultText style={{ whiteSpace: 'nowrap', marginBottom: 0 }}>
          {wording}
        </DefaultText>
      </HiddenText>
      <source srcSet={'/images/Widget/yetic_logo.webp'} type="image/webp" />
      <OpenButtonImage src={'/images/Widget/yetic_logo.webp'} alt="Yeti" />
    </Wrapper>
  )
}

export default WidgetOpenButton
