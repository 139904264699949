export enum AgeRangeEnum {
  range18to24 = 'range18to24',
  range25to34 = 'range25to34',
  range35to44 = 'range35to44',
  range45to54 = 'range45to54',
  range55to64 = 'range55to64',
  range65 = 'range65',
  default = 'default'
}

export const getAgeRangeFromEnum = (value: AgeRangeEnum): string => {
  switch (value) {
    case AgeRangeEnum.range18to24:
      return '18-24'
    case AgeRangeEnum.range25to34:
      return '25-34'
    case AgeRangeEnum.range35to44:
      return '35-44'
    case AgeRangeEnum.range45to54:
      return '45-54'
    case AgeRangeEnum.range55to64:
      return '55-64'
    case AgeRangeEnum.range65:
      return '65+'
    case AgeRangeEnum.default:
      return 'Inconnu'
  }
}

export const getAgeRangeEnumList = (): AgeRangeEnum[] => {
  return [
    AgeRangeEnum.range18to24,
    AgeRangeEnum.range25to34,
    AgeRangeEnum.range35to44,
    AgeRangeEnum.range45to54,
    AgeRangeEnum.range55to64,
    AgeRangeEnum.range65,
    AgeRangeEnum.default
  ]
}
