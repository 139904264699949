import { ImageAdCreationFormDataType } from '..'
import {
  linkedinMaxWidth,
  linkedinMinWidth,
  linkedinRectangleRatio,
  linkedinSquareRatio
} from '../../../../../constants/image'
import { checkUrl } from '../../../../../helpers/checkers'
import { getImageDimensions, getImageRatio } from '../../../../../helpers/image'
import { LinkedinFormImageAdError } from '../../../../../types/pages/adImageCreation'
import {
  checkIfCTALabelValid,
  checkIfIntroTextValid,
  checkIfHeadlineValid,
  checkIfImageGiven,
  checkIfNameValid,
  checkImageSize
} from './checkers'

export type ImageAdCreationErrorsType = {
  name: string
  url: string
  headline: string
  introText: string
  ctaLabel: string
}

export const getFormErrors = (
  formData: ImageAdCreationFormDataType
): ImageAdCreationErrorsType => {
  return {
    name: checkIfNameValid(formData.name) ? '' : LinkedinFormImageAdError.NAME,
    url: checkUrl(formData.url) ? '' : LinkedinFormImageAdError.URL,
    headline: checkIfHeadlineValid(formData.headline)
      ? ''
      : LinkedinFormImageAdError.HEADLINE,
    introText: checkIfIntroTextValid(formData.introText)
      ? ''
      : LinkedinFormImageAdError.CONTENT,
    ctaLabel: checkIfCTALabelValid(formData.ctaLabel)
      ? ''
      : LinkedinFormImageAdError.CTA
  }
}

export const getImageError = async (img: File | null): Promise<string> => {
  if (!checkIfImageGiven(img)) {
    return LinkedinFormImageAdError.IMAGE_MISSING
  } else {
    if (!checkImageSize(img)) {
      return LinkedinFormImageAdError.IMAGE_SIZE
    } else {
      return getDimensionsError(img)
    }
  }
}

export const getDimensionsError = async (img: File): Promise<string> => {
  const imgSrc = URL.createObjectURL(img)

  const imgRatio = await getImageRatio(imgSrc)
  const imgDimensions = await getImageDimensions(imgSrc)

  if (imgRatio !== linkedinRectangleRatio && imgRatio !== linkedinSquareRatio) {
    return LinkedinFormImageAdError.IMAGE_RATIO
  }
  if (imgDimensions.width < linkedinMinWidth) {
    return LinkedinFormImageAdError.IMAGE_MIN_DIMENSIONS
  } else if (imgDimensions.width > linkedinMaxWidth) {
    return LinkedinFormImageAdError.IMAGE_MAX_DIMENSIONS
  }
  return ''
}

export const checkIfEmptyErrors = (
  errors: ImageAdCreationErrorsType,
  imageError: string
) => {
  return (
    Object.values(errors).filter((e) => e).length === 0 && imageError === ''
  )
}
