import styled from '@emotion/styled'

import Colors from '../../../constants/colors'

export const LeftMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  box-shadow: 0px 0px 2px #142a4c40;
  width: 56px;
  align-items: center;
`

export const LeftMenuIcon = styled.div<{ isActive?: boolean }>`
  padding: 10px;
  margin-bottom: 24px;
  box-sizing: border-box;
  position: relative;
  margin-right: 8px;
  margin-left: 8px;
  transition: background-color 0.3s;
  &:hover > div {
    visibility: visible;
  }
  &:hover {
    background-color: ${(props) =>
      props.isActive ? `#FFE787` : `${Colors.YETIC_GREY_DARK}`};
    border-radius: 8px;
    cursor: pointer;
  }
  background-color: ${(props) => (props.isActive ? `#FFE787` : ``)};
  border-radius: 8px;
`

export const LeftMenuFirstIcon = styled(LeftMenuIcon)<{ isActive?: boolean }>`
  margin-top: 16px;
`

export const LeftMenuAvatar = styled.div`
  margin-top: auto;
  padding: 8px;
  transform: scale(0.8);
`

export const Tooltip = styled.div`
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 8px;
  padding: 5px;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: calc(100% + 10px);
  white-space: nowrap;
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`
