import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import Margin from '../../../../../../constants/margin'

export const DivContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const GaleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: ${Margin.m7};
`

export const RenderPreview = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${Margin.m6};
  height: 600px;
`

export const NextRender = styled.div`
  position: absolute;
  right: 10px;
  top: 295px;
  transform: translate(0, -50%);
  cursor: pointer;
`

export const PreviewWrapper = styled.div`
  transform: scale(0.2) translateX(-100%);
  transform-origin: top;
  width: 100px;
  height: 100px;
  cursor: pointer;
  flex: 0.2;
  margin-bottom: 32px;

  &:hover {
    filter: drop-shadow(0 0 1rem ${Colors.YETIC_GREY_AD});
  }
`
