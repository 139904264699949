import { backendURL } from '../../../constants/urls'
import { AdNavigation, checkAdNavigation } from '../../../types/ad/ad'
import { CampaignType, checkIfCampaign } from '../../../types/campaign/campaign'
import { FormatAPIEnum } from '../../../types/format'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : manage error
export type GetCampaignOutputType = {
  campaign: CampaignType
  navigation: AdNavigation
} | null

export type GetCampaignInput = {
  campaignId: string
  withoutAds?: boolean
  adType?: FormatAPIEnum
  skip?: number
  limit?: number
}

// TODO response.data includes ads, which will be {format: string, id: string, name: string}[]
export const getCampaignById = async ({
  campaignId,
  withoutAds,
  adType,
  skip,
  limit
}: GetCampaignInput): Promise<GetCampaignOutputType> => {
  try {
    let url = ''
    if (withoutAds) {
      url = `${backendURL}/campaigns/${campaignId}?navigation=1&withoutAds=1${
        skip ? `&skip=${skip * 5}` : ''
      }${limit ? `&limit=${limit}` : ''}`
    } else if (adType) {
      url = `${backendURL}/campaigns/${campaignId}?navigation=1&adType=${adType}${
        skip ? `&skip=${skip * 5}` : ''
      }${limit ? `&limit=${limit}` : ''}`
    } else {
      url = `${backendURL}/campaigns/${campaignId}?navigation=1${
        skip ? `&skip=${skip * 5}` : ''
      }${limit ? `&limit=${limit}` : ''}`
    }

    const response = await axiosGet({
      url
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (
        response.data &&
        checkIfCampaign(response.data.campaign) &&
        checkAdNavigation(response.data.navigation)
      ) {
        return {
          campaign: response.data.campaign,
          navigation: response.data.navigation
        }
      }
    }
    return null
  } catch (_) {
    return null
  }
}
