import React from 'react'

import {
  ContentContainer,
  ContentImage,
  ParagraphContainer,
  Wrapper,
  paragraphStyle,
  titleStyle
} from './styles'
import {
  WidgetPageContentType as Content,
  WidgetPageType
} from '../../../../../types/widget'
import DefaultText from '../../../../../components/DefaultText'
import CTA from '../CTA'

type PropsType = {
  currentPageData: WidgetPageType
}

const WidgetParagraphs = ({ currentPageData }: PropsType) => {
  if (!currentPageData) {
    return <></>
  }

  const renderImages = (content: Content) =>
    content.images.map((img) => {
      return <ContentImage key={content.title ?? Math.random()} src={img} />
    })

  const renderParagraphs = (content: Content) =>
    content.paragraphs.map((paragraph, index) => {
      const displayedParagraph =
        content.paragraphs.length > 1 ? `→ ${paragraph}` : paragraph
      return (
        <ParagraphContainer key={index}>
          <DefaultText style={paragraphStyle}>{displayedParagraph}</DefaultText>
        </ParagraphContainer>
      )
    })

  const paragraphsContent: Content[] =
    currentPageData.page.elements.content.filter(
      (content) =>
        content.title ||
        content.paragraphs ||
        content.images.length > 0 ||
        content.cta
    )

  return (
    <Wrapper>
      {paragraphsContent.map((content) => {
        return (
          <ContentContainer key={content.title ?? Math.random()}>
            {content.title && (
              <DefaultText bold style={titleStyle}>
                {content.title}
              </DefaultText>
            )}
            {content.images.length > 0 && renderImages(content)}
            {content.paragraphs && renderParagraphs(content)}
            {content.cta && (
              <CTA actionId={content.cta.actionId} label={content.cta.label} />
            )}
          </ContentContainer>
        )
      })}
    </Wrapper>
  )
}

export default WidgetParagraphs
