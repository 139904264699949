import { backendURL } from '../../../constants/urls'
import { DevicesNumber } from '../../../types/campaign/devices'
import { checkIsAxiosGetOutputSuccess } from '../axios/get'
import { axiosPost } from '../axios/post'

export type SimulateCampaignCO2CalculationOutputType = {
  co2: number
}

export type SimulateCampaignCO2CalculationInput = {
  campaignId: string
  proportions: DevicesNumber
  networkProportions?: object
  picturesTotalWeight?: number
}

// TODO response.data includes ads, which will be {format: string, id: string, name: string}[]
export const simulateCampaignCO2Calculation = async ({
  campaignId,
  proportions,
  networkProportions,
  picturesTotalWeight
}: SimulateCampaignCO2CalculationInput): Promise<SimulateCampaignCO2CalculationOutputType | null> => {
  try {
    const response = await axiosPost({
      url: `${backendURL}/campaigns/${campaignId}/simulate-co2`,
      data: {
        proportions,
        networkProportions,
        picturesTotalWeight
      }
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (
        response.data &&
        response.data.co2 !== undefined &&
        typeof response.data.co2 === 'number'
      ) {
        return response.data.co2
      }
    }
    return null
  } catch (_) {
    return null
  }
}
