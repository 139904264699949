import { MediaPlanGoal } from '../goals'

export type FormMediaPlanType = {
  name: string
  isEditorPaying: boolean
  startDate: string
  endDate: string
  amountBudget: number
  selectedGoals: (keyof typeof MediaPlanGoal)[]
  selectedAudiences: string[]
}

export enum FormMediaPlanError {
  START_DATE = `Veuillez choisir une date de début du plan média`,
  END_DATE = `Veuillez choisir une date de fin du plan média`,
  AMOUNT_BUDGET_MIN = `Le montant du budget est invalide`,
  NAME = `Le nom doit faire au moins 2 caractères`,
  GOALS = `Vous devez sélectionner au moins un objectif`,
  AUDIENCES = `Vous devez sélectionner au moins une audience`
}

export enum FormMediaPlanAudienceError {
  NAME = `Le nom doit faire au moins 2 caractères`,
  MIN_AGE = `L'âge minimum doit être compris entre 18 et 100 ans`,
  MAX_AGE = `L'âge maximum doit être compris entre 18 et 100 ans`,
  VALID_AGES = `L'âge maximum doit être supérieur à l'âge minimum`,
  LOCATIONS = `Vous devez sélectionner au moins une zone géographique`,
  // INTERESTS = `Vous devez sélectionner au moins un centre d'intérêt`
  BUSINESS_SEGMENTS = `Vous devez sélectionner au moins un secteur d'activité`,
  JOB_FUNCTIONS = `Pour assurer une taille d'audience significative, vous ne pouvez pas sélectionner à la fois une fonction professionnelle et un intitulé de poste. Veuillez exclure l'un des deux de votre sélection.`,
  JOB_TITLES = `Pour assurer une taille d'audience significative, vous ne pouvez pas sélectionner à la fois une fonction professionnelle et un intitulé de poste. Veuillez exclure l'un des deux de votre sélection.`
}
