import { BaseType, Selection } from 'd3'

const insertTextFromClassName = (
  d3Element: Selection<BaseType, unknown, HTMLElement, any>,
  className: string,
  text: string
) => {
  d3Element.selectAll(className).text(text)
}

export default insertTextFromClassName
