import { GetMediaPlanSelfEditorSectionsParams } from '../../types/mediaPlanSections'
import { sectionLabels } from '../sectionLabels/selfEditor'

export const getMediaPlanSelfEditorSections = ({
  sections,
  mediaPlans,
  campaigns,
  ads,
  fetchCampaigns,
  fetchAds,
  navigate
}: GetMediaPlanSelfEditorSectionsParams) => {
  mediaPlans !== null &&
    sections.push({
      id: 'mediaPlan',
      title: sectionLabels.mediaPlan,
      items: mediaPlans.map((mediaPlan) => {
        return {
          id: mediaPlan.id,
          title: mediaPlan.name,
          action: () => {
            navigate(`/media-plan/${mediaPlan.id}/details`)
          }
        }
      }),
      fetchData: fetchCampaigns
    })

  campaigns !== null &&
    sections.push({
      id: 'campaign',
      title: sectionLabels.campaign,
      items: campaigns.map((campaign) => {
        return {
          id: campaign.id,
          title: campaign.name,
          action: () => {
            navigate(`/campaign/${campaign.id}/details`)
          }
        }
      }),
      fetchData: fetchAds
    })

  ads !== null &&
    sections.push({
      id: 'ad',
      title: sectionLabels.ad,
      items: ads.map((ad) => {
        return {
          id: ad.id,
          title: ad.name,
          action: () => {
            navigate(`/ad/${ad.id}/details`)
          }
        }
      })
    })
}
