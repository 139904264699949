import { roundToTwoDecimals } from './rounds'

export const getCO2andUnit = (
  co2InG: number
): { co2: number; co2Unit: string } => {
  const co2Unit = co2InG && co2InG >= 1000 ? 'Kg' : 'g'
  const co2 =
    co2InG && co2InG >= 1000 ? roundToTwoDecimals(co2InG / 1000) : co2InG

  return { co2, co2Unit }
}
