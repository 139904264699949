import React from 'react'

import { AudienceDataType } from '..'
import { PanelType } from '../../../../types/templates/panels'
import { LocationData } from '../../../../types/mediaPlanAudience'
import Location from '../Location'

type GetLocationPanelProps = {
  locations: LocationData[]
  audienceData: AudienceDataType
  handleNewLocationTag: (location: LocationData) => void
  handleRemoveLocationTags: (index: number) => void
  hasBeenSubmitted: boolean
  error: string
}

const getLocationPanel = ({
  locations,
  audienceData,
  handleNewLocationTag,
  handleRemoveLocationTags,
  hasBeenSubmitted,
  error
}: GetLocationPanelProps) => {
  const locationPanel: PanelType = {
    title: 'Zones géographiques',
    description: 'Sélectionnez les zones géographiques de votre audience',
    disabled: false,
    sections: [
      <Location
        key="audience-location"
        selectedTags={audienceData.selectedLocations}
        removeTag={handleRemoveLocationTags}
        addNewTag={handleNewLocationTag}
        data={locations}
        error={hasBeenSubmitted ? error : ''}
      />
    ],
    isDropdown: false
  }

  return locationPanel
}

export default getLocationPanel
