import { GetMediaPlanSectionsParams } from '../../types/mediaPlanSections'
import { getMediaPlanSelfEditorSections } from './selfEditor'

export const getMediaPlanSections = ({
  sections,
  isEditor,
  isSelfEditor,
  mediaPlans,
  campaigns,
  ads,
  fetchCampaigns,
  fetchAds,
  navigate
}: GetMediaPlanSectionsParams) => {
  if (isSelfEditor) {
    getMediaPlanSelfEditorSections({
      sections,
      mediaPlans,
      campaigns,
      ads,
      fetchCampaigns,
      fetchAds,
      navigate
    })
  }
}
