import React from 'react'

import {
  Container,
  Image,
  Content,
  Title,
  DivButtons,
  Description,
  buttonCancelStyle,
  buttonSkipStyle,
  DivPagination,
  DivText,
  buttonConfirmStyle,
  DivImage
} from './styles'
import Button from '../Button'
import Pagination from './Pagination'

type IntroductionProps = {
  title: string
  description?: string | JSX.Element
  skipIntroduction?: boolean
  imageWebp: string
  imagePng: string
  isNotBottom?: boolean
  buttonCancel?: {
    label?: string
  }
  buttonConfirm: {
    label?: string
    onClick?: () => void
  }
  buttonSkip?: {
    onClick: () => void
  }
  totalSlides: number
  currentSlide: number
  isSelfEditor?: boolean
  handleNextSlide: () => void
  handlePreviousSlide: () => void
  handleChangeSlide: (index: number) => void
  handleSkipIntroduction: () => void
}

const Introduction = ({
  title,
  description,
  imageWebp,
  imagePng,
  isNotBottom,
  buttonCancel,
  buttonConfirm,
  skipIntroduction = true,
  totalSlides,
  currentSlide,
  isSelfEditor,
  handleNextSlide,
  handlePreviousSlide,
  handleChangeSlide,
  handleSkipIntroduction
}: IntroductionProps) => {
  return (
    <Container>
      <Content>
        <DivImage>
          <source srcSet={imageWebp} type="image/webp" />
          <Image src={imagePng} alt="Yeti" isNotBottom={isNotBottom} />
        </DivImage>
        <DivText>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
          <DivButtons>
            {buttonCancel && (
              <Button
                type="secondary"
                width="fit-content"
                style={buttonCancelStyle}
                onClick={handlePreviousSlide}
              >
                {buttonCancel.label ?? 'Retour'}
              </Button>
            )}
            <Button
              type="primary"
              width="fit-content"
              onClick={buttonConfirm.onClick ?? handleNextSlide}
              style={buttonConfirmStyle}
            >
              {buttonConfirm.label ?? 'Suivant'}
            </Button>
          </DivButtons>
          {skipIntroduction && (
            <Button
              type="textButton"
              style={buttonSkipStyle}
              onClick={handleSkipIntroduction}
            >{`Passer l'introduction`}</Button>
          )}
        </DivText>
      </Content>
      <DivPagination>
        <Pagination
          totalSlides={totalSlides}
          currentSlide={currentSlide}
          handleChangeSlide={handleChangeSlide}
          isSelfEditor={isSelfEditor}
        />
      </DivPagination>
    </Container>
  )
}

export default Introduction
