import React from 'react'
import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../../../constants/fontFamily'
import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import FontWeight from '../../../../constants/fontWeight'

const PanelTitle = styled.div`
  color: ${Colors.NEW_YETIC_DARK};
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: ${FontWeight.YETIC_BOLD};
  font-family: ${YETIC_FONT_FAMILY};
  margin: ${Margin.m2} 0;
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: ${Margin.m2};
`

export default PanelTitle
