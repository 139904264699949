import React from 'react'

import { TipsInfos } from '../../../../../components/Tips'

const InfoTip = () => {
  return (
    <TipsInfos
      title="Nos recommandations"
      text={`Optez pour des images et des graphiques
            de haute qualité et compressés pour maximiser l'impact visuel
            sans compromettre la taille du fichier.`}
      isDropdown={true}
      style={{ marginBottom: '24px' }}
    />
  )
}

export default InfoTip
