import React from 'react'

import GooglePreview from '../../../components/PreviewAds/Google/Text'
import { ContainerBlock } from '../../../templates/ReviewPageTemplate/ReviewContent'
import {
  CampaignReviewPageDataType,
  checkIfGoogleCampaignReviewPageDataType,
  checkIfGoogleImageCampaignReviewPageDataType,
  checkIfGoogleTextCampaignReviewPageDataType,
  checkIfLinkedinCampaignReviewPageDataType,
  checkIfLinkedinImageCampaignReviewPageDataType
} from '../../../types/pages/campaignReview'
import { ImageAdPreview } from '../styles'
import Margin from '../../../constants/margin'

export const getAds = (
  reviewData: CampaignReviewPageDataType,
  images: Record<string, string>
): ContainerBlock[] => {
  let newAds: ContainerBlock[] = []

  if (checkIfGoogleCampaignReviewPageDataType(reviewData)) {
    if (checkIfGoogleTextCampaignReviewPageDataType(reviewData)) {
      newAds = reviewData.ads.map((textAd) => ({
        id: textAd.id,
        title: textAd.name,
        container: {
          column1: (
            <GooglePreview
              url={textAd.url}
              title1={textAd.title1}
              title2={textAd.title2}
              title3={textAd.title3}
              description={textAd.description1}
              description2={textAd.description2}
              description3={textAd.description3}
              style={{ margin: `${Margin.m6} auto` }}
            ></GooglePreview>
          )
        }
      }))
    } else if (
      checkIfGoogleImageCampaignReviewPageDataType(reviewData) &&
      Object.keys(images).length > 0
    ) {
      newAds = reviewData.ads.map((imageAd) => ({
        id: imageAd.id,
        title: imageAd.name,
        container: {
          column1: (
            <ImageAdPreview>
              <img
                src={images[imageAd.id]}
                alt={`Image paysage annonce ${imageAd.name}`}
                width="60%"
              />
            </ImageAdPreview>
          )
        }
      }))
    }
  } else if (checkIfLinkedinCampaignReviewPageDataType(reviewData)) {
    if (checkIfLinkedinImageCampaignReviewPageDataType(reviewData)) {
      newAds = reviewData.ads.map((imageAd) => ({
        id: imageAd.id,
        title: imageAd.name,
        container: {
          column1: (
            <ImageAdPreview>
              <img
                src={images[imageAd.id]}
                alt={`Image annonce ${imageAd.name}`}
                width="60%"
              />
            </ImageAdPreview>
          )
        }
      }))
    }
  }

  return newAds
}
