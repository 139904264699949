import React from 'react'

import { IconType } from '../../types/icon'

const LeafIcon = ({ height = '58px', style, color }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      style={{ marginTop: '-3px', ...style }}
      viewBox="0 0 18 22"
    >
      <path
        fill={color}
        d="M 19.231 16.793 a 7.9 7.9 0 0 1 -2.176 -0.457 a 8.158 8.158 0 0 0 -2.981 -7.641 c -2.8 -2.313 -7.067 -0.633 -10.387 -4.149 c -0.773 -0.82 -0.324 9.2 3.832 13 a 7.307 7.307 0 0 0 7.6 1.469 a 4.35 4.35 0 0 0 1.512 -1.324 a 11.464 11.464 0 0 0 2.422 0.555 C 19.622 18.312 19.911 16.9 19.231 16.793 Z m -3.586 0.289 a 18.4 18.4 0 0 1 -7.387 -4.93 a 0.159 0.159 0 0 1 0.215 -0.234 a 23.756 23.756 0 0 0 7.551 4.215 A 1.457 1.457 0 0 1 15.645 17.082 Z"
      />
    </svg>
  )
}
export default LeafIcon
