import { backendURL } from '../../../constants/urls'
import { ViewEnum } from '../../../types/axios/common'
import {
  MediaPlanType,
  checkIfMediaPlanArray,
  checkIfMediaPlanNameOnlyArray
} from '../../../types/mediaPlan/mediaPlan'
import { Status } from '../../../types/status'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetMediaPlansByCompanyOutputType =
  | (MediaPlanType | { id: string; name: string })[]
  | null

export type GetMediaPlansByCompanyInput = {
  companyId: string
  view?: ViewEnum
  status?: Status[]
}

export const getMediaPlansByCompany = async ({
  companyId,
  view,
  status
}: GetMediaPlansByCompanyInput): Promise<GetMediaPlansByCompanyOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/companies/${companyId}/media-plans${
        view ? `?view=${view}` : ''
      }${
        status
          ? view
            ? `&status=${status.join(',')}`
            : `?status=${status.join(',')}`
          : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (view === ViewEnum.NAME) {
        if (checkIfMediaPlanNameOnlyArray(response.data.mediaPlans)) {
          return response.data.mediaPlans
        }
      }
      if (checkIfMediaPlanArray(response.data.mediaPlans)) {
        return response.data.mediaPlans
      }
    }
    return null
  } catch (_) {
    return null
  }
}
