import { backendURL } from '../../../constants/urls'
import { GetMediaPlanCreationDataErrorEnum } from '../../../types/error/mediaPlan/getMediaPlanCreationData'
import {
  MediaPlanNavigation,
  checkMediaPlanNavigation
} from '../../../types/mediaPlan/navigation'
import { isStringArray } from '../../nativeTypes'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetMediaPlanCreationDataOutputType = {
  audienceNames: string[]
  hasCustomerUsers: boolean
  navigation: MediaPlanNavigation
}

export type GetMediaPlanIdCreationDataInput = {
  companyId: string
}

// TODO refacto error management.
// output type should be GetMediaPlanCreationDataOutputType | GetMediaPlanCreationDataErrorEnum
export const getMediaPlanCreationData = async (
  companyId: string
): Promise<
  | GetMediaPlanCreationDataOutputType
  | { error: GetMediaPlanCreationDataErrorEnum }
> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/media-plan-creation/data?customerId=${companyId}&navigation=1`
    })
    if (
      checkIsAxiosGetOutputSuccess(response) &&
      response.data &&
      response.data.audienceNames &&
      response.data.hasCustomerUsers !== undefined &&
      checkMediaPlanNavigation(response.data.navigation)
    ) {
      return {
        audienceNames: response.data.audienceNames,
        hasCustomerUsers: response.data.hasCustomerUsers,
        navigation: response.data.navigation
      }
    }
    return { error: GetMediaPlanCreationDataErrorEnum.NO_RESPONSE }
  } catch (error: any) {
    return {
      error: Object.values(GetMediaPlanCreationDataErrorEnum.UNKNOWN).includes(
        error.data.code
      )
        ? (error.data.code as GetMediaPlanCreationDataErrorEnum)
        : GetMediaPlanCreationDataErrorEnum.UNKNOWN
    }
  }
}

export const checkIfGetMediaPlanCreationDataOutputType = (
  result: any
): result is GetMediaPlanCreationDataOutputType => {
  return (
    result &&
    result.audienceNames &&
    isStringArray(result.audienceNames) &&
    result.hasCustomerUsers !== undefined &&
    checkMediaPlanNavigation(result.navigation)
  )
}
