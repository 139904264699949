import React from 'react'

import { ImageAdCreationFormDataType } from '../types'
import { ImageAdCreationErrorsType } from '../../../helpers/imageAd/formErrors'
import { PanelType } from '../../../types/templates/panels'
import Input from '../../../components/Input'
import { TextInputsContainer } from '../styles'

export type GetDescriptionsPanelInput = {
  formData: ImageAdCreationFormDataType
  handleTextAreaChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  errors: ImageAdCreationErrorsType
  hasBeenSubmitted: boolean
}

export const getDescriptionsPanel = ({
  formData,
  handleTextAreaChange,
  errors,
  hasBeenSubmitted
}: GetDescriptionsPanelInput): PanelType => {
  return {
    title: 'Descriptions',
    disabled: false,
    isDropdown: false,
    description: `Renseignez les descriptions qui s'afficheront sur votre annonce.`,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="textarea"
              onChange={(e) => {
                handleTextAreaChange('description1', e)
              }}
              placeholder="Description 1"
              label={`Description 1`}
              value={formData.description1}
              maxLength={90}
              showCharacterCount
              error={hasBeenSubmitted ? errors.description1 : ''}
              withBorder
            />
            <Input
              type="textarea"
              onChange={(e) => {
                handleTextAreaChange('description2', e)
              }}
              placeholder="Description 2"
              label={`Description 2`}
              value={formData.description2}
              maxLength={90}
              showCharacterCount
              isRequired={false}
              error={hasBeenSubmitted ? errors.description2 : ''}
              withBorder
            />
            <Input
              type="textarea"
              onChange={(e) => {
                handleTextAreaChange('description3', e)
              }}
              placeholder="Description 3"
              label={`Description 3`}
              value={formData.description3}
              maxLength={90}
              showCharacterCount
              isRequired={false}
              error={hasBeenSubmitted ? errors.description3 : ''}
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }
}
