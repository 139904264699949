import React from 'react'

import { CaptionRectangle, CaptionsContainer } from './styles'
import { DatasetsSelection, LineChartData, Point } from '../LineChartDrawer'
import DefaultText from '../../../DefaultText'

type PropsType = {
  data: LineChartData<Point>
  selectedDatasets: DatasetsSelection
}

export const LineChartCaptions = ({ data, selectedDatasets }: PropsType) => {
  return (
    <CaptionsContainer>
      {data.map((d) => {
        if (
          selectedDatasets.find((el) => el.name === d.name && el.isSelected) &&
          d.caption
        ) {
          return (
            <DefaultText
              key={Math.random()}
              size="small"
              style={{ display: 'flex', alignItems: 'center', gap: '7px' }}
            >
              <CaptionRectangle color={d.color} />: {d.caption}
            </DefaultText>
          )
        } else {
          return ''
        }
      })}
    </CaptionsContainer>
  )
}

export default LineChartCaptions
