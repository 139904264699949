import React from 'react'

import ReviewPageTemplate from '../../templates/ReviewPageTemplate'
import {
  ContainerBlock,
  Container
} from '../../templates/ReviewPageTemplate/ReviewContent'
import { getFormatEnum } from '../../types/format'
import { checkIfGoogleCampaignReviewPageDataType } from '../../types/pages/campaignReview'
import Breadcrumb from '../../components/BreadCrumb'
import { StrategyEnum } from '../../types/strategy'
import LabelledItemsList from '../../components/LabelledItemsList'
import { getAds } from './helpers/getAds'
import { AdvertiserCampaignReviewProps } from './types/advertiser'
import { PlatformAPIEnum } from '../../types/platform'

const AdvertiserCampaignReview = ({
  reviewData,
  startDateCampaign,
  endDateCampaign,
  images,
  setIsCO2ModalOpen,
  navigate,
  handleViewMore
}: AdvertiserCampaignReviewProps) => {
  const container: Container = {
    column1: (
      <LabelledItemsList
        items={[
          {
            label: 'Budget total ',
            value: `${reviewData.campaign.budget.toString()}` + ` €`
          },
          {
            label: 'Période ',
            value: `${startDateCampaign} au ${endDateCampaign}`
          },
          { label: 'Objectif ', value: reviewData.campaign.goal.toString() },
          {
            label: 'Plateforme',
            value: reviewData.campaign.platform
          },
          {
            label: 'Format ',
            value: getFormatEnum(reviewData.campaign.format)
          }
          // ...(checkIfGoogleCampaignReviewPageDataType(reviewData)
          //   ? [{ label: 'Enchères ', value: reviewData.campaign.bidStrategy }]
          //   : [])
        ]}
      />
    ),
    items: getAds(reviewData, images)
  }

  const containerBlock: ContainerBlock = {
    title: reviewData.campaign.name,
    container,
    platform: reviewData.campaign.platform
  }

  const validation: {
    label: string
    action: () => void
    disabled: boolean
    type?: 'primary' | 'secondary'
  } = {
    label: 'Voir le plan média',
    action: () => {
      navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
    },
    disabled: false,
    type: reviewData.ads.length > 0 ? 'primary' : 'secondary'
  }
  const items = [
    {
      label: 'Plan média'
    },
    {
      onClick: () => {
        navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
      },
      label: reviewData.navigation.mediaPlan.name
    },
    {
      label: `Campagne « ${reviewData.navigation.campaign.name} »`
    }
  ]

  const adCount = reviewData.ads.length

  return (
    <>
      <Breadcrumb items={items} />
      <ReviewPageTemplate
        name={reviewData.campaign.name}
        title={'Revue de la campagne'}
        type={StrategyEnum.CAMPAIGN}
        validation={validation}
        container={containerBlock}
        handleViewMore={handleViewMore}
        isEditor={false}
        format={reviewData.campaign.format}
        co2={reviewData.campaign.co2}
        co2Header={reviewData.campaign.platform === PlatformAPIEnum.GOOGLE}
        adCount={adCount}
        handleOpenModal={() => {
          setIsCO2ModalOpen(true)
        }}
      />
    </>
  )
}

export default AdvertiserCampaignReview
