import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PopupContent,
  PopupMenuContainer,
  PopupMenuContentList,
  PopupMenuContentTitle,
  Separator,
  SeeAll,
  PopupMenuContentListLi,
  PopupMenuContentListSpanLabel,
  PopupMenuContentListSpanArrow,
  NoResultContainer
} from './styles'
import { SectionId, SelectedItemsId } from '..'
import DefaultText from '../../../../components/DefaultText'
import Button from '../../../../components/Button'

export type Item = {
  id?: string
  title: string
  action?: () => void
  selected?: boolean
}

export type Section = {
  items: Item[]
  id: SectionId
  title: string
  fetchData?: (id: string) => Promise<void>
}

export type MenuProps = {
  isSeeAllDisplayed: boolean
  sections: Section[]
  isOpen: boolean
  updateMenuProps: (value: boolean) => void
  closeMenu: () => void
  selectedItemsId: SelectedItemsId
  setSelectedItems: React.Dispatch<React.SetStateAction<SelectedItemsId>>
  isCustomerMenuOpened: boolean
  isAddMenuOpened: boolean
  setCampaignCreationModalMediaPlanId: (id: string) => void
  isMediaPlansMenuOpened: boolean
  isEditor: boolean
  isSelfEditor: boolean
  companyId: string
}

const editorCreateNewLabels = {
  campaign: 'Nouvelle campagne',
  customers: 'Nouveau client',
  ad: 'Nouvelle annonce',
  mediaPlan: 'Nouveau plan média',
  new: null
}

const selfEditorCreateNewLabels = {
  campaign: 'Nouvelle campagne',
  customers: null,
  ad: 'Nouvelle publicité',
  mediaPlan: 'Nouveau plan publicitaire',
  new: null
}

const PopupMenu = ({
  sections,
  isOpen,
  closeMenu,
  isSeeAllDisplayed,
  selectedItemsId,
  setSelectedItems,
  isCustomerMenuOpened,
  isAddMenuOpened,
  isMediaPlansMenuOpened,
  setCampaignCreationModalMediaPlanId,
  isEditor,
  isSelfEditor,
  companyId
}: MenuProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target as Node)
      ) {
        closeMenu()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, closeMenu])

  const seeAllRoutes = isEditor
    ? {
        customers: '/customers',
        new: null,
        mediaPlan: `/customer/${selectedItemsId.customers}/details`,
        campaign: `/media-plan/${selectedItemsId.mediaPlan}/details`,
        ad: `/campaign/${selectedItemsId.campaign}/details`
      }
    : {
        customers: null,
        new: null,
        mediaPlan: `/customer/${companyId}/details`,
        campaign: `/media-plan/${selectedItemsId.mediaPlan}/details`,
        ad: `/campaign/${selectedItemsId.campaign}/details`
      }

  const createNewActions = isEditor
    ? {
        customers: () => {
          navigate('/customer/create')
        },
        mediaPlan: () => {
          navigate(`/company/${selectedItemsId.customers}/media-plan/create`)
        },
        campaign: () => {
          setCampaignCreationModalMediaPlanId(selectedItemsId.mediaPlan)
        },
        new: null,
        ad: null
      }
    : {
        customers: null,
        mediaPlan: () => {
          navigate(`/company/${companyId}/media-plan/create`)
        },
        campaign: () => {
          setCampaignCreationModalMediaPlanId(selectedItemsId.mediaPlan)
        },
        new: null,
        ad: null
      }

  const handleOnItemClick = (item: Item, section: Section) => {
    if (item.id && section.fetchData) {
      section.fetchData(item.id)

      const newSelectedItemsId: SelectedItemsId = { ...selectedItemsId }
      newSelectedItemsId[section.id] = item.id

      const indexOfClickedSection = Object.keys(newSelectedItemsId).indexOf(
        section.id
      )
      Object.keys(newSelectedItemsId).forEach((key, index) => {
        if (index > indexOfClickedSection) {
          newSelectedItemsId[key as keyof SelectedItemsId] = ''
        }
      })

      setSelectedItems(newSelectedItemsId)
    } else if (item.action) {
      item.action()
    }
  }

  const handleMenuLeave = () => {
    closeMenu()
  }

  const handleOnLabelClick = (item: Item, section: Section) => {
    if ((isCustomerMenuOpened || isMediaPlansMenuOpened) && item.action) {
      closeMenu()
      item.action()
    }
  }

  return (
    <PopupMenuContainer
      isOpen={isOpen}
      ref={parentRef}
      onMouseDown={handleMenuLeave}
    >
      {sections.map((section, index) => (
        <PopupContent
          key={section.title}
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
        >
          <PopupMenuContentTitle>{section.title}</PopupMenuContentTitle>
          {section.items.length < 1 ? (
            <NoResultContainer>
              <DefaultText>Aucun résultat</DefaultText>
              <Button
                type="tertiary"
                width="100%"
                onClick={() => {
                  createNewActions[section.id]?.()
                  closeMenu()
                }}
              >
                {isEditor
                  ? editorCreateNewLabels[section.id]
                  : isSelfEditor
                  ? selfEditorCreateNewLabels[section.id]
                  : ''}
              </Button>
            </NoResultContainer>
          ) : (
            <PopupMenuContentList scrollable={isAddMenuOpened}>
              {section.items.map((item) => {
                return (
                  <PopupMenuContentListLi
                    key={`${section.title}-${item.title}`}
                    isSelected={selectedItemsId[section.id] === item.id}
                    onClick={() => {
                      handleOnItemClick(item, section)
                    }}
                  >
                    <PopupMenuContentListSpanLabel
                      onClick={() => {
                        handleOnLabelClick(item, section)
                      }}
                      clickable={isCustomerMenuOpened || isMediaPlansMenuOpened}
                    >
                      {item.title}
                    </PopupMenuContentListSpanLabel>
                    {item.id && section.fetchData && (
                      <PopupMenuContentListSpanArrow>
                        ▸
                      </PopupMenuContentListSpanArrow>
                    )}
                  </PopupMenuContentListLi>
                )
              })}
            </PopupMenuContentList>
          )}

          {isSeeAllDisplayed && (
            <>
              <Separator />
              <SeeAll
                route={seeAllRoutes[section.id]}
                onClick={() => {
                  const route = seeAllRoutes[section.id]
                  route != null && navigate(route)
                  closeMenu()
                }}
              >
                Voir tout
              </SeeAll>
            </>
          )}
        </PopupContent>
      ))}
    </PopupMenuContainer>
  )
}

export default PopupMenu
