export enum GetCampaignReportingErrorEnum {
  CAMPAIGN_ID = 'CAMPAIGN_ID',
  UNKNOWN = 'UNKNOWN',
  AUTH_FAILED = 'AUTH_FAILED',
  INVALID_DATE = 'INVALID_DATE',
  MISSING_SECTION = 'MISSING_SECTION',
  WRONG_SECTION = 'WRONG_SECTION'
}

export const GetCampaignReportingErrorEnumTranslation: Record<
  GetCampaignReportingErrorEnum,
  string
> = {
  [GetCampaignReportingErrorEnum.CAMPAIGN_ID]: "L'id de la campagne est requis",
  [GetCampaignReportingErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [GetCampaignReportingErrorEnum.AUTH_FAILED]: "L'authentification a échoué",
  [GetCampaignReportingErrorEnum.INVALID_DATE]:
    'Les dates doivent être au format YYYY-MM-DD',
  [GetCampaignReportingErrorEnum.MISSING_SECTION]: 'La section est requise',
  [GetCampaignReportingErrorEnum.WRONG_SECTION]:
    'La section est requise et doit être correctement formatée'
}
