import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'
import FontSize from '../../../constants/fontSize'
import Padding from '../../../constants/padding'

export const DivAuth = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1000px) {
    display: block;
    overflow: auto;
  }
`

export const DivBand = styled.div`
  background-color: ${Colors.NEW_YETIC_BLUE_DARK};
  width: 500px;
  padding: ${Margin.m11};
  position: relative;

  @media (max-width: 1000px) {
    display: none;
  }
`

export const logoStyle: CSSProperties = {
  width: '170px',
  marginBottom: Margin.m9
}

export const spanStyle: CSSProperties = {
  color: Colors.NEW_YETIC_GREEN
}

export const titleStyle: CSSProperties = {
  color: Colors.YETIC_WHITE,
  fontSize: '30px',
  marginBottom: Margin.m9
}

export const listItemStyle: CSSProperties = {
  color: Colors.YETIC_WHITE,
  marginLeft: Margin.m4,
  fontSize: FontSize.YETIC_TITLE_THIN
}

export const CheckList = styled.div``

export const CheckListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Margin.m4};
`

export const DivContainer = styled.div`
  text-align: center;
  padding: ${Padding.p11} 0px;

  @media (max-width: 1000px) {
    padding: 20px 10px;
  }
`

export const DivForm = styled.div`
  background-color: ${Colors.NEW_YETIC_GREY};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #8893a4;
  width: 600px;
  position: relative;
  z-index: 1;

  @media (max-width: 1000px) {
    width: 100%;
    margin: auto;
  }
`

export const DivTitle = styled.div`
  margin: auto;
  margin-top: ${Margin.m8};
`

export const titleTextStyle: CSSProperties = {
  fontSize: FontSize.YETIC_TITLE,
  fontWeight: 600
}

export const DivInputs = styled.div`
  margin: ${Margin.m6} ${Margin.m10};
`

export const Yeti = styled.img`
  position: absolute;
  bottom: 0;
  right: -120px;
`

export const AroundForm = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 100px 250px;
  display: flex;
  justify-content: center;
  margin-bottom: ${Margin.m7};

  @media (max-width: 1000px) {
    padding: 50px 0;
    display: block;
  }
`

export const SeparationBar = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.YETIC_GREY_DARK};
  margin: ${Margin.m8} 0;
`

export const LoginRegisterLink = styled.div`
  color: ${Colors.NEW_YETIC_BLUE_DARK};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  cursor: pointer;
  margin-bottom: ${Margin.m9};

  &:hover {
    text-decoration: underline;
  }
`
