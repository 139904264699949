import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import FontSize from '../../../constants/fontSize'
import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'
import { AlignEnum } from '../../../types/common'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m8};
  width: 100%;
  margin-bottom: ${Margin.m4};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: ${Padding.p3};
  border-bottom: 1px solid black;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${FontSize.YETIC_HOME_BOX_TITLE};
  box-shadow: 0px 0px 2px grey;
  border-radius: 10px;
  overflow: hidden;
`

export const TableHeader = styled.div`
  display: flex;
  padding-top: ${Padding.p5};
  padding-bottom: ${Padding.p6};
  box-shadow: 0px 0px 2px grey;
  background-color: ${Colors.YETIC_WHITE};
`

export const TableHeaderItem = styled.div<{
  width: string
  align?: AlignEnum
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  color: ${Colors.YETIC_BLUE_DARK};
  padding: 0 25px 0 20px;
  box-sizing: border-box;
  width: ${(props) => props.width};
  justify-content: ${({ align }) =>
    align === AlignEnum.LEFT
      ? 'flex-start'
      : align === AlignEnum.CENTER
      ? 'center'
      : 'flex-end'};
`

export const tableHeaderTextStyle: CSSProperties = {
  marginBottom: 0,
  fontSize: FontSize.YETIC_HOME_BOX_TITLE
}

export const TableRowsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  tablelist-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
`

export const TableRow = styled.li<{ isClickable?: boolean }>`
  box-shadow: 0px 0px 2px grey;
  position: relative;
  overflow: hidden;
  height: 54px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  background-color: ${Colors.YETIC_WHITE};

  :hover {
    background-color: ${Colors.NEW_YETIC_GREY_LIGHT};
  }
`

export const RowContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const RowItem = styled.div<{
  index: number
  bold?: boolean
  width: string
  align?: AlignEnum
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  width: ${(props) => props.width};
  height: 100%;
  box-sizing: border-box;
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  padding: 0 20px;
  ${({ index }) => index !== 1 && `border-left: 1px solid #d7d7d7;`}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  justify-content: ${({ align }) =>
    align === AlignEnum.LEFT
      ? 'flex-start'
      : align === AlignEnum.CENTER
      ? 'center'
      : 'flex-end'};
`

export const NoResult = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.span`
  display: inline-block;
  margin-left: auto;
`

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const actionButtonSize = 30

export const sortIconStyle: CSSProperties = {
  cursor: 'pointer'
}

export const downloadIcon: CSSProperties = {
  marginLeft: Margin.m4
}
