import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import Margin from '../../../../../../constants/margin'
import Padding from '../../../../../../constants/padding'

export const LineChartTooltipContainer = styled.div`
  position: absolute;
  background-color: ${Colors.YETIC_WHITE};
  border-radius: 10px;
  padding: ${Padding.p6} ${Padding.p5};
  box-shadow: 0px 0px 5px #142a4c80;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m3};
`

export const LineChartTooltipTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
`

export const LineChartTooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Margin.m7};
`

export const LineChartTooltipItemLegend = styled.div`
  display: flex;
  align-items: center;
`

export const LineChartTooltipItemColor = styled.div<{ color: string }>`
  height: 5px;
  width: 10px;
  background-color: ${({ color }) => color};
  margin-right: ${Margin.m3};
`

export const LineChartTooltipItemName = styled.div`
  font-size: 14px;
`

export const LineChartTooltipItemValue = styled.div`
  font-size: 14px;
  font-weight: 600;
`

export const LineChartTooltipItemPercentage = styled.div`
  font-size: 10px;
  font-weight: 600;
`

export const LineChartTooltipItemDataContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: end;
`
