import { backendURL } from '../../../constants/urls'
import { axiosPost, checkIsAxiosPostOutputSuccess } from '../axios/post'

export type CreateAdOutputType = {
  adId: string
  error?: string
}

export type CreateAdInputType = {
  campaignId: string
  name: string
  url: string
  title1: string
  title2: string
  title3: string
  title4?: string
  title5?: string
  title6?: string
  title7?: string
  title8?: string
  description1: string
  description2: string
  description3?: string
  siteLinks: {
    title: string
    url: string
    description1?: string
    description2?: string
  }[]
  catchphrases: string[]
}

export const createAd = async ({
  campaignId,
  name,
  url,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  title8,
  description1,
  description2,
  description3,
  siteLinks,
  catchphrases
}: CreateAdInputType): Promise<CreateAdOutputType> => {
  const response = await axiosPost({
    url: `${backendURL}/ads/text`,
    data: {
      campaignId,
      name,
      url,
      title1,
      title2,
      title3,
      title4,
      title5,
      title6,
      title7,
      title8,
      description1,
      description2,
      description3,
      siteLinks,
      catchphrases
    }
  })
  if (checkIsAxiosPostOutputSuccess(response)) {
    if (response.data.adId) {
      return {
        adId: response.data.adId
      }
    } else {
      return {
        adId: '',
        error: 'UNKNOWN'
      }
    }
  } else {
    return {
      adId: '',
      error: (response.error as any).data.code
    }
  }
}
