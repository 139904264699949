import React from 'react'

import Title from '../Title'
import Modal from '../Modal'
import {
  MediaPlanAudience,
  checkIfFullAudience,
  checkIfGoogleAudience,
  checkIfLinkedinAudience
} from '../../types/mediaPlanAudience'
import { DivLabelledItems, modalStyle } from './styles'
import LabelledItem from '../LabelledItem'
import { GenderTranslation } from '../../types/gender'

type ModalType = {
  handleClose?: () => void
  title: string
  data: MediaPlanAudience
}

const ModalAudienceDetails = ({ handleClose, title, data }: ModalType) => {
  const isGoogleAndLinkedinAudience = checkIfFullAudience(data)
  const isGoogleAudience = checkIfGoogleAudience(data)
  const isLinkedinAudience = checkIfLinkedinAudience(data)

  return (
    <Modal onClose={handleClose} style={modalStyle}>
      <Title type="subTitle">{`${title} "${data.name}"`}</Title>
      <DivLabelledItems style={{ textAlign: 'left' }}>
        {(isGoogleAndLinkedinAudience || isGoogleAudience) && (
          <>
            <LabelledItem
              label="Genre"
              value={GenderTranslation[data.gender]}
            />
            <LabelledItem
              label="Tranche d'âge"
              value={`De ${data.minAge} à ${data.maxAge} ans`}
            />
          </>
        )}
        {data.locations.length > 0 && (
          <LabelledItem
            label={`Zones géographiques`}
            value={data.locations.map((location) => location.name).join('\n')}
            isNotFlex
          />
        )}
        {(isGoogleAndLinkedinAudience || isGoogleAudience) &&
          data.interests &&
          data.interests.length > 0 && (
            <LabelledItem
              label={`Centres d'intérêts`}
              value={data.interests.join('\n')}
              isNotFlex
            />
          )}
        {(isGoogleAndLinkedinAudience || isLinkedinAudience) &&
          data.businessSegments &&
          data.businessSegments.length > 0 && (
            <LabelledItem
              label={`Secteurs d'activité`}
              value={data.businessSegments.join('\n')}
              isNotFlex
            />
          )}
        {(isGoogleAndLinkedinAudience || isLinkedinAudience) &&
          data.jobFunctions &&
          data.jobFunctions.length > 0 && (
            <LabelledItem
              label={`Fonctions professionnelles`}
              value={data.jobFunctions.join('\n')}
              isNotFlex
            />
          )}
        {(isGoogleAndLinkedinAudience || isLinkedinAudience) &&
          data.jobTitles &&
          data.jobTitles.length > 0 && (
            <LabelledItem
              label={`Intitulés de poste`}
              value={data.jobTitles.join('\n')}
              isNotFlex
            />
          )}
      </DivLabelledItems>
    </Modal>
  )
}

export default ModalAudienceDetails
