import React from 'react'

import TipsFormat from '../../../../../components/Tips/TipsFormat'
import {
  linkedinMaxSize,
  linkedinMaxWidth,
  linkedinMinWidth,
  linkedinRectangleRatio,
  linkedinSquareRatio
} from '../../../../../constants/image'

const ImagesTip = () => {
  return (
    <TipsFormat
      title={`Quels formats et résolution d'images ?`}
      items={[
        {
          title: 'Taille de fichier',
          content: [
            `La taille de fichier maximale autorisée est de ${
              linkedinMaxSize / 1000000
            } Mo.`
          ]
        },
        {
          title: `Format paysage (${linkedinRectangleRatio}:1)`,
          content: [
            'Taille recommandée : 1200 x 628',
            `Taille minimale : ${linkedinMinWidth} x ${Math.ceil(
              linkedinMinWidth / 1.91
            )}`,
            `Taille maximale : ${linkedinMaxWidth} x ${Math.floor(
              linkedinMaxWidth / 1.91
            )}`
          ]
        },
        {
          title: `Format carré (${linkedinSquareRatio}:1)`,
          content: [
            'Taille recommandée : 1200 x 1200',
            `Taille minimale : ${linkedinMinWidth} x ${linkedinMinWidth}`,
            `Taille maximale : ${linkedinMaxWidth} x ${linkedinMaxWidth}`
          ]
        }
      ]}
    />
  )
}

export default ImagesTip
