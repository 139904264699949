import React, { useState } from 'react'

import { AdsTabContainer } from './styles'
import { formatAdsData } from './helpers/formatData'
import { GetCampaignAdsReportingOutputType } from '../../../../types/reporting/campaign/output'
import { TableContainer } from '../../styles'
import Table, { ColumnItem } from '../../../../components/Table'
import { AlignEnum, SortEnum } from '../../../../types/common'

type AdsTabProps = {
  reportingData: GetCampaignAdsReportingOutputType
}

const AdsTab = ({ reportingData }: AdsTabProps) => {
  const { tableData } = formatAdsData(reportingData)
  const [tablePage, setTablePage] = useState(1)

  const tableColumns: ColumnItem[] = [
    {
      name: 'adName',
      label: "Nom de l'annonce",
      isSortable: true,
      width: '60%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '20%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '20%'
    }
  ]

  const defaultTableSort = {
    name: 'adName',
    order: SortEnum.ASC
  }

  return (
    <AdsTabContainer>
      <TableContainer>
        <Table
          title="Clics et impressions par annonce"
          columns={tableColumns}
          elements={tableData}
          sort={defaultTableSort}
          pagination={{
            totalItems: tableData.length,
            itemsByPage: 10,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
        />
      </TableContainer>
    </AdsTabContainer>
  )
}

export default AdsTab
