import React from 'react'

import { DevicesTabContainer } from './styles'
import { formatDevicesData } from './helpers/formatData'
import { TableContainer, chartGap } from '../../styles'
import { GetCampaignDevicesReportingOutputType } from '../../../../types/reporting/campaign/output'
import BarChart from '../../../../components/Chart/BarChart'
import Table from '../../../../components/Table'
import { AlignEnum, SortEnum } from '../../../../types/common'

type DevicesTabProps = {
  reportingData: GetCampaignDevicesReportingOutputType
  contentWidth: number
}

const DevicesTab = ({ reportingData, contentWidth }: DevicesTabProps) => {
  const { devicesBarChart, devicesTableData } = formatDevicesData(reportingData)

  const tableColumns = [
    {
      name: 'device',
      label: 'Appareil',
      isSortable: true,
      width: '28%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'cost',
      label: 'Coût',
      unit: '€',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'co2',
      label: 'CO₂',
      unit: 'g',
      isSortable: true,
      width: '18%'
    }
  ]

  const defaultTableSort = {
    name: 'clicks',
    order: SortEnum.DESC
  }

  const chartWidth =
    screen.width > 1300 ? (contentWidth - chartGap * 2) / 2 : contentWidth

  return (
    <DevicesTabContainer>
      <BarChart
        data={devicesBarChart}
        title="Performances des annonces par appareil"
        width={chartWidth}
        horizontal
      />
      <TableContainer>
        <Table
          title="Vue d'ensemble des performances par appareil"
          columns={tableColumns}
          elements={devicesTableData}
          sort={defaultTableSort}
          download
        />
      </TableContainer>
    </DevicesTabContainer>
  )
}

export default DevicesTab
