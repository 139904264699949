import React from 'react'

import { NavigateFunction } from 'react-router-dom'
import Summary, { SummaryProps } from './Summary'
import { DetailsPageTemplateContainer, Page } from './styles'
import Breadcrumb, { BreadcrumbProps } from '../../components/BreadCrumb'
import AuthenticatedTemplate from '../AuthenticatedTemplate'

type DetailsPageTemplateProps = {
  breadcrumbItems: BreadcrumbProps['items']
  isEditor: boolean
  summary: SummaryProps
  children?: JSX.Element
  navigate: NavigateFunction
}

const DetailsPageTemplate = ({
  breadcrumbItems,
  isEditor,
  summary,
  children,
  navigate
}: DetailsPageTemplateProps) => {
  return (
    <AuthenticatedTemplate isEditor={isEditor} navigate={navigate}>
      <div>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <DetailsPageTemplateContainer>
        <Summary {...summary} />
        <>{children}</>
      </DetailsPageTemplateContainer>
    </AuthenticatedTemplate>
  )
}

export default DetailsPageTemplate
