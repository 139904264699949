export enum PauseCampaignErrorEnum {
  MISSING_CAMPAIGN_ID = 'MISSING_CAMPAIGN_ID',
  AUTH_FAILED = 'AUTH_FAILED',
  NOT_FOUND = 'NOT_FOUND',
  USER_NOT_MATCHING = 'USER_NOT_MATCHING',
  CAMPAIGN_STATUS = 'CAMPAIGN_STATUS',
  GOOGLE_API_ERROR = 'GOOGLE_API_ERROR',
  UNKNOWN = 'UNKNOWN'
}

export const PauseCampaignErrorTranslation = {
  [PauseCampaignErrorEnum.MISSING_CAMPAIGN_ID]:
    "L'identifiant de la campagne n'a pas été reçu",
  [PauseCampaignErrorEnum.AUTH_FAILED]: "L'authentification a échoué",
  [PauseCampaignErrorEnum.NOT_FOUND]: 'La campagne n’a pas été trouvée',
  [PauseCampaignErrorEnum.USER_NOT_MATCHING]:
    'L’utilisateur ne correspond pas à la campagne',
  [PauseCampaignErrorEnum.CAMPAIGN_STATUS]:
    'Le statut de la campagne n’est pas en cours',
  [PauseCampaignErrorEnum.GOOGLE_API_ERROR]:
    'Une erreur est survenue avec l’API Google',
  [PauseCampaignErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue'
}

export const checkIfPauseCampaignErrorCodeValid = (
  errorCode: string
): errorCode is PauseCampaignErrorEnum => {
  return Object.keys(PauseCampaignErrorEnum).includes(errorCode)
}

export enum RestartCampaignErrorEnum {
  MISSING_CAMPAIGN_ID = 'MISSING_CAMPAIGN_ID',
  AUTH_FAILED = 'AUTH_FAILED',
  NOT_FOUND = 'NOT_FOUND',
  USER_NOT_MATCHING = 'USER_NOT_MATCHING',
  CAMPAIGN_STATUS = 'CAMPAIGN_STATUS',
  GOOGLE_API_ERROR = 'GOOGLE_API_ERROR',
  UNKNOWN = 'UNKNOWN'
}

export const RestartCampaignErrorTranslation = {
  [RestartCampaignErrorEnum.MISSING_CAMPAIGN_ID]:
    "L'identifiant de la campagne n'a pas été reçu",
  [RestartCampaignErrorEnum.AUTH_FAILED]: "L'authentification a échoué",
  [RestartCampaignErrorEnum.NOT_FOUND]: 'La campagne n’a pas été trouvée',
  [RestartCampaignErrorEnum.USER_NOT_MATCHING]:
    'L’utilisateur ne correspond pas à la campagne',
  [RestartCampaignErrorEnum.CAMPAIGN_STATUS]:
    'Le statut de la campagne n’est pas en pause',
  [RestartCampaignErrorEnum.GOOGLE_API_ERROR]:
    'Une erreur est survenue avec l’API Google',
  [RestartCampaignErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue'
}

export const checkIfRestartCampaignErrorCodeValid = (
  errorCode: string
): errorCode is RestartCampaignErrorEnum => {
  return Object.keys(RestartCampaignErrorEnum).includes(errorCode)
}
