import styled from '@emotion/styled'
import FontSize from '../../../constants/fontSize'
import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${Padding.p5} ${Padding.p6} ${Padding.p0} ${Padding.p6};
`

export const BreadcrumbItem = styled.span<{ onClick?: () => void }>`
  font-size: ${FontSize.YETIC_TEXT};

  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    text-decoration: ${(props) => (props.onClick ? 'underline' : 'none')};
  }
`

export const BreadcrumbSeparator = styled.span`
  margin: 0 ${Margin.m3};
  color: gray;
  display: flex;
`
