import styled from '@emotion/styled'

import Margin from '../../../../../constants/margin'
import Padding from '../../../../../constants/padding'

export const AdContainer = styled.div<{ scale: number }>`
  background-color: white;
  border: 1px solid #dddfe2;
  border-radius: 8px;
  padding: ${Padding.p5};
  box-sizing: border-box;
  width: 600px;
  min-height: 130px;
  font-family: Arial, sans-serif;
  margin: 0px auto;
  transform: scale(${(props) => props.scale});
`

export const Sponsorised = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  color: black;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
`

export const DivHead = styled.div`
  display: flex;
  align-items: center;
`

export const DivImg = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.h2`
  font-size: 20px;
  line-height: 26px;
  color: #1a0dab;
  margin: 0;
  font-weight: 500;
  margin-top: ${Margin.m3};
`

export const Name = styled.h3`
  font-size: 14px;
  line-height: 20px;
  color: black;
  font-weight: 400;
  font-family: Arial, sans-serif;
  white-space: nowrap;
  margin: 0;
`

export const Description = styled.div`
  font-size: 14px;
  margin: 8px 0;
  color: #4d5156;
  font-family: Arial, sans-serif;
  font-weight: 500;
`

export const Url = styled.a`
  color: #4d5156;
  font-size: 12px;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 500;
`
