import {
  AdNavigation,
  AdType,
  checkAdNavigation,
  checkIfAdArray
} from '../../ad/ad'
import { FullLinkedinImageAdType } from '../../ad/linkedin/imageAd'
import {
  LinkedinCampaignType,
  checkIfLinkedinCampaign
} from '../linkedin/linkedinCampaign'

// TODO : add video ad type (when it will be available)
export type LinkedinCampaignReviewApiType = LinkedinImageCampaignReviewApiType

export type LinkedinImageCampaignReviewApiType = {
  campaign: LinkedinCampaignType & { co2: number }
  ads: FullLinkedinImageAdType[]
  navigation: AdNavigation
}

export const checkIfLinkedinCampaignReviewApi = (
  value: any
): value is LinkedinCampaignReviewApiType => {
  return (
    value.campaign &&
    checkIfLinkedinCampaign(value.campaign) &&
    value.campaign.co2 !== undefined &&
    typeof value.campaign.co2 === 'number' &&
    checkIfAdArray(value.ads) &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfLinkedinImageCampaignReviewApi = (
  value: any
): value is LinkedinImageCampaignReviewApiType => {
  return (
    value.campaign &&
    checkIfLinkedinCampaign(value.campaign) &&
    value.campaign.co2 !== undefined &&
    typeof value.campaign.co2 === 'number' &&
    // TODO : change AdType checker to LinkedinImageAdType checker (detailed image ad)
    // checkIfLinkedinImageAdArray(value.ads) &&
    checkIfAdArray(value.ads) &&
    checkAdNavigation(value.navigation)
  )
}
