import React from 'react'

import DefaultText from '../../../../components/DefaultText'
import Button from '../../../../components/Button'

type MessageButtonProps = {
  message: string
  buttonLabel: string
  buttonAction: () => void
}

export const MessageButton = ({
  message,
  buttonLabel,
  buttonAction
}: MessageButtonProps) => {
  return (
    <>
      <DefaultText size="big" style={{ textAlign: 'center' }}>
        {message}
      </DefaultText>
      <Button
        width="fit-content"
        style={{ padding: '0 30px' }}
        type="primary"
        onClick={buttonAction}
      >
        {buttonLabel}
      </Button>
    </>
  )
}
