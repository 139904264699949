export type WidgetPageContentType = {
  title: string
  images: string[]
  paragraphs: string[]
  cta: {
    label: string
    actionId: ActionIdEnum
  } | null
}

export type WidgetPageType = {
  page: {
    id: string
    title: string
    nav: {
      label: string
      icon: string
      redirectId: string
      comingSoon?: boolean
    }[]
    elements: {
      faq: {
        question: string
        answer: string
      }[]
      content: WidgetPageContentType[]
    }
  }
}

export enum ActionIdEnum {
  NAVIGATE_HOME = 'navigateHome'
}

export const checkIfActionIdEnum = (val: any): val is ActionIdEnum => {
  return Object.values(ActionIdEnum).includes(val)
}

export const checkIfWidgetPageType = (obj: any): obj is WidgetPageType => {
  if (!obj || typeof obj !== 'object') {
    return false
  }

  return (
    (obj.page &&
      typeof obj.page === 'object' &&
      typeof obj.page.id === 'string' &&
      typeof obj.page.title === 'string' &&
      Array.isArray(obj.page.nav) &&
      obj.page.nav.every(
        (nav: any) =>
          typeof nav === 'object' &&
          typeof nav.label === 'string' &&
          typeof nav.icon === 'string' &&
          typeof nav.redirectId === 'string' &&
          (nav.comingSoon === undefined || typeof nav.comingSoon === 'boolean')
      ) &&
      obj.page.elements &&
      typeof obj.page.elements === 'object' &&
      Array.isArray(obj.page.elements.faq) &&
      obj.page.elements.faq.every(
        (faq: any) =>
          typeof faq === 'object' &&
          typeof faq.question === 'string' &&
          typeof faq.answer === 'string'
      ) &&
      Array.isArray(obj.page.elements.content) &&
      obj.page.elements.content.every(
        (content: any) =>
          typeof content === 'object' &&
          typeof content.title === 'string' &&
          Array.isArray(content.images) &&
          content.images.every((img: any) => typeof img === 'string') &&
          Array.isArray(content.paragraphs) &&
          content.paragraphs.every(
            (paragraph: any) => typeof paragraph === 'string'
          ) &&
          (content.cta === null ||
            (typeof content.cta === 'object' &&
              typeof content.cta.label === 'string' &&
              checkIfActionIdEnum(content.cta.actionId)))
      )) ||
    obj.page === null
  )
}
