import axios from 'axios'
import { AxiosResponseType } from '../../../types/axios/common'

export type AxiosDeleteType = {
  url: string
  // eslint-disable-next-line
}

type AxiosDeleteOutputSuccessType = {
  type: AxiosResponseType.SUCCESS
  data: any
}

type AxiosDeleteOutputErrorType = {
  type: AxiosResponseType.ERROR
  error: string
}

export const checkIsAxiosDeleteOutputSuccess = (
  value: any
): value is AxiosDeleteOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === AxiosResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export type AxiosDeleteOutputType =
  | AxiosDeleteOutputSuccessType
  | AxiosDeleteOutputErrorType

export const axiosDelete = async ({
  url
}: AxiosDeleteType): Promise<AxiosDeleteOutputType> => {
  try {
    const response = await axios.delete(url, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${token}`
        // using withCredentials seems more adapted
      }
    })
    // console.log(response);
    if (
      checkIsAxiosDeleteOutputSuccess({
        type: AxiosResponseType.SUCCESS,
        data: response.data
      })
    ) {
      return {
        type: AxiosResponseType.SUCCESS,
        data: response.data
      }
    }
    return {
      type: AxiosResponseType.ERROR,
      error: 'Une erreur est survenue' // use an enum for the errors
    }
  } catch (error: any) {
    return {
      type: AxiosResponseType.ERROR,
      error: error.response ?? error.request ?? error
    }
  }
}
