import React, { useState } from 'react'

import { Option } from '../Dropdown'
import TableListDropdowns from './TableListDropdowns'
import TableListContent from './TableListContent'
import {
  Container,
  TableListHeader,
  TableListHeaderItem,
  ContainerHead,
  buttonStyle
} from './styles'
import TitleCount from '../../pages/parts/TitleCount'
import Button from '../Button'
import { LabelledItemType } from '../LabelledItem'
import { PlatformAPIEnum } from '../../types/platform'
import Pagination from '../Pagination'

export type TableListFilter = {
  title: string
  options: string[]
  seeAll: boolean
  filterState: string | null
  handleFilterChange: (otpion: Option | null) => void
}

export type TableListSubItem = {
  title: string
  data: LabelledItemType[]
  platform?: PlatformAPIEnum
  preview?: JSX.Element
  onSeeMore?: () => void
}

export type TableListItem = {
  id: string
  type?: string
  actions?: {
    label: string
    handleAction: (id: string) => void
  }[]
  row: (string | JSX.Element)[]
  details?: {
    column1: LabelledItemType[]
    column2?: LabelledItemType[]
  }
  subItems?: TableListSubItem[]
}

export type TableListDataType = {
  titles: (string | JSX.Element)[]
  widths: string[]
  items: TableListItem[]
}

type TableListProps = {
  title?: string
  data: TableListDataType
  filters?: TableListFilter[]
  itemsByPage: number
  seeMoreText?: string
  handleSeeMoreSubItem?: (id: string) => void
  handleAdd?: () => void
  handleOnRowClick: (id: string) => void
  actions?: {
    label: string
    handleAction: (id: string) => void
  }[]
  noRowOpen?: string
  subtitle?: string
  addText?: string
  style?: React.CSSProperties
  totalItems: number
  currentPage: number
  handleChangePage: (page: number) => void
}

const TableList = ({
  title,
  data,
  filters,
  itemsByPage,
  seeMoreText,
  handleSeeMoreSubItem,
  handleAdd,
  handleOnRowClick,
  actions,
  noRowOpen,
  subtitle,
  addText,
  style,
  totalItems,
  currentPage,
  handleChangePage
}: TableListProps) => {
  const paginatedData = {
    ...data,
    items: data.items.slice(
      (currentPage - 1) * itemsByPage,
      currentPage * itemsByPage
    )
  }

  return (
    <Container style={style}>
      {(title || handleAdd) && (
        <ContainerHead>
          {title && <TitleCount title={title} count={data.items.length} />}
          {handleAdd && (
            <Button style={buttonStyle} onClick={handleAdd}>
              {addText ?? 'Nouveau'}
            </Button>
          )}
        </ContainerHead>
      )}
      <TableListDropdowns filters={filters} />
      <TableListHeader>
        {data.titles.map((title, index) => {
          return (
            <TableListHeaderItem
              key={index}
              style={{
                width:
                  data.widths && data.widths[index] ? data.widths[index] : '31%'
              }}
            >
              {title}
            </TableListHeaderItem>
          )
        })}
      </TableListHeader>
      <TableListContent
        data={paginatedData}
        actions={actions}
        seeMoreText={seeMoreText}
        handleSeeMoreSubItem={handleSeeMoreSubItem}
        handleClick={handleOnRowClick}
        noRowOpen={noRowOpen}
        subtitle={subtitle}
      />
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsByPage}
        onChangePage={handleChangePage}
      />
    </Container>
  )
}

export default TableList
