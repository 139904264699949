import { createContext } from 'react'

type HelpWidgetContextType = {
  getAndSetWidgetDataPage: ((pageId: string) => Promise<void>) | null
  isWidgetOpen: boolean | null
  setWidgetOpen: ((val: boolean) => void) | null
}

export const HelpWidgetContext = createContext<HelpWidgetContextType>({
  getAndSetWidgetDataPage: null,
  isWidgetOpen: null,
  setWidgetOpen: null
})
