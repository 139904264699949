import styled from '@emotion/styled/macro'

import FontSize from '../../../../../constants/fontSize'
import FontWeight from '../../../../../constants/fontWeight'
import Colors from '../../../../../constants/colors'

export const BoxInfoContainer = styled.div`
  box-shadow: 0px 0px 5px #142a4c40;
  border-radius: 10px;
  padding: 10px 20px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const BoxTitle = styled.div`
  font-size: ${FontSize.YETIC_HOME_BOX_TITLE};
  font-weight: ${FontWeight.YETIC_BOLD};
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`

export const BoxTitleInfo = styled.div`
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  box-shadow: 0px 0px 5px #142a4c80;
  border-radius: 10px;
  position: absolute;
  font-weight: 500;
  padding: 10px;
  top: 22px;
  left: 0;
  display: none;
  color: ${Colors.YETIC_BLUE_DARK};
  text-align: left;
  background-color: ${Colors.YETIC_WHITE};
`

export const BoxTitleInfoTip = styled.span`
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  background: ${Colors.NEW_YETIC_BOX_INFO_TIP};
  color: ${Colors.YETIC_WHITE};
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 8px;
  text-align: center;
  cursor: pointer;

  &:hover ${BoxTitleInfo} {
    display: block;
  }
`

export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 90px;

  > span {
    display: flex;
    width: 60%;
    align-self: baseline;
    justify-content: space-between;
  }
`

export const BoxPercent = styled.div`
  background-color: ${Colors.NEW_YETIC_GREY};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  font-weight: bold;
  height: 24px;
  border-radius: 10px 0;
  padding: 4px 15px;
  width: fit-content;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
`
