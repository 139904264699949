import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  LeftMenuAvatar,
  LeftMenuContainer,
  LeftMenuFirstIcon,
  LeftMenuIcon,
  Tooltip
} from './styles'
import HomePicto from '../../components/Icon/homePicto'
import AddPicto from '../../components/Icon/addPicto'
import AnalyticsPicto from '../../components/Icon/analyticsPicto'
import { MediaPlanPicto } from '../../components/Icon/mediaPlanPicto'
import Avatar from '../../components/Avatar'

type LeftMenuProps = {
  activeIndex?: number
  userInitials?: string
}

const LeftMenu = ({ activeIndex, userInitials }: LeftMenuProps) => {
  const navigate = useNavigate()

  const handleAddClick = () => {
    // TODO
  }

  const handleHomeClick = () => {
    navigate('/')
  }

  const handleMediaPlanClick = () => {
    // TODO
  }

  const handleAnalyticsClick = () => {
    navigate('/analytics')
  }

  const handleClickProfile = () => {
    // TODO
  }

  return (
    <LeftMenuContainer>
      <LeftMenuFirstIcon onClick={handleAddClick} isActive={activeIndex === 0}>
        <AddPicto style={{ display: 'block' }} width="16px" height="16px" />
        <Tooltip>Nouveau</Tooltip>
      </LeftMenuFirstIcon>
      <LeftMenuIcon onClick={handleHomeClick} isActive={activeIndex === 1}>
        <HomePicto style={{ display: 'block' }} width="16px" height="16px" />
        <Tooltip>Accueil</Tooltip>
      </LeftMenuIcon>
      <LeftMenuIcon onClick={handleMediaPlanClick} isActive={activeIndex === 2}>
        <MediaPlanPicto
          style={{ display: 'block' }}
          width="16px"
          height="16px"
        />
        <Tooltip>Plan Média</Tooltip>
      </LeftMenuIcon>
      <LeftMenuIcon onClick={handleAnalyticsClick} isActive={activeIndex === 3}>
        <AnalyticsPicto
          style={{ display: 'block' }}
          width="16px"
          height="16px"
        />
        <Tooltip>Analytics</Tooltip>
      </LeftMenuIcon>
      <LeftMenuAvatar>
        <Avatar
          character={userInitials !== undefined ? userInitials : 'FB'}
          onClick={handleClickProfile}
        />
      </LeftMenuAvatar>
    </LeftMenuContainer>
  )
}

export default LeftMenu
