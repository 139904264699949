import styled from '@emotion/styled'

import Margin from '../../../constants/margin'

export const DivStatus = styled.div<{
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `}
`

export const StatusText = styled.span`
  margin-left: ${Margin.m2};
`

export const StatusDot = styled.span<{ color: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: ${Margin.m2};
  background-color: ${({ color }) => color};
`
