export enum DevicesEnum {
  desktop = 'desktop',
  smartphone = 'smartphone',
  tablet = 'tablet',
  tv = 'tv'
}

export const DevicesEnumTranslation = {
  [DevicesEnum.desktop]: 'Ordinateur',
  [DevicesEnum.smartphone]: 'Smartphone',
  [DevicesEnum.tablet]: 'Tablette',
  [DevicesEnum.tv]: 'TV'
}

export type DevicesBoolean = {
  desktop: boolean
  smartphone: boolean
  tablet: boolean
  tv: boolean
}

export type DevicesNumber = {
  desktop: number
  smartphone: number
  tablet: number
  tv: number
}

export const checkKeyOfDevicesBoolean = (
  value: string
): value is keyof DevicesBoolean => {
  return ['desktop', 'smartphone', 'tablet', 'tv'].includes(value)
}

export const checkKeyOfDevicesNumber = (
  value: string
): value is keyof DevicesNumber => {
  return ['desktop', 'smartphone', 'tablet', 'tv'].includes(value)
}

export const getKeysOfDevicesBoolean = (): (keyof DevicesBoolean)[] => {
  return ['desktop', 'smartphone', 'tablet', 'tv']
}

export const getKeysOfDevicesNumber = (): (keyof DevicesNumber)[] => {
  return ['desktop', 'smartphone', 'tablet', 'tv']
}
