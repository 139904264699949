import React, { ChangeEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  checkIfVerifyTokenOutput,
  handleVerifyToken
} from '../../../../helpers/queries/auth/verifyToken'
import { PasswordCreationErrors } from '../../../../types/pages/passwordCreation'
import {
  checkIfHandleCreatePasswordResult,
  handleCreatePassword
} from '../../../../helpers/queries/auth/password/createPassword'
import { ErrorOutput } from '../../../../types/error/generic'
import { Content, DivContainer, logoStyle, messageStyle } from '../styles'
import YeticLogo from '../../../../components/Icon/yeticLogo'
import Colors from '../../../../constants/colors'
import DefaultText from '../../../../components/DefaultText'
import { MessageButton } from '../MessageButton'
import { PasswordForm } from './PasswordForm'

type PasswordCreationProps = {
  token: string
}

const PasswordCreation = ({ token }: PasswordCreationProps) => {
  const [tokenError, setTokenError] = useState('')
  const [errorFromApi, setErrorFromApi] = useState('')

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [passwordCreation, setPasswordCreation] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [passwordCreated, setPasswordCreated] = useState(false)
  const [userActivated, setUserActivated] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    ;(async function () {
      const res = await handleVerifyToken(token)

      if (checkIfVerifyTokenOutput(res)) {
        if (res.userActivated) {
          setUserActivated(true)
          setTimeout(() => {
            navigate('/auth')
          }, 3000)
        }
      } else {
        setTokenError(res.error)
      }

      setIsLoading(false)
    })()
  }, [])

  const handleRedirection = () => {
    navigate('/auth')
  }

  const handleStartCreation = () => {
    setPasswordCreation(true)
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value)
  }

  const checkPassword = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{12,}$/
    return regex.test(password)
  }

  const checkPasswordsDifferences = () => password === passwordConfirmation

  const errors = {
    password: checkPassword() ? '' : PasswordCreationErrors.PASSWORD,
    passwordConfirmation: checkPasswordsDifferences()
      ? ''
      : PasswordCreationErrors.PASSWORDS_DIFFERENT
  }

  const handleSubmitPassword = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!hasBeenSubmitted) {
      setHasBeenSubmitted(true)
    }

    if (
      errorFromApi === '' &&
      Object.values(errors).filter((e) => e !== '').length < 1
    ) {
      const res = await handleCreatePassword(token, password)

      if (checkIfHandleCreatePasswordResult(res)) {
        setPasswordCreated(true)
        setPasswordCreation(false)
      } else {
        setErrorFromApi((res as ErrorOutput).error)
        setTimeout(() => {
          setErrorFromApi('')
        }, 5000)
      }
    }
  }

  return (
    <DivContainer>
      <YeticLogo style={logoStyle} color={Colors.YETIC_BLUE_DARK} />
      <Content>
        {isLoading ? (
          <DefaultText style={messageStyle}>Chargement...</DefaultText>
        ) : tokenError ? (
          <MessageButton
            message={tokenError}
            buttonLabel="Retour à la page de connexion"
            buttonAction={handleRedirection}
          />
        ) : userActivated ? (
          <DefaultText size="big" style={messageStyle}>
            Votre mot de passe a déjà été créé, vous allez être redirigé vers la
            page de connexion...
          </DefaultText>
        ) : passwordCreation ? (
          <PasswordForm
            password={password}
            passwordConfirmation={passwordConfirmation}
            errors={errors}
            errorFromApi={errorFromApi}
            handlePasswordChange={handlePasswordChange}
            handlePasswordConfirmationChange={handlePasswordConfirmationChange}
            handleStartCreation={handleStartCreation}
            handleSubmitPassword={(event) => {
              handleSubmitPassword(event)
            }}
            hasBeenSubmitted={hasBeenSubmitted}
          />
        ) : passwordCreated ? (
          <MessageButton
            message="Félicitations ! Votre mot de passe a été créé avec succès."
            buttonLabel="Se connecter"
            buttonAction={handleRedirection}
          />
        ) : (
          <MessageButton
            message="Félicitations ! Votre compte a bien été créé."
            buttonLabel="Créer un mot de passe"
            buttonAction={handleStartCreation}
          />
        )}
      </Content>
    </DivContainer>
  )
}

export default PasswordCreation
