import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppContext } from '../../../../contexts/AppContext'
import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import LoadingPage from '../../../LoadingPage'
import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import { LinkedinFormImageAdError } from '../../../../types/pages/adImageCreation'
import ErrorPage from '../../../ErrorPage'
import { getCampaignById } from '../../../../helpers/queries/campaign/getCampaignById'
import { AdNavigation, emptyAdNavigation } from '../../../../types/ad/ad'
import {
  Status,
  StatusTranslation,
  getIsEditable
} from '../../../../types/status'
import Breadcrumb from '../../../../components/BreadCrumb'
import { FormatAPIEnum } from '../../../../types/format'
import { convertObjectToFormData } from '../../../../helpers/convertObjectToFromData'
import {
  checkIfEmptyErrors,
  getFormErrors,
  getImageError
} from './helpers/formErrors'
import {
  LinkedinCTA,
  checkIfLinkedinCTA
} from '../../../../types/ad/linkedin/linkedinCTA'
import { Option } from '../../../../components/Dropdown'
import {
  checkIfCreateLinkedinImageAdOutput,
  createLinkedinImageAd
} from '../../../../helpers/queries/ad/linkedin/createImageAd'
import getAllPanels from './Panels'
import { getBreadcrumb } from './breadcrumb'

export type ImageAdCreationFormDataType = {
  name: string
  campaignId: string
  url: string
  headline: string
  introText: string
  image: File | null
  ctaLabel: LinkedinCTA | null
}

const LinkedinImageAdCreation = () => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [errorFromApi, setErrorFromApi] = useState('')
  const [hasFetchError, setHasFetchError] = useState(false)
  const [navigation, setNavigation] = useState<AdNavigation>(emptyAdNavigation)
  const [campaignStatus, setCampaignStatus] = useState<Status | null>(null)
  const [formData, setFormData] = useState<ImageAdCreationFormDataType>({
    name: '',
    campaignId: '',
    url: '',
    headline: '',
    introText: '',
    image: null,
    ctaLabel: null
  })
  const [imageUrl, setImageUrl] = useState('')
  const [imageError, setImageError] = useState<string>(
    LinkedinFormImageAdError.IMAGE_MISSING
  )

  const { campaignId } = useParams()

  const navigate = useNavigate()

  const { isEditor, user } = useContext(AppContext)

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignById({
          campaignId,
          adType: FormatAPIEnum.IMAGE
        })
        if (result && result.navigation) {
          setCampaignStatus(result.campaign.status)
          setNavigation(result.navigation)
          setFormData({
            ...formData,
            campaignId
          })
        } else {
          setHasFetchError(true)
        }
      })()
      setFormData({ ...formData, campaignId })
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [campaignId])

  useEffect(() => {
    if (isSubmitLoading) {
      if (!campaignId) {
        console.error("Aucune campagne n'a été sélectionnée.")
      } else {
        ;(async function () {
          try {
            const form = convertObjectToFormData(formData)
            const response = await createLinkedinImageAd({
              form
            })

            if (checkIfCreateLinkedinImageAdOutput(response)) {
              navigate(`/campaign/${campaignId}/review`)
            } else {
              console.error(response.error)
              setErrorFromApi(response.error)
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            }
          } catch (error) {
            console.error(
              "Une erreur s'est produite lors de la création de l'annonce :",
              error
            )
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  if (hasFetchError || !campaignId) {
    return <ErrorPage />
  }

  if (!navigation || !campaignStatus) {
    return <LoadingPage />
  }

  if (!getIsEditable({ status: campaignStatus, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas créer d'annonce pour cette campagne au statut ${StatusTranslation[
          campaignStatus
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Revenir à la campagne',
          onClick: () => {
            navigate(`/campaign/${campaignId}/details`)
          }
        }}
      />
    )
  }

  const errors = getFormErrors(formData)

  const handleImageError = async (img: File | null) => {
    const newImageError = await getImageError(img)
    setImageError(newImageError)
  }

  const handleInputChange = (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [attribute]: e.target.value })
  }

  const handleTextAreaChange = (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [attribute]: e.target.value })
  }

  const handleCTAChange = (option: Option | null) => {
    if (option && checkIfLinkedinCTA(option.label)) {
      setFormData({ ...formData, ctaLabel: option.label })
    } else {
      setFormData({ ...formData, ctaLabel: null })
    }
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formData }
    if (e.target.files && e.target.files[0]) {
      const imageUrl = URL.createObjectURL(e.target.files[0])
      newFormData.image = e.target.files[0]

      const newImageError = await getImageError(e.target.files[0])
      setImageError(newImageError)

      if (newImageError !== '') {
        setFormData({ ...formData, image: null })
        setImageUrl('')
      } else {
        setFormData(newFormData)
        setImageUrl(imageUrl)
      }
    }
  }

  const handleImageDelete = () => {
    const newFormData = { ...formData }
    newFormData.image = null

    handleImageError(null)
    setFormData(newFormData)
    setImageUrl('')
  }

  const handleSubmit = () => {
    setIsSubmitLoading(true)
  }

  const breadcrumbItems = getBreadcrumb({
    navigation,
    navigate
  })

  const panels = getAllPanels({
    companyName: navigation.company.name,
    formData,
    handleInputChange,
    handleTextAreaChange,
    handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      handleImageChange(e)
    },
    handleImageDelete,
    handleCTAChange,
    hasBeenSubmitted,
    errors,
    imageError,
    imageUrl
  })

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Nouvelle annonce image`}
        subTitle={`Création de votre annonce image Linkedin`}
        panels={panels}
        validation={{
          action: () => {
            if (checkIfEmptyErrors(errors, imageError)) {
              handleSubmit()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled: hasBeenSubmitted && !checkIfEmptyErrors(errors, imageError),
          wording: 'Enregistrer et continuer',
          isLoading: isSubmitLoading
        }}
        errors={
          hasBeenSubmitted
            ? {
                errors: [
                  [
                    errorFromApi,
                    errors.name,
                    errors.url,
                    errors.headline,
                    errors.introText,
                    errors.ctaLabel,
                    imageError
                  ].filter((e) => e)[0]
                ]
              }
            : undefined
        }
        cancel={{
          wording: 'Annuler',
          action: () => {
            navigate(`/campaign/${campaignId}/review`)
          },
          disabled: false
        }}
      />
    </AuthenticatedTemplate>
  )
}

export default LinkedinImageAdCreation
