import React from 'react'

import {
  DivContainer,
  Description,
  DivContent,
  ImageTop,
  Logo,
  Title,
  DivButton
} from './styles'
import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import Colors from '../../../../../constants/colors'
import { ButtonArrow } from '../styles'
import { ArrowRight } from '../../../../Icon'

type SmartphonePreview2Props = {
  adImageRectangle: string
  logoImage: string
  title: string
  description: string
}

const SmartphonePreview2 = ({
  adImageRectangle,
  logoImage,
  title,
  description
}: SmartphonePreview2Props) => {
  return (
    // TODO check for background blur
    <DisplayAdInfoTemplate width="320px" height="570px" bgColor="grey">
      <DivContainer>
        <ImageTop src={adImageRectangle} alt="image paysage" loading="lazy" />
        <DivContent>
          <Title>{title}</Title>
          <Logo src={logoImage} alt="logo" loading="lazy" />
          <Description>{description}</Description>
          <DivButton>
            <ButtonArrow>
              <ArrowRight
                color={Colors.YETIC_WHITE}
                width="30px"
                height="30px"
              ></ArrowRight>
            </ButtonArrow>
          </DivButton>
        </DivContent>
      </DivContainer>
    </DisplayAdInfoTemplate>
  )
}

export default SmartphonePreview2
