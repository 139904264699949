import React from 'react'

import { TitleCountContainer } from './styles'

type TitleCountProps = {
  title: string
  count: number
  style?: React.CSSProperties
}
const TitleCount = ({ title, count, style }: TitleCountProps) => {
  return (
    <TitleCountContainer style={style}>
      {title} ({count})
    </TitleCountContainer>
  )
}

export default TitleCount
