import React, { ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom'

import { Page } from './styles'
import LeftMenu from '../../pages/parts/LeftMenu'
import Header from '../Header'

type AuthenticatedPageProps = {
  isEditor: boolean
  children: ReactNode
  user?: any // TODO declare the type
  navigate: NavigateFunction
  pageRef?: React.RefObject<HTMLDivElement>
}

const AuthenticatedTemplate = ({
  isEditor,
  children,
  navigate,
  pageRef
}: AuthenticatedPageProps) => {
  return (
    <>
      <LeftMenu />
      <Page ref={pageRef}>
        <Header callback={() => {}} isEditor={isEditor} navigate={navigate} />
        {children}
      </Page>
    </>
  )
}

export default AuthenticatedTemplate
