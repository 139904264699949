import React from 'react'

import { LoadingContainer } from './styles'
import ImpactTab from './TabsContent/ImpactTab'
import AnimatedLoader from '../../components/AnimatedLoader'
import { checkIfGetCampaignOverallReporting } from '../../helpers/queries/reporting/sections/overall'
import { checkIfAudienceLocationAndReportingOutputType } from '../../helpers/queries/reporting/sections/audiences'
import { checkIfGetCampaignDevicesReporting } from '../../helpers/queries/reporting/sections/devices'
import { checkIfGetCampaignKeywordsReporting } from '../../helpers/queries/reporting/sections/keywords'
import { checkIfGetCampaignAdsReporting } from '../../helpers/queries/reporting/sections/ads'
import ClickImpressionCostTab from './TabsContent/ClickImpressionCostTab'
import AudiencesTab from './TabsContent/AudiencesTab'
import DevicesTab from './TabsContent/DevicesTab'
import KeywordsTab from './TabsContent/KeywordsTab'
import AdsTab from './TabsContent/AdsTab'
import OverallTab from './TabsContent/OverallTab'
import {
  GetCampaignReportingBySectionOutputType,
  checkIfGetCampaignImpactReporting
} from '../../types/reporting/campaign/output'
import {
  ReportingTabItem,
  ReportingTabKeyEnum
} from '../../types/reporting/tabs'

type TabMappingProps = {
  activeTab: ReportingTabItem
  isLoading: boolean
  reportingData: GetCampaignReportingBySectionOutputType | null
  contentWidth: number
}

const TabMapping = ({
  activeTab,
  isLoading,
  reportingData,
  contentWidth
}: TabMappingProps) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <AnimatedLoader size={20} />
      </LoadingContainer>
    )
  }

  switch (activeTab.key) {
    case ReportingTabKeyEnum.overall:
      if (checkIfGetCampaignOverallReporting(reportingData)) {
        return (
          <OverallTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.recommendations:
      return <div>Recommandations en cours de développement</div>

    case ReportingTabKeyEnum.clickImpressionCost:
      if (checkIfGetCampaignOverallReporting(reportingData)) {
        return (
          <ClickImpressionCostTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.devices:
      if (checkIfGetCampaignDevicesReporting(reportingData)) {
        return (
          <DevicesTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.impact:
      if (checkIfGetCampaignImpactReporting(reportingData)) {
        return (
          <ImpactTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.audiences:
      if (checkIfAudienceLocationAndReportingOutputType(reportingData)) {
        return (
          <AudiencesTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.keywords:
      if (checkIfGetCampaignKeywordsReporting(reportingData)) {
        return <KeywordsTab reportingData={reportingData} />
      }
      return null

    case ReportingTabKeyEnum.ads:
      if (checkIfGetCampaignAdsReporting(reportingData)) {
        return <AdsTab reportingData={reportingData} />
      }
      return null

    default:
      return null
  }
}

export default TabMapping
