import React from 'react'
import { AudienceListDropdown } from './AudienceListDropdown'
import Button from '../../../../components/Button'
import { InputError } from '../../../../components/Input/Styles'

export type AudiencePanelProps = {
  addSelectedAudience: (name: string) => void
  audiences: string[]
  selectedAudiences: string[]
  nonRemovableAudiences?: string[]
  removeAudience: (index: number) => void
  openAudienceCreation: () => void
  onClickTag?: (audienceName: string) => void
  textButtonAudienceCreation: string
  error?: string
}

const AudiencePanel = ({
  addSelectedAudience,
  audiences,
  selectedAudiences,
  nonRemovableAudiences,
  removeAudience,
  openAudienceCreation,
  onClickTag,
  textButtonAudienceCreation,
  error
}: AudiencePanelProps) => {
  return (
    <div>
      <AudienceListDropdown
        audiences={audiences}
        addSelectedAudience={addSelectedAudience}
        selectedAudiences={selectedAudiences}
        nonRemovableAudiences={nonRemovableAudiences}
        removeAudience={removeAudience}
        onClickTag={onClickTag}
      />
      <Button type="secondary" onClick={openAudienceCreation}>
        {textButtonAudienceCreation}
      </Button>
      {error && <InputError>{error}</InputError>}
    </div>
  )
}

export default AudiencePanel
