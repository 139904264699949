import { backendURL } from '../../../../../constants/urls'
import { GetMediaPlanDevicesReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../../axios/get'
import { checkIfGetCampaignDevicesReporting } from '../devices'

type GetMediaPlanDevicesReportingInputType = {
  mediaPlanId: string
  startDate?: string
  endDate?: string
}

export const getMediaPlanDevicesReporting = async ({
  mediaPlanId,
  startDate,
  endDate
}: GetMediaPlanDevicesReportingInputType): Promise<GetMediaPlanDevicesReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/media-plan/${mediaPlanId}/sections/devices${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetMediaPlanDevicesReporting(response.data.devices)) {
        return response.data.devices
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetMediaPlanDevicesReporting = (
  val: any
): val is GetMediaPlanDevicesReportingOutputType => {
  return checkIfGetCampaignDevicesReporting(val)
}
