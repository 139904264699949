export type AdvertiserItemType = {
  id: string
  name: string
  nextStep?: string
  status: string
  inProgressType: string
  updatedAt: string
  editorName: string
}

export const checkIfAdvertiserItemType = (
  value: any
): value is AdvertiserItemType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.status &&
    typeof value.status === 'string' &&
    value.updatedAt &&
    typeof value.updatedAt === 'string' &&
    value.editorName &&
    typeof value.editorName === 'string'
  )
}

export const checkIfAdvertiserItemTypeArray = (
  value: any
): value is AdvertiserItemType[] => {
  return (
    value &&
    Array.isArray(value) &&
    value.every((item) => checkIfAdvertiserItemType(item))
  )
}

export type EditorItemType = {
  id: string
  name: string
  nextStep?: string
  status: string
  feedback?: string
  inProgressType: string
  updatedAt: string
  customerName: string
}

export const checkIfEditorItemType = (value: any): value is EditorItemType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.status &&
    typeof value.status === 'string' &&
    value.updatedAt &&
    typeof value.updatedAt === 'string' &&
    value.customerName &&
    typeof value.customerName === 'string'
  )
}

export const checkIfEditorItemTypeArray = (
  value: any
): value is EditorItemType[] => {
  return (
    value &&
    Array.isArray(value) &&
    value.every((item) => checkIfEditorItemType(item))
  )
}

export type SelfEditorItemType = {
  id: string
  name: string
  nextStep?: string
  status: string
  inProgressType: string
  updatedAt: string
  budget: number
}

export const checkIfSelfEditorItemType = (
  value: any
): value is SelfEditorItemType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.status &&
    typeof value.status === 'string' &&
    value.updatedAt &&
    typeof value.updatedAt === 'string' &&
    value.budget !== undefined &&
    typeof value.budget === 'number'
  )
}

export const checkIfSelfEditorItemTypeArray = (
  value: any
): value is SelfEditorItemType[] => {
  return (
    value &&
    Array.isArray(value) &&
    value.every((item) => checkIfSelfEditorItemType(item))
  )
}
