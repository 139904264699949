import React from 'react'

import { AudienceDataType } from '..'
import { PanelType } from '../../../../types/templates/panels'
import { AudienceCreationErrors } from '../helpers/errors'
import Margin from '../../../../constants/margin'
import BusinessSegments from '../Linkedin/BusinessSegments'
import JobFunctions from '../Linkedin/JobFunctions'
import JobTitles from '../Linkedin/JobTitles'

type GetLinkedinParamsPanelProps = {
  businessSegments: string[]
  jobFunctions: string[]
  jobTitles: string[]
  audienceData: AudienceDataType
  handleNewBusinessSegment: (tag: string) => void
  handleRemoveBusinessSegment: (index: number) => void
  handleNewJobFunction: (tag: string) => void
  handleRemoveJobFunction: (index: number) => void
  handleNewJobTitle: (tag: string) => void
  handleRemoveJobTitle: (index: number) => void
  hasBeenSubmitted: boolean
  errors: AudienceCreationErrors
  isChecked: boolean
  setIsChecked: (isChecked: boolean) => void
}

const getLinkedinParamsPanel = ({
  businessSegments,
  jobFunctions,
  jobTitles,
  audienceData,
  handleNewBusinessSegment,
  handleRemoveBusinessSegment,
  handleNewJobFunction,
  handleRemoveJobFunction,
  handleNewJobTitle,
  handleRemoveJobTitle,
  hasBeenSubmitted,
  errors,
  isChecked,
  setIsChecked
}: GetLinkedinParamsPanelProps) => {
  const linkedinParamsPanel: PanelType = {
    title: 'Paramètres LinkedIn',
    disabled: false,
    sections: [
      <div key="linkedin-audience-parameters" style={{ marginTop: Margin.m4 }}>
        <BusinessSegments
          data={businessSegments}
          selectedTags={audienceData.selectedBusinessSegments}
          removeBusinessSegment={handleRemoveBusinessSegment}
          addNewBusinessSegment={handleNewBusinessSegment}
          error={hasBeenSubmitted ? errors.businessSegments : ''}
        />
        <JobFunctions
          data={jobFunctions}
          selectedTags={audienceData.selectedJobFunctions}
          removeJobFunction={handleRemoveJobFunction}
          addNewJobFunction={handleNewJobFunction}
          error={hasBeenSubmitted ? errors.jobFunctions : ''}
        />
        <JobTitles
          data={jobTitles}
          selectedTags={audienceData.selectedJobTitles}
          removeJobTitle={handleRemoveJobTitle}
          addNewJobTitle={handleNewJobTitle}
          error={hasBeenSubmitted ? errors.jobTitles : ''}
        />
      </div>
    ],
    isDropdown: false,
    isChecked,
    setIsChecked
  }

  return linkedinParamsPanel
}

export default getLinkedinParamsPanel
