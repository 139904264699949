import { getCookie } from '../../helpers/cookies'

export const getDataFromCookieInHOC = () => {
  const rawUserCookie = getCookie('user')
  if (rawUserCookie) {
    const userCookie = JSON.parse(rawUserCookie)
    const {
      firstName,
      lastName,
      email,
      companyName,
      companyId,
      isEditor: isEditorRaw,
      isSelfEditor: isSelfEditorRaw
    } = userCookie

    const isEditor = isEditorRaw === 0 ? false : isEditorRaw === 1 ? true : null
    const isSelfEditor =
      isSelfEditorRaw === 0 ? false : isSelfEditorRaw === 1 ? true : null

    return {
      firstName,
      lastName,
      email,
      companyName,
      companyId,
      isEditor,
      isSelfEditor
    }
  }

  return null
}
