import { checkIfLinkedinCTA } from '../../../../../types/ad/linkedin/linkedinCTA'

export const checkIfMoreOneChar = (val: string) => {
  return val.length > 0
}

export const checkIfNameValid = (val: string) => {
  return checkIfMoreOneChar(val) && val.length <= 20
}

export const checkIfHeadlineValid = (val: string) => {
  return checkIfMoreOneChar(val) && val.length <= 200
}

export const checkIfIntroTextValid = (val: string) => {
  return checkIfMoreOneChar(val) && val.length <= 600
}

export const checkIfImageGiven = (img: File | null): img is File => {
  return img != null
}

export const checkIfCTALabelValid = (val: string | null) => {
  return checkIfLinkedinCTA(val)
}

export const checkImageSize = (img: File | null) => {
  return img == null || img.size <= 1000000
}
