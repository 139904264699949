export const isSameWeek = (date1: Date, date2: Date) => {
  const getDayOfWeek = (date: Date): number => (date.getUTCDay() + 6) % 7

  // Obtient le lundi de la semaine pour la date donnée
  const getMondayOfWeek = (date: Date): Date => {
    const dayOfWeek = getDayOfWeek(date)
    const monday = new Date(date)
    monday.setUTCDate(date.getUTCDate() - dayOfWeek)
    return monday
  }

  // Compare les lundis des semaines pour les deux dates
  const monday1 = getMondayOfWeek(date1)
  const monday2 = getMondayOfWeek(date2)

  // Si les lundis des semaines sont égaux, alors les dates sont dans la même semaine
  return monday1.getTime() === monday2.getTime()
}

export const isSameMonth = (date1: Date, date2: Date) => {
  return date1.getMonth() === date2.getMonth()
}

export const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}
