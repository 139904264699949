import React from 'react'

import DefaultText from '../../../../../components/DefaultText'
import Colors from '../../../../../constants/colors'
import { AudienceImportContainer, importTextStyle } from './styles'
import ImportIcon from '../../../../../components/Icon/import'

type AudienceImportButtonProps = {
  handleOpenModal: () => void
}

const AudienceImportButton = ({
  handleOpenModal
}: AudienceImportButtonProps) => {
  return (
    <AudienceImportContainer onClick={handleOpenModal}>
      <DefaultText size="small" style={importTextStyle}>
        Importer
      </DefaultText>
      <ImportIcon width="24px" color={Colors.NEW_YETIC_DARK} />
    </AudienceImportContainer>
  )
}

export default AudienceImportButton
