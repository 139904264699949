export enum GetMediaPlanReportingErrorEnum {
  MEDIA_PLAN_ID = 'CAMPAIGN_ID',
  UNKNOWN = 'UNKNOWN',
  AUTH_FAILED = 'AUTH_FAILED',
  INVALID_DATE = 'INVALID_DATE',
  MISSING_SECTION = 'MISSING_SECTION',
  WRONG_SECTION = 'WRONG_SECTION'
}

export const GetMediaPlanReportingErrorEnumTranslation: Record<
  GetMediaPlanReportingErrorEnum,
  string
> = {
  [GetMediaPlanReportingErrorEnum.MEDIA_PLAN_ID]:
    "L'id du plan média est requis",
  [GetMediaPlanReportingErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [GetMediaPlanReportingErrorEnum.AUTH_FAILED]: "L'authentification a échoué",
  [GetMediaPlanReportingErrorEnum.INVALID_DATE]:
    'Les dates doivent être au format YYYY-MM-DD',
  [GetMediaPlanReportingErrorEnum.MISSING_SECTION]: 'La section est requise',
  [GetMediaPlanReportingErrorEnum.WRONG_SECTION]:
    'La section est requise et doit être correctement formatée'
}
