import React from 'react'

import { tabContainerStyle, tabStyle } from './styles'
import Tabs from '../../../components/Tabs'
import Colors from '../../../constants/colors'

type AnalyticsTabsProps = {
  tabs: string[]
  activeTabIndex: number
  handleChangeTabIndex: (index: number) => void
}

const AnalyticsTabs = ({
  tabs,
  activeTabIndex,
  handleChangeTabIndex
}: AnalyticsTabsProps) => {
  return (
    <Tabs
      items={tabs}
      activeTabIndex={activeTabIndex}
      handleTabClick={handleChangeTabIndex}
      hasSeparator={false}
      containerStyle={tabContainerStyle}
      tabStyle={tabStyle}
      tabColor={Colors.YETIC_WHITE}
      textColor={Colors.NEW_YETIC_BLUE_DARK}
      activeTabColor={Colors.NEW_YETIC_BLUE_DARK}
      activeTextColor={Colors.YETIC_WHITE}
      hoverTabColor={Colors.NEW_YETIC_BLUE_DARK}
      hoverTextColor={Colors.YETIC_WHITE}
    />
  )
}

export default AnalyticsTabs
