import { PlatformAPIEnum, checkIfPlatformApiEnum } from './platform'
import { Status, checkIfStatus } from './status'
import { UserType, checkIfUser } from './user'

export type CustomerNames = {
  id: string
  name: string
}

export type CustomersWithBudgets = {
  customers: {
    id: string
    name: string
    spentBudget: string
    expectedBudget: string
    realizedBenefits: string
    expectedBenefits: string
  }[]
  navigation: {
    skip: number
    customersCount: number
  }
}

export type CustomerNavigation = {
  skip: number
  mediaPlansCount: number
}

export type CustomerMediaPlan = {
  id: string
  name: string
  status: Status
  platforms: PlatformAPIEnum[]
  expectedBudget: number
  spentBudget: number
  co2: number
  consumedCo2: number
  campaignsCount: number
  feedback?: string
}

export type CustomerDetailsType = {
  name: string
  createdAt: string
  spentBudget: number
  expectedBudget: number
  realizedBenefits: number
  expectedBenefits: number
  users: UserType[]
  co2: number
  consumedCo2: number
  mediaPlans: CustomerMediaPlan[]
  navigation: CustomerNavigation
}

const checkIfCustomerMediaPlan = (value: any): value is CustomerMediaPlan => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.status &&
    checkIfStatus(value.status) &&
    value.platforms &&
    Array.isArray(value.platforms) &&
    value.platforms.every((platform: any) =>
      checkIfPlatformApiEnum(platform)
    ) &&
    value.expectedBudget !== undefined &&
    typeof value.expectedBudget === 'number' &&
    value.spentBudget !== undefined &&
    typeof value.spentBudget === 'number' &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.consumedCo2 !== undefined &&
    typeof value.consumedCo2 === 'number' &&
    value.campaignsCount !== undefined &&
    typeof value.campaignsCount === 'number'
  )
}

export const checkIfCustomerDetails = (
  value: any
): value is CustomerDetailsType => {
  return (
    value &&
    value.name &&
    typeof value.name === 'string' &&
    value.createdAt &&
    typeof value.createdAt === 'string' &&
    value.spentBudget !== undefined &&
    typeof value.spentBudget === 'number' &&
    value.expectedBudget !== undefined &&
    typeof value.expectedBudget === 'number' &&
    value.realizedBenefits !== undefined &&
    typeof value.realizedBenefits === 'number' &&
    value.expectedBenefits !== undefined &&
    typeof value.expectedBenefits === 'number' &&
    value.users &&
    Array.isArray(value.users) &&
    value.users.every((user: any) => checkIfUser(user)) &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.consumedCo2 !== undefined &&
    typeof value.consumedCo2 === 'number' &&
    value.mediaPlans &&
    Array.isArray(value.mediaPlans) &&
    value.mediaPlans.every((mediaPlan: any) =>
      checkIfCustomerMediaPlan(mediaPlan)
    ) &&
    value.navigation &&
    value.navigation.mediaPlansCount !== undefined &&
    typeof value.navigation.mediaPlansCount === 'number'
  )
}
