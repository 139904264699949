import styled from '@emotion/styled'

export const ButtonArrow = styled.button`
  background-color: rgba(39, 39, 37, 1);
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  width: 55px;
  height: 55px;
  padding: 5px 0 0 2px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.4);
`
