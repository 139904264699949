import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppContext } from '../../../../contexts/AppContext'
import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import LoadingPage from '../../../LoadingPage'
import ErrorPage from '../../../ErrorPage'
import { FormatAPIEnum } from '../../../../types/format'
import {
  checkIfGetAdOutputGoogleImageAdDetails,
  getAdById
} from '../../../../helpers/queries/ad/getAdById'
import {
  GoogleAdImages,
  GoogleImageAdDetails,
  emptyImages,
  getGoogleAdImagesKeys
} from '../../../../types/ad/google/imageAd'
import getImageById from '../../../../helpers/queries/ad/getImageById'
import Colors from '../../../../constants/colors'
import { convertBase64ToUrl } from '../../../../helpers/convertBase64ToUrl'
import { PlatformAPIEnum } from '../../../../types/platform'
import EditorImageAdReviewContent from './editor'
import SelfEditorImageAdReviewContent from './selfEditor'
import AdvertiserImageAdReviewContent from './advertiser'

export type ImagesType = {
  adImageSquare: string
  adImageRectangle: string
}

const ImageAdReview = () => {
  const [imageAd, setImageAd] = useState<GoogleImageAdDetails | null>(null)
  const [images, setImages] = useState<GoogleAdImages | null>(null)
  const [previewImages, setPreviewImages] = useState<ImagesType>({
    adImageSquare: '',
    adImageRectangle: ''
  })
  const [hasFetchError, setHasFetchError] = useState(false)
  const [selectedPreview, setSelectedPreview] = useState(1)

  const { adId } = useParams()

  const navigate = useNavigate()

  const { isEditor, isSelfEditor, user } = useContext(AppContext)

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const data = await getAdById({
          adId,
          adType: FormatAPIEnum.IMAGE,
          platform: PlatformAPIEnum.GOOGLE
        })
        if (data && checkIfGetAdOutputGoogleImageAdDetails(data)) {
          const imageAdData = {
            id: data.ad.id,
            format: data.ad.format,
            name: data.ad.name,
            status: data.ad.status,
            url: data.ad.url,
            headline1: data.ad.headline1,
            headline2: data.ad.headline2,
            headline3: data.ad.headline3,
            longHeadline: data.ad.longHeadline,
            description1: data.ad.description1,
            description2: data.ad.description2,
            description3: data.ad.description3,
            businessName: data.ad.businessName,
            logo: data.ad.logo,
            marketingImage1: data.ad.marketingImage1,
            marketingImage2: data.ad.marketingImage2,
            marketingImage3: data.ad.marketingImage3,
            squareMarketingImage1: data.ad.squareMarketingImage1,
            squareMarketingImage2: data.ad.squareMarketingImage2,
            squareMarketingImage3: data.ad.squareMarketingImage3,
            navigation: data.navigation
          }

          const imageResults: GoogleAdImages = emptyImages

          const googleImageKeys = getGoogleAdImagesKeys()

          for (let i = 0; i < googleImageKeys.length; i++) {
            const imageAttribute = googleImageKeys[i]

            if (imageAdData[imageAttribute]) {
              const imageResult = await getImageById(
                imageAdData[imageAttribute]
              )
              if (imageResult) {
                const imageUrl = convertBase64ToUrl(imageResult)
                imageResults[imageAttribute] = imageUrl
              }
            }
          }

          setImageAd(imageAdData)
          setImages(imageResults)
          setPreviewImages({
            adImageSquare: imageResults.squareMarketingImage1 ?? '',
            adImageRectangle: imageResults.marketingImage1 ?? ''
          })
        } else {
          console.error(
            `Something went wrong during request to get ads/${adId}`
          )
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [adId])

  useEffect(() => {
    if (images) {
      const adImageSquares = [
        images.squareMarketingImage1,
        images.squareMarketingImage2,
        images.squareMarketingImage3
      ].filter((item: string | null) => item) as string[]
      const adImageRectangles = [
        images.marketingImage1,
        images.marketingImage2,
        images.marketingImage3
      ].filter((item: string | null) => item) as string[]
      const randomAdImageSquare =
        adImageSquares[Math.floor(Math.random() * adImageSquares.length)]
      const randomAdImageRectangle =
        adImageRectangles[Math.floor(Math.random() * adImageRectangles.length)]

      setPreviewImages((prevImages) => ({
        ...prevImages,
        adImageRectangle: randomAdImageRectangle,
        adImageSquare: randomAdImageSquare
      }))
    }
  }, [selectedPreview])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!adId || !imageAd || !images) {
    return <LoadingPage />
  }

  const titles = [
    imageAd.headline1,
    imageAd.headline2,
    imageAd.headline3
  ].filter((item) => item)
  const descriptions = [
    imageAd.description1,
    imageAd.description2,
    imageAd.description3
  ].filter((item) => item)

  const randomTitle = titles[Math.floor(Math.random() * titles.length)]
  const randomDescription =
    descriptions[Math.floor(Math.random() * descriptions.length)]

  const handleOnClickNextPreview = () => {
    setSelectedPreview((prevSelectedPreview) =>
      prevSelectedPreview >= 9 ? 1 : prevSelectedPreview + 1
    )
  }

  // TODO: get the main color of the image and handle the change of color according to the
  // selected preview (use the color of the square or the rectangle image according to the preview)
  const fakeColor = Colors.YETIC_GREY_AD

  const marketingImages: Partial<GoogleAdImages> = {
    marketingImage1: images.marketingImage1,
    marketingImage2: images.marketingImage2,
    marketingImage3: images.marketingImage3
  }
  const marketingImagesKeys = Object.keys(
    marketingImages
  ) as (keyof typeof marketingImages)[]

  const squareMarketingImages: Partial<GoogleAdImages> = {
    squareMarketingImage1: images.squareMarketingImage1,
    squareMarketingImage2: images.squareMarketingImage2,
    squareMarketingImage3: images.squareMarketingImage3
  }
  const squareMarketingImagesKeys = Object.keys(
    squareMarketingImages
  ) as (keyof typeof squareMarketingImages)[]

  const ImageAdReviewContent = isEditor
    ? EditorImageAdReviewContent
    : isSelfEditor
    ? SelfEditorImageAdReviewContent
    : AdvertiserImageAdReviewContent

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <ImageAdReviewContent
        imageAd={imageAd}
        selectedPreview={selectedPreview}
        setSelectedPreview={setSelectedPreview}
        handleOnClickNextPreview={handleOnClickNextPreview}
        previewImages={previewImages}
        images={images}
        marketingImagesKeys={marketingImagesKeys}
        squareMarketingImagesKeys={squareMarketingImagesKeys}
        randomTitle={randomTitle}
        randomDescription={randomDescription}
        fakeColor={fakeColor}
      />
    </AuthenticatedTemplate>
  )
}

export default ImageAdReview
