import React, { useState } from 'react'

import LinkedinComment from '../../../../Icon/linkedinComment'
import LinkedinCompletLogo from '../../../../Icon/linkedinCompletLogo'
import LinkedinLike from '../../../../Icon/linkedinLike'
import LinkedinRepost from '../../../../Icon/linkedinRepost'
import PictureIcon from '../../../../Icon/picture'
import SmileyIcon from '../../../../Icon/smileyIcon'
import Colors from '../../../../../constants/colors'
import {
  AdImage,
  BusinessName,
  Button,
  CommentBar,
  CommentBarIcons,
  Company,
  DivAccountImage,
  DivAdContainer,
  DivAdHeader,
  DivCommentBar,
  DivContent,
  DivDescription,
  DivHeader,
  DivHeaderContent,
  DivOtherContent,
  DivPostButtons,
  DivSentence,
  DivSubImage,
  DivWindow,
  Headline,
  linkedinLikeStyle,
  Logo,
  pictureIconStyle,
  Placeholder,
  PostButton,
  PostButtonLabel,
  SeeMore,
  Url
} from './styles'

type LinkedinSmartphonePreviewType = {
  companyName: string
  content: string
  image: string
  headline: string
  url: string
  cta: string
}

const LinkedinSmartphonePreview = ({
  companyName,
  content,
  image,
  headline,
  url,
  cta
}: LinkedinSmartphonePreviewType) => {
  const [isContentOpen, setIsContentOpen] = useState(false)

  const handleOpen = () => {
    setIsContentOpen(true)
  }

  return (
    <DivWindow>
      <DivHeader>
        <LinkedinCompletLogo />
        <DivAccountImage />
      </DivHeader>
      <DivOtherContent />
      <DivAdContainer>
        <DivAdHeader>
          <Logo />
          <DivHeaderContent>
            <Company>
              <BusinessName>{companyName}</BusinessName>
              <DivSentence />
            </Company>
            <Button>+ Suivre</Button>
          </DivHeaderContent>
        </DivAdHeader>
        <DivContent isExpanded={isContentOpen}>
          {content}
          {!isContentOpen && content.length > 100 && (
            <SeeMore onClick={handleOpen}>...voir plus</SeeMore>
          )}
        </DivContent>
        <AdImage src={image} loading="lazy" />
        <DivSubImage>
          <DivDescription>
            <Headline>{headline}</Headline>
            <Url>{url}</Url>
          </DivDescription>
          <Button border>{cta}</Button>
        </DivSubImage>
        <DivPostButtons>
          <PostButton>
            <LinkedinLike
              color={Colors.YETIC_GREY_AD}
              style={linkedinLikeStyle}
            />
            <PostButtonLabel>{`J'aime`}</PostButtonLabel>
          </PostButton>
          <PostButton>
            <LinkedinComment color={Colors.YETIC_GREY_AD} />
            <PostButtonLabel>Commenter</PostButtonLabel>
          </PostButton>
          <PostButton>
            <LinkedinRepost color={Colors.YETIC_GREY_AD} width="18px" />
            <PostButtonLabel>Republier</PostButtonLabel>
          </PostButton>
        </DivPostButtons>
        <DivCommentBar>
          <Logo small />
          <CommentBar>
            <Placeholder>Commenter en tant que {companyName}... </Placeholder>
            <CommentBarIcons>
              <SmileyIcon color={Colors.YETIC_GREY_AD} />
              <PictureIcon style={pictureIconStyle} />
            </CommentBarIcons>
          </CommentBar>
        </DivCommentBar>
      </DivAdContainer>
      <DivOtherContent />
    </DivWindow>
  )
}

export default LinkedinSmartphonePreview
