import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const Header = styled.div`
  padding-top: ${Padding.p10};
  width: 100%;
  text-align: left;
`

export const ProfilePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: auto;
  padding-top: ${Padding.p6};
`

export const ProfilePageRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-height: 70px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: ${Padding.p6};
  margin-top: ${Margin.m6};
  box-sizing: border-box;
`

export const labelledItemStyle: CSSProperties = {
  marginTop: '0px'
}

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
