import React, { useEffect, useState } from 'react'

import { DivContainer, InputContainer, errorStyle, loaderStyle } from './styles'
import KeywordsDetails from './KeywordsDetails'
import Input from '../../../../components/Input'
import Margin from '../../../../constants/margin'
import { Tags } from '../../../../components/Tag'
import { KeywordDetails } from '../../../../types/campaign/keyword'
import AnimatedLoader from '../../../../components/AnimatedLoader'
import { InputError } from '../../../../components/Input/Styles'
import Button from '../../../../components/Button'
import DefaultText from '../../../../components/DefaultText'
import Colors from '../../../../constants/colors'

export type KeywordsProps = {
  selectedTags: string[]
  error?: string
  apiError?: string
  removeTag: (index: number) => void
  addNewTag: (tag: string) => void
  keywordToSearch: string
  setKeywordToSearch: (tag: string) => void
  selectedKeywords: string[]
  keywordDetails: KeywordDetails[] | null
  setKeywordDetails: (keywordDetails: KeywordDetails[] | null) => void
  isSubmitActivated?: boolean
}

const Keywords = ({
  selectedTags,
  removeTag,
  addNewTag,
  keywordToSearch,
  setKeywordToSearch,
  selectedKeywords,
  keywordDetails,
  setKeywordDetails,
  error,
  apiError,
  isSubmitActivated
}: KeywordsProps) => {
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (
      selectedTags.length < 15 ||
      (keywordDetails && keywordDetails.length < 15)
    ) {
      setErrorMessage('')
    }
  }, [selectedTags])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setKeywordDetails(null)
    setKeywordToSearch('')
  }

  const handleInputEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (isSubmitActivated === false) {
        event.preventDefault()
        return
      }
      setKeywordToSearch(inputValue)
    }
  }

  const handleValidateKeyword = () => {
    setKeywordToSearch(inputValue)
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (
      selectedTags.length < 15 ||
      (keywordDetails && keywordDetails.length < 15)
    ) {
      addNewTag(inputValue)
      setInputValue('')
    } else {
      setErrorMessage('Vous ne pouvez pas ajouter plus de 15 mots-clés')
    }
  }

  return (
    <DivContainer>
      {/* <Tips
        text="Choisissez des mots-clés pertinents pour votre produit."
        hiddenText="Plus de tips..."
      /> */}
      <form
        onSubmit={(e) => {
          handleFormSubmit(e)
        }}
        style={{ marginTop: Margin.m5 }}
      >
        <InputContainer>
          <Input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputEnter}
            label="Entrez votre mots-clé"
            error={error}
            withBorder
            style={{ width: '300px' }}
          />
          <Button
            style={{ width: 'fit-content', whiteSpace: 'nowrap' }}
            disabled={!inputValue}
            type="primary"
            onClick={handleValidateKeyword}
          >
            Ajouter le mot-clé
          </Button>
          {keywordToSearch && !keywordDetails && (
            <AnimatedLoader size={15} style={loaderStyle} />
          )}
        </InputContainer>
        <DefaultText
          size="small"
          style={{ color: Colors.YETIC_GREEN_LIGHT, marginTop: Margin.m4 }}
        >
          Astuce : appuyez sur &quot;Entrée&quot; pour ajouter votre mot-clé
        </DefaultText>
      </form>
      {keywordDetails && keywordDetails.length > 0 && (
        <KeywordsDetails
          currentKeyword={keywordToSearch}
          keywordDetails={keywordDetails}
          selectedKeywords={selectedKeywords}
          addKeyword={addNewTag}
          removeKeyword={removeTag}
        />
      )}
      {apiError && <InputError style={errorStyle}>{apiError}</InputError>}
      <DefaultText style={{ marginTop: Margin.m7 }} size="medium" bold>
        Liste des mots-clé sélectionnés ({selectedTags.length}/15 maximum) :
      </DefaultText>
      <Tags labels={selectedTags} removeElements={removeTag} />
      {errorMessage && (
        <InputError style={errorStyle}>{errorMessage}</InputError>
      )}
    </DivContainer>
  )
}
export default Keywords
