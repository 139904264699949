import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'

export const CampaignPlatformChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const titleStyle: CSSProperties = {
  fontWeight: 700,
  marginTop: Margin.m8,
  marginBottom: Margin.m5,
  textAlign: 'center'
}

export const DivPlatforms = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${Margin.m4};
`

export const platformLogoStyle = {
  marginRight: Margin.m4
}

export const buttonStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 0,
  flex: 0.25
}

export const PlatformName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const platformTextStyle: CSSProperties = {
  marginBottom: 0
}

export const DivButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m2};
  flex: 0.25;
`
