import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from '../../types/icon'

export const ArrowCloseRight = ({
  width = '14px',
  height = '20px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 1L1 7L7 13" stroke={color} strokeWidth="2" />
    </svg>
  )
}

export const ArrowCloseLeft = ({
  width = '14px',
  height = '20px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 7L1 13" stroke={Colors.YETIC_BLUE_DARK} strokeWidth="2" />
    </svg>
  )
}
