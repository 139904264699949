import React from 'react'

import { LabelledItemType } from '../../LabelledItem'
import LabelledItemsList from '../../LabelledItemsList'
import {
  CTA,
  CTAContainer,
  CardsListTitle,
  Column,
  DetailsColumns,
  OpenRowDetailsContainer
} from './styles'
import SubItemCard from './SubItemCard'
import { TableListSubItem } from '..'

type OpenRowDetailsProps = {
  id: string
  items: {
    column1: LabelledItemType[]
    column2?: LabelledItemType[]
  }
  subtitle?: string
  subItems?: TableListSubItem[]
  seeMoreText?: string
  handleSeeMore?: (id: string) => void
}

const OpenRowDetails = ({
  id,
  items,
  subtitle,
  subItems,
  seeMoreText,
  handleSeeMore
}: OpenRowDetailsProps) => {
  return (
    <OpenRowDetailsContainer>
      <DetailsColumns>
        <Column>
          <LabelledItemsList items={items.column1} />
        </Column>
        {items.column2 && (
          <Column>
            <LabelledItemsList items={items.column2} />
          </Column>
        )}
        <CTAContainer>
          <CTA
            onClick={
              handleSeeMore &&
              (() => {
                handleSeeMore(id)
              })
            }
          >
            {seeMoreText}
          </CTA>
        </CTAContainer>
      </DetailsColumns>
      {subtitle && (
        <>
          <CardsListTitle>
            {subtitle} ({subItems?.length})
          </CardsListTitle>
          <>
            {subItems?.map((item, index) => {
              return <SubItemCard key={index} index={index} item={item} />
            })}
          </>
        </>
      )}
    </OpenRowDetailsContainer>
  )
}

export default OpenRowDetails
