import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import LoadingPage from '../LoadingPage'
import { PanelType } from '../../types/templates/panels'
import DisabledSection from '../../templates/EditPageTemplate/DisabledSection'
import {
  checkIfUpdateTextAd,
  updateTextAd
} from '../../helpers/queries/ad/updateTextAd'
import { checkMoreThanOneCharacter } from '../../helpers/checkers'
import { FormAdError } from '../../types/pages/adCreation'
import Input from '../../components/Input'
import Margin from '../../constants/margin'
import { TextAdDetails, TextAdUpdate } from '../../types/ad/google/textAd'
import {
  getAdById,
  checkIfGetAdOutputGoogleTextAdDetails
} from '../../helpers/queries/ad/getAdById'
import { FormatAPIEnum } from '../../types/format'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import GooglePreview from '../../components/PreviewAds/Google/Text'
import { StatusTranslation, getIsEditable } from '../../types/status'
import { PlatformAPIEnum } from '../../types/platform'
import EditorTextAdEdit from './editor'
import SelfEditorTextAdEdit from './selfEditor'
import { AddToPanelsParams, TextAdEditErrors } from './types'

const TextAdEdit = () => {
  const [textAd, setTextAd] = useState<TextAdDetails | null>(null)
  const [errorFromApi, setErrorFromApi] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { adId } = useParams()
  const location = useLocation()
  const { isEditor } = useContext(AppContext)

  const params = new URLSearchParams(location.search)
  const flow = params.get('flow') as FlowEnum

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const data = await getAdById({
          adId,
          adType: FormatAPIEnum.TEXT,
          platform: PlatformAPIEnum.GOOGLE
        })

        if (data && checkIfGetAdOutputGoogleTextAdDetails(data)) {
          const textAdData = {
            id: data.ad.id,
            format: data.ad.format,
            name: data.ad.name,
            url: data.ad.url,
            title1: data.ad.title1,
            title2: data.ad.title2,
            title3: data.ad.title3,
            title4: data.ad.title4,
            title5: data.ad.title5,
            title6: data.ad.title6,
            title7: data.ad.title7,
            title8: data.ad.title8,
            description1: data.ad.description1,
            description2: data.ad.description2,
            description3: data.ad.description3,
            status: data.ad.status,
            navigation: data.navigation
          }

          setTextAd(textAdData)
        } else {
          console.error(
            `Something went wrong during request to get ads/${adId}`
          )
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    scrollTo(0, 0)
  }, [adId])

  useEffect(() => {
    if (isSubmitLoading) {
      if (textAd && adId) {
        ;(async function () {
          try {
            const dataToSubmit: TextAdUpdate = {
              adId,
              title1: textAd.title1,
              title2: textAd.title2,
              title3: textAd.title3,
              title4: textAd.title4,
              title5: textAd.title5,
              title6: textAd.title6,
              title7: textAd.title7,
              title8: textAd.title8,
              description1: textAd.description1,
              description2: textAd.description2,
              description3: textAd.description3
            }

            const result = await updateTextAd(dataToSubmit)

            if (!checkIfUpdateTextAd(result)) {
              setErrorFromApi(result.error)
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            } else {
              navigate(`/ad/${adId}/review?platform=${PlatformAPIEnum.GOOGLE}`)
            }
          } catch (error) {
            console.error(error)
          }
          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  if (!textAd || !adId) {
    return <LoadingPage />
  }

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!getIsEditable({ status: textAd.status, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas modifier cette annonce au statut ${StatusTranslation[
          textAd.status
        ].toLocaleLowerCase()}.`}
        action={{
          text: "Retour à l'annonce",
          onClick: () => {
            navigate(`/ad/${adId}/details`)
          }
        }}
      />
    )
  }

  const titles = [
    textAd.title1,
    textAd.title2,
    textAd.title3,
    textAd.title4 ?? '',
    textAd.title5 ?? '',
    textAd.title6 ?? '',
    textAd.title7 ?? '',
    textAd.title8 ?? ''
  ]

  const descriptions = [
    textAd.description1,
    textAd.description2,
    textAd.description3 ?? ''
  ]

  const errors: TextAdEditErrors = {
    titles: titles
      .slice(0, 3)
      .map((title) =>
        checkMoreThanOneCharacter(title) ? '' : FormAdError.TITLE
      ),
    descriptions: [
      checkMoreThanOneCharacter(descriptions[0]) ? '' : FormAdError.DESCRIPTION,
      checkMoreThanOneCharacter(descriptions[1]) ? '' : FormAdError.DESCRIPTION,
      checkMoreThanOneCharacter(descriptions[2]) || !descriptions[2]
        ? ''
        : FormAdError.DESCRIPTION
    ],
    errorFromApi: errorFromApi ?? ''
  }

  const bottomErrors = {
    titles: errors.titles
      .slice(0, 3)
      .concat(
        errors.titles
          .slice(3)
          .filter((title, index) => titles[index + 3]?.trim())
      )
      .some((error) => error !== '')
      ? FormAdError.TITLE
      : '',
    descriptions:
      errors.descriptions.filter((e) => e !== '').length > 0
        ? FormAdError.DESCRIPTION
        : '',
    errorFromApi: errors.errorFromApi
  }

  type EditableTextAdFields =
    | 'title1'
    | 'title2'
    | 'title3'
    | 'title4'
    | 'title5'
    | 'title6'
    | 'title7'
    | 'title8'
    | 'description1'
    | 'description2'
    | 'description3'

  const handleChangeString = (
    value: string,
    attribute: EditableTextAdFields
  ) => {
    setTextAd({
      ...textAd,
      [attribute]: value
    })
  }

  const handleSubmitTextAd = () => {
    setIsSubmitLoading(true)
  }

  const adPreview = (
    <GooglePreview
      url={textAd.url}
      title1={textAd.title1}
      title2={textAd.title2}
      title3={textAd.title3}
      description={textAd.description1}
      description2={textAd.description2}
      description3={textAd.description3}
    />
  )

  const nameSection = <DisabledSection value={textAd.name} />
  const urlSection = <DisabledSection value={textAd.url} />
  const titlesSection = {
    content: (
      <>
        {titles.map((title, index) => (
          <Input
            key={index}
            type="text"
            placeholder={`Titre ${index + 1}`}
            value={textAd[`title${index + 1}` as EditableTextAdFields]}
            onChange={(event) => {
              handleChangeString(
                event.target.value,
                `title${index + 1}` as EditableTextAdFields
              )
            }}
            label={`Titre ${index + 1}`}
            maxLength={30}
            showCharacterCount
            containerStyle={{ marginBottom: Margin.m6 }}
            error={hasBeenSubmitted ? errors.titles[index] : ''}
            withBorder
            isRequired={index <= 2}
          />
        ))}
      </>
    )
  }
  const descriptionsSection = {
    content: (
      <>
        {descriptions.map((description, index) => (
          <Input
            key={index}
            type="textarea"
            placeholder={`Description ${index + 1}`}
            value={textAd[`description${index + 1}` as EditableTextAdFields]}
            onChange={(event) => {
              handleChangeString(
                event.target.value,
                `description${index + 1}` as EditableTextAdFields
              )
            }}
            label={`Description ${index + 1}`}
            maxLength={90}
            showCharacterCount
            containerStyle={{ marginBottom: Margin.m6 }}
            error={hasBeenSubmitted ? errors.descriptions[index] : ''}
            isRequired={index !== 2}
            withBorder
          />
        ))}
      </>
    )
  }

  const TextAdEditContent = isEditor ? EditorTextAdEdit : SelfEditorTextAdEdit

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <TextAdEditContent
        textAd={textAd}
        adPreview={adPreview}
        bottomErrors={bottomErrors}
        nameSection={nameSection}
        urlSection={urlSection}
        titlesSection={titlesSection}
        descriptionsSection={descriptionsSection}
        isSubmitLoading={isSubmitLoading}
        handleSubmitTextAd={handleSubmitTextAd}
        adId={adId}
        flow={flow}
      />
    </AuthenticatedTemplate>
  )
}

export default TextAdEdit
