import React, { useEffect, useState } from 'react'

import AnimatedLoader from '../../components/AnimatedLoader'
import { NonAuthLoadingPageContainer } from './styles'

const NonAuthLoadingPage = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaderVisible(true)
    }, 200)
  }, [])

  return (
    <NonAuthLoadingPageContainer>
      {isLoaderVisible && <AnimatedLoader size={40} />}
    </NonAuthLoadingPageContainer>
  )
}

export default NonAuthLoadingPage
