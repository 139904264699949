import { checkUrl } from '../checkers'
import { GoogleFormImageAdError } from '../../types/pages/adImageCreation'
import {
  checkIfDescriptionValid,
  checkIfImageGiven,
  checkIfMoreOneChar,
  checkIfNameValid,
  checkIfTitleValid,
  checkImageSize
} from './checkers'
import { getLogoError, getRectangleError, getSquareError } from './imageErrors'
import {
  ImageAdCreationFormDataType,
  ImageAdFiles
} from '../../types/ad/google/imageAd'

export type ImageAdCreationErrorsType = {
  name: string
  businessName: string
  url: string
  headline1: string
  headline2: string
  headline3: string
  longHeadline: string
  description1: string
  description2: string
  description3: string
}

export type ImageAdFilesErrors = {
  logo: string
  marketingImage1: string
  marketingImage2: string
  marketingImage3: string
  squareMarketingImage1: string
  squareMarketingImage2: string
  squareMarketingImage3: string
}

export const getFormErrors = (
  formData: ImageAdCreationFormDataType
): ImageAdCreationErrorsType => {
  return {
    name: checkIfNameValid(formData.name) ? '' : GoogleFormImageAdError.NAME,
    businessName: checkIfNameValid(formData.businessName)
      ? ''
      : GoogleFormImageAdError.BUSINESS_NAME,
    url: checkUrl(formData.url) ? '' : GoogleFormImageAdError.URL,
    headline1: checkIfTitleValid(formData.headline1, 0)
      ? ''
      : GoogleFormImageAdError.HEADLINE,
    headline2: checkIfTitleValid(formData.headline2, 1)
      ? ''
      : GoogleFormImageAdError.HEADLINE,
    headline3: checkIfTitleValid(formData.headline3, 2)
      ? ''
      : GoogleFormImageAdError.HEADLINE,
    longHeadline: checkIfMoreOneChar(formData.longHeadline)
      ? ''
      : GoogleFormImageAdError.HEADLINE,
    description1: checkIfDescriptionValid(formData.description1, 0)
      ? ''
      : GoogleFormImageAdError.DESCRIPTION,
    description2: checkIfDescriptionValid(formData.description2, 1)
      ? ''
      : GoogleFormImageAdError.DESCRIPTION,
    description3: checkIfDescriptionValid(formData.description3, 2)
      ? ''
      : GoogleFormImageAdError.DESCRIPTION
  }
}

export const getImagesErrors = async (
  attribute: keyof ImageAdFiles,
  img: File | null,
  initialImageErrors: ImageAdFilesErrors
): Promise<ImageAdFilesErrors> => {
  const newImageErrors = { ...initialImageErrors }
  const requiredAttributes = [
    'logo',
    'marketingImage1',
    'squareMarketingImage1'
  ]
  const squareAttributes = [
    'squareMarketingImage1',
    'squareMarketingImage2',
    'squareMarketingImage3'
  ]

  if (!checkIfImageGiven(img)) {
    if (requiredAttributes.includes(attribute)) {
      newImageErrors[attribute] = GoogleFormImageAdError.IMAGE_MISSING
    } else {
      newImageErrors[attribute] = ''
    }
  } else {
    if (!checkImageSize(img)) {
      newImageErrors[attribute] = GoogleFormImageAdError.IMAGE_SIZE
    } else {
      if (attribute === 'logo') {
        newImageErrors.logo = await getLogoError(img)
      } else if (squareAttributes.includes(attribute)) {
        newImageErrors[attribute] = await getSquareError(img)
      } else {
        newImageErrors[attribute] = await getRectangleError(img)
      }
    }
  }

  return newImageErrors
}

export const checkIfEmptyErrors = (
  errors: ImageAdCreationErrorsType,
  imageErrors: ImageAdFilesErrors
) => {
  return (
    Object.values(errors).filter((e) => e).length === 0 &&
    Object.values(imageErrors).filter((e) => e).length === 0
  )
}
