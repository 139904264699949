import styled from '@emotion/styled'

import Padding from '../../../../constants/padding'
import Colors from '../../../../constants/colors'

export const BarChartContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: ${Padding.p8} ${Padding.p5} ${Padding.p9} 0;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px #142a4c80;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.YETIC_WHITE};
`
