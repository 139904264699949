import React from 'react'

import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import {
  AdButton,
  AdCompany,
  AdDescription,
  AdHeader,
  AdImage,
  AdTitle,
  DivContent
} from './styles'
import Colors from '../../../../../constants/colors'
import DisplayAdWebpage from '../../../../../templates/DisplayAdTemplate/DisplayAdWebPage'

type WebpagePreview2Props = {
  title: string
  description: string
  businessName: string
  logoImage: string
}

const WebpagePreview2 = ({
  title,
  description,
  businessName,
  logoImage
}: WebpagePreview2Props) => {
  return (
    <DisplayAdWebpage>
      <DisplayAdInfoTemplate
        width={'300px'}
        height={'255px'}
        bgColor={Colors.YETIC_WHITE}
        style={{ borderColor: '#E9E9E9' }}
      >
        <DivContent>
          <AdHeader>
            <AdImage src={logoImage} alt="logo" loading="lazy" />
            <AdTitle>{title}</AdTitle>
          </AdHeader>
          <AdDescription>{description}</AdDescription>
          <AdCompany>{businessName}</AdCompany>
          <AdButton>Ouvrir</AdButton>
        </DivContent>
      </DisplayAdInfoTemplate>
    </DisplayAdWebpage>
  )
}
export default WebpagePreview2
