import { AdType, checkAdNavigation } from './../ad/ad'
import { PlatformAPIEnum, checkIfPlatformApiEnum } from '../platform'
import { GoalsEnum, checkIfGoalsEnum } from '../goals'
import { FormatAPIEnum, checkIfFormatApiEnum } from '../format'
import { AdNavigation } from '../ad/ad'
import { TextAdType, checkIfTextAd } from '../ad/google/textAd'
import { GoogleImageAdType, checkIfGoogleImageAd } from '../ad/google/imageAd'
import { checkIfDate } from '../../helpers/nativeTypes'
import {
  LinkedinImageAdType,
  checkIfLinkedinImageAd
} from '../ad/linkedin/imageAd'
import { Status, checkIfStatus } from '../status'

export type CampaignReviewPageDataType =
  | GoogleTextCampaignReviewPageDataType
  | GoogleImageCampaignReviewPageDataType
  | LinkedinImageCampaignReviewPageDataType

type GoogleCampaignReviewPageDataTypeWithoutAds = {
  campaign: {
    name: string
    platform: PlatformAPIEnum
    budget: number
    goal: GoalsEnum
    bidStrategy: string
    period: {
      start: Date | string
      end: Date | string
    }
    format: FormatAPIEnum
    status: Status
    devices: {
      desktop: boolean
      smartphone: boolean
      tablet: boolean
      tv: boolean
    }
    co2: string
  }
  navigation: AdNavigation
}

type LinkedinCampaignReviewPageDataTypeWithoutAds = {
  campaign: {
    name: string
    platform: PlatformAPIEnum
    budget: number
    goal: GoalsEnum
    period: {
      start: Date | string
      end: Date | string
    }
    format: FormatAPIEnum
    status: Status
    co2: string
  }
  navigation: AdNavigation
}

export type GoogleTextCampaignReviewPageDataType =
  GoogleCampaignReviewPageDataTypeWithoutAds & {
    ads: (Omit<AdType, 'status' | 'id'> & TextAdType)[]
  }

export type GoogleImageCampaignReviewPageDataType =
  GoogleCampaignReviewPageDataTypeWithoutAds & {
    ads: (Omit<AdType, 'status' | 'id'> & GoogleImageAdType)[]
  }

export type LinkedinImageCampaignReviewPageDataType =
  LinkedinCampaignReviewPageDataTypeWithoutAds & {
    ads: (Omit<AdType, 'status' | 'id'> & LinkedinImageAdType)[]
  }

export const checkIfGoogleCampaignReviewPageDataType = (
  value: any
): value is
  | GoogleTextCampaignReviewPageDataType
  | GoogleImageCampaignReviewPageDataType => {
  return (
    value &&
    value.campaign &&
    value.campaign.name &&
    typeof value.campaign.name === 'string' &&
    value.campaign.platform &&
    checkIfPlatformApiEnum(value.campaign.platform) &&
    value.campaign.budget !== undefined &&
    typeof value.campaign.budget === 'number' &&
    value.campaign.goal &&
    checkIfGoalsEnum(value.campaign.goal) &&
    value.campaign.bidStrategy &&
    typeof value.campaign.bidStrategy === 'string' &&
    value.campaign.period &&
    value.campaign.period.start &&
    checkIfDate(new Date(value.campaign.period.start)) &&
    value.campaign.period.end &&
    checkIfDate(new Date(value.campaign.period.end)) &&
    value.campaign.format &&
    checkIfFormatApiEnum(value.campaign.format) &&
    value.campaign.status &&
    checkIfStatus(value.campaign.status) &&
    value.campaign.devices &&
    value.campaign.devices.desktop !== undefined &&
    value.campaign.devices.smartphone !== undefined &&
    value.campaign.devices.tablet !== undefined &&
    value.campaign.devices.tv !== undefined &&
    value.campaign.co2 != null &&
    typeof value.campaign.co2 === 'string' &&
    value.navigation &&
    checkAdNavigation(value.navigation) &&
    value.ads &&
    Array.isArray(value.ads) &&
    value.ads.every((ad: any) => {
      return (
        ad &&
        ad.name &&
        typeof ad.name === 'string' &&
        ad.format &&
        checkIfFormatApiEnum(ad.format)
      )
    })
  )
}

export const checkIfGoogleTextCampaignReviewPageDataType = (
  value: any
): value is GoogleTextCampaignReviewPageDataType => {
  return (
    checkIfGoogleCampaignReviewPageDataType(value) &&
    value.ads.every((ad: any) => {
      return checkIfTextAd(ad)
    })
  )
}

export const checkIfGoogleImageCampaignReviewPageDataType = (
  value: any
): value is GoogleImageCampaignReviewPageDataType => {
  return (
    checkIfGoogleCampaignReviewPageDataType(value) &&
    value.ads.every((ad: any) => {
      return checkIfGoogleImageAd(ad)
    })
  )
}

export const checkIfLinkedinCampaignReviewPageDataType = (
  value: any
): value is LinkedinImageCampaignReviewPageDataType => {
  return (
    value &&
    value.campaign &&
    value.campaign.name &&
    typeof value.campaign.name === 'string' &&
    value.campaign.platform &&
    checkIfPlatformApiEnum(value.campaign.platform) &&
    value.campaign.budget !== undefined &&
    typeof value.campaign.budget === 'number' &&
    value.campaign.goal &&
    checkIfGoalsEnum(value.campaign.goal) &&
    value.campaign.period &&
    value.campaign.period.start &&
    checkIfDate(new Date(value.campaign.period.start)) &&
    value.campaign.period.end &&
    checkIfDate(new Date(value.campaign.period.end)) &&
    value.campaign.format &&
    checkIfFormatApiEnum(value.campaign.format) &&
    value.campaign.status &&
    checkIfStatus(value.campaign.status) &&
    value.campaign.co2 != null &&
    typeof value.campaign.co2 === 'string' &&
    value.navigation &&
    checkAdNavigation(value.navigation) &&
    value.ads &&
    Array.isArray(value.ads) &&
    value.ads.every((ad: any) => {
      return (
        ad &&
        ad.name &&
        typeof ad.name === 'string' &&
        ad.format &&
        checkIfFormatApiEnum(ad.format)
      )
    })
  )
}

export const checkIfLinkedinImageCampaignReviewPageDataType = (
  value: any
): value is LinkedinImageCampaignReviewPageDataType => {
  return (
    checkIfLinkedinCampaignReviewPageDataType(value) &&
    value.ads.every((ad: any) => {
      return checkIfLinkedinImageAd(ad)
    })
  )
}
