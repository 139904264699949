import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import FontWeight from '../../../constants/fontWeight'

export const AvatarContainer = styled.div<{
  backgroundColor?: string
  onClick?: () => void
}>`
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.YETIC_WHITE};
  border-radius: 100px;
  color: ${Colors.YETIC_WHITE};
  background: ${(props) => props.backgroundColor ?? Colors.YETIC_BLUE_DARK};
  font-size: 16px;
  font-weight: ${FontWeight.YETIC_BOLD};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`
