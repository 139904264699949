import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import Padding from '../../../../../../constants/padding'
import Margin from '../../../../../../constants/margin'

export const DivContainer = styled.div`
  text-align: center;
`

export const ImageTop = styled.img`
  width: 320px;
  height: 170px;
`

export const DivContent = styled.div`
  width: 260px;
  height: 375px;
  margin: ${Margin.m2} auto;
  padding: 0px ${Padding.p4};
  background-color: ${Colors.YETIC_WHITE};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Logo = styled.img`
  width: 55px;
  height: 55px;
`

export const Title = styled.h1`
  font-size: 26px;
  color: ${Colors.YETIC_GREY_AD};
  margin: ${Margin.m8} 0px;
`

export const DivButton = styled.div`
  margin: ${Margin.m6} 0px;
`

export const Description = styled.div`
  font-size: 17px;
  color: ${Colors.YETIC_GREY_AD};
  opacity: 0.9;
  margin-top: ${Margin.m8};
  margin-bottom: ${Margin.m4};
  min-height: 50px;
`
