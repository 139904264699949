import React from 'react'

import { IconType } from '../../types/icon'

const AnalyticsPicto = ({
  height = '17px',
  width = '17px',
  style
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 17 17"
    >
      <g
        id="Groupe_1549"
        data-name="Groupe 1549"
        transform="translate(-24 -367)"
      >
        <line
          id="Ligne_68"
          data-name="Ligne 68"
          y2="6.923"
          transform="translate(25 376.077)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Ligne_69"
          data-name="Ligne 69"
          y2="15"
          transform="translate(30 368)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Ligne_70"
          data-name="Ligne 70"
          y2="10.385"
          transform="translate(35 372.615)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Ligne_71"
          data-name="Ligne 71"
          y2="12.692"
          transform="translate(40 370.308)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

export default AnalyticsPicto
