import React from 'react'

import {
  TipsFormatContentContainer,
  TipsFormatContentItem,
  TipsFormatContentItemLi,
  TipsFormatContentItemList,
  TipsFormatContentItemText
} from './styles'
import { TipsInfos } from '..'
import DefaultText from '../../DefaultText'
import Margin from '../../../constants/margin'

export type TipsFormatProps = {
  title: string
  items: {
    title: string
    content: string[]
    icon?: JSX.Element
  }[]
}

const TipsFormat = ({ title, items }: TipsFormatProps) => {
  return (
    <TipsInfos
      style={{ marginBottom: Margin.m7 }}
      isDropdown={true}
      title={title}
      text={
        <TipsFormatContentContainer>
          {items.map((item, index) => (
            <TipsFormatContentItem key={index}>
              <TipsFormatContentItemText>
                <DefaultText bold size="medium">
                  {item.title}
                </DefaultText>
                <TipsFormatContentItemList>
                  {item.content.map((line, index) => (
                    <TipsFormatContentItemLi key={index}>
                      {line}
                    </TipsFormatContentItemLi>
                  ))}
                </TipsFormatContentItemList>
              </TipsFormatContentItemText>
              {item.icon}
            </TipsFormatContentItem>
          ))}
        </TipsFormatContentContainer>
      }
    />
  )
}

export default TipsFormat
