import React from 'react'

import DefaultText from '../../../../components/DefaultText'
import LocationIcon from '../../../../components/Icon/location'
import Colors from '../../../../constants/colors'
import { LocationContainer, locationTextStyle } from './styles'
import { AudienceLocation } from '../../../../helpers/queries/campaign/getCampaignCreationData'

type KeywordsLocationProps = {
  locations?: AudienceLocation[]
  handleOpenModal: () => void
}

const KeywordsLocation = ({
  locations,
  handleOpenModal
}: KeywordsLocationProps) => {
  return (
    <LocationContainer onClick={handleOpenModal}>
      <DefaultText size="small" style={locationTextStyle}>
        Voir la zone
      </DefaultText>
      <LocationIcon width="24px" color={Colors.NEW_YETIC_DARK} />
    </LocationContainer>
  )
}

export default KeywordsLocation
