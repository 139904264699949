import styled from '@emotion/styled'
import Margin from '../../../../../../constants/margin'

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
`

export const DivRecommandation = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: ${Margin.m10};
`

export const DivContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
