import React from 'react'

import {
  DivContent,
  Description,
  BusinessName,
  Logo,
  Title,
  DivButtons,
  ButtonClose,
  ButtonOpen,
  divStyle
} from './styles'
import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'

type SmartphonePreview4Props = {
  logoImage: string
  title: string
  description: string
  businessName: string
}

const SmartphonePreview4 = ({
  logoImage,
  title,
  description,
  businessName
}: SmartphonePreview4Props) => {
  return (
    <DisplayAdInfoTemplate height="570px" style={divStyle}>
      <>
        <DivContent>
          <Logo src={logoImage} alt="logo" loading="lazy" />
          <Title>{title}</Title>
          <BusinessName>{businessName}</BusinessName>
          <Description>{description}</Description>
        </DivContent>
        <DivButtons>
          <ButtonClose>Fermer</ButtonClose>
          <ButtonOpen>Ouvrir</ButtonOpen>
        </DivButtons>
      </>
    </DisplayAdInfoTemplate>
  )
}

export default SmartphonePreview4
