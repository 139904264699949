import { backendURL } from '../../../constants/urls'
import { AdNavigation, checkAdNavigation } from '../../../types/ad/ad'
import {
  TextAdDetails,
  checkIfTextAdDetails
} from '../../../types/ad/google/textAd'
import { FormatAPIEnum, checkIfFormatApiEnum } from '../../../types/format'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'
import { checkIfStatus, Status } from '../../../types/status'
import {
  GoogleImageAdDetails,
  checkIfGoogleImageAdDetails
} from '../../../types/ad/google/imageAd'
import { PlatformAPIEnum } from '../../../types/platform'
import {
  LinkedinImageAdDetails,
  checkIfLinkedinImageAdDetails
} from '../../../types/ad/linkedin/imageAd'

type AdWithoutDetails = {
  name: string
  format: FormatAPIEnum
  status: Status
}

// TODO : manage error
export type GetAdOutputType<AdType> = {
  ad: AdType
  navigation: AdNavigation
}

export type GetAdInput = {
  adId: string
  adType?: FormatAPIEnum
  platform?: PlatformAPIEnum
}

export const getAdById = async ({
  adId,
  adType,
  platform
}: GetAdInput): Promise<GetAdOutputType<
  | AdWithoutDetails
  | TextAdDetails
  | GoogleImageAdDetails
  | LinkedinImageAdDetails
> | null> => {
  try {
    const url = `${backendURL}/ads/${adId}?navigation=1${
      adType ? `&adType=${adType}` : ''
    }${platform ? `&platform=${platform}` : ''}`

    const response = await axiosGet({
      url
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkAdNavigation(response.data.navigation)) {
        if (
          adType === FormatAPIEnum.TEXT &&
          checkIfGetAdOutputGoogleTextAdDetails(response.data)
        ) {
          return {
            ad: response.data.ad,
            navigation: response.data.navigation
          }
        }
        if (
          platform === PlatformAPIEnum.GOOGLE &&
          adType === FormatAPIEnum.IMAGE &&
          checkIfGetAdOutputGoogleImageAdDetails(response.data)
        ) {
          return {
            ad: response.data.ad,
            navigation: response.data.navigation
          }
        }
        if (
          platform === PlatformAPIEnum.LINKEDIN &&
          adType === FormatAPIEnum.IMAGE &&
          checkIfGetAdOutputLinkedinImageAdDetails(response.data)
        ) {
          return {
            ad: response.data.ad,
            navigation: response.data.navigation
          }
        }
        if (
          (!adType || !platform) &&
          checkIfGetAdOutputAdWithoutDetails(response.data)
        ) {
          return {
            ad: response.data.ad,
            navigation: response.data.navigation
          }
        }
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetAdOutputGoogleTextAdDetails = (
  value: any
): value is GetAdOutputType<TextAdDetails> => {
  return (
    value &&
    value.ad &&
    checkIfTextAdDetails(value.ad) &&
    value.navigation &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfGetAdOutputGoogleImageAdDetails = (
  value: any
): value is GetAdOutputType<GoogleImageAdDetails> => {
  return (
    value &&
    value.ad &&
    checkIfGoogleImageAdDetails(value.ad) &&
    value.navigation &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfGetAdOutputLinkedinImageAdDetails = (
  value: any
): value is GetAdOutputType<LinkedinImageAdDetails> => {
  return (
    value &&
    value.ad &&
    checkIfLinkedinImageAdDetails(value.ad) &&
    value.navigation &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfGetAdOutputAdWithoutDetails = (
  value: any
): value is GetAdOutputType<AdWithoutDetails> => {
  return (
    value &&
    value.ad &&
    checkIfAdWithoutDetails(value.ad) &&
    value.navigation &&
    checkAdNavigation(value.navigation)
  )
}

export const checkIfAdWithoutDetails = (
  value: any
): value is AdWithoutDetails => {
  return (
    value &&
    value.name &&
    typeof value.name === 'string' &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    checkIfStatus(value.status)
  )
}
