import React, { useState, useEffect } from 'react'

import {
  Span,
  PanelContainer,
  ToggleButton,
  Content,
  DivTitlePannel,
  DivMap
} from '../styles'
import Margin from '../../../../constants/margin'
import DefaultText from '../../../../components/DefaultText'
import { ArrowRight } from '../../../../components/Icon'
import ArrowDown from '../../../../components/Icon/arrowDown'
import { PanelType, isJSXSection } from '../../../../types/templates/panels'
import LockIcon from '../../../../components/Icon/lock'
import Colors from '../../../../constants/colors'
import PanelTitle from '../PanelTitle'

type PanelProps = Omit<PanelType, 'isDropdown'>

const PanelDropdown = ({
  title,
  description,
  sections,
  disabled = false
}: PanelProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (disabled && isExpanded) {
      setIsExpanded(false)
    }
  }, [disabled])

  const togglePanel = () => {
    if (!disabled) setIsExpanded(!isExpanded)
  }

  return (
    <PanelContainer>
      <ToggleButton onClick={togglePanel} isExpanded={isExpanded}>
        {isExpanded ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowDown />
            <Span>{title}</Span>
          </div>
        ) : (
          <DivTitlePannel disabled={disabled}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowRight />
              <Span>{title}</Span>
            </div>
          </DivTitlePannel>
        )}
      </ToggleButton>
      {description && isExpanded && (
        <DefaultText
          size="small"
          style={{ opacity: '0.5', marginBottom: Margin.m5 }}
        >
          {description}
        </DefaultText>
      )}
      {isExpanded &&
        sections &&
        sections.map((section, index) => {
          if (isJSXSection(section)) {
            return <DivMap key={index}>{section}</DivMap>
          }
          return (
            <DivMap
              key={section.title}
              style={{
                marginBottom:
                  index === sections.length - 1 ? Margin.m3 : Margin.m8
              }}
            >
              {section.title && (
                <PanelTitle>
                  {section.title}
                  {section.disabled && (
                    <LockIcon
                      width="12px"
                      height="12px"
                      fill={Colors.NEW_YETIC_DARK}
                    />
                  )}
                </PanelTitle>
              )}
              {section.description && (
                <DefaultText size="small" style={{ opacity: '0.5' }}>
                  {section.description}
                </DefaultText>
              )}
              {section.content && <Content>{section.content}</Content>}
            </DivMap>
          )
        })}
    </PanelContainer>
  )
}

export default PanelDropdown
