import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import Padding from '../../../../../../constants/padding'
import Margin from '../../../../../../constants/margin'

export const DivContainer = styled.div`
  z-index: 1;
`

export const ImageAd = styled.img`
  width: 230px;
  height: 230px;
  z-index: 1;
`

export const DivContent = styled.div`
  margin: 0px auto;
  padding: 0px ${Padding.p4};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 230px;
  width: 260px;
  z-index: 1;
`

export const Logo = styled.img`
  width: 37px;
  height: 37px;
  margin-top: ${Margin.m4};
`

export const Title = styled.h1`
  font-size: 24px;
  color: ${Colors.YETIC_WHITE};
  margin-top: ${Margin.m4};
  margin-bottom: ${Margin.m4};
`

export const DivImage = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.YETIC_WHITE};
  border-radius: 5px;
  margin: ${Margin.m10} auto ${Margin.m6};
  padding: ${Padding.p4} 0px;
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${Margin.m5} 0px;
  width: 100%;
`

export const Description = styled.div`
  font-size: 16px;
  color: ${Colors.YETIC_WHITE};
  opacity: 0.9;
  margin: 0px;
`

export const CloseButton = styled.button<{ secondaryColor?: string }>`
  background-color: transparent;
  border: solid 1px ${(props) => props.secondaryColor};
  border-radius: 5px;
  color: white;
  padding: ${Padding.p3};
  borderradius: 5px;
  width: 115px;
  font-size: 12px;
  font-weight: 600;
`
