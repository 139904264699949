import { MutableRefObject } from 'react'

import initChart from './initChart'
import drawXAxis from './drawXAxis'
import drawYAxis from './drawYAxis'
import drawTooltip from './drawTooltip'
import drawBackgroundLines from './drawBackgroundLines'
import drawLines from './drawLines'
import { LineChartData, Point } from '../../LineChartDrawer'
import { formatDateToFrench } from '../../../../../helpers/date'
import { TooltipRenderer } from '../../../../../types/lineChart'

type DrawChartInput<PointType extends Point> = {
  chartId: string
  chartRef: MutableRefObject<HTMLDivElement | null>
  data: LineChartData<PointType>
  width: number
  renderTooltip: TooltipRenderer<PointType>
}

export const drawChart = <PointType extends Point>({
  chartId,
  chartRef,
  data,
  width,
  renderTooltip
}: DrawChartInput<PointType>) => {
  if (data.length === 0) {
    return
  }

  const margin = { top: 50, right: 100, bottom: 30, left: 70 }
  const widthWithoutMargin = width - margin.left - margin.right
  const height = width / 2 - margin.top - margin.bottom

  const svg = initChart(chartRef, width, widthWithoutMargin, height, margin)

  const xAxisValues = data[0].values.map((d) => formatDateToFrench(d.time))

  const x = drawXAxis(svg, xAxisValues, widthWithoutMargin, height)
  const yScales = drawYAxis(data, widthWithoutMargin, height, svg)

  const yAxisTicks = yScales[0].ticks(5).slice(1)

  drawBackgroundLines(svg, yScales, yAxisTicks, widthWithoutMargin)

  drawLines(svg, data, yScales, x)

  drawTooltip(svg, data, chartId, x, yScales, renderTooltip)
}
