import React from 'react'

import { ArrowRight } from '../Icon'
import {
  BreadcrumbContainer,
  BreadcrumbItem,
  BreadcrumbSeparator
} from './styles'

export interface BreadcrumbProps {
  items: {
    onClick?: () => void
    label: string
  }[]
  separator?: string
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
  separator = <ArrowRight />
}) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <BreadcrumbItem onClick={item.onClick}>{item.label}</BreadcrumbItem>
          {index < items.length - 1 && (
            <BreadcrumbSeparator>{separator}</BreadcrumbSeparator>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  )
}

export default Breadcrumb
