import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import FontWeight from '../../../../constants/fontWeight'
import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'
import { CSSProperties } from 'react'

export const OpenRowDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: -${Margin.m5} 0 ${Margin.m5} 0;
  padding: ${Padding.p4} ${Padding.p7} ${Padding.p8} ${Padding.p7};
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  box-shadow: inset 0 -1px 0 1px ${Colors.NEW_YETIC_GREY};
  transition: height 0.6s ease-in-out;
`

export const DetailsColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`

export const CTAContainer = styled.div`
  padding: ${Padding.p5} ${Padding.p3};
`

export const CTA = styled.div`
  margin-bottom: ${Margin.m5};
  cursor: pointer;
  text-decoration: underline;
  height: fit-content;
`

export const CardsListTitle = styled.h2`
  font-size: ${FontSize.YETIC_TITLE_H2};
  font-weight: ${FontWeight.YETIC_BOLD};
  text-transform: uppercase;
  color: ${Colors.YETIC_BLUE_DARK};
  padding: ${Padding.p4} 0;
`

export const rowLabelledItemsListStyle: CSSProperties = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around'
}
