import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getMediaPlanReview } from '../../helpers/queries/mediaPlan/getMediaPlanReview'
import { MediaPlanReviewDataType } from '../../types/pages/mediaPlanReview'
import LoadingPage from '../LoadingPage'
import { getBidStrategyEnum } from '../../types/bidStrategy'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import { getCO2andUnit } from '../../helpers/getCO2andUnit'
import CO2Modal from '../../components/Modal/CO2Modal'
import { checkIfReviewGoogleCampaign } from '../../types/mediaPlan/review'
import { checkIfGoogleCampaign } from '../../types/campaign/google/googleCampaign'
import { linkedinDevices } from '../../constants/linkedin/linkedinDevices'
import { getPlatformEnum } from '../../types/platform'
import SelfEditorMediaPlanValidation from './selfEditor'
import EditorMediaPlanValidation from './editor'

const MediaPlanValidation = () => {
  const [reviewData, setReviewData] = useState<MediaPlanReviewDataType | null>(
    null
  )
  const [campaignIdCO2ModalOpen, setCampaignIdCO2ModalOpen] = useState('')
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { mediaPlanId } = useParams()
  const { isEditor, isSelfEditor } = useContext(AppContext)

  useEffect(() => {
    if (mediaPlanId) {
      ;(async function () {
        const result = await getMediaPlanReview({
          mediaPlanId
        })
        if (result != null) {
          const newMediaPlan = {
            name: result.mediaPlan.name,
            isEditorPaying: result.mediaPlan.isEditorPaying,
            budget: result.mediaPlan.budget,
            audiences: result.mediaPlan.audiences,
            goals: result.mediaPlan.goals,
            period: {
              start: result.mediaPlan.period.start,
              end: result.mediaPlan.period.end
            },
            status: result.mediaPlan.status,
            co2:
              getCO2andUnit(result.mediaPlan.co2).co2 +
              ` ${getCO2andUnit(result.mediaPlan.co2).co2Unit}`,
            editor: result.mediaPlan.editor
          }

          const newCampaigns = result.campaigns.map((campaign) => {
            if (checkIfReviewGoogleCampaign(campaign)) {
              return {
                id: campaign.id,
                name: campaign.name,
                platform: campaign.platform,
                budget: campaign.budget,
                goal: campaign.goal,
                bidStrategy: getBidStrategyEnum(campaign.bidStrategy),
                period: {
                  start: new Date(campaign.period.start).toLocaleDateString(),
                  end: new Date(campaign.period.end).toLocaleDateString()
                },
                format: campaign.format,
                devices: campaign.devices,
                co2:
                  getCO2andUnit(campaign.co2).co2 +
                  ` ${getCO2andUnit(campaign.co2).co2Unit}`,
                adsCount: campaign.adsCount,
                isPlatformSynced: campaign.isPlatformSynced
              }
            } else {
              return {
                id: campaign.id,
                name: campaign.name,
                platform: campaign.platform,
                budget: campaign.budget,
                goal: campaign.goal,
                period: {
                  start: new Date(campaign.period.start).toLocaleDateString(),
                  end: new Date(campaign.period.end).toLocaleDateString()
                },
                format: campaign.format,
                co2:
                  getCO2andUnit(campaign.co2).co2 +
                  ` ${getCO2andUnit(campaign.co2).co2Unit}`,
                adsCount: campaign.adsCount,
                isPlatformSynced: campaign.isPlatformSynced
              }
            }
          })

          setReviewData({
            mediaPlan: newMediaPlan,
            campaigns: newCampaigns,
            adminUserData: result.adminUserData,
            platformConnection: result.platformConnection,
            navigation: result.navigation
          })
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [mediaPlanId])

  if (hasFetchError || (isEditor === false && isSelfEditor === false)) {
    return <ErrorPage />
  }

  if (!mediaPlanId || !reviewData || isEditor == null) {
    return <LoadingPage />
  }

  if (reviewData.campaigns.length === 0) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas valider un plan média n'ayant pas de campagne.`}
        action={{
          text: 'Retour au plan média',
          onClick: () => {
            navigate(`/media-plan/${mediaPlanId}/review`)
          }
        }}
      />
    )
  }

  if (!reviewData.campaigns.every((campaign) => campaign.adsCount > 0)) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas valider un plan média dont
        toutes les campagnes n'ont pas au moins chacune une annonce.`}
        action={{
          text: 'Retour au plan média',
          onClick: () => {
            navigate(`/media-plan/${mediaPlanId}/review`)
          }
        }}
      />
    )
  }

  if (!reviewData.campaigns.every((campaign) => campaign.isPlatformSynced)) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas valider un plan média dont
        toutes les campagnes n'ont pas été synchronisées.`}
        action={{
          text: 'Retour au plan média',
          onClick: () => {
            navigate(`/media-plan/${mediaPlanId}/review`)
          }
        }}
      />
    )
  }

  const startDateMediaPlan = new Date(
    reviewData.mediaPlan.period.start
  ).toLocaleDateString()
  const endDateMediaPlan = new Date(
    reviewData.mediaPlan.period.end
  ).toLocaleDateString()

  const handleViewMore = (id: string) => {
    navigate(`/campaign/${id}/review`)
  }

  const uniquePlatforms = new Set(
    reviewData.campaigns.map((campaign) => getPlatformEnum(campaign.platform))
  )

  const mediaPlanPlatforms = Array.from(uniquePlatforms).join(', ')

  const currentCampaign = reviewData.campaigns.find(
    (campaign) => campaign.id === campaignIdCO2ModalOpen
  )

  const campaignDevices = checkIfGoogleCampaign(currentCampaign)
    ? currentCampaign?.devices
    : linkedinDevices

  const MediaPlanValidationContent = isEditor
    ? EditorMediaPlanValidation
    : SelfEditorMediaPlanValidation

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <MediaPlanValidationContent
        reviewData={reviewData}
        mediaPlanId={mediaPlanId}
        startDateMediaPlan={startDateMediaPlan}
        endDateMediaPlan={endDateMediaPlan}
        platforms={mediaPlanPlatforms}
        setCampaignIdCO2ModalOpen={setCampaignIdCO2ModalOpen}
        navigate={navigate}
        handleViewMore={handleViewMore}
      />
      {campaignIdCO2ModalOpen && currentCampaign && (
        <CO2Modal
          onClose={() => {
            setCampaignIdCO2ModalOpen('')
          }}
          format={currentCampaign.format}
          campaignId={campaignIdCO2ModalOpen}
          campaignDevices={campaignDevices}
        />
      )}
    </AuthenticatedTemplate>
  )
}

export default MediaPlanValidation
