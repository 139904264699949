import React from 'react'
import { NavigateFunction } from 'react-router-dom'

import FontWeight from '../../../constants/fontWeight'

type OnboardingSlideType = {
  title: string
  description?: string | JSX.Element
  imageWebp: string
  imagePng: string
  isNotBottom?: boolean
  buttonConfirm: {
    label?: string
    onClick?: () => void
  }
  buttonCancel?: {
    label?: string
  }
  skipIntroduction?: boolean
}

export const selfEditorOnboardingSlides: (
  navigate: NavigateFunction,
  isSelfEditor?: boolean,
  companyId?: string | null
) => Record<number, OnboardingSlideType> = (
  navigate,
  isSelfEditor,
  companyId
) => {
  return {
    0: {
      title: 'Bienvenue sur YETIC Ad Manager',
      description:
        'Faisons un tour rapide pour explorer toutes les fonctionnalités et vous aider à démarrer.',
      imageWebp: '/images/Onboarding/Yeti-hello.webp',
      imagePng: '/images/Onboarding/Yeti-hello.png',
      buttonConfirm: {
        label: 'Allons y !'
      }
    },
    1: {
      title: 'Gérez des campagnes publicitaires en un seul endroit',
      description: (
        <>
          Créez et publiez vos publicités sur différentes plateformes :{' '}
          <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>
            Google, LinkedIn, Meta...
          </span>
        </>
      ),
      imageWebp: '/images/Onboarding/Yeti-socials.webp',
      imagePng: '/images/Onboarding/Yeti-socials.png',
      buttonConfirm: {},
      buttonCancel: {}
    },
    2: {
      title: 'Profitez de nos conseils éthiques',
      description:
        'Créez des campagnes publicitaires alignées avec vos valeurs en suivant nos recommandations éthiques.',
      imageWebp: '/images/Onboarding/Yeti-book.webp',
      imagePng: '/images/Onboarding/Yeti-book.png',
      buttonConfirm: {},
      buttonCancel: {}
    },
    3: {
      title: 'Donnez le dernier mot à vos clients',
      description:
        'Avant la diffusion, validez vos plans publicitaires avec vos clients.',
      imageWebp: '/images/Onboarding/Yeti.webp',
      imagePng: '/images/Onboarding/Yeti.png',
      buttonConfirm: {},
      buttonCancel: {}
    },
    4: {
      title: "Profitez d'une structure intuitive",
      description: 'Simplifiez la gestion de vos campagnes publicitaires',
      imageWebp: '/images/Onboarding/Structure.webp',
      imagePng: '/images/Onboarding/Structure.png',
      buttonConfirm: {},
      buttonCancel: {},
      isNotBottom: true
    },
    5: {
      title: 'Ajoutez un nouvel élément à tout moment',
      description: (
        <>
          Plan publicitaire, campagne, annonce... Utilisez le bouton{' '}
          <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>
            « + Nouveau »
          </span>{' '}
          pour ajouter un nouvel élément.
        </>
      ),
      imageWebp: '/images/Onboarding/New.webp',
      imagePng: '/images/Onboarding/New.png',
      buttonConfirm: {},
      buttonCancel: {},
      isNotBottom: true
    },
    6: {
      title: `Naviguez facilement parmi vos plans publicitaires`,
      description: (
        <>
          Accédez rapidement aux détails de vos plans publicitaires grâce au
          menu{' '}
          <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>mes projets</span>
          .
        </>
      ),
      imageWebp: '/images/Onboarding/Navigation.webp',
      imagePng: '/images/Onboarding/Navigation.png',
      buttonConfirm: {},
      buttonCancel: {},
      isNotBottom: true
    },
    7: {
      title: 'Alors, on se lance ?',
      description: `Nous vous accompagnons dans vos première créations : plans publicitaires, campagnes et annonces.`,
      imageWebp: '/images/Onboarding/Yeti-hello.webp',
      imagePng: '/images/Onboarding/Yeti-hello.png',
      buttonConfirm: {
        label: 'Créer mon premier plan publicitaire',
        onClick: () => {
          companyId && navigate(`company/${companyId}/media-plan/create`)
        }
      },
      buttonCancel: {},
      skipIntroduction: false
    }
  }
}
