import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'

export const Container = styled.div<{ width: number; height?: string }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height ?? '100%'};
  border-radius: 10px;
  box-shadow: 0px 0px 3px #142a4c80;
  background-color: ${Colors.YETIC_WHITE};
  box-sizing: border-box;
  padding: ${Padding.p8};
`

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`

export const titleStyle: CSSProperties = {
  width: '100%',
  textAlign: 'center',
  fontSize: '21px',
  fontWeight: '700'
}
