import React from 'react'

import {
  DeleteImageButton,
  InputFileContainer,
  InputFileInfo,
  InputFileLabel,
  InputImagePreview,
  InputImagePreviewContainer,
  ScoreContainer,
  StyledInputFile,
  fileNameTextStyle,
  textStyle
} from './styles'
import PictureIcon from '../Icon/picture'
import CrossClose from '../Icon/crossClose'
import { InputError } from '../Input/Styles'
import {
  getFileSizeInKo,
  calculateCO2Score,
  formatFileSize
} from '../../helpers/image'
import CO2Score from '../CO2Score'
import { CustomTooltip } from '../CustomToolTip'
import DefaultText from '../DefaultText'

type InputFileProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDelete: () => void
  selectedFile: File | null
  fileUrl?: string
  uniqueKey: string
  error?: string
}

const InputFile = ({
  handleChange,
  handleDelete,
  selectedFile,
  fileUrl,
  uniqueKey,
  error
}: InputFileProps) => {
  return (
    <>
      <InputFileContainer>
        <InputImagePreviewContainer>
          {selectedFile ? (
            <>
              <InputImagePreview src={fileUrl} />
              <DeleteImageButton onClick={handleDelete}>
                <CrossClose width="15px" height="15px" />
              </DeleteImageButton>
            </>
          ) : (
            <PictureIcon style={{ width: '50px' }} />
          )}
        </InputImagePreviewContainer>
        {!selectedFile && (
          <InputFileLabel htmlFor={`inputTag${uniqueKey}`}>
            Parcourir
          </InputFileLabel>
        )}
        <StyledInputFile
          onChange={handleChange}
          id={`inputTag${uniqueKey}`}
          type="file"
          accept=".png, .jpg, .jpeg"
        />
        <InputFileInfo>
          {
            <DefaultText size="medium" style={fileNameTextStyle}>
              {selectedFile?.name ?? 'Aucune image sélectionnée'}
            </DefaultText>
          }
          {selectedFile && (
            <ScoreContainer>
              <CO2Score score={calculateCO2Score(selectedFile)} />
              <DefaultText size="small" style={textStyle}>
                {formatFileSize(getFileSizeInKo(selectedFile))}
              </DefaultText>
              <CustomTooltip
                dark
                hover
                content="Le format d'image affecte sa taille. Par exemple, les PNG sont souvent plus lourds que les JPEG, augmentant l'empreinte CO2. Les formats comme WebP offrent un bon équilibre entre qualité et taille. Pensez à optimiser vos images pour un web plus écologique."
              />
            </ScoreContainer>
          )}
        </InputFileInfo>
      </InputFileContainer>
      {error && <InputError>{error}</InputError>}
    </>
  )
}

export default InputFile
