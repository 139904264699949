import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import {
  GetCampaignOverallReportingOutputType,
  checkIfGetCampaignAudiencesReportingRowType
} from '../../../../types/reporting/campaign/output'
import { checkIfGetCampaignDevicesReporting } from './devices'
import { checkIfGetCampaignGlobalReporting } from './global'

type GetCampaignOverallReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignOverallReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignOverallReportingInputType): Promise<GetCampaignOverallReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/overall${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignOverallReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignOverallReporting = (
  value: any
): value is GetCampaignOverallReportingOutputType => {
  return (
    value &&
    value.kpi &&
    checkIfGetCampaignGlobalReporting(value.kpi) &&
    value.devices &&
    checkIfGetCampaignDevicesReporting(value.devices, true) &&
    value.genderAge &&
    value.genderAge.clicks &&
    Array.isArray(value.genderAge.clicks) &&
    value.genderAge.clicks.every((row: any) =>
      checkIfGetCampaignAudiencesReportingRowType(row)
    ) &&
    value.genderAge.impressions &&
    Array.isArray(value.genderAge.impressions) &&
    value.genderAge.impressions.every((row: any) =>
      checkIfGetCampaignAudiencesReportingRowType(row)
    )
  )
}
