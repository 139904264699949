import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import Padding from '../../../constants/padding'

const syncingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

export const IconContainer = styled.div<{ isSyncing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${(props) => (props.isSyncing ? syncingAnimation : '')} 2s linear
    infinite;
`

export const SyncStateContainer = styled.div<{ isSynced: boolean }>`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 0 ${Padding.p2};

  ${({ isSynced }) => !isSynced && `cursor: pointer;`}
`
