import React from 'react'

import { Container, ContainerUl, ContainerLi, ContainerSpan } from './styles'
import ProgressionChart, {
  PieChartProps
} from '../../../../../components/ProgressionChart'

export type BenefitsProps = {
  estimatedLabel: string
  accomplishedLabel: string
  pieChart: PieChartProps
}

const Benefits = ({
  estimatedLabel,
  accomplishedLabel,
  pieChart
}: BenefitsProps) => {
  const estimatedValue = Math.floor(pieChart.total.value * 10) / 10
  const accomplishedValue = Math.floor(pieChart.partial.value * 10) / 10
  return (
    <Container>
      <ProgressionChart
        size={pieChart.size}
        total={pieChart.total}
        partial={pieChart.partial}
      />
      <ContainerUl>
        <ContainerLi color={pieChart.partial.color}>
          {accomplishedLabel} :{' '}
          <ContainerSpan>{accomplishedValue} €</ContainerSpan>
        </ContainerLi>
        <ContainerLi color={pieChart.total.color}>
          {estimatedLabel} : <ContainerSpan>{estimatedValue} €</ContainerSpan>
        </ContainerLi>
      </ContainerUl>
    </Container>
  )
}

export default Benefits
