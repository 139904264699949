import {
  FormMediaPlanError,
  FormMediaPlanType
} from '../../../types/pages/mediaPlanCreation'
import {
  checkAudience,
  checkBudget,
  checkEndDate,
  checkGoals,
  checkName,
  checkStartDate
} from './checkers'

type MediaPlanCreationErrors = {
  name: string
  audience: string
  budget: string
  goals: string
  startDate: string
  endDate: string
  errorFromApi: string
}

export const getErrors = (
  formData: FormMediaPlanType,
  errorFromApi: string
): MediaPlanCreationErrors => {
  return {
    name: checkName(formData.name) ? '' : FormMediaPlanError.NAME,
    audience: checkAudience(formData.selectedAudiences)
      ? ''
      : FormMediaPlanError.AUDIENCES,
    budget: checkBudget(formData.amountBudget)
      ? ''
      : FormMediaPlanError.AMOUNT_BUDGET_MIN,
    goals: checkGoals(formData.selectedGoals) ? '' : FormMediaPlanError.GOALS,
    startDate: checkStartDate(formData.startDate)
      ? ''
      : FormMediaPlanError.START_DATE,
    endDate: checkEndDate(formData.startDate, formData.endDate)
      ? ''
      : FormMediaPlanError.END_DATE,
    errorFromApi: errorFromApi ?? ''
  }
}
