import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const LabelledItemsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Padding.p6};
  margin-top: ${Margin.m3};
`
