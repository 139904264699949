import {
  MediaPlanTypeReviewPage,
  checkIfMediaPlanReviewPage
} from './mediaPlan'
import { AdminUserDataType, checkAdminUserData } from './adminUserData'
import {
  CampaignNavigation,
  checkCampaignNavigation
} from '../campaign/navigation'
import {
  GoogleCampaignType,
  checkIfGoogleCampaign
} from '../campaign/google/googleCampaign'
import {
  LinkedinCampaignType,
  checkIfLinkedinCampaign
} from '../campaign/linkedin/linkedinCampaign'
import { PlatformBoolean, checkIfPlatformBoolean } from '../platform'

type GoogleCampaignReview = GoogleCampaignType & { adsCount: number }
type LinkedinCampaignReview = LinkedinCampaignType & { adsCount: number }

export type MediaPlanReviewType = {
  mediaPlan: MediaPlanTypeReviewPage & { co2: number }
  campaigns: (GoogleCampaignReview | LinkedinCampaignReview)[]
  adminUserData: AdminUserDataType | null
  platformConnection: PlatformBoolean
  navigation: CampaignNavigation
}

export const checkIfMediaPlanReview = (
  value: any
): value is MediaPlanReviewType => {
  return (
    value.mediaPlan &&
    checkIfMediaPlanReviewPage(value.mediaPlan) &&
    value.mediaPlan.co2 !== undefined &&
    typeof value.mediaPlan.co2 === 'number' &&
    value.campaigns &&
    Array.isArray(value.campaigns) &&
    value.campaigns.every((campaign: any) => checkIfReviewCampaign(campaign)) &&
    checkCampaignNavigation(value.navigation) &&
    checkIfPlatformBoolean(value.platformConnection) &&
    checkAdminUserData(value.adminUserData)
  )
}

export const checkIfReviewGoogleCampaign = (
  value: any
): value is GoogleCampaignReview => {
  return (
    value &&
    value.adsCount !== undefined &&
    typeof value.adsCount === 'number' &&
    checkIfGoogleCampaign(value)
  )
}

export const checkIfReviewLinkedinCampaign = (
  value: any
): value is LinkedinCampaignReview => {
  return (
    value &&
    value.adsCount !== undefined &&
    typeof value.adsCount === 'number' &&
    checkIfLinkedinCampaign(value)
  )
}

export const checkIfReviewCampaign = (
  value: any
): value is GoogleCampaignReview | LinkedinCampaignReview => {
  return (
    checkIfReviewGoogleCampaign(value) || checkIfReviewLinkedinCampaign(value)
  )
}
