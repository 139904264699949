import styled from '@emotion/styled'
import Colors from '../../../constants/colors'

export const Container = styled.div<{ padding?: string }>`
  width: 100%;
  padding: ${(props) => props.padding ?? '0'};
  box-sizing: border-box;
`

export const Separator = styled.div<{
  color?: string
  marginTop?: string
  width?: string
}>`
  width: ${(props) => props.width ?? '100%'};
  background-color: ${(props) => props.color ?? Colors.NEW_YETIC_BLUE_DARK};
  height: 2px;
  margin-top: ${(props) => props.marginTop ?? '0'};
`
