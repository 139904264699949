import axios from 'axios'

import { backendURL } from '../../../constants/urls'
import { getErrorCode } from '../../getErrorCode'
import {
  VerifyTokenErrorEnum,
  VerifyTokenErrorTranslation
} from '../../../types/error/auth/verifyToken'

export const handleVerifyToken = async (
  token: string | undefined
): Promise<{ userActivated: boolean } | { error: string }> => {
  if (token) {
    try {
      const url = `${backendURL}/token/verify/${token}`
      const response = await axios.get(url)
      if (checkIfVerifyTokenOutput(response.data)) {
        return response.data
      }
      return {
        error: VerifyTokenErrorTranslation[VerifyTokenErrorEnum.UNKNOWN]
      }
    } catch (error) {
      const errorCode = getErrorCode(error) ?? null
      if (
        errorCode &&
        Object.values<string>(VerifyTokenErrorEnum).includes(errorCode)
      ) {
        return {
          error: VerifyTokenErrorTranslation[errorCode as VerifyTokenErrorEnum]
        }
      }
      return {
        error: VerifyTokenErrorTranslation[VerifyTokenErrorEnum.UNKNOWN]
      }
    }
  } else {
    return {
      error:
        VerifyTokenErrorTranslation[VerifyTokenErrorEnum.TOKEN_NOT_RECEIVED]
    }
  }
}

export const checkIfVerifyTokenOutput = (
  data: any
): data is { userActivated: boolean } => {
  return (
    data &&
    data.userActivated != null &&
    (data.userActivated === 0 || data.userActivated === 1)
  )
}
