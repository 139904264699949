/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getMediaPlanReview } from '../../helpers/queries/mediaPlan/getMediaPlanReview'
import { MediaPlanReviewDataType } from '../../types/pages/mediaPlanReview'
import LoadingPage from '../LoadingPage'
import { getBidStrategyEnum } from '../../types/bidStrategy'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import {
  archiveCampaign,
  checkIfArchiveCampaign
} from '../../helpers/queries/campaign/archiveCampaign'
import {
  checkIfRejectMediaPlan,
  rejectMediaPlan
} from '../../helpers/queries/mediaPlan/rejectMediaPlan'
import {
  checkIfValidateMediaPlan,
  validateMediaPlan
} from '../../helpers/queries/mediaPlan/validateMediaPlan'
import { getCO2andUnit } from '../../helpers/getCO2andUnit'
import { checkAdminUserDataNotNull } from '../../types/mediaPlan/adminUserData'
import { MediaPlanAudience } from '../../types/mediaPlanAudience'
import { checkIfReviewGoogleCampaign } from '../../types/mediaPlan/review'
import { PlatformAPIEnum } from '../../types/platform'
import { deepClone } from '../../helpers/clone'
import {
  checkIfSyncCampaign,
  syncCampaign
} from '../../helpers/queries/campaign/syncCampaign'
import { PlatformConnectionModalType } from './types'
import EditorMediaPlanReview from './editor'
import AdvertiserMediaPlanReview from './advertiser'
import SelfEditorMediaPlanReview from './selfEditor'

const MediaPlanReview = () => {
  const [reviewData, setReviewData] = useState<MediaPlanReviewDataType | null>(
    null
  )
  const [hasFetchError, setHasFetchError] = useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [reasonForRejection, setReasonForRejection] = useState('')
  const [submitRejectError, setSubmitRejectError] = useState('')
  const [rejectValidationMessage, setRejectValidationMessage] = useState('')
  const [validateError, setValidateError] = useState('')
  const [validateValidationMessage, setValidateValidationMessage] = useState('')
  const [paymentType, setPaymentType] = useState(-1)
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false)
  const [isSubmitValidateLoading, setIsSubmitValidateLoading] = useState(false)
  const [isSubmitRejectLoading, setIsSubmitRejectLoading] = useState(false)
  const [hasRejectBeenSubmitted, setHasRejectBeenSubmitted] = useState(false)
  const [campaignIdCO2ModalOpen, setCampaignIdCO2ModalOpen] = useState('')
  const [dataAudienceModal, setDataAudienceModal] =
    useState<MediaPlanAudience | null>(null)
  const [platformConnectionModal, setPlatformConnectionModal] =
    useState<PlatformConnectionModalType | null>(null)
  const [campaignCreationModal, setCampaignCreationModal] =
    useState<PlatformConnectionModalType | null>(null)

  const navigate = useNavigate()
  const { mediaPlanId } = useParams()
  const { isEditor, isSelfEditor } = useContext(AppContext)

  const flow = FlowEnum.creation

  useEffect(() => {
    if (mediaPlanId) {
      ;(async function () {
        const result = await getMediaPlanReview({
          mediaPlanId
        })
        if (result != null) {
          const newMediaPlan = {
            name: result.mediaPlan.name,
            isEditorPaying: result.mediaPlan.isEditorPaying,
            budget: result.mediaPlan.budget,
            audiences: result.mediaPlan.audiences,
            goals: result.mediaPlan.goals,
            period: {
              start: result.mediaPlan.period.start,
              end: result.mediaPlan.period.end
            },
            status: result.mediaPlan.status,
            editor: result.mediaPlan.editor,
            co2:
              getCO2andUnit(result.mediaPlan.co2).co2 +
              ` ${getCO2andUnit(result.mediaPlan.co2).co2Unit}`
          }

          const newCampaigns = result.campaigns.map((campaign) => {
            if (checkIfReviewGoogleCampaign(campaign)) {
              return {
                id: campaign.id,
                name: campaign.name,
                platform: campaign.platform,
                budget: campaign.budget,
                goal: campaign.goal,
                bidStrategy: getBidStrategyEnum(campaign.bidStrategy),
                period: {
                  start: new Date(campaign.period.start).toLocaleDateString(),
                  end: new Date(campaign.period.end).toLocaleDateString()
                },
                format: campaign.format,
                devices: campaign.devices,
                co2:
                  getCO2andUnit(campaign.co2).co2 +
                  ` ${getCO2andUnit(campaign.co2).co2Unit}`,
                adsCount: campaign.adsCount,
                isPlatformSynced: campaign.isPlatformSynced
              }
            } else {
              return {
                id: campaign.id,
                name: campaign.name,
                platform: campaign.platform,
                budget: campaign.budget,
                goal: campaign.goal,
                period: {
                  start: new Date(campaign.period.start).toLocaleDateString(),
                  end: new Date(campaign.period.end).toLocaleDateString()
                },
                format: campaign.format,
                co2:
                  getCO2andUnit(campaign.co2).co2 +
                  ` ${getCO2andUnit(campaign.co2).co2Unit}`,
                adsCount: campaign.adsCount,
                isPlatformSynced: campaign.isPlatformSynced
              }
            }
          })

          setReviewData({
            mediaPlan: newMediaPlan,
            campaigns: newCampaigns,
            adminUserData: result.adminUserData,
            platformConnection: result.platformConnection,
            navigation: result.navigation
          })
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [mediaPlanId])

  useEffect(() => {
    if (validateValidationMessage !== '' || rejectValidationMessage !== '') {
      setTimeout(() => {
        navigate('/')
      }, 4000)
    }
  }, [rejectValidationMessage, validateValidationMessage])

  useEffect(() => {
    if (mediaPlanId && isSubmitValidateLoading) {
      /* if (checkPaymentType(paymentType)) { */
      ;(async function () {
        const result = await validateMediaPlan({
          mediaPlanId,
          isMonthlyPayment: paymentType === 0
        })
        if (checkIfValidateMediaPlan(result)) {
          setValidateValidationMessage(
            'Le plan média a bien été validé. Vous allez recevoir un email pour le paiement.'
          )
        } else {
          setValidateError(result.error)
        }
        setIsSubmitValidateLoading(false)
      })()
    } /* else {
        setValidateError('Veuillez renseigner un moyen de paiement')
      }
    } */
  }, [isSubmitValidateLoading])

  useEffect(() => {
    if (mediaPlanId && reviewData && isSubmitRejectLoading) {
      if (
        checkReasonForRejection(reasonForRejection) &&
        checkAdminUserDataNotNull(advertiserData)
      ) {
        ;(async function () {
          const result = await rejectMediaPlan({
            mediaPlan: {
              id: mediaPlanId,
              name: reviewData.mediaPlan.name
            },
            receiverEmail: reviewData.mediaPlan.editor.authorEmail,
            advertiser: {
              id: reviewData.navigation.company.id,
              companyName: reviewData.navigation.company.name,
              email: advertiserData.email
            },
            reasonOfRejection: reasonForRejection
          })
          if (checkIfRejectMediaPlan(result)) {
            setRejectValidationMessage(
              'Le plan média a bien été rejeté et un email a été envoyé à l’agence.'
            )
            setReasonForRejection('')
          } else {
            setSubmitRejectError(result.error)
          }
          setIsSubmitRejectLoading(false)
        })()
      }
    }
  }, [isSubmitRejectLoading])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!mediaPlanId || !reviewData) {
    return <LoadingPage />
  }

  const availableBudget =
    Math.round(
      (reviewData.mediaPlan.budget -
        reviewData.campaigns.reduce(
          (acc, campaign) => acc + campaign.budget,
          0
        )) *
        100
    ) / 100

  const startDateMediaPlan = new Date(
    reviewData.mediaPlan.period.start
  ).toLocaleDateString()
  const endDateMediaPlan = new Date(
    reviewData.mediaPlan.period.end
  ).toLocaleDateString()

  const rejectError = !reasonForRejection
    ? 'Veuillez renseigner la raison de votre refus'
    : ''

  const handleViewMore = (id: string) => {
    navigate(`/campaign/${id}/review`)
  }

  const handleEdit = () => {
    navigate(`/media-plan/${mediaPlanId}/edit?flow=${flow}`)
  }

  const handleDeleteCampaign = async (id: string) => {
    const result = await archiveCampaign({
      campaignId: id
    })
    if (checkIfArchiveCampaign(result)) {
      window.location.reload()
    } else {
      console.error('Impossible to archive the campaign')
    }
  }

  const handleEditCampaign = (id: string) => {
    navigate(`/campaign/${id}/edit`)
  }

  const handleAddCampaign = (platform: PlatformAPIEnum) => {
    navigate(`/media-plan/${mediaPlanId}/campaign/create?platform=${platform}`)
  }

  const handleOnClickPlatformCampaignCreation = (platform: PlatformAPIEnum) => {
    if (!reviewData.platformConnection?.[platform]) {
      setCampaignCreationModal({ platform, flow: 'campaign' })
    } else {
      handleAddCampaign(platform)
    }
  }

  const handleOnClickPlatformConnection = (platform: PlatformAPIEnum) => {
    if (!reviewData.platformConnection?.[platform]) {
      setPlatformConnectionModal({ platform, flow: 'connection' })
    }
  }

  const handleSyncCampaign = async (id: string): Promise<boolean> => {
    const syncCampaignResult = await syncCampaign({
      campaignId: id
    })

    if (checkIfSyncCampaign(syncCampaignResult)) {
      setReviewData({
        ...deepClone(reviewData),
        campaigns: reviewData.campaigns.map((campaign) => {
          if (campaign.id === id) {
            return {
              ...campaign,
              isPlatformSynced: true,
              syncError: ''
            }
          } else {
            return campaign
          }
        })
      })

      return true
    } else {
      setReviewData({
        ...deepClone(reviewData),
        campaigns: reviewData.campaigns.map((campaign) => {
          if (campaign.id === id) {
            return {
              ...campaign,
              syncError: syncCampaignResult.error
            }
          } else {
            return campaign
          }
        })
      })

      return false
    }
  }

  const handleChangeReasonForRejection = (reason: string) => {
    setReasonForRejection(reason)
  }

  const checkReasonForRejection = (value: string) => value.length > 0

  const advertiserData = reviewData.adminUserData

  const handleSubmitReject = () => {
    if (!rejectError) {
      setIsSubmitRejectLoading(true)
    }
    setHasRejectBeenSubmitted(true)
  }

  const handleChangePaymentType = (value: number) => {
    setPaymentType(value)
  }

  const checkPaymentType = (value: number) => value >= 0

  const handleSubmitValidate = () => {
    setIsSubmitValidateLoading(true)
  }

  return (
    <AuthenticatedTemplate isEditor={isEditor ?? false} navigate={navigate}>
      {isEditor ? (
        <EditorMediaPlanReview
          reviewData={reviewData}
          availableBudget={availableBudget}
          mediaPlanId={mediaPlanId}
          startDateMediaPlan={startDateMediaPlan}
          endDateMediaPlan={endDateMediaPlan}
          navigate={navigate}
          dataAudienceModal={dataAudienceModal}
          setDataAudienceModal={setDataAudienceModal}
          platformConnectionModal={platformConnectionModal}
          setPlatformConnectionModal={setPlatformConnectionModal}
          campaignCreationModal={campaignCreationModal}
          setCampaignCreationModal={setCampaignCreationModal}
          campaignIdCO2ModalOpen={campaignIdCO2ModalOpen}
          setCampaignIdCO2ModalOpen={setCampaignIdCO2ModalOpen}
          isValidateModalOpen={isValidateModalOpen}
          setIsValidateModalOpen={setIsValidateModalOpen}
          validateValidationMessage={validateValidationMessage}
          isSubmitValidateLoading={isSubmitValidateLoading}
          validateError={validateError}
          paymentType={paymentType}
          handleChangePaymentType={handleChangePaymentType}
          handleOnClickPlatformConnection={handleOnClickPlatformConnection}
          handleOnClickPlatformCampaignCreation={
            handleOnClickPlatformCampaignCreation
          }
          handleAddCampaign={handleAddCampaign}
          handleViewMore={handleViewMore}
          handleEdit={handleEdit}
          handleSyncCampaign={handleSyncCampaign}
          handleEditCampaign={handleEditCampaign}
          handleDeleteCampaign={handleDeleteCampaign}
          handleSubmitValidate={handleSubmitValidate}
        />
      ) : isSelfEditor ? (
        <SelfEditorMediaPlanReview
          reviewData={reviewData}
          availableBudget={availableBudget}
          mediaPlanId={mediaPlanId}
          startDateMediaPlan={startDateMediaPlan}
          endDateMediaPlan={endDateMediaPlan}
          navigate={navigate}
          dataAudienceModal={dataAudienceModal}
          setDataAudienceModal={setDataAudienceModal}
          platformConnectionModal={platformConnectionModal}
          setPlatformConnectionModal={setPlatformConnectionModal}
          campaignCreationModal={campaignCreationModal}
          setCampaignCreationModal={setCampaignCreationModal}
          campaignIdCO2ModalOpen={campaignIdCO2ModalOpen}
          setCampaignIdCO2ModalOpen={setCampaignIdCO2ModalOpen}
          isValidateModalOpen={isValidateModalOpen}
          setIsValidateModalOpen={setIsValidateModalOpen}
          validateValidationMessage={validateValidationMessage}
          isSubmitValidateLoading={isSubmitValidateLoading}
          validateError={validateError}
          paymentType={paymentType}
          handleChangePaymentType={handleChangePaymentType}
          handleOnClickPlatformConnection={handleOnClickPlatformConnection}
          handleOnClickPlatformCampaignCreation={
            handleOnClickPlatformCampaignCreation
          }
          handleAddCampaign={handleAddCampaign}
          handleViewMore={handleViewMore}
          handleEdit={handleEdit}
          handleSyncCampaign={handleSyncCampaign}
          handleEditCampaign={handleEditCampaign}
          handleDeleteCampaign={handleDeleteCampaign}
          handleSubmitValidate={handleSubmitValidate}
        />
      ) : (
        <AdvertiserMediaPlanReview
          reviewData={reviewData}
          availableBudget={availableBudget}
          mediaPlanId={mediaPlanId}
          startDateMediaPlan={startDateMediaPlan}
          endDateMediaPlan={endDateMediaPlan}
          navigate={navigate}
          dataAudienceModal={dataAudienceModal}
          setDataAudienceModal={setDataAudienceModal}
          campaignIdCO2ModalOpen={campaignIdCO2ModalOpen}
          setCampaignIdCO2ModalOpen={setCampaignIdCO2ModalOpen}
          isValidateModalOpen={isValidateModalOpen}
          setIsValidateModalOpen={setIsValidateModalOpen}
          isRejectModalOpen={isRejectModalOpen}
          setIsRejectModalOpen={setIsRejectModalOpen}
          reasonForRejection={reasonForRejection}
          handleChangeReasonForRejection={handleChangeReasonForRejection}
          validateValidationMessage={validateValidationMessage}
          handleSubmitValidate={handleSubmitValidate}
          isSubmitValidateLoading={isSubmitValidateLoading}
          validateError={validateError}
          rejectValidationMessage={rejectValidationMessage}
          handleSubmitReject={handleSubmitReject}
          isSubmitRejectLoading={isSubmitRejectLoading}
          hasRejectBeenSubmitted={hasRejectBeenSubmitted}
          rejectError={rejectError}
          submitRejectError={submitRejectError}
          paymentType={paymentType}
          handleChangePaymentType={handleChangePaymentType}
          handleViewMore={handleViewMore}
        />
      )}
    </AuthenticatedTemplate>
  )
}

export default MediaPlanReview
