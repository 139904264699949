import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const Header = styled.div`
  padding-top: ${Padding.p10};
  width: 100%;
  text-align: left;
  margin-bottom: ${Margin.m3};
`

export const PlatformsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: auto;
  padding-top: ${Padding.p6};
`

export const PlatformTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Margin.m5};
  margin-top: ${Margin.m7};
`

export const PlatformsPageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${Margin.m6};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ${Margin.m5};
`

export const RowTextContainer = styled.div<{ isConnected: boolean }>`
  min-width: 25vw;
  min-height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: ${Padding.p5} ${Padding.p4} ${Padding.p5} ${Padding.p6};
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${Margin.m4};
  color: ${({ isConnected }) => (isConnected ? '#4caf50' : '#f44336')};
`

export const titleStyle: CSSProperties = {
  marginBottom: 0,
  marginTop: Margin.m2
}

export const textStyle: CSSProperties = {
  marginBottom: 0
}

export const ConnectionStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${Margin.m3};
  flex: 1;
`
