import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import {
  checkIfGetCampaignImpactReporting,
  emptyCampaignImpact,
  GetCampaignImpactReportingOutputType
} from '../../../../types/reporting/campaign/output'

type GetCampaignImpactReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignImpactReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignImpactReportingInputType): Promise<GetCampaignImpactReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/impact${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignImpactReporting(response.data.impact)) {
        return response.data.impact
      } else {
        return emptyCampaignImpact
      }
    }

    return null
  } catch (_) {
    return null
  }
}
