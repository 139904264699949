import React from 'react'

import { Dot, PaginationDots } from './styles'

type PaginationProps = {
  totalSlides: number
  currentSlide: number
  handleChangeSlide: (index: number) => void
  isSelfEditor?: boolean
}

const Pagination = ({
  totalSlides,
  currentSlide,
  handleChangeSlide,
  isSelfEditor
}: PaginationProps) => {
  return (
    <PaginationDots>
      {Array.from({ length: totalSlides }, (_, index) =>
        !isSelfEditor || (isSelfEditor && index !== 3) ? (
          <Dot
            key={index}
            isActive={currentSlide === index}
            onClick={() => {
              handleChangeSlide(index)
            }}
          />
        ) : null
      )}
    </PaginationDots>
  )
}

export default Pagination
