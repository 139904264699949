import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import { GetCampaignDownloadReportingOutputType } from '../../../../types/reporting/campaign/output'

type GetCampaignDownloadReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignDownloadReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignDownloadReportingInputType): Promise<GetCampaignDownloadReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/download${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignDownloadReporting(response.data.data)) {
        return response.data.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignDownloadReporting = (
  value: any
): value is GetCampaignDownloadReportingOutputType => {
  return (
    Array.isArray(value) &&
    value.every(
      (row) =>
        row &&
        row.date &&
        typeof row.date === 'string' &&
        row.clicks !== undefined &&
        typeof row.clicks === 'number' &&
        row.impressions !== undefined &&
        typeof row.impressions === 'number' &&
        row.cost !== undefined &&
        typeof row.cost === 'number' &&
        row.co2 !== undefined &&
        typeof row.co2 === 'number' &&
        row.impact !== undefined &&
        typeof row.impact === 'number'
    )
  )
}
