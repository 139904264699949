export enum LangEnum {
  FR = 'Français',
  EN = 'Anglais'
}

export enum LangAPIEnum {
  FR = 'fr',
  EN = 'en'
}

export const getLangAPIEnum = (value: LangEnum): LangAPIEnum => {
  switch (value) {
    case LangEnum.FR:
      return LangAPIEnum.FR
    case LangEnum.EN:
      return LangAPIEnum.EN
    default:
      return LangAPIEnum.FR
  }
}

export const getLangEnum = (value: LangAPIEnum): LangEnum => {
  switch (value) {
    case LangAPIEnum.FR:
      return LangEnum.FR
    case LangAPIEnum.EN:
      return LangEnum.EN
    default:
      return LangEnum.FR
  }
}

export const checkIfLangEnum = (value: any): value is LangEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(LangEnum) as string[]).includes(value)
  )
}

export const checkIfLangApiEnum = (value: any): value is LangAPIEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(LangAPIEnum) as string[]).includes(value)
  )
}
