import React from 'react'

import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import DefaultText from '../../../components/DefaultText'
import Margin from '../../../constants/margin'
import { InputError } from '../../../components/Input/Styles'

type ValidateModalProps = {
  onClose: () => void
  paymentType: number
  handleChangePaymentType: (paymentType: number) => void
  isLoading: boolean
  error: string
  validationMessage: string
  handleSubmit: () => void
  budget: number
  name: string
}

const ValidateModal = ({
  onClose,
  paymentType,
  handleChangePaymentType,
  isLoading,
  error,
  validationMessage,
  handleSubmit,
  budget,
  name
}: ValidateModalProps) => {
  return (
    <ConfirmationModal
      onClose={onClose}
      onClickReject={onClose}
      onClickConfirm={handleSubmit}
      textConfirm="Valider et passer au paiement"
      isConfirmLoading={isLoading}
      textReject={validationMessage ? 'Fermer' : 'Annuler'}
      title="Validation du plan média"
      confirmHidden={!!validationMessage}
      divButtonsStyle={{ justifyContent: 'flex-end' }}
      style={{ maxWidth: '400px' }}
    >
      <DefaultText>
        <p>{`Vous vous apprêtez à valider le plan média « ${name} » dont le budget s'élève à ${budget}€ HT.`}</p>
        <p>{`Le montant total à régler inclura les 20% de TVA.`}</p>
      </DefaultText>
      {/* <DefaultText size="small" style={{ marginBottom: Margin.m6 }}>
        Veuillez sélectionner un moyen de paiement pour valider votre plan média
      </DefaultText>
      <RadioListTmp
        values={[
          {
            label: 'Paiement en 1x',
            description: 'Paiement de la totalité du plan média en 1 fois'
          }
        ]}
        selectedValue={paymentType}
        onChange={handleChangePaymentType}
      /> */}
      {error && (
        <InputError style={{ textAlign: 'left', marginTop: Margin.m6 }}>
          {error}
        </InputError>
      )}
      {validationMessage && (
        <DefaultText
          size="small"
          style={{ textAlign: 'left', marginTop: Margin.m6 }}
        >
          {validationMessage}
        </DefaultText>
      )}
    </ConfirmationModal>
  )
}

export default ValidateModal
