import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import FontSize from '../../../../../../constants/fontSize'
import Padding from '../../../../../../constants/padding'

export const modalStyle: CSSProperties = {
  zIndex: 9997,
  width: '40vw'
}

export const modalOverlayStyle: CSSProperties = {
  zIndex: 9996
}

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  max-width: 70vw;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  padding-right: ${Padding.p7};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: ${Padding.p7} ${Padding.p4};
`

export const columnTitleStyle: CSSProperties = {
  fontSize: FontSize.YETIC_HOME_BOX_TITLE,
  fontWeight: '600'
}

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  padding-bottom: ${Padding.p6};
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60px;
`

export const checkboxStyle: CSSProperties = {
  justifyContent: 'flex-start',
  position: 'absolute',
  width: '100%'
}

export const audienceNameStyle: CSSProperties = {
  paddingLeft: Padding.p8,
  cursor: 'pointer',
  margin: 0,
  textAlign: 'left'
}

export const disabledAudienceNameStyle: CSSProperties = {
  paddingLeft: Padding.p8,
  margin: 0,
  textAlign: 'left',
  cursor: 'default',
  opacity: 0.5
}

export const mediaPlanNameStyle: CSSProperties = {
  margin: 0,
  textAlign: 'left'
}

export const disabledMediaPlanNameStyle: CSSProperties = {
  margin: 0,
  textAlign: 'left',
  opacity: 0.5
}

export const seeDetailsButtonStyle: CSSProperties = {
  height: '20px',
  display: 'flex',
  alignItems: 'center'
}

export const loaderStyle: CSSProperties = {
  alignSelf: 'center'
}

export const BottomModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Padding.p8} 0 ${Padding.p2};
  border-top: 1px solid #e5e5e5;
`

export const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  text-align: left;
`
