import React, { useState } from 'react'

import {
  DivDetails,
  listTitleStyle,
  DivColumns,
  Column1,
  Column2,
  btnStyle,
  cardTitleStyle,
  DivButtons,
  adCountWarningStyle
} from './styles'
import DefaultText from '../../../components/DefaultText'
import Button from '../../../components/Button'
import Colors from '../../../constants/colors'
import { StrategyEnum, StrategyTranslation } from '../../../types/strategy'
import Card from '../../../components/Card'
import TitleCount from '../../../pages/parts/TitleCount'
import { PlatformAPIEnum } from '../../../types/platform'
import PlatformLogo from '../../../components/PlatformLogo'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { TipsInfos } from '../../../components/Tips'
import { FormatAPIEnum } from '../../../types/format'

export type Container = {
  column1: JSX.Element
  column2?: JSX.Element
  items?: ContainerBlock[]
  preview?: JSX.Element
}

export type ContainerBlock = {
  id?: string
  title: string
  container: Container
  platform?: PlatformAPIEnum
  isPlatformSynced?: boolean
  syncError?: string
}

export type ReviewContentProps = {
  listType: StrategyEnum | null
  container: ContainerBlock
  isEditor?: boolean | null
  isSelfEditor?: boolean | null
  footer?: JSX.Element
  tipsBudget?: string | JSX.Element
  format?: FormatAPIEnum
  handleViewMore?: (id: string) => void
  openDeleteModal?: () => void
  handleDelete?: (id: string) => Promise<void>
  handleEdit?: (id: string) => void
  handleSync?: (id: string) => Promise<boolean>
  bottomMessage?: string
}

const ReviewContent = ({
  listType,
  container,
  isEditor,
  isSelfEditor,
  tipsBudget,
  footer,
  handleViewMore,
  handleDelete,
  handleEdit,
  handleSync,
  format,
  bottomMessage
}: ReviewContentProps) => {
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null)

  const openDeleteModal = (id: string | null) => {
    setDeleteModalId(id)
  }

  const isListEmpty = container.container.items?.length === 0

  const handleViewMoreIfExist = (blockId: string | null) => {
    handleViewMore && handleViewMore(blockId ?? '')
  }
  const handleDeleteIfExist = (blockId: string | null) => {
    handleDelete && handleDelete(blockId ?? '')
  }
  const handleEditIfExist = (blockId: string | null) => {
    handleEdit && handleEdit(blockId ?? '')
  }
  const handleSyncIfExist = async (
    blockId: string | null
  ): Promise<boolean> => {
    if (handleSync) {
      const result = await handleSync(blockId ?? '')
      return result
    }
    return false
  }

  let strategy = listType
    ? StrategyTranslation[listType].toLocaleLowerCase()
    : ''

  if (isSelfEditor) {
    if (listType === StrategyEnum.AD) {
      strategy = 'publicité'
    }
  }

  const emptyListMessageSelfEditor =
    listType &&
    `Vous n'avez pas encore de ${strategy}, ajoutez en une dès maintenant !`

  const emptyListMessageEditor =
    listType &&
    `Vous n'avez pas encore ${
      listType === StrategyEnum.AD ? `d'` : `de `
    }${strategy}, ajoutez en une dès maintenant !`

  const emptyListMessageAdvertiser =
    listType &&
    `Vous n'avez pas encore ${
      listType === StrategyEnum.AD ? `d'` : `de `
    }${strategy}, revenez plus tard !`

  const seeMore = listType
    ? `Voir ${
        listType === StrategyEnum.AD && !isSelfEditor ? "l'" : 'la '
      }${strategy}`
    : null

  return (
    <>
      {isListEmpty && listType ? (
        <DefaultText style={{ textAlign: 'center' }}>
          {isEditor
            ? emptyListMessageEditor
            : isSelfEditor
            ? emptyListMessageSelfEditor
            : emptyListMessageAdvertiser}
        </DefaultText>
      ) : (
        <>
          {listType && (
            <TitleCount
              title={`Liste des ${strategy}s`}
              count={container.container.items?.length ?? 0}
              style={listTitleStyle}
            />
          )}
          {container.container.items?.map((item) => (
            <Card
              key={item.title}
              title={item.title}
              headerColor={Colors.YETIC_BLUE}
              titleSize="medium"
              titleStyle={cardTitleStyle}
              titleIcon={
                item.platform ? (
                  <div>
                    <PlatformLogo
                      platform={item.platform}
                      color={Colors.YETIC_WHITE}
                      width="18px"
                      height="18px"
                    />
                  </div>
                ) : container.platform ? (
                  <div>
                    <PlatformLogo
                      platform={container.platform}
                      color={Colors.YETIC_WHITE}
                      width="18px"
                      height="18px"
                    />
                  </div>
                ) : undefined
              }
              isSynced={item.isPlatformSynced}
              onSyncClick={async () => {
                return handleSyncIfExist(item.id ?? null)
              }}
              syncError={item.syncError}
            >
              <DivDetails>
                <DivColumns>
                  <Column1>{item.container.column1}</Column1>
                  {item.container.column2 && (
                    <Column2>{item.container.column2}</Column2>
                  )}
                </DivColumns>
                {listType && (
                  <DivButtons>
                    {handleDelete && (isEditor || isSelfEditor) && (
                      <Button
                        type="textButton"
                        style={btnStyle}
                        onClick={() => {
                          openDeleteModal(item.id ?? null)
                        }}
                      >
                        Supprimer
                      </Button>
                    )}
                    {handleEdit && (isEditor || isSelfEditor) && (
                      <Button
                        type="textButton"
                        style={btnStyle}
                        onClick={() => {
                          handleEditIfExist(item.id ?? null)
                        }}
                      >
                        Modifier
                      </Button>
                    )}
                    {seeMore && (
                      <Button
                        type="textButton"
                        style={btnStyle}
                        onClick={() => {
                          handleViewMoreIfExist(item.id ?? null)
                        }}
                      >
                        {seeMore}
                      </Button>
                    )}
                  </DivButtons>
                )}
              </DivDetails>
              {deleteModalId && (
                <ConfirmationModal
                  onClose={() => {
                    setDeleteModalId(null)
                  }}
                  onClickConfirm={() => {
                    handleDeleteIfExist(deleteModalId)
                  }}
                  onClickReject={() => {
                    setDeleteModalId(null)
                  }}
                  textConfirm="Supprimer"
                  title="Êtes-vous sûr de vouloir supprimer cet élément ?"
                ></ConfirmationModal>
              )}
            </Card>
          ))}
        </>
      )}
      {(isEditor || isSelfEditor) && tipsBudget && (
        <TipsInfos text={tipsBudget} title="BUDGET" />
      )}
      {isEditor || isSelfEditor ? (
        bottomMessage ? (
          <DefaultText style={adCountWarningStyle}>{bottomMessage}</DefaultText>
        ) : (
          footer
        )
      ) : null}
    </>
  )
}

export default ReviewContent
