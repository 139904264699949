import styled from '@emotion/styled'
import { CSSProperties } from 'react'
import Colors from '../../../constants/colors'

export const SurveyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`

export const SurveyNavigation = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`

export const SurveyContent = styled.div<{ hasAnswers: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 35vh;

  ${({ hasAnswers }) =>
    !hasAnswers &&
    `
    margin-bottom: 0;
    height: 100%;
    justify-content: center;
    gap: 20px;
  `}
`

export const surveyQuestionStyle: CSSProperties = {
  fontSize: '26px',
  lineHeight: '36px',
  fontWeight: '600',
  maxWidth: '1000px',
  marginBottom: 0
}

export const SurveyChoiceButtonsWrapper = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  justify-content: center;
`

export const SurveyChoiceButton = styled.button<{
  activeBorderColor?: string
  activeBackgroundColor?: string
  flex: number
}>`
  font-family: Montserrat;
  border: none;
  box-shadow: 0px 0px 4px #142a4c40;
  flex: ${({ flex }) => flex};
  max-width: 250px;
  min-height: 120px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  padding: 16px;
  cursor: pointer;

  background-color: #fff;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 0px 12px
      ${({ activeBorderColor }) => activeBorderColor ?? '#00000030'};
    transition: all 0.2s;
  }

  &:focus {
    background-color: ${({ activeBackgroundColor }) =>
      activeBackgroundColor ?? '#f1f3ff'};
    box-shadow: 0px 0px 4px #142a4c40;
    transition: all 0.2s;
  }
`

export const SurveyChoiceButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const surveyChoiceTitleStyle: CSSProperties = {
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: 0
}

export const surveyChoiceDescriptionStyle: CSSProperties = {
  fontSize: '14px',
  fontWeight: '300',
  marginBottom: 0
}

export const SurveyButton = styled.button`
  font-family: Montserrat;
  font-size: 16px;
  width: fit-content;
  font-weight: 600;
  color: #fff;
  background-color: ${Colors.YETIC_BLUE_DARK};
  border: none;
  border-radius: 6px;
  padding: 16px 22px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #0c1e3a;
    transition: all 0.2s;
  }
`
