import React from 'react'

import {
  ImageAdCreationFormDataType,
  ImageAdFiles,
  ImageAdFilesUrls
} from '../types'
import InputFile from '../../../components/InputFile'
import { googleRectangleRatio } from '../../../constants/image'
import { ImageAdFilesErrors } from '../../../helpers/imageAd/formErrors'
import { GoogleFormImageAdError } from '../../../types/pages/adImageCreation'
import { PanelType } from '../../../types/templates/panels'
import { ImagesSelectionContainer } from '../styles'

export type GetMarketingImagesSelectionPanelInput = {
  formData: ImageAdCreationFormDataType
  imageUrls: ImageAdFilesUrls
  imageErrors: ImageAdFilesErrors
  handleImageChange: (
    attribute: keyof ImageAdFiles,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleImageDelete: (attribute: keyof ImageAdFiles) => void
  hasBeenSubmitted: boolean
}

export const getMarketingImagesSelectionPanel = ({
  formData,
  imageUrls,
  imageErrors,
  handleImageChange,
  handleImageDelete,
  hasBeenSubmitted
}: GetMarketingImagesSelectionPanelInput): PanelType => {
  const marketingImages: Partial<ImageAdFiles> = {
    marketingImage1: formData.marketingImage1,
    marketingImage2: formData.marketingImage2,
    marketingImage3: formData.marketingImage3
  }

  const marketingImagesKeys = Object.keys(
    marketingImages
  ) as (keyof typeof marketingImages)[]

  return {
    title: 'Sélection des images paysages',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez jusqu'à 3 images (une obligatoire).`,
    sections: [
      {
        title: 'Formats paysages*',
        description: `Choisissez des images au format rectangulaire ${googleRectangleRatio}:1.`,
        content: (
          <ImagesSelectionContainer>
            {marketingImagesKeys.map(
              (img, index) =>
                (index === 0 ||
                  marketingImages[marketingImagesKeys[index - 1]] ||
                  Object.values(marketingImages).find((_, i) => {
                    return i >= index && marketingImages[marketingImagesKeys[i]]
                  })) && (
                  <InputFile
                    key={index}
                    uniqueKey={`marketingImage${index + 1}`}
                    selectedFile={formData[marketingImagesKeys[index]]}
                    fileUrl={imageUrls[marketingImagesKeys[index]]}
                    handleChange={(e) => {
                      handleImageChange(marketingImagesKeys[index], e)
                    }}
                    handleDelete={() => {
                      handleImageDelete(marketingImagesKeys[index])
                    }}
                    error={
                      hasBeenSubmitted
                        ? imageErrors[marketingImagesKeys[index]]
                        : imageErrors[marketingImagesKeys[index]] !==
                          GoogleFormImageAdError.IMAGE_MISSING
                        ? imageErrors[marketingImagesKeys[index]]
                        : ''
                    }
                  />
                )
            )}
          </ImagesSelectionContainer>
        )
      }
    ]
  }
}
