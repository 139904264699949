import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from '../../types/icon'

const MetaIcon = ({
  height = '30px',
  width = '30px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30.09 20"
      style={style}
    >
      <path
        d="M3.551,13.189a5.01,5.01,0,0,0,.582,2.567,1.982,1.982,0,0,0,1.733,1c.849,0,1.623-.209,3.118-2.279,1.2-1.656,2.607-3.983,3.556-5.443l1.608-2.471a23.92,23.92,0,0,1,3.89-4.916A5.885,5.885,0,0,1,21.866,0c2.206,0,4.305,1.278,5.912,3.677a16.814,16.814,0,0,1,2.613,9.347,9.149,9.149,0,0,1-1.08,4.7A4.51,4.51,0,0,1,25.218,20V16.751c1.844,0,2.305-1.7,2.305-3.637a15.382,15.382,0,0,0-2.065-8.031,4.685,4.685,0,0,0-3.75-2.507c-1.552,0-2.8,1.174-4.209,3.264-.747,1.11-1.514,2.465-2.375,3.992l-.949,1.681a42.245,42.245,0,0,1-3.339,5.42C9.169,19.157,7.743,20,5.866,20a4.873,4.873,0,0,1-4.5-2.417A8.762,8.762,0,0,1,.3,13.074Z"
        transform="translate(-0.3 0)"
        fill={color}
      />
      <path
        d="M133.7,3.906C135.191,1.608,137.341,0,139.808,0a6.581,6.581,0,0,1,4.33,1.635c1.622,1.324,3.351,3.506,5.508,7.1l.772,1.289a54.014,54.014,0,0,0,3.55,5.468c.8.97,1.36,1.258,2.086,1.258,1.844,0,2.305-1.7,2.305-3.637l2.866-.09a9.149,9.149,0,0,1-1.08,4.7A4.51,4.51,0,0,1,156.055,20a5.1,5.1,0,0,1-3.839-1.529,21.285,21.285,0,0,1-3.1-4.159l-2.7-4.511a31.067,31.067,0,0,0-3.316-4.715,4.557,4.557,0,0,0-3.353-1.816c-1.285,0-2.375.9-3.287,2.28Z"
        transform="translate(-131.137 0)"
        fill={color}
      />
      <path
        d="M8.919,3.27c-1.285,0-2.375.9-3.287,2.281a14.5,14.5,0,0,0-2.081,7.639,5.01,5.01,0,0,0,.582,2.567l-2.77,1.827A8.762,8.762,0,0,1,.3,13.074,17.243,17.243,0,0,1,2.863,3.906C4.354,1.608,6.5,0,8.97,0Z"
        transform="translate(-0.3 0)"
        fill={color}
      />
    </svg>
  )
}

export default MetaIcon
