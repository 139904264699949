import React from 'react'

import { FullTextAdType, checkIfFullTextAd } from '../../types/ad/google/textAd'
import {
  FullGoogleImageAdType,
  checkIfFullGoogleImageAd
} from '../../types/ad/google/imageAd'
import GooglePreview from '../../components/PreviewAds/Google/Text'
import { AdImageWrapper } from './styles'

type AdPreviewProps = {
  ad: FullTextAdType | FullGoogleImageAdType
  images: Record<string, string> | null
}

const AdPreview = ({ ad, images }: AdPreviewProps) => {
  if (checkIfFullTextAd(ad)) {
    return (
      <GooglePreview
        url={ad.url}
        title1={ad.title1}
        title2={ad.title2}
        title3={ad.title3}
        description={ad.description1}
        description2={ad.description2}
        description3={ad.description3}
      />
    )
  } else if (checkIfFullGoogleImageAd(ad) && images) {
    return (
      <AdImageWrapper>
        <img src={images[ad.id]} width="50%" />
      </AdImageWrapper>
    )
  } else {
    return null
  }
}

export default AdPreview
