import React, { useState } from 'react'
import { NavigateFunction } from 'react-router-dom'

import HeaderSearch from './HeaderSearch'
import { Actions, Head } from './styles'
import HeaderMenu from './HeaderMenu'
import ResetPasswordModal from '../../pages/ResetPassword/ResetPasswordModal'

export type HeaderTypeType = {
  callback?: () => void
  isEditor?: boolean
  navigate: NavigateFunction
}

const Header = ({ callback, isEditor, navigate }: HeaderTypeType) => {
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)

  const menuEntries = [
    {
      title: 'Mon profil',
      onClick: () => {
        navigate && navigate('/profile')
      }
    },
    {
      title: 'Modifier mon mot de passe',
      onClick: () => {
        setResetPasswordModalOpen(true)
      }
    },
    {
      title: 'Plateformes',
      onClick: () => {
        navigate && navigate('/platforms')
      }
    },
    {
      title: 'Se déconnecter',
      onClick: () => {
        navigate && navigate('/logout')
      }
    }
  ]

  return (
    <Head>
      {isEditor ? <HeaderSearch callback={callback} navigate={navigate} /> : ''}
      <Actions>
        <HeaderMenu entries={menuEntries} />
      </Actions>
      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          onClose={() => {
            setResetPasswordModalOpen(false)
          }}
        />
      )}
    </Head>
  )
}

export default Header
