import React, { ChangeEvent, useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import Input from '../../../../components/Input'
import { PanelType } from '../../../../types/templates/panels'
import {
  FormAdType,
  SelfEditorFormAdError
} from '../../../../types/pages/adCreation'
import { AppContext } from '../../../../contexts/AppContext'
import Margin from '../../../../constants/margin'
import GooglePreview from '../../../../components/PreviewAds/Google/Text'
import Button from '../../../../components/Button'
import {
  CreateAdInputType,
  createAd
} from '../../../../helpers/queries/ad/create'
import DefaultText from '../../../../components/DefaultText'
import { deepClone } from '../../../../helpers/clone'
import { getCampaignById } from '../../../../helpers/queries/campaign/getCampaignById'
import {
  CreateAdErrorFromApi,
  checkIfCreateAdErrorCodeValid
} from '../../../../types/error/ad/create'
import Breadcrumb from '../../../../components/BreadCrumb'
import { AdNavigation, emptyAdNavigation } from '../../../../types/ad/ad'
import {
  checkMoreThanOneCharacter,
  checkUrl
} from '../../../../helpers/checkers'
import ErrorPage from '../../../ErrorPage'
import LoadingPage from '../../../LoadingPage'
import {
  Status,
  StatusTranslation,
  getIsEditable
} from '../../../../types/status'
import { FormatAPIEnum } from '../../../../types/format'
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal'
import { ModalContent } from './styles'
import { TipsFormatProps } from '../../../../components/Tips/TipsFormat'

const emptyFormAd = {
  name: '',
  url: '',
  titles: ['', '', ''],
  descriptions: ['', '', ''],
  siteLinks: [],
  catchphrase: []
}

type AdCreationErrors = {
  name: string
  url: string
  titles: string[]
  descriptions: string[]
  errorFromApi: string
}

const SelfEditorGoogleTextAdCreation = () => {
  const [titles, setTitles] = useState(['', '', ''])
  const [descriptions, setDescriptions] = useState(['', '', ''])
  // const [links, setLinks] = useState<OptionLink[]>([])
  // const [catchphrases, setCatchphrases] = useState<string[]>([''])
  const [formAd, setFormAd] = useState<FormAdType>(emptyFormAd)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [showAdditionalParameters, setShowAdditionalParameters] =
    useState(false)
  const [mediaPlanId, setMediaPlanId] = useState<string | null>(null)
  const [errorFromApi, setErrorFromApi] = useState<string>('')
  const [navigation, setNavigation] = useState<AdNavigation>(emptyAdNavigation)
  const [campaignStatus, setCampaignStatus] = useState<Status | null>(null)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [keywords, setKeywords] = useState<string[]>([])

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()

  const { isSelfEditor, user } = useContext(AppContext)

  const { campaignId } = useParams()

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignById({
          campaignId,
          adType: FormatAPIEnum.TEXT
        })
        if (result && result.campaign.ads && result.campaign.ads.length >= 3) {
          navigate(`/campaign/${campaignId}/review`)
        }
        if (result && result.campaign.mediaPlanId && result.navigation) {
          setMediaPlanId(result.campaign.mediaPlanId)
          setCampaignStatus(result.campaign.status)
          setNavigation(result.navigation)
          setKeywords(result.campaign.keywords ?? [])
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [campaignId])

  useEffect(() => {
    if (isSubmitLoading) {
      if (!campaignId) {
        console.error("Aucune campagne n'a été sélectionnée.")
      } else {
        ;(async function () {
          try {
            const filteredTitles = formAd.titles
              .filter((title) => title.trim() !== '')
              .slice(0, 8)
            const filteredTitlesLength = filteredTitles.length
            if (filteredTitlesLength < 8) {
              for (let i = filteredTitlesLength; i < 8; i++) {
                filteredTitles.push('')
              }
            }

            const adData: CreateAdInputType = {
              campaignId,
              name: formAd.name,
              url: formAd.url,
              title1: filteredTitles[0],
              title2: filteredTitles[1],
              title3: filteredTitles[2],
              title4: filteredTitles[3],
              title5: filteredTitles[4],
              title6: filteredTitles[5],
              title7: filteredTitles[6],
              title8: filteredTitles[7],
              description1: formAd.descriptions[0],
              description2: formAd.descriptions[1],
              description3: formAd.descriptions[2],
              catchphrases: formAd.catchphrase || [],
              siteLinks: formAd.siteLinks
            }
            const response = await createAd(adData)

            setIsSubmitLoading(false)

            if (response && response.adId) {
              navigate(`/campaign/${campaignId}/review`)
            } else {
              console.error("La création de l'annonce a échoué.")
              if (response && response.error) {
                setErrorFromApi(
                  checkIfCreateAdErrorCodeValid(response.error)
                    ? CreateAdErrorFromApi[response.error]
                    : CreateAdErrorFromApi.UNKNOWN
                )
              } else {
                setErrorFromApi(CreateAdErrorFromApi.UNKNOWN)
              }
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            }
          } catch (error) {
            console.error(
              "Une erreur s'est produite lors de la création de l'annonce :",
              error
            )
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  if (hasFetchError || !campaignId) {
    return <ErrorPage />
  }

  if (!mediaPlanId || !navigation || !campaignStatus) {
    return <LoadingPage />
  }

  if (!getIsEditable({ status: campaignStatus, isEditor: false })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas créer de publicité pour cette campagne au statut ${StatusTranslation[
          campaignStatus
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Revenir à la campagne',
          onClick: () => {
            navigate(`/campaign/${campaignId}/details`)
          }
        }}
      />
    )
  }

  const handleAddTitle = () => {
    if (titles.length < 8) {
      setTitles([...titles, ''])
    }
  }

  const addToPanels = (
    panels: (PanelType | JSX.Element)[],
    title: string,
    sections: JSX.Element[],
    description?: string,
    titleTooltip?: string,
    tip?: { title: string; content: string },
    tipsFormat?: TipsFormatProps
  ) => {
    panels.push({
      title,
      description,
      disabled: false,
      sections,
      isDropdown: false,
      titleTooltip,
      tip,
      tipsFormat
    })
  }

  const handleChangeTitle = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newTitles = [...titles]
    newTitles[index] = event.target.value
    setTitles(newTitles)
    const newFormAdTitles = [...newTitles]
    setFormAd({ ...deepClone(formAd), titles: newFormAdTitles })
  }

  const handleChangeDescription = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDescriptions = [...descriptions]
    newDescriptions[index] = event.target.value
    setDescriptions(newDescriptions)
    const newFormAdDescriptions = [...formAd.descriptions]
    newFormAdDescriptions[index] = event.target.value
    setFormAd({ ...deepClone(formAd), descriptions: newFormAdDescriptions })
  }

  const handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setFormAd({ ...deepClone(formAd), url: event.target.value })
  }

  const handleAdNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormAd({ ...deepClone(formAd), name: event.target.value })
  }

  const items = [
    {
      label: 'Plan publicitaire'
    },
    {
      onClick: () => {
        navigate(`/media-plan/${navigation.mediaPlan.id}/review`)
      },
      label: navigation.mediaPlan.name
    },
    {
      onClick: () => {
        navigate(`/campaign/${navigation.campaign.id}/review`)
      },
      label: `Campagne « ${navigation.campaign.name} »`
    },
    { label: `Création de publicité` }
  ]

  // const handleAddLink = () => {
  //   const newLinks = [...links]
  //   newLinks.push({ title: '', description1: '', description2: '', url: '' })
  //   setLinks(newLinks)
  // }

  // const handleRemoveLink = (index: number) => {
  //   const newLinks = [...links]
  //   newLinks.splice(index, 1)
  //   setLinks(newLinks)
  // }

  // const handleChangeLink = (
  //   index: number,
  //   field: keyof OptionLink,
  //   value: string
  // ) => {
  //   const newLinks = [...links]
  //   newLinks[index][field] = value
  //   setFormAd({ ...deepClone(formAd), siteLinks: newLinks })
  // }

  // const handleAddCatchphrase = () => {
  //   const newCatchphrase = [...catchphrases, '']
  //   setCatchphrases(newCatchphrase)
  // }

  // const handleRemoveCatchphrase = (index: number) => {
  //   const newCatchphrase = [...catchphrases]
  //   newCatchphrase.splice(index, 1)
  //   setCatchphrases(newCatchphrase)
  // }

  // const handleChangeCatchphrase = (index: number, value: string) => {
  //   const newCatchphrase = [...catchphrases]
  //   newCatchphrase[index] = value
  //   setCatchphrases(newCatchphrase)
  //   setFormAd({ ...deepClone(formAd), catchphrase: newCatchphrase })
  // }

  const handleToggleAdditionalParameters = () => {
    setShowAdditionalParameters(!showAdditionalParameters)
  }

  const handleSubmit = () => {
    if (checkIfEmptyErrors()) {
      setIsSubmitLoading(true)
    }
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    navigate(`/campaign/${campaignId}/review`)
  }

  // const checkSiteLinks = () => {
  //   return formAd.siteLinks.length > 0
  // }

  // const checkSiteLinksTitle = () => {
  //   return formAd.siteLinks.every((link) => {
  //     return link.title.length > 1
  //   })
  // }

  // const checkSiteLinksUrl = () => {
  //   return formAd.siteLinks.every((link) => {
  //     return checkUrl(link.url)
  //   })
  // }

  const errors: AdCreationErrors = {
    name: checkMoreThanOneCharacter(formAd.name)
      ? ''
      : SelfEditorFormAdError.NAME,
    url: checkUrl(formAd.url) ? '' : SelfEditorFormAdError.URL,
    titles: formAd.titles.map((title, index) =>
      index < 3 && !checkMoreThanOneCharacter(title)
        ? SelfEditorFormAdError.TITLE
        : ''
    ),
    descriptions: [
      checkMoreThanOneCharacter(formAd.descriptions[0])
        ? ''
        : SelfEditorFormAdError.DESCRIPTION,
      checkMoreThanOneCharacter(formAd.descriptions[1])
        ? ''
        : SelfEditorFormAdError.DESCRIPTION,
      checkMoreThanOneCharacter(formAd.descriptions[2]) ||
      !formAd.descriptions[2]
        ? ''
        : SelfEditorFormAdError.DESCRIPTION
    ],
    // checkSiteLinks() ? '' : FormAdError.MISSING_SITE_LINKS,
    // checkSiteLinksTitle() ? '' : FormAdError.SITE_LINKS_TITLE,
    // checkSiteLinksUrl() ? '' : FormAdError.SITE_LINKS_URL
    errorFromApi: errorFromApi ?? ''
  }

  const panels: (PanelType | JSX.Element)[] = []

  const inputName = (
    <Input
      type="text"
      onChange={handleAdNameChange}
      placeholder="Nom"
      maxLength={20}
      showCharacterCount
      value={formAd.name}
      label={`Nom`}
      error={hasBeenSubmitted ? errors.name : ''}
      withBorder
    />
  )

  const inputUrl = (
    <Input
      type="url"
      onChange={handleChangeUrl}
      placeholder="https://"
      showCharacterCount
      value={formAd.url}
      label={`URL finale`}
      error={hasBeenSubmitted ? errors.url : ''}
      withBorder
    />
  )

  // const inputTitles = (
  //   <>
  //     <div>
  //       {titles.map((title, index) => (
  //         <Input
  //           key={index}
  //           type="text"
  //           placeholder={`Titre ${index + 1}`}
  //           value={title}
  //           onChange={(event) => { handleChangeTitle(index, event) }}
  //           label={`Titre ${index + 1}`}
  //           maxLength={30}
  //           showCharacterCount
  //           containerStyle={{ marginBottom: Margin.m6 }}
  //           error={hasBeenSubmitted && index < 3 ? errors.titles[index] : ''}
  //           withBorder
  //         />
  //       ))}
  //       {titles.length < 8 && titles[titles.length - 1] && (
  //         <Button onClick={handleAddTitle} style={{ marginTop: '10px' }}>
  //           Plus de titres
  //         </Button>
  //       )}
  //     </div>
  //   </>
  // )

  const inputTitles = (
    <>
      <div>
        {titles.map((title, index) => (
          <Input
            key={index}
            type="text"
            placeholder={`Titre ${index + 1}`}
            value={title}
            onChange={(event) => {
              handleChangeTitle(index, event)
            }}
            label={`Titre ${index + 1}`}
            maxLength={30}
            isRequired={index < 3}
            showCharacterCount
            containerStyle={{ marginBottom: Margin.m6 }}
            error={hasBeenSubmitted && index < 3 ? errors.titles[index] : ''}
            withBorder
          />
        ))}
        {titles.length < 8 && (
          <Button
            type="secondary"
            onClick={handleAddTitle}
            style={{ marginTop: '10px', width: '250px' }}
          >
            Plus de titres
          </Button>
        )}
      </div>
    </>
  )

  const inputDescriptions = (
    <div>
      {descriptions.slice(0, 2).map((description, index) => (
        <Input
          key={index}
          type="textarea"
          placeholder={`Description ${index + 1}`}
          value={description}
          onChange={(event) => {
            handleChangeDescription(index, event)
          }}
          label={`Description ${index + 1}`}
          maxLength={90}
          showCharacterCount
          containerStyle={{ marginBottom: Margin.m6 }}
          error={hasBeenSubmitted ? errors.descriptions[index] : ''}
          withBorder
        />
      ))}
    </div>
  )

  const inputPreview = (
    <GooglePreview
      url={formAd.url}
      title1={formAd.titles[0]}
      title2={formAd.titles[1]}
      title3={formAd.titles[2]}
      description={formAd.descriptions[0]}
      description2={formAd.descriptions[1]}
      description3={formAd.descriptions[2]}
    />
  )

  /*
  const inputSiteLinks = (
    <SiteLinks
      links={links}
      onAddLink={handleAddLink}
      onChangeLink={handleChangeLink}
      onRemoveLink={handleRemoveLink}
    />
  )
  const inputCatchphrases = (
    <Catchphrases
      catchphrases={catchphrases}
      onAddCatchphrase={handleAddCatchphrase}
      onChangeCatchphrase={handleChangeCatchphrase}
      onRemoveCatchphrase={handleRemoveCatchphrase}
    />
  )
  */

  const MoreDescriptionSection = (
    <Input
      type="textarea"
      onChange={(event) => {
        handleChangeDescription(2, event)
      }}
      placeholder="Description 3"
      label={`Description 3`}
      isRequired={false}
      value={formAd.descriptions[2]}
      maxLength={90}
      showCharacterCount
      containerStyle={{ marginTop: Margin.m6 }}
      error={hasBeenSubmitted ? errors.descriptions[2] : ''}
      withBorder
    />
  )

  const buttonMoreParameters = (
    <Button
      onClick={handleToggleAdditionalParameters}
      type="textButton"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: Margin.m6
      }}
    >
      {showAdditionalParameters ? (
        <DefaultText>{`Moins de paramètres`}</DefaultText>
      ) : (
        <DefaultText>{`Plus de paramètres`}</DefaultText>
      )}
    </Button>
  )

  addToPanels(
    panels,
    `Nom de la publicité`,
    [inputName],
    `Donnez un nom à cette publicité.`
  )
  addToPanels(
    panels,
    `Lien de votre site web`,
    [inputUrl],
    `Ajoutez le lien de votre site web vers lequelle vous souhaitez rediriger votre public cible`
  )
  addToPanels(
    panels,
    `Vos titres`,
    [inputTitles],
    `Ajoutez des titres accrocheurs pour attirer l'attention de votre public cible`,
    ``,
    {
      title: '',
      content: ``
    },
    {
      title: 'Titres',
      items: [
        {
          title: 'Consignes pour la rédaction des titres de votre publicité',
          content: [
            'Pour de meilleures performances, utilisez certains de vos mots-clé dans vos titres.',
            `Veuillez ne pas renseigner de noms de marques ou d'entreprises dans vos titres et descriptions !`,
            `Il est important d'inclure vos mots-clés dans vos titres. Pour rappel, vos mots-clés sont : ${keywords.join(
              ', '
            )}`
          ]
        }
      ]
    }
  )
  addToPanels(
    panels,
    `Vos descriptions`,
    [inputDescriptions],
    `Ajoutez des textes persuasifs pour inciter à l'action`,
    ``,
    {
      title: 'Descriptions',
      content: `Ajoutez des descriptions détaillant votre offre`
    }
  )
  // addToPanels(panels, `Vos liens annexes`, [inputSiteLinks])
  panels.push(buttonMoreParameters)
  if (showAdditionalParameters) {
    addToPanels(panels, `Description supplémentaire`, [MoreDescriptionSection])
    // addToPanels(panels, `Vos accroches`, [inputCatchphrases])
  }

  const checkIfEmptyErrors = () => {
    const { titles, descriptions, ...stringErrors } = errors
    const titleErrors = titles.slice(0, 3)
    return (
      Object.values(stringErrors).filter((e) => e).length === 0 &&
      titleErrors.filter((e) => e).length === 0 &&
      descriptions.filter((description) => description).length === 0
    )
  }

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isSelfEditor ?? false}
      navigate={navigate}
    >
      <Breadcrumb items={items} />
      <CreationPageTemplate
        title="ETAPE 3/3 - Ma nouvelle publicité textuelle"
        subTitle={`Créez votre publicité textuelle sur Google ads`}
        preview={inputPreview}
        panels={panels}
        errors={
          hasBeenSubmitted
            ? {
                errors: [
                  [
                    errorFromApi,
                    errors.name,
                    errors.url,
                    ...errors.titles,
                    ...errors.descriptions
                  ].filter((e) => e)[0]
                ]
              }
            : undefined
        }
        validation={{
          wording: 'Enregistrer et continuer',
          isLoading: isSubmitLoading,
          action: () => {
            if (checkIfEmptyErrors()) {
              handleSubmit()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled: hasBeenSubmitted && !checkIfEmptyErrors()
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
      />
      {isCancelModalOpen && (
        <ConfirmationModal
          title="La publicité est en cours de création"
          textConfirm="Oui, annuler"
          textReject="Non, continuer"
          onClickReject={handleCloseCancelModal}
          onClickConfirm={handleCancelConfirm}
          onClose={handleCloseCancelModal}
        >
          <ModalContent>
            <DefaultText>
              {'Êtes-vous sûr de vouloir annuler la création de la publicité ?'}
            </DefaultText>
            <DefaultText>
              {'Toutes les informations saisies seront perdues.'}
            </DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default SelfEditorGoogleTextAdCreation
