import { CSSProperties } from 'react'

import Margin from '../../../../../constants/margin'

export const modalStyle: CSSProperties = {
  maxWidth: '450px'
}

export const questionTextStyle: CSSProperties = {
  textAlign: 'center',
  marginBottom: Margin.m8
}

export const contentTextStyle: CSSProperties = {
  margin: `0 0 ${Margin.m8}`
}
