import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'
import Margin from '../../../../../../constants/margin'

export const AdImage = styled.img`
  width: 100%;
  height: 160px;
`

export const AdContent = styled.div`
  margin: 15px;
`

export const AdTitle = styled.div`
  font-weight: bold;
  font-size: ${FontSize.YETIC_TEXT_MEDIUM};
  color: #363636;
`

export const AdDescription = styled.div`
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  margin: ${Margin.m2} 0px;
  color: ${Colors.YETIC_GREY_AD};
  min-height: 30px;
`

export const AdLink = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AdCompany = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #363636;
`

export const AdButton = styled.div<{ primaryColor?: string }>`
  color: ${(props) => props.primaryColor};
  font-size: ${FontSize.YETIC_TEXT_MEDIUM};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const marginLeftStyle = {
  marginLeft: '5px'
}
