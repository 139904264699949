import styled from '@emotion/styled'

import { chartGap } from '../../../styles'
import Colors from '../../../../../constants/colors'

export const AudiencesTabContainer = styled.div`
  width: 100%;
  gap: ${chartGap}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Tip = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  background-color: ${Colors.NEW_YETIC_GREEN_LIGHT};
`
