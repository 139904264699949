import { LeftMenuElementToCreateEnum } from '../..'
import { GetAddButtonSelfEditorSectionsParams } from '../../types/addButtonSections'
import { sectionLabels } from '../sectionLabels/selfEditor'

export const getAddButtonSelfEditorSections = ({
  isAddMenuOpened,
  sections,
  companyId,
  mediaPlans,
  campaigns,
  ads,
  elementToCreate,
  setCampaignCreationModalMediaPlanId,
  fetchMediaPlans,
  fetchCampaigns,
  fetchAds,
  navigate
}: GetAddButtonSelfEditorSectionsParams) => {
  isAddMenuOpened &&
    sections.push({
      id: 'new',
      title: sectionLabels.new,
      items: [
        {
          title: 'Plan publicitaire',
          action: () => {
            navigate(`/company/${companyId}/media-plan/create`)
          }
        },
        { id: LeftMenuElementToCreateEnum.CAMPAIGN, title: 'Campagne' },
        { id: LeftMenuElementToCreateEnum.AD, title: 'Publicité' }
      ],
      fetchData: fetchMediaPlans
    })

  mediaPlans !== null &&
    sections.push({
      id: 'mediaPlan',
      title: sectionLabels.mediaPlan,
      items: mediaPlans.map((mediaPlan) => {
        return {
          id: mediaPlan.id,
          title: mediaPlan.name,
          action: isAddMenuOpened
            ? () => {
                setCampaignCreationModalMediaPlanId(mediaPlan.id)
              }
            : () => {
                navigate(`/media-plan/${mediaPlan.id}/details`)
              }
        }
      }),
      fetchData:
        isAddMenuOpened && elementToCreate !== LeftMenuElementToCreateEnum.AD
          ? undefined
          : fetchCampaigns
    })

  campaigns !== null &&
    sections.push({
      id: 'campaign',
      title: sectionLabels.campaign,
      items: campaigns.map((campaign) => {
        return {
          id: campaign.id,
          title: campaign.name,
          action: isAddMenuOpened
            ? () => {
                navigate(`/campaign/${campaign.id}/ad/create`)
              }
            : () => {
                navigate(`/campaign/${campaign.id}/details`)
              }
        }
      }),
      fetchData: isAddMenuOpened ? undefined : fetchAds
    })

  ads !== null &&
    sections.push({
      id: 'ad',
      title: sectionLabels.ad,
      items: ads.map((ad) => {
        return {
          id: ad.id,
          title: ad.name,
          action: () => {
            navigate(`/ad/${ad.id}/details`)
          }
        }
      })
    })
}
