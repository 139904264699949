import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const DivTips = styled.div<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor || 'rgba(156, 226, 226, 0.4)'};
  display: flex;
  align-items: flex-start;
  padding: ${Padding.p5} ${Padding.p6};
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 10px;
`

export const DivContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TipHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const TipHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`

export const TipHeaderDropdownButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const DivLine = styled.div`
  align-items: center;
  margin-bottom: ${Margin.m3};
  width: 100%;
  display: ${(props: { hidden: boolean | undefined }) =>
    props.hidden ? 'none' : 'flex'};
`
