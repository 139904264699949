import { GetAddButtonSectionsParams } from '../../types/addButtonSections'
import { getAddButtonEditorSections } from './editor'
import { getAddButtonSelfEditorSections } from './selfEditor'

export const getAddButtonSections = ({
  sections,
  isAddMenuOpened,
  isEditor,
  isSelfEditor,
  companyId,
  customers,
  mediaPlans,
  campaigns,
  ads,
  elementToCreate,
  setCampaignCreationModalMediaPlanId,
  fetchCustomers,
  fetchMediaPlans,
  fetchCampaigns,
  fetchAds,
  navigate
}: GetAddButtonSectionsParams) => {
  if (isEditor) {
    getAddButtonEditorSections({
      sections,
      isAddMenuOpened,
      customers,
      mediaPlans,
      campaigns,
      ads,
      elementToCreate,
      setCampaignCreationModalMediaPlanId,
      fetchCustomers,
      fetchMediaPlans,
      fetchCampaigns,
      fetchAds,
      navigate
    })
  } else if (isSelfEditor) {
    getAddButtonSelfEditorSections({
      sections,
      isAddMenuOpened,
      companyId,
      mediaPlans,
      campaigns,
      ads,
      elementToCreate,
      setCampaignCreationModalMediaPlanId,
      fetchMediaPlans,
      fetchCampaigns,
      fetchAds,
      navigate
    })
  }
}
