import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import Margin from '../../../../constants/margin'

export const PaginationDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Dot = styled.span<{ isActive: boolean }>`
  padding: 4px;
  margin-right: ${Margin.m4};
  cursor: pointer;
  border-radius: 50%;
  background: ${({ isActive }) =>
    isActive ? Colors.YETIC_BLUE_DARK : Colors.YETIC_GREY_DARK};
  width: 2px;
  height: 2px;
`
