import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'

export const DisplayMobileWebPageTemplate = styled.div<{ scale?: number }>`
  width: 320px;
  height: 570px;
  border-radius: 10px;
  border: solid 1px ${Colors.YETIC_GREY_AD};
  background-color: ${Colors.YETIC_WHITE};
  overflow: hidden;
  scale: ${(props) => props.scale || 1};
`

export const DivHeadBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #e9eaed;
  padding: 10px;
  box-sizing: border-box;
`
export const DivMenu = styled.div`
  position: relative;
  width: 20px;
  height: 3px;
  background-color: #5f6369;
  margin-top: 6px;
  &:before,
  &:after {
    content: '';
    background-color: #5f6369;
    position: absolute;
    left: 0;
    right: 0;
    top: -6px;
    width: 20px;
    height: 3px;
  }
  &:after {
    top: -12px;
  }
`

export const DivContainer = styled.div`
  padding: ${Padding.p3};
`

export const DivLine = styled.div<{
  url?: boolean
  small?: boolean
}>`
  background-color: ${(props) => (props.url ? '#BDC1C7' : '#E9EAED')};
  height: 10px;
  width: ${(props) => (props.url ? '120px' : '100%')};
  max-width: ${(props) => props.small && '80%'};
  margin-bottom: ${(props) => (props.url ? '0' : '10px')};
`

export const marginTopStyle = {
  marginTop: Margin.m4
}

export const marginBottomStyle = {
  marginBottom: Margin.m4
}
