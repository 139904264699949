import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import EditorMediaPlanEdit from './editor'
import SelfEditorMediaPlanEdit from './selfEditor'
import { MediaPlanEditErrors, MediaPlanType } from './types'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { getMediaPlanById } from '../../helpers/queries/mediaPlan/getMediaPlanById'
import LoadingPage from '../LoadingPage'
import {
  formatDateToEnglishString,
  parseStringDate,
  verifyStringIsValidDate
} from '../../helpers/date'
import { deepClone } from '../../helpers/clone'
import Period from '../components/Period'
import DisabledSection from '../../templates/EditPageTemplate/DisabledSection'
import Input from '../../components/Input'
import { FormMediaPlanError } from '../../types/pages/mediaPlanCreation'
import {
  checkIfUpdateMediaPlan,
  updateMediaPlan
} from '../../helpers/queries/mediaPlan/updateMediaPlan'
import SelfEditorAudienceCreationPage from '../MediaPlanCreation/AudienceCreationPageSelfEditor'
import EditorAudienceCreationPage from '../MediaPlanCreation/AudienceCreationPageEditor'
import {
  checkIfGetMediaPlanCreationDataOutputType,
  getMediaPlanCreationData
} from '../../helpers/queries/mediaPlanAudience/getMediaPlanCreationData'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import { getAudienceByNameAndCompanyId } from '../../helpers/queries/audiences/getAudienceByName'
import { MediaPlanAudience } from '../../types/mediaPlanAudience'
import ModalAudienceDetails from '../../components/ModalAudienceDetails'
import { Status, StatusTranslation, getIsEditable } from '../../types/status'

const MediaPlanEdit = () => {
  const [mediaPlan, setMediaPlan] = useState<MediaPlanType | null>(null)
  const [initialAudiences, setInitialAudiences] = useState<string[]>([])
  const [audiences, setAudiences] = useState<string[]>([])
  const [initialStartDate, setInitialStartDate] = useState('')
  const [initialEndDate, setInitialEndDate] = useState('')
  const [isEditAudience, setIsEditAudience] = useState(false)
  const [errorFromApi, setErrorFromApi] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [dataAudienceModal, setDataAudienceModal] =
    useState<MediaPlanAudience | null>(null)

  const navigate = useNavigate()
  const { mediaPlanId } = useParams()
  const location = useLocation()
  const { isEditor } = useContext(AppContext)

  const startDateFromApi = useRef('')
  const endDateFromApi = useRef('')

  const params = new URLSearchParams(location.search)
  const flow = params.get('flow') as FlowEnum

  useEffect(() => {
    if (mediaPlanId) {
      ;(async function () {
        const data = await getMediaPlanById({
          mediaPlanId
        })

        if (data && data.mediaPlan) {
          const mediaPlanData = {
            name: data.mediaPlan.name,
            goals: data.mediaPlan.goals,
            startDate: formatDateToEnglishString(
              new Date(data.mediaPlan.startDate)
            ),
            endDate: formatDateToEnglishString(
              new Date(data.mediaPlan.endDate)
            ),
            newAudiences: [],
            budget: data.mediaPlan.expectedBudget,
            status: data.mediaPlan.status,
            navigation: {
              company: data.mediaPlan.navigation.company
            }
          }

          const mediaPlanAudiences = await getMediaPlanCreationData(
            mediaPlanData.navigation.company.id
          )

          if (checkIfGetMediaPlanCreationDataOutputType(mediaPlanAudiences)) {
            setAudiences(mediaPlanAudiences.audienceNames)
            setInitialAudiences(data.mediaPlan.audiences)
            setInitialStartDate(mediaPlanData.startDate)
            setInitialEndDate(mediaPlanData.endDate)
            setMediaPlan(mediaPlanData)
            setHasFetchError(false)
            startDateFromApi.current = formatDateToEnglishString(
              new Date(data.mediaPlan.startDate)
            )
            endDateFromApi.current = formatDateToEnglishString(
              new Date(data.mediaPlan.endDate)
            )
          } else {
            console.error(
              `Something went wrong during request to /mediaPlanCreationData/${mediaPlanData.navigation.company.id}`
            )
            setHasFetchError(true)
          }
        } else {
          console.error(
            `Something went wrong during request to /mediaPlan/${mediaPlanId}`
          )
          setHasFetchError(true)
        }
      })()
    } else {
      console.error('No media plan id provided')
      setHasFetchError(true)
    }

    scrollTo(0, 0)
  }, [mediaPlanId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isEditAudience])

  useEffect(() => {
    if (isSubmitLoading) {
      if (
        mediaPlan &&
        mediaPlanId &&
        mediaPlan.startDate &&
        mediaPlan.endDate
      ) {
        ;(async function () {
          try {
            const dataToSubmit = {
              mediaPlanId,
              budget: mediaPlan.budget,
              startDate: new Date(mediaPlan.startDate),
              endDate: new Date(mediaPlan.endDate),
              newAudiences: mediaPlan.newAudiences
            }

            const result = await updateMediaPlan(dataToSubmit)
            if (!checkIfUpdateMediaPlan(result)) {
              setErrorFromApi(result.error)
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            } else {
              flow === FlowEnum.creation
                ? navigate(`/media-plan/${mediaPlanId}/review`)
                : navigate(`/media-plan/${mediaPlanId}/details`)
            }
          } catch (error) {
            console.error(error)
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  const startDate = mediaPlan?.startDate
  const endDate = mediaPlan?.endDate

  if (!mediaPlan || !mediaPlanId || !startDate || !endDate) {
    return <LoadingPage />
  }

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!getIsEditable({ status: mediaPlan.status, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas modifier ce plan média au statut ${StatusTranslation[
          mediaPlan.status
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Retour au plan média',
          onClick: () => {
            navigate(`/media-plan/${mediaPlanId}/details`)
          }
        }}
      />
    )
  }

  const checkAudiences = () =>
    mediaPlan.newAudiences.length > 0 || initialAudiences.length > 0
  const checkBudget = () => mediaPlan.budget > 0

  const checkStartDate = () => {
    const isStartDateValid = verifyStringIsValidDate(startDate)

    const validStatusForOlderDates: Status[] = [Status.IN_PROGRESS]

    if (
      isStartDateValid &&
      validStatusForOlderDates.includes(mediaPlan.status)
    ) {
      return true
    } else if (isStartDateValid) {
      const parsedStartDate = parseStringDate(startDate)
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      const isStartGreaterThanToday =
        parsedStartDate.getTime() > tomorrow.getTime()
      return isStartGreaterThanToday
    } else {
      return false
    }
  }

  const checkEndDate = () => {
    const isStartDateValid = verifyStringIsValidDate(startDate)
    const isEndDateValid = verifyStringIsValidDate(endDate)

    if (isStartDateValid && isEndDateValid) {
      const parsedStartDate = parseStringDate(startDate)
      const parsedEndDate = parseStringDate(endDate)
      const isEndGreaterThanStart =
        parsedEndDate.getTime() > parsedStartDate.getTime()
      return isEndGreaterThanStart
    } else {
      return isEndDateValid
    }
  }

  const errors: MediaPlanEditErrors = {
    audiences: checkAudiences() ? '' : FormMediaPlanError.AUDIENCES,
    budget: checkBudget() ? '' : FormMediaPlanError.AMOUNT_BUDGET_MIN,
    startDate: checkStartDate() ? '' : FormMediaPlanError.START_DATE,
    endDate: checkEndDate() ? '' : FormMediaPlanError.END_DATE,
    errorFromApi: errorFromApi ?? ''
  }

  const handleAddSelectedAudience = (newAudience: string) => {
    setMediaPlan({
      ...deepClone(mediaPlan),
      newAudiences: [...mediaPlan.newAudiences, newAudience]
    })
  }

  const handleRemoveSelectedAudience = (indexToDelete: number) => {
    setMediaPlan({
      ...deepClone(mediaPlan),
      newAudiences: [
        ...mediaPlan.newAudiences.filter(
          (_, index: number) =>
            index !== indexToDelete - initialAudiences.length
        )
      ]
    })
  }

  const handleAddAudience = (newAudience: string) => {
    setAudiences([...audiences, newAudience])
    handleAddSelectedAudience(newAudience)
  }

  const AudienceCreationPage = isEditor
    ? EditorAudienceCreationPage
    : SelfEditorAudienceCreationPage

  if (isEditAudience) {
    return (
      <AudienceCreationPage
        customerId={mediaPlan ? mediaPlan.navigation.company.id : ''}
        back={() => {
          setIsEditAudience(false)
        }}
        validate={(newAudience: string) => {
          handleAddAudience(newAudience)
          setIsEditAudience(false)
        }}
      />
    )
  }

  const handleChangeDate = (
    date: string,
    attribute: 'startDate' | 'endDate'
  ) => {
    setMediaPlan((prevMediaPlan) => {
      if (!prevMediaPlan) return null
      const updatedMediaPlan = deepClone(prevMediaPlan)
      if (date) {
        updatedMediaPlan[attribute] = date
        if (attribute === 'startDate') {
          startDateFromApi.current = date
        } else {
          endDateFromApi.current = date
        }
      } else {
        updatedMediaPlan[attribute] =
          attribute === 'startDate'
            ? startDateFromApi.current
            : endDateFromApi.current
      }
      return updatedMediaPlan
    })
  }

  const handleChangeBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    // TODO : to see if debounce is needed
    if (event.target.value && !Number.isNaN(parseFloat(event.target.value))) {
      setMediaPlan({
        ...deepClone(mediaPlan),
        budget: parseFloat(event.target.value)
      })
    }
  }

  const handleSubmitMediaPlan = () => {
    setIsSubmitLoading(true)
  }

  const handleClickAudienceTag = async (audienceName: string) => {
    const result = await getAudienceByNameAndCompanyId({
      name: audienceName,
      companyId: mediaPlan.navigation.company.id
    })
    if (result) {
      setDataAudienceModal(result)
    }
  }

  const nameSection = <DisabledSection value={mediaPlan.name} />
  const goalsSection = <DisabledSection value={mediaPlan.goals} />

  const dayAfterTomorrow = new Date()
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2)

  const periodSection = {
    content: (
      <Period
        startDate={startDate.split('T')[0]}
        endDate={endDate.split('T')[0]}
        onChangeStartDate={(date) => {
          handleChangeDate(date, 'startDate')
        }}
        onChangeEndDate={(date) => {
          handleChangeDate(date, 'endDate')
        }}
        startDateError={hasBeenSubmitted ? errors.startDate : ''}
        endDateError={hasBeenSubmitted ? errors.endDate : ''}
        minStartDate={dayAfterTomorrow.toISOString().split('T')[0]}
        maxStartDate={initialStartDate.split('T')[0]}
        minEndDate={initialEndDate.split('T')[0]}
      />
    )
  }

  const allSelectedAudiences = [...initialAudiences, ...mediaPlan.newAudiences]

  const budgetSection = {
    content: (
      <Input
        type="devise"
        onChange={handleChangeBudget}
        placeholder="Budget en €"
        value={mediaPlan.budget ? String(mediaPlan.budget) : ''}
        style={{ paddingRight: '30px' }}
        error={hasBeenSubmitted ? errors.budget : ''}
        withBorder
      />
    )
  }

  const MediaPlanEditContent = isEditor
    ? EditorMediaPlanEdit
    : SelfEditorMediaPlanEdit

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <MediaPlanEditContent
        mediaPlan={mediaPlan}
        nameSection={nameSection}
        goalsSection={goalsSection}
        periodSection={periodSection}
        audiences={audiences}
        selectedAudiences={allSelectedAudiences}
        addSelectedAudience={handleAddAudience}
        removeAudience={handleRemoveSelectedAudience}
        nonRemovableAudiences={initialAudiences}
        openAudienceCreation={() => {
          setIsEditAudience(true)
        }}
        onClickTag={(audienceName: string) => {
          handleClickAudienceTag(audienceName)
        }}
        budgetSection={budgetSection}
        errors={errors}
        flow={flow}
        mediaPlanId={mediaPlanId}
        isSubmitLoading={isSubmitLoading}
        handleSubmitMediaPlan={handleSubmitMediaPlan}
      />

      {dataAudienceModal && (
        <ModalAudienceDetails
          title={"Détail de l'audience"}
          data={dataAudienceModal}
          handleClose={() => {
            setDataAudienceModal(null)
          }}
        />
      )}
    </AuthenticatedTemplate>
  )
}

export default MediaPlanEdit
