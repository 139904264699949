import React from 'react'

import TipsFormat from '../../../components/Tips/TipsFormat'
import {
  googleLogoMinHeight,
  googleLogoMinWidth,
  googleLogoRatio,
  googleMaxSize,
  googleRectangleMinHeight,
  googleRectangleMinWidth,
  googleRectangleRatio,
  googleSquareMinHeight,
  googleSquareMinWidth,
  googleSquareRatio
} from '../../../constants/image'

export const TipsImage = () => (
  <TipsFormat
    title={`Quels formats et résolution d'images ?`}
    items={[
      {
        title: 'Taille de fichier et format requis',
        content: [
          `La taille de fichier maximale autorisée est de ${
            googleMaxSize / 1000000
          } Mo.`,
          'Au moins une image au format paysage, une au format carré et un logo sont requis.'
        ]
      },
      {
        title: `Format paysage (${googleRectangleRatio}:1)`,
        content: [
          'Taille recommandée : 1200 x 628',
          `Taille minimale : ${googleRectangleMinWidth} x ${googleRectangleMinHeight}`
        ]
      },
      {
        title: `Format carré (${googleSquareRatio}:1)`,
        content: [
          'Taille recommandée : 1200 x 1200',
          `Taille minimale : ${googleSquareMinWidth} x ${googleSquareMinHeight}`
        ]
      },
      {
        title: `Logo (${googleLogoRatio}:1)`,
        content: [
          'Taille recommandée : 1200 x 1200',
          `Taille minimale : ${googleLogoMinWidth} x ${googleLogoMinHeight}`
        ]
      }
    ]}
  />
)
