export enum RegisterErrorEnum {
  MISSING_PARAMS = `MISSING_PARAMS`,
  UNKNOWN = 'UNKNOWN',
  COMPANY_ALREADY_EXISTS = 'COMPANY_ALREADY_EXISTS',
  USER_EXISTS = 'USER_EXISTS',
  VERIFICATION_EMAIL = 'VERIFICATION_EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  PHONE_NUMBER = 'PHONE_NUMBER',
  SIREN = 'SIREN',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  COMPANY_NAME = 'COMPANY_NAME',
  ACTIVITY = 'ACTIVITY',
  SIRET = 'SIRET',
  VAT = 'VAT',
  TERMS = 'TERMS'
}
export const RegisterErrorTranslation = {
  [RegisterErrorEnum.MISSING_PARAMS]:
    'Certains champs sont manquants ou invalides.',
  [RegisterErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [RegisterErrorEnum.COMPANY_ALREADY_EXISTS]:
    "Un membre de votre entreprise est déjà inscrit sur la plateforme. Veuillez contacter votre administrateur pour qu'il vous ajoute à l'entreprise.",
  [RegisterErrorEnum.USER_EXISTS]:
    'Un compte existe déjà avec cette adresse email',
  [RegisterErrorEnum.VERIFICATION_EMAIL]:
    "L'email de vérification n'a pas pu être envoyé. Veuillez réessayer.",
  [RegisterErrorEnum.FIRST_NAME]: "Votre prénom n'est pas valide",
  [RegisterErrorEnum.LAST_NAME]: "Votre nom de famille n'est pas valide",
  [RegisterErrorEnum.EMAIL]: "Le format de l'adresse email n'est pas valide",
  [RegisterErrorEnum.PASSWORD]:
    "Le mot de passe n'est pas valide. Il doit contenir au moins 12 caractères, dont 1 lettre majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial (@, $, !, %, *, ?, &, _, -)",
  [RegisterErrorEnum.CONFIRM_PASSWORD]:
    'Les deux mots de passe ne correspondent pas',
  [RegisterErrorEnum.PHONE_NUMBER]: "Le numéro de téléphone n'est pas valide",
  [RegisterErrorEnum.SIREN]:
    "Le numéro de SIREN n'est pas valide. Veuillez vous assurer qu'il contient 9 chiffres.",
  [RegisterErrorEnum.BILLING_ADDRESS]:
    "L'adresse de facturation n'est pas valide",
  [RegisterErrorEnum.COMPANY_NAME]:
    "Le nom de l'entreprise doit faire au moins 2 caractères",
  [RegisterErrorEnum.ACTIVITY]:
    "Le format de l'activité de l'entreprise n'est pas valide",
  [RegisterErrorEnum.SIRET]:
    "Le numéro de SIRET n'est pas valide. Veuillez vous assurer qu'il contient 14 chiffres.",
  [RegisterErrorEnum.VAT]:
    "Le numéro de TVA n'est pas valide. Veuillez vous assurer qu'il est constitué du code pays (FR), suivi de 2 chiffres (clé informatique), suivi de 9 chiffres (SIREN).",
  [RegisterErrorEnum.TERMS]:
    'Vous devez accepter les conditions afin de finaliser votre inscription.'
}

export const checkIfRegisterErrorCodeValid = (
  errorCode: string
): errorCode is RegisterErrorEnum => {
  return Object.keys(RegisterErrorEnum).includes(errorCode)
}
