import React, { useContext, useState } from 'react'

import { TableListDataType, TableListFilter } from '../../components/TableList'
import KPI from './UI/KPI'
import { DivHeadBanner, Hr, TutorialSwitchContainer } from './styles'
import { SelfEditorHomeDataType } from '../../helpers/queries/getHomeData'
import HomeTableList from './UI/HomeTableList'
import { Status } from '../../types/status'
import { formatDateToString } from '../../helpers/date'
import { BoxInfoProps } from './UI/BoxInfo'
import Benefits from './UI/BoxInfo/Benefits'
import { PieChartProps } from '../../components/ProgressionChart'
import Infos from './UI/BoxInfo/Infos/selfEditor'
import Colors from '../../constants/colors'
import StatusWithColor from '../../components/StatusDot'
import DefaultText from '../../components/DefaultText'
import { SelfEditorItemType } from './types'
import Introduction from '../../components/Onboarding/Introduction'
import { selfEditorOnboardingSlides } from '../../components/Onboarding/Slides/selfEditor'
import { useNavigate } from 'react-router-dom'
import Title from '../../components/Title'
import QuestionMarkIcon from '../../components/Icon/questionMark'
import { AppContext } from '../../contexts/AppContext'

type HomeSelfEditorContentProps = {
  data: SelfEditorHomeDataType
  filteredData: SelfEditorItemType[]
  selectedInProgressType: string
  filters: TableListFilter[]
  dateRangeValue: string
  setDateRangeValue: (newValue: string) => void
  fetchDataOnClickDate: (dateRangeValue: string) => Promise<void>
  handleOnRowClick: (id: string) => void
  totalItems: number
  onChangePage: (page: number) => void
  currentPage: number
  isTutorialHidden: boolean
  setIsTutorialHidden: (value: boolean) => void
  companyId?: string | null
}

const HomeSelfEditorContent = ({
  data,
  filteredData,
  selectedInProgressType,
  filters,
  dateRangeValue,
  setDateRangeValue,
  fetchDataOnClickDate,
  handleOnRowClick,
  totalItems,
  onChangePage,
  currentPage,
  isTutorialHidden,
  setIsTutorialHidden,
  companyId
}: HomeSelfEditorContentProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const { user } = useContext(AppContext)

  const navigate = useNavigate()

  const pieChart: PieChartProps = {
    total: {
      value: data.budget.estimated,
      color: Colors.NEW_YETIC_BOX_INFO_TIP
    },
    partial: { value: data.budget.spent, color: Colors.YETIC_YELLOW_DARK }
  }

  const formattedData: TableListDataType = {
    titles: ['Nom', 'Budget', 'Statut', 'Modifié le'],
    widths: ['31%', '25%', '26%', '18%'],
    items: filteredData.map((item) => ({
      id: item.id,
      type: selectedInProgressType,
      row: [
        item.name,
        `${item.budget.toString()} €`,
        <StatusWithColor status={item.status as Status} key={item.status} />,
        formatDateToString(new Date(item.updatedAt))
      ]
    }))
  }

  const boxInfos: BoxInfoProps[] = [
    {
      title: 'Dépenses',
      content: (
        <Benefits
          estimatedLabel="Estimées"
          accomplishedLabel="Réalisées"
          pieChart={pieChart}
        />
      )
    },
    {
      title: 'Impact',
      // content: <Impact value={data.co2} />
      content: <DefaultText size="small">En cours de développement</DefaultText>
    },
    {
      title: '',
      content: (
        <Infos
          ads={data.adCount}
          campaigns={data.campaignCount}
          mediaPlans={data.mediaPlanCount}
        />
      ),
      style: {
        justifyContent: 'center'
      }
    }
  ]

  const totalSlides = Object.keys(selfEditorOnboardingSlides(navigate)).length

  const handleChangeSlide = (index: number) => {
    setCurrentSlide(index)
  }

  const handleNextSlide = () => {
    if (currentSlide < totalSlides) {
      setCurrentSlide(
        currentSlide + 1 === 3 ? currentSlide + 2 : currentSlide + 1
      )
    }
  }

  const handlePreviousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(
        currentSlide - 1 === 3 ? currentSlide - 2 : currentSlide - 1
      )
    }
  }

  const slideData = selfEditorOnboardingSlides(navigate, true, companyId)[
    currentSlide
  ]

  const handleSkipIntroduction = () => {
    setCurrentSlide(0)
    setIsTutorialHidden(true)
  }

  return (
    <>
      <DivHeadBanner>
        <Title style={{ marginBottom: 0 }}>Bonjour, {user?.firstName}.</Title>
        {isTutorialHidden && (
          <TutorialSwitchContainer
            onClick={() => {
              setIsTutorialHidden(false)
            }}
          >
            <QuestionMarkIcon width="28px" height="28px" />
          </TutorialSwitchContainer>
        )}
      </DivHeadBanner>
      {!isTutorialHidden && (
        <Introduction
          title={slideData.title}
          description={slideData.description}
          buttonConfirm={slideData.buttonConfirm}
          buttonCancel={slideData.buttonCancel}
          imageWebp={slideData.imageWebp}
          imagePng={slideData.imagePng}
          skipIntroduction={slideData.skipIntroduction}
          totalSlides={totalSlides}
          currentSlide={currentSlide}
          handleNextSlide={handleNextSlide}
          handlePreviousSlide={handlePreviousSlide}
          handleChangeSlide={handleChangeSlide}
          isNotBottom={slideData.isNotBottom}
          isSelfEditor={true}
          handleSkipIntroduction={handleSkipIntroduction}
        />
      )}
      <KPI
        selectedFilter={dateRangeValue}
        setSelectedFilter={(newValue: string) => {
          setDateRangeValue(newValue)
        }}
        boxInfos={boxInfos}
        handleClick={fetchDataOnClickDate}
      />
      <Hr />
      <HomeTableList
        data={formattedData}
        filters={filters}
        handleOnRowClick={handleOnRowClick}
        totalItems={totalItems}
        handleChangePage={onChangePage}
        currentPage={currentPage}
      />
    </>
  )
}

export default HomeSelfEditorContent
