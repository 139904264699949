import React from 'react'
import styled from '@emotion/styled'

import Colors from '../../constants/colors'

const VerticalLine = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${Colors.YETIC_BLUE_LIGHTER};
  opacity: 0.9;
`

const VerticalSeparator = () => {
  return <VerticalLine />
}

export default VerticalSeparator
