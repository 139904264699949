import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../../constants/colors'

export const Wrapper = styled.div<{
  isHovered: boolean
  disabled?: boolean
  width?: string
  height?: string
}>`
  width: ${(props) => props.width ?? 'auto'};
  height: ${(props) => props.height ?? 'auto'};
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  justify-content: center;
  transition: 0.2s;
  border-radius: 5px;
  background-color: ${(props) =>
    props.isHovered && !props.disabled
      ? Colors.YETIC_BLUE_DARK
      : Colors.YETIC_WHITE};
  box-shadow: 0px 0px 5px #142a4c20;

  ${(props) => !props.disabled && `cursor: pointer;`}
`

export const widgetBoxButtonComingSoonStyle: CSSProperties = {
  textAlign: 'center',
  marginBottom: 0,
  opacity: 0.6,
  fontStyle: 'italic'
}
