import styled from '@emotion/styled'

import FontSize from '../../../constants/fontSize'
import FontWeight from '../../../constants/fontWeight'
import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'
import { StrategyTranslation } from '../../../types/strategy'

export const Container = styled.div`
  padding: 10px 0;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${FontSize.YETIC_PANEL_TITLE};
`

export const ContainerHead = styled.div`
  display: flex;
  align-items: center;
  margin: ${Margin.m7} 0;
`

export const buttonStyle = {
  backgroundColor: Colors.YETIC_YELLOW_DARK,
  width: 'auto',
  marginLeft: 'auto',
  marginTop: 0,
  color: Colors.YETIC_BLUE_DARK,
  borderRadius: '10px'
}

export const TableListHeader = styled.div`
  margin-bottom: 10px;
  padding: 0 10px;
`

export const TableListHeaderItem = styled.div`
  display: inline-block;
  color: ${Colors.YETIC_BLUE_DARK};
  font-weight: ${FontWeight.YETIC_BOLD};
`

export const TableListRowsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  tablelist-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
`

export const TableListRow = styled.li<{ rowType?: string; selected?: boolean }>`
  box-shadow: 0px 0px 2px #142a4c40;
  position: relative;
  border-radius: 10px;
  height: 54px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  justify-content: ${(props) => (props.rowType ? '' : 'center')};
  cursor: ${(props) => (props.rowType ? 'normal' : 'pointer')};
  background-color: ${(props) =>
    props.selected ? Colors.NEW_YETIC_GREY_BACKGROUND : Colors.YETIC_WHITE};
`

export const RowContent = styled.div<{ hoverable: boolean }>`
  padding: 0px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  :hover {
    background-color: ${(props) =>
      props.hoverable ? Colors.NEW_YETIC_GREY_LIGHT : ''};
  }
`

export const RowItem = styled.div<{
  index: number
  width: string
  rowType?: string
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  width: ${(props) => props.width};
  height: 100%;
  box-sizing: border-box;
  font-weight: ${(props) => (props.index === 0 ? '600' : 'normal')};
  padding-top: ${(props) => (props.rowType ? '10px' : '0px')};
  cursor: pointer;
`

export const FilledArrow = styled.div<{ selected: boolean }>`
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid ${Colors.YETIC_BLUE_DARK};
  transform: ${(props) => (props.selected ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: transform 0.15s;
`

export const BoxType = styled.div<{ type: string }>`
  border-radius: 10px 0px;
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  font-weight: ${FontWeight.YETIC_MEDIUM};
  padding: 5px 10px;
  width: fit-content;
  background-color: ${(props) =>
    props.type === StrategyTranslation.CAMPAIGN
      ? Colors.YETIC_GREEN_LIGHT
      : Colors.YETIC_BLUE_LIGHT};
  color: ${Colors.YETIC_WHITE};
  position: absolute;
`

export const NoResult = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const IconContainer = styled.span`
  display: inline-block;
  margin-left: auto;
`

export const DivDropdowns = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`

export const actionButtonSize = 30
