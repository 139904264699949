import React from 'react'

import { ImageAdCreationFormDataType } from '../types'
import { ImageAdCreationErrorsType } from '../../../helpers/imageAd/formErrors'
import { PanelType } from '../../../types/templates/panels'
import Input from '../../../components/Input'

export type GetUrlPanelInput = {
  formData: ImageAdCreationFormDataType
  errors: ImageAdCreationErrorsType
  hasBeenSubmitted: boolean
}

export const getUrlPanel = ({
  formData,
  errors,
  hasBeenSubmitted
}: GetUrlPanelInput): PanelType => {
  return {
    title: 'URL',
    disabled: true,
    isDropdown: false,
    description: `Renseignez l'URL finale qui s'affichera sur votre annonce.`,
    sections: [
      {
        content: (
          <Input
            type="text"
            placeholder="https://example.com"
            isRequired={false}
            label={'URL finale'}
            value={formData.url}
            error={hasBeenSubmitted ? errors.url : ''}
            withBorder
            disabled
          />
        )
      }
    ]
  }
}
