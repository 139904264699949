import React, { useContext, useEffect, useRef, useState } from 'react'

import { ContainerMenu, PopupMenu, PopupMenuItem } from './styles'
import { AppContext } from '../../../contexts/AppContext'
import Avatar from '../../../components/Avatar'

export type HeaderMenuEntryType = {
  title: string
  onClick: () => void
}

export type HeaderMenuType = {
  entries: HeaderMenuEntryType[]
}

const HeaderMenu = ({ entries }: HeaderMenuType) => {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { user } = useContext(AppContext)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const userLetters = user
    ? user?.firstName?.charAt(0) + user?.lastName?.charAt(0)
    : 'FB'

  return (
    <ContainerMenu ref={containerRef}>
      <Avatar
        onClick={() => {
          setShowMenu(!showMenu)
        }}
        character={userLetters}
      />
      {showMenu ? (
        <PopupMenu>
          {entries?.map((entry, index) => (
            <PopupMenuItem key={index} onClick={entry.onClick}>
              {entry.title}
            </PopupMenuItem>
          ))}
        </PopupMenu>
      ) : (
        ''
      )}
    </ContainerMenu>
  )
}

export default HeaderMenu
