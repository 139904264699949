import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const HomeContainer = styled.div`
  margin: auto;
  margin-top: ${Margin.m7};
  margin-bottom: ${Margin.m7};
  padding: ${Padding.p7};
  width: 80%;
  max-width: 900px;
`

export const DivHeadBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Margin.m5};
`

export const KPIContainer = styled.div`
  display: flex;
`
export const Hr = styled.hr`
  border: 1px solid #e0e0e0;
  margin: ${Margin.m10} 0 ${Margin.m8} 0;
`

export const ContainerBox = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  > div {
    width: calc(100% / 3);
  }
`

export const TutorialSwitchContainer = styled.div`
  cursor: pointer;
  margin: 0;
  height: 28px;
`
