import React, { CSSProperties } from 'react'

import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import {
  RadioButton,
  RadioButtonLabel,
  RadioList
} from '../components/RadioButton'

type RadioListTmpProps = {
  values: { label: string; description?: string }[]
  selectedValue: number
  onChange: (index: number) => void
  style?: CSSProperties
}

// TODO RadioList should be changed to include the "onChange",
// which will take the new index in input
export const RadioListTmp = ({
  values,
  selectedValue,
  onChange,
  style
}: RadioListTmpProps) => {
  const formatRadioButtonValues = values.map(
    (
      value: {
        label: string
        description?: string
        disabled?: boolean
        tooltipView?: EmotionJSX.Element
      },
      index: number
    ) => ({
      label: value.label,
      description: value.description,
      checked: index === selectedValue,
      disabled: value.disabled ?? false,
      tooltipView: value.tooltipView
    })
  )
  return (
    <RadioList
      values={formatRadioButtonValues.map(
        ({ label, description, checked, disabled, tooltipView }, index) => (
          <RadioButton
            key={label}
            checked={checked}
            onChange={() => {
              onChange(index)
            }}
            disabled={disabled}
            withBorder
          >
            <RadioButtonLabel label={label} description={description} />
            {tooltipView}
          </RadioButton>
        )
      )}
      hasBorder
      selected={selectedValue}
    />
  )
}
