import React, { CSSProperties, FC, ReactNode } from 'react'
import styled from '@emotion/styled'

import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'
import FontSize from '../../constants/fontSize'
import Colors from '../../constants/colors'
import Margin from '../../constants/margin'

const StyledThinTitle = styled.h2`
  color: ${Colors.YETIC_BLUE_DARK};

  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  font-weight: 600;

  opacity: 0.6;

  margin-top: ${Margin.m0};
  margin-bottom: ${Margin.m0};
`

export interface PropsType {
  children: ReactNode
  style: CSSProperties
}

const TitleBig: FC<PropsType> = ({ children, style }) => (
  <StyledThinTitle style={style}>{children}</StyledThinTitle>
)

export default TitleBig
