import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PreviewContainer } from '../../../AdCreation/Google/ImageAdCreation/styles'
import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import { PanelType } from '../../../../types/templates/panels'
import Input from '../../../../components/Input'
import Breadcrumb from '../../../../components/BreadCrumb'
import Margin from '../../../../constants/margin'
import { ImagesContainer } from './styles'
import CarouselPreview from '../../../../components/PreviewAds/Google/Display/CarouselPreview'
import { ImageAdReviewContentProps } from './types'

const AdvertiserImageAdReviewContent = ({
  imageAd,
  selectedPreview,
  setSelectedPreview,
  handleOnClickNextPreview,
  previewImages,
  images,
  marketingImagesKeys,
  squareMarketingImagesKeys,
  randomTitle,
  randomDescription,
  fakeColor
}: ImageAdReviewContentProps) => {
  const navigate = useNavigate()

  const breadcrumbItems = [
    {
      label: 'Plan média'
    },
    {
      onClick: () => {
        navigate(`/media-plan/${imageAd.navigation.mediaPlan.id}/review`)
      },
      label: imageAd.navigation.mediaPlan.name
    },
    {
      onClick: () => {
        navigate(`/campaign/${imageAd.navigation.campaign.id}/review`)
      },
      label: `Campagne « ${imageAd.navigation.campaign.name} »`
    },
    {
      label: `Annonce « ${imageAd.name} »`
    }
  ]

  const namePanel: PanelType = {
    title: 'Noms',
    disabled: true,
    isDropdown: false,
    isShown: true,
    sections: [
      {
        content: (
          <>
            <Input
              type="text"
              value={imageAd.name}
              isRequired={true}
              placeholder="Nom de mon annonce"
              label={`Nom de l'annonce`}
              containerStyle={{ marginBottom: Margin.m6 }}
              disabled
              withBorder
            />
            <Input
              type="text"
              value={imageAd.businessName}
              isRequired={true}
              placeholder="Nom de l'entreprise"
              label={`Nom de l'entreprise`}
              disabled
              withBorder
            />
          </>
        )
      }
    ]
  }

  const previewPanel: PanelType = {
    title: 'Aperçu de votre annonce',
    disabled: false,
    isDropdown: false,
    sections: [
      {
        content: (
          <PreviewContainer>
            <CarouselPreview
              selectedPreview={selectedPreview}
              setSelectedPreview={setSelectedPreview}
              handleOnClickNextPreview={handleOnClickNextPreview}
              title={randomTitle}
              description={randomDescription}
              businessName={imageAd.businessName}
              adImageSquare={previewImages.adImageSquare}
              adImageRectangle={previewImages.adImageRectangle}
              logoImage={images.logo ?? ''}
              buttonColor={fakeColor}
            />
          </PreviewContainer>
        )
      }
    ]
  }

  const urlPanel: PanelType = {
    title: 'URL',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <Input
            type="text"
            placeholder="https://example.com"
            isRequired={true}
            label={'URL finale'}
            value={imageAd.url}
            disabled
            withBorder
          />
        )
      }
    ]
  }

  const longHeadlinePanel: PanelType = {
    title: 'Titre long',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <Input
            type="text"
            label={'Titre long'}
            placeholder="Titre long"
            maxLength={90}
            showCharacterCount
            value={imageAd.longHeadline}
            disabled
            withBorder
          />
        )
      }
    ]
  }

  const headlinesPanel: PanelType = {
    title: 'Titres',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <>
            <Input
              type="text"
              label={'Titre 1'}
              placeholder="Titre 1"
              maxLength={30}
              showCharacterCount
              value={imageAd.headline1}
              containerStyle={{ marginBottom: Margin.m6 }}
              disabled
              withBorder
            />
            <Input
              type="text"
              label={'Titre 2'}
              placeholder="Titre 2"
              maxLength={30}
              showCharacterCount
              value={imageAd.headline2}
              isRequired={false}
              containerStyle={{ marginBottom: Margin.m6 }}
              disabled
              withBorder
            />
            <Input
              type="text"
              label={'Titre 3'}
              placeholder="Titre 3"
              maxLength={30}
              showCharacterCount
              value={imageAd.headline3}
              isRequired={false}
              disabled
              withBorder
            />
          </>
        )
      }
    ]
  }

  const descriptionsPanel: PanelType = {
    title: 'Descriptions',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <>
            <Input
              type="textarea"
              placeholder="Description 1"
              label={`Description 1`}
              value={imageAd.description1}
              maxLength={90}
              showCharacterCount
              containerStyle={{ marginBottom: Margin.m6 }}
              disabled
              withBorder
            />
            <Input
              type="textarea"
              placeholder="Description 2"
              label={`Description 2`}
              value={imageAd.description2}
              maxLength={90}
              showCharacterCount
              isRequired={false}
              containerStyle={{ marginBottom: Margin.m6 }}
              disabled
              withBorder
            />
            <Input
              type="textarea"
              placeholder="Description 3"
              label={`Description 3`}
              value={imageAd.description3}
              maxLength={90}
              showCharacterCount
              isRequired={false}
              disabled
              withBorder
            />
          </>
        )
      }
    ]
  }

  const marketingImagesSelectionPanel: PanelType = {
    title: 'Images paysages',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <ImagesContainer>
            {marketingImagesKeys.map((img, index) => {
              if (images[marketingImagesKeys[index]]) {
                return (
                  <img
                    key={marketingImagesKeys[index]}
                    src={images[marketingImagesKeys[index]] ?? undefined}
                    width="30%"
                    loading="lazy"
                  />
                )
              } else {
                return null
              }
            })}
          </ImagesContainer>
        )
      }
    ]
  }

  const squareMarketingImagesSelectionPanel: PanelType = {
    title: 'Images carrées',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <ImagesContainer>
            {squareMarketingImagesKeys.map((img, index) => {
              if (images[squareMarketingImagesKeys[index]]) {
                return (
                  <img
                    key={squareMarketingImagesKeys[index]}
                    src={images[squareMarketingImagesKeys[index]] ?? undefined}
                    width="16%"
                    loading="lazy"
                  />
                )
              } else {
                return null
              }
            })}
          </ImagesContainer>
        )
      }
    ]
  }

  const logoSelectionPanel: PanelType = {
    title: 'Logo',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <>
            <img
              alt="logo"
              src={images.logo ?? undefined}
              width="8%"
              loading="lazy"
            />
          </>
        )
      }
    ]
  }

  const panels: (JSX.Element | PanelType)[] = [
    namePanel,
    urlPanel,
    longHeadlinePanel,
    headlinesPanel,
    descriptionsPanel,
    marketingImagesSelectionPanel,
    squareMarketingImagesSelectionPanel,
    logoSelectionPanel,
    previewPanel
  ]

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Revue de l'annonce « ${imageAd.name} »`}
        panels={panels}
        validation={{
          wording: 'Retour à la campagne',
          action: () => {
            navigate(`/campaign/${imageAd.navigation.campaign.id}/review`)
          },
          disabled: false
        }}
      />
    </>
  )
}

export default AdvertiserImageAdReviewContent
