import {
  GoogleCampaignType,
  checkIfGoogleCampaign
} from './google/googleCampaign'
import {
  LinkedinCampaignType,
  checkIfLinkedinCampaign
} from './linkedin/linkedinCampaign'

export type CampaignType = GoogleCampaignType | LinkedinCampaignType

export const checkIfCampaign = (
  value: any
): value is GoogleCampaignType | LinkedinCampaignType => {
  return (
    value && (checkIfGoogleCampaign(value) || checkIfLinkedinCampaign(value))
  )
}

export const checkIfCampaignNameOnlyArray = (
  data: any
): data is { id: string; name: string }[] => {
  return (
    Array.isArray(data) &&
    data.every(
      (campaign: any) =>
        campaign.id &&
        campaign.name &&
        typeof campaign.id === 'string' &&
        typeof campaign.name === 'string'
    )
  )
}
