import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const modalStyle: CSSProperties = {
  width: '25%',
  height: 'auto',
  backgroundColor: Colors.NEW_YETIC_GREY,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}

export const titleStyle: CSSProperties = {
  justifyContent: 'center',
  marginBottom: Margin.m4
}

export const listItemStyle: CSSProperties = {
  textAlign: 'left',
  marginBottom: 0
}

export const ItemList = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-left: ${Padding.p6};
  padding-right: ${Padding.p6};
  margin-top: ${Margin.m4};
`

export const EmptyList = styled(ItemList)`
  align-items: center;
  justify-content: center;
`

export const Item = styled.li<{ isLastItem: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(props) => (props.isLastItem ? '' : '1px solid #cccbcc')};
  padding: ${Padding.p5};
`

export const PlatformDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.m4};
`

export const platformLogo: CSSProperties = {
  width: '20px'
}

export const AdImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${Margin.m4} 0 ${Margin.m8};
`
