import styled from '@emotion/styled'

export const ContainerBox = styled.div<{
  totalDeg: number
  valueDeg: number
  totalColor: string
  valueColor: string
  size: number
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(
    ${(props) => props.valueColor} 0deg ${(props) => props.valueDeg}deg,
    ${(props) => props.totalColor} 0deg ${(props) => props.totalDeg}deg
  );
`

export const Hole = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`
