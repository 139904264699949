import { AdNavigation, AdType } from '../ad'
import { checkIfStatus } from '../../status'
import { checkIfFormatApiEnum } from '../../format'

export type LinkedinImageAdType = {
  id: string
  name: string
  introText: string
  url: string
  headline: string
  imageId: string
  cta: string
}

export type FullLinkedinImageAdType = AdType &
  Omit<LinkedinImageAdType, 'id' | 'name'>

export type LinkedinImageAdDetails = FullLinkedinImageAdType & {
  startDate?: string
  feedback?: string
  navigation: AdNavigation
}

export const checkIfLinkedinImageAd = (
  value: any
): value is LinkedinImageAdType => {
  return (
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.introText &&
    typeof value.introText === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.headline &&
    typeof value.headline === 'string' &&
    value.imageId &&
    typeof value.imageId === 'string' &&
    value.cta &&
    typeof value.cta === 'string'
  )
}

export const checkIfLinkedinImageAdDetails = (
  value: any
): value is LinkedinImageAdDetails => {
  return (
    value.name &&
    typeof value.name === 'string' &&
    value.introText &&
    typeof value.introText === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.headline &&
    typeof value.headline === 'string' &&
    value.imageId &&
    typeof value.imageId === 'string' &&
    value.cta &&
    typeof value.cta === 'string' &&
    value.status &&
    checkIfStatus(value.status)
  )
}

export const checkIfLinkedinImageAdArray = (
  data: any
): data is LinkedinImageAdType[] => {
  return (
    Array.isArray(data) &&
    data.every((imageAd: any) => checkIfLinkedinImageAd(imageAd))
  )
}

export const checkIfFullLinkedinImageAd = (
  value: any
): value is FullLinkedinImageAdType => {
  return (
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    checkIfStatus(value.status) &&
    value.introText &&
    typeof value.introText === 'string' &&
    value.url &&
    typeof value.url === 'string' &&
    value.headline &&
    typeof value.headline === 'string' &&
    value.imageId &&
    typeof value.imageId === 'string' &&
    value.cta &&
    typeof value.cta === 'string'
  )
}

export const checkIfFullLinkedinImageAdArray = (
  data: any
): data is FullLinkedinImageAdType[] => {
  return (
    data &&
    Array.isArray(data) &&
    data.every((fullImageAd: any) => checkIfFullLinkedinImageAd(fullImageAd))
  )
}
