import {
  BarChartData,
  Point as BarChartPoint
} from '../../../../../components/Chart/BarChart'
import { ElementItem } from '../../../../../components/Table'
import Colors from '../../../../../constants/colors'
import { GetCampaignDevicesReportingOutputType } from '../../../../../types/reporting/campaign/output'

type FormatDevicesDataOutput = {
  devicesBarChart: BarChartData<BarChartPoint>[]
  devicesTableData: ElementItem[]
}

export const formatDevicesData = (
  data: GetCampaignDevicesReportingOutputType
): FormatDevicesDataOutput => {
  const devicesBarChart: BarChartData<BarChartPoint>[] = [
    {
      name: 'Clics',
      color: 'blue',
      values: [
        {
          category: 'Smartphone',
          value: data.smartphone.clicks
        },
        {
          category: 'Tablette',
          value: data.tablet.clicks
        },
        {
          category: 'Ordinateur',
          value: data.desktop.clicks
        },
        {
          category: 'TV',
          value: data.tv.clicks
        }
      ]
    },
    {
      name: 'Impressions',
      color: 'deeppink',
      values: [
        {
          category: 'Smartphone',
          value: data.smartphone.impressions
        },
        {
          category: 'Tablette',
          value: data.tablet.impressions
        },
        {
          category: 'Ordinateur',
          value: data.desktop.impressions
        },
        {
          category: 'TV',
          value: data.tv.impressions
        }
      ]
    },
    {
      name: 'Coût',
      color: 'orange',
      unit: '€',
      values: [
        {
          category: 'Smartphone',
          value: data.smartphone.cost
        },
        {
          category: 'Tablette',
          value: data.tablet.cost
        },
        {
          category: 'Ordinateur',
          value: data.desktop.cost
        },
        {
          category: 'TV',
          value: data.tv.cost
        }
      ]
    },
    {
      name: 'CO₂',
      color: Colors.NEW_YETIC_CO2,
      unit: 'g',
      values: [
        {
          category: 'Smartphone',
          value: data.smartphone.co2
        },
        {
          category: 'Tablette',
          value: data.tablet.co2
        },
        {
          category: 'Ordinateur',
          value: data.desktop.co2
        },
        {
          category: 'TV',
          value: data.tv.co2
        }
      ]
    }
  ]

  const devicesTableData: ElementItem[] = [
    {
      id: 'smartphone',
      device: 'Smartphone',
      clicks: data.smartphone.clicks,
      impressions: data.smartphone.impressions,
      cost: data.smartphone.cost,
      co2: data.smartphone.co2
    },
    {
      id: 'tablet',
      device: 'Tablette',
      clicks: data.tablet.clicks,
      impressions: data.tablet.impressions,
      cost: data.tablet.cost,
      co2: data.tablet.co2
    },
    {
      id: 'desktop',
      device: 'Ordinateur',
      clicks: data.desktop.clicks,
      impressions: data.desktop.impressions,
      cost: data.desktop.cost,
      co2: data.desktop.co2
    },
    {
      id: 'tv',
      device: 'TV',
      clicks: data.tv.clicks,
      impressions: data.tv.impressions,
      cost: data.tv.cost,
      co2: data.tv.co2
    }
  ]

  return { devicesBarChart, devicesTableData }
}
