import React, { CSSProperties } from 'react'

import {
  DivContainer,
  GaleryContainer,
  RenderPreview,
  PreviewWrapper,
  NextRender
} from './styles'
import SmartphonePreview1 from '../SmartphonePreview1'
import SmartphonePreview2 from '../SmartphonePreview2'
import SmartphonePreview3 from '../SmartphonePreview3'
import SmartphonePreview4 from '../SmartphonePreview4'
import YoutubePreview from '../YoutubePreview'
import WebpagePreview1 from '../WebpagePreview1'
import WebpagePreview2 from '../WebpagePreview2'
import WebpagePreview3 from '../WebpagePreview3'
import WebpagePreview4 from '../WebpagePreview4'
import { ArrowRight } from '../../../../Icon'
import Colors from '../../../../../constants/colors'

type CarouselPreviewProps = {
  selectedPreview: number
  setSelectedPreview: (preview: number) => void
  handleOnClickNextPreview: () => void
  title: string
  description: string
  businessName: string
  buttonColor: string
  backgroundColor?: string
  adImageSquare: string
  adImageRectangle: string
  logoImage: string
  style?: CSSProperties
  scale?: number
}

const CarouselPreview = ({
  selectedPreview,
  setSelectedPreview,
  handleOnClickNextPreview,
  title,
  description,
  adImageSquare,
  adImageRectangle,
  logoImage,
  businessName,
  buttonColor
}: CarouselPreviewProps) => {
  const titleFake = "Titre de l'annonce"
  const descriptionFake = "Description de l'annonce"
  const adImageSquareFake = 'https://via.placeholder.com/300x300'
  const adImageRectangleFake = 'https://via.placeholder.com/300x600'
  const logoImageFake = 'https://via.placeholder.com/50x50'
  const businessNameFake = "Nom de l'entreprise"
  const buttonColorFake = Colors.YETIC_GREY_AD

  const handleDisplayPreview = (previewNumber: number) => () => {
    setSelectedPreview(previewNumber)
  }

  const renderSelectedPreview = () => {
    switch (selectedPreview) {
      case 1:
        return (
          <SmartphonePreview1
            title={title}
            description={description}
            adImageSquare={adImageSquare}
            logoImage={logoImage}
            buttonColor={buttonColor}
          />
        )
      case 2:
        return (
          <SmartphonePreview2
            title={title}
            description={description}
            adImageRectangle={adImageRectangle}
            logoImage={logoImage}
          />
        )
      case 3:
        return (
          <SmartphonePreview3
            title={title}
            description={description}
            adImageSquare={adImageSquare}
            logoImage={logoImage}
            buttonColor={buttonColor}
          />
        )
      case 4:
        return (
          <SmartphonePreview4
            title={title}
            description={description}
            businessName={businessName}
            logoImage={logoImage}
          />
        )
      case 5:
        return (
          <WebpagePreview3
            title={title}
            description={description}
            logoImage={logoImage}
            adImageSquare={adImageSquare}
            buttonColor={buttonColor}
          />
        )
      case 6:
        return (
          <WebpagePreview1
            title={title}
            description={description}
            businessName={businessName}
            adImageRectangle={adImageRectangle}
            buttonColor={buttonColor}
          />
        )
      case 7:
        return (
          <WebpagePreview2
            title={title}
            description={description}
            businessName={businessName}
            logoImage={logoImage}
          />
        )
      case 8:
        return (
          <WebpagePreview4
            title={title}
            description={description}
            logoImage={logoImage}
            adImageRectangle={adImageRectangle}
          />
        )
      case 9:
        return (
          <YoutubePreview
            title={title}
            description={description}
            adImageRectangle={adImageRectangle}
            businessName={businessName}
          />
        )
      default:
        return (
          <SmartphonePreview1
            title={title}
            description={description}
            adImageSquare={adImageSquare}
            logoImage={logoImage}
            buttonColor={buttonColor}
          />
        )
    }
  }

  return (
    <DivContainer>
      <RenderPreview>
        {renderSelectedPreview()}
        <NextRender onClick={handleOnClickNextPreview}>
          <ArrowRight width="35px" height="35px" />
        </NextRender>
      </RenderPreview>
      <GaleryContainer>
        <PreviewWrapper onClick={handleDisplayPreview(1)}>
          <SmartphonePreview1
            title={titleFake}
            description={descriptionFake}
            adImageSquare={adImageSquareFake}
            logoImage={logoImageFake}
            buttonColor={buttonColor}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(2)}>
          <SmartphonePreview2
            title={titleFake}
            description={descriptionFake}
            adImageRectangle={adImageRectangleFake}
            logoImage={logoImageFake}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(3)}>
          <SmartphonePreview3
            title={titleFake}
            description={descriptionFake}
            adImageSquare={adImageSquareFake}
            logoImage={logoImageFake}
            buttonColor={buttonColor}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(4)}>
          <SmartphonePreview4
            title={titleFake}
            businessName={businessNameFake}
            description={descriptionFake}
            logoImage={logoImageFake}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(5)}>
          <WebpagePreview3
            title={titleFake}
            description={descriptionFake}
            logoImage={logoImageFake}
            adImageSquare={adImageSquareFake}
            buttonColor={buttonColorFake}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(6)}>
          <WebpagePreview1
            title={titleFake}
            description={descriptionFake}
            businessName={businessNameFake}
            adImageRectangle={adImageRectangleFake}
            buttonColor={buttonColorFake}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(7)}>
          <WebpagePreview2
            title={titleFake}
            description={descriptionFake}
            businessName={businessNameFake}
            logoImage={logoImageFake}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(9)}>
          <YoutubePreview
            title={titleFake}
            description={descriptionFake}
            adImageRectangle={adImageRectangleFake}
            businessName={businessNameFake}
          />
        </PreviewWrapper>
        <PreviewWrapper onClick={handleDisplayPreview(8)}>
          <WebpagePreview4
            title={titleFake}
            description={descriptionFake}
            logoImage={logoImageFake}
            adImageRectangle={adImageRectangleFake}
          />
        </PreviewWrapper>
      </GaleryContainer>
    </DivContainer>
  )
}

export default CarouselPreview
