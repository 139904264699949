import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${Colors.NEW_YETIC_GREY};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 10px;
  margin-top: 30px;
`
