import React, { ReactElement } from 'react'

import {
  Home,
  Test,
  AdList,
  MediaPlanList,
  CampaignList,
  MediaPlanCreation,
  MediaPlanReview,
  CampaignReview,
  CampaignCreation,
  AdCreation,
  GoogleTextAdCreation,
  GoogleImageAdCreation,
  Authentication,
  MediaPlanValidation,
  CustomerDetails,
  AdReview,
  TextAdReview,
  GoogleImageAdReview,
  LinkedinImageAdReview,
  AdDetails,
  TextAdDetails,
  ImageAdDetails,
  Customers,
  MediaPlanDetails,
  Logout,
  VerificationEmail,
  CreateCustomer,
  CampaignDetails,
  CampaignEdit,
  MediaPlanEdit,
  TextAdEdit,
  Tutorial,
  LinkedinImageAdCreation,
  ResetPassword,
  ProfilePage,
  PlatformsPage,
  Analytics,
  ImageAdEdit
} from './pages'
import RequiredAuthRoute from './hoc/RequiredAuthRoute'
import RequiredIfNotAuthenticated from './hoc/RequiredIfNotAuthenticated'

const routes: { path: string; component: ReactElement }[] = [
  {
    path: '/',
    component: (
      <RequiredAuthRoute>
        <Home />
      </RequiredAuthRoute>
    )
  },
  { path: '/test', component: <Test /> },
  { path: '/tutorial', component: <Tutorial /> },
  {
    path: '/company/:companyId/media-plan/list',
    component: (
      <RequiredAuthRoute>
        <MediaPlanList />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/company/:companyId/media-plan/create',
    component: (
      <RequiredAuthRoute>
        <MediaPlanCreation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/customers',
    component: (
      <RequiredAuthRoute>
        <Customers />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/media-plan/:mediaPlanId/review',
    component: (
      <RequiredAuthRoute>
        <MediaPlanReview />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/media-plan/:mediaPlanId/validate',
    component: (
      <RequiredAuthRoute>
        <MediaPlanValidation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/media-plan/:mediaPlanId/edit',
    component: (
      <RequiredAuthRoute>
        <MediaPlanEdit />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/media-plan/:mediaPlanId/details',
    component: (
      <RequiredAuthRoute>
        <MediaPlanDetails />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/media-plan/:mediaPlanId/campaign/list',
    component: (
      <RequiredAuthRoute>
        <CampaignList />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/media-plan/:mediaPlanId/campaign/create',
    component: (
      <RequiredAuthRoute>
        <CampaignCreation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/review',
    component: (
      <RequiredAuthRoute>
        <CampaignReview />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/edit',
    component: (
      <RequiredAuthRoute>
        <CampaignEdit />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/details',
    component: (
      <RequiredAuthRoute>
        <CampaignDetails />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/ad/list',
    component: (
      <RequiredAuthRoute>
        <AdList />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/ad/create',
    component: (
      <RequiredAuthRoute>
        <AdCreation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/ad/google/text/create',
    component: (
      <RequiredAuthRoute>
        <GoogleTextAdCreation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/ad/google/image/create',
    component: (
      <RequiredAuthRoute>
        <GoogleImageAdCreation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/campaign/:campaignId/ad/linkedin/image/create',
    component: (
      <RequiredAuthRoute>
        <LinkedinImageAdCreation />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/image/:adId/edit',
    component: (
      <RequiredAuthRoute>
        <ImageAdEdit />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/text/:adId/edit',
    component: (
      <RequiredAuthRoute>
        <TextAdEdit />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/customer/:customerId/details',
    component: (
      <RequiredAuthRoute>
        <CustomerDetails />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/review',
    component: (
      <RequiredAuthRoute>
        <AdReview />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/google/text/review',
    component: (
      <RequiredAuthRoute>
        <TextAdReview />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/google/image/review',
    component: (
      <RequiredAuthRoute>
        <GoogleImageAdReview />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/linkedin/image/review',
    component: (
      <RequiredAuthRoute>
        <LinkedinImageAdReview />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/details',
    component: (
      <RequiredAuthRoute>
        <AdDetails />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/text/details',
    component: (
      <RequiredAuthRoute>
        <TextAdDetails />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/ad/:adId/image/details',
    component: (
      <RequiredAuthRoute>
        <ImageAdDetails />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/customers',
    component: (
      <RequiredAuthRoute>
        <Customers />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/auth',
    component: (
      <RequiredIfNotAuthenticated>
        <Authentication />
      </RequiredIfNotAuthenticated>
    )
  },
  { path: '/logout', component: <Logout /> },
  { path: '/email-verification', component: <VerificationEmail /> },
  {
    path: '/customer/create',
    component: (
      <RequiredAuthRoute>
        <CreateCustomer />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/password-reset',
    component: (
      <RequiredAuthRoute noRedirection>
        <ResetPassword />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/profile',
    component: (
      <RequiredAuthRoute>
        <ProfilePage />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/platforms',
    component: (
      <RequiredAuthRoute>
        <PlatformsPage />
      </RequiredAuthRoute>
    )
  },
  {
    path: '/analytics',
    component: (
      <RequiredAuthRoute>
        <Analytics />
      </RequiredAuthRoute>
    )
  }
]

export default routes
