export const checkIfMoreOneChar = (val: string) => {
  return val.length > 0
}

export const checkIfNameValid = (val: string) => {
  return checkIfMoreOneChar(val) && val.length <= 20
}

export const checkIfTitleValid = (val: string, index: number) => {
  if (index === 0) {
    return checkIfMoreOneChar(val) && val.length <= 40
  } else {
    return val.length <= 40
  }
}

export const checkIfDescriptionValid = (val: string, index: number) => {
  if (index === 0) {
    return checkIfMoreOneChar(val) && val.length <= 90
  } else {
    return val.length <= 90
  }
}

export const checkIfImageGiven = (img: File | null) => {
  return img != null
}

export const checkImageSize = (img: File | null) => {
  return img == null || img.size <= 1000000
}
