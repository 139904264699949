import styled from '@emotion/styled'

import Colors from '../../../constants/colors'

export const Head = styled.header`
  height: 74px;
  background: ${Colors.NEW_YETIC_GREY_BACKGROUND};
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
`

export const Actions = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 24px;
    color: #142a4c;
    margin: 0 15px;
  }
`
