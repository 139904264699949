import { backendURL } from '../../../constants/urls'
import { ViewEnum } from '../../../types/axios/common'
import { Status } from '../../../types/status'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : add all the fields that the backend returns when view = undefined
export type MediaPlanType = {
  id: string
  name: string
  customerName?: string
  editorName?: string
}

export type GetMediaPlansOutputType = {
  mediaPlans: MediaPlanType[]
  navigation: {
    mediaPlansCount: number
    skip: number
  }
}

type GetMediaPlansInputType = {
  status?: Status
  view?: ViewEnum
  skip?: number
}

export const getMediaPlans = async ({
  status,
  view,
  skip
}: GetMediaPlansInputType): Promise<GetMediaPlansOutputType | null> => {
  const url = `${backendURL}/media-plans${skip ? `?skip=${skip * 5}` : ''}${
    status ? (skip ? `&status=${status}` : `?status=${status}`) : ''
  }${view ? (skip || status ? `&view=${view}` : `?view=${view}`) : ''}`
  try {
    const response = await axiosGet({
      url
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (view === ViewEnum.NAME) {
        if (response.data && Array.isArray(response.data.mediaPlans)) {
          return response.data
        }
      } else if (checkIfMediaPlans(response.data)) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const getMediaPlansForHeader = async ({
  status,
  view,
  skip
}: GetMediaPlansInputType): Promise<{ id: string; name: string }[] | null> => {
  const url = `${backendURL}/media-plans${skip ? `?skip=${skip * 5}` : ''}${
    status ? (skip ? `&status=${status}` : `?status=${status}`) : ''
  }${view ? (skip || status ? `&view=${view}` : `?view=${view}`) : ''}`
  try {
    const response = await axiosGet({ url })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (response.data && Array.isArray(response.data.mediaPlans)) {
        return response.data.mediaPlans.map((mp: MediaPlanType) => ({
          id: mp.id,
          name: mp.name
        }))
      }
    }
    return null
  } catch (error) {
    console.error('Error in getMediaPlansForHeader:', error)
    return null
  }
}

export const checkIfMediaPlans = (
  value: any
): value is GetMediaPlansOutputType => {
  return (
    value &&
    Array.isArray(value.mediaPlans) &&
    value.mediaPlans.every((mp: MediaPlanType) => {
      return (
        mp.id &&
        typeof mp.id === 'string' &&
        mp.name &&
        typeof mp.name === 'string' &&
        ((mp.customerName && typeof mp.customerName === 'string') ||
          (mp.editorName && typeof mp.editorName === 'string'))
      )
    }) &&
    value.navigation &&
    typeof value.navigation.mediaPlansCount === 'number' &&
    typeof value.navigation.skip === 'number'
  )
}
