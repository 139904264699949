import React from 'react'

import {
  KeywordDetails,
  getCompetitionTranslation
} from '../../../../../types/campaign/keyword'
import {
  Column,
  Container,
  ContainerScroll,
  Item,
  MainContainer
} from './styles'
import DefaultText from '../../../../../components/DefaultText'
import { Checkbox } from '../../../../../components/CheckBox'

type KeywordsDetailsProps = {
  currentKeyword: string
  selectedKeywords: string[]
  keywordDetails: KeywordDetails[] | null
  addKeyword: (keyword: string) => void
  removeKeyword: (index: number) => void
}

const KeywordsDetails = ({
  currentKeyword,
  selectedKeywords,
  keywordDetails,
  addKeyword,
  removeKeyword
}: KeywordsDetailsProps) => {
  const initialColumns = [
    {
      flex: 5,
      items: ['Mot-clés', 'Mot-clé que vous avez fourni']
    },
    {
      flex: 2,
      items: ['Recherches mensuelles moy.', '']
    },
    {
      flex: 2,
      items: ['Concurrence', '']
    },
    {
      flex: 3,
      items: ['Enchère haut de page', '']
    }
  ]

  const columns = initialColumns.map((column, index) => {
    if (keywordDetails) {
      switch (index) {
        case 0:
          return {
            ...column,
            items: [
              ...column.items,
              currentKeyword,
              'Idées de mots-clés',
              ...keywordDetails.map((keyword) => keyword.name)
            ]
          }
        case 1:
          return {
            ...column,
            items: [
              ...column.items,
              keywordDetails[0].avgMonthlySearches,
              '',
              ...keywordDetails.map((keyword) => keyword.avgMonthlySearches)
            ]
          }
        case 2:
          return {
            ...column,
            items: [
              ...column.items,
              getCompetitionTranslation(keywordDetails[0].competition),
              '',
              ...keywordDetails.map((keyword) =>
                getCompetitionTranslation(keyword.competition)
              )
            ]
          }
        case 3:
          return {
            ...column,
            items: [
              ...column.items,
              keywordDetails[0].lowBid > 0
                ? `${keywordDetails[0].lowBid}€ - ${keywordDetails[0].highBid}€`
                : 'Non défini',
              '',
              ...keywordDetails.map((keyword) =>
                keyword.lowBid > 0
                  ? `${keyword.lowBid}€ - ${keyword.highBid}€`
                  : 'Non défini'
              )
            ]
          }
        default:
          return initialColumns[index]
      }
    } else {
      return initialColumns[index]
    }
  })

  const ItemText = ({ text }: { text: string | number }) => (
    <DefaultText size="small" style={{ margin: 0 }}>
      {text}
    </DefaultText>
  )

  return (
    <MainContainer>
      <Container>
        {columns.map((column, columnIndex) => (
          <Column key={columnIndex} style={{ flex: column.flex }}>
            {column.items.slice(0, 4).map((item, index) => (
              <Item
                key={index}
                column={columnIndex}
                row={index}
                totalRows={column.items.length}
                totalColumns={columns.length}
              >
                {columnIndex === 0 && index === 2 ? (
                  <Checkbox
                    selected={selectedKeywords.includes(item as string)}
                    onChange={() => {
                      if (selectedKeywords.includes(item as string)) {
                        removeKeyword(selectedKeywords.indexOf(item as string))
                      } else {
                        addKeyword(item as string)
                      }
                    }}
                  >
                    <ItemText text={item} />
                  </Checkbox>
                ) : (
                  <ItemText text={item} />
                )}
              </Item>
            ))}
          </Column>
        ))}
      </Container>
      <ContainerScroll>
        {columns.map((column, columnIndex) => (
          <Column key={columnIndex} style={{ flex: column.flex }}>
            {column.items.slice(4).map((item, index) => (
              <Item
                key={index}
                column={columnIndex}
                row={index + 4}
                totalRows={column.items.length}
                totalColumns={columns.length}
              >
                {columnIndex === 0 ? (
                  <Checkbox
                    selected={selectedKeywords.includes(item as string)}
                    onChange={() => {
                      if (selectedKeywords.includes(item as string)) {
                        removeKeyword(selectedKeywords.indexOf(item as string))
                      } else {
                        addKeyword(item as string)
                      }
                    }}
                  >
                    <ItemText text={item} />
                  </Checkbox>
                ) : (
                  <ItemText text={item} />
                )}
              </Item>
            ))}
          </Column>
        ))}
      </ContainerScroll>
    </MainContainer>
  )
}

export default KeywordsDetails
