import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from '../../types/icon'

const GoogleIcon = ({
  height = '25px',
  width = '25px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 19.62 20"
    >
      <path
        d="M23.161,11.841l-.1-.428H13.755v3.938h5.559a5.564,5.564,0,0,1-5.444,4.184,6.51,6.51,0,0,1-4.381-1.746,6.254,6.254,0,0,1-1.866-4.415,6.451,6.451,0,0,1,1.832-4.41,6.232,6.232,0,0,1,4.352-1.7,5.677,5.677,0,0,1,3.7,1.443l2.8-2.784a9.9,9.9,0,0,0-6.593-2.539h0A10.192,10.192,0,0,0,6.5,6.315,10.151,10.151,0,0,0,3.656,13.38a10.049,10.049,0,0,0,2.736,6.949,10.439,10.439,0,0,0,7.526,3.054A9.283,9.283,0,0,0,20.68,20.54a9.983,9.983,0,0,0,2.6-6.915A11.514,11.514,0,0,0,23.161,11.841Z"
        transform="translate(-3.656 -3.382)"
        fill={color}
      />
    </svg>
  )
}

export default GoogleIcon
