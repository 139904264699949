import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContentContainer } from './styles'
import DefaultText from '../../components/DefaultText'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { AppContext } from '../../contexts/AppContext'

const InProgress = () => {
  const { isEditor } = useContext(AppContext)
  const navigate = useNavigate()

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <ContentContainer>
        <DefaultText size="big">
          Page en cours de création, revenez plus tard !
        </DefaultText>
      </ContentContainer>
    </AuthenticatedTemplate>
  )
}

export default InProgress
