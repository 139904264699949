import React from 'react'

import { contentTextStyle, modalStyle, questionTextStyle } from './styles'
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal'
import DefaultText from '../../../../components/DefaultText'
import {
  PlatformAPIEnum,
  PlatformBoolean,
  getPlatformEnum
} from '../../../../types/platform'

type PlatformConnectionModalProps = {
  onClose: () => void
  handleReject: () => void
  handleValidate: () => void
  platform: PlatformAPIEnum
  isCampaignCreationFlow?: boolean
}

const PlatformConnectionModal = ({
  onClose,
  handleValidate,
  platform
}: PlatformConnectionModalProps) => {
  const platformName = getPlatformEnum(platform)

  const onClickReject = onClose
  const textReject = 'Annuler'

  return (
    <ConfirmationModal
      onClose={onClose}
      onClickReject={onClickReject}
      onClickConfirm={handleValidate}
      textConfirm={'Je me connecte'}
      textReject={textReject}
      title={`Connexion à ${platformName}`}
      style={modalStyle}
    >
      <DefaultText size="medium" bold style={questionTextStyle}>
        {' '}
        {`Souhaitez-vous vous connecter à ${platformName} ?`}
      </DefaultText>
    </ConfirmationModal>
  )
}

export default PlatformConnectionModal
