import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'

export const ActionsButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const ActionsButtonIconContainer = styled.button<{ size: number }>`
  border-radius: 50px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;

  :hover {
    background-color: ${Colors.YETIC_BACKGROUND_BUTTON};
  }
`

export const ActionsListContainer = styled.div`
  position: absolute;
  bottom: 30px;
  background-color: ${Colors.YETIC_WHITE};
  border-radius: 5px;
  box-shadow: 0px 0px 2px #142a4c40;
  padding: ${Padding.p3};
  text-align: right;
  z-index: 1;
  max-width: 200px;
`

export const Action = styled.div`
  cursor: pointer;
  padding: ${Padding.p3};
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
  }
`
