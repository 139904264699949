import React from 'react'

import { IconType } from '../../types/icon'

const LinkedinRepost = ({
  height = '20px',
  width = '20px',
  style,
  color
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-4 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-60.000000, -7075.000000)"
          fill={color}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M24,6929.87115 L24,6936.81107 C24,6937.90659 23.105,6938.7949 22,6938.7949 L8.207,6938.7949 C7.762,6938.7949 7.539,6939.32828 7.854,6939.64058 L9.536,6941.30815 C9.926,6941.6948 9.926,6942.32237 9.536,6942.71001 C9.145,6943.09666 8.512,6943.09666 8.121,6942.71001 C6.751,6941.35078 5.737,6940.34647 4.586,6939.20436 C3.805,6938.43006 3.805,6937.17492 4.586,6936.40063 L8.121,6932.89497 C8.512,6932.50832 9.145,6932.50832 9.536,6932.89497 C9.926,6933.28262 9.926,6933.91018 9.536,6934.29783 L7.854,6935.96539 C7.539,6936.27769 7.762,6936.81206 8.207,6936.81206 L21,6936.81206 C21.552,6936.81206 22,6936.36692 22,6935.81965 L22,6929.87115 C22,6929.32388 22.448,6928.87973 23,6928.87973 C23.552,6928.87973 24,6929.32388 24,6929.87115 M4,6928.12923 L4,6921.1893 C4,6920.09477 4.895,6918.96654 6,6918.96654 L19.793,6918.96654 C20.238,6918.96654 20.461,6918.55213 20.146,6918.23983 L18.464,6916.69322 C18.074,6916.30557 18.074,6915.67801 18.464,6915.29036 C18.855,6914.90371 19.488,6914.90272 19.879,6915.29036 C20.431,6915.83762 22.867,6918.25272 23.414,6918.79205 C24.195,6919.56635 24.195,6920.81851 23.414,6921.59281 L19.879,6925.09053 C19.488,6925.47718 18.855,6925.46231 18.464,6925.07566 C18.074,6924.68801 18.074,6924.0307 18.464,6923.64306 L20.146,6921.91601 C20.461,6921.60371 20.238,6920.94938 19.793,6920.94938 L7,6920.94938 C6.448,6920.94938 6,6921.63345 6,6922.18072 L6,6928.12923 C6,6928.67748 5.552,6929.12064 5,6929.12064 C4.448,6929.12064 4,6928.67748 4,6928.12923"
              id="arrow_repeat-[#242]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default LinkedinRepost
