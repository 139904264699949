import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'
import Colors from '../../../constants/colors'

export const LeftMenuDiv = styled.div`
  width: 100%;
`

export const HeaderDiv = styled.div`
  marginbottom: ${Margin.m7};
  padding: ${Padding.p7};
  width: 800px;
  margin: auto;
`

export const TextDiv = styled.div`
  margin: ${Margin.m9};
  padding: ${Padding.p11};
  text-align: center;
`

export const Container = styled.div`
  width: 80%;
  max-width: 900px;
  margin: auto;
  margin-top: ${Margin.m9};
`
