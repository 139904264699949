import { Status } from '../types/status'

const statusHasStarted = (mediaPlanStatus: Status) => {
  return !(
    mediaPlanStatus === Status.PENDING ||
    mediaPlanStatus === Status.TO_PAY ||
    mediaPlanStatus === Status.DRAFT ||
    mediaPlanStatus === Status.REJECTED ||
    mediaPlanStatus === Status.SCHEDULED
  )
}

export default statusHasStarted
