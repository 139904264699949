import { backendURL } from '../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetCompanyAudiencesOutput = {
  id: string
  name: string
  mediaPlanName: string
}[]

export const getCompanyAudiences = async ({
  companyId
}: {
  companyId: string
}): Promise<GetCompanyAudiencesOutput | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/companies/${companyId}/audiences`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      return response.data.audiences
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCompanyAudiencesOutput = (
  data: any
): data is GetCompanyAudiencesOutput => {
  return (
    data &&
    Array.isArray(data) &&
    data.every((item) => {
      return (
        item &&
        item.id &&
        typeof item.id === 'string' &&
        item.name &&
        typeof item.name === 'string' &&
        item.mediaPlanName &&
        typeof item.mediaPlanName === 'string'
      )
    })
  )
}
