import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Margin from '../../../constants/margin'

export const analyticsContentPadding = 60
export const chartGap = 40

export const ContentContainer = styled.div`
  width: 100%;
  padding: ${Padding.p9} ${analyticsContentPadding}px;
  box-sizing: border-box;
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: ${Padding.p8} ${Padding.p9} 0;
  gap: ${Margin.m7};
`

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20vh;
`

export const TableContainer = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
`

export const MessageContainer = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 ${Padding.p10};
  box-sizing: border-box;
`

export const DatesFiltersContainer = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  gap: 40px;
`
