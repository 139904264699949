import React from 'react'

import {
  DivButtons,
  DivErrors,
  DivErrorsContainer,
  DivErrorsIntroduction,
  Preview
} from './styles'
import { PanelType, checkIfPanelType } from '../../types/templates/panels'
import Margin, { MarginButtonEnum } from '../../constants/margin'
import Title from '../../components/Title'
import Button from '../../components/Button'
import { Panel, PanelDropdown } from '../components/Panel'
import Padding from '../../constants/padding'
import AnimatedLoader from '../../components/AnimatedLoader'
import Colors from '../../constants/colors'
import CheckablePanel from '../components/Panel/CheckablePanel'

export type CreationPageTemplateProps = {
  title: string
  subTitle?: string
  preview?: JSX.Element
  panels: (PanelType | JSX.Element)[]
  errors?: {
    introduction?: string
    // TODO find a better name than errors.errors
    errors: string[]
  }
  validation: {
    wording: string
    action: () => void
    disabled: boolean
    isLoading?: boolean
  }
  cancel?: {
    wording: string
    action: () => void
    disabled: boolean
  }
}

export const CreationPageTemplate = ({
  title,
  subTitle,
  preview,
  panels,
  errors,
  validation,
  cancel
}: CreationPageTemplateProps) => {
  return (
    <div>
      <Title
        type="big"
        style={{
          textAlign: 'center',
          marginTop: Margin.m8,
          marginBottom: Margin.m3
        }}
      >
        {title}
      </Title>
      <Title
        type="thin"
        style={{ textAlign: 'center', marginBottom: Margin.m6 }}
      >
        {subTitle}
      </Title>
      {preview && <Preview>{preview}</Preview>}
      <div style={{ width: '80%', maxWidth: '900px', margin: 'auto' }}>
        {panels.map((panel, index) => {
          if (checkIfPanelType(panel)) {
            if (panel.isDropdown) {
              return (
                <PanelDropdown
                  key={panel.title}
                  title={panel.title}
                  description={panel.description}
                  sections={panel.sections}
                  disabled={panel.disabled}
                />
              )
            } else if (panel.isChecked !== undefined && panel.setIsChecked) {
              return (
                <CheckablePanel
                  key={panel.title}
                  title={panel.title}
                  description={panel.description}
                  sections={panel.sections}
                  disabled={panel.disabled}
                  isChecked={panel.isChecked}
                  setIsChecked={panel.setIsChecked}
                />
              )
            } else {
              return (
                <Panel
                  key={panel.title}
                  title={panel.title}
                  description={panel.description}
                  sections={panel.sections}
                  disabled={panel.disabled}
                  titleTooltip={panel.titleTooltip}
                  tip={panel.tip}
                  tipsFormat={panel.tipsFormat}
                />
              )
            }
          } else {
            return <div key={index}>{panel}</div>
          }
        })}
      </div>
      {errors && errors.errors.length > 0 && (
        <DivErrorsContainer>
          {errors.introduction && (
            <DivErrorsIntroduction>{errors.introduction}</DivErrorsIntroduction>
          )}
          {errors.errors.length > 0 && (
            <div>
              {errors.errors.map((error) => (
                <DivErrors key={error}>{error}</DivErrors>
              ))}
            </div>
          )}
        </DivErrorsContainer>
      )}
      <DivButtons>
        {cancel && (
          <Button
            type="secondary"
            onClick={cancel.action}
            width="fit-content"
            disabled={cancel.disabled}
          >
            {cancel.wording}
          </Button>
        )}
        <Button
          onClick={validation.action}
          disabled={validation.disabled}
          width="fit-content"
          style={{
            paddingLeft: Padding.pLeftButton,
            paddingRight: Padding.pRightButton,
            marginBottom: MarginButtonEnum.marginBottom
          }}
        >
          {validation.isLoading ? (
            <AnimatedLoader color={Colors.YETIC_WHITE} />
          ) : (
            validation.wording
          )}
        </Button>
      </DivButtons>
    </div>
  )
}
