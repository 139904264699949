import React from 'react'

import DefaultText from '../../DefaultText'
import Margin from '../../../constants/margin'
import {
  DivTips,
  DivContent,
  DivText,
  TipHeader,
  TipHeaderTitle
} from '../styles'
import { TipsProps } from '../../../types/tips'

export const TipsEcology = ({ text, style, title, bgColor }: TipsProps) => {
  return (
    <DivTips style={style} bgColor={bgColor}>
      <DivContent>
        <DivText>
          {title && (
            <TipHeader>
              <TipHeaderTitle>
                <div>🍃</div>
                <DefaultText
                  style={{
                    fontWeight: 700,
                    margin: '0',
                    marginLeft: Margin.m3
                  }}
                >
                  {title}
                </DefaultText>
              </TipHeaderTitle>
            </TipHeader>
          )}
          <DefaultText size="small" style={{ marginTop: Margin.m4 }}>
            {text}
          </DefaultText>
        </DivText>
      </DivContent>
    </DivTips>
  )
}
