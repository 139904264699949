import { ElementItem } from '../../../../../components/Table'
import { GetCampaignKeywordsReportingOutputType } from '../../../../../types/reporting/campaign/output'

type FormatKeywordsDataOutput = {
  keywordsTableData: ElementItem[]
  searchTermsTableData: ElementItem[]
}

export const formatKeywordsData = (
  data: GetCampaignKeywordsReportingOutputType
): FormatKeywordsDataOutput => {
  const keywordsTableData = data.keywords.map((keywordData) => {
    const { keyword, clicks, impressions } = keywordData

    return {
      id: keyword,
      keyword,
      clicks,
      impressions
    }
  })

  const searchTermsTableData = data.searchTerms.map((searchTermData) => {
    const { searchTerm, clicks, impressions } = searchTermData

    return {
      id: searchTerm,
      searchTerm,
      clicks,
      impressions
    }
  })

  return { keywordsTableData, searchTermsTableData }
}
