import React from 'react'

import { IconType } from '../../types/icon'

const AgeRangeIcon = ({
  height = '36px',
  width = '36px',
  style,
  color = '#fff'
}: IconType) => {
  return (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 491.668 491.668"
      xmlSpace="preserve"
      fill={color}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <path
              color={color}
              d="M176.053,256.645c-18.445-14.116-41.436-22.59-66.452-22.59C49.07,234.055,0,283.125,0,343.656 v67.604c0,7.923,6.418,14.34,14.34,14.34h115.659v-69.133C129.999,316.56,147.917,280.803,176.053,256.645z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              color={color}
              d="M109.601,215.617c41.3,0,74.774-33.475,74.774-74.774c0-41.301-33.474-74.775-74.774-74.775 c-41.3,0-74.774,33.474-74.774,74.775C34.827,182.143,68.301,215.617,109.601,215.617z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              color={color}
              d="M261.662,257.581c-54.607,0-98.886,44.278-98.886,98.886v54.793c0,7.923,6.418,14.34,14.34,14.34 h42.085h71.51v-58.777c0-33.202,13.988-63.148,36.29-84.442C309.573,266.993,286.742,257.581,261.662,257.581z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              color={color}
              d="M225.131,230.069c10.54,6.819,23.04,10.876,36.531,10.876c13.493,0,25.993-4.056,36.539-10.876 c18.574-12.019,30.929-32.817,30.929-56.584c0-37.267-30.201-67.468-67.468-67.468c-37.259,0-67.46,30.201-67.46,67.468 C194.202,197.252,206.558,218.05,225.131,230.069z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              color={color}
              d="M407.578,282.733c-46.445,0-84.088,37.652-84.088,84.09v44.437c0,7.923,6.418,14.34,14.34,14.34 h22.727h116.77c7.923,0,14.341-6.417,14.341-14.34v-44.437C491.668,320.385,454.016,282.733,407.578,282.733z"
            ></path>{' '}
          </g>{' '}
          <g>
            {' '}
            <path
              color={color}
              d="M371.705,255.646c9.851,7.961,22.221,12.93,35.873,12.93c13.66,0,26.032-4.969,35.883-12.93 c13.004-10.515,21.486-26.401,21.486-44.431c0-31.688-25.68-57.376-57.369-57.376c-31.682,0-57.368,25.688-57.368,57.376 C350.21,229.245,358.691,245.131,371.705,255.646z"
            ></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  )
}

export default AgeRangeIcon
