import React from 'react'

import Colors from '../../constants/colors'
import Leaf from '../Icon/leaf'
import { DivCO2, DivCO2Score, DivLeaves } from './styles'

type CO2ScoreType = {
  co2?: number
  score: number
}

const getColorForScore = (score: number): string => {
  switch (score) {
    case 0:
      return Colors.YETIC_RED
    case 1:
      return Colors.YETIC_YELLOW_LIGHT
    case 2:
      return Colors.YETIC_YELLOW_LIGHT
    case 3:
      return Colors.NEW_YETIC_GREEN
    default:
      return Colors.NEW_YETIC_GREEN
  }
}

const CO2Score = ({ co2, score }: CO2ScoreType) => {
  const leaves = []
  const color = getColorForScore(score)

  for (let i = 0; i < 3; i++) {
    const opacity = score === 0 ? 0.2 : i >= score ? 0.4 : 1

    leaves.push(
      <Leaf
        height={'25px'}
        color={color}
        style={{ opacity }}
        key={`score-${i}`}
      />
    )
  }

  return (
    <DivCO2Score>
      <DivLeaves>{leaves}</DivLeaves>
      {co2 && <DivCO2>{co2}g CO2</DivCO2>}
    </DivCO2Score>
  )
}

export default CO2Score
