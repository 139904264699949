import React, { CSSProperties } from 'react'

import {
  TabText,
  TabsContainer,
  TabsItemWithSeparator,
  TabsSeparator
} from './styles'

export type TabsProps = {
  items: string[]
  activeTabIndex: number
  handleTabClick: (index: number) => void
  containerStyle?: CSSProperties
  tabStyle?: CSSProperties
  tabColor?: string
  textColor?: string
  activeTextColor?: string
  activeTabColor?: string
  hoverTabColor?: string
  hoverTextColor?: string
  hasSeparator?: boolean
}

const Tabs = ({
  items,
  activeTabIndex,
  handleTabClick,
  containerStyle,
  tabStyle,
  tabColor,
  textColor,
  activeTextColor,
  activeTabColor,
  hoverTabColor,
  hoverTextColor,
  hasSeparator = true
}: TabsProps) => {
  return (
    <TabsContainer style={containerStyle}>
      {items.map((item, index) => (
        <TabsItemWithSeparator
          key={index}
          onClick={() => {
            handleTabClick(index)
          }}
          isActive={activeTabIndex === index}
          style={tabStyle}
          tabColor={tabColor}
          activeColor={activeTabColor}
          hoverColor={hoverTabColor}
          textColor={textColor}
          activeTextColor={activeTextColor}
          hoverTextColor={hoverTextColor}
        >
          <TabText>{item}</TabText>
          {index < items.length - 1 && hasSeparator && <TabsSeparator />}
        </TabsItemWithSeparator>
      ))}
    </TabsContainer>
  )
}

export default Tabs
