import React, { useEffect, useState } from 'react'

import Button from '../../components/Button'
import Title from '../../components/Title'
import Input from '../../components/Input'
import DefaultText from '../../components/DefaultText'
import SummaryBanner from '../../components/SummaryBanner'
import Status from '../../components/StatusDot'
import { ArrowRight, Dots } from '../../components/Icon'
import VerticalSeparator from '../../components/VerticalSeparator'
import Breadcrumb from '../../components/BreadCrumb'
import { Status as StatusEnum } from '../../types/status'
import Navigation from '../../components/Navigation'
import CheckboxLabel from '../../components/CheckBox/CheckBoxLabel'
import CheckboxList from '../../components/CheckBox/CheckBoxList'
import Checkbox from '../../components/CheckBox/CheckBoxInput'
import {
  RadioButton,
  RadioButtonLabel,
  RadioList
} from '../../components/RadioButton'
import Dropdown from '../../components/Dropdown'
import { Tags } from '../../components/Tag'
import Modal from '../../components/Modal'
import Margin from '../../constants/margin'
import { TipsEcology } from '../../components/Tips'
import CO2Score from '../../components/CO2Score'
import CO2Tag from '../../components/CO2Tag'
import LinkedinSmartphonePreview from '../../components/PreviewAds/Google/Display/LinkedinSmartphonePreview'
import LineChart from '../../components/Chart/LineChart/LineChartDrawer'
import Tabs from '../../components/Tabs'
import Colors from '../../constants/colors'
import Heatmap from '../../components/Chart/Heatmap'
import { barChartData, heatmapData, lineChartData } from './data/chart'
import BarChart from '../../components/Chart/BarChart'
import Table from '../../components/Table'
import { tableColumns, tableElements } from './data/table'
import Recommandation from '../Analytics/TabsContent/ImpactTab/Recommandation'
import Survey from '../../components/Survey'
import { SurveyContentType } from '../../components/Survey/types'
import { mockedRetailSurveyData } from '../../components/Survey/mockedData/retail'
import LeftMenu from '../../templates/LeftMenuTemplate'

const Home = () => {
  const [isActive, setIsActive] = useState(false)
  const handleToggle = () => {
    setIsActive(!isActive)
  }
  const [checkbox1Selected, setCheckbox1Selected] = useState(false)
  const [checkbox2Selected, setCheckbox2Selected] = useState(false)
  const [radioSelected, setRadioSelected] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState()
  const [keywords, setKeywords] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [surveyCurrentQuestionId, setSurveyCurrentQuestionId] = useState('')

  useEffect(() => {
    setSurveyCurrentQuestionId(mockedSurvey[0].id)
  }, [])

  const handleDropdownChange = () => {
    setSelectedOptions(selectedOptions)
  }

  const handleCheckbox1Change = () => {
    setCheckbox1Selected(!checkbox1Selected)
  }

  const handleCheckbox2Change = () => {
    setCheckbox2Selected(!checkbox2Selected)
  }
  const handleRadioChange = (index: number) => {
    if (index === radioSelected) {
      setRadioSelected(-1)
    } else {
      setRadioSelected(index)
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleChangeTabIndex = (index: number) => {
    setActiveTabIndex(index)
  }

  const cb1 = (
    <Checkbox selected={checkbox1Selected} onChange={handleCheckbox1Change}>
      <CheckboxLabel
        label="Titre"
        description={`Description de pourquoi du comment
         de savoir ce qui faut pour ete ale fatogue dis`}
      />
    </Checkbox>
  )
  const cb2 = (
    <Checkbox selected={checkbox2Selected} onChange={handleCheckbox2Change}>
      <CheckboxLabel label="Titre" />
    </Checkbox>
  )

  const rb1 = (
    <RadioButton
      checked={radioSelected === 0}
      onChange={() => {
        handleRadioChange(0)
      }}
    >
      <RadioButtonLabel
        label="Titre"
        description={`Description de pourquoi du comment de
          savoir ce qui faut pour ete ale fatogue dis`}
      />
    </RadioButton>
  )

  const rb2 = (
    <RadioButton
      checked={radioSelected === 1}
      onChange={() => {
        handleRadioChange(1)
      }}
    >
      <RadioButtonLabel label="Titre" />
    </RadioButton>
  )
  const rb3 = (
    <RadioButton
      checked={radioSelected === 2}
      onChange={() => {
        handleRadioChange(2)
      }}
    >
      <RadioButtonLabel label="Ouaaais" />
    </RadioButton>
  )

  const checkboxListValues = [
    { checkbox: cb1, selected: checkbox1Selected },
    { checkbox: cb2, selected: checkbox2Selected }
  ]
  const radioListValues = [rb1, rb2, rb3]

  const optionsDropDown = [
    { id: 1, label: 'Option 1' },
    { id: 2, label: 'Option 2' },
    { id: 3, label: 'Option 3' },
    { id: 4, label: 'Option 4' }
  ]

  const statusElement = <Status status={StatusEnum.PAUSED} />
  const statusElement2 = <Status status={StatusEnum.PAUSED} />
  const toggleElement = (
    <Button type="toggle" active={isActive} onClick={handleToggle} />
  )
  const verticalSeparator = <VerticalSeparator />

  const content = [
    {
      labels: 'Budget total',
      texts: '120000 €'
    },
    { labels: 'Budget restant', texts: '809 €' },
    {
      labels: "Nombre d'annonces",
      texts: '908 €'
    },
    {
      labels: "Nombre d'annonces",
      texts: '908303 €'
    },
    {
      labels: "Nombre d'annonces",
      texts: '908343 €'
    },
    {
      labels: "Nombre d'annonces",
      texts: '908343 €'
    },
    {
      labels: "Nombre d'annonces",
      texts: '908343 €'
    },
    {
      labels: "Nombre d'annonces",
      texts: '908343 €'
    }
  ]

  const dropdown = (
    <Dropdown
      options={optionsDropDown}
      width="300px"
      onChange={handleDropdownChange}
    />
  )

  const mockedSurvey = mockedRetailSurveyData

  const onSurveyAnswerClick = (answerId: string) => {
    setSurveyCurrentQuestionId(answerId)
  }

  return (
    <>
      <LeftMenu activeIndex={1} />
      <div style={{ padding: '20px', overflowY: 'auto', overflowX: 'hidden' }}>
        <Navigation />
        <Breadcrumb
          items={[
            { onClick: () => {}, label: 'Le Pull Français' },
            { onClick: () => {}, label: 'Saint Valentin 2023' },
            { onClick: () => {}, label: 'Google Ads' }
          ]}
        />
        <Tags labels={['abc abc', 'My new audience', 'bla bla bla bla bla']} />
        <hr />
        <Button type="secondary" width="200px">
          Button
        </Button>
        <Button width="200px">Button</Button>
        <hr />
        <Title>Title</Title>
        <Title type="subTitle">Title</Title>
        <Title type="thin">Title</Title>
        <DefaultText>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non adipisci
          doloremque laborum hic nobis reiciendis corrupti itaque vel? Quidem
          molestias repellendus possimus blanditiis fugiat rem odio culpa nisi
          quaerat amet.
        </DefaultText>
        <hr />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Input
            label="Nom d'utilisateur"
            width="300px"
            type="text"
            placeholder="Input Text"
          />
          <Input width="300px" type="text" placeholder="Input Text" />
          <Input
            width="300px"
            type="number"
            placeholder="Input Text"
            label="T'as quel âge mec ?"
            min={0}
            max={100}
          />
        </div>
        <Input
          width="300px"
          type="range"
          min={0}
          max={10}
          step={1}
          defaultValue={10}
        />
        <Input width="300px" type="date" />
        <Input width="300px" type="time" />
        <Input
          width="300px"
          type="keyword"
          placeholder="Entrez des mots clés"
          keywords={keywords}
          setKeywords={setKeywords}
        />
        <hr />
        <Dropdown
          options={optionsDropDown}
          onChange={handleDropdownChange}
          width="300px"
        />

        <hr />
        <div>
          <CheckboxList values={checkboxListValues} hasBorder />
          <hr />

          <RadioList values={radioListValues} selected={radioSelected} />
        </div>
        <hr />
        <SummaryBanner color="white" contents={content} />

        <hr />
        <Button
          type="toggle"
          active={isActive}
          onClick={handleToggle}
          style={{ marginRight: '30px' }}
        />

        <CO2Tag
          positive
          wording="CO2 - Meilleur Choix"
          tooltip
          modalText="Voici un exemple de texte dans la tooltip CO2"
        />
        <CO2Tag
          positive={false}
          wording="CO2 - Pire Choix"
          tooltip
          modalText="Voici un exemple de texte dans la tooltip CO2"
        />

        <Dots />
        <ArrowRight />

        <CO2Score co2={1} score={0} />
        <CO2Score co2={0.5} score={1} />
        <CO2Score co2={0.25} score={2} />
        <CO2Score co2={0} score={3} />

        <hr />

        <TipsEcology text="OUi je suis une tips"></TipsEcology>

        <LinkedinSmartphonePreview
          companyName="YETIC"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum. schsudcs zduadzuzgad uigzqd ygazdu fehjzq fez efoizehf ioezffhief uzeh d zdgz dzyd zgdyzedg yzegdzged zygef yzgefd zegfzefg ezygf u"
          // image="https://media.licdn.com/dms/image/D4E03AQE_HyomMBQGmw/profile-displayphoto-shrink_200_200/0/1683297981359?e=2147483647&v=beta&t=ClMIUGh7XBI_6Qo2sthhGd15xcG5SBszS_TUaTlAC84"
          image="https://s1.1zoom.me/prev/378/377265.jpg"
          headline="Lorem ipsum dolor sit"
          url="https://www.google.com/"
          cta="Demander une démo"
        />

        <div style={{ width: '600px', margin: '15px 0' }}>
          <Tabs
            items={[
              "Vue d'ensemble",
              'Recommandations',
              'Clic / impression / coût',
              'Appareils'
            ]}
            activeTabIndex={activeTabIndex}
            handleTabClick={handleChangeTabIndex}
            hasSeparator={false}
            containerStyle={{
              justifyContent: 'flex-start',
              gap: '10px'
            }}
            tabStyle={{
              border: `1px solid ${Colors.NEW_YETIC_BLUE_DARK}`,
              borderRadius: '50px',
              fontSize: '12px',
              width: 'fit-content',
              padding: '10px 20px',
              whiteSpace: 'nowrap',
              transition: 'all 0.2s ease-in-out',
              flex: 0
            }}
            textColor={Colors.NEW_YETIC_BLUE_DARK}
            activeTabColor={Colors.NEW_YETIC_BLUE_DARK}
            activeTextColor={Colors.YETIC_WHITE}
            hoverTabColor={Colors.NEW_YETIC_BLUE_DARK}
            hoverTextColor={Colors.YETIC_WHITE}
          />
        </div>

        {/* <LineChart data={lineChartData} /> */}

        <Heatmap
          title="Clics et impressions par âge et sexe"
          data={heatmapData}
          displayedIntensityIndex={heatmapData
            .map((dataset) => dataset.name)
            .indexOf('Clics')}
        />

        <BarChart title="Démographie" data={barChartData} />

        <div style={{ width: '70%', marginTop: '20px' }}>
          <Table
            pagination={{
              totalItems: 6,
              itemsByPage: 3,
              currentPage,
              handleChangePage: (page: number) => {
                setCurrentPage(page)
              }
            }}
            columns={tableColumns}
            elements={tableElements}
          />
        </div>
        <Recommandation />

        <Button onClick={handleOpenModal} style={{ width: 'fit-content' }}>
          Ouvrir modale Oui/non
        </Button>
        {isModalOpen && (
          <Modal
            onClose={handleCloseModal}
            style={{ width: '91%', height: '91%' }}
          >
            <Survey
              content={mockedSurvey}
              currentQuestionId={surveyCurrentQuestionId}
              updateCurrentQuestionId={setSurveyCurrentQuestionId}
              onAnswerClick={onSurveyAnswerClick}
              onClose={() => {
                handleCloseModal()
                setSurveyCurrentQuestionId(mockedSurvey[0].id)
              }}
            />
          </Modal>
        )}
      </div>
    </>
  )
}

export default Home
