import { ElementItem } from '../../../components/Table'

export const tableColumns = [
  {
    name: 'name',
    label: 'Nom',
    bold: true,
    width: '50%',
    isSortable: true
  },
  {
    name: 'age',
    label: 'Age',
    width: '20%',
    isSortable: true
  },
  {
    name: 'city',
    label: 'Ville',
    width: '25%',
    isSortable: true
  }
]

export const tableElements: ElementItem[] = [
  {
    id: '1',
    name: 'Melyssa',
    age: 25,
    city: 'Paris',
    onClick: () => {
      console.log('click', '1')
    }
  },
  {
    id: '2',
    name: 'Isabelle',
    age: 38,
    city: 'Rouen'
  },
  {
    id: '3',
    name: 'Joseph',
    age: 28,
    city: 'Lyon'
  },
  {
    id: '4',
    name: 'Marie',
    age: 18,
    city: 'Londres'
  },
  {
    id: '5',
    name: 'Jean',
    age: 22,
    city: 'Brest',
    actions: [
      {
        label: 'Modifier',
        handleAction: () => {
          console.log('click', '5')
        }
      },
      {
        label: 'Supprimer',
        handleAction: () => {
          console.log('click', '5')
        }
      }
    ]
  },
  {
    id: '6',
    name: 'Jules',
    age: 12,
    city: 'Toulouse'
  }
]
