import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Breadcrumb from '../../components/BreadCrumb'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import { FlowEnum } from '../../types/flow'
import { PlatformAPIEnum } from '../../types/platform'
import { PanelType } from '../../types/templates/panels'
import { AddToPanelsParams, TextAdEditType } from './types/index'

type SelfEditorAddToPanelsParams = AddToPanelsParams & {
  titleTooltip?: string
  tip?: { title: string; content: string }
}

const SelfEditorTextAdEdit = ({
  textAd,
  adId,
  adPreview,
  bottomErrors,
  nameSection,
  urlSection,
  titlesSection,
  descriptionsSection,
  flow,
  isSubmitLoading,
  handleSubmitTextAd
}: TextAdEditType) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)

  const navigate = useNavigate()

  const breadcrumbItems = [
    ...(flow === FlowEnum.view
      ? [
          {
            label: 'Accueil',
            onClick: () => {
              navigate(`/`)
            }
          }
        ]
      : []),
    {
      label: 'Plan publicitaire'
    },
    {
      label: textAd ? textAd.navigation.mediaPlan.name : '',
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(
              `/media-plan/${
                textAd ? textAd.navigation.mediaPlan.id : ''
              }/review`
            )
          : navigate(
              `/media-plan/${
                textAd ? textAd.navigation.mediaPlan.id : ''
              }/details`
            )
      }
    },
    {
      label: `Campagne « ${textAd ? textAd.navigation.campaign.name : ''} »`,
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(
              `/campaign/${textAd ? textAd.navigation.campaign.id : ''}/review`
            )
          : navigate(
              `/campaign/${textAd ? textAd.navigation.campaign.id : ''}/details`
            )
      }
    },
    {
      label: `Publicité « ${textAd ? textAd.name : ''} »`,
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(
              `/ad/${adId ?? ''}/review?platform=${PlatformAPIEnum.GOOGLE}`
            )
          : navigate(`/ad/${adId ?? ''}/text/details`)
      }
    },
    {
      label: 'Édition'
    }
  ]

  const panels: PanelType[] = []
  const addToPanels = ({
    title,
    sections,
    isDropdown = false,
    disabled = true,
    titleTooltip,
    tip
  }: SelfEditorAddToPanelsParams) => {
    panels.push({
      title,
      sections,
      isDropdown,
      disabled,
      titleTooltip,
      tip
    })
  }

  addToPanels({ title: 'Nom', sections: [nameSection] })
  addToPanels({ title: 'URL', sections: [urlSection] })
  addToPanels({
    title: 'Titres',
    sections: [titlesSection],
    disabled: false,
    tip: {
      title: 'Titres',
      content: `Pour de meilleures performances, utiliser certains de vos mots-clé dans vos titres`
    }
  })
  addToPanels({
    title: 'Descriptions',
    sections: [descriptionsSection],
    disabled: false,
    tip: {
      title: 'Descriptions',
      content: `Ajoutez des descriptions détaillant votre offre`
    }
  })

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Édition de la publicité « ${textAd ? textAd.name : ''} »`}
        subTitle="Mise à jour des caractéristiques de votre publicité"
        preview={adPreview}
        panels={panels}
        errors={{
          errors: hasBeenSubmitted
            ? Object.values(bottomErrors).filter((e) => e !== '')
            : []
        }}
        validation={{
          wording: 'Valider les modifications',
          isLoading: isSubmitLoading,
          action: () => {
            if (Object.values(bottomErrors).filter((e) => e).length < 1) {
              handleSubmitTextAd()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled:
            hasBeenSubmitted &&
            Object.values(bottomErrors).filter((e) => e).length > 0
        }}
        cancel={{
          wording: 'Retour',
          action: () => {
            navigate(
              `/campaign/${textAd ? textAd.navigation.campaign.id : ''}/review`
            )
          },
          disabled: false
        }}
      />
    </>
  )
}

export default SelfEditorTextAdEdit
