import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getCampaignReview } from '../../helpers/queries/campaign/getCampaignReview'
import {
  CampaignReviewPageDataType,
  checkIfGoogleCampaignReviewPageDataType,
  checkIfLinkedinCampaignReviewPageDataType,
  checkIfGoogleImageCampaignReviewPageDataType,
  checkIfLinkedinImageCampaignReviewPageDataType
} from '../../types/pages/campaignReview'
import LoadingPage from '../LoadingPage'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { FlowEnum } from '../../types/flow'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import CO2Modal from '../../components/Modal/CO2Modal'
import { GetCampaignCO2DetailsOutputType } from '../../helpers/queries/campaign/getCampaignCO2Details'
import getImageById from '../../helpers/queries/ad/getImageById'
import { formatReviewData } from './helpers/formatReviewData'
import { linkedinDevices } from '../../constants/linkedin/linkedinDevices'
import { checkIfGoogleImageAd } from '../../types/ad/google/imageAd'
import EditorCampaignReview from './editor'
import SelfEditorCampaignReview from './selfEditor'
import AdvertiserCampaignReview from './advertiser'
import {
  checkIfValidateCampaign,
  validateCampaign
} from '../../helpers/queries/campaign/validateCampaign'

const CampaignReview = () => {
  const [reviewData, setReviewData] =
    useState<CampaignReviewPageDataType | null>(null)
  const [images, setImages] = useState<Record<string, string>>({})
  const [hasFetchError, setHasFetchError] = useState(false)
  const [co2Details, setCo2Details] =
    useState<GetCampaignCO2DetailsOutputType | null>(null)
  const [isCO2ModalOpen, setIsCO2ModalOpen] = useState(false)
  const [isValidateCampaignLoading, setIsValidateCampaignLoading] =
    useState(false)

  const navigate = useNavigate()

  const { campaignId } = useParams()
  const { isEditor, isSelfEditor } = useContext(AppContext)

  const params = new URLSearchParams(location.search)
  const flow = params.get('flow') as FlowEnum | null

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignReview({
          campaignId
        })
        if (result) {
          const formattedReviewData = formatReviewData(result)

          setReviewData(formattedReviewData)
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }, [campaignId])

  useEffect(() => {
    if (reviewData) {
      const isImageCampaignReviewDataType =
        checkIfGoogleImageCampaignReviewPageDataType(reviewData) ||
        checkIfLinkedinImageCampaignReviewPageDataType(reviewData)
      if (isImageCampaignReviewDataType && Object.keys(images).length < 1) {
        ;(async function () {
          const imageResults: Record<string, string> = {}
          for (let i = 0; i < reviewData.ads.length; i++) {
            const imageAd = reviewData.ads[i]
            const imageId = checkIfGoogleImageAd(imageAd)
              ? imageAd.marketingImage1
              : imageAd.imageId

            const imageResult = await getImageById(imageId)
            if (imageResult) {
              imageResults[imageAd.id] = imageResult
            }
          }

          setImages(imageResults)
        })()
      } else if (
        !isImageCampaignReviewDataType &&
        Object.keys(images).length > 0
      ) {
        setImages({})
      }
    }
  }, [reviewData])

  useEffect(() => {
    if (campaignId && isValidateCampaignLoading) {
      ;(async function () {
        const result = await validateCampaign({
          campaignId
        })
        if (checkIfValidateCampaign(result)) {
          navigate(`/campaign/${result.campaignId}/details`)
        }
      })()
    }
  }, [isValidateCampaignLoading])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!campaignId || !reviewData) {
    return <LoadingPage />
  }

  const campaignDevices = checkIfGoogleCampaignReviewPageDataType(reviewData)
    ? reviewData.campaign.devices
    : checkIfLinkedinCampaignReviewPageDataType(reviewData)
    ? linkedinDevices
    : linkedinDevices

  const startDateCampaign = new Date(
    reviewData.campaign.period.start
  ).toLocaleDateString()
  const endDateCampaign = new Date(
    reviewData.campaign.period.end
  ).toLocaleDateString()

  const handleViewMore = (id: string) => {
    navigate(`/ad/${id}/review?platform=${reviewData.campaign.platform}`)
  }

  const handleValidateCampaign = () => {
    setIsValidateCampaignLoading(true)
  }

  const adCount = reviewData.ads.length

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      {isEditor ? (
        <EditorCampaignReview
          reviewData={reviewData}
          campaignId={campaignId}
          startDateCampaign={startDateCampaign}
          endDateCampaign={endDateCampaign}
          images={images}
          flow={flow}
          setIsCO2ModalOpen={setIsCO2ModalOpen}
          navigate={navigate}
          handleViewMore={handleViewMore}
        />
      ) : isSelfEditor ? (
        <SelfEditorCampaignReview
          reviewData={reviewData}
          campaignId={campaignId}
          startDateCampaign={startDateCampaign}
          endDateCampaign={endDateCampaign}
          images={images}
          flow={flow}
          setIsCO2ModalOpen={setIsCO2ModalOpen}
          navigate={navigate}
          handleViewMore={handleViewMore}
          isValidateCampaignLoading={isValidateCampaignLoading}
          handleValidateCampaign={handleValidateCampaign}
        />
      ) : (
        <AdvertiserCampaignReview
          reviewData={reviewData}
          startDateCampaign={startDateCampaign}
          endDateCampaign={endDateCampaign}
          images={images}
          setIsCO2ModalOpen={setIsCO2ModalOpen}
          navigate={navigate}
          handleViewMore={handleViewMore}
        />
      )}
      {isCO2ModalOpen && (
        <CO2Modal
          campaignId={campaignId}
          format={reviewData.campaign.format}
          onClose={() => {
            setIsCO2ModalOpen(false)
          }}
          campaignDevices={campaignDevices}
          adCount={adCount}
        />
      )}
    </AuthenticatedTemplate>
  )
}

export default CampaignReview
