export enum BidStrategyEnum {
  CALL_CONVERSION = 'Conversion par appel téléphonique',
  CPC = 'Coût Par Clic (CPC)',
  CPM = 'Coût Par 1000 Impressions (CPM)'
}

export enum BidStrategyAPIEnum {
  CALL_CONVERSION = 'call_conversion',
  CPC = 'CPC',
  CPM = 'CPM'
}

export const getBidStrategyAPIEnum = (
  value: BidStrategyEnum
): BidStrategyAPIEnum => {
  switch (value) {
    case BidStrategyEnum.CALL_CONVERSION:
      return BidStrategyAPIEnum.CALL_CONVERSION
    case BidStrategyEnum.CPC:
      return BidStrategyAPIEnum.CPC
    case BidStrategyEnum.CPM:
      return BidStrategyAPIEnum.CPM
    default:
      return BidStrategyAPIEnum.CPC
  }
}

export const getBidStrategyEnum = (
  value: BidStrategyAPIEnum
): BidStrategyEnum => {
  switch (value) {
    case BidStrategyAPIEnum.CALL_CONVERSION:
      return BidStrategyEnum.CALL_CONVERSION
    case BidStrategyAPIEnum.CPC:
      return BidStrategyEnum.CPC
    case BidStrategyAPIEnum.CPM:
      return BidStrategyEnum.CPM
    default:
      return BidStrategyEnum.CPC
  }
}

export const checkIfBidStrategyEnum = (
  value: any
): value is BidStrategyEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(BidStrategyEnum) as string[]).includes(value)
  )
}

export const checkIfBidStrategyApiEnum = (
  value: any
): value is BidStrategyAPIEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(BidStrategyAPIEnum) as string[]).includes(value)
  )
}
