import styled from '@emotion/styled'

import { chartGap } from '../../../styles'

export const DevicesTabContainer = styled.div`
  width: 100%;
  gap: ${chartGap}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
