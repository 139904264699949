export enum PlatformEnum {
  GOOGLE = 'Google',
  LINKEDIN = 'LinkedIn'
  // META = 'Meta',
}

export enum PlatformAPIEnum {
  GOOGLE = 'google',
  LINKEDIN = 'linkedin'
  // META = 'meta'
}

export type PlatformBoolean = {
  google: boolean
  linkedin: boolean
  // meta: boolean
}

export const getPlatformAPIEnum = (value: PlatformEnum): PlatformAPIEnum => {
  switch (value) {
    case PlatformEnum.GOOGLE:
      return PlatformAPIEnum.GOOGLE
    case PlatformEnum.LINKEDIN:
      return PlatformAPIEnum.LINKEDIN
    // case PlatformEnum.META:
    //   return PlatformAPIEnum.META
    default:
      return PlatformAPIEnum.GOOGLE
  }
}

export const getPlatformEnum = (value: PlatformAPIEnum): PlatformEnum => {
  switch (value) {
    case PlatformAPIEnum.GOOGLE:
      return PlatformEnum.GOOGLE
    case PlatformAPIEnum.LINKEDIN:
      return PlatformEnum.LINKEDIN
    // case PlatformAPIEnum.META:
    //   return PlatformEnum.META
    default:
      return PlatformEnum.GOOGLE
  }
}

export const checkIfPlatformEnum = (value: any): value is PlatformEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(PlatformEnum) as string[]).includes(value)
  )
}

export const checkIfPlatformApiEnum = (
  value: any
): value is PlatformAPIEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(PlatformAPIEnum) as string[]).includes(value)
  )
}

export const checkIfPlatformBoolean = (
  value: any
): value is PlatformBoolean => {
  return (
    value &&
    value.google !== undefined &&
    typeof value.google === 'boolean' &&
    value.linkedin !== undefined &&
    typeof value.linkedin === 'boolean'
    // value.meta !== undefined &&
    // typeof value.meta === 'boolean'
  )
}
