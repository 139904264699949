import React from 'react'

import HomePicto from '../../../../Icon/homePicto'
import YoutubeBell from '../../../../Icon/youtubeBell'
import YoutubeFlame from '../../../../Icon/youtubeFlame'
import YoutubeFolder from '../../../../Icon/youtubeFolder'
import YoutubeLogo from '../../../../Icon/youtubeLogo'
import YoutubeMedia from '../../../../Icon/youtubeMedia'
import {
  AdButton,
  AdCompany,
  AdContainer,
  AdContent,
  AdDescription,
  AdIcon,
  AdImage,
  AdLink,
  AdTitle,
  AdVideo,
  Channel,
  ChannelName,
  CompanyName,
  OptionBtn,
  ProfileIcon,
  Tab,
  TabBar,
  YoutubeHeader,
  YoutubeWindow
} from './styles'
import Colors from '../../../../../constants/colors'

type YoutubePreviewProps = {
  title: string
  description: string
  businessName: string
  adImageRectangle: string
}

const YoutubePreview = ({
  title,
  description,
  businessName,
  adImageRectangle
}: YoutubePreviewProps) => {
  return (
    <YoutubeWindow>
      <YoutubeHeader>
        <YoutubeLogo width="80px" />
        <ProfileIcon small />
      </YoutubeHeader>
      <AdContent>
        <Channel>
          <ProfileIcon />
          <ChannelName />
          <OptionBtn />
        </Channel>
        <AdContainer>
          <AdImage src={adImageRectangle} alt="image paysage" loading="lazy" />
          <AdTitle>{title}</AdTitle>
          <AdDescription>{description}</AdDescription>
          <AdLink>
            <AdCompany>
              <CompanyName>{businessName}</CompanyName>
              <AdIcon>Annonce</AdIcon>
            </AdCompany>
            <AdButton>Ouvrir</AdButton>
          </AdLink>
        </AdContainer>
        <AdVideo />
        <TabBar>
          <Tab color={Colors.YOUTUBE_RED}>
            <HomePicto width="20px" height="auto" color={Colors.YOUTUBE_RED} />
          </Tab>
          <Tab>
            <YoutubeFlame width="20px" height="auto" />
          </Tab>
          <Tab>
            <YoutubeMedia width="22px" height="auto" />
          </Tab>
          <Tab>
            <YoutubeBell width="22px" height="auto" />
          </Tab>
          <Tab>
            <YoutubeFolder width="22px" height="auto" />
          </Tab>
        </TabBar>
      </AdContent>
    </YoutubeWindow>
  )
}
export default YoutubePreview
