import styled from '@emotion/styled'

import { chartGap } from '../../../styles'

export const CampaignsTabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${chartGap}px;
`
