import React from 'react'
import {
  CardContainer,
  CardContent,
  CardHeader,
  CardSeeMore,
  CardTitle,
  SyncStateWithErrorContainer,
  syncErrorIconStyle
} from './styles'
import DefaultText, { DefaultTextSize } from '../DefaultText'
import SyncState from '../SyncState'
import { CustomTooltip } from '../CustomToolTip'

type CardProps = {
  headerColor?: string
  titleIcon?: JSX.Element
  title: string
  titleStyle?: React.CSSProperties
  titleSize?: DefaultTextSize
  width?: string
  children?: React.ReactNode
  seeMore?: () => void
  isSynced?: boolean
  onSyncClick?: () => Promise<boolean>
  syncError?: string
}

const Card = ({
  headerColor,
  titleIcon,
  title,
  titleStyle,
  titleSize,
  width,
  children,
  seeMore,
  isSynced,
  onSyncClick,
  syncError
}: CardProps) => {
  const syncErrorText = syncError
    ? `${syncError}. Veuillez réessayer.`
    : 'Synchroniser votre campagne pour pouvoir la publier sur la plateforme'

  return (
    <CardContainer width={width}>
      <CardHeader background={headerColor}>
        <CardTitle>
          {titleIcon}
          <DefaultText
            size={titleSize}
            style={{ marginBottom: 0, ...titleStyle }}
          >
            {title}
          </DefaultText>
        </CardTitle>
        {seeMore && <CardSeeMore onClick={seeMore}>Afficher</CardSeeMore>}
        {isSynced !== undefined && onSyncClick && !isSynced && (
          <SyncStateWithErrorContainer>
            <CustomTooltip
              content={syncErrorText}
              iconStyle={syncErrorIconStyle}
              hover
            />
            <SyncState isSynced={isSynced} onSyncClick={onSyncClick} />
          </SyncStateWithErrorContainer>
        )}
        {onSyncClick && !!isSynced && (
          <SyncState isSynced={isSynced} onSyncClick={onSyncClick} />
        )}
      </CardHeader>
      <CardContent>{children}</CardContent>
    </CardContainer>
  )
}

export default Card
