import styled from '@emotion/styled'

import Colors from '../../../../../constants/colors'
import Margin from '../../../../../constants/margin'
import Padding from '../../../../../constants/padding'

export const BarChartTooltipContainer = styled.div`
  position: absolute;
  background-color: ${Colors.YETIC_WHITE};
  border-radius: 10px;
  padding: ${Padding.p6} ${Padding.p5};
  box-shadow: 0px 0px 5px #142a4c80;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m2};
  min-width: 80px;
`

export const BarChartTooltipTitle = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`

export const BarChartTooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Margin.m7};
`

export const BarChartTooltipItemValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  text-align: center;
`
