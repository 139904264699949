import React from 'react'

import { ImageAdCreationFormDataType } from '../types'
import { ImageAdCreationErrorsType } from '../../../helpers/imageAd/formErrors'
import { PanelType } from '../../../types/templates/panels'
import { TextInputsContainer } from '../styles'
import Input from '../../../components/Input'

export type GetNamePanelInput = {
  formData: ImageAdCreationFormDataType
  errors: ImageAdCreationErrorsType
  hasBeenSubmitted: boolean
}

export const getNamePanel = ({
  formData,
  errors,
  hasBeenSubmitted
}: GetNamePanelInput): PanelType => {
  return {
    title: 'Noms',
    disabled: true,
    isDropdown: false,
    isShown: true,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="text"
              value={formData.name}
              isRequired={false}
              placeholder="Nom de mon annonce"
              maxLength={20}
              label={`Nom de l'annonce`}
              error={hasBeenSubmitted ? errors.name : ''}
              withBorder
              disabled
            />
            <Input
              type="text"
              value={formData.businessName}
              isRequired={false}
              placeholder="Nom de l'entreprise"
              maxLength={20}
              label={`Nom de l'entreprise`}
              error={hasBeenSubmitted ? errors.businessName : ''}
              withBorder
              disabled
            />
          </TextInputsContainer>
        )
      }
    ]
  }
}
