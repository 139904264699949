import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../../../constants/padding'
import Colors from '../../../../constants/colors'
import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'

export const listTitleStyle: CSSProperties = {
  marginTop: Margin.m8,
  marginBottom: Margin.m6,
  fontWeight: 700,
  fontSize: FontSize.YETIC_TITLE_THIN
}

export const DivDetails = styled.div`
  padding: 0 ${Padding.p4} ${Padding.p4};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Column2 = styled(Column1)`
  flex: 1;
`

export const DivColumns = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Margin.m4};
`

export const btnStyle: CSSProperties = {
  opacity: 0.6,
  width: 'fit-content'
}

export const cardTitleStyle: CSSProperties = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  color: Colors.YETIC_WHITE,
  fontWeight: 700
}

export const adCountWarningStyle: CSSProperties = {
  color: Colors.NEW_YETIC_WARNING,
  textAlign: 'center'
}
