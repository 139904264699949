import React from 'react'

import { IconType } from '../../types/icon'

const MegaphonePicto = ({ height = '65px', width = '65px' }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 369.356 366.454"
    >
      <g transform="matrix(0.966, -0.259, 0.259, 0.966, -10.434, 80.267)">
        <path
          d="M31.445,63.263c0-24.888,12.742-46.795,32.414-58.792A2.41,2.41,0,0,0,62.592,0h0C34.128,0,11.55,22.652,7.377,52.011,3.2,52.682,0,57.45,0,63.114c0,5.961,3.5,10.656,8.048,11.028,4.992,25.186,25.186,41.281,45.6,48.062h0a3.249,3.249,0,0,0,2.832-5.589C41.877,103.426,31.445,83.605,31.445,63.263Z"
          transform="translate(0 40.312)"
          fill="#142a4c"
        />
        <path
          d="M248.654,40.834c-.745-2.012-1.565-3.875-2.384-5.738C241.8,25.111,236.285,17.064,230.1,11.1,222.276,3.875,214.154,0,204.169,0a34.064,34.064,0,0,0-19.225,5.812c-32.116,19.3-83.68,34.277-114.752,34.277s-29.359.149-29.359.149A69.63,69.63,0,0,0,0,103.575c0,25.484,13.264,47.838,33.084,59.611,9.016,7.526,17.585,6.557,15.723,16.542S32.041,252.38,30.178,265.42s-2.161,20.491,2.981,26.825c5.216,6.334,36.885,17.734,50.67,17.734s10.283-6.483,10.283-32.637-6.93-98.135,12.295-98.135c35.99,0,54.768,12.891,82.934,23.621,6.706,2.533,10.507,3.5,14.977,3.5s13.934-1.416,25.856-13.562a77.215,77.215,0,0,0,16.17-23.994c.82-1.937,1.639-3.875,2.459-5.961,6.632-17.287,10.208-38.673,10.208-61.027C258.937,79.581,255.36,58.121,248.654,40.834ZM228.833,157.821c-4.769,11.7-13.115,21.013-20.044,21.013s-13.264-9.314-18.032-21.013c-5.589-13.636-9.165-33.531-9.165-55.662,0-22.205,3.577-42.175,9.165-55.811,4.769-11.624,11.1-18.629,17.958-18.629,6.93,0,15.126,7.079,19.97,18.629a150.127,150.127,0,0,1,.149,111.473Z"
          transform="translate(51.117 0)"
          fill="#ffcf00"
        />
      </g>
    </svg>
  )
}

export default MegaphonePicto
