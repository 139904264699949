import { backendURL } from '../../../constants/urls'
import {
  BidStrategyEnum,
  getBidStrategyAPIEnum
} from '../../../types/bidStrategy'
import { FormatEnum, getFormatAPIEnum } from '../../../types/format'
import { GoalsEnum } from '../../../types/goals'
import { PlatformEnum, getPlatformAPIEnum } from '../../../types/platform'
import { axiosPost, checkIsAxiosPostOutputSuccess } from '../axios/post'

export type CreateCampaignInputType = {
  name: string
  platform: PlatformEnum
  format: FormatEnum
  goal: GoalsEnum
  budget: number
  isBudgetPerDay: boolean
  lang: string
  startDate?: Date
  endDate?: Date
  os?: {
    desktop: boolean
    android: boolean
    ios: boolean
  }
  devices: {
    desktop: boolean
    smartphone: boolean
    tablet: boolean
    tv: boolean
  }
  bidStrategy: BidStrategyEnum
  mediaPlanId: string
  audience: { id: string; name: string }
  keywords: string[]
}

export const createCampaign = async ({
  name,
  platform,
  format,
  goal,
  budget,
  isBudgetPerDay,
  lang,
  startDate,
  endDate,
  os,
  devices,
  bidStrategy,
  mediaPlanId,
  audience,
  keywords
}: CreateCampaignInputType) => {
  const response = await axiosPost({
    url: `${backendURL}/campaigns`,
    data: {
      name,
      platform: getPlatformAPIEnum(platform),
      format: getFormatAPIEnum(format),
      goal,
      budget,
      isBudgetPerDay,
      lang,
      startDate,
      endDate,
      os,
      devices,
      bidStrategy: getBidStrategyAPIEnum(bidStrategy),
      mediaPlanId,
      audience,
      keywords
    }
  })
  if (checkIsAxiosPostOutputSuccess(response)) {
    if (response.data.campaignId) {
      return {
        campaignId: response.data.campaignId
      }
    } else {
      return {
        campaignId: '',
        error: 'UNKNOWN'
      }
    }
  } else {
    return {
      campaignId: '',
      error: (response.error as any).data.code
    }
  }
}
