import { isSameDay, isSameMonth, isSameWeek } from './dates'
import { makeAverage, makeSum } from './calculation'
import {
  LineChartAggregatorEnum,
  RangeSelectionIdEnum
} from '../../../../types/lineChart'
import { Point } from '../LineChartDrawer'
import {
  AggregatedPointBuilder,
  AggregationFunctionType,
  DateComparatorType
} from '../types'

const calculateAggregation = <PointType extends Point>(
  currentPointsToAggregate: PointType[],
  aggregationFunction: AggregationFunctionType,
  buildAggregatedPoint: AggregatedPointBuilder<PointType>
) => {
  const valuesToAggregate: number[] = currentPointsToAggregate.map(
    (point) => point.value
  )

  const aggregatedValue = aggregationFunction(valuesToAggregate)

  const aggregation = buildAggregatedPoint(
    currentPointsToAggregate,
    aggregatedValue
  )

  return aggregation
}

const getDateComparator = (range: RangeSelectionIdEnum) => {
  switch (range) {
    case RangeSelectionIdEnum.WEEK:
      return isSameWeek
    case RangeSelectionIdEnum.MONTH:
      return isSameMonth
    case RangeSelectionIdEnum.DAY:
      return isSameDay
  }
}

const getAggregationFunction = (aggregationMethod: LineChartAggregatorEnum) => {
  switch (aggregationMethod) {
    case LineChartAggregatorEnum.SUM:
      return makeSum
    case LineChartAggregatorEnum.AVERAGE:
      return makeAverage
  }
}

const aggregator = <PointType extends Point>(
  range: RangeSelectionIdEnum,
  initialPoints: PointType[],
  aggregationMethod: LineChartAggregatorEnum,
  buildAggregatedPoint: AggregatedPointBuilder<PointType>
) => {
  const dateComparator: DateComparatorType = getDateComparator(range)

  const aggregationFunction: AggregationFunctionType =
    getAggregationFunction(aggregationMethod)

  const finalPoints: PointType[] = []
  let previousPoint: PointType | null = null
  let currentPoint: PointType | null = null
  let currentPointsToAggregate: PointType[] = []

  for (let i = 0; i < initialPoints.length; i++) {
    previousPoint = currentPoint
    currentPoint = initialPoints[i]

    if (
      i === 0 ||
      (currentPoint &&
        previousPoint &&
        dateComparator(
          new Date(currentPoint.time),
          new Date(previousPoint.time)
        ))
    ) {
      currentPointsToAggregate.push(currentPoint)
    } else {
      const aggregation = calculateAggregation(
        currentPointsToAggregate,
        aggregationFunction,
        buildAggregatedPoint
      )

      currentPointsToAggregate = [currentPoint]

      finalPoints.push(aggregation)
    }
  }

  const aggregation = calculateAggregation(
    currentPointsToAggregate,
    aggregationFunction,
    buildAggregatedPoint
  )

  finalPoints.push(aggregation)

  return finalPoints
}

export default aggregator
