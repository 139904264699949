import React, { CSSProperties, useEffect, useRef } from 'react'

import { HeatmapContainer, HeatmapTitle } from './styles'
import HeatmapTooltip from './Tooltip'
import { drawChart } from './helpers/drawChart'
import DefaultText from '../../DefaultText'

export type Point = {
  x: string
  values: { y: string; value: number }[]
}

export type HeatmapData<CategoryType> = {
  name: string
  categories: CategoryType[]
  unit?: string
}[]

export type FormattedPoint = {
  datasetIndex: number
  index: number
  x: string
  values: { y: string; value: number }[]
}

type HeatmapProps = {
  data: HeatmapData<Point>
  displayedIntensityIndex: number
  title?: string
  color?: string
  width?: number
  style?: CSSProperties
}

const Heatmap = ({
  data,
  displayedIntensityIndex,
  title,
  color = '#3f82f3',
  width = 1000,
  style
}: HeatmapProps) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    drawChart({
      chartId,
      chartRef,
      data: formattedData,
      width,
      displayedIntensityIndex,
      color
    })
  }, [data])

  const chartId = `id${Math.random().toString(36).substring(2)}`

  const formattedData = data.map((dataset, datasetIndex) => ({
    name: dataset.name,
    unit: dataset.unit,
    categories: dataset.categories.map((point, index) => ({
      ...point,
      datasetIndex,
      index
    }))
  }))

  if (data.length === 0) {
    return null
  }

  return (
    <HeatmapContainer ref={chartRef} width={width} style={style}>
      {title && (
        <HeatmapTitle>
          <DefaultText size="big" bold>
            {title}
          </DefaultText>
        </HeatmapTitle>
      )}
      <HeatmapTooltip chartId={chartId} data={formattedData} />
    </HeatmapContainer>
  )
}

export default Heatmap
