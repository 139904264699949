import React from 'react'

import { Wrapper } from './styles'
import { WidgetPageType } from '../../../../../types/widget'
import ContentDropdown from '../../../../../components/ContentDropdown'
import DefaultText from '../../../../../components/DefaultText'

type PropsType = {
  currentPageData: WidgetPageType
}

const WidgetFaq = ({ currentPageData }: PropsType) => {
  let faqContent: JSX.Element[] = []

  if (currentPageData) {
    faqContent = currentPageData.page.elements.faq.map((faq) => {
      return (
        <ContentDropdown
          key={faq.question}
          title={faq.question}
          content={[
            <DefaultText size="small" key={faq.question}>
              {faq.answer}
            </DefaultText>
          ]}
        />
      )
    })
  }

  return (
    <Wrapper>
      <DefaultText
        size="big"
        style={{ marginBottom: '20px', textAlign: 'center' }}
        bold
      >
        Questions fréquentes
      </DefaultText>
      {faqContent}
    </Wrapper>
  )
}

export default WidgetFaq
