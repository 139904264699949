import { backendURL } from '../../../constants/urls'
import {
  ValidateMediaPlanErrorEnum,
  ValidateMediaPlanErrorTranslation,
  checkIfValidateMediaPlanErrorCodeValid
} from '../../../types/error/mediaPlan/validateMediaPlan'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'

// TODO : manage error
export type ValidateMediaPlanOutputType = {
  mediaPlanId: string
}

export type ValidateMediaPlanErrorType = {
  error: string
}

export type ValidateMediaPlanInput = {
  mediaPlanId: string
  isMonthlyPayment: boolean
}

export const validateMediaPlan = async ({
  mediaPlanId,
  isMonthlyPayment
}: ValidateMediaPlanInput): Promise<
  ValidateMediaPlanOutputType | ValidateMediaPlanErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/media-plans/${mediaPlanId}/validate`,
      data: {
        isMonthlyPayment
      }
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.mediaPlanId) {
        return {
          mediaPlanId: response.data.mediaPlanId
        }
      }
    } else {
      const errorCode =
        (response as any)?.error.data?.code ?? (response as any)?.error.code
      if (errorCode && checkIfValidateMediaPlanErrorCodeValid(errorCode)) {
        return { error: ValidateMediaPlanErrorTranslation[errorCode] }
      }
    }

    return {
      error:
        ValidateMediaPlanErrorTranslation[ValidateMediaPlanErrorEnum.UNKNOWN]
    }
  } catch (_) {
    return {
      error:
        ValidateMediaPlanErrorTranslation[ValidateMediaPlanErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfValidateMediaPlan = (
  result: any
): result is ValidateMediaPlanOutputType => {
  return result.mediaPlanId !== undefined
}
