import React, { CSSProperties, useEffect, useRef, useState } from 'react'

import {
  DropDownContainer,
  LineChartContainer,
  LineChartRoot,
  LineChartTooltipContainer,
  dropDownStyle
} from './styles'
import LineChartHeaderSelection from './HeaderSelection'
import { drawChart } from './helpers/drawChart/drawChart'
import { defaultFormatHeaderData } from './helpers/formatHeaderData'
import LineChartCaptions from './Captions'
import Dropdown, { Option } from '../../Dropdown'
import {
  LineChartAggregatorEnum,
  TooltipComponentType,
  TooltipRenderer
} from '../../../types/lineChart'

export type Point = {
  time: Date
  value: number
  endTime?: Date
  percentageEvolution?: number
}

export type LineChartDataElement<PointType> = {
  name: string
  color: string
  values: PointType[]
  isAugmentationGood: boolean
  unit?: string
  caption: string
  aggregator?: LineChartAggregatorEnum
}

export type LineChartData<PointType> = LineChartDataElement<PointType>[]

export type FormattedPoint = {
  datasetIndex: number
  index: number
  time: string
  value: number
  endTime?: string
}

export type DatasetsSelection = {
  name: string
  color: string
  isSelected: boolean
  headerKpi?: string
  unit?: string
}[]

export type DropDownData = {
  options: Option[]
  value: Option
  onChange: (selectedOption: Option | null) => void
}

type PropsType<PointType extends Point> = {
  initialSelectionNames?: string[]
  data: LineChartData<PointType>
  dropDownData: DropDownData | null
  chartId: string
  formatHeaderData?: (
    data: LineChartData<PointType>,
    initialSelectionNames?: string[],
    globalCTR?: number
  ) => DatasetsSelection
  renderTooltip: TooltipRenderer<PointType>
  tooltipComponent: TooltipComponentType<PointType>
  width?: number
  style?: CSSProperties
}

const LineChartDrawer = <PointType extends Point>({
  initialSelectionNames,
  data,
  formatHeaderData = defaultFormatHeaderData,
  width = 1000,
  style,
  dropDownData,
  tooltipComponent,
  renderTooltip,
  chartId,
  globalCTR
}: PropsType<PointType> & { globalCTR?: number }) => {
  const [selectedDatasets, setSelectedDatasets] = useState<DatasetsSelection>(
    formatHeaderData(data, initialSelectionNames, globalCTR)
  )

  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setSelectedDatasets(
      formatHeaderData(data, initialSelectionNames, globalCTR)
    )
  }, [data, globalCTR])

  useEffect(() => {
    filteredData &&
      drawChart({
        chartId,
        chartRef,
        data: filteredData,
        width,
        renderTooltip
      })
  }, [data, selectedDatasets])

  const handleDatasetClick = (datasetIndex: number) => {
    const newSelectedDatasets = [...selectedDatasets]
    newSelectedDatasets[datasetIndex].isSelected =
      !newSelectedDatasets[datasetIndex].isSelected

    setSelectedDatasets(newSelectedDatasets)
  }

  const filteredData = data.filter(
    (d, index) => !!selectedDatasets[index].isSelected
  )

  if (data.length === 0) {
    return null
  }

  const TooltipComponent = tooltipComponent

  return (
    <LineChartContainer width={width} style={style}>
      <LineChartHeaderSelection
        datasets={selectedDatasets}
        handleDatasetClick={handleDatasetClick}
        width={width}
        globalCTR={globalCTR}
      />
      {dropDownData && (
        <DropDownContainer>
          <Dropdown
            options={dropDownData.options}
            onChange={dropDownData.onChange}
            value={dropDownData.value}
            style={dropDownStyle}
          />
        </DropDownContainer>
      )}
      <LineChartTooltipContainer id={`${chartId}-tooltip`}>
        <TooltipComponent chartId={chartId} data={filteredData} />
      </LineChartTooltipContainer>
      <LineChartRoot ref={chartRef}></LineChartRoot>
      <LineChartCaptions
        data={filteredData}
        selectedDatasets={selectedDatasets}
      />
    </LineChartContainer>
  )
}

export default LineChartDrawer
