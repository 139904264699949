import React from 'react'

import { IconType } from '../../types/icon'
import Colors from '../../constants/colors'

const HomePicto = ({
  height = '16px',
  width = '18px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 15.998 17.666"
    >
      <g
        id="Groupe_1404"
        data-name="Groupe 1404"
        transform="translate(-29.501 -221.111)"
      >
        <g
          id="Groupe_1361"
          data-name="Groupe 1361"
          transform="translate(30 221.611)"
        >
          <path
            id="Soustraction_2"
            data-name="Soustraction 2"
            d="M4.215,16.666H1.667A1.668,1.668,0,0,1,0,15V5.833L7.5,0,15,5.833V15a1.668,1.668,0,0,1-1.667,1.666H10.786V8.333H4.215v8.332Z"
            fill={color}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default HomePicto
