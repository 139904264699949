import React, { useState } from 'react'

import { PlatformContainer } from './styles'
import LabelledItemsList from '../../components/LabelledItemsList'
import DetailsPageTemplate from '../../templates/DetailsPageTemplate'
import TableList from '../../components/TableList'
import { formatDateToString } from '../../helpers/date'
import { PlatformAPIEnum } from '../../types/platform'
import { getFormatEnum } from '../../types/format'
import GoogleIcon from '../../components/Icon/googleLogo'
import { Label } from '../../components/LabelledItem/styles'
import { getBidStrategyEnum } from '../../types/bidStrategy'
import { getLangEnum } from '../../types/lang'
import { getAudienceByMediaPlanIdAndName } from '../../helpers/queries/audiences/getAudienceByName'
import ModalAudienceDetails from '../../components/ModalAudienceDetails'
import ModalKeywords from '../parts/ModalKeywords'
import { getCampaignKeywords } from '../../helpers/queries/campaign/getCampaignKeywords'
import StatusWithColor from '../../components/StatusDot'
import ModalReasonOfReject from '../parts/ModalReasonOfReject'
import { checkIfGoogleCampaign } from '../../types/campaign/google/googleCampaign'
import { linkedinDevices } from '../../constants/linkedin/linkedinDevices'
import LinkedinIcon from '../../components/Icon/linkedinLogo'
import { CampaignDetailsProps } from './types'
import statusHasStarted from '../../helpers/statusHasStarted'
import { Status } from '../../types/status'
import { ButtonPropsType } from '../../components/Button'
import { FlowEnum } from '../../types/flow'

const EditorCampaignDetails = ({
  campaign,
  mediaPlanName,
  skip,
  handleChangePage,
  dataAudienceModal,
  setDataAudienceModal,
  isKeywordsModalOpen,
  setIsKeywordsModalOpen,
  dataKeywordsModal,
  setDataKeywordsModal,
  isModalReasonOpen,
  setIsModalReasonOpen,
  handleShowReason,
  rejectionReason,
  navigation,
  handleAdd,
  handleEdit,
  navigate
}: CampaignDetailsProps) => {
  const [campaignName, setCampaignName] = useState('')

  const breadcrumbItems = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate(`/customers`)
      },
      label: 'Clients'
    },
    {
      onClick: () => {
        navigate(`/customer/${navigation?.company.id ?? ''}/details`)
      },
      label: navigation?.company.name ?? ''
    },
    {
      onClick: () => {
        if (campaign.status === Status.DRAFT) {
          navigate(`/media-plan/${campaign.mediaPlanId}/review`)
        } else {
          navigate(`/media-plan/${campaign.mediaPlanId}/details`)
        }
      },
      label: navigation?.mediaPlan.name ?? ''
    },
    {
      label: `Campagne « ${campaign.name} »` ?? ''
    }
  ]

  const devicesList: string[] = []
  const campaignDevices = checkIfGoogleCampaign(campaign)
    ? campaign.devices
    : linkedinDevices

  if (campaignDevices.desktop) {
    devicesList.push('Ordinateur')
  }
  if (campaignDevices.smartphone) {
    devicesList.push('Mobile')
  }
  if (campaignDevices.tablet) {
    devicesList.push('Tablette')
  }
  if (campaignDevices.tv) {
    devicesList.push('TV')
  }

  const summaryItems = [
    {
      label: 'Statut',
      value: (
        <StatusWithColor
          status={campaign.status}
          handleClick={handleShowReason}
        />
      )
    },
    {
      label: 'Période',
      value: campaign.period
        ? `du ${formatDateToString(
            new Date(campaign.period.start)
          )} au ${formatDateToString(new Date(campaign.period.end))}`
        : ''
    },
    {
      label: 'Budget dépensé / prévu',
      value: `${Math.round(campaign.spentBudget * 100) / 100} € / ${
        Math.round(campaign.budget * 100) / 100
      }€`
    },
    {
      label: 'Objectif',
      value: campaign.goal
    },
    {
      label: 'Format',
      value: getFormatEnum(campaign.format)
    },
    ...(checkIfGoogleCampaign(campaign)
      ? [
          // {
          //   label: 'Enchères',
          //   value: getBidStrategyEnum(campaign.bidStrategy)
          // },
          {
            label: 'Langue',
            value: getLangEnum(campaign.lang)
          },
          campaign.format !== 'image'
            ? {
                label: 'Mots clés',
                value:
                  campaign.keywordsCount !== undefined
                    ? campaign.keywordsCount.toString()
                    : '',
                onClick: async () => {
                  const data = await getCampaignKeywords({
                    campaignId: campaign.id
                  })
                  if (data) {
                    const dataKeywords = data.keywords.map((k) => ({
                      value: k,
                      status: 'initial' as const
                    }))
                    setDataKeywordsModal(dataKeywords)
                  }
                  setIsKeywordsModalOpen(true)
                  setCampaignName(campaign.name)
                }
              }
            : { label: '', value: '' }
        ]
      : []),
    {
      label: 'Audience',
      value: campaign.audiences.map((a) => a.name).join('\n'),
      onClick: async (audienceName: string) => {
        const result = await getAudienceByMediaPlanIdAndName({
          name: audienceName,
          mediaPlanId: campaign.mediaPlanId
        })
        if (result) {
          setDataAudienceModal(result)
        }
      }
    },
    {
      label: 'Périphériques',
      value: devicesList.join(', ')
    }
  ]

  const summaryContentColumn1 = <LabelledItemsList items={summaryItems} />

  const summaryContentColumn2 = campaign.platform ? (
    <PlatformContainer>
      <Label>Plateforme</Label>
      {campaign.platform === PlatformAPIEnum.GOOGLE && <GoogleIcon />}
      {campaign.platform === PlatformAPIEnum.LINKEDIN && <LinkedinIcon />}
    </PlatformContainer>
  ) : undefined

  const items = campaign.ads
    ? campaign.ads.map((c) => {
        return {
          id: c.id,
          row: [
            c.name,
            c.status ? (
              <StatusWithColor
                status={c.status}
                handleClick={handleShowReason}
              />
            ) : (
              ''
            ),
            getFormatEnum(c.format)
          ],
          details: {
            column1: []
          },
          subItems: []
        }
      })
    : []

  const campaignListData = {
    titles: ['Nom', 'Statut', 'Format'],
    widths: ['40%', '30%', '30%'],
    items
  }

  const hideCO2 = !statusHasStarted(campaign.status)

  const cta:
    | { label: string; action: () => void; btnType: ButtonPropsType['type'] }[]
    | undefined =
    campaign.status === Status.DRAFT
      ? [
          {
            label: 'Reprendre la création',
            action: () => {
              navigate(`/campaign/${campaign.id}/review?flow=${FlowEnum.view}`)
            },
            btnType: 'secondary'
          }
        ]
      : undefined

  return (
    <DetailsPageTemplate
      navigate={navigate}
      breadcrumbItems={breadcrumbItems}
      isEditor={true}
      summary={{
        title: campaign.name,
        type: 'Campagne',
        co2InG:
          checkIfGoogleCampaign(campaign) && !hideCO2
            ? campaign.consumedCo2
            : null,
        column1: summaryContentColumn1,
        column2: summaryContentColumn2,
        onEdit: handleEdit,
        cta
      }}
    >
      <>
        <TableList
          title="Annonces"
          data={campaignListData}
          itemsByPage={5}
          handleAdd={handleAdd}
          addText="Nouvelle annonce"
          handleOnRowClick={(id: string) => {
            navigate(
              `/ad/${id}${
                campaign.ads?.find((ad) => ad.id === id)?.format
                  ? '/' + campaign.ads?.find((ad) => ad.id === id)?.format
                  : ''
              }/details`
            )
          }}
          totalItems={navigation?.campaign.adsCount ?? 0}
          currentPage={skip / 5 + 1}
          handleChangePage={(page) => {
            handleChangePage(page)
          }}
        />
        {dataAudienceModal && (
          <ModalAudienceDetails
            title={"Détail de l'audience"}
            data={dataAudienceModal}
            handleClose={() => {
              setDataAudienceModal(null)
            }}
          />
        )}
        {isKeywordsModalOpen && (
          <ModalKeywords
            setIsOpen={setIsKeywordsModalOpen}
            campaignName={campaignName}
            keywords={dataKeywordsModal}
          />
        )}
        {isModalReasonOpen && (
          <ModalReasonOfReject
            onClose={() => {
              setIsModalReasonOpen(!isModalReasonOpen)
            }}
            rejectionReason={rejectionReason}
            mediaPlanName={mediaPlanName}
          />
        )}
      </>
    </DetailsPageTemplate>
  )
}

export default EditorCampaignDetails
