export enum CreateAudienceErrorEnum {
  UNKNOWN = 'unknown',
  NAME_NOT_AVAILABLE = 'name-not-available',
  NAME_TOO_SHORT = 'name-too-short',
  INTERESTS = 'interests',
  MIN_AGE = 'min-age',
  MAX_AGE = 'max-age',
  LOCATION = 'location',
  CREATION_FAILED = 'creation-failed'
}

export const CreateAudienceErrorTranslation = {
  [CreateAudienceErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [CreateAudienceErrorEnum.NAME_NOT_AVAILABLE]:
    "Le nom de l'audience n'est pas disponible",
  [CreateAudienceErrorEnum.NAME_TOO_SHORT]:
    "Le nom de l'audience doit faire au minimum 3 caractères",
  [CreateAudienceErrorEnum.INTERESTS]:
    'Une erreur est survenue avec les intérêts que vous avez sélectionné',
  [CreateAudienceErrorEnum.MIN_AGE]: `L'âge minimal doit être supérieur ou égal à 18 ans
    et inférieur ou égal à l'âge maximal`,
  [CreateAudienceErrorEnum.MAX_AGE]:
    "L'âge maximal doit être supérieur ou égal à l'âge minimal",
  [CreateAudienceErrorEnum.LOCATION]: `Une erreur est survenue avec une des zones géographiques
    que vous avez renseignés`,
  [CreateAudienceErrorEnum.CREATION_FAILED]:
    "Une erreur est survenue. La création de l'audience a été annulée"
}

export enum CreateAudiencenErrorFromApi {
  'INSERTION' = `Une erreur est survenue durant la création de votre audience`,
  'MISSING_VALUE' = `Une erreur est survenue. Il manque un ou plusieurs paramètres
    à votre audience`,
  'INPUT_FORMAT' = `Une erreur relative au format d'un des paramètres d'entrée est survenue.`,
  'UNKNOWN' = 'Une erreur inconnue est survenue',
  'NAME_ALREADY_EXISTS' = `Le nom de l'audience est déjà utilisé pour ce plan média`,
  'NAME_ALREADY_EXISTS_IN_OTHER_PM' = `Le nom de l'audience est déjà utilisé pour un autre de vos plans média`
}

export type CreateAudienceErrorFromApiKeys =
  keyof typeof CreateAudiencenErrorFromApi

export const checkIfCreateAudienceErrorCodeValid = (
  errorCode: string
): errorCode is CreateAudienceErrorFromApiKeys => {
  return Object.keys(CreateAudiencenErrorFromApi).includes(errorCode)
}
