import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Colors from '../../constants/colors'
import FontSize from '../../constants/fontSize'
import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'
import Margin from '../../constants/margin'

export type DefaultTextSize = 'small' | 'medium' | 'big' | 'huge'

type TextPropsType = {
  children: React.ReactNode
  size?: DefaultTextSize
  style?: CSSProperties
  bold?: boolean
  italic?: boolean
  hoverable?: boolean
  onClick?: () => void
}

const getTextSize = (size?: DefaultTextSize) => {
  switch (size) {
    case 'small':
      return FontSize.YETIC_TEXT_SMALL
    case 'medium':
      return FontSize.YETIC_TEXT_MEDIUM
    case 'big':
      return FontSize.YETIC_SUBTITLE
    case 'huge':
      return FontSize.YETIC_TITLE_HUGE
    default:
      return FontSize.YETIC_TEXT
  }
}

const Text = styled.div<{
  size?: DefaultTextSize
  bold?: boolean
  italic?: boolean
  hoverable?: boolean
  onClick?: () => void
}>`
  color: ${Colors.YETIC_BLUE_DARK};
  font-size: ${(props) => getTextSize(props.size)};
  line-height: ${(props) => getTextSize(props.size) + 3};
  font-weight: ${(props) =>
    props.bold ? 700 : props.size === 'medium' ? 400 : 500};
  font-family: ${YETIC_FONT_FAMILY};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  margin: ${Margin.m2} 0;
  margin-top: 0;
  ${(props) => (props.onClick || props.hoverable ? 'cursor: pointer' : '')};

  :hover {
    text-decoration: ${(props) => (props.hoverable ? 'underline' : 'none')};
  }
`

const DefaultText = ({
  children,
  size,
  style,
  bold,
  italic,
  hoverable,
  onClick
}: TextPropsType) => (
  <Text
    size={size}
    style={style}
    bold={bold}
    italic={italic}
    hoverable={hoverable}
    onClick={onClick}
  >
    {children}
  </Text>
)

export default DefaultText
