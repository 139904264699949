export enum CreateCampaignErrorFromApi {
  CREATE = `Une erreur est survenue.
  Votre campagne n'a par conséquent pas été enregistre`,
  MISSING_VALUE = `Une erreur est survenue. Il manque un ou plusieurs paramètres
  à votre campagne`,
  KEY_WORD = `Au moins un mot clé est requis`,
  MEDIA_PLAN_ID = `L'identifiant du plan média est manquant`,
  VALUE_ALREADY_USED = `Le nom du campagne est déjà utilisé`,
  UNKNOWN = 'Une erreur inconnue est survenue',
  GOOGLE_CONNECTION_FAILED = 'Une erreur est survenue lors de la connexion à Google Ads',
  GOOGLE_API_CAMPAIGN = "Une erreur est survenue lors de la création de la campagne sur Google Ads. Vérifiez que vous n'avez pas déjà une campagne avec le même nom",
  GOOGLE_API_BUDGET = 'Une erreur est survenue lors de la création du budget sur Google Ads',
  GOOGLE_API_AGE = "Une erreur est survenue lors de la création de l'âge cible de l'audience sur Google Ads",
  GOOGLE_API_GENDER = "Une erreur est survenue lors de la création du genre de l'audience sur Google Ads",
  GOOGLE_API_DEVICES = 'Une erreur est survenue lors de la création des appareils cibles sur Google Ads',
  GOOGLE_API_LOCATION = 'Une erreur est survenue lors de la création de la localisation cible sur Google Ads',
  GOOGLE_API_KEYWORD = 'Une erreur est survenue lors de la création des mots clés sur Google Ads. Veillez à ne pas utiliser de caractères spéciaux.'
}

export type CreateCampaignErrorFromApiKeys =
  keyof typeof CreateCampaignErrorFromApi

export const checkIfCreateCampaignErrorCodeValid = (
  errorCode: string
): errorCode is CreateCampaignErrorFromApiKeys => {
  return Object.keys(CreateCampaignErrorFromApi).includes(errorCode)
}
