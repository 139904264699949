import React, { FC, ChangeEvent, CSSProperties } from 'react'
import styled from '@emotion/styled'

import Colors from '../../../constants/colors'

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

const CheckboxInput = styled.input`
  appearance: none;
  min-width: 15px;
  height: 15px;
  margin-right: 8px;
  border: 1px solid ${Colors.YETIC_BLUE_DARK};
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s;

  &:checked {
    background-color: ${Colors.YETIC_BLUE_DARK};
  }
`

interface CheckboxProps {
  selected?: boolean
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | (() => void)
  style?: CSSProperties
  inputStyle?: CSSProperties
  children?: React.ReactNode
  disabled?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  selected = false,
  onChange,
  children,
  style,
  inputStyle,
  disabled = false
}) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange && !disabled) {
      onChange(event)
    }
  }

  return (
    <CheckboxContainer style={style}>
      <CheckboxInput
        type="checkbox"
        checked={selected}
        onChange={handleCheckboxChange}
        style={inputStyle}
      />
      {children}
    </CheckboxContainer>
  )
}

export default Checkbox
