import React from 'react'

import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import Input from '../../../components/Input'
import Margin from '../../../constants/margin'
import { InputError } from '../../../components/Input/Styles'
import DefaultText from '../../../components/DefaultText'
import FontWeight from '../../../constants/fontWeight'

type RejectModalProps = {
  onClose: () => void
  handleChangeReasonForRejection: (reason: string) => void
  reasonForRejection: string
  isLoading: boolean
  error: string
  validationMessage: string
  handleSubmit: () => void
  mediaPlan: string
  editor: string
}

const RejectModal = ({
  onClose,
  handleChangeReasonForRejection,
  reasonForRejection,
  isLoading,
  error,
  validationMessage,
  handleSubmit,
  mediaPlan,
  editor
}: RejectModalProps) => {
  const rejectMessage = () => (
    <>
      Vous êtes sur le point de rejeter le plan média{' '}
      <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>{mediaPlan}</span>.
      <div
        style={{ marginTop: Margin.m5 }}
      >{`Faites part à l'agence ${editor} des raisons de ce refus, elle pourra modifier le plan média et vous soumettre une nouvelle version.`}</div>
    </>
  )

  return (
    <ConfirmationModal
      onClose={onClose}
      onClickReject={onClose}
      onClickConfirm={handleSubmit}
      title={`Rejet du plan média « ${mediaPlan} »`}
      textConfirm="Rejeter le plan média et envoyer le message"
      isConfirmLoading={isLoading}
      textReject={validationMessage ? 'Fermer' : 'Annuler'}
      confirmHidden={!!validationMessage}
      style={{ maxWidth: '500px' }}
      divButtonsStyle={{ justifyContent: 'flex-end' }}
    >
      <DefaultText size="medium">{rejectMessage()}</DefaultText>
      <Input
        label={'Raison du refus'}
        placeholder="Votre message ..."
        type="textarea"
        onChange={(e) => {
          handleChangeReasonForRejection(e.target.value)
        }}
        value={reasonForRejection}
        containerStyle={{ marginTop: Margin.m7, marginBottom: Margin.m3 }}
        withBorder
      />
      {error && <InputError style={{ textAlign: 'left' }}>{error}</InputError>}
      {validationMessage && (
        <DefaultText size="small" style={{ textAlign: 'left' }}>
          {validationMessage}
        </DefaultText>
      )}
    </ConfirmationModal>
  )
}

export default RejectModal
