import React, { useEffect, useRef, useState } from 'react'

import {
  DivContainer,
  DivLabel,
  LabelText,
  DropdownContainer,
  DropdownButton,
  DropDownScroll,
  DropdownContent,
  OptionItem,
  Separation
} from './styles'
import { ArrowDropDown } from '../Icon'

// TODO review this type, we have too many casts
export type Option = {
  id: number | string
  label: string
}

type DropdownProps = {
  options: Option[]
  onChange: (selectedOption: Option | null) => void
  width?: string
  label?: string // Add a label prop
  value?: Option | string
  style?: React.CSSProperties
  placeholder?: string
  isDropdownFilter?: boolean
  viewAllText?: string
  disabled?: boolean
}

const Dropdown = ({
  options,
  onChange,
  width,
  value,
  label,
  style,
  placeholder,
  isDropdownFilter = false,
  viewAllText,
  disabled
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    if (options.length > 0 && !disabled) {
      setIsOpen(!isOpen)
    }
  }

  const handleOptionClick = (option: Option | null) => {
    onChange(option)
    setIsOpen(false)
  }

  // TODO : 🤪 refacto this please
  const currentLabel = value
    ? (value as Option).label
      ? (value as Option).label
      : (value as string)
    : placeholder || 'Choisissez une option'

  return (
    <DivContainer disabled={disabled || options.length < 1}>
      {label && (
        <DivLabel>
          <LabelText>{label}</LabelText>
        </DivLabel>
      )}
      <DropdownContainer
        width={width}
        ref={containerRef}
        onClick={toggleDropdown}
        disabled={disabled || options.length < 1}
      >
        <DropdownButton
          width={width}
          style={style}
          isOpen={isOpen}
          disabled={disabled || options.length < 1}
        >
          {currentLabel}
          <span
            style={{
              background: '#fff',
              borderRadius: '30px',
              display: 'inline-flex',
              transform: isOpen ? 'rotate(180deg)' : ''
            }}
          >
            <ArrowDropDown width={'15px'} height={'15px'} />
          </span>
        </DropdownButton>
        <DropDownScroll isOpen={isOpen}>
          {isOpen && (
            <DropdownContent>
              {isDropdownFilter && (
                <>
                  <OptionItem
                    onClick={() => {
                      handleOptionClick(null)
                    }}
                  >
                    {viewAllText ?? 'Voir tout'}
                  </OptionItem>
                  <Separation />
                </>
              )}
              {options.map((option) => (
                <OptionItem
                  key={option.id}
                  onClick={() => {
                    handleOptionClick(option)
                  }}
                >
                  {option.label}
                </OptionItem>
              ))}
            </DropdownContent>
          )}
        </DropDownScroll>
      </DropdownContainer>
    </DivContainer>
  )
}

export default Dropdown
