import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Content, DivContainer } from './styles'
import PasswordCreation from './PasswordCreation'
import UserActivation from './UserActivation'
import { MessageButton } from './MessageButton'
import LoadingPage from '../../LoadingPage'

export type VerificationEmailErrors = {
  password: string
  passwordConfirmation: string
}

const VerificationEmail = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const search = location.search
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')
  const isEditor = searchParams.get('isEditor')

  if (!token || !isEditor) {
    return <LoadingPage />
  }

  const handleGoToLogin = () => {
    navigate('/auth')
  }

  if (isEditor !== '0' && isEditor !== '1') {
    return (
      <DivContainer>
        <Content>
          <MessageButton
            message={`Un ou plusieurs paramètres dans l'url sont manquants ou incorrects.`}
            buttonLabel={`Revenir à l'écran de connexion.`}
            buttonAction={handleGoToLogin}
          />
        </Content>
      </DivContainer>
    )
  }

  return isEditor === '0' ? (
    <PasswordCreation token={token} />
  ) : (
    <UserActivation token={token} />
  )
}

export default VerificationEmail
