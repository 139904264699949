import React from 'react'

import { ImageAdCreationFormDataType } from '..'
import Input from '../../../../../components/Input'
import { PanelType } from '../../../../../types/templates/panels'

type GetHeadlinePanelProps = {
  formData: ImageAdCreationFormDataType
  handleInputChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  hasBeenSubmitted: boolean
  error: string
}

const getHeadlinePanel = ({
  formData,
  handleInputChange,
  hasBeenSubmitted,
  error
}: GetHeadlinePanelProps): PanelType => {
  const headlinePanel: PanelType = {
    title: 'Titre',
    disabled: false,
    isDropdown: false,
    description: `Renseignez le titre qui s'affichera sous l'image de votre annonce.`,
    sections: [
      {
        content: (
          <Input
            type="text"
            label={'Titre'}
            placeholder="Titre"
            maxLength={200}
            showCharacterCount
            value={formData.headline}
            onChange={(e) => {
              handleInputChange('headline', e)
            }}
            error={hasBeenSubmitted ? error : ''}
            withBorder
          />
        )
      }
    ]
  }

  return headlinePanel
}

export default getHeadlinePanel
