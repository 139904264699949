import styled from '@emotion/styled'

import Padding from '../../../../constants/padding'
import FontSize from '../../../../constants/fontSize'
import FontWeight from '../../../../constants/fontWeight'
import Margin from '../../../../constants/margin'

export const TipsFormatContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: ${Padding.p5};
  margin-top: ${Margin.m4};
`

export const TipsFormatContentItem = styled.div`
  display: flex;
  background-color: white;
  border-radius: 10px;
  padding: ${Padding.p5};
  align-items: center;
  justify-content: space-between;
`

export const TipsFormatContentItemText = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`

export const TipsFormatContentItemList = styled.ul`
  padding-left: ${Padding.p7};
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TipsFormatContentItemLi = styled.li`
  font-family: Montserrat;
  font-size: ${FontSize.YETIC_TEXT};
  font-weight: ${FontWeight.YETIC_NORMAL};
  width: 100%;
`
