import {
  googleLogoMinHeight,
  googleLogoMinWidth,
  googleMaxSize,
  googleRectangleMinHeight,
  googleRectangleMinWidth,
  googleRectangleRatio,
  googleSquareMinHeight,
  googleSquareMinWidth,
  linkedinMaxSize,
  linkedinMaxWidth,
  linkedinMinWidth,
  linkedinRectangleRatio
} from '../../constants/image'

export enum GoogleFormImageAdError {
  NAME = `Veuillez renseigner un nom pour votre annonce`,
  BUSINESS_NAME = `Veuillez renseigner un nom d'entreprise`,
  URL = `L'url n'est pas valide`,
  IMAGE_MISSING = `Veuillez fournir une image`,
  HEADLINE = `Veuillez renseigner un titre`,
  DESCRIPTION = `Veuillez renseigner une description`,
  IMAGE_SIZE = `L'image est trop lourde. Veuillez ne pas dépasser ${
    googleMaxSize / 1000000
  } Mo.`,
  LOGO_RATIO = `Le logo n'est pas au format requis. Veuillez utiliser un logo carré.`,
  LOGO_SIZE = `Le format du logo n'est pas valide. Veuillez utiliser un logo d'au moins ${googleLogoMinWidth} x ${googleLogoMinHeight} pixels.`,
  RECTANGLE_RATIO = `L'image n'est pas au format requis. Veuillez utiliser une image ayant un ratio de ${googleRectangleRatio} x 1.`,
  RECTANGLE_SIZE = `Le format de l'image n'est pas valide. Veuillez utiliser une image d'au moins ${googleRectangleMinWidth} x ${googleRectangleMinHeight} pixels.`,
  SQUARE_RATIO = `L'image n'est pas au format requis. Veuillez utiliser une image carrée.`,
  SQUARE_SIZE = `Le format de l'image n'est pas valide. Veuillez utiliser une image d'au moins ${googleSquareMinWidth} x ${googleSquareMinHeight} pixels.`,
  SQUARE_IMAGE = `L'image n'est pas au format requis`
}

export enum LinkedinFormImageAdError {
  NAME = `Veuillez renseigner un nom pour votre annonce`,
  URL = `L'url n'est pas valide`,
  IMAGE_MISSING = `Veuillez fournir une image`,
  HEADLINE = `Veuillez renseigner un titre`,
  CONTENT = `Veuillez renseigner un texte d'introduction pour votre annonce`,
  CTA = `Veuillez renseigner un texte pour le bouton d'appel à l'action`,
  IMAGE_SIZE = `L'image est trop lourde. Veuillez ne pas dépasser ${
    linkedinMaxSize / 1000000
  } Mo.`,
  IMAGE_RATIO = `L'image n'est pas au format requis. Veuillez utiliser une image au format ${linkedinRectangleRatio}:1 ou 1:1.`,
  IMAGE_MIN_DIMENSIONS = `Veuillez utiliser une image d'au moins ${linkedinMinWidth} pixels de largeur.`,
  IMAGE_MAX_DIMENSIONS = `Veuillez utiliser une image d'au plus ${linkedinMaxWidth} pixels de largeur.`
}
