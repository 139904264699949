import styled from '@emotion/styled'

import Padding from '../../../../../constants/padding'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${Padding.p3} 0;
`
