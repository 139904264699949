import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import TipsFormat from '../../../../components/Tips/TipsFormat'
import {
  ModalContent,
  ImagesSelectionContainer,
  PictureWeightSpan,
  PictureWeightSpanContainer,
  PreviewContainer,
  TextInputsContainer,
  previewMessageStyle
} from './styles'
import { AppContext } from '../../../../contexts/AppContext'
import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import LoadingPage from '../../../LoadingPage'
import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import { PanelType } from '../../../../types/templates/panels'
import Input from '../../../../components/Input'
import { TipsInfos } from '../../../../components/Tips'
import InputFile from '../../../../components/InputFile'
import { GoogleFormImageAdError } from '../../../../types/pages/adImageCreation'
import ErrorPage from '../../../ErrorPage'
import { getCampaignById } from '../../../../helpers/queries/campaign/getCampaignById'
import { AdNavigation, emptyAdNavigation } from '../../../../types/ad/ad'
import {
  Status,
  StatusTranslation,
  getIsEditable
} from '../../../../types/status'
import Breadcrumb from '../../../../components/BreadCrumb'
import { FormatAPIEnum } from '../../../../types/format'
import {
  checkIfCreateGoogleImageAdOutput,
  createGoogleImageAd
} from '../../../../helpers/queries/ad/google/createImageAd'
import { convertObjectToFormData } from '../../../../helpers/convertObjectToFromData'
import {
  googleLogoMinHeight,
  googleLogoMinWidth,
  googleLogoRatio,
  googleMaxSize,
  googleRectangleMinHeight,
  googleRectangleMinWidth,
  googleRectangleRatio,
  googleSquareMinHeight,
  googleSquareMinWidth,
  googleSquareRatio
} from '../../../../constants/image'
import CarouselPreview from '../../../../components/PreviewAds/Google/Display/CarouselPreview'
import Colors from '../../../../constants/colors'
import DefaultText from '../../../../components/DefaultText'
import {
  ImageAdFilesErrors,
  checkIfEmptyErrors,
  getFormErrors,
  getImagesErrors
} from '../../../../helpers/imageAd/formErrors'
import { getRandomArrayElement } from '../../../../helpers/getRandomArrayElement'
import {
  ImageAdCreationFormDataType,
  ImageAdFiles
} from '../../../../types/ad/google/imageAd'
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal'

type ImageAdFilesUrls = {
  logo: string
  marketingImage1: string
  marketingImage2: string
  marketingImage3: string
  squareMarketingImage1: string
  squareMarketingImage2: string
  squareMarketingImage3: string
}

type ImagesType = {
  logo: string
  adImageSquare: string
  adImageRectangle: string
}

const SelfEditorGoogleImageAdCreation = () => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [errorFromApi, setErrorFromApi] = useState('')
  const [hasFetchError, setHasFetchError] = useState(false)
  const [navigation, setNavigation] = useState<AdNavigation>(emptyAdNavigation)
  const [campaignStatus, setCampaignStatus] = useState<Status | null>(null)
  const [selectedPreview, setSelectedPreview] = useState(1)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [previewImages, setPreviewImages] = useState<ImagesType>({
    logo: '',
    adImageSquare: '',
    adImageRectangle: ''
  })
  const [formData, setFormData] = useState<ImageAdCreationFormDataType>({
    name: '',
    businessName: '',
    campaignId: '',
    url: '',
    logo: null,
    headline1: '',
    headline2: '',
    headline3: '',
    longHeadline: '',
    description1: '',
    description2: '',
    description3: '',
    marketingImage1: null,
    marketingImage2: null,
    marketingImage3: null,
    squareMarketingImage1: null,
    squareMarketingImage2: null,
    squareMarketingImage3: null
  })
  const [imageUrls, setImageUrls] = useState<ImageAdFilesUrls>({
    logo: '',
    marketingImage1: '',
    marketingImage2: '',
    marketingImage3: '',
    squareMarketingImage1: '',
    squareMarketingImage2: '',
    squareMarketingImage3: ''
  })
  const [imageErrors, setImageErrors] = useState<ImageAdFilesErrors>({
    logo: GoogleFormImageAdError.IMAGE_MISSING,
    marketingImage1: GoogleFormImageAdError.IMAGE_MISSING,
    marketingImage2: '',
    marketingImage3: '',
    squareMarketingImage1: GoogleFormImageAdError.IMAGE_MISSING,
    squareMarketingImage2: '',
    squareMarketingImage3: ''
  })

  const areAllImagesGivenRef = useRef(false)

  const { campaignId } = useParams()

  const navigate = useNavigate()

  const { isSelfEditor, user } = useContext(AppContext)

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignById({
          campaignId,
          adType: FormatAPIEnum.IMAGE
        })
        if (result && result.campaign.ads && result.campaign.ads.length >= 3) {
          navigate(`/campaign/${campaignId}/review`)
        }
        if (result && result.navigation) {
          setCampaignStatus(result.campaign.status)
          setNavigation(result.navigation)
          setFormData({
            ...formData,
            campaignId,
            businessName: result.navigation.company.name.substring(0, 20)
          })
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }, [campaignId])

  useEffect(() => {
    if (isSubmitLoading) {
      if (!campaignId) {
        console.error("Aucune campagne n'a été sélectionnée.")
      } else {
        ;(async function () {
          try {
            const form = convertObjectToFormData(formData)
            const response = await createGoogleImageAd({
              form
            })

            setIsSubmitLoading(false)

            if (checkIfCreateGoogleImageAdOutput(response)) {
              navigate(`/campaign/${campaignId}/review`)
            } else {
              console.error(response.error)
              setErrorFromApi(response.error)
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            }
          } catch (error) {
            console.error(
              "Une erreur s'est produite lors de la création de la publicité :",
              error
            )
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  useEffect(() => {
    const areAllImagesGiven =
      formData.logo &&
      formData.marketingImage1 &&
      formData.squareMarketingImage1

    if (!areAllImagesGivenRef.current && areAllImagesGiven) {
      areAllImagesGivenRef.current = true
      setPreviewImages((prevImages) => ({
        ...prevImages,
        logo: imageUrls.logo,
        adImageRectangle: imageUrls.marketingImage1,
        adImageSquare: imageUrls.squareMarketingImage1
      }))
    } else if (areAllImagesGivenRef.current && !areAllImagesGiven) {
      areAllImagesGivenRef.current = false
      setPreviewImages((prevImages) => ({
        ...prevImages,
        logo: '',
        adImageRectangle: '',
        adImageSquare: ''
      }))
    }
  }, [imageUrls])

  useEffect(() => {
    const adImageSquaresUrls = [
      imageUrls.squareMarketingImage1,
      imageUrls.squareMarketingImage2,
      imageUrls.squareMarketingImage3
    ].filter((item) => item !== '')
    const adImageRectanglesUrls = [
      imageUrls.marketingImage1,
      imageUrls.marketingImage2,
      imageUrls.marketingImage3
    ].filter((item) => item !== '')

    const randomAdImageSquareUrl =
      getRandomArrayElement<string>(adImageSquaresUrls)
    const randomAdImageRectangleUrl = getRandomArrayElement<string>(
      adImageRectanglesUrls
    )

    setPreviewImages((prevImages) => ({
      ...prevImages,
      adImageRectangle: randomAdImageRectangleUrl,
      adImageSquare: randomAdImageSquareUrl
    }))
  }, [selectedPreview])

  if (hasFetchError || !campaignId) {
    return <ErrorPage />
  }

  if (!navigation || !campaignStatus) {
    return <LoadingPage />
  }

  if (!getIsEditable({ status: campaignStatus, isEditor: false })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas créer de publicité pour cette campagne au statut ${StatusTranslation[
          campaignStatus
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Revenir à la campagne',
          onClick: () => {
            navigate(`/campaign/${campaignId}/details`)
          }
        }}
      />
    )
  }

  const isPreviewDisplayed =
    areAllImagesGivenRef.current &&
    imageErrors.logo === '' &&
    imageErrors.marketingImage1 === '' &&
    imageErrors.squareMarketingImage1 === ''

  const errors = getFormErrors(formData)

  const handleImageError = async (
    attribute: keyof ImageAdFiles,
    img: File | null
  ) => {
    const newImageErrors = await getImagesErrors(attribute, img, imageErrors)

    setImageErrors(newImageErrors)
  }

  const handleInputChange = (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [attribute]: e.target.value })
  }

  const handleTextAreaChange = (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [attribute]: e.target.value })
  }

  const handleImageChange = (
    attribute: keyof ImageAdFiles,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFormData = { ...formData }
    if (e.target.files && e.target.files[0]) {
      const imageUrl = URL.createObjectURL(e.target.files[0])
      newFormData[attribute] = e.target.files[0]
      const logo = newFormData.logo

      if (attribute === 'logo' && logo) {
        setPreviewImages((prevImages) => ({
          ...prevImages,
          logo: imageUrl
        }))
      }

      handleImageError(attribute, e.target.files[0])
      setFormData(newFormData)
      setImageUrls((prevUrls) => ({ ...prevUrls, [attribute]: imageUrl }))
    }
  }

  const handleImageDelete = (attribute: keyof ImageAdFiles) => {
    const newFormData = { ...formData }
    newFormData[attribute] = null

    handleImageError(attribute, null)
    setFormData(newFormData)
    setImageUrls((prevUrls) => ({ ...prevUrls, [attribute]: '' }))
  }

  const handleSubmit = () => {
    setIsSubmitLoading(true)
  }

  const titles = [
    formData.headline1,
    formData.headline2,
    formData.headline3
  ].filter((item) => item)
  const descriptions = [
    formData.description1,
    formData.description2,
    formData.description3
  ].filter((item) => item)

  const randomTitle = getRandomArrayElement<string>(titles)
  const randomDescription = getRandomArrayElement<string>(descriptions)

  const handleOnClickNextPreview = () => {
    setSelectedPreview((prevSelectedPreview) =>
      prevSelectedPreview >= 9 ? 1 : prevSelectedPreview + 1
    )
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    navigate(`/campaign/${campaignId}/review`)
  }

  // TODO: get the main color of the image and handle the change of color according to the
  // selected preview (use the color of the square or the rectangle image according to the preview)
  const fakeColor = Colors.YETIC_GREY_AD

  const breadcrumbItems = [
    {
      label: 'Plan publicitaire'
    },
    {
      onClick: () => {
        navigate(`/media-plan/${navigation.mediaPlan.id}/review`)
      },
      label: navigation.mediaPlan.name
    },
    {
      onClick: () => {
        navigate(`/campaign/${navigation.campaign.id}/review`)
      },
      label: `Campagne « ${navigation.campaign.name} »`
    },
    { label: `Création de publicité` }
  ]

  const namePanel: PanelType = {
    title: 'Noms',
    disabled: false,
    isDropdown: false,
    isShown: true,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="text"
              value={formData.name}
              isRequired={true}
              placeholder="Nom de ma publicité"
              maxLength={20}
              showCharacterCount
              onChange={(e) => {
                handleInputChange('name', e)
              }}
              label={`Nom de la publicité`}
              error={hasBeenSubmitted ? errors.name : ''}
              withBorder
            />
            <Input
              type="text"
              value={formData.businessName}
              isRequired={true}
              placeholder="Nom de l'entreprise"
              maxLength={20}
              showCharacterCount
              onChange={(e) => {
                handleInputChange('businessName', e)
              }}
              label={`Nom de l'entreprise`}
              error={hasBeenSubmitted ? errors.businessName : ''}
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }

  const tipsDropdown = (
    <TipsInfos
      title="Nos recommandations"
      text={`Optez pour des images et des graphiques
            de haute qualité et compressés pour maximiser l'impact visuel
            sans compromettre la taille du fichier.`}
      isDropdown={true}
      style={{ marginBottom: '24px' }}
    />
  )

  const urlPanel: PanelType = {
    title: 'Lien de votre site web',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez le lien de votre site web vers lequelle vous souhaitez rediriger votre public cible.`,
    sections: [
      {
        content: (
          <Input
            type="text"
            placeholder="https://example.com"
            isRequired={true}
            label={'URL finale'}
            value={formData.url}
            onChange={(e) => {
              handleInputChange('url', e)
            }}
            error={hasBeenSubmitted ? errors.url : ''}
            withBorder
          />
        )
      }
    ]
  }

  const longHeadlinePanel: PanelType = {
    title: 'Titre long',
    disabled: false,
    isDropdown: false,
    description: `Renseignez le titre long qui s'affichera sur votre publicité.`,
    sections: [
      {
        content: (
          <Input
            type="text"
            label={'Titre long'}
            placeholder="Titre long"
            maxLength={90}
            showCharacterCount
            value={formData.longHeadline}
            onChange={(e) => {
              handleInputChange('longHeadline', e)
            }}
            error={hasBeenSubmitted ? errors.longHeadline : ''}
            withBorder
          />
        )
      }
    ]
  }

  const headlinesPanel: PanelType = {
    title: 'Titres',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez des titres accrocheurs pour attirer l'attention de votre public cible.`,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="text"
              label={'Titre 1'}
              placeholder="Titre 1"
              maxLength={30}
              showCharacterCount
              value={formData.headline1}
              onChange={(e) => {
                handleInputChange('headline1', e)
              }}
              error={hasBeenSubmitted ? errors.headline1 : ''}
              withBorder
            />
            <Input
              type="text"
              label={'Titre 2'}
              placeholder="Titre 2"
              maxLength={30}
              showCharacterCount
              value={formData.headline2}
              onChange={(e) => {
                handleInputChange('headline2', e)
              }}
              isRequired={false}
              error={hasBeenSubmitted ? errors.headline2 : ''}
              withBorder
            />
            <Input
              type="text"
              label={'Titre 3'}
              placeholder="Titre 3"
              maxLength={30}
              showCharacterCount
              value={formData.headline3}
              onChange={(e) => {
                handleInputChange('headline3', e)
              }}
              isRequired={false}
              error={hasBeenSubmitted ? errors.headline3 : ''}
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }

  const descriptionsPanel: PanelType = {
    title: 'Descriptions',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez des textes persuasifs pour inciter à l'action.`,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="textarea"
              onChange={(e) => {
                handleTextAreaChange('description1', e)
              }}
              placeholder="Description 1"
              label={`Description 1`}
              value={formData.description1}
              maxLength={90}
              showCharacterCount
              error={hasBeenSubmitted ? errors.description1 : ''}
              withBorder
            />
            <Input
              type="textarea"
              onChange={(e) => {
                handleTextAreaChange('description2', e)
              }}
              placeholder="Description 2"
              label={`Description 2`}
              value={formData.description2}
              maxLength={90}
              showCharacterCount
              isRequired={false}
              error={hasBeenSubmitted ? errors.description2 : ''}
              withBorder
            />
            <Input
              type="textarea"
              onChange={(e) => {
                handleTextAreaChange('description3', e)
              }}
              placeholder="Description 3"
              label={`Description 3`}
              value={formData.description3}
              maxLength={90}
              showCharacterCount
              isRequired={false}
              error={hasBeenSubmitted ? errors.description3 : ''}
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }

  const tipsImages = (
    <TipsFormat
      title={`Quels formats et résolution d'images ?`}
      items={[
        {
          title: 'Taille de fichier et format requis',
          content: [
            `La taille de fichier maximale autorisée est de ${
              googleMaxSize / 1000000
            } Mo.`,
            'Au moins une image au format paysage, une au format carré et un logo sont requis.'
          ]
        },
        {
          title: `Format paysage (${googleRectangleRatio}:1)`,
          content: [
            'Taille recommandée : 1200 x 628',
            `Taille minimale : ${googleRectangleMinWidth} x ${googleRectangleMinHeight}`,
            `Ratio imposé : ${googleRectangleRatio} x 1`
          ]
        },
        {
          title: `Format carré (${googleSquareRatio}:1)`,
          content: [
            'Taille recommandée : 1200 x 1200',
            `Taille minimale : ${googleSquareMinWidth} x ${googleSquareMinHeight}`,
            `Ratio imposé : ${googleSquareRatio} x 1`
          ]
        },
        {
          title: `Logo (${googleLogoRatio}:1)`,
          content: [
            'Taille recommandée : 1200 x 1200',
            `Taille minimale : ${googleLogoMinWidth} x ${googleLogoMinHeight}`,
            `Ratio imposé : ${googleLogoRatio} x 1`
          ]
        }
      ]}
    />
  )

  const marketingImages: Partial<ImageAdFiles> = {
    marketingImage1: formData.marketingImage1,
    marketingImage2: formData.marketingImage2,
    marketingImage3: formData.marketingImage3
  }
  const marketingImagesKeys = Object.keys(
    marketingImages
  ) as (keyof typeof marketingImages)[]

  const marketingImagesSelectionPanel: PanelType = {
    title: 'Sélection des images paysages',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez jusqu'à 3 images (une obligatoire).`,
    sections: [
      {
        title: 'Formats paysages*',
        description: `Choisissez des images au format rectangulaire ${googleRectangleRatio}:1.`,
        content: (
          <>
            <ImagesSelectionContainer>
              {marketingImagesKeys.map(
                (img, index) =>
                  (index === 0 ||
                    marketingImages[marketingImagesKeys[index - 1]] ||
                    Object.values(marketingImages).find((_, i) => {
                      return (
                        i >= index && marketingImages[marketingImagesKeys[i]]
                      )
                    })) && (
                    <InputFile
                      key={index}
                      uniqueKey={`marketingImage${index + 1}`}
                      selectedFile={formData[marketingImagesKeys[index]]}
                      fileUrl={imageUrls[marketingImagesKeys[index]]}
                      handleChange={(e) => {
                        handleImageChange(marketingImagesKeys[index], e)
                      }}
                      handleDelete={() => {
                        handleImageDelete(marketingImagesKeys[index])
                      }}
                      error={
                        hasBeenSubmitted
                          ? imageErrors[marketingImagesKeys[index]]
                          : imageErrors[marketingImagesKeys[index]] !==
                            GoogleFormImageAdError.IMAGE_MISSING
                          ? imageErrors[marketingImagesKeys[index]]
                          : ''
                      }
                    />
                  )
              )}
            </ImagesSelectionContainer>
            <PictureWeightSpanContainer>
              <PictureWeightSpan>
                Poids cible des images : 100 ko
              </PictureWeightSpan>
            </PictureWeightSpanContainer>
          </>
        )
      }
    ]
  }

  const squareMarketingImages: Partial<ImageAdFiles> = {
    squareMarketingImage1: formData.squareMarketingImage1,
    squareMarketingImage2: formData.squareMarketingImage2,
    squareMarketingImage3: formData.squareMarketingImage3
  }
  const squareMarketingImagesKeys = Object.keys(
    squareMarketingImages
  ) as (keyof typeof squareMarketingImages)[]

  const squareMarketingImagesSelectionPanel: PanelType = {
    title: 'Sélection des images carrées',
    disabled: false,
    isDropdown: false,
    description: `Ajoutez jusqu'à 3 images (une obligatoire).`,
    sections: [
      {
        title: 'Formats carrés*',
        description: `Choisissez des images au format ${googleSquareRatio}:1.`,
        content: (
          <>
            <ImagesSelectionContainer>
              {squareMarketingImagesKeys.map(
                (img, index) =>
                  (index === 0 ||
                    squareMarketingImages[
                      squareMarketingImagesKeys[index - 1]
                    ] ||
                    Object.values(squareMarketingImages).find((_, i) => {
                      return (
                        i >= index &&
                        squareMarketingImages[squareMarketingImagesKeys[i]]
                      )
                    })) && (
                    <InputFile
                      key={index}
                      uniqueKey={`squareMarketingImage${index + 1}`}
                      selectedFile={formData[squareMarketingImagesKeys[index]]}
                      fileUrl={imageUrls[squareMarketingImagesKeys[index]]}
                      handleChange={(e) => {
                        handleImageChange(squareMarketingImagesKeys[index], e)
                      }}
                      handleDelete={() => {
                        handleImageDelete(squareMarketingImagesKeys[index])
                      }}
                      error={
                        hasBeenSubmitted
                          ? imageErrors[squareMarketingImagesKeys[index]]
                          : imageErrors[squareMarketingImagesKeys[index]] !==
                            GoogleFormImageAdError.IMAGE_MISSING
                          ? imageErrors[squareMarketingImagesKeys[index]]
                          : ''
                      }
                    />
                  )
              )}
            </ImagesSelectionContainer>
            <PictureWeightSpanContainer>
              <PictureWeightSpan>
                Poids cible des images : 100 ko
              </PictureWeightSpan>
            </PictureWeightSpanContainer>
          </>
        )
      }
    ]
  }

  const logoSelectionPanel: PanelType = {
    title: 'Sélection du logo',
    disabled: false,
    isDropdown: false,
    description: 'Ajoutez votre logo (obligatoire).',
    sections: [
      {
        title: 'Logo*',
        description: `Choisissez votre logo au format ${googleLogoRatio}:1.`,
        content: (
          <>
            <ImagesSelectionContainer>
              <InputFile
                uniqueKey="logoInput"
                handleChange={(e) => {
                  handleImageChange('logo', e)
                }}
                handleDelete={() => {
                  handleImageDelete('logo')
                }}
                selectedFile={formData.logo}
                fileUrl={imageUrls.logo}
                error={
                  hasBeenSubmitted
                    ? imageErrors.logo
                    : imageErrors.logo !== GoogleFormImageAdError.IMAGE_MISSING
                    ? imageErrors.logo
                    : ''
                }
              />
            </ImagesSelectionContainer>
            <PictureWeightSpanContainer>
              <PictureWeightSpan>
                Poids cible des images : 100 ko
              </PictureWeightSpan>
            </PictureWeightSpanContainer>
          </>
        )
      }
    ]
  }

  const previewPanel: PanelType = {
    title: 'Aperçu de votre publicité',
    disabled: false,
    isDropdown: false,
    sections: [
      {
        description: 'Aperçu',
        content: isPreviewDisplayed ? (
          <PreviewContainer>
            <CarouselPreview
              selectedPreview={selectedPreview}
              setSelectedPreview={setSelectedPreview}
              handleOnClickNextPreview={handleOnClickNextPreview}
              title={randomTitle}
              description={randomDescription}
              businessName={formData.businessName}
              adImageSquare={previewImages.adImageSquare}
              adImageRectangle={previewImages.adImageRectangle}
              logoImage={previewImages.logo}
              buttonColor={fakeColor}
            />
          </PreviewContainer>
        ) : (
          <DefaultText style={previewMessageStyle}>
            {`Veuillez ajouter les images requises pour voir l'aperçu.`}
          </DefaultText>
        )
      }
    ]
  }

  const panels: (JSX.Element | PanelType)[] = [
    namePanel,
    tipsDropdown,
    urlPanel,
    longHeadlinePanel,
    headlinesPanel,
    descriptionsPanel,
    tipsImages,
    marketingImagesSelectionPanel,
    squareMarketingImagesSelectionPanel,
    logoSelectionPanel,
    previewPanel
  ]

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isSelfEditor ?? false}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`ETAPE 3/3 – Ma nouvelle publicité image`}
        subTitle={`Créez votre publicité image sur Google Ads`}
        panels={panels}
        validation={{
          action: () => {
            if (checkIfEmptyErrors(errors, imageErrors)) {
              handleSubmit()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled:
            hasBeenSubmitted && !checkIfEmptyErrors(errors, imageErrors),
          wording: 'Enregistrer et continuer',
          isLoading: isSubmitLoading
        }}
        errors={
          hasBeenSubmitted
            ? {
                errors: [
                  [
                    errorFromApi,
                    errors.name,
                    errors.businessName,
                    errors.url,
                    errors.longHeadline,
                    errors.headline1,
                    errors.headline2,
                    errors.headline3,
                    errors.description1,
                    errors.description2,
                    errors.description3,
                    imageErrors.logo,
                    imageErrors.marketingImage1,
                    imageErrors.squareMarketingImage1
                  ].filter((e) => e)[0]
                ]
              }
            : undefined
        }
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
      />
      {isCancelModalOpen && (
        <ConfirmationModal
          title="La publicité est en cours de création"
          textConfirm="Oui, annuler"
          textReject="Non, continuer"
          onClickReject={handleCloseCancelModal}
          onClickConfirm={handleCancelConfirm}
          onClose={handleCloseCancelModal}
        >
          <ModalContent>
            <DefaultText>
              {'Êtes-vous sûr de vouloir annuler la création de la publicité ?'}
            </DefaultText>
            <DefaultText>
              {'Toutes les informations saisies seront perdues.'}
            </DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default SelfEditorGoogleImageAdCreation
