import React from 'react'

import {
  DivContainer,
  Description,
  DivContent,
  ImageTop,
  Logo,
  Title,
  DivButtons
} from './styles'
import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import Button from '../../../../Button'

type SmartphonePreview1Props = {
  adImageSquare: string
  logoImage: string
  title: string
  description: string
  buttonColor: string
}

const SmartphonePreview1 = ({
  adImageSquare,
  logoImage,
  title,
  description,
  buttonColor
}: SmartphonePreview1Props) => {
  return (
    <DisplayAdInfoTemplate bgBlur={adImageSquare} width="320px">
      <DivContainer>
        <ImageTop src={adImageSquare} alt="image carrée" loading="lazy" />
        <DivContent>
          <Logo src={logoImage} alt="logo" loading="lazy" />
          <Title>{title}</Title>
          <Description>{description}</Description>
          <DivButtons>
            <Button type="secondary" width="115px">
              Fermer
            </Button>
            <Button
              type="primary"
              width="115px"
              style={{ backgroundColor: buttonColor }}
            >
              Ouvrir
            </Button>
          </DivButtons>
        </DivContent>
      </DivContainer>
    </DisplayAdInfoTemplate>
  )
}

export default SmartphonePreview1
