import axios from 'axios'

import { backendURL } from '../../../constants/urls'

export type HandleLogoutResult = {
  success?: boolean
}

async function handleLogout(): Promise<HandleLogoutResult> {
  const res = await axios.post(
    `${backendURL}/logout`,
    {},
    {
      withCredentials: true
    }
  )
  return res.data
}

export default handleLogout
