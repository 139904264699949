import React from 'react'

import DefaultText from '../../../components/DefaultText'
import { AdminUserDataType } from '../../../types/mediaPlan/adminUserData'
import { InputError } from '../../../components/Input/Styles'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { DivList, buttonConfirmStyle, messageStyle } from './styles'
import LabelledItemsList from '../../../components/LabelledItemsList'

type ModalValidationProps = {
  handleCloseModal: () => void
  handleConfirm: () => void
  mediaPlanName: string
  companyName: string
  adminUserData: AdminUserDataType
  isLoading: boolean
  error: string
  message: string
}

const ModalValidation = ({
  handleCloseModal,
  handleConfirm,
  mediaPlanName,
  companyName,
  adminUserData,
  isLoading,
  error,
  message
}: ModalValidationProps) => {
  const rejectText = message ? 'Fermer' : 'Annuler'

  return (
    <ConfirmationModal
      textConfirm={`Envoyer le mail de validation`}
      textReject={rejectText}
      onClickReject={handleCloseModal}
      onClickConfirm={handleConfirm}
      isConfirmLoading={isLoading}
      title={`Demande de validation du plan média « ${mediaPlanName} »`}
      onClose={handleCloseModal}
      styleButtonConfirm={buttonConfirmStyle}
      style={{ maxWidth: '420px' }}
      confirmHidden={!!message}
    >
      <DefaultText>
        {`Votre proposition de plan média « ${mediaPlanName} » pour ${companyName} sera envoyé au responsable de la validation.`}
      </DefaultText>
      <DivList>
        <LabelledItemsList
          items={[
            {
              label: 'Entreprise',
              value: companyName
            },
            {
              label: 'Responsable',
              value: `${adminUserData.firstName} ${adminUserData.lastName}`
            },
            {
              label: 'Adresse e-mail',
              value: adminUserData.email
            }
          ]}
        />
      </DivList>
      {error && <InputError style={messageStyle}>{error}</InputError>}
      {message && (
        <DefaultText size="medium" style={messageStyle} bold>
          {message}
        </DefaultText>
      )}
    </ConfirmationModal>
  )
}

export default ModalValidation
