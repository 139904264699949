import { checkIfDate } from '../../../helpers/nativeTypes'

export type GetCampaignReportingBySectionOutputType =
  | GetCampaignAdsReportingOutputType
  | GetCampaignDevicesReportingOutputType
  | GetCampaignGlobalReportingOutputType
  | GetCampaignKeywordsReportingOutputType
  | GetCampaignOverallReportingOutputType
  | GetCampaignImpactReportingOutputType
  | GetCampaignHighlightReportingOutputType
  | GetCampaignDownloadReportingOutputType
  | GetCampaignAudienceAndLocationReportingOutputType

export type GetCampaignAdsReportingOutputType = {
  ads: {
    adName: string
    clicks: number
    impressions: number
  }[]
}

export type GetCampaignAudiencesReportingRowType = {
  gender: string
  age: string
  value: number
}

export type GetCampaignAudiencesReportingOutputType = {
  clicks: GetCampaignAudiencesReportingRowType[]
  impressions: GetCampaignAudiencesReportingRowType[]
  ctr: GetCampaignAudiencesReportingRowType[]
}

export type GetCampaignLocationReportingRowType = {
  campaignId: string
  location: string
  totalClicks: number
  totalImpressions: number
}

export type GetCampaignAudienceAndLocationReportingOutputType = {
  audiences: GetCampaignAudiencesReportingOutputType
  locations: GetCampaignLocationReportingRowType[]
}

export type GetCampaignDevicesReportingRowType = {
  clicks: number
  impressions: number
  cost: number
  co2: number
}

export type GetCampaignDevicesReportingOutputType = {
  smartphone: GetCampaignDevicesReportingRowType
  tablet: GetCampaignDevicesReportingRowType
  desktop: GetCampaignDevicesReportingRowType
  tv: GetCampaignDevicesReportingRowType
}

export type GetCampaignImpactReportingOutputType = {
  genderAndAge: {
    clicks: {
      age: string
      gender: string
      value: number
    }[]
    impressions: {
      age: string
      gender: string
      value: number
    }[]
    ctr?: {
      age: string
      gender: string
      value: number
    }[]
  }
  devices: {
    smartphone: {
      clicks: number
      impressions: number
      cost: number
      co2: number
    }
    desktop: {
      clicks: number
      impressions: number
      cost: number
      co2: number
    }
    tablet: {
      clicks: number
      impressions: number
      cost: number
      co2: number
    }
    tv: {
      clicks: number
      impressions: number
      cost: number
      co2: number
    }
  }
  ads: {
    adName: string
    clicks: number
    impressions: number
  }[]
}

export type GetCampaignGlobalReportingRowType = {
  day: string
  value: number
}

export type GetCampaignGlobalReportingOutputType = {
  clicks: GetCampaignGlobalReportingRowType[]
  impressions: GetCampaignGlobalReportingRowType[]
  ctr: GetCampaignGlobalReportingRowType[]
  cost: GetCampaignGlobalReportingRowType[]
  co2: GetCampaignGlobalReportingRowType[]
}

export type GetCampaignKeywordsReportingOutputType = {
  keywords: {
    keyword: string
    clicks: number
    impressions: number
  }[]
  searchTerms: {
    searchTerm: string
    clicks: number
    impressions: number
  }[]
}

export type GetCampaignOverallReportingOutputType = {
  kpi: GetCampaignGlobalReportingOutputType
  devices: {
    smartphone: {
      clicks: number
      impressions: number
      cost: number
      co2: number
    }
    tablet: { clicks: number; impressions: number; cost: number; co2: number }
    desktop: { clicks: number; impressions: number; cost: number; co2: number }
    tv: { clicks: number; impressions: number; cost: number; co2: number }
  }
  genderAge: {
    clicks: GetCampaignAudiencesReportingRowType[]
    impressions: GetCampaignAudiencesReportingRowType[]
  }
}

export type GetCampaignHighlightReportingOutputType = {
  clicks: number
  impressions: number
  co2: number
  cost: number
  impact: number
}

export type GetCampaignDownloadReportingOutputType = {
  date: string
  clicks: number
  impressions: number
  cost: number
  co2: number
  impact: number
  co2perEuro: number
}[]

export const checkIfGetCampaignAudiencesReportingRowType = (
  value: any
): value is GetCampaignAudiencesReportingRowType => {
  return (
    value &&
    value.gender &&
    typeof value.gender === 'string' &&
    value.age &&
    typeof value.age === 'string' &&
    value.value !== undefined &&
    typeof value.value === 'number'
  )
}

export const checkIfAudienceLocationReportingRowType = (
  value: any
): value is GetCampaignLocationReportingRowType => {
  return (
    value &&
    typeof value.campaignId === 'string' &&
    value.location &&
    typeof value.location === 'string' &&
    value.totalClicks !== undefined &&
    typeof value.totalClicks === 'number' &&
    value.totalImpressions !== undefined &&
    typeof value.totalImpressions === 'number'
  )
}

export const checkIfGetCampaignDevicesReportingRowType = (
  value: any,
  withCo2 = true
): value is GetCampaignDevicesReportingRowType => {
  if (withCo2) {
    return (
      value &&
      (value.clicks === undefined ||
        (value.clicks !== undefined && typeof value.clicks === 'number')) &&
      (value.impressions === undefined ||
        (value.impressions !== undefined &&
          typeof value.impressions === 'number')) &&
      (value.cost === undefined ||
        (value.cost !== undefined && typeof value.cost === 'number')) &&
      (value.co2 === undefined ||
        (value.co2 !== undefined && typeof value.co2 === 'number'))
    )
  }

  return (
    value &&
    (value.clicks === undefined ||
      (value.clicks !== undefined && typeof value.clicks === 'number')) &&
    (value.impressions === undefined ||
      (value.impressions !== undefined &&
        typeof value.impressions === 'number')) &&
    (value.cost === undefined ||
      (value.cost !== undefined && typeof value.cost === 'number'))
  )
}

export const checkIfGetCampaignGlobalReportingRowType = (
  value: any
): value is GetCampaignGlobalReportingRowType => {
  return (
    value &&
    value.day &&
    checkIfDate(value.day) &&
    value.value !== undefined &&
    typeof value.value === 'number'
  )
}

export const checkIfGetCampaignImpactReportingAgeRowType = (val: any) => {
  return (
    val &&
    val.gender !== undefined &&
    typeof val.gender === 'string' &&
    val.age !== undefined &&
    typeof val.age === 'string' &&
    val.value !== undefined &&
    typeof val.value === 'number'
  )
}

export const checkIfCampaignImpactReportingAgeRowTypeIsEmpty = (value: any) => {
  return value.gender === '' && value.age === '' && value.value === 0
}

export const checkIfGetCampaignImpactReportingAge = (val: any) => {
  return (
    val &&
    val.clicks &&
    Array.isArray(val.clicks) &&
    val.clicks.every((c: any) =>
      checkIfGetCampaignImpactReportingAgeRowType(c)
    ) &&
    val.impressions &&
    Array.isArray(val.impressions) &&
    val.impressions.every((imp: any) =>
      checkIfGetCampaignImpactReportingAgeRowType(imp)
    ) &&
    (val.ctr === undefined ||
      (Array.isArray(val.ctr) &&
        val.ctr.every((ctr: any) =>
          checkIfGetCampaignImpactReportingAgeRowType(ctr)
        )))
  )
}

export const checkIfCampaignImpactReportingAgeIsEmpty = (value: any) => {
  return (
    value &&
    value.clicks &&
    Array.isArray(value.clicks) &&
    value.clicks.every((c: any) =>
      checkIfCampaignImpactReportingAgeRowTypeIsEmpty(c)
    ) &&
    value.impressions &&
    Array.isArray(value.impressions) &&
    value.impressions.every((imp: any) =>
      checkIfCampaignImpactReportingAgeRowTypeIsEmpty(imp)
    ) &&
    (value.ctr === undefined ||
      (Array.isArray(value.ctr) &&
        value.ctr.every((ctr: any) =>
          checkIfCampaignImpactReportingAgeRowTypeIsEmpty(ctr)
        )))
  )
}

export const checkIfGetCampaignImpactReportingDevicesItem = (val: any) => {
  return (
    val &&
    val.clicks !== undefined &&
    typeof val.clicks === 'number' &&
    val.impressions !== undefined &&
    typeof val.impressions === 'number' &&
    val.cost !== undefined &&
    typeof val.cost === 'number' &&
    val.co2 !== undefined &&
    typeof val.co2 === 'number'
  )
}

export const checkIfCampaignImpactReportingDevicesItemIsEmpty = (val: any) => {
  return (
    val.clicks === 0 && val.impressions === 0 && val.cost === 0 && val.co2 === 0
  )
}

export const checkIfGetCampaignImpactReportingDevices = (val: any) => {
  return (
    val &&
    val.smartphone !== undefined &&
    checkIfGetCampaignImpactReportingDevicesItem(val.smartphone) &&
    val.desktop !== undefined &&
    checkIfGetCampaignImpactReportingDevicesItem(val.desktop) &&
    val.tablet !== undefined &&
    checkIfGetCampaignImpactReportingDevicesItem(val.tablet) &&
    val.tv !== undefined &&
    checkIfGetCampaignImpactReportingDevicesItem(val.tv)
  )
}

export const checkIfCampaignImpactReportingDevicesIsEmpty = (val: any) => {
  return (
    val &&
    val.smartphone !== undefined &&
    checkIfCampaignImpactReportingDevicesItemIsEmpty(val.smartphone) &&
    val.desktop !== undefined &&
    checkIfCampaignImpactReportingDevicesItemIsEmpty(val.desktop) &&
    val.tablet !== undefined &&
    checkIfCampaignImpactReportingDevicesItemIsEmpty(val.tablet) &&
    val.tv !== undefined &&
    checkIfCampaignImpactReportingDevicesItemIsEmpty(val.tv)
  )
}

export const checkIfGetCampaignImpactReportingAdsRow = (val: any) => {
  return (
    val !== undefined &&
    val.adName !== undefined &&
    typeof val.adName === 'string' &&
    val.clicks !== undefined &&
    typeof val.clicks === 'number' &&
    val.impressions !== undefined &&
    typeof val.impressions === 'number'
  )
}

export const checkIfCampaignImpactReportingAdsRowIsEmpty = (val: any) => {
  return val.adName === '' && val.clicks === 0 && val.impressions === 0
}

export const checkIfGetCampaignImpactReportingAds = (val: any) => {
  return (
    val !== undefined &&
    Array.isArray(val) &&
    val.every((el: any) => checkIfGetCampaignImpactReportingAdsRow(el))
  )
}

export const checkIfCampaignImpactReportingAdsIsEmpty = (val: any) => {
  return (
    val !== undefined &&
    Array.isArray(val) &&
    val.every((el: any) => checkIfCampaignImpactReportingAdsRowIsEmpty(el))
  )
}

export const checkIfGetCampaignImpactReporting = (
  val: any
): val is GetCampaignImpactReportingOutputType => {
  return (
    val &&
    val.genderAndAge !== undefined &&
    checkIfGetCampaignImpactReportingAge(val.genderAndAge) &&
    val.devices !== undefined &&
    checkIfGetCampaignImpactReportingDevices(val.devices) &&
    val.ads !== undefined &&
    checkIfGetCampaignImpactReportingAds(val.ads)
  )
}

export const checkIfCampaignImpactReportingIsEmpty = (
  val: any
): val is GetCampaignImpactReportingOutputType => {
  return (
    val &&
    val.genderAndAge !== undefined &&
    checkIfCampaignImpactReportingAgeIsEmpty(val.genderAndAge) &&
    val.devices !== undefined &&
    checkIfCampaignImpactReportingDevicesIsEmpty(val.devices) &&
    val.ads !== undefined &&
    checkIfCampaignImpactReportingAdsIsEmpty(val.ads)
  )
}

export const checkIfGetCampaignLocationReportingRowType = (
  value: any
): value is GetCampaignLocationReportingRowType => {
  return (
    value &&
    typeof value.location === 'string' &&
    typeof value.totalClicks === 'number' &&
    typeof value.totalImpressions === 'number'
  )
}

export const emptyCampaignImpact = {
  devices: {
    smartphone: {
      clicks: 0,
      impressions: 0,
      cost: 0,
      co2: 0
    },
    desktop: {
      clicks: 0,
      impressions: 0,
      cost: 0,
      co2: 0
    },
    tablet: {
      clicks: 0,
      impressions: 0,
      cost: 0,
      co2: 0
    },
    tv: {
      clicks: 0,
      impressions: 0,
      cost: 0,
      co2: 0
    }
  },
  genderAndAge: {
    clicks: [
      {
        age: '',
        gender: '',
        value: 0
      }
    ],
    impressions: [
      {
        age: '',
        gender: '',
        value: 0
      }
    ],
    ctr: [
      {
        age: '',
        gender: '',
        value: 0
      }
    ]
  },
  ads: [
    {
      adName: '',
      clicks: 0,
      impressions: 0
    }
  ]
}
