enum OldGoalsEnum {
  AWARENESS = 'Notoriété',
  CONSIDERATION = 'Considération',
  LEAD = 'Prospection',
  TRAFFIC = 'Traffic',
  SALES = 'Vente',
  UNKNOWN = 'Je ne sais pas'
}

export enum MediaPlanGoal {
  LEAD = 'Prospection',
  TRAFFIC = 'Traffic',
  SALES = 'Vente',
  UNKNOWN = 'Je ne sais pas'
}

export enum MediaPlanGoalsDescription {
  LEAD = 'Attirez de nouveaux clients intéressés par votre activité',
  TRAFFIC = 'Augmentez le nombre de visites sur votre site internet',
  SALES = 'Générez des ventes',
  UNKNOWN = 'Ne pas fixer d’objectif précis'
}

export enum GoalsEnum {
  AWARENESS = 'Notoriété',
  LEAD = 'Prospection',
  TRAFFIC = 'Traffic',
  SALES = 'Vente',
  UNKNOWN = 'Je ne sais pas'
}

export const checkIfGoalsEnum = (value: any): value is GoalsEnum => {
  return (
    typeof value === 'string' &&
    (Object.values(GoalsEnum) as string[]).includes(value)
  )
}

export default OldGoalsEnum
