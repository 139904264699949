import styled from '@emotion/styled'

import { YETIC_FONT_FAMILY } from '../../../constants/fontFamily'
import { CSSProperties } from 'react'
import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const Container = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${YETIC_FONT_FAMILY};
`

export const NamesDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`

export const SiretContainer = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
`

export const ModalContent = styled.div`
  font-family: ${YETIC_FONT_FAMILY};
  text-align: center;
`

export const siretButtonStyle: CSSProperties = {
  borderRadius: '10px',
  height: '40px',
  marginTop: '25px',
  width: '80px'
}

export const RadioButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`

export const customerTipStyle: CSSProperties = {
  marginBottom: Margin.m7
}

export const CancelModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${Padding.p3} 0;
`
