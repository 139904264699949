import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'

export const CardContainer = styled.div<{ width?: string }>`
  border-radius: 10px;
  overflow: hidden;
  width: ${({ width }) => width ?? '100%'};
  margin: 0 auto ${Margin.m6};
  border: 1px solid ${Colors.YETIC_GREY_DARK};
`

export const CardHeader = styled.div<{ background?: string; color?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Padding.p5} ${Padding.p6} ${Padding.p5} ${Padding.p6};
  color: ${({ color }) => color ?? Colors.NEW_YETIC_DARK};
  background-color: ${({ background }) =>
    background ?? Colors.NEW_YETIC_GREY_BACKGROUND};
`

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.m4};
`

export const CardSeeMore = styled.div`
  color: ${Colors.YETIC_WHITE};
  cursor: pointer;
  text-decoration: underline;
`

export const CardContent = styled.div`
  padding: ${Padding.p3};
`

export const SyncStateWithErrorContainer = styled.div`
  position: relative;
  width: fit-content;
`

export const syncErrorIconStyle: CSSProperties = {
  color: 'black',
  fontSize: '10px',
  fontWeight: 'bold',
  backgroundColor: Colors.NEW_YETIC_WARNING,
  border: 'none',
  width: '12px',
  height: '12px',
  position: 'absolute',
  bottom: '75%',
  left: '80%'
}
