import styled from '@emotion/styled'

import Colors from '../../../../../constants/colors'
import Padding from '../../../../../constants/padding'

export const ScoreComparisonContainer = styled.div<{ isPositive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: ${Padding.p2} ${Padding.p3} ${Padding.p1};
  border-radius: 30px;
  background-color: ${({ isPositive }) =>
    isPositive ? Colors.NEW_YETIC_RED_LIGHT : Colors.NEW_YETIC_GREEN_LIGHT};
  transform: scale(0.75) translateX(15%);
`
