import { backendURL } from '../../../constants/urls'
import {
  CustomerDetailsType,
  checkIfCustomerDetails
} from '../../../types/customer'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : manage error
export type GetCustomerOutputType = {
  customer: CustomerDetailsType
}

export type GetCustomerInput = {
  customerId: string
  skip?: number
}

export const getCustomerById = async ({
  customerId,
  skip
}: GetCustomerInput): Promise<GetCustomerOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/customers/${customerId}${
        skip ? `?skip=${skip * 5}` : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCustomerDetails(response.data)) {
        return {
          customer: response.data
        }
      }
    }
    return null
  } catch (_) {
    return null
  }
}
