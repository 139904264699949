import React, { CSSProperties, useState } from 'react'

// import TableDropdowns from './TableDropdowns'
// import TableContent from './TableContent'
import {
  TableContainer,
  TableHeader,
  TableHeaderItem,
  TableRowsContainer,
  TableRow,
  RowContent,
  RowItem,
  IconContainer,
  actionButtonSize,
  NoResult,
  tableHeaderTextStyle,
  sortIconStyle,
  Container,
  Title,
  DownloadContainer,
  downloadIcon
} from './styles'
import Pagination from '../Pagination'
import ActionsButton, {
  ActionItem,
  checkIfActionItemArray
} from '../ActionsButton'
import DefaultText from '../DefaultText'
import { AlignEnum, SortEnum } from '../../types/common'
import SortIcon from '../Icon/sort'
import { formatTableValue } from './helpers/formatValue'
import { DownloadIcon } from '../Icon/download'
import { generateCsv } from '../../helpers/generateCsv'

type SortStatus = {
  name: string
  order?: SortEnum
}

export type ElementItem = {
  id?: string
  onClick?: (id: string) => void
  actions?: ActionItem[]
} & Record<
  string,
  | number
  | string
  | JSX.Element
  | ActionItem[]
  | ((id: string) => void)
  | undefined
>

export type ColumnItem = {
  name: string
  label: string
  unit?: string
  isSortable?: boolean
  isFilterable?: boolean
  width?: string
  bold?: boolean
  align?: AlignEnum
}

type TableProps = {
  title?: string
  columns: ColumnItem[]
  elements: ElementItem[]
  sort?: SortStatus
  download?: boolean
  pagination?: {
    totalItems: number
    itemsByPage: number
    currentPage: number
    handleChangePage: (page: number) => void
  }
}

const Table = ({
  title,
  columns,
  elements,
  sort,
  pagination,
  download
}: TableProps) => {
  const [sortStatus, setSortStatus] = useState<SortStatus | null>(sort ?? null)

  let sortedElements = elements

  if (sortStatus) {
    sortedElements = sortedElements.sort((a, b) => {
      const valueA = a[sortStatus.name]
      const valueB = b[sortStatus.name]

      if (valueA === undefined || valueB === undefined) {
        return 0
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return sortStatus.order === SortEnum.ASC
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA)
      }

      if (valueA < valueB) {
        return sortStatus.order === SortEnum.ASC ? -1 : 1
      }

      if (valueA > valueB) {
        return sortStatus.order === SortEnum.ASC ? 1 : -1
      }

      return 0
    })
  }

  const paginatedSortedData = pagination
    ? sortedElements.slice(
        (pagination.currentPage - 1) * pagination.itemsByPage,
        pagination.currentPage * pagination.itemsByPage
      )
    : sortedElements

  const defaultWidth = `${100 / columns.length}%`

  const handleSortData = (name: string) => {
    const order = sortStatus?.name === name ? sortStatus.order : undefined

    const updatedSortStatus = {
      name,
      order: order === SortEnum.ASC ? SortEnum.DESC : SortEnum.ASC
    }

    setSortStatus(updatedSortStatus)
  }

  return (
    <Container>
      {title && (
        <Title>
          <DefaultText size="big" bold>
            {title}
          </DefaultText>
          {download && (
            <DownloadContainer
              onClick={() => {
                generateCsv(
                  elements,
                  columns.map((column) => column.name),
                  'table_data'
                )
              }}
            >
              <DownloadIcon style={downloadIcon} />
            </DownloadContainer>
          )}
        </Title>
      )}
      <TableContainer>
        <TableHeader>
          {columns.map((column, index) => {
            return (
              <TableHeaderItem
                key={index}
                width={column.width ?? defaultWidth}
                align={column.align}
              >
                <DefaultText
                  style={tableHeaderTextStyle}
                  hoverable
                  bold
                  onClick={() => {
                    handleSortData(column.name)
                  }}
                >
                  {column.label}
                </DefaultText>
                {column.isSortable &&
                  (sortStatus?.name === column.name &&
                  sortStatus.order === SortEnum.ASC ? (
                    <SortIcon
                      style={sortIconStyle}
                      handleClick={() => {
                        handleSortData(column.name)
                      }}
                    />
                  ) : sortStatus?.name === column.name &&
                    sortStatus.order === SortEnum.DESC ? (
                    <SortIcon
                      style={{ ...sortIconStyle, transform: 'rotateX(180deg)' }}
                      handleClick={() => {
                        handleSortData(column.name)
                      }}
                    />
                  ) : null)}
              </TableHeaderItem>
            )
          })}
        </TableHeader>
        <TableRowsContainer>
          {paginatedSortedData.length === 0 ? (
            <NoResult>Aucun résultat</NoResult>
          ) : (
            paginatedSortedData.map((row, rowIndex) => {
              return (
                <TableRow key={rowIndex} isClickable={!!row.onClick}>
                  <RowContent>
                    {Object.keys(row).map((key, index) => {
                      const currentValue = row[key]
                      if (
                        key === 'id' ||
                        checkIfActionItemArray(currentValue) ||
                        typeof currentValue === 'function'
                      ) {
                        return null
                      }

                      return (
                        <RowItem
                          key={index}
                          index={index}
                          bold={
                            columns.find((column) => column.name === key)
                              ?.bold ?? false
                          }
                          width={
                            columns.find((column) => column.name === key)
                              ?.width ?? defaultWidth
                          }
                          onClick={() => {
                            if (row.id && row.onClick) {
                              row.onClick(row.id)
                            }
                          }}
                          align={
                            columns.find((column) => column.name === key)?.align
                          }
                        >
                          {formatTableValue(
                            currentValue,
                            columns.find((column) => column.name === key)?.unit
                          )}
                        </RowItem>
                      )
                    })}
                    {row.actions && row.actions.length > 0 ? (
                      <IconContainer>
                        <ActionsButton
                          actions={row.actions}
                          size={actionButtonSize}
                        />
                      </IconContainer>
                    ) : null}
                  </RowContent>
                </TableRow>
              )
            })
          )}
        </TableRowsContainer>
        {pagination && (
          <Pagination
            totalItems={pagination.totalItems}
            itemsPerPage={pagination.itemsByPage}
            onChangePage={pagination.handleChangePage}
          />
        )}
      </TableContainer>
    </Container>
  )
}

export default Table
