import React from 'react'

import { IconType } from '../../types/icon'

const AddPicto = ({ height = '15px', width = '15px', style }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 14 14"
    >
      <g
        id="Groupe_12998"
        data-name="Groupe 12998"
        transform="translate(-277 -241)"
      >
        <line
          id="Ligne_105"
          data-name="Ligne 105"
          y2="13"
          transform="translate(284 241.5)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Ligne_106"
          data-name="Ligne 106"
          x1="13"
          transform="translate(277.5 248)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default AddPicto
