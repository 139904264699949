export enum StrategyEnum {
  MEDIA_PLAN = 'MEDIA_PLAN',
  CAMPAIGN = 'CAMPAIGN',
  AD = 'AD'
}

export const StrategyTranslation = {
  [StrategyEnum.MEDIA_PLAN]: 'Plan média',
  [StrategyEnum.CAMPAIGN]: 'Campagne',
  [StrategyEnum.AD]: 'Annonce'
}

export const SelfEditorStrategyTranslation = {
  [StrategyEnum.MEDIA_PLAN]: 'Plan publicitaire',
  [StrategyEnum.AD]: 'Publicité'
}

export const getStrategyKey = (
  strategyTranslation: string
): StrategyEnum | null => {
  const strategy = Object.keys(StrategyEnum).find(
    (key) => StrategyEnum[key as StrategyEnum] === strategyTranslation
  )
  if (strategy) {
    return strategy as StrategyEnum
  }
  return null
}

export const getSubStrategy = (strategy: StrategyEnum): StrategyEnum | null => {
  switch (strategy) {
    case StrategyEnum.MEDIA_PLAN:
      return StrategyEnum.CAMPAIGN
    case StrategyEnum.CAMPAIGN:
      return StrategyEnum.AD
    case StrategyEnum.AD:
      return null
    default:
      return null
  }
}
