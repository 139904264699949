import React from 'react'
import KpiCustomersPicto from '../../../../../components/Icon/kpiCustomersPicto'

import { Container } from './styles'

export type CustomerProps = {
  value: number
}

const Customer = ({ value }: CustomerProps) => {
  return (
    <>
      <KpiCustomersPicto />
      <Container>{value}</Container>
    </>
  )
}

export default Customer
