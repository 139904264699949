import React from 'react'

import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import {
  AdPageContainer,
  Circle,
  NavBar,
  NavBarSearchBar,
  OptionCircle,
  Paragraph,
  SemiSentence,
  Sentence,
  Tab,
  TabBar,
  TabFavicon,
  TabText,
  Website,
  WebsiteContent,
  WebsiteImage,
  WebsiteNavbar,
  WebsiteTitle,
  WebsiteWindow,
  Word,
  DivContainer,
  DivContent,
  DivButton,
  ImageTop,
  Title,
  Logo,
  Description,
  ButtonArrow
} from './styles'
import { ArrowRight } from '../../../../Icon'
import Colors from '../../../../../constants/colors'

type WebpagePreview4Props = {
  title: string
  description: string
  logoImage: string
  adImageRectangle: string
}

const WebpagePreview4 = ({
  title,
  description,
  logoImage,
  adImageRectangle
}: WebpagePreview4Props) => {
  return (
    <WebsiteWindow>
      <TabBar>
        <Circle />
        <Circle />
        <Circle />
        <Tab>
          <TabFavicon />
          <TabText />
        </Tab>
      </TabBar>
      <NavBar>
        <NavBarSearchBar>
          <TabFavicon />
          <TabText />
        </NavBarSearchBar>
        <div>
          <OptionCircle />
          <OptionCircle />
          <OptionCircle />
        </div>
      </NavBar>
      <Website>
        <WebsiteTitle>Site Web</WebsiteTitle>
        <WebsiteNavbar>
          <Word />
          <Word />
          <Word />
          <Word />
          <TabFavicon />
          <TabFavicon />
          <TabFavicon />
          <TabFavicon />
          <TabFavicon />
        </WebsiteNavbar>
        <AdPageContainer>
          <WebsiteContent>
            <WebsiteImage />
            <Paragraph>
              <Sentence />
              <Sentence />
              <Sentence />
              <Sentence />
              <SemiSentence />
            </Paragraph>
            <Paragraph>
              <Sentence />
              <Sentence />
              <Sentence />
              <Sentence />
              <SemiSentence />
            </Paragraph>
            <WebsiteImage />
          </WebsiteContent>
          <DisplayAdInfoTemplate
            width={'120px'}
            height={'auto'}
            bgColor={Colors.YETIC_WHITE}
            style={{ borderColor: '#E9E9E9' }}
          >
            <DivContainer>
              <ImageTop
                src={adImageRectangle}
                alt="image paysage"
                loading="lazy"
              />
              <DivContent>
                <Title>{title}</Title>
                <Logo src={logoImage} alt="logo" loading="lazy" />
                <Description>{description}</Description>
                <DivButton>
                  <ButtonArrow>
                    <ArrowRight
                      color={Colors.YETIC_WHITE}
                      width="20px"
                      height="20px"
                    ></ArrowRight>
                  </ButtonArrow>
                </DivButton>
              </DivContent>
            </DivContainer>
          </DisplayAdInfoTemplate>
        </AdPageContainer>
      </Website>
    </WebsiteWindow>
  )
}

export default WebpagePreview4
