import React, { FC } from 'react'
import styled from '@emotion/styled'

import Padding from '../../constants/padding'
import FontSize from '../../constants/fontSize'
import Margin from '../../constants/margin'
import Colors from '../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'

const DivBanner = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  width: 100%;
  padding: ${Padding.p5} 0;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid grey;
  border-radius: 5px;
  font-family: ${YETIC_FONT_FAMILY};
  margin: ${Margin.m6} 0;
`

const DivContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 25%;
  flex-grow: 1;
  padding: ${Padding.p5} 0;
`

const Title = styled.h3<{ colorText?: string }>`
  color: ${(props) => props.colorText || Colors.YETIC_BLUE_DARK};
  font-size: ${FontSize.YETIC_TEXT};
  margin-bottom: ${Margin.m3};
  margin-top: 0;
  font-weight: 300;
  text-align: center;
`

const Text = styled.p<{ colorText?: string }>`
  color: ${(props) => props.colorText || Colors.YETIC_BLUE_DARK};
  font-size: ${FontSize.YETIC_TITLE_THIN};
  margin-bottom: ${Margin.m1};
  margin-top: 0;
  font-weight: 700;
  text-align: center;
`

export interface SummaryBannerProps {
  contents: {
    labels: string
    texts: string
  }[]
  color?: string
  colorText?: string
}

const SummaryBanner: FC<SummaryBannerProps> = ({
  contents,
  color,
  colorText
}) => {
  let divContentStyle = {}
  if (contents.length === 5) {
    divContentStyle = {
      flexBasis: '20%'
    }
  } else if (contents.length === 8) {
    divContentStyle = {
      flexBasis: '25%'
    }
  }

  return (
    <DivBanner color={color}>
      {contents.map((content, index) => (
        <DivContent key={index} style={divContentStyle}>
          <Title colorText={colorText}>{content.labels}</Title>
          <Text colorText={colorText}>{content.texts}</Text>
        </DivContent>
      ))}
    </DivBanner>
  )
}

export default SummaryBanner
