import { backendURL } from '../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../axios/get'
import {
  GetCampaignGlobalReportingOutputType,
  checkIfGetCampaignGlobalReportingRowType
} from '../../../../types/reporting/campaign/output'

type GetCampaignGlobalReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignGlobalReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignGlobalReportingInputType): Promise<GetCampaignGlobalReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/campaigns/${campaignId}/sections/global${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetCampaignGlobalReporting(response.data.kpi)) {
        return response.data.kpi
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetCampaignGlobalReporting = (
  value: any
): value is GetCampaignGlobalReportingOutputType => {
  return (
    value &&
    value.clicks &&
    Array.isArray(value.clicks) &&
    value.clicks.every((value: any) =>
      checkIfGetCampaignGlobalReportingRowType(value)
    ) &&
    value.impressions &&
    Array.isArray(value.impressions) &&
    value.impressions.every((value: any) =>
      checkIfGetCampaignGlobalReportingRowType(value)
    ) &&
    value.ctr &&
    Array.isArray(value.ctr) &&
    value.ctr.every((value: any) =>
      checkIfGetCampaignGlobalReportingRowType(value)
    ) &&
    value.cost &&
    Array.isArray(value.cost) &&
    value.cost.every((value: any) =>
      checkIfGetCampaignGlobalReportingRowType(value)
    ) &&
    value.co2 &&
    Array.isArray(value.co2) &&
    value.co2.every((value: any) =>
      checkIfGetCampaignGlobalReportingRowType(value)
    )
  )
}
