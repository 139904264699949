import axios from 'axios'

import { backendURL } from '../../../constants/urls'
import {
  LoginErrorEnum,
  checkIfLoginErrorCodeValid
} from '../../../types/error/auth/connection'

export type HandleLoginType = {
  email: string
  password: string
}

type RawHandleLoginResult = {
  firstName: string
  lastName: string
  email: string
  isEditor: number
  isSelfEditor: number
  companyId: string
  companyName: string
}

export type HandleLoginResult = {
  firstName: string
  lastName: string
  email: string
  isEditor: boolean
  isSelfEditor: boolean
  companyId: string
  companyName: string
}

async function handleLogin({
  email,
  password
}: HandleLoginType): Promise<HandleLoginResult | LoginErrorEnum> {
  try {
    const res = await axios.post(
      `${backendURL}/login`,
      {
        email,
        password
      },
      {
        withCredentials: true
      }
    )

    if (checkRawHandleLoginResult(res.data)) {
      return {
        ...res.data,
        isEditor: res.data.isEditor === 1,
        isSelfEditor: res.data.isSelfEditor === 1
      }
    } else {
      return LoginErrorEnum.UNKNOWN
    }
  } catch (error) {
    const errorCode =
      (error as any)?.response?.data?.code ?? (error as any)?.response?.code
    if (errorCode && checkIfLoginErrorCodeValid(errorCode)) {
      return errorCode
    }
    return LoginErrorEnum.UNKNOWN
  }
}

export default handleLogin

export function checkRawHandleLoginResult(
  data: any
): data is RawHandleLoginResult {
  return (
    data &&
    typeof data.firstName === 'string' &&
    typeof data.lastName === 'string' &&
    typeof data.email === 'string' &&
    typeof data.isEditor === 'number' &&
    typeof data.isSelfEditor === 'number' &&
    typeof data.companyId === 'string' &&
    typeof data.companyName === 'string'
  )
}

export function checkIfHandleLoginResult(data: any): data is HandleLoginResult {
  return (
    data &&
    typeof data.firstName === 'string' &&
    typeof data.lastName === 'string' &&
    typeof data.email === 'string' &&
    typeof data.isEditor === 'boolean' &&
    typeof data.isSelfEditor === 'boolean' &&
    typeof data.companyId === 'string' &&
    typeof data.companyName === 'string'
  )
}
