import {
  googleLogoMinHeight,
  googleLogoMinWidth,
  googleLogoRatio,
  googleRectangleMinHeight,
  googleRectangleMinWidth,
  googleRectangleRatio,
  googleSquareMinHeight,
  googleSquareMinWidth,
  googleSquareRatio
} from '../../constants/image'
import { getImageDimensions, getImageRatio } from '../image'
import { GoogleFormImageAdError } from '../../types/pages/adImageCreation'

export const getLogoError = async (img: File | null): Promise<string> => {
  if (!img) return ''

  const imgSrc = URL.createObjectURL(img)

  const imgRatio = await getImageRatio(imgSrc)
  const imgDimensions = await getImageDimensions(imgSrc)

  if (imgRatio !== googleLogoRatio) {
    return GoogleFormImageAdError.LOGO_RATIO
  }
  if (
    imgDimensions.width < googleLogoMinWidth ||
    imgDimensions.height < googleLogoMinHeight
  ) {
    return GoogleFormImageAdError.LOGO_SIZE
  }
  return ''
}

export const getRectangleError = async (img: File | null): Promise<string> => {
  if (!img) return ''

  const imgSrc = URL.createObjectURL(img)

  const imgRatio = await getImageRatio(imgSrc)
  const imgDimensions = await getImageDimensions(imgSrc)

  if (imgRatio !== googleRectangleRatio) {
    return GoogleFormImageAdError.RECTANGLE_RATIO
  }
  if (
    imgDimensions.width < googleRectangleMinWidth ||
    imgDimensions.height < googleRectangleMinHeight
  ) {
    return GoogleFormImageAdError.RECTANGLE_SIZE
  }
  return ''
}

export const getSquareError = async (img: File | null): Promise<string> => {
  if (!img) return ''

  const imgSrc = URL.createObjectURL(img)

  const imgRatio = await getImageRatio(imgSrc)
  const imgDimensions = await getImageDimensions(imgSrc)

  if (imgRatio !== googleSquareRatio) {
    return GoogleFormImageAdError.SQUARE_RATIO
  }
  if (
    imgDimensions.width < googleSquareMinWidth ||
    imgDimensions.height < googleSquareMinHeight
  ) {
    return GoogleFormImageAdError.SQUARE_SIZE
  }
  return ''
}
