import React from 'react'

import Box from '../../../../../components/Box'
import { StyledBox, boxStyle } from '../../../AnalyticsHighlights/styles'
import DefaultText from '../../../../../components/DefaultText'
import Colors from '../../../../../constants/colors'
import Title from '../../../../../components/Title'
import DevicesIcon from '../../../../../components/Icon/devices'
import { DivText, DivRecommandation, DivContainer } from './styles'
import FranceIcon from '../../../../../components/Icon/france'
import AgeRangeIcon from '../../../../../components/Icon/ageRange'

const Recommandation = () => {
  return (
    <>
      <Title>Meilleurs ROI</Title>
      <DivRecommandation>
        <Box
          backgroundColor={Colors.NEW_YETIC_BLUE_DARK}
          width="20%"
          style={boxStyle}
          elements={[
            <DivContainer key={Math.random()}>
              <DivText key={Math.random()}>
                <StyledBox>
                  <DefaultText
                    style={{ color: 'white' }}
                    size="medium"
                    key={Math.random()}
                  >
                    Appareils
                  </DefaultText>
                </StyledBox>
                <StyledBox>
                  <DefaultText
                    style={{ color: 'white' }}
                    size="big"
                    key={Math.random()}
                  >
                    Smartphone
                  </DefaultText>
                </StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="small"
                  key={Math.random()}
                >
                  10g co2 / € dépensé
                </DefaultText>
              </DivText>
              <DevicesIcon />
            </DivContainer>
          ]}
        />
        <Box
          backgroundColor={Colors.NEW_YETIC_BLUE_DARK}
          width="20%"
          style={boxStyle}
          elements={[
            <DivContainer key={Math.random()}>
              <DivText key={Math.random()}>
                <StyledBox>
                  <DefaultText
                    style={{ color: 'white' }}
                    size="medium"
                    key={Math.random()}
                  >
                    Zone géographique
                  </DefaultText>
                </StyledBox>
                <StyledBox>
                  <DefaultText
                    style={{ color: 'white' }}
                    size="big"
                    key={Math.random()}
                  >
                    Normandie
                  </DefaultText>
                </StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="small"
                  key={Math.random()}
                >
                  18g co2 / € dépensé
                </DefaultText>
              </DivText>
              <FranceIcon />
            </DivContainer>
          ]}
        />
        <Box
          backgroundColor={Colors.NEW_YETIC_BLUE_DARK}
          width="20%"
          style={boxStyle}
          elements={[
            <DivContainer key={Math.random()}>
              <DivText key={Math.random()}>
                <StyledBox>
                  <DefaultText
                    style={{ color: 'white' }}
                    size="medium"
                    key={Math.random()}
                  >
                    {`Tranche d'âge`}
                  </DefaultText>
                </StyledBox>
                <StyledBox>
                  <DefaultText
                    style={{ color: 'white' }}
                    size="big"
                    key={Math.random()}
                  >
                    18 - 24 ans
                  </DefaultText>
                </StyledBox>
                <DefaultText
                  style={{ color: 'white' }}
                  size="small"
                  key={Math.random()}
                >
                  14g co2 / € dépensé
                </DefaultText>
              </DivText>
              <AgeRangeIcon />
            </DivContainer>
          ]}
        />
      </DivRecommandation>
    </>
  )
}

export default Recommandation
