import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const addButtonStyle: CSSProperties = {
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  marginTop: Margin.m6
}

export const SeeCO2Detail = styled.div`
  position: absolute;
  right: ${Margin.m4};
  top: ${Margin.m4};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ImageAdPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Padding.p5} 0 ${Padding.p8};
`
