import React from 'react'

import {
  PanelContainer,
  Content,
  DivMap,
  TitleContainer,
  TipContainer
} from '../styles'
import Margin from '../../../../constants/margin'
import DefaultText from '../../../../components/DefaultText'
import { PanelType, isJSXSection } from '../../../../types/templates/panels'
import PanelTitle from '../PanelTitle'
import LockIcon from '../../../../components/Icon/lock'
import Colors from '../../../../constants/colors'
import { SectionType } from '../../../../types/templates/section'
import { CustomTooltip } from '../../../../components/CustomToolTip'
import { TipsInfos } from '../../../../components/Tips'
import TipsFormat from '../../../../components/Tips/TipsFormat'
// import PanelSubTitle from '../PanelSubTitle'

type PanelProps = Omit<PanelType, 'isDropdown'>

const Panel = ({
  title,
  description,
  sections,
  disabled,
  titleTooltip,
  tipsFormat,
  tip
}: PanelProps) => {
  // {title && <PanelSubTitle>{title}</PanelSubTitle>}
  return (
    <>
      {tipsFormat ? (
        <TipsFormat title={tipsFormat.title} items={tipsFormat.items} />
      ) : (
        tip && (
          <TipContainer>
            <TipsInfos title={tip.title} text={tip.content} />
          </TipContainer>
        )
      )}
      <PanelContainer>
        {title && (
          <TitleContainer>
            <PanelTitle>
              {title}
              {titleTooltip && (
                <CustomTooltip content={titleTooltip} hover dark />
              )}
              {disabled && (
                <LockIcon
                  width="12px"
                  height="12px"
                  fill={Colors.NEW_YETIC_DARK}
                />
              )}
            </PanelTitle>
            {(sections[0] as SectionType).cornerAction}
          </TitleContainer>
        )}
        {description && (
          <DefaultText
            size="small"
            style={{ opacity: '0.5', marginBottom: Margin.m5 }}
          >
            {description}
          </DefaultText>
        )}
        {sections &&
          sections.map((section, index) => {
            if (isJSXSection(section)) {
              return <DivMap key={index}>{section}</DivMap>
            }
            return (
              <div key={index}>
                {section.title && (
                  <DefaultText style={{ fontWeight: '600' }}>
                    {section.title}
                    {section.disabled && (
                      <LockIcon
                        width="12px"
                        height="12px"
                        fill={Colors.NEW_YETIC_DARK}
                      />
                    )}
                  </DefaultText>
                )}
                {section.description && (
                  <DefaultText size="small" style={{ opacity: '0.5' }}>
                    {section.description}
                  </DefaultText>
                )}
                {section.content && <Content>{section.content}</Content>}
              </div>
            )
          })}
      </PanelContainer>
    </>
  )
}

export default Panel
