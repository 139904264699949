import React, { useState, useEffect } from 'react'

import {
  PanelContainer,
  Content,
  DivMap,
  CheckboxSpan,
  checkboxContainerStyle
} from '../styles'
import Margin from '../../../../constants/margin'
import DefaultText from '../../../../components/DefaultText'
import { PanelType, isJSXSection } from '../../../../types/templates/panels'
import LockIcon from '../../../../components/Icon/lock'
import Colors from '../../../../constants/colors'
import PanelTitle from '../PanelTitle'
import { Checkbox } from '../../../../components/CheckBox'

type PanelProps = Omit<PanelType, 'isDropdown'>

const CheckablePanel = ({
  title,
  description,
  sections,
  disabled = false,
  isChecked,
  setIsChecked
}: PanelProps) => {
  useEffect(() => {
    if (disabled && setIsChecked) {
      setIsChecked(false)
    }
  }, [disabled])

  const togglePanel = () => {
    if (!disabled && setIsChecked) {
      setIsChecked(!isChecked)
    }
  }

  return (
    <PanelContainer>
      <Checkbox
        selected={isChecked}
        onChange={togglePanel}
        style={isChecked ? checkboxContainerStyle : undefined}
      >
        <CheckboxSpan>{title}</CheckboxSpan>
      </Checkbox>
      {description && isChecked && (
        <DefaultText
          size="small"
          style={{ opacity: '0.5', marginBottom: Margin.m5 }}
        >
          {description}
        </DefaultText>
      )}
      {isChecked &&
        sections &&
        sections.map((section, index) => {
          if (isJSXSection(section)) {
            return <DivMap key={index}>{section}</DivMap>
          }
          return (
            <DivMap
              key={section.title}
              style={{
                marginBottom:
                  index === sections.length - 1 ? Margin.m3 : Margin.m8
              }}
            >
              {section.title && (
                <PanelTitle>
                  {section.title}
                  {section.disabled && (
                    <LockIcon
                      width="12px"
                      height="12px"
                      fill={Colors.NEW_YETIC_DARK}
                    />
                  )}
                </PanelTitle>
              )}
              {section.description && (
                <DefaultText size="small" style={{ opacity: '0.5' }}>
                  {section.description}
                </DefaultText>
              )}
              {section.content && <Content>{section.content}</Content>}
            </DivMap>
          )
        })}
    </PanelContainer>
  )
}

export default CheckablePanel
