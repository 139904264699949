import React from 'react'

import { AudienceDataType } from '..'
import { PanelType } from '../../../../types/templates/panels'
import Age from '../Google/Age'
import Interests from '../Google/Interests'
import { AudienceCreationErrors } from '../helpers/errors'
import GenderComponent from '../Google/Gender'
import Margin from '../../../../constants/margin'
import { TipsInfos } from '../../../../components/Tips'

type GetGoogleParamsPanelProps = {
  /*   interests: string[] */
  audienceData: AudienceDataType
  handleMinAgeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleMaxAgeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /*   handleAddInterests: (tag: string) => void
  handleRemoveInterests: (index: number) => void */
  handleChangeGender: (index: number) => void
  hasBeenSubmitted: boolean
  errors: AudienceCreationErrors
  isChecked: boolean
  setIsChecked: (isChecked: boolean) => void
}

const getGoogleParamsPanel = ({
  /*   interests, */
  audienceData,
  handleMinAgeChange,
  handleMaxAgeChange,
  /*   handleAddInterests,
  handleRemoveInterests, */
  handleChangeGender,
  hasBeenSubmitted,
  errors,
  isChecked,
  setIsChecked
}: GetGoogleParamsPanelProps) => {
  const googleParamsPanel: PanelType = {
    title: 'Paramètres Google Ads',
    disabled: false,
    sections: [
      <div key="google-audience-parameters" style={{ marginTop: Margin.m4 }}>
        <GenderComponent
          radioSelectedGender={audienceData.indexSelectedGender}
          onChangeGender={handleChangeGender}
        />
        <TipsInfos
          style={{ marginTop: '20px' }}
          title="Âge minimum"
          text={
            'Google Ads limite le ciblage par âge à 18 ans et plus pour protéger la vie privée des mineurs et se conformer aux réglementations légales, assurant ainsi une pratique publicitaire responsable et éthique.'
          }
        />
        <Age
          minAge={audienceData.minAge}
          maxAge={audienceData.maxAge}
          handleMinAgeChange={handleMinAgeChange}
          handleMaxAgeChange={handleMaxAgeChange}
          minAgeError={hasBeenSubmitted ? errors.minAge : ''}
          maxAgeError={hasBeenSubmitted ? errors.maxAge : ''}
        />
        {/* <Interests
          data={interests}
          addNewInterest={handleAddInterests}
          removeInterest={handleRemoveInterests}
          selectedTags={audienceData.selectedInterests}
        /> */}
      </div>
    ],
    isDropdown: false,
    isChecked,
    setIsChecked
  }

  return googleParamsPanel
}

export default getGoogleParamsPanel
