import React, { CSSProperties } from 'react'

import { CheckboxListTmp } from '../../../../tmpComponents/CheckboxList'
import {
  DevicesEnum,
  DevicesEnumTranslation
} from '../../../../types/campaign/devices'
import CO2Tag from '../../../../components/CO2Tag'
import CO2Score from '../../../../components/CO2Score'
import { CO2ScoreContainer } from './styles'

interface DevicesProps {
  isDesktopSelected: boolean
  isSmartphoneSelected: boolean
  isTabletSelected: boolean
  isTVSelected: boolean
  handleChangeDevice: (type: string) => void
  style?: CSSProperties
  withBorder?: boolean
  tooltip?: boolean
}

const Devices = ({
  isDesktopSelected,
  isSmartphoneSelected,
  isTabletSelected,
  isTVSelected,
  handleChangeDevice,
  style,
  withBorder = true,
  tooltip
}: DevicesProps) => {
  const values = [
    {
      id: DevicesEnum.desktop,
      label: DevicesEnumTranslation.desktop,
      selected: isDesktopSelected,
      tooltipView: tooltip ? (
        <CO2ScoreContainer>
          <CO2Score score={1} />
        </CO2ScoreContainer>
      ) : undefined
    },
    {
      id: DevicesEnum.smartphone,
      label: DevicesEnumTranslation.smartphone,
      selected: isSmartphoneSelected,
      tooltipView: tooltip ? (
        <CO2ScoreContainer>
          <CO2Tag
            positive
            tooltip
            wording="CO2 - Meilleur choix"
            modalText="Les publicités diffusées sur smartphone ont tendance a avoir un impact moins élevé en raison de la petite taille de l'écran."
          />
          <CO2Score score={3} />
        </CO2ScoreContainer>
      ) : undefined
    },
    {
      id: DevicesEnum.tablet,
      label: DevicesEnumTranslation.tablet,
      selected: isTabletSelected,
      tooltipView: tooltip ? (
        <CO2ScoreContainer>
          <CO2Score score={1} />
        </CO2ScoreContainer>
      ) : undefined
    },
    {
      id: DevicesEnum.tv,
      label: DevicesEnumTranslation.tv,
      selected: isTVSelected,
      tooltipView: tooltip ? (
        <CO2ScoreContainer>
          <CO2Tag
            positive={false}
            tooltip
            wording="CO2 - Pire choix"
            modalText="Les publicités diffusées sur TV ont tendance a avoir un impact plus élevé en raison de la grande taille de l'écran et de la consommation d'énergie associée."
          />
          <CO2Score score={0} />
        </CO2ScoreContainer>
      ) : undefined
    }
  ]

  return (
    <CheckboxListTmp
      values={values}
      onChange={handleChangeDevice}
      style={style}
      withBorder={withBorder}
    />
  )
}

export default Devices
