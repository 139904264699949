import { CSSProperties } from 'react'

import Colors from '../../../../constants/colors'
import Margin from '../../../../constants/margin'
import Padding from '../../../../constants/padding'

export const tabContainerStyle: CSSProperties = {
  justifyContent: 'flex-start',
  gap: '10px',
  padding: Padding.p9,
  paddingBottom: 0,
  boxSizing: 'border-box'
}

export const tabStyle: CSSProperties = {
  border: `1px solid ${Colors.NEW_YETIC_BLUE_DARK}`,
  borderRadius: '50px',
  fontSize: '12px',
  width: 'fit-content',
  padding: '10px 20px',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  transition: 'all 0.2s ease-in-out',
  flex: 0,
  marginBottom: Margin.m2
}
