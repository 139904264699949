import { backendURL } from '../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export const getImageById = async (imageId: string): Promise<string | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/image/${imageId}`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfImage(response.data)) {
        return response.data.base64Image
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export default getImageById

export const checkIfImage = (input: any): input is { base64Image: string } => {
  return input && input.base64Image && typeof input.base64Image === 'string'
}
