import React from 'react'

interface RadioListProps {
  values: React.ReactNode[]
  selected: number
  hasBorder?: boolean
  style?: React.CSSProperties
}

const RadioList = ({ values, selected, hasBorder, style }: RadioListProps) => {
  return (
    <div style={style}>
      {values.map((radio, index) => (
        <div key={index}>{radio}</div>
      ))}
    </div>
  )
}

export default RadioList
