import { backendURL } from '../../../../../constants/urls'
import { checkIfGetCampaignImpactReporting } from '../../../../../types/reporting/campaign/output'
import { GetMediaPlanImpactReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../../axios/get'

type GetMediaPlanImpactReportingInputType = {
  mediaPlanId: string
  startDate?: string
  endDate?: string
}

export const getMediaPlanImpactReporting = async ({
  mediaPlanId,
  startDate,
  endDate
}: GetMediaPlanImpactReportingInputType): Promise<GetMediaPlanImpactReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/media-plan/${mediaPlanId}/sections/impact${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetMediaPlanImpactReporting(response.data.impact)) {
        return response.data.impact
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetMediaPlanImpactReporting = (
  val: any
): val is GetMediaPlanImpactReportingOutputType => {
  return checkIfGetCampaignImpactReporting(val)
}
