import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import LabelledItemsList from '../../components/LabelledItemsList'
import DetailsPageTemplate from '../../templates/DetailsPageTemplate'
import TableList, { TableListDataType } from '../../components/TableList'
import { getCustomerById } from '../../helpers/queries/customer/getCustomerById'
import { CustomerDetailsType, CustomerMediaPlan } from '../../types/customer'
import { formatDateToFrench, formatDateToString } from '../../helpers/date'
import Team from '../../components/Team'
import { getPlatformEnum } from '../../types/platform'
import LoadingPage from '../LoadingPage'
import { getMediaPlanById } from '../../helpers/queries/mediaPlan/getMediaPlanById'
import { getFormatEnum } from '../../types/format'
import { AppContext } from '../../contexts/AppContext'
import ErrorPage from '../ErrorPage'
import StatusWithColor from '../../components/StatusDot'
import { MediaPlanDetailsType } from '../../types/mediaPlan/mediaPlan'
import ModalReasonOfReject from '../parts/ModalReasonOfReject'
import { Status } from '../../types/status'
import statusHasStarted from '../../helpers/statusHasStarted'
import { FlowEnum } from '../../types/flow'

const CustomerDetails = () => {
  const [noRowOpen, setNoRowOpen] = useState<string>('')
  const [customer, setCustomer] = useState<CustomerDetailsType | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [selectedMediaPlan, setSelectedMediaPlan] =
    useState<MediaPlanDetailsType | null>(null)

  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [mediaPlanName, setMediaPlanName] = useState('')
  const [skip, setSkip] = useState(0)

  const navigate = useNavigate()
  const { customerId } = useParams()
  const { isEditor } = useContext(AppContext)

  useEffect(() => {
    if (customerId) {
      ;(async function () {
        const result = await getCustomerById({
          customerId,
          skip
        })
        if (result != null) {
          const newCustomer = {
            name: result.customer.name,
            createdAt: result.customer.createdAt,
            spentBudget: result.customer.spentBudget,
            expectedBudget: result.customer.expectedBudget,
            realizedBenefits: result.customer.realizedBenefits,
            expectedBenefits: result.customer.expectedBenefits,
            users: result.customer.users,
            co2: result.customer.co2,
            consumedCo2: result.customer.consumedCo2,
            mediaPlans: result.customer.mediaPlans,
            navigation: result.customer.navigation
          }

          setCustomer(newCustomer)
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [customerId, skip])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!customerId || !customer) {
    return <LoadingPage />
  }

  const initialCustomerMediaPlans: TableListDataType = {
    titles: [
      'Nom',
      'Statut',
      <>
        <div>Budget</div>
        <div>dépensé / prévu</div>
      </>,
      'Plateformes',
      'CO₂ consommé'
    ],
    widths: ['28%', '20%', '22%', '22%', '8%'],
    items: []
  }

  const breadcrumbItems = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate(`/customers`)
      },
      label: 'Clients'
    },
    {
      label: customer.name
    }
  ]

  const summaryItems = [
    {
      label: 'Budget dépensé / prévu',
      value: `${Math.round(customer.spentBudget * 100) / 100}€ / ${
        Math.round(customer.expectedBudget * 100) / 100
      }€`
    }
    // {
    //   label: 'Revenus réalisés / estimés',value: `${Math.round(customer.spentBudget)}€ / ${Math.round(customer.expectedBudget)}€`
    //   value: `${customer.realizedBenefits}€ / ${customer.expectedBenefits}€`
    // }
  ]

  const date = formatDateToFrench(new Date(customer.createdAt))

  const summaryContentColumn1 = <LabelledItemsList items={summaryItems} />

  const summaryContentColumn2 = customer.users && (
    <Team users={customer.users} />
  )

  const handleShowReason = (
    event: React.MouseEvent,
    data: CustomerMediaPlan | MediaPlanDetailsType
  ) => {
    event.stopPropagation()
    setIsModalReasonOpen(true)
    setRejectionReason(data.feedback ?? 'Aucune raison donnée')
    setMediaPlanName(data.name)
  }

  const handleChangePage = async (page: number) => {
    const newSkip = page - 1
    try {
      const result = await getCustomerById({
        customerId,
        skip: newSkip
      })
      if (result != null) {
        const newCustomer = {
          name: result.customer.name,
          createdAt: result.customer.createdAt,
          spentBudget: result.customer.spentBudget,
          expectedBudget: result.customer.expectedBudget,
          realizedBenefits: result.customer.realizedBenefits,
          expectedBenefits: result.customer.expectedBenefits,
          users: result.customer.users,
          co2: result.customer.co2,
          consumedCo2: result.customer.consumedCo2,
          mediaPlans: result.customer.mediaPlans,
          navigation: result.customer.navigation
        }
        setCustomer(newCustomer)
      } else {
        setHasFetchError(true)
      }
    } catch (error) {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideCO2 = customer.mediaPlans.every(
    (mp) => !statusHasStarted(mp.status)
  )

  let customerMediaPlans: TableListDataType = customer.mediaPlans
    ? {
        ...initialCustomerMediaPlans,
        items: customer.mediaPlans.map((m) => {
          return {
            id: m.id,
            row: [
              m.name,
              <StatusWithColor
                status={m.status}
                key={m.status}
                handleClick={
                  m.status === Status.REJECTED
                    ? (e) => {
                        handleShowReason(e, m)
                      }
                    : undefined
                }
              />,
              `${Math.round(m.spentBudget * 100) / 100}€ / ${
                Math.round(m.expectedBudget * 100) / 100
              }€`,
              m.platforms.map((p) => getPlatformEnum(p)).join(', '),
              hideCO2 ? '--' : `${Math.round(m.consumedCo2 * 100) / 100} g`
            ],
            details: {
              column1: []
            },
            subItems: []
          }
        })
      }
    : initialCustomerMediaPlans

  const handleOnRowClick = async (id: string) => {
    if (noRowOpen === id) {
      setNoRowOpen('')
    } else {
      const result = await getMediaPlanById({
        mediaPlanId: id
      })
      if (result != null) {
        setSelectedMediaPlan(result.mediaPlan)
      }
      setNoRowOpen(id)
    }
  }

  if (selectedMediaPlan != null) {
    const cardDetails = {
      column1: [
        {
          label: 'Budget dépensé / prévu',
          value:
            selectedMediaPlan.spentBudget !== undefined &&
            selectedMediaPlan.expectedBudget !== undefined
              ? `${selectedMediaPlan.spentBudget}€ / ${selectedMediaPlan.expectedBudget}€`
              : ''
        },
        {
          label: 'Objectif(s)',
          value: selectedMediaPlan.goals.join('\n')
        }
      ],
      column2: [
        {
          label: 'Période',
          value: `du ${formatDateToString(
            new Date(selectedMediaPlan.startDate)
          )} au ${formatDateToString(new Date(selectedMediaPlan.endDate))}`
        }
      ]
    }

    const subItems = selectedMediaPlan.campaigns.map((c) => ({
      title: c.name,
      data: [
        {
          label: 'Statut',
          value: (
            <StatusWithColor
              status={c.status}
              handleClick={(e) => {
                handleShowReason(e, selectedMediaPlan)
              }}
            />
          )
        },
        {
          label: "Nombre d'annonces",
          value: c.adsCount.toString()
        },
        { label: 'Format', value: getFormatEnum(c.format) },
        { label: 'Budget', value: `${c.dailyBudget} €/jour` },
        ...(hideCO2
          ? []
          : [
              {
                label: '🍃 CO₂',
                value: `${Math.round(c.consumedCo2 * 100) / 100} g`
              }
            ])
      ],
      onSeeMore: () => {
        if (c.status === Status.DRAFT) {
          navigate(`/campaign/${c.id}/review`)
        } else {
          navigate(`/campaign/${c.id}/details`)
        }
      },
      platform: c.platform
    }))

    const updatedCustomerMediaPlansData = customerMediaPlans.items.map(
      (item) => {
        if (item.id === noRowOpen) {
          return {
            ...item,
            details: cardDetails,
            subItems
          }
        }
        return item
      }
    )

    customerMediaPlans = {
      titles: customerMediaPlans.titles,
      widths: customerMediaPlans.widths,
      items: updatedCustomerMediaPlansData
    }
  }

  return (
    <DetailsPageTemplate
      navigate={navigate}
      breadcrumbItems={breadcrumbItems}
      isEditor={isEditor != null ? isEditor : true}
      summary={{
        title: customer.name,
        type: `Client (depuis le ${date})`,
        co2InG: hideCO2 ? null : Math.round(customer.consumedCo2 * 100) / 100,
        column1: summaryContentColumn1,
        column2: summaryContentColumn2
      }}
    >
      <>
        <TableList
          title="Plans média"
          data={customerMediaPlans}
          itemsByPage={5}
          handleAdd={() => {
            navigate(`/company/${customerId}/media-plan/create`)
          }}
          addText="Nouveau plan média"
          handleOnRowClick={(id: string) => {
            handleOnRowClick(id)
          }}
          noRowOpen={noRowOpen}
          subtitle="Liste des campagnes"
          seeMoreText="Voir le plan média"
          handleSeeMoreSubItem={(id) => {
            if (selectedMediaPlan?.status === Status.DRAFT) {
              navigate(`/media-plan/${id}/review`)
            } else {
              navigate(`/media-plan/${id}/details`)
            }
          }}
          totalItems={customer.navigation.mediaPlansCount}
          handleChangePage={(page) => {
            handleChangePage(page)
          }}
          currentPage={skip / 5 + 1}
        />
        {isModalReasonOpen && (
          <ModalReasonOfReject
            onClose={() => {
              setIsModalReasonOpen(!isModalReasonOpen)
            }}
            rejectionReason={rejectionReason}
            mediaPlanName={mediaPlanName}
          />
        )}
      </>
    </DetailsPageTemplate>
  )
}

export default CustomerDetails
