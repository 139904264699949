import React from 'react'

import {
  ContainerBlock,
  Container
} from '../../templates/ReviewPageTemplate/ReviewContent'
import Breadcrumb from '../../components/BreadCrumb'
import LabelledItemsList from '../../components/LabelledItemsList'
import { getFormatEnum } from '../../types/format'
import FontWeight from '../../constants/fontWeight'
import { MediaPlanValidationProps } from './types'
import SelfEditorPaymentContent from './PaymentContent/selfEditor'

const SelfEditorMediaPlanValidation = ({
  reviewData,
  mediaPlanId,
  startDateMediaPlan,
  endDateMediaPlan,
  platforms,
  setCampaignIdCO2ModalOpen,
  navigate,
  handleViewMore
}: MediaPlanValidationProps) => {
  const tipsEcology = (
    <>
      {`Votre plan publicitaire va génerer une quantité d'environ `}
      <span style={{ fontWeight: FontWeight.YETIC_BOLD }}>
        {reviewData.mediaPlan.co2}
      </span>
      {` de CO2.`}
    </>
  )

  const container: Container = {
    column1: (
      <LabelledItemsList
        items={[
          {
            label: 'Budget prévu',
            value: `${reviewData.mediaPlan.budget.toString()}` + ` €`
          },
          {
            label: 'Période de diffusion',
            value: `${startDateMediaPlan} au ${endDateMediaPlan}`
          },
          {
            label: 'Objectif(s)',
            value: reviewData.mediaPlan.goals.toString()
          },
          {
            label: 'Nombre de campagnes',
            value: reviewData.campaigns.length.toString()
          },
          { label: 'Plateforme(s)', value: platforms }
        ]}
      />
    ),
    items: reviewData.campaigns.map((campaign) => ({
      id: campaign.id,
      title: campaign.name,
      platform: campaign.platform,
      container: {
        column1: (
          <LabelledItemsList
            items={[
              {
                label: 'Plateforme',
                value:
                  campaign.platform.charAt(0).toUpperCase() +
                  campaign.platform.slice(1)
              },
              {
                label: 'Budget alloué',
                value: campaign.budget.toString() + ` €`
              },
              { label: 'Objectif(s)', value: campaign.goal },
              ...(campaign.bidStrategy
                ? [
                    {
                      label: "Stratégie d'optimisation du budget",
                      value: campaign.bidStrategy
                    }
                  ]
                : [])
            ]}
          />
        ),
        column2: (
          <LabelledItemsList
            items={[
              {
                id: campaign.id,
                label: 'CO2',
                value: campaign.co2,
                onTooltipClick: (campaignId: string) => {
                  setCampaignIdCO2ModalOpen(campaignId)
                }
              },
              {
                label: 'Période de diffusion',
                value: `${campaign.period.start.toString()} au ${campaign.period.end.toString()}`
              },
              { label: 'Format', value: getFormatEnum(campaign.format) }
            ]}
          />
        )
      }
    }))
  }

  const containerBlock: ContainerBlock = {
    title: reviewData.mediaPlan.name,
    container
  }

  const items = [
    {
      label: 'Plan publicitaire'
    },
    {
      onClick: () => {
        navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
      },
      label: reviewData.navigation.mediaPlan.name
    },
    { label: 'Validation du plan média' }
  ]

  return (
    <>
      <Breadcrumb items={items} />
      <SelfEditorPaymentContent
        mediaPlanId={mediaPlanId}
        reviewData={reviewData}
        tipsEcology={tipsEcology}
        containerBlock={containerBlock}
        handleViewSubItem={handleViewMore}
      />
    </>
  )
}

export default SelfEditorMediaPlanValidation
