import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { getCampaignById } from '../../helpers/queries/campaign/getCampaignById'

const AdCreation = () => {
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()
  const { campaignId } = useParams()

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignById({
          campaignId: campaignId,
          withoutAds: true
        })
        if (result != null) {
          navigate(
            `/campaign/${campaignId}/ad/${result.campaign.platform}/${result.campaign.format}/create`
          )
        }
        setHasFetchError(true)
      })()
    } else {
      setHasFetchError(true)
    }
  }, [campaignId])

  if (hasFetchError) {
    return <ErrorPage />
  }

  return <LoadingPage />
}

export default AdCreation
