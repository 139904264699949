export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T
}

export function deepCloneWithDate<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null || obj instanceof Date) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCloneWithDate(item)) as any
  }

  const result: any = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = deepCloneWithDate(obj[key])
    }
  }

  return result
}
