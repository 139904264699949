import React from 'react'

import { LoadingContainer } from './styles'
import ImpactTab from './TabsContent/ImpactTab'
import AnimatedLoader from '../../components/AnimatedLoader'
import ClickImpressionCostTab from './TabsContent/ClickImpressionCostTab'
import AudiencesTab from './TabsContent/AudiencesTab'
import DevicesTab from './TabsContent/DevicesTab'
import OverallTab from './TabsContent/OverallTab'
import { checkIfGetCampaignImpactReporting } from '../../types/reporting/campaign/output'
import {
  ReportingTabItem,
  ReportingTabKeyEnum
} from '../../types/reporting/tabs'
import CampaignsTab from './TabsContent/CampaignsTab'
import {
  checkIfGetMediaPlanAudiencesReporting,
  checkIfGetMediaPlanCampaignsReporting,
  checkIfGetMediaPlanDevicesReporting,
  checkIfGetMediaPlanGlobalReporting,
  checkIfGetMediaPlanOverallReporting
} from '../../helpers/queries/reporting/sections/mediaPlan'
import { GetMediaPlanReportingBySectionOutputType } from '../../types/reporting/mediaPlan/output'

type MediaPlanTabMappingProps = {
  activeTab: ReportingTabItem
  isLoading: boolean
  reportingData: GetMediaPlanReportingBySectionOutputType | null
  contentWidth: number
}

const MediaPlanTabMapping = ({
  activeTab,
  isLoading,
  reportingData,
  contentWidth
}: MediaPlanTabMappingProps) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <AnimatedLoader size={20} />
      </LoadingContainer>
    )
  }

  switch (activeTab.key) {
    case ReportingTabKeyEnum.overall:
      if (checkIfGetMediaPlanOverallReporting(reportingData)) {
        return (
          <OverallTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.recommendations:
      return <div>Recommandations en cours de développement</div>

    case ReportingTabKeyEnum.clickImpressionCost:
      if (checkIfGetMediaPlanGlobalReporting(reportingData)) {
        return (
          <ClickImpressionCostTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.devices:
      if (checkIfGetMediaPlanDevicesReporting(reportingData)) {
        return (
          <DevicesTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.impact:
      if (checkIfGetCampaignImpactReporting(reportingData)) {
        return (
          <ImpactTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.audiences:
      if (checkIfGetMediaPlanAudiencesReporting(reportingData)) {
        return (
          <AudiencesTab
            reportingData={reportingData}
            contentWidth={contentWidth}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.campaigns:
      if (checkIfGetMediaPlanCampaignsReporting(reportingData)) {
        return <CampaignsTab reportingData={reportingData} />
      }
      return null

    default:
      return null
  }
}

export default MediaPlanTabMapping
