import {
  ReportingAPISectionEnum,
  checkIfReportingAPISectionEnum
} from '../../../types/reporting/apiSection'
import { GetCampaignReportingBySectionOutputType } from '../../../types/reporting/campaign/output'
import { getCampaignAdsReporting } from './sections/ads'
import { getCampaignAudiencesReporting } from './sections/audiences'
import { getCampaignDevicesReporting } from './sections/devices'
import { getCampaignGlobalReporting } from './sections/global'
import { getCampaignKeywordsReporting } from './sections/keywords'
import { getCampaignOverallReporting } from './sections/overall'
import {
  GetCampaignReportingErrorEnum,
  GetCampaignReportingErrorEnumTranslation
} from '../../../types/error/reporting/getCampaignReporting'
import { checkIfDate } from '../../nativeTypes'
import { getCampaignImpactReporting } from './sections/impact'
import { getCampaignHighlightReporting } from './sections/highlight'
import { getCampaignDownloadReporting } from './sections/download'

export type GetCampaignReportingBySectionInputType = {
  campaignId: string
  section: ReportingAPISectionEnum
  startDate?: string
  endDate?: string
}

type GetCampaignReportingBySectionErrorType = { error: string }

export const getCampaignReportingBySection = async ({
  campaignId,
  section,
  startDate,
  endDate
}: GetCampaignReportingBySectionInputType): Promise<
  | GetCampaignReportingBySectionOutputType
  | GetCampaignReportingBySectionErrorType
> => {
  if (!campaignId) {
    return {
      error:
        GetCampaignReportingErrorEnumTranslation[
          GetCampaignReportingErrorEnum.CAMPAIGN_ID
        ]
    }
  }

  if (
    (startDate && !checkIfDate(startDate)) ||
    (endDate && !checkIfDate(endDate))
  ) {
    return {
      error:
        GetCampaignReportingErrorEnumTranslation[
          GetCampaignReportingErrorEnum.INVALID_DATE
        ]
    }
  }
  if (!checkIfReportingAPISectionEnum(section)) {
    return {
      error:
        GetCampaignReportingErrorEnumTranslation[
          GetCampaignReportingErrorEnum.WRONG_SECTION
        ]
    }
  }

  switch (section) {
    case ReportingAPISectionEnum.ads: {
      const result = await getCampaignAdsReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.audiences: {
      const result = await getCampaignAudiencesReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.devices: {
      const result = await getCampaignDevicesReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.global: {
      const result = await getCampaignGlobalReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.keywords: {
      const result = await getCampaignKeywordsReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.overall: {
      const result = await getCampaignOverallReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.impact: {
      const result = await getCampaignImpactReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.highlight: {
      const result = await getCampaignHighlightReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.download: {
      const result = await getCampaignDownloadReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
  }

  return {
    error:
      GetCampaignReportingErrorEnumTranslation[
        GetCampaignReportingErrorEnum.UNKNOWN
      ]
  }
}

export const checkIfGetCampaignReportingOutputError = (
  value: any
): value is GetCampaignReportingBySectionErrorType => {
  return value && value.error && typeof value.error === 'string'
}
