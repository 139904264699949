import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PreviewContainer, previewMessageStyle } from './styles'
import { AppContext } from '../../contexts/AppContext'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import LoadingPage from '../LoadingPage'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import { PanelType } from '../../types/templates/panels'
import { TipsInfos } from '../../components/Tips'
import ErrorPage from '../ErrorPage'
import { getCampaignById } from '../../helpers/queries/campaign/getCampaignById'
import { AdNavigation, emptyAdNavigation } from '../../types/ad/ad'
import { Status, StatusTranslation, getIsEditable } from '../../types/status'
import Breadcrumb from '../../components/BreadCrumb'
import { FormatAPIEnum } from '../../types/format'
import {
  checkIfUpdateGoogleImageAdOutput,
  updateGoogleImageAd
} from '../../helpers/queries/ad/google/updateImageAd'
import CarouselPreview from '../../components/PreviewAds/Google/Display/CarouselPreview'
import Colors from '../../constants/colors'
import DefaultText from '../../components/DefaultText'
import { getRandomArrayElement } from '../../helpers/getRandomArrayElement'
import { convertObjectToFormData } from '../../helpers/convertObjectToFromData'
import {
  ImageAdFilesErrors,
  checkIfEmptyErrors,
  getFormErrors,
  getImagesErrors
} from '../../helpers/imageAd/formErrors'
import {
  checkIfGetAdOutputGoogleImageAdDetails,
  getAdById
} from '../../helpers/queries/ad/getAdById'
import { PlatformAPIEnum } from '../../types/platform'
import {
  ImageAdCreationFormDataType,
  getGoogleAdImagesKeys
} from '../../types/ad/google/imageAd'
import getImageById from '../../helpers/queries/ad/getImageById'
import { convertBase64ToUrl } from '../../helpers/convertBase64ToUrl'
import { TipsImage } from './TipsImage'
import { getMarketingImagesSelectionPanel } from './MarketingImagesSelection'
import { getSquareMarketingImagesSelectionPanel } from './SquareMarketingImagesSelection'
import { getLogoSelectionPanel } from './LogoSelection'
import { getNamePanel } from './Name'
import { getUrlPanel } from './Url'
import { getLongheadlinePanel } from './Longheadline'
import { getHeadlinesPanel } from './Headlines'
import { getDescriptionsPanel } from './Descriptions'
import { ImageAdFiles, ImageAdFilesUrls } from './types'
import { base64ToFile, getImageContentType } from '../../helpers/image'
import { FlowEnum } from '../../types/flow'

type ImagesType = {
  logo: string
  adImageSquare: string
  adImageRectangle: string
}

const GoogleImageAdEdit = () => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [errorFromApi, setErrorFromApi] = useState('')
  const [hasFetchError, setHasFetchError] = useState(false)
  const [navigation, setNavigation] = useState<AdNavigation>(emptyAdNavigation)
  const [campaignStatus, setCampaignStatus] = useState<Status | null>(null)
  const [selectedPreview, setSelectedPreview] = useState(1)
  const [previewImages, setPreviewImages] = useState<ImagesType>({
    logo: '',
    adImageSquare: '',
    adImageRectangle: ''
  })
  const [formData, setFormData] = useState<ImageAdCreationFormDataType>({
    name: '',
    businessName: '',
    campaignId: '',
    url: '',
    logo: null,
    headline1: '',
    headline2: '',
    headline3: '',
    longHeadline: '',
    description1: '',
    description2: '',
    description3: '',
    marketingImage1: null,
    marketingImage2: null,
    marketingImage3: null,
    squareMarketingImage1: null,
    squareMarketingImage2: null,
    squareMarketingImage3: null
  })
  const [imageUrls, setImageUrls] = useState<ImageAdFilesUrls>({
    logo: '',
    marketingImage1: '',
    marketingImage2: '',
    marketingImage3: '',
    squareMarketingImage1: '',
    squareMarketingImage2: '',
    squareMarketingImage3: ''
  })
  const [imageErrors, setImageErrors] = useState<ImageAdFilesErrors>({
    logo: '',
    marketingImage1: '',
    marketingImage2: '',
    marketingImage3: '',
    squareMarketingImage1: '',
    squareMarketingImage2: '',
    squareMarketingImage3: ''
  })

  const areAllImagesGivenRef = useRef(false)

  const { adId } = useParams()

  const navigate = useNavigate()

  const { isEditor, user } = useContext(AppContext)

  const params = new URLSearchParams(location.search)
  const flow = params.get('flow') as FlowEnum

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const adData = await getAdById({
          adId,
          adType: FormatAPIEnum.IMAGE,
          platform: PlatformAPIEnum.GOOGLE
        })
        if (!(adData && checkIfGetAdOutputGoogleImageAdDetails(adData))) {
          setHasFetchError(true)
          return
        }

        const campaign = await getCampaignById({
          campaignId: adData.navigation.campaign.id,
          adType: FormatAPIEnum.IMAGE
        })

        if (campaign && campaign.navigation) {
          const imageAdData: ImageAdCreationFormDataType = {
            name: adData.ad.name,
            url: adData.ad.url,
            headline1: adData.ad.headline1,
            headline2: adData.ad.headline2,
            headline3: adData.ad.headline3,
            longHeadline: adData.ad.longHeadline,
            description1: adData.ad.description1,
            description2: adData.ad.description2,
            description3: adData.ad.description3,
            businessName: adData.ad.businessName,
            campaignId: '',
            logo: null,
            marketingImage1: null,
            marketingImage2: null,
            marketingImage3: null,
            squareMarketingImage1: null,
            squareMarketingImage2: null,
            squareMarketingImage3: null
          }

          const imageIds = {
            logo: adData.ad.logo,
            marketingImage1: adData.ad.marketingImage1,
            marketingImage2: adData.ad.marketingImage2,
            marketingImage3: adData.ad.marketingImage3,
            squareMarketingImage1: adData.ad.squareMarketingImage1,
            squareMarketingImage2: adData.ad.squareMarketingImage2,
            squareMarketingImage3: adData.ad.squareMarketingImage3
          }

          const imageUrls = {
            logo: '',
            marketingImage1: '',
            marketingImage2: '',
            marketingImage3: '',
            squareMarketingImage1: '',
            squareMarketingImage2: '',
            squareMarketingImage3: ''
          }

          const googleImageKeys = getGoogleAdImagesKeys()

          for (let i = 0; i < googleImageKeys.length; i++) {
            const imageAttribute = googleImageKeys[i]

            if (imageIds[imageAttribute]) {
              const imageResult = await getImageById(imageIds[imageAttribute])
              if (imageResult) {
                const imageUrl = convertBase64ToUrl(imageResult)
                const file = base64ToFile(imageResult, imageAttribute)
                imageUrls[imageAttribute] = imageUrl
                imageAdData[imageAttribute] = file
              }
            }
          }

          setCampaignStatus(campaign.campaign.status)
          setNavigation(campaign.navigation)
          setFormData({
            ...imageAdData,
            campaignId: adData.navigation.campaign.id
          })
          setImageUrls(imageUrls)
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [adId])

  useEffect(() => {
    if (isSubmitLoading) {
      if (!adId) {
        console.error("Aucune annonce n'a été sélectionnée.")
      } else {
        ;(async function () {
          try {
            const form = convertObjectToFormData({
              ...formData,
              adId
            })
            const response = await updateGoogleImageAd({
              form
            })

            setIsSubmitLoading(false)

            if (checkIfUpdateGoogleImageAdOutput(response)) {
              navigate(`/campaign/${formData.campaignId}/review`)
            } else {
              console.error(response.error)
              setErrorFromApi(response.error)
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            }
          } catch (error) {
            console.error(
              "Une erreur s'est produite lors de l'édition de l'annonce :",
              error
            )
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  useEffect(() => {
    const areAllImagesGiven =
      formData.logo &&
      formData.marketingImage1 &&
      formData.squareMarketingImage1

    if (!areAllImagesGivenRef.current && areAllImagesGiven) {
      areAllImagesGivenRef.current = true
      setPreviewImages((prevImages) => ({
        ...prevImages,
        logo: imageUrls.logo,
        adImageRectangle: imageUrls.marketingImage1,
        adImageSquare: imageUrls.squareMarketingImage1
      }))
    } else if (areAllImagesGivenRef.current && !areAllImagesGiven) {
      areAllImagesGivenRef.current = false
      setPreviewImages((prevImages) => ({
        ...prevImages,
        logo: '',
        adImageRectangle: '',
        adImageSquare: ''
      }))
    }
  }, [imageUrls])

  useEffect(() => {
    const adImageSquaresUrls = [
      imageUrls.squareMarketingImage1,
      imageUrls.squareMarketingImage2,
      imageUrls.squareMarketingImage3
    ].filter((item) => item !== '')
    const adImageRectanglesUrls = [
      imageUrls.marketingImage1,
      imageUrls.marketingImage2,
      imageUrls.marketingImage3
    ].filter((item) => item !== '')

    const randomAdImageSquareUrl =
      getRandomArrayElement<string>(adImageSquaresUrls)
    const randomAdImageRectangleUrl = getRandomArrayElement<string>(
      adImageRectanglesUrls
    )

    setPreviewImages((prevImages) => ({
      ...prevImages,
      adImageRectangle: randomAdImageRectangleUrl,
      adImageSquare: randomAdImageSquareUrl
    }))
  }, [selectedPreview])

  if (hasFetchError || !adId) {
    return <ErrorPage />
  }

  if (!navigation || !campaignStatus) {
    return <LoadingPage />
  }

  if (!getIsEditable({ status: campaignStatus, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas modifier d'annonce pour cette campagne au statut ${StatusTranslation[
          campaignStatus
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Revenir à la campagne',
          onClick: () => {
            navigate(`/campaign/${formData.campaignId}/details`)
          }
        }}
      />
    )
  }

  const isPreviewDisplayed =
    areAllImagesGivenRef.current &&
    imageErrors.logo === '' &&
    imageErrors.marketingImage1 === '' &&
    imageErrors.squareMarketingImage1 === ''

  const errors = getFormErrors(formData)

  const handleImageError = async (
    attribute: keyof ImageAdFiles,
    img: File | null
  ) => {
    const newImageErrors = await getImagesErrors(attribute, img, imageErrors)

    setImageErrors(newImageErrors)
  }

  const handleInputChange = (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [attribute]: e.target.value })
  }

  const handleTextAreaChange = (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [attribute]: e.target.value })
  }

  const handleImageChange = (
    attribute: keyof ImageAdFiles,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFormData = { ...formData }
    if (e.target.files && e.target.files[0]) {
      const imageUrl = URL.createObjectURL(e.target.files[0])
      newFormData[attribute] = e.target.files[0]
      const logo = newFormData.logo

      if (attribute === 'logo' && logo) {
        setPreviewImages((prevImages) => ({
          ...prevImages,
          logo: imageUrl
        }))
      }

      handleImageError(attribute, e.target.files[0])
      setFormData(newFormData)
      setImageUrls((prevUrls) => ({ ...prevUrls, [attribute]: imageUrl }))
    }
  }

  const handleImageDelete = (attribute: keyof ImageAdFiles) => {
    const newFormData = { ...formData }
    newFormData[attribute] = null

    handleImageError(attribute, null)
    setFormData(newFormData)
    setImageUrls((prevUrls) => ({ ...prevUrls, [attribute]: '' }))
  }

  const handleSubmit = () => {
    setIsSubmitLoading(true)
  }

  const titles = [
    formData.headline1,
    formData.headline2,
    formData.headline3
  ].filter((item) => item)
  const descriptions = [
    formData.description1,
    formData.description2,
    formData.description3
  ].filter((item) => item)

  const randomTitle = getRandomArrayElement<string>(titles)
  const randomDescription = getRandomArrayElement<string>(descriptions)

  const handleOnClickNextPreview = () => {
    setSelectedPreview((prevSelectedPreview) =>
      prevSelectedPreview >= 9 ? 1 : prevSelectedPreview + 1
    )
  }

  // TODO: get the main color of the image and handle the change of color according to the
  // selected preview (use the color of the square or the rectangle image according to the preview)
  const fakeColor = Colors.YETIC_GREY_AD

  const breadcrumbItems = [
    {
      label: navigation.company.name
    },
    {
      onClick: () => {
        navigate(`/media-plan/${navigation.mediaPlan.id}/review`)
      },
      label: navigation.mediaPlan.name
    },
    {
      onClick: () => {
        navigate(`/campaign/${navigation.campaign.id}/review`)
      },
      label: `Campagne « ${navigation.campaign.name} »`
    },
    {
      label: `Annonce « ${formData.name} »`,
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(
              `/ad/${adId ?? ''}/review?platform=${PlatformAPIEnum.GOOGLE}`
            )
          : navigate(`/ad/${adId ?? ''}/image/details`)
      }
    },
    { label: `Édition` }
  ]

  const namePanel: PanelType = getNamePanel({
    formData,
    errors,
    hasBeenSubmitted
  })

  const urlPanel: PanelType = getUrlPanel({
    formData,
    errors,
    hasBeenSubmitted
  })

  const longHeadlinePanel: PanelType = getLongheadlinePanel({
    formData,
    handleInputChange,
    errors,
    hasBeenSubmitted
  })

  const headlinesPanel: PanelType = getHeadlinesPanel({
    formData,
    handleInputChange,
    errors,
    hasBeenSubmitted
  })

  const descriptionsPanel: PanelType = getDescriptionsPanel({
    formData,
    handleTextAreaChange,
    errors,
    hasBeenSubmitted
  })

  const tipsImages = <TipsImage />

  const marketingImagesSelectionPanel: PanelType =
    getMarketingImagesSelectionPanel({
      formData,
      imageUrls,
      imageErrors,
      handleImageChange,
      handleImageDelete,
      hasBeenSubmitted
    })

  const squareMarketingImagesSelectionPanel: PanelType =
    getSquareMarketingImagesSelectionPanel({
      formData,
      imageUrls,
      imageErrors,
      handleImageChange,
      handleImageDelete,
      hasBeenSubmitted
    })

  const logoSelectionPanel: PanelType = getLogoSelectionPanel({
    formData,
    imageUrls,
    imageErrors,
    handleImageChange,
    handleImageDelete,
    hasBeenSubmitted
  })

  const previewPanel: PanelType = {
    title: 'Aperçu de votre annonce',
    disabled: false,
    isDropdown: false,
    sections: [
      {
        description: 'Aperçu',
        content: isPreviewDisplayed ? (
          <PreviewContainer>
            <CarouselPreview
              selectedPreview={selectedPreview}
              setSelectedPreview={setSelectedPreview}
              handleOnClickNextPreview={handleOnClickNextPreview}
              title={randomTitle}
              description={randomDescription}
              businessName={formData.businessName}
              adImageSquare={previewImages.adImageSquare}
              adImageRectangle={previewImages.adImageRectangle}
              logoImage={previewImages.logo}
              buttonColor={fakeColor}
            />
          </PreviewContainer>
        ) : (
          <DefaultText style={previewMessageStyle}>
            {`Veuillez ajouter les images requises pour voir l'aperçu.`}
          </DefaultText>
        )
      }
    ]
  }

  const panels: (JSX.Element | PanelType)[] = [
    namePanel,
    urlPanel,
    longHeadlinePanel,
    headlinesPanel,
    descriptionsPanel,
    tipsImages,
    marketingImagesSelectionPanel,
    squareMarketingImagesSelectionPanel,
    logoSelectionPanel,
    previewPanel
  ]

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Édition de l'annonce « ${formData.name} »`}
        subTitle={`Mise à jour des caractéristiques de votre annonce`}
        panels={panels}
        validation={{
          action: () => {
            if (checkIfEmptyErrors(errors, imageErrors)) {
              handleSubmit()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled:
            hasBeenSubmitted && !checkIfEmptyErrors(errors, imageErrors),
          wording: 'Enregistrer et continuer',
          isLoading: isSubmitLoading
        }}
        errors={{
          errors: hasBeenSubmitted && errorFromApi ? [errorFromApi] : []
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            navigate(`/campaign/${formData?.campaignId ?? ''}/review`)
          },
          disabled: false
        }}
      />
    </AuthenticatedTemplate>
  )
}

export default GoogleImageAdEdit
