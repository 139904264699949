import React, { ChangeEvent } from 'react'

import Input from '../../../../components/Input'

type NameProps = {
  value: string
  error?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Name = ({ value, onChange, error }: NameProps) => {
  return (
    <Input
      type="text"
      placeholder="Public cible n°1"
      value={value}
      onChange={onChange}
      label="Nom du public cible"
      error={error}
      withBorder
    />
  )
}

export default Name
