export enum ReportingAPISectionEnum {
  ads = 'ads',
  audiences = 'audiences',
  devices = 'devices',
  global = 'global',
  keywords = 'keywords',
  overall = 'overall',
  impact = 'impact',
  highlight = 'highlight',
  download = 'download',
  campaigns = 'campaigns'
}

export const checkIfReportingAPISectionEnum = (
  value: any
): value is ReportingAPISectionEnum => {
  return (
    value &&
    Object.values(ReportingAPISectionEnum).includes(
      value as ReportingAPISectionEnum
    )
  )
}
