import React from 'react'

import { ImpactTooltipTitle } from './styles'
import DefaultText from '../../../../../components/DefaultText'

const ImpactTooltip = () => {
  return (
    <div>
      <ImpactTooltipTitle>
        <DefaultText bold>Emissions de CO₂ évitées</DefaultText>
      </ImpactTooltipTitle>
      <DefaultText size="small">
        {`Ce pourcentage est la différence entre le CO₂eq d'une campagne créée sur le Yetic Ad Manager et le CO₂eq d'une campagne avec un paramétrage considéré moyen.`}
      </DefaultText>
      <DefaultText size="small">
        {`Ce paramétrage moyen est basé sur des données de Mediametrie et Httparchive.`}
      </DefaultText>
    </div>
  )
}

export default ImpactTooltip
