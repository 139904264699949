export type UserType = {
  firstName: string
  lastName: string
  email?: string
}

export type RegisterUserType = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  confirmPassword: string
  role?: string
}

export const emptyRegisterUser: RegisterUserType = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  role: ''
}

export const checkIfUser = (value: any): value is UserType => {
  return (
    value &&
    value.firstName &&
    typeof value.firstName === 'string' &&
    value.lastName &&
    typeof value.lastName === 'string' &&
    (!value.email || (value.email && typeof value.email === 'string'))
  )
}
