import React from 'react'

import { formatOverallData } from './helpers/formatData'
import { OverallTabContainer } from './styles'
import Heatmap from '../../../../components/Chart/Heatmap'
import BarChart from '../../../../components/Chart/BarChart'
import { chartGap } from '../../styles'
import { GetCampaignOverallReportingOutputType } from '../../../../types/reporting/campaign/output'
import { formatAnalyticsHeaderData } from '../../Chart/helpers/formatHeaderData'
import { RangeSelectionIdEnum } from '../../../../types/lineChart'
import LineChart from '../../../../components/Chart/LineChart'
import { RangeSelectorData } from '../../../../components/Chart/LineChart/types'
import { Point } from '../../../../components/Chart/LineChart/LineChartDrawer'
import { renderDefaultTooltip } from '../../../../components/Chart/LineChart/Tooltips/Default/renderer'
import DefaultLineChartTooltip from '../../../../components/Chart/LineChart/Tooltips/Default'

type OverallTabProps = {
  reportingData: GetCampaignOverallReportingOutputType
  contentWidth: number
}

const OverallTab = ({ reportingData, contentWidth }: OverallTabProps) => {
  const {
    kpiLineChartData,
    genderAgeHeatmapData,
    performancesPerDeviceBarchartData,
    globalCTR
  } = formatOverallData(reportingData)

  const initialSelectionNames = ['Clics', 'Impressions']

  const chartWidth =
    screen.width > 1300 ? (contentWidth - chartGap * 2) / 2 : contentWidth

  const rangeSelectorData: RangeSelectorData<Point> = {
    selectionOptions: [
      {
        id: RangeSelectionIdEnum.WEEK,
        label: 'Semaine'
      },
      {
        id: RangeSelectionIdEnum.MONTH,
        label: 'Mois'
      },
      {
        id: RangeSelectionIdEnum.DAY,
        label: 'Jours'
      }
    ],
    defaultSelectionId: RangeSelectionIdEnum.DAY,
    buildAggregatedPoint: (aggregatedPoints, aggregatedValue) => {
      const aggregation = {
        value: aggregatedValue,
        time: aggregatedPoints[0].time,
        endTime:
          aggregatedPoints[0].time ===
          aggregatedPoints[aggregatedPoints.length - 1].time
            ? undefined
            : aggregatedPoints[aggregatedPoints.length - 1].time
      }

      return aggregation
    }
  }

  const chartId = `id${Math.random().toString(36).substring(2)}`

  const TooltipComponent = DefaultLineChartTooltip
  const renderTooltip = renderDefaultTooltip

  return (
    <OverallTabContainer>
      <LineChart
        data={kpiLineChartData}
        initialSelectionNames={initialSelectionNames}
        width={chartWidth}
        formatHeaderData={(data, initialSelectionNames) =>
          formatAnalyticsHeaderData(data, initialSelectionNames, globalCTR)
        }
        rangeSelectorData={rangeSelectorData}
        renderTooltip={renderTooltip}
        tooltipComponent={TooltipComponent}
        chartId={chartId}
      />
      <BarChart
        data={performancesPerDeviceBarchartData}
        title={'Performances des annonces par appareil'}
        width={chartWidth}
        horizontal
      />
      <Heatmap
        data={genderAgeHeatmapData}
        displayedIntensityIndex={0}
        title={'Clics et impressions par âge et genre'}
        width={chartWidth}
      />
    </OverallTabContainer>
  )
}

export default OverallTab
