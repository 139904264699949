import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'

export const modalStyle: CSSProperties = {
  maxWidth: '400px'
}

export const textStyle: CSSProperties = {
  textAlign: 'left',
  margin: `${Margin.m7} 0 0`
}

export const ChoiceContainer = styled.div`
  margin: ${Margin.m7} 0 ${Margin.m5};
`
