import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../constants/margin'
import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'
import FontSize from '../../../../constants/fontSize'

export const modalStyle: CSSProperties = {
  height: '90vh',
  maxHeight: '800px',
  width: '600px',
  display: 'flex',
  flexDirection: 'column',
  flex: 12
}

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Margin.m4};
  margin-bottom: ${Margin.m6};
`

export const DivTabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${Margin.m8};
  background-color: ${Colors.YETIC_WHITE};
  border: 1px solid ${Colors.YETIC_BLUE_DARK};
  border-radius: 100px;
  overflow: hidden;
`

export const tabStyle: CSSProperties = {
  fontSize: FontSize.YETIC_TITLE_THIN
}

export const Container = styled.div`
  flex: 8;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${Margin.m6};
  padding: 0 ${Padding.p5};
  box-sizing: border-box;
  overflow: visible;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const Item = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: ${Padding.p5};
  ${(props) =>
    props.withBorder ? `border-bottom: 1px solid rgba(0, 0, 0, 0.3);` : ''}
`

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const ItemSubheader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const ItemHeaderTextContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m2};
`

export const valueTextStyle: CSSProperties = {
  fontSize: FontSize.YETIC_TITLE_THIN,
  fontWeight: 'bold'
}

export const percentageTextStyle: CSSProperties = {
  color: Colors.YETIC_BLUE_LIGHT,
  position: 'absolute',
  marginTop: '28px'
}

export const Description = styled.div`
  width: 80%;
  text-align: left;
  margin-bottom: ${Margin.m3};
`

export const Comment = styled.div`
  width: 80%;
  text-align: left;
`

export const descriptionTextStyle: CSSProperties = {
  color: Colors.YETIC_BLUE_LIGHT,
  lineHeight: '1.5'
}

export const commentTextStyle: CSSProperties = {
  color: Colors.YETIC_BLUE_LIGHT,
  lineHeight: '1.5',
  fontStyle: 'italic'
}

export const RowValue = styled(Value)`
  margin-top: ${Margin.m4};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${Margin.m1};
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${Margin.m4};
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: ${Padding.p6};
`

export const leafTotalStyle: CSSProperties = {
  marginRight: `${Margin.m3}`
}

export const CO2Total = styled.div`
  display: flex;
  justify-content: justify-content;
  gap: ${Margin.m7};
  align-items: flex-end;
`

export const co2TotalTextStyle: CSSProperties = {
  width: '240px',
  textAlign: 'right',
  fontSize: FontSize.YETIC_SUBTITLE
}

export const KnowMoreContainer = styled.div`
  color: ${Colors.YETIC_BLUE_LIGHT};
  align-self: flex-start;
  display: flex;
  justify-content: flex-start;
  gap: ${Margin.m3};
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const knowMoreTextStyle: CSSProperties = {
  color: Colors.YETIC_BLUE_LIGHT,
  lineHeight: '1.5',
  textAlign: 'left'
}

export const arrowStyle: CSSProperties = {
  marginTop: '1px'
}

export const simulationSubtitleStyle: CSSProperties = {
  textAlign: 'left'
}

export const checkboxStyle: CSSProperties = {
  height: '25px',
  marginLeft: Margin.m2,
  marginTop: Margin.m2
}

export const inputStyle: CSSProperties = {
  marginLeft: '5px',
  width: '90px',
  border: `1px solid ${Colors.YETIC_GREY_DARK}`,
  height: '36px',
  paddingLeft: `${Padding.p3}`,
  paddingRight: `${Padding.p3}`,
  textAlign: 'center'
}

export const suffixInputStyle: CSSProperties = {
  marginBottom: '0px',
  marginLeft: `${Margin.m2}`
}

export const ProportionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  marginleft: ${Margin.m3};
`

export const LabelledInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  margin-bottom: ${Margin.m2};
`

export const InputPercentage = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.m2};
`

export const changeValueButtonStyle: CSSProperties = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: Colors.YETIC_BLUE_DARK,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: Colors.YETIC_WHITE,
  fontSize: FontSize.YETIC_TITLE,
  border: 'none',
  cursor: 'pointer'
}

export const substractionButtonStyle: CSSProperties = {
  ...changeValueButtonStyle,
  paddingTop: '10px'
}

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`
