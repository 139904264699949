import React, { ChangeEvent, useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import Input from '../../../../components/Input'
import { PanelType } from '../../../../types/templates/panels'
import { FormAdType, FormAdError } from '../../../../types/pages/adCreation'
import { AppContext } from '../../../../contexts/AppContext'
import Margin from '../../../../constants/margin'
import GooglePreview from '../../../../components/PreviewAds/Google/Text'
import Button from '../../../../components/Button'
import {
  CreateAdInputType,
  createAd
} from '../../../../helpers/queries/ad/create'
import DefaultText from '../../../../components/DefaultText'
import { deepClone } from '../../../../helpers/clone'
import { getCampaignById } from '../../../../helpers/queries/campaign/getCampaignById'
import {
  CreateAdErrorFromApi,
  checkIfCreateAdErrorCodeValid
} from '../../../../types/error/ad/create'
import Breadcrumb from '../../../../components/BreadCrumb'
import { AdNavigation, emptyAdNavigation } from '../../../../types/ad/ad'
import {
  checkMoreThanOneCharacter,
  checkUrl
} from '../../../../helpers/checkers'
import ErrorPage from '../../../ErrorPage'
import LoadingPage from '../../../LoadingPage'
import {
  Status,
  StatusTranslation,
  getIsEditable
} from '../../../../types/status'
import { FormatAPIEnum } from '../../../../types/format'
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal'
import { ModalContent } from './styles'
import { FlowEnum } from '../../../../types/flow'
import { TipsFormatProps } from '../../../../components/Tips/TipsFormat'

const emptyFormAd = {
  name: '',
  url: '',
  titles: ['', '', ''],
  descriptions: ['', '', ''],
  siteLinks: [],
  catchphrase: []
}

type AdCreationErrors = {
  name: string
  url: string
  titles: string[]
  descriptions: string[]
  errorFromApi: string
}

const EditorGoogleTextAdCreation = () => {
  const [titles, setTitles] = useState(['', '', ''])
  const [descriptions, setDescriptions] = useState(['', '', ''])
  // const [links, setLinks] = useState<OptionLink[]>([])
  // const [catchphrases, setCatchphrases] = useState<string[]>([''])
  const [formAd, setFormAd] = useState<FormAdType>(emptyFormAd)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [showAdditionalParameters, setShowAdditionalParameters] =
    useState(false)
  const [mediaPlanId, setMediaPlanId] = useState<string | null>(null)
  const [errorFromApi, setErrorFromApi] = useState<string>('')
  const [navigation, setNavigation] = useState<AdNavigation>(emptyAdNavigation)
  const [campaignStatus, setCampaignStatus] = useState<Status | null>(null)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false)
  const [keywords, setKeywords] = useState<string[]>([])

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()

  const { isEditor, user } = useContext(AppContext)

  const { campaignId } = useParams()

  const params = new URLSearchParams(location.search)
  const flow = params.get('flow') as FlowEnum | null

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const result = await getCampaignById({
          campaignId,
          adType: FormatAPIEnum.TEXT
        })
        if (result && result.campaign.ads && result.campaign.ads.length >= 3) {
          navigate(`/campaign/${campaignId}/review`)
        }
        if (result && result.campaign.mediaPlanId && result.navigation) {
          setMediaPlanId(result.campaign.mediaPlanId)
          setCampaignStatus(result.campaign.status)
          setNavigation(result.navigation)
          setKeywords(result.campaign.keywords ?? [])
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [campaignId])

  useEffect(() => {
    if (isSubmitLoading) {
      if (!campaignId) {
        console.error("Aucune campagne n'a été sélectionnée.")
      } else {
        ;(async function () {
          try {
            const filteredTitles = formAd.titles
              .filter((title) => title.trim() !== '')
              .slice(0, 8)
            const filteredTitlesLength = filteredTitles.length
            if (filteredTitlesLength < 8) {
              for (let i = filteredTitlesLength; i < 8; i++) {
                filteredTitles.push('')
              }
            }

            const adData: CreateAdInputType = {
              campaignId,
              name: formAd.name,
              url: formAd.url,
              title1: filteredTitles[0],
              title2: filteredTitles[1],
              title3: filteredTitles[2],
              title4: filteredTitles[3],
              title5: filteredTitles[4],
              title6: filteredTitles[5],
              title7: filteredTitles[6],
              title8: filteredTitles[7],
              description1: formAd.descriptions[0],
              description2: formAd.descriptions[1],
              description3: formAd.descriptions[2],
              catchphrases: formAd.catchphrase || [],
              siteLinks: formAd.siteLinks
            }
            const response = await createAd(adData)

            setIsSubmitLoading(false)

            if (response && response.adId) {
              navigate(`/campaign/${campaignId}/review`)
            } else {
              console.error("La création de l'annonce a échoué.")
              if (response && response.error) {
                setErrorFromApi(
                  checkIfCreateAdErrorCodeValid(response.error)
                    ? CreateAdErrorFromApi[response.error]
                    : CreateAdErrorFromApi.UNKNOWN
                )
              } else {
                setErrorFromApi(CreateAdErrorFromApi.UNKNOWN)
              }
              setTimeout(() => {
                setErrorFromApi('')
              }, 5000)
            }
          } catch (error) {
            console.error(
              "Une erreur s'est produite lors de la création de l'annonce :",
              error
            )
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  if (hasFetchError || !campaignId) {
    return <ErrorPage />
  }

  if (!mediaPlanId || !navigation || !campaignStatus) {
    return <LoadingPage />
  }

  if (!getIsEditable({ status: campaignStatus, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas créer d'annonce pour cette campagne au statut ${StatusTranslation[
          campaignStatus
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Revenir à la campagne',
          onClick: () => {
            navigate(`/campaign/${campaignId}/details`)
          }
        }}
      />
    )
  }

  const addToPanels = (
    panels: (PanelType | JSX.Element)[],
    title: string,
    sections: JSX.Element[],
    description?: string,
    titleTooltip?: string,
    tip?: { title: string; content: string },
    tipsFormat?: TipsFormatProps
  ) => {
    panels.push({
      title,
      description,
      disabled: false,
      sections,
      isDropdown: false,
      titleTooltip,
      tip,
      tipsFormat
    })
  }

  const handleChangeTitle = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newTitles = [...titles]
    newTitles[index] = event.target.value
    setTitles(newTitles)
    const newFormAdTitles = [...newTitles]
    setFormAd({ ...deepClone(formAd), titles: newFormAdTitles })
  }

  const handleChangeDescription = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDescriptions = [...descriptions]
    newDescriptions[index] = event.target.value
    setDescriptions(newDescriptions)
    const newFormAdDescriptions = [...formAd.descriptions]
    newFormAdDescriptions[index] = event.target.value
    setFormAd({ ...deepClone(formAd), descriptions: newFormAdDescriptions })
  }

  const handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setFormAd({ ...deepClone(formAd), url: event.target.value })
  }

  const handleAdNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormAd({ ...deepClone(formAd), name: event.target.value })
  }

  const handleOpenLeaveModal = () => {
    setIsLeaveModalOpen(true)
  }

  const handleCloseLeaveModal = () => {
    setIsLeaveModalOpen(false)
  }

  const breadcrumb = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate('/customers')
      },
      label: 'Clients'
    },
    {
      onClick: () => {
        handleOpenLeaveModal()
      },
      label: navigation.company.name
    },
    {
      onClick:
        flow === FlowEnum.view
          ? () => {
              navigate(`/media-plan/${navigation.mediaPlan.id}/details`)
            }
          : () => {
              navigate(`/media-plan/${navigation.mediaPlan.id}/review`)
            },
      label: navigation.mediaPlan.name
    },
    {
      onClick:
        flow === FlowEnum.view
          ? () => {
              navigate(`/campaign/${navigation.campaign.id}/details`)
            }
          : () => {
              navigate(`/campaign/${navigation.campaign.id}/review`)
            },
      label: `Campagne « ${navigation.campaign.name} »`
    },
    { label: `Création d'annonce` }
  ]

  // const handleAddLink = () => {
  //   const newLinks = [...links]
  //   newLinks.push({ title: '', description1: '', description2: '', url: '' })
  //   setLinks(newLinks)
  // }

  // const handleRemoveLink = (index: number) => {
  //   const newLinks = [...links]
  //   newLinks.splice(index, 1)
  //   setLinks(newLinks)
  // }

  // const handleChangeLink = (
  //   index: number,
  //   field: keyof OptionLink,
  //   value: string
  // ) => {
  //   const newLinks = [...links]
  //   newLinks[index][field] = value
  //   setFormAd({ ...deepClone(formAd), siteLinks: newLinks })
  // }

  // const handleAddCatchphrase = () => {
  //   const newCatchphrase = [...catchphrases, '']
  //   setCatchphrases(newCatchphrase)
  // }

  // const handleRemoveCatchphrase = (index: number) => {
  //   const newCatchphrase = [...catchphrases]
  //   newCatchphrase.splice(index, 1)
  //   setCatchphrases(newCatchphrase)
  // }

  // const handleChangeCatchphrase = (index: number, value: string) => {
  //   const newCatchphrase = [...catchphrases]
  //   newCatchphrase[index] = value
  //   setCatchphrases(newCatchphrase)
  //   setFormAd({ ...deepClone(formAd), catchphrase: newCatchphrase })
  // }

  const handleToggleAdditionalParameters = () => {
    setShowAdditionalParameters(!showAdditionalParameters)
  }

  const handleSubmit = () => {
    if (checkIfEmptyErrors()) {
      setIsSubmitLoading(true)
    }
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    navigate(`/campaign/${campaignId}/review`)
  }

  const handleAddTitle = () => {
    if (titles.length < 8) {
      setTitles([...titles, ''])
    }
  }

  // const checkSiteLinks = () => {
  //   return formAd.siteLinks.length > 0
  // }

  // const checkSiteLinksTitle = () => {
  //   return formAd.siteLinks.every((link) => {
  //     return link.title.length > 1
  //   })
  // }

  // const checkSiteLinksUrl = () => {
  //   return formAd.siteLinks.every((link) => {
  //     return checkUrl(link.url)
  //   })
  // }

  const errors: AdCreationErrors = {
    name: checkMoreThanOneCharacter(formAd.name) ? '' : FormAdError.NAME,
    url: checkUrl(formAd.url) ? '' : FormAdError.URL,
    titles: formAd.titles.map((title, index) =>
      index < 3 && !checkMoreThanOneCharacter(title) ? FormAdError.TITLE : ''
    ),
    descriptions: [
      checkMoreThanOneCharacter(formAd.descriptions[0])
        ? ''
        : FormAdError.DESCRIPTION,
      checkMoreThanOneCharacter(formAd.descriptions[1])
        ? ''
        : FormAdError.DESCRIPTION,
      checkMoreThanOneCharacter(formAd.descriptions[2]) ||
      !formAd.descriptions[2]
        ? ''
        : FormAdError.DESCRIPTION
    ],
    // checkSiteLinks() ? '' : FormAdError.MISSING_SITE_LINKS,
    // checkSiteLinksTitle() ? '' : FormAdError.SITE_LINKS_TITLE,
    // checkSiteLinksUrl() ? '' : FormAdError.SITE_LINKS_URL
    errorFromApi: errorFromApi ?? ''
  }

  const panels: (PanelType | JSX.Element)[] = []

  const inputName = (
    <Input
      type="text"
      onChange={handleAdNameChange}
      placeholder="Nom"
      maxLength={20}
      showCharacterCount
      value={formAd.name}
      label={`Nom de l'annonce`}
      error={hasBeenSubmitted ? errors.name : ''}
      withBorder
    />
  )

  const inputUrl = (
    <Input
      type="url"
      onChange={handleChangeUrl}
      placeholder="https://"
      showCharacterCount
      value={formAd.url}
      label={`URL finale`}
      error={hasBeenSubmitted ? errors.url : ''}
      withBorder
    />
  )

  // const inputTitles = (
  //   <>
  //     <div>
  //       {titles.map((title, index) => (
  //         <Input
  //           key={index}
  //           type="text"
  //           placeholder={`Titre ${index + 1}`}
  //           value={title}
  //           onChange={(event) => { handleChangeTitle(index, event) }}
  //           label={`Titre ${index + 1}`}
  //           maxLength={30}
  //           showCharacterCount
  //           containerStyle={{ marginBottom: Margin.m6 }}
  //           error={hasBeenSubmitted ? errors.titles[index] : ''}
  //           withBorder
  //         />
  //       ))}
  //       {titles.length < 8 && (
  //         <Button onClick={handleAddTitle} style={{ marginTop: '10px' }}>
  //           Plus de titres
  //         </Button>
  //       )}
  //     </div>
  //   </>
  // )

  const inputTitles = (
    <>
      <div>
        {titles.map((title, index) => (
          <Input
            key={index}
            type="text"
            placeholder={`Titre ${index + 1}`}
            value={title}
            onChange={(event) => {
              handleChangeTitle(index, event)
            }}
            label={`Titre ${index + 1}`}
            maxLength={30}
            isRequired={index < 3}
            showCharacterCount
            containerStyle={{ marginBottom: Margin.m6 }}
            error={hasBeenSubmitted && index < 3 ? errors.titles[index] : ''}
            withBorder
          />
        ))}
        {titles.length < 8 && (
          <Button
            type="secondary"
            onClick={handleAddTitle}
            style={{ marginTop: '10px', width: '250px' }}
          >
            Plus de titres
          </Button>
        )}
      </div>
    </>
  )

  const inputDescriptions = (
    <div>
      {descriptions.slice(0, 2).map((description, index) => (
        <Input
          key={index}
          type="textarea"
          placeholder={`Description ${index + 1}`}
          value={description}
          onChange={(event) => {
            handleChangeDescription(index, event)
          }}
          label={`Description ${index + 1}`}
          maxLength={90}
          showCharacterCount
          containerStyle={{ marginBottom: Margin.m6 }}
          error={hasBeenSubmitted ? errors.descriptions[index] : ''}
          withBorder
        />
      ))}
    </div>
  )

  const inputPreview = (
    <GooglePreview
      url={formAd.url}
      title1={formAd.titles[0]}
      title2={formAd.titles[1]}
      title3={formAd.titles[2]}
      description={formAd.descriptions[0]}
      description2={formAd.descriptions[1]}
      description3={formAd.descriptions[2]}
    />
  )

  /*
  const inputSiteLinks = (
    <SiteLinks
      links={links}
      onAddLink={handleAddLink}
      onChangeLink={handleChangeLink}
      onRemoveLink={handleRemoveLink}
    />
  )
  const inputCatchphrases = (
    <Catchphrases
      catchphrases={catchphrases}
      onAddCatchphrase={handleAddCatchphrase}
      onChangeCatchphrase={handleChangeCatchphrase}
      onRemoveCatchphrase={handleRemoveCatchphrase}
    />
  )
  */

  const MoreDescriptionSection = (
    <Input
      type="textarea"
      onChange={(event) => {
        handleChangeDescription(2, event)
      }}
      placeholder="Description 3"
      label={`Description 3`}
      isRequired={false}
      value={formAd.descriptions[2]}
      maxLength={90}
      showCharacterCount
      containerStyle={{ marginTop: Margin.m6 }}
      error={hasBeenSubmitted ? errors.descriptions[2] : ''}
      withBorder
    />
  )

  const buttonMoreParameters = (
    <Button
      onClick={handleToggleAdditionalParameters}
      type="textButton"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: Margin.m6
      }}
    >
      {showAdditionalParameters ? (
        <DefaultText>{`Moins de paramètres`}</DefaultText>
      ) : (
        <DefaultText>{`Plus de paramètres`}</DefaultText>
      )}
    </Button>
  )

  addToPanels(panels, `Nom`, [inputName], `Donnez un nom à cette annonce`)
  addToPanels(
    panels,
    `Votre URL`,
    [inputUrl],
    `L'URL finale est l'adresse à laquelle vos clients potentiels arrivent après avoir cliqué sur votre annonce.`,
    ``
  )
  addToPanels(
    panels,
    `Vos titres`,
    [inputTitles],
    `Les titres sont les éléments situés en haut de votre annonce.`,
    `Votre annonce Google Ads doit commencer par un titre accrocheur, car c'est ce que les clients remarquent en premier.
    Utilisez des mots-clés pertinents pour attirer ceux qui effectuent des recherches.
    Vous pouvez créer trois titres pour promouvoir efficacement votre produit ou service.`,
    {
      title: '',
      content: ``
    },
    {
      title: 'Titres',
      items: [
        {
          title: 'Consignes pour la rédaction des titres de votre annonce',
          content: [
            'Il est préférable de renseigner un maximum de titres dans la limite de 8.',
            `Veuillez ne pas renseigner de noms de marques ou d'entreprises dans vos titres et descriptions !`,
            `Il est important d'inclure vos mots-clés dans vos titres. Pour rappel, vos mots-clés sont : ${keywords.join(
              ', '
            )}`
          ]
        }
      ]
    }
  )
  addToPanels(
    panels,
    `Vos descriptions`,
    [inputDescriptions],
    `Les descriptions apparaissent en dessous de l'URL à afficher.`,
    `Présentez clairement les détails sur vos offres.
    Ajoutez une phrase d'appel à l'action pour guider les clients, comme "Achetez maintenant" pour des produits ou "Obtenez un devis" pour des services.
    Cela clarifie ce que vous attendez de l'utilisateur après la lecture de l'annonce.`
  )
  // addToPanels(panels, `Vos liens annexes`, [inputSiteLinks])
  panels.push(buttonMoreParameters)
  if (showAdditionalParameters) {
    addToPanels(panels, `Description supplémentaire`, [MoreDescriptionSection])
    // addToPanels(panels, `Vos accroches`, [inputCatchphrases])
  }

  const checkIfEmptyErrors = () => {
    const { titles, descriptions, ...stringErrors } = errors
    const titleErrors = titles.slice(0, 3)
    return (
      Object.values(stringErrors).filter((e) => e).length === 0 &&
      titleErrors.filter((e) => e).length === 0 &&
      descriptions.filter((description) => description).length === 0
    )
  }

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumb} />
      <CreationPageTemplate
        title="Nouvelle annonce texte"
        subTitle={`Création de votre annonce texte Google Ads`}
        preview={inputPreview}
        panels={panels}
        errors={
          hasBeenSubmitted
            ? {
                errors: [
                  [
                    errorFromApi,
                    errors.name,
                    errors.url,
                    ...errors.titles,
                    ...errors.descriptions
                  ].filter((e) => e)[0]
                ]
              }
            : undefined
        }
        validation={{
          wording: 'Enregistrer et continuer',
          isLoading: isSubmitLoading,
          action: () => {
            if (checkIfEmptyErrors()) {
              handleSubmit()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled: hasBeenSubmitted && !checkIfEmptyErrors()
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
      />
      {isCancelModalOpen && (
        <ConfirmationModal
          title="L'annonce est en cours de création"
          textConfirm="Oui, annuler"
          textReject="Non, continuer"
          onClickReject={handleCloseCancelModal}
          onClickConfirm={handleCancelConfirm}
          onClose={handleCloseCancelModal}
        >
          <ModalContent>
            <DefaultText>
              {"Êtes-vous sûr de vouloir annuler la création de l'annonce ?"}
            </DefaultText>
            <DefaultText>
              {'Toutes les informations saisies seront perdues.'}
            </DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
      {isLeaveModalOpen && (
        <ConfirmationModal
          onClose={handleCloseLeaveModal}
          textConfirm="Quitter"
          textReject="Annuler et continuer"
          onClickConfirm={() => {
            navigate(`/customer/${navigation.company.id}/details`)
          }}
          onClickReject={handleCloseLeaveModal}
          title="Êtes-vous sûr de vouloir voir la fiche client ?"
        >
          <ModalContent>
            <DefaultText>{`Voulez-vous vraiment pour voir la fiche client ?`}</DefaultText>
            <DefaultText>{`Vous quitterez la création. Toutes les données saisies seront perdues.`}</DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default EditorGoogleTextAdCreation
