import { backendURL } from '../../../constants/urls'
import {
  KeywordDetails,
  checkIfKeywordDetails
} from '../../../types/campaign/keyword'
import { GetSuggestedKeywordsErrorEnum } from '../../../types/error/campaign/getSuggestedKeywords'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetSuggestedKeywordsOutputType = {
  keywords: KeywordDetails[]
}

export type GetSuggestedKeywordsInput = {
  keyword: string
  audienceId: string
}

export const getSuggestedKeywords = async ({
  keyword,
  audienceId
}: GetSuggestedKeywordsInput): Promise<
  GetSuggestedKeywordsOutputType | GetSuggestedKeywordsErrorEnum
> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/keywords/${keyword}/suggestions?audience=${audienceId}`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (response.data) {
        if (checkIfGetSuggestedKeywords(response.data)) {
          return {
            keywords: response.data.keywords
          }
        }
        return GetSuggestedKeywordsErrorEnum.WRONG_RESPONSE_FORMAT
      }
      return GetSuggestedKeywordsErrorEnum.NO_RESPONSE
    } else {
      return GetSuggestedKeywordsErrorEnum.UNKNOWN
    }
  } catch (_) {
    return GetSuggestedKeywordsErrorEnum.UNKNOWN
  }
}

export const checkIfGetSuggestedKeywords = (
  result: any
): result is GetSuggestedKeywordsOutputType => {
  return (
    result &&
    result.keywords &&
    Array.isArray(result.keywords) &&
    result.keywords.every((k: any): k is KeywordDetails =>
      checkIfKeywordDetails(k)
    )
  )
}
