import { LeftMenuElementToCreateEnum } from '../..'
import { GetAddButtonEditorSectionsParams } from '../../types/addButtonSections'
import { sectionLabels } from '../sectionLabels/editor'

export const getAddButtonEditorSections = ({
  isAddMenuOpened,
  sections,
  customers,
  mediaPlans,
  campaigns,
  ads,
  elementToCreate,
  setCampaignCreationModalMediaPlanId,
  fetchCustomers,
  fetchMediaPlans,
  fetchCampaigns,
  fetchAds,
  navigate
}: GetAddButtonEditorSectionsParams) => {
  // TODO : we should not edit an input, so sections[] should be a return value
  isAddMenuOpened &&
    sections.push({
      id: 'new',
      title: sectionLabels.new,
      items: [
        {
          title: 'Client',
          action: () => {
            navigate('/customer/create')
          }
        },
        { id: LeftMenuElementToCreateEnum.MEDIA_PLAN, title: 'Plan média' },
        { id: LeftMenuElementToCreateEnum.CAMPAIGN, title: 'Campagne' },
        { id: LeftMenuElementToCreateEnum.AD, title: 'Annonce' }
      ],
      fetchData: fetchCustomers
    })

  customers !== null &&
    sections.push({
      id: 'customers',
      title: sectionLabels.customers,
      items: customers.map((customer) => {
        return {
          id: customer.id,
          title: customer.name,
          action: isAddMenuOpened
            ? () => {
                navigate(`/company/${customer.id}/media-plan/create`)
              }
            : () => {
                navigate(`/customer/${customer.id}/details`)
              }
        }
      }),
      fetchData:
        isAddMenuOpened &&
        elementToCreate === LeftMenuElementToCreateEnum.MEDIA_PLAN
          ? undefined
          : fetchMediaPlans
    })

  mediaPlans !== null &&
    sections.push({
      id: 'mediaPlan',
      title: sectionLabels.mediaPlan,
      items: mediaPlans.map((mediaPlan) => {
        return {
          id: mediaPlan.id,
          title: mediaPlan.name,
          action: isAddMenuOpened
            ? () => {
                setCampaignCreationModalMediaPlanId(mediaPlan.id)
              }
            : () => {
                navigate(`/media-plan/${mediaPlan.id}/details`)
              }
        }
      }),
      fetchData:
        isAddMenuOpened && elementToCreate !== LeftMenuElementToCreateEnum.AD
          ? undefined
          : fetchCampaigns
    })

  campaigns !== null &&
    sections.push({
      id: 'campaign',
      title: sectionLabels.campaign,
      items: campaigns.map((campaign) => {
        return {
          id: campaign.id,
          title: campaign.name,
          action: isAddMenuOpened
            ? () => {
                navigate(`/campaign/${campaign.id}/ad/create`)
              }
            : () => {
                navigate(`/campaign/${campaign.id}/details`)
              }
        }
      }),
      fetchData: isAddMenuOpened ? undefined : fetchAds
    })

  ads !== null &&
    sections.push({
      id: 'ad',
      title: sectionLabels.ad,
      items: ads.map((ad) => {
        return {
          id: ad.id,
          title: ad.name,
          action: () => {
            navigate(`/ad/${ad.id}/details`)
          }
        }
      })
    })
}
