import { roundToTwoDecimals } from './rounds'

export const getImageRatio = async (imageSrc: string): Promise<number> => {
  const image = new Image()
  image.src = imageSrc

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const imgRatio = roundToTwoDecimals(
        image.naturalWidth / image.naturalHeight
      )
      resolve(imgRatio)
    }

    image.onerror = (error) => {
      reject(error)
    }
  })
}

export const getImageDimensions = async (
  imageSrc: string
): Promise<{ width: number; height: number }> => {
  const image = new Image()
  image.src = imageSrc

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const imgWidth = image.naturalWidth
      const imgHeight = image.naturalHeight

      resolve({ width: imgWidth, height: imgHeight })
    }

    image.onerror = (error) => {
      reject(error)
    }
  })
}

export const getFileSizeInKo = (file: File | null): number => {
  if (file) {
    const fileSizeInKo = file.size / 1000
    return fileSizeInKo
  }
  return 0
}

export const formatFileSize = (fileSizeInKO: number): string => {
  if (fileSizeInKO < 1000) {
    return `${fileSizeInKO.toFixed(2)} Ko`
  }
  return `${(fileSizeInKO / 1000).toFixed(2)} Mo`
}

export const calculateCO2Score = (file: File | null): number => {
  if (file) {
    const fileSizeInKO = getFileSizeInKo(file)
    if (fileSizeInKO < 100) {
      return 3
    } else if (fileSizeInKO < 500) {
      return 2
    } else if (fileSizeInKO < 800) {
      return 1
    }
  }
  return 0
}

export const getImageContentType = (base64: string): string => {
  const contentTypeMatch = base64.match(/^data:(.*?);base64,/)
  let contentType = 'image/png'

  if (contentTypeMatch && contentTypeMatch[1]) {
    contentType = contentTypeMatch[1]
    base64 = base64.replace(/^data:.*?;base64,/, '')
  }

  return contentType
}

export function base64ToFile(base64: string, filename: string): File {
  // Convertir la base64 en données binaires brutes dans un tableau
  const byteString = atob(base64.split(',')[1])
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0] // Extraire le type MIME

  // Création d'un tableau d'octets
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const intArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i)
  }

  // Création de l'objet Blob
  const blob = new Blob([arrayBuffer], { type: mimeString })

  // Création de l'objet File
  const file = new File([blob], filename, { type: mimeString })
  return file
}
