import React from 'react'
import styled from '@emotion/styled'

import { ArrowBack, ArrowNext } from '../Icon'
import Margin from '../../constants/margin'

const DivNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  margin-right: ${Margin.m6};
`

const Navigation = () => {
  return (
    <DivNavigation>
      <ArrowBack />
      <ArrowNext />
    </DivNavigation>
  )
}

export default Navigation
