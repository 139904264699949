import styled from '@emotion/styled'
import Colors from '../../../constants/colors'

export const PaginationContainer = styled.ul`
  display: flex;
  padding: 0;
  justify-content: center;
`
export const PaginationItem = styled.li<{ isActive: boolean }>`
  box-shadow: 0px 0px 5px #142a4c80;
  border-radius: 10px;
  text-align: center;
  padding: 10px 0;
  width: 39px;
  margin: 10px;
  list-style-type: none;
  background: ${(props) => (props.isActive ? Colors.NEW_YETIC_GREY : '')};
`
