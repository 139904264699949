export enum GoogleStatus {
  YETIC_PENDING = 'YETIC_PENDING',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}

export enum LinkedinStatus {
  NO_AD_ACCOUNT = 'NO_AD_ACCOUNT',
  // all the following statuses are linkedin statuses
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
  PENDING_DELETION = 'PENDING_DELETION',
  REMOVED = 'REMOVED'
}

export const checkIfGoogleStatus = (value: any): value is GoogleStatus => {
  return Object.values(GoogleStatus).includes(value)
}

export const checkIfLinkedinStatus = (value: any): value is LinkedinStatus => {
  return Object.values(LinkedinStatus).includes(value)
}

export const getGoogleStatusTranslation = (status: GoogleStatus): string => {
  switch (status) {
    case GoogleStatus.YETIC_PENDING:
      return 'En attente'
    case GoogleStatus.ACTIVE:
      return 'Connecté'
    case GoogleStatus.DELETED:
      return 'Supprimé'
  }
}

export const getLinkedinStatusTranslation = (
  status: LinkedinStatus
): string => {
  switch (status) {
    case LinkedinStatus.NO_AD_ACCOUNT:
      return 'Compte publicitaire non créé'
    case LinkedinStatus.ACTIVE:
      return 'Connecté'
    case LinkedinStatus.CANCELED:
      return 'Compte fermé'
    case LinkedinStatus.DRAFT:
      return 'Brouillon'
    case LinkedinStatus.PENDING_DELETION:
      return 'Suppression en cours'
    case LinkedinStatus.REMOVED:
      return 'Supprimé'
  }
}

export const GoogleStatusColor = {
  [GoogleStatus.ACTIVE]: '#09DE00',
  [GoogleStatus.YETIC_PENDING]: '#0089FF',
  [GoogleStatus.DELETED]: '#DB1818'
}

export const LinkedinStatusColor = {
  [LinkedinStatus.NO_AD_ACCOUNT]: '#FF8800',
  [LinkedinStatus.ACTIVE]: '#09DE00',
  [LinkedinStatus.CANCELED]: '#000',
  [LinkedinStatus.DRAFT]: '#FFCE00',
  [LinkedinStatus.PENDING_DELETION]: '#DB1818',
  [LinkedinStatus.REMOVED]: '#DB1818'
}
