import React, { useContext, useEffect, useState, MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { TextAdDetails } from '../../../../types/ad/google/textAd'
import {
  getAdById,
  checkIfGetAdOutputGoogleTextAdDetails,
  checkIfGetAdOutputAdWithoutDetails
} from '../../../../helpers/queries/ad/getAdById'
import { PlatformAPIEnum } from '../../../../types/platform'
import LoadingPage from '../../../LoadingPage'
import { FlowEnum } from '../../../../types/flow'
import { AppContext } from '../../../../contexts/AppContext'
import ErrorPage from '../../../ErrorPage'
import { Status, getIsEditable } from '../../../../types/status'
import EditorTextAdDetailsPage from './editor'
import SelfEditorTextAdDetailsPage from './selfEditor'

const TextAdDetailsPage = () => {
  const [adDetails, setAdDetails] = useState<TextAdDetails | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)

  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false)

  const navigate = useNavigate()
  const { adId } = useParams()
  const { isEditor, isSelfEditor } = useContext(AppContext)

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const result = await getAdById({
          adId
        })

        if (result && checkIfGetAdOutputAdWithoutDetails(result)) {
          if (result.ad.status === Status.DRAFT) {
            navigate(
              `/ad/${adId}/review?flow=${FlowEnum.creation}&platform=${PlatformAPIEnum.GOOGLE}`
            )
          }

          const resultDetails = await getAdById({
            adId,
            adType: result.ad.format,
            platform: PlatformAPIEnum.GOOGLE
          })
          if (resultDetails) {
            if (checkIfGetAdOutputGoogleTextAdDetails(resultDetails)) {
              const newAdDetails = {
                id: resultDetails.ad.id,
                format: resultDetails.ad.format,
                name: resultDetails.ad.name,
                startDate: resultDetails.ad.startDate,
                url: resultDetails.ad.url,
                title1: resultDetails.ad.title1,
                title2: resultDetails.ad.title2,
                title3: resultDetails.ad.title3,
                description1: resultDetails.ad.description1,
                description2: resultDetails.ad.description2,
                description3: resultDetails.ad.description3,
                status: resultDetails.ad.status,
                feedback: resultDetails.ad.feedback,
                navigation: result.navigation
              }
              setAdDetails(newAdDetails)
            }
          }
        } else {
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }
    window.scrollTo(0, 0)
  }, [adId])

  if (hasFetchError || (!isEditor && !isSelfEditor)) {
    return <ErrorPage />
  }

  if (!adId || !adDetails) {
    return <LoadingPage />
  }

  const handleShowReason =
    adDetails.status === Status.REJECTED
      ? (event: MouseEvent) => {
          event.stopPropagation()
          if (adDetails.feedback) {
            setIsReasonModalOpen(true)
          }
        }
      : undefined

  const handleEdit = getIsEditable({ status: adDetails.status, isEditor })
    ? () => {
        navigate(`/ad/text/${adId}/edit?flow=${FlowEnum.view}`)
      }
    : undefined

  const TextAdDetailsContent = isEditor
    ? EditorTextAdDetailsPage
    : SelfEditorTextAdDetailsPage

  return (
    <TextAdDetailsContent
      adDetails={adDetails}
      isReasonModalOpen={isReasonModalOpen}
      setIsReasonModalOpen={setIsReasonModalOpen}
      handleShowReason={handleShowReason}
      handleEdit={handleEdit}
      navigate={navigate}
    />
  )
}

export default TextAdDetailsPage
