import React from 'react'

import {
  ClickableValue,
  ItemContainer,
  Label,
  Tooltip,
  Value,
  ValueLine
} from './styles'

export type LabelledItemType = {
  id?: string
  label: string
  value: string | JSX.Element
  onClick?: ((id: string) => void) | ((id: string) => Promise<void>)
  onTooltipClick?: (id: string) => void
  hidden?: boolean
}

type LabelledItemProps = LabelledItemType & {
  style?: React.CSSProperties
  isNotFlex?: boolean
}

const LabelledItem = ({
  id,
  label,
  value,
  onClick,
  onTooltipClick,
  hidden,
  style,
  isNotFlex
}: LabelledItemProps) => {
  return (
    <>
      {!hidden && label !== '' && value !== '' ? (
        <ItemContainer style={style} isNotFlex={isNotFlex}>
          <Label>{label} :</Label>
          <Value isNotFlex={isNotFlex}>
            {typeof value === 'string' ? (
              onClick ? (
                value.split('\n').map((v, i) => (
                  <ClickableValue
                    key={i}
                    onClick={() => {
                      onClick(v)
                    }}
                  >
                    {v}
                  </ClickableValue>
                ))
              ) : (
                value
                  .split('\n')
                  .map((v, i) => <ValueLine key={i}>{v}</ValueLine>)
              )
            ) : (
              <ValueLine>{value}</ValueLine>
            )}
          </Value>
          {onTooltipClick && id && (
            <Tooltip
              onClick={() => {
                onTooltipClick(id)
              }}
            >
              i
            </Tooltip>
          )}
        </ItemContainer>
      ) : null}
    </>
  )
}

export default LabelledItem
