export enum LoginErrorEnum {
  INCORRECT_PARAMS = `INCORRECT_PARAMS`,
  MISSING_PARAMS = `MISSING_PARAMS`,
  USER_NOT_FOUND = `USER_NOT_FOUND`,
  UNKNOWN = 'UNKNOWN',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  NOT_VERIFIED = 'NOT_VERIFIED'
}

export const LoginErrorTranslation = {
  [LoginErrorEnum.MISSING_PARAMS]:
    'Veuillez renseigner votre adresse e-mail et votre mot de passe',
  [LoginErrorEnum.INCORRECT_PARAMS]:
    'Adresse mail et/ou mot de passe incorrect',
  [LoginErrorEnum.USER_NOT_FOUND]: "L'utilisateur n'existe pas",
  [LoginErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [LoginErrorEnum.INCORRECT_PASSWORD]: 'Le mot de passe est incorrect',
  [LoginErrorEnum.NOT_VERIFIED]:
    'Votre compte est en attente de vérification par un administrateur',
  [LoginErrorEnum.NOT_ACTIVATED]:
    'Veuillez activer votre compte en cliquant sur le lien envoyé par mail pour vous connecter'
}

export const checkIfLoginErrorCodeValid = (
  errorCode: string
): errorCode is LoginErrorEnum => {
  return Object.keys(LoginErrorEnum).includes(errorCode)
}
