import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import Padding from '../../../../../../constants/padding'
import Margin from '../../../../../../constants/margin'

export const DivContainer = styled.div`
  border-radius: 10px;
  text-align: center;
  background-color: ${Colors.YETIC_WHITE};
  margin: ${Margin.m3};
`

export const ImageTop = styled.img`
  width: 100%;
  height: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const DivContent = styled.div`
  padding: 0px ${Padding.p6};
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 5px;
`

export const Title = styled.h1`
  font-size: 24px;
  color: ${Colors.YETIC_GREY_AD};
  font-weight: 600;
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Margin.m6};
  width: 100%;
  margin: ${Margin.m6} 0px;
`

export const Description = styled.div`
  font-size: 16px;
  color: ${Colors.YETIC_GREY_AD};
  opacity: 0.8;
  margin-bottom: ${Margin.m2};
`

export const paddingStyle = {
  padding: Padding.p3
}
