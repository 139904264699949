import styled from '@emotion/styled'
import FontWeight from '../../../../../../constants/fontWeight'
import Colors from '../../../../../../constants/colors'

export const Container = styled.div`
  font-size: 30px;
  width: 60%;
  font-weight: ${FontWeight.YETIC_BOLD};
  color: ${Colors.NEW_YETIC_DARK};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
