import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from '../../types/icon'

const LinkedinIcon = ({
  height = '20px',
  width = '20px',
  style,
  color = Colors.YETIC_BLUE_DARK
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 20 20"
    >
      <path
        data-name="Icon awesome-linkedin-in"
        d="M4.477,20H.33V6.648H4.477ZM2.4,4.826A2.413,2.413,0,1,1,4.8,2.4,2.422,2.422,0,0,1,2.4,4.826ZM20,20H15.858V13.5c0-1.549-.031-3.536-2.156-3.536-2.156,0-2.486,1.683-2.486,3.424V20H7.074V6.648h3.977V8.469h.058a4.357,4.357,0,0,1,3.923-2.156c4.2,0,4.968,2.763,4.968,6.353V20Z"
        transform="translate(0 -0.001)"
        fill={color}
      />
    </svg>
  )
}

export default LinkedinIcon
