import React from 'react'

import { PlatformAPIEnum } from '../../types/platform'
import GoogleIcon from '../Icon/googleLogo'
import MetaIcon from '../Icon/metaLogo'
import LinkedinIcon from '../Icon/linkedinLogo'

type PlatformLogoProps = {
  platform: PlatformAPIEnum
  width?: string
  height?: string
  color?: string
  style?: React.CSSProperties
}

const PlatformLogo = ({
  platform,
  width,
  height,
  color,
  style = { marginBottom: '-3px' }
}: PlatformLogoProps) => {
  switch (platform) {
    case PlatformAPIEnum.GOOGLE:
      return (
        <GoogleIcon width={width} height={height} color={color} style={style} />
      )
    case PlatformAPIEnum.LINKEDIN:
      return (
        <LinkedinIcon
          width={width}
          height={height}
          color={color}
          style={style}
        />
      )
    // case PlatformAPIEnum.META:
    //   return (
    //     <MetaIcon width={width} height={height} color={color} style={style} />
    //   )
    default:
      return null
  }
}

export default PlatformLogo
