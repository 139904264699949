import React from 'react'

import { ImageAdCreationFormDataType } from '..'
import Input from '../../../../../components/Input'
import { PanelType } from '../../../../../types/templates/panels'

type GetIntroTextPanelProps = {
  formData: ImageAdCreationFormDataType
  handleTextAreaChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  hasBeenSubmitted: boolean
  error: string
}

const getIntroTextPanel = ({
  formData,
  handleTextAreaChange,
  hasBeenSubmitted,
  error
}: GetIntroTextPanelProps): PanelType => {
  const introTextPanel: PanelType = {
    title: "Texte d'introduction",
    disabled: false,
    isDropdown: false,
    description: `Renseignez le texte d'introduction de votre annonce.`,
    sections: [
      {
        content: (
          <Input
            type="textarea"
            onChange={(e) => {
              handleTextAreaChange('introText', e)
            }}
            placeholder="Texte d'introduction"
            label={`Texte d'introduction`}
            value={formData.introText}
            maxLength={600}
            showCharacterCount
            error={hasBeenSubmitted ? error : ''}
            style={{ resize: 'vertical' }}
            withBorder
          />
        )
      }
    ]
  }

  return introTextPanel
}

export default getIntroTextPanel
