import { axiosGet, checkIsAxiosGetOutputSuccess } from './axios/get'

import { backendURL } from '../../constants/urls'
import { checkIfDate } from '../nativeTypes'
import { Status, checkIfStatus } from '../../types/status'
import { FormatAPIEnum, checkIfFormatApiEnum } from '../../types/format'
import { PlatformAPIEnum } from '../../types/platform'

export type ReportingDataType = {
  customers: {
    id: string
    name: string
  }[]
  mediaPlans: {
    id: string
    name: string
    customerName: string
    startDate: string
    endDate: string
  }[]
  campaigns: {
    id: string
    name: string
    mediaPlanName: string
    startDate: string
    endDate: string
    status: Status
    format: FormatAPIEnum
    platform: PlatformAPIEnum
  }[]
}

export const getReportingData = async (): Promise<ReportingDataType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting`
    })

    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfReportingData(response.data)
    ) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfReportingData = (
  value: any
): value is ReportingDataType => {
  return (
    value &&
    value.customers &&
    Array.isArray(value.customers) &&
    value.customers.every(
      (customer: any) =>
        customer &&
        customer.id &&
        typeof customer.id === 'string' &&
        customer.name &&
        typeof customer.name === 'string'
    ) &&
    value.mediaPlans &&
    Array.isArray(value.mediaPlans) &&
    value.mediaPlans.every(
      (mediaPlan: any) =>
        mediaPlan &&
        mediaPlan.id &&
        typeof mediaPlan.id === 'string' &&
        mediaPlan.name &&
        typeof mediaPlan.name === 'string' &&
        mediaPlan.customerName &&
        typeof mediaPlan.customerName === 'string' &&
        mediaPlan.startDate &&
        checkIfDate(mediaPlan.startDate) &&
        mediaPlan.endDate &&
        checkIfDate(mediaPlan.endDate)
    ) &&
    value.campaigns &&
    Array.isArray(value.campaigns) &&
    value.campaigns.every(
      (campaign: any) =>
        campaign &&
        campaign.id &&
        typeof campaign.id === 'string' &&
        campaign.name &&
        typeof campaign.name === 'string' &&
        campaign.mediaPlanName &&
        typeof campaign.mediaPlanName === 'string' &&
        campaign.startDate &&
        typeof campaign.platform === 'string' &&
        campaign.platform &&
        checkIfDate(campaign.startDate) &&
        campaign.endDate &&
        checkIfDate(campaign.endDate) &&
        campaign.status &&
        checkIfStatus(campaign.status) &&
        campaign.format &&
        checkIfFormatApiEnum(campaign.format)
    )
  )
}
