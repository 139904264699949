import React from 'react'

import {
  BarChartTooltipContainer,
  BarChartTooltipItem,
  BarChartTooltipItemValue,
  BarChartTooltipTitle
} from './styles'
import { FormattedPoint, BarChartData } from '..'

type BarChartTooltipProps = {
  chartId: string
  data: BarChartData<FormattedPoint>
}

const BarChartTooltip = ({ chartId, data }: BarChartTooltipProps) => {
  return (
    <BarChartTooltipContainer id={`${chartId}-tooltip`}>
      <BarChartTooltipTitle
        id={`${chartId}-tooltip-title`}
      ></BarChartTooltipTitle>
      <BarChartTooltipItem
        id={`${chartId}-tooltip-${data.name}`}
        key={data.name}
      >
        <BarChartTooltipItemValue
          className={`${chartId}-tooltip-${data.name}-value`}
        ></BarChartTooltipItemValue>
      </BarChartTooltipItem>
    </BarChartTooltipContainer>
  )
}

export default BarChartTooltip
