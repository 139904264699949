import React, { useEffect, useState } from 'react'

import {
  AdDetailsListContainer,
  AdPreviewContainer,
  AdPreviewTitle
} from './styles'
import { LabelledItemType } from '../../../../components/LabelledItem'
import LabelledItemsList from '../../../../components/LabelledItemsList'
import { GoogleImageAdDetails } from '../../../../types/ad/google/imageAd'
import CarouselPreview from '../../../../components/PreviewAds/Google/Display/CarouselPreview'
import getImageById from '../../../../helpers/queries/ad/getImageById'
import { convertBase64ToUrl } from '../../../../helpers/convertBase64ToUrl'
import Colors from '../../../../constants/colors'

type AdDetailsContentProps = {
  title: string
  adDetails: GoogleImageAdDetails
  listItems: LabelledItemType[]
}

type ImagesType = {
  logo: string
  adImageSquare: string
  adImageRectangle: string
}

const AdDetailsContentImage = ({
  title,
  adDetails,
  listItems
}: AdDetailsContentProps) => {
  const [selectedPreview, setSelectedPreview] = useState(1)
  const [images, setImages] = useState<ImagesType>({
    logo: '',
    adImageSquare: '',
    adImageRectangle: ''
  })

  useEffect(() => {
    ;(async () => {
      const logoResult = await getImageById(adDetails.logo)
      if (logoResult) {
        const logoURL = convertBase64ToUrl(logoResult)
        setImages((prevImages) => ({ ...prevImages, logo: logoURL }))
      }
    })()
  }, [adDetails])

  useEffect(() => {
    const randomAdImageSquare =
      adImageSquares[Math.floor(Math.random() * adImageSquares.length)]
    const randomAdImageRectangle =
      adImageRectangles[Math.floor(Math.random() * adImageRectangles.length)]
    let adImageSquareURL = ''
    let adImageRectangleURL = ''

    ;(async () => {
      const adImageSquareResult = await getImageById(randomAdImageSquare)
      if (adImageSquareResult) {
        adImageSquareURL = convertBase64ToUrl(adImageSquareResult)
      }

      const adImageRectangleResult = await getImageById(randomAdImageRectangle)
      if (adImageRectangleResult) {
        adImageRectangleURL = convertBase64ToUrl(adImageRectangleResult)
      }

      setImages((prevImages) => ({
        ...prevImages,
        ...(adImageRectangleResult
          ? { adImageRectangle: adImageRectangleURL }
          : {}),
        ...(adImageSquareResult ? { adImageSquare: adImageSquareURL } : {})
      }))
    })()
  }, [selectedPreview])

  const titles = [
    adDetails.headline1,
    adDetails.headline2,
    adDetails.headline3
  ].filter((item) => item)
  const descriptions = [
    adDetails.description1,
    adDetails.description2,
    adDetails.description3
  ].filter((item) => item)
  const adImageSquares = [
    adDetails.squareMarketingImage1,
    adDetails.squareMarketingImage2,
    adDetails.squareMarketingImage3
  ].filter((item) => item)
  const adImageRectangles = [
    adDetails.marketingImage1,
    adDetails.marketingImage2,
    adDetails.marketingImage3
  ].filter((item) => item)

  const randomTitle = titles[Math.floor(Math.random() * titles.length)]
  const randomDescription =
    descriptions[Math.floor(Math.random() * descriptions.length)]

  const handleOnClickNextPreview = () => {
    setSelectedPreview((prevSelectedPreview) =>
      prevSelectedPreview >= 9 ? 1 : prevSelectedPreview + 1
    )
  }

  // TODO: get the main color of the image and handle the change of color according to the
  // selected preview (use the color of the square or the rectangle image according to the preview)
  const fakeColor = Colors.YETIC_GREY_AD

  return (
    <>
      <AdDetailsListContainer>
        <LabelledItemsList items={listItems} />
      </AdDetailsListContainer>
      <AdPreviewContainer>
        <AdPreviewTitle>{title}</AdPreviewTitle>
        <CarouselPreview
          selectedPreview={selectedPreview}
          setSelectedPreview={setSelectedPreview}
          handleOnClickNextPreview={handleOnClickNextPreview}
          businessName={adDetails.businessName}
          title={randomTitle}
          description={randomDescription}
          adImageSquare={images.adImageSquare}
          adImageRectangle={images.adImageRectangle}
          logoImage={images.logo}
          buttonColor={fakeColor}
        />
      </AdPreviewContainer>
    </>
  )
}

export default AdDetailsContentImage
