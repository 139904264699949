import React, { useEffect, useState } from 'react'

import Modal from '..'
import Tabs from '../../Tabs'
import { DivTabs, TitleContainer, modalStyle, tabStyle } from './styles'
import DetailsTab from './DetailsTab'
import {
  GetCampaignCO2DetailsOutputType,
  getCampaignCO2Details
} from '../../../helpers/queries/campaign/getCampaignCO2Details'
import SimulationTab from './SimulationTab'
import Title from '../../Title'
import { FormatAPIEnum } from '../../../types/format'
import Colors from '../../../constants/colors'
import Leaf from '../../Icon/leaf'
import EmptyTab from './EmptyTab'

type CO2ModalProps = {
  campaignId: string
  format: FormatAPIEnum
  onClose: () => void
  isSimulationHidden?: boolean
  adCount?: number
  campaignDevices?: {
    desktop: boolean
    smartphone: boolean
    tablet: boolean
    tv: boolean
  }
}

const CO2Modal = ({
  campaignId,
  format,
  onClose,
  campaignDevices,
  isSimulationHidden,
  adCount
}: CO2ModalProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  // TODO : here we are in the directory components, which should include only pure UI compo
  // please create another component somewhere else (e.g pages/parts) which will call CO2ModalUI
  const [co2Details, setCo2Details] =
    useState<GetCampaignCO2DetailsOutputType | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)

  useEffect(() => {
    if (campaignId) {
      ;(async function () {
        const co2Result = await getCampaignCO2Details({ campaignId })
        if (co2Result != null) {
          setCo2Details(co2Result)
          setHasFetchError(false)
        } else {
          setHasFetchError(true)
        }
      })()
    }
  }, [campaignId])

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index)
  }

  const tabs = isSimulationHidden
    ? ['Détails']
    : ['Détails', 'Hypothèses de calcul']

  return (
    <Modal onClose={onClose} style={modalStyle}>
      {adCount === 0 ? (
        <>
          <TitleContainer>
            <Title>Comprendre et diminuer mon empreinte carbone</Title>
          </TitleContainer>
          <EmptyTab />
        </>
      ) : (
        <>
          <TitleContainer>
            <Leaf height="30px" color={Colors.NEW_YETIC_GREEN} />
            <Title style={{ margin: 0 }}>Empreinte CO2 de la campagne</Title>
          </TitleContainer>
          <DivTabs>
            <Tabs
              items={tabs}
              activeTabIndex={activeTabIndex}
              handleTabClick={handleTabClick}
              tabStyle={tabStyle}
              containerStyle={{ width: '100%' }}
              hasSeparator={false}
              textColor={Colors.YETIC_BLUE_DARK}
              activeTabColor={Colors.YETIC_BLUE_DARK}
              activeTextColor={Colors.YETIC_WHITE}
            />
          </DivTabs>
          {activeTabIndex === 0 ? (
            <DetailsTab
              data={co2Details}
              hasFetchError={hasFetchError}
              format={format}
            />
          ) : (
            <SimulationTab
              campaignId={campaignId}
              format={format}
              co2Details={co2Details}
              campaignDevices={campaignDevices}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default CO2Modal
