import React, { useState } from 'react'

import { KeywordsTabContainer } from './styles'
import Table, { ColumnItem } from '../../../../components/Table'
import { formatKeywordsData } from './helpers/formatData'
import { TableContainer } from '../../styles'
import { AlignEnum, SortEnum } from '../../../../types/common'
import { GetCampaignKeywordsReportingOutputType } from '../../../../types/reporting/campaign/output'

type KeywordsTabProps = {
  reportingData: GetCampaignKeywordsReportingOutputType
}

const KeywordsTab = ({ reportingData }: KeywordsTabProps) => {
  const { keywordsTableData, searchTermsTableData } =
    formatKeywordsData(reportingData)
  const [tablePage, setTablePage] = useState(1)

  const keywordsTableColumns: ColumnItem[] = [
    {
      name: 'keyword',
      label: 'Mot-clé',
      isSortable: true,
      width: '60%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '20%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '20%'
    }
  ]

  const searchTermsTableColumns: ColumnItem[] = [
    {
      name: 'searchTerm',
      label: 'Terme de recherche',
      isSortable: true,
      width: '60%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '20%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '20%'
    }
  ]

  const defaultTableSort = {
    name: 'clicks',
    order: SortEnum.DESC
  }

  return (
    <KeywordsTabContainer>
      <TableContainer>
        <Table
          title="Clics et impressions par mot-clé"
          columns={keywordsTableColumns}
          elements={keywordsTableData}
          sort={defaultTableSort}
          pagination={{
            totalItems: keywordsTableData.length,
            itemsByPage: 10,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
        />
      </TableContainer>
      <TableContainer>
        <Table
          title="Clics et impressions par terme de recherche"
          columns={searchTermsTableColumns}
          elements={searchTermsTableData}
          sort={defaultTableSort}
          pagination={{
            totalItems: searchTermsTableData.length,
            itemsByPage: 10,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
        />
      </TableContainer>
    </KeywordsTabContainer>
  )
}

export default KeywordsTab
