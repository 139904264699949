import { backendURL } from '../../../constants/urls'
import {
  CampaignNavigation,
  checkCampaignNavigation
} from '../../../types/campaign/navigation'
// eslint-disable-next-line
import { GetCampaignCreationDataErrorEnum } from '../../../types/error/campaign/getCampaignCreationData'
import { GoalsEnum, checkIfGoalsEnum } from '../../../types/goals'
import { PlatformAPIEnum } from '../../../types/platform'
import { Status, checkIfStatus } from '../../../types/status'
import { checkIfDate, isStringArray } from '../../nativeTypes'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type AudienceLocation = {
  canonicalName: string
  name: string
}

export type GetCampaignCreationDataOutputType = {
  goals: GoalsEnum[]
  audiences: { id: string; name: string; locations: AudienceLocation[] }[]
  availableBudget: number
  mediaPlanStartDate: string
  mediaPlanEndDate: string
  mediaPlanStatus: Status
  navigation: CampaignNavigation
}

export type GetCampaignCreationDataInput = {
  mediaPlanId: string
  platform?: PlatformAPIEnum
}

export const getCampaignCreationData = async ({
  mediaPlanId,
  platform
}: GetCampaignCreationDataInput): Promise<
  GetCampaignCreationDataOutputType | GetCampaignCreationDataErrorEnum
> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/campaign-creation/data/${mediaPlanId}?navigation=1${
        platform ? `&platform=${platform}` : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (response.data) {
        if (checkGetCampaignCreationData(response.data)) {
          return {
            goals: response.data.goals,
            audiences: response.data.audiences,
            availableBudget: response.data.availableBudget,
            mediaPlanStartDate: response.data.mediaPlanStartDate,
            mediaPlanEndDate: response.data.mediaPlanEndDate,
            mediaPlanStatus: response.data.mediaPlanStatus,
            navigation: response.data.navigation
          }
        }
        return GetCampaignCreationDataErrorEnum.WRONG_RESPONSE_FORMAT
      }
      return GetCampaignCreationDataErrorEnum.NO_RESPONSE
    } else {
      return GetCampaignCreationDataErrorEnum.UNKNOWN
    }
  } catch (_) {
    return GetCampaignCreationDataErrorEnum.UNKNOWN
  }
}

export const checkGetCampaignCreationData = (
  value: any
): value is GetCampaignCreationDataOutputType => {
  return (
    value.goals &&
    isStringArray(value.goals) &&
    value.goals.reduce(
      (prev: boolean, curr: string) => checkIfGoalsEnum(curr) && prev,
      true
    ) &&
    value.audiences &&
    value.audiences.every(
      (audience: any) =>
        audience.id &&
        typeof audience.id === 'string' &&
        audience.name &&
        typeof audience.name === 'string' &&
        audience.locations !== undefined &&
        Array.isArray(audience.locations) &&
        audience.locations.every(
          (location: any): location is AudienceLocation =>
            location.canonicalName &&
            typeof location.canonicalName === 'string' &&
            location.name &&
            typeof location.name === 'string'
        )
    ) &&
    value.availableBudget != null &&
    checkCampaignNavigation(value.navigation) &&
    value.mediaPlanStartDate &&
    checkIfDate(value.mediaPlanStartDate) &&
    value.mediaPlanEndDate &&
    checkIfDate(value.mediaPlanEndDate) &&
    value.mediaPlanStatus &&
    checkIfStatus(value.mediaPlanStatus)
  )
}
