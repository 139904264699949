import { ElementItem } from '../../../../../components/Table'
import { GetMediaPlanCampaignsReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'

type FormatCampaignsDataOutput = {
  tableData: ElementItem[]
}

export const formatCampaignsData = (
  data: GetMediaPlanCampaignsReportingOutputType
): FormatCampaignsDataOutput => {
  const tableData = data.map((cp) => {
    const { campaignId, campaignName, clicks, impressions } = cp

    return {
      id: campaignId,
      campaignName,
      clicks,
      impressions
    }
  })

  return { tableData }
}
