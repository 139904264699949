import React from 'react'

import { AvatarContainer } from './styles'

interface AvatarProps {
  character: string
  backgroundColor?: string
  onClick?: () => void
}

const Avatar = ({ character, backgroundColor, onClick }: AvatarProps) => {
  return (
    <AvatarContainer backgroundColor={backgroundColor} onClick={onClick}>
      {character}
    </AvatarContainer>
  )
}

export default Avatar
