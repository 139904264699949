import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../../contexts/AppContext'
import {
  Header,
  ProfilePageContainer,
  ProfilePageRows,
  labelledItemStyle
} from './styles'
import Button from '../../components/Button'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import Title from '../../components/Title'
import LabelledItem from '../../components/LabelledItem'
import ResetPasswordModal from '../ResetPassword/ResetPasswordModal'

type ContentType = {
  label: string
  value: string
  btnText?: string
  btnAction?: () => void
}[]

const ProfilePage = () => {
  const { user, isEditor, companyName } = useContext(AppContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const profileContent: ContentType = [
    { label: 'Email', value: user?.email || '', btnAction: undefined },
    { label: 'Prénom', value: user?.firstName || '', btnAction: undefined },
    { label: 'Nom', value: user?.lastName || '', btnAction: undefined },
    // { label: 'Rôle', value: 'ROLE', btnAction: () => {} },
    // TODO call resetPasswordModale here
    { label: 'Mot de passe', value: '******', btnAction: handleOpenModal },
    { label: 'Entreprise', value: companyName || '' }
  ]

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <ProfilePageContainer>
        <Header>
          <Title>Mon profil</Title>
        </Header>

        {profileContent.map((profile, index) => (
          <ProfilePageRows key={index}>
            <LabelledItem
              label={profile.label}
              value={profile.value || ''}
              style={labelledItemStyle}
            />
            {profile.btnAction && (
              <Button type="secondary" onClick={profile.btnAction} width="auto">
                {profile.btnText ?? 'Modifier'}
              </Button>
            )}
          </ProfilePageRows>
        ))}
        {isModalOpen && <ResetPasswordModal onClose={handleCloseModal} />}
      </ProfilePageContainer>
    </AuthenticatedTemplate>
  )
}

export default ProfilePage
