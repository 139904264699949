import React from 'react'

import { IconType } from '../../types/icon'

const CustomersPicto = ({
  height = '17px',
  width = '21px',
  style
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      fill="#142a4c"
    >
      <path d="M15.437 9.952c1.67-.001 3.023-1.354 3.023-3.024s-1.354-3.023-3.024-3.024-3.024 1.353-3.024 3.023c0 .802.318 1.572.886 2.139s1.337.886 2.139.886zm2.117.751h-.395c-1.093.504-2.352.504-3.445 0h-.394c-.843 0-1.651.335-2.247.932s-.93 1.405-.929 2.248v.983c-.001.301.118.59.331.804s.502.333.803.333h8.317c.301 0 .589-.119.802-.332s.332-.501.332-.802v-.986c.001-.843-.333-1.652-.928-2.248s-1.404-.932-2.247-.932z" />
      <path d="M12.718 16.5H2.406A1.91 1.91 0 0 1 .5 14.593v-1.219c0-2.447 1.991-4.437 4.437-4.437h.489.109l.099.045c.614.281 1.263.423 1.928.423s1.316-.142 1.927-.423l.099-.046h.109.489c2.447 0 4.437 1.991 4.437 4.437v1.219a1.91 1.91 0 0 1-1.906 1.906zM7.562 9c-2.343 0-4.25-1.906-4.25-4.25S5.219.5 7.562.5s4.25 1.906 4.25 4.25S9.906 9 7.562 9z" />
      <path
        d="M7.562 1a3.75 3.75 0 0 0 0 7.5 3.75 3.75 0 0 0 0-7.5M4.937 9.437A3.94 3.94 0 0 0 1 13.375v1.219C1 15.37 1.63 16 2.406 16h10.312c.776 0 1.406-.63 1.406-1.406v-1.219a3.94 3.94 0 0 0-3.937-3.937h-.489c-.65.299-1.374.469-2.136.469s-1.482-.17-2.136-.469h-.489M7.562 0c2.619 0 4.75 2.131 4.75 4.75 0 1.494-.693 2.828-1.774 3.7 2.559.181 4.587 2.321 4.587 4.925v1.219A2.41 2.41 0 0 1 12.718 17H2.406A2.41 2.41 0 0 1 0 14.593v-1.219C0 10.77 2.027 8.63 4.587 8.45c-1.081-.871-1.774-2.206-1.774-3.7C2.812 2.131 4.943 0 7.562 0z"
        fill="#f3f4f6"
      />
    </svg>
  )
}

export default CustomersPicto
