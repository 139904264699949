import React from 'react'

import { ImageAdCreationFormDataType } from '../types'
import { ImageAdCreationErrorsType } from '../../../helpers/imageAd/formErrors'
import { PanelType } from '../../../types/templates/panels'
import Input from '../../../components/Input'

export type GetLongheadlinePanelInput = {
  formData: ImageAdCreationFormDataType
  handleInputChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  errors: ImageAdCreationErrorsType
  hasBeenSubmitted: boolean
}

export const getLongheadlinePanel = ({
  formData,
  handleInputChange,
  errors,
  hasBeenSubmitted
}: GetLongheadlinePanelInput): PanelType => {
  return {
    title: 'Titre long',
    disabled: false,
    isDropdown: false,
    description: `Renseignez le titre long qui s'affichera sur votre annonce.`,
    sections: [
      {
        content: (
          <Input
            type="text"
            label={'Titre long'}
            placeholder="Titre long"
            maxLength={90}
            showCharacterCount
            value={formData.longHeadline}
            onChange={(e) => {
              handleInputChange('longHeadline', e)
            }}
            error={hasBeenSubmitted ? errors.longHeadline : ''}
            withBorder
          />
        )
      }
    ]
  }
}
