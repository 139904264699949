import {
  LineChartData,
  DatasetsSelection,
  Point
} from '../../../../components/Chart/LineChart/LineChartDrawer'
import { formatNumber } from '../../../../helpers/rounds'

export const formatAnalyticsHeaderData = (
  data: LineChartData<Point>,
  initialSelectionNames?: string[],
  globalCTR?: number
): DatasetsSelection => {
  const getHeaderKpi = (name: string, values: Point[]) => {
    if (values.length === 0) {
      return undefined
    }

    if (name === 'CTR') {
      return formatNumber(globalCTR ?? 0)
    }

    return formatNumber(
      values.reduce((current, point) => current + point.value, 0)
    )
  }

  return data.map((dataset) => ({
    name: dataset.name,
    color: dataset.color,
    isSelected: initialSelectionNames
      ? initialSelectionNames.includes(dataset.name) ?? false
      : true,
    headerKpi: getHeaderKpi(dataset.name, dataset.values),
    unit: dataset.unit
  }))
}
