import styled from '@emotion/styled'

import FontWeight from '../../../../../../constants/fontWeight'
import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ContainerUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 60%;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  color: ${Colors.NEW_YETIC_DARK};
`

export const ContainerLi = styled.li<{ color: string }>`
  display: flex;
  align-items: baseline;
  &::before {
    content: '\\2022';
    color: ${(props) => props.color};
    display: inline-block;
    font-size: 22px;
    font-weight: ${FontWeight.YETIC_BOLD};
    margin-right: 5px;
  }
`
export const ContainerSpan = styled.span`
  font-weight: bold;
  flex: 1;
  text-align: right;
`
