import styled from '@emotion/styled'

import Padding from '../../../constants/padding'

export const Container = styled.div<{
  backgroundColor?: string
  width?: string
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width ?? 'unset'};
  background-color: ${(props) => props.backgroundColor ?? 'white'};
  padding: ${Padding.p5};
  border-radius: 10px;
`
