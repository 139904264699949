export enum CreateCustomerErrorEnum {
  MISSING_PARAMS = 'MISSING_PARAMS',
  COMPANY_ALREADY_USED = `COMPANY_ALREADY_USED`,
  USER_EXISTS = 'USER_EXISTS',
  AUTH_FAILED = 'AUTH_FAILED',
  UNKNOWN = 'UNKNOWN'
}

export const CreateCustomerErrorTranslation = {
  [CreateCustomerErrorEnum.MISSING_PARAMS]:
    'Veuillez remplir tous les champs obligatoires',
  [CreateCustomerErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [CreateCustomerErrorEnum.COMPANY_ALREADY_USED]:
    'Cette entreprise est déjà existante',
  [CreateCustomerErrorEnum.USER_EXISTS]:
    'Cette adresse email est déjà existante',
  [CreateCustomerErrorEnum.AUTH_FAILED]: `L'authentification a échoué`
}

export const checkIfCreateCustomerErrorCodeValid = (
  errorCode: string
): errorCode is CreateCustomerErrorEnum => {
  return Object.keys(CreateCustomerErrorEnum).includes(errorCode)
}
