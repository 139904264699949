import React, { useState } from 'react'

import { Body, Header, Wrapper } from './styles'
import DefaultText from '../DefaultText'
import ArrowDown from '../Icon/arrowDown'

type PropsType = {
  title: string
  content: JSX.Element[]
}

const ContentDropdown = ({ title, content }: PropsType) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Wrapper>
      <Header onClick={handleClick}>
        <DefaultText style={{ margin: 0 }}>{title}</DefaultText>
        <ArrowDown
          style={{
            width: '20px',
            height: '20px',
            transform: `rotate(${open ? '180' : '0'}deg)`,
            flexShrink: 0
          }}
        />
      </Header>
      <Body open={open}>{content}</Body>
    </Wrapper>
  )
}

export default ContentDropdown
