/* eslint-disable max-len */
import React from 'react'

import ReviewPageTemplate from '../../templates/ReviewPageTemplate'
import {
  ContainerBlock,
  Container
} from '../../templates/ReviewPageTemplate/ReviewContent'
import { getFormatEnum } from '../../types/format'
import Breadcrumb from '../../components/BreadCrumb'
import { StrategyEnum } from '../../types/strategy'
import LabelledItemsList from '../../components/LabelledItemsList'
import Button from '../../components/Button'
import { archiveAd, checkIfArchiveAd } from '../../helpers/queries/ad/archiveAd'
import AddPicto from '../../components/Icon/addPicto'
import { addButtonStyle } from './styles'
import { getAds } from './helpers/getAds'
import { SelfEditorCampaignReviewProps } from './types/selfEditor'
import { PlatformAPIEnum } from '../../types/platform'
import { FlowEnum } from '../../types/flow'
import { Status } from '../../types/status'

const SelfEditorCampaignReview = ({
  reviewData,
  campaignId,
  startDateCampaign,
  endDateCampaign,
  images,
  flow,
  setIsCO2ModalOpen,
  navigate,
  handleViewMore,
  isValidateCampaignLoading,
  handleValidateCampaign
}: SelfEditorCampaignReviewProps) => {
  let bottomMessage = ''

  if (reviewData && reviewData.ads.length >= 3) {
    bottomMessage =
      'Vous avez atteint le nombre maximum de publicités pour cette campagne.'
  }

  const container: Container = {
    column1: (
      <LabelledItemsList
        items={[
          {
            label: 'Budget alloué',
            value: `${reviewData.campaign.budget.toString()}` + ` €`
          },
          {
            label: 'Période de diffusion',
            value: `${startDateCampaign} au ${endDateCampaign}`
          },
          { label: 'Objectif', value: reviewData.campaign.goal.toString() },
          {
            label: 'Plateforme',
            value: reviewData.campaign.platform
          },
          {
            label: 'Format',
            value: getFormatEnum(reviewData.campaign.format)
          }
          // ...(checkIfGoogleCampaignReviewPageDataType(reviewData)
          //   ? [
          //       {
          //         label: 'Stratégie d’optimisation du budget',
          //         value: reviewData.campaign.bidStrategy
          //       }
          //     ]
          //   : [])
        ]}
      />
    ),
    items: getAds(reviewData, images)
  }

  const containerBlock: ContainerBlock = {
    title: reviewData.campaign.name,
    container,
    platform: reviewData.campaign.platform
  }

  let validation: {
    label: string
    action: () => void
    disabled: boolean
    disabledMessage?: string
    type?: 'primary' | 'secondary'
    isLoading?: boolean
  } | null = null

  let cancel: {
    label: string
    action: () => void
    disabled: boolean
  } | null = null

  if (flow === FlowEnum.view && reviewData.campaign.status === Status.DRAFT) {
    cancel = {
      label: 'Retour au plan publicitaire',
      action: () => {
        navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/details`)
      },
      disabled: false
    }

    validation = {
      label: 'Valider la campagne',
      action: () => {
        handleValidateCampaign()
      },
      disabled: reviewData.ads.length === 0,
      disabledMessage:
        'Veuillez ajouter au moins une publicité pour valider la campagne',
      type: 'primary',
      isLoading: isValidateCampaignLoading
    }
  } else {
    validation = {
      label: 'Retour au plan publicitaire',
      action: () => {
        navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
      },
      disabled: false,
      type: 'primary'
    }
  }

  const addAds = {
    label: 'Ajouter une publicité',
    action: () => {
      navigate(
        `/campaign/${campaignId}/ad/${reviewData.campaign.platform}/${reviewData.campaign.format}/create/`
      )
      scroll(0, 0)
    },
    disabled: false
  }

  const handleEdit = () => {
    navigate(`/campaign/${campaignId}/edit?flow=${FlowEnum.creation}`)
  }

  const handleDeleteAd = async (id: string) => {
    const result = await archiveAd({
      adId: id
    })
    if (checkIfArchiveAd(result)) {
      window.location.reload()
    } else {
      console.error('Impossible to archive ad')
    }
  }

  const handleEditAd = (id: string) => {
    // TODO : update according to the ad type and platform
    navigate(`/ad/${reviewData.campaign.format}/${id}/edit`)
  }

  const items = [
    {
      label: 'Plan publicitaire'
    },
    {
      onClick: () => {
        if (flow === FlowEnum.view) {
          navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/details`)
        } else {
          navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
        }
      },
      label: reviewData.navigation.mediaPlan.name
    },
    {
      label: `Campagne « ${reviewData.navigation.campaign.name} »`
    }
  ]

  const adCount = reviewData.ads.length

  const AddAd = () => {
    return (
      <Button type="tertiary" style={addButtonStyle} onClick={addAds.action}>
        <AddPicto width="10px" height="10px" />
        {addAds.label}
      </Button>
    )
  }

  return (
    <>
      <Breadcrumb items={items} />
      <ReviewPageTemplate
        name={reviewData.campaign.name}
        title={'Revue de la campagne'}
        type={StrategyEnum.CAMPAIGN}
        cancel={cancel || undefined}
        validation={validation}
        container={containerBlock}
        handleViewMore={handleViewMore}
        handleDelete={handleDeleteAd}
        handleEdit={handleEdit}
        handleSubEdit={handleEditAd}
        isSelfEditor={true}
        footer={<AddAd />}
        format={reviewData.campaign.format}
        co2={reviewData.campaign.co2}
        co2Header={reviewData.campaign.platform === PlatformAPIEnum.GOOGLE}
        adCount={adCount}
        bottomMessage={bottomMessage}
        handleOpenModal={() => {
          setIsCO2ModalOpen(true)
        }}
      />
    </>
  )
}

export default SelfEditorCampaignReview
