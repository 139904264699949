import React from 'react'
import TipsFormat from '../../../components/Tips/TipsFormat'

export const AudienceTipsEditor = () => (
  <TipsFormat
    title={`Définition d'une audience`}
    items={[
      {
        title: 'Audience définie',
        content: [
          `L'audience est le public ciblé par vos campagnes publicitaires. Elle est définie par différentes contraintes
        (par exemple où se situent les personnes que vous souhaitez toucher). Chaque campagne appartenant à votre plan
        média sera rattaché à une audience. Il est nécessaire de définir pour le plan média toutes les
        audiences qui seront utilisées par vos campagnes.`
        ]
      },
      {
        title: 'Campagnes ciblées',
        content: [
          `Alignez chaque campagne avec une audience spécifique. Une segmentation efficace et une planification basée sur des objectifs clairs conduisent à des résultats supérieurs.`
        ]
      }
    ]}
  />
)
