import React from 'react'

import LabelledItem, { LabelledItemType } from '../LabelledItem'
import { LabelledItemsListContainer } from './styles'

type LabelledItemsListProps = {
  items: LabelledItemType[]
  style?: React.CSSProperties
}

const LabelledItemsList = ({ items, style }: LabelledItemsListProps) => {
  return (
    <LabelledItemsListContainer style={style}>
      {items.map((item, index) => (
        <LabelledItem
          key={`item${index}-${item.label}`}
          id={item.id}
          label={item.label}
          value={item.value}
          onClick={item.onClick}
          onTooltipClick={item.onTooltipClick}
          hidden={item.hidden}
        />
      ))}
    </LabelledItemsListContainer>
  )
}

export default LabelledItemsList
