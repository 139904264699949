import React, { CSSProperties } from 'react'

import Input from '../../../components/Input'
import { inputStyle } from './styles'

type DisabledSectionProps = {
  value: string | string[]
  style?: CSSProperties
}

const DisabledSection = ({ value, style = {} }: DisabledSectionProps) => {
  if (typeof value === 'string') {
    return (
      <Input
        value={value}
        type="text"
        style={{ ...inputStyle, ...style }}
        disabled
        withBorder
      />
    )
  } else {
    return (
      <>
        {value.map((item: string) => (
          <Input
            key={item}
            value={item}
            type="text"
            style={{ ...inputStyle, ...style }}
            disabled
            withBorder
          />
        ))}
      </>
    )
  }
}

export default DisabledSection
