import React from 'react'

import MegaphonePicto from '../../../../../components/Icon/megaphonePicto'
import { ListContainer, ListLi, Title, Data } from './styles'
import { InfosProps } from './types'

const SelfEditorInfos = ({ mediaPlans, campaigns, ads }: InfosProps) => {
  return (
    <>
      <MegaphonePicto />
      <ListContainer>
        <ListLi>
          <Title>Plans publicitaires :</Title> <Data>{mediaPlans}</Data>
        </ListLi>
        <ListLi>
          <Title>Campagnes :</Title> <Data>{campaigns}</Data>
        </ListLi>
        <ListLi>
          <Title>Publicités :</Title> <Data>{ads}</Data>
        </ListLi>
      </ListContainer>
    </>
  )
}

export default SelfEditorInfos
