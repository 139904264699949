import React from 'react'

import { RadioListTmp } from '../../../../tmpComponents/RadioList'
import Period from '../../../components/Period'
import Margin from '../../../../constants/margin'
import {
  formatDateToEnglishString,
  formatDateToFrench,
  getMaxDate,
  getMinDate
} from '../../../../helpers/date'
import { Status } from '../../../../types/status'

export type PeriodType = {
  isSamePeriod: boolean
  handleChangePeriod: (isSamePeriod: boolean) => void
  startDate?: string
  endDate?: string
  startDateError?: string | undefined
  endDateError?: string | undefined
  mediaPlanStartDate: string
  mediaPlanEndDate: string
  mediaPlanStatus?: Status
  handleChangeStartDate: (input: string) => void
  handleChangeEndDate: (input: string) => void
}

const CampaignPeriod = ({
  isSamePeriod,
  handleChangePeriod,
  startDate,
  endDate,
  startDateError,
  endDateError,
  mediaPlanStartDate,
  mediaPlanEndDate,
  mediaPlanStatus,
  handleChangeStartDate,
  handleChangeEndDate
}: PeriodType) => {
  const twoDaysAfterToday = new Date(new Date().getTime() + 172800000)

  const dateRangeSection = !isSamePeriod && (
    <Period
      startDate={startDate ?? ''}
      endDate={endDate ?? ''}
      onChangeStartDate={handleChangeStartDate}
      onChangeEndDate={handleChangeEndDate}
      startDateError={startDateError}
      endDateError={endDateError}
      minStartDate={
        getMaxDate(
          formatDateToEnglishString(twoDaysAfterToday),
          mediaPlanStartDate
        ) ?? ''
      }
      maxStartDate={mediaPlanEndDate ?? ''}
      minEndDate={
        getMaxDate(
          formatDateToEnglishString(twoDaysAfterToday),
          mediaPlanStartDate
        ) ?? ''
      }
      maxEndDate={mediaPlanEndDate ?? ''}
      style={{ marginTop: Margin.m8 }}
    />
  )
  const mediaPlanStartDateString = formatDateToFrench(
    typeof mediaPlanStartDate === 'string'
      ? new Date(mediaPlanStartDate)
      : mediaPlanStartDate
  )

  const mediaPlanEndDateString = formatDateToFrench(
    typeof mediaPlanEndDate === 'string'
      ? new Date(mediaPlanEndDate)
      : mediaPlanEndDate
  )

  if (
    new Date(mediaPlanStartDate).getTime() <
    new Date().getTime() + 172800000
  ) {
    return dateRangeSection
  } else {
    return (
      <div>
        <RadioListTmp
          values={[
            {
              label: `Sur toute la période du plan média (du ${mediaPlanStartDateString} au ${mediaPlanEndDateString}) `
            },
            { label: 'Choisir d’autres dates de début et de fin' }
          ]}
          selectedValue={isSamePeriod ? 0 : 1}
          onChange={(index) => {
            handleChangePeriod(index === 0)
          }}
        />
        {dateRangeSection}
      </div>
    )
  }
}

export default CampaignPeriod
