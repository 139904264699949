export enum GetCampaignCreationDataErrorEnum {
  UNKNOWN = 'unknown',
  NO_RESPONSE = 'no-response',
  WRONG_RESPONSE_FORMAT = 'wrong-response-format'
}

export const CreateAudienceCreationDataErrorTranslation = {
  [GetCampaignCreationDataErrorEnum.UNKNOWN]:
    'Une erreur inconnue est survenue',
  [GetCampaignCreationDataErrorEnum.NO_RESPONSE]: `Nous rencontrons 
     des difficultés pour communiquer avec le serveur. 
     Merci de bien vouloir vérifier votre connexion internet`,
  [GetCampaignCreationDataErrorEnum.WRONG_RESPONSE_FORMAT]:
    'Erreur de format concernant une ou plusieurs données reçues.'
}
