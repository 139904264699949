import React from 'react'

import { PanelType } from '../../../../../types/templates/panels'
import { PreviewContainer } from '../styles'
import LinkedinSmartphonePreview from '../../../../../components/PreviewAds/Google/Display/LinkedinSmartphonePreview'
import { ImageAdCreationFormDataType } from '..'

type GetPreviewPanelProps = {
  companyName: string
  formData: ImageAdCreationFormDataType
  imageUrl: string
}

const getPreviewPanel = ({
  companyName,
  formData,
  imageUrl
}: GetPreviewPanelProps): PanelType => {
  const previewPanel: PanelType = {
    title: 'Aperçu de votre annonce',
    disabled: false,
    isDropdown: false,
    sections: [
      {
        description: 'Aperçu',
        content: (
          <PreviewContainer>
            <LinkedinSmartphonePreview
              companyName={companyName}
              content={formData.introText}
              image={imageUrl}
              headline={formData.headline}
              url={formData.url}
              cta={formData.ctaLabel ?? ''}
            />
          </PreviewContainer>
        )
      }
    ]
  }

  return previewPanel
}

export default getPreviewPanel
