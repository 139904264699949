import React, { CSSProperties, useState } from 'react'

import LineChartDrawer, {
  DatasetsSelection,
  DropDownData,
  LineChartData,
  Point
} from './LineChartDrawer'
import { RangeSelectorData } from './types'
import { processData } from './helpers/processData'
import { renderDefaultTooltip } from './Tooltips/Default/renderer'
import DefaultLineChartTooltip from './Tooltips/Default'
import { Option } from '../../Dropdown'
import {
  LineChartAggregatorEnum,
  RangeSelectionIdEnum,
  TooltipComponentType,
  TooltipRenderer,
  checkIfRangeSelectionOption
} from '../../../types/lineChart'
import { deepCloneWithDate } from '../../../helpers/clone'

type PropsType<PointType extends Point> = {
  rangeSelectorData: RangeSelectorData<PointType> | null
  data: LineChartData<PointType>
  renderTooltip?: TooltipRenderer<PointType>
  tooltipComponent?: TooltipComponentType<PointType>
  chartId: string
  width?: number
  style?: CSSProperties
  formatHeaderData?: (
    data: LineChartData<PointType>,
    initialSelectionNames?: string[],
    globalCTR?: number
  ) => DatasetsSelection
  initialSelectionNames?: string[]
  globalCTR?: number
}

const LineChart = <PointType extends Point>({
  rangeSelectorData,
  data,
  tooltipComponent,
  renderTooltip,
  width = 1000,
  style,
  formatHeaderData,
  initialSelectionNames,
  globalCTR,
  chartId
}: PropsType<PointType>) => {
  const [selectedRangeId, setSelectedRangeId] =
    useState<RangeSelectionIdEnum | null>(
      rangeSelectorData ? rangeSelectorData.defaultSelectionId : null
    )

  const selectedRangeObject = rangeSelectorData?.selectionOptions.find(
    (el) => el.id === selectedRangeId
  )

  const dropDownValue: Option | null = selectedRangeObject
    ? {
        id: selectedRangeObject.id,
        label: selectedRangeObject.label
      }
    : null

  const dropDownOptions: Option[] | null = rangeSelectorData
    ? rangeSelectorData.selectionOptions.map((option) => {
        return {
          id: option.id,
          label: option.label
        }
      })
    : null

  const handleDropDownChange = (selectedOption: Option | null) => {
    if (checkIfRangeSelectionOption(selectedOption)) {
      setSelectedRangeId(selectedOption.id)
    }
  }

  const dropDownData: DropDownData | null =
    rangeSelectorData && dropDownOptions && dropDownValue
      ? {
          options: dropDownOptions,
          value: dropDownValue,
          onChange: handleDropDownChange
        }
      : null

  const defaultAggregator = LineChartAggregatorEnum.SUM

  let processedData = deepCloneWithDate(data)

  const buildAggregatedPoint = rangeSelectorData?.buildAggregatedPoint ?? null

  processedData = processData(
    processedData,
    selectedRangeId,
    defaultAggregator,
    buildAggregatedPoint
  )

  return (
    <LineChartDrawer
      dropDownData={dropDownData}
      data={processedData}
      width={width}
      formatHeaderData={formatHeaderData}
      style={style}
      initialSelectionNames={initialSelectionNames}
      renderTooltip={renderTooltip ?? renderDefaultTooltip}
      tooltipComponent={tooltipComponent ?? DefaultLineChartTooltip}
      chartId={chartId}
      globalCTR={globalCTR}
    />
  )
}

export default LineChart
