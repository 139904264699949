import {
  GoogleCampaignReviewApiType,
  checkIfGoogleCampaignReviewApi
} from './google/googleCampaignReview'
import {
  LinkedinCampaignReviewApiType,
  checkIfLinkedinCampaignReviewApi
} from './linkedin/linkedinCampaignReview'

export type CampaignReviewApiType =
  | GoogleCampaignReviewApiType
  | LinkedinCampaignReviewApiType

export const checkIfCampaignReviewApi = (
  value: any
): value is CampaignReviewApiType => {
  return (
    checkIfGoogleCampaignReviewApi(value) ||
    checkIfLinkedinCampaignReviewApi(value)
  )
}
