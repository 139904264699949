import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TableListDataType, TableListFilter } from '../../components/TableList'
import KPI from './UI/KPI'
import { DivHeadBanner, Hr, TutorialSwitchContainer } from './styles'
import { EditorHomeDataType } from '../../helpers/queries/getHomeData'
import HomeTableList from './UI/HomeTableList'
import { Status } from '../../types/status'
import { formatDateToString } from '../../helpers/date'
import Benefits from './UI/BoxInfo/Benefits'
import { BoxInfoProps } from './UI/BoxInfo'
import Infos from './UI/BoxInfo/Infos/editor'
import { PieChartProps } from '../../components/ProgressionChart'
import Customer from './UI/BoxInfo/Customer'
import Colors from '../../constants/colors'
import StatusWithColor from '../../components/StatusDot'
import Introduction from '../../components/Onboarding/Introduction'
import { editorOnboardingSlides } from '../../components/Onboarding/Slides/editor'
import { EditorItemType } from './types'
import Title from '../../components/Title'
import QuestionMarkIcon from '../../components/Icon/questionMark'
import { AppContext } from '../../contexts/AppContext'

type HomeEditorContentProps = {
  data: EditorHomeDataType
  filteredData: EditorItemType[]
  selectedInProgressType: string
  filters: TableListFilter[]
  dateRangeValue: string
  setDateRangeValue: (newValue: string) => void
  fetchDataOnClickDate: (dateRangeValue: string) => Promise<void>
  handleOnRowClick: (id: string) => void
  handleRowAction: (id: string) => void
  handleShowReason: (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => void
  totalItems: number
  onChangePage: (page: number) => void
  currentPage: number
  isTutorialHidden: boolean
  setIsTutorialHidden: (value: boolean) => void
}

const HomeEditorContent = ({
  data,
  filteredData,
  selectedInProgressType,
  filters,
  dateRangeValue,
  setDateRangeValue,
  fetchDataOnClickDate,
  handleOnRowClick,
  handleShowReason,
  totalItems,
  onChangePage,
  currentPage,
  isTutorialHidden,
  setIsTutorialHidden
}: HomeEditorContentProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const { user } = useContext(AppContext)

  const navigate = useNavigate()

  const pieChart: PieChartProps = {
    total: {
      value: data.budget.estimated,
      color: Colors.NEW_YETIC_BOX_INFO_TIP
    },
    partial: { value: data.budget.spent, color: Colors.YETIC_YELLOW_DARK }
  }

  const formattedData: TableListDataType = {
    titles: ['Nom', 'Client', 'Statut', 'Modifié le'],
    widths: ['31%', '25%', '25%', '15%'],
    items: filteredData.map((item) => ({
      id: item.id,
      type: selectedInProgressType,
      row: [
        item.name,
        item.customerName,
        <StatusWithColor
          status={item.status as Status}
          key={item.status}
          handleClick={
            item.status === Status.REJECTED
              ? (event) => {
                  handleShowReason(event, item.id)
                }
              : undefined
          }
        />,
        formatDateToString(new Date(item.updatedAt))
      ]
    }))
  }

  const boxInfos: BoxInfoProps[] = [
    {
      title: 'Revenus',
      content: (
        <Benefits
          estimatedLabel="Estimés"
          accomplishedLabel="Réalisés"
          pieChart={pieChart}
        />
      ),
      titleInfo:
        'Revenus réalisés : Profit net effectif après commission versée à YETIC. Revenus estimés : Profit net prévu sur l’ensemble des budgets clients.'
    },
    {
      title: 'Composants créés',
      content: (
        <Infos
          ads={data.adCount}
          campaigns={data.campaignCount}
          mediaPlans={data.mediaPlanCount}
        />
      ),
      style: {
        justifyContent: 'center'
      }
    },
    {
      title: 'Clients',
      content: <Customer value={data.customerCount} />
    }
  ]

  const totalSlides = Object.keys(editorOnboardingSlides(navigate)).length

  const handleChangeSlide = (index: number) => {
    setCurrentSlide(index)
  }

  const handleNextSlide = () => {
    if (currentSlide < totalSlides) {
      setCurrentSlide(currentSlide + 1)
    }
  }

  const handlePreviousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    }
  }

  const slideData = editorOnboardingSlides(navigate)[currentSlide]

  const handleSkipIntroduction = () => {
    setCurrentSlide(0)
    setIsTutorialHidden(true)
  }

  return (
    <>
      <DivHeadBanner>
        <Title style={{ marginBottom: 0 }}>Bonjour, {user?.firstName}.</Title>
        {isTutorialHidden && (
          <TutorialSwitchContainer
            onClick={() => {
              setIsTutorialHidden(false)
            }}
          >
            <QuestionMarkIcon width="28px" height="28px" />
          </TutorialSwitchContainer>
        )}
      </DivHeadBanner>
      {!isTutorialHidden && (
        <Introduction
          title={slideData.title}
          description={slideData.description}
          buttonConfirm={slideData.buttonConfirm}
          buttonCancel={slideData.buttonCancel}
          imageWebp={slideData.imageWebp}
          imagePng={slideData.imagePng}
          skipIntroduction={slideData.skipIntroduction}
          totalSlides={totalSlides}
          currentSlide={currentSlide}
          handleNextSlide={handleNextSlide}
          handlePreviousSlide={handlePreviousSlide}
          handleChangeSlide={handleChangeSlide}
          isNotBottom={slideData.isNotBottom}
          handleSkipIntroduction={handleSkipIntroduction}
        />
      )}
      <KPI
        selectedFilter={dateRangeValue}
        setSelectedFilter={(newValue: string) => {
          setDateRangeValue(newValue)
        }}
        boxInfos={boxInfos}
        handleClick={fetchDataOnClickDate}
      />
      <Hr />
      <HomeTableList
        data={formattedData}
        filters={filters}
        handleOnRowClick={handleOnRowClick}
        // handleRowAction={handleRowAction}
        totalItems={totalItems}
        handleChangePage={onChangePage}
        currentPage={currentPage}
      />
    </>
  )
}

export default HomeEditorContent
