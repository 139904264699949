import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import FontSize from '../../../constants/fontSize'
import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'

export const DivButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Margin.m6};
  width: 80%;
  max-width: 900px;
  margin: auto;
`
export const DivErrorsContainer = styled.div`
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  width: 80%;
  max-width: 900px;
  margin: auto;
  margin-bottom: ${Margin.m6};
`

export const DivErrorsIntroduction = styled.div`
  color: ${Colors.YETIC_RED};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
`

export const DivErrors = styled.div`
  color: ${Colors.YETIC_RED};
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  text-align: center;
`

export const Preview = styled.div`
  z-index: 3;
  position: sticky;
  top: ${Margin.m0};
  background-color: ${Colors.YETIC_WHITE};
  margin: 0 auto;
  margin-bottom: ${Margin.m6};
  padding: ${Padding.p5} 0px;
  box-shadow: 0px 4px 4px -2px ${Colors.NEW_YETIC_GREY};
`
