import { backendURL } from '../../../../constants/urls'
import {
  CreateAdErrorFromApi,
  checkIfCreateAdErrorCodeValid
} from '../../../../types/error/ad/create'
import { ErrorOutput } from '../../../../types/error/generic'
import { axiosPost, checkIsAxiosPostOutputSuccess } from '../../axios/post'

export type CreateLinkedinImageAdOutputType = {
  adId: string
}

export type CreateLinkedinImageAdInputType = { form: FormData }

export const createLinkedinImageAd = async (
  input: CreateLinkedinImageAdInputType
): Promise<CreateLinkedinImageAdOutputType | ErrorOutput> => {
  if (checkIfCreateLinkedinImageAdInput(input)) {
    try {
      const response = await axiosPost({
        url: `${backendURL}/linkedin/ads/image`,
        contentType: 'multipart/form-data',
        data: input.form
      })

      if (checkIsAxiosPostOutputSuccess(response)) {
        if (response.data.adId) {
          return {
            adId: response.data.adId
          }
        }
      } else {
        const errorCode =
          (response.error as any).data?.code ?? (response.error as any).code
        if (errorCode && checkIfCreateAdErrorCodeValid(errorCode)) {
          return { error: CreateAdErrorFromApi[errorCode] }
        }
      }

      return { error: CreateAdErrorFromApi.UNKNOWN }
    } catch (error) {
      const errorCode =
        (error as any)?.response?.data?.code ?? (error as any)?.response?.code
      if (errorCode && checkIfCreateAdErrorCodeValid(errorCode)) {
        return { error: CreateAdErrorFromApi[errorCode] }
      }
      return { error: CreateAdErrorFromApi.UNKNOWN }
    }
  } else {
    return { error: CreateAdErrorFromApi.INPUT_FORMAT }
  }
}

const checkIfCreateLinkedinImageAdInput = (
  value: any
): value is CreateLinkedinImageAdInputType => {
  return value && value.form !== undefined
}

export const checkIfCreateLinkedinImageAdOutput = (
  value: any
): value is CreateLinkedinImageAdOutputType => {
  return value && value.adId !== undefined
}
