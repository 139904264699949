import React from 'react'

import MultipleSelectionInput from '../../../../../components/Input/MultipleSelectionInput'

type JobFunctionsProps = {
  data: string[]
  selectedTags: string[]
  error?: string
  removeJobFunction: (index: number) => void
  addNewJobFunction: (tag: string) => void
}

const JobFunctions = ({
  data,
  selectedTags,
  error,
  removeJobFunction,
  addNewJobFunction
}: JobFunctionsProps) => {
  return (
    <MultipleSelectionInput
      data={data}
      selectedTags={selectedTags}
      error={error}
      removeItem={removeJobFunction}
      addNewItem={addNewJobFunction}
      label="Fonctions professionnelles"
      placeholder="Marketing, Communication, Finance..."
      isRequired={false}
    />
  )
}

export default JobFunctions
