import React, { useEffect, useState } from 'react'

import { getCO2andUnit } from '../../../helpers/getCO2andUnit'
import { GetCampaignCO2DetailsOutputType } from '../../../helpers/queries/campaign/getCampaignCO2Details'
import AnimatedLoader from '../../AnimatedLoader'
import DefaultText from '../../DefaultText'
import {
  Item,
  CO2Total,
  Container,
  checkboxStyle,
  RowValue,
  Content,
  Value,
  inputStyle,
  suffixInputStyle,
  simulationSubtitleStyle,
  Footer,
  co2TotalTextStyle,
  leafTotalStyle,
  KnowMoreContainer,
  knowMoreTextStyle,
  arrowStyle,
  ItemHeader,
  valueTextStyle,
  commentTextStyle,
  changeValueButtonStyle,
  substractionButtonStyle,
  ScoreContainer
} from './styles'
import Devices from '../../../pages/CampaignCreation/UI/Devices'
import {
  DevicesBoolean,
  DevicesEnumTranslation,
  DevicesNumber,
  checkKeyOfDevicesBoolean,
  getKeysOfDevicesNumber
} from '../../../types/campaign/devices'
import Button from '../../Button'
import { simulateCampaignCO2Calculation } from '../../../helpers/queries/campaign/simulateCampaignCO2Calculation'
import MultiRangeSlider, {
  MultiRangeData,
  MultiRangeItem
} from '../../MultiRangeSlider'
import Margin from '../../../constants/margin'
import { getCO2TheoreticalDevicePortions } from '../../../helpers/queries/campaign/getCO2TheoreticalDevicePortions'
import { InputError } from '../../Input/Styles'
import { FormatAPIEnum } from '../../../types/format'
import Input from '../../Input'
import { getArrayAverage } from '../../../helpers/getArrayAverage'
import { roundToTwoDecimals } from '../../../helpers/rounds'
import Colors from '../../../constants/colors'
import LeafIcon from '../../Icon/leaf'
import { ArrowNext } from '../../Icon'
import ScoreComparison from './ScoreComparison/ScoreComparison'

type SimulationTabProps = {
  campaignId: string
  format: FormatAPIEnum
  co2Details: GetCampaignCO2DetailsOutputType | null
  campaignDevices?: DevicesBoolean
}

const SimulationTab = ({
  campaignId,
  format,
  co2Details,
  campaignDevices
}: SimulationTabProps) => {
  const [devices, setDevices] = useState<DevicesBoolean | null>(
    campaignDevices ?? null
  )
  const [co2Calculation, setCo2Calculation] = useState<number | null>(null)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [multiRangeData, setMultiRangeData] = useState<MultiRangeData | null>(
    null
  )

  const [networkSliderData, setNetworkSliderData] =
    useState<MultiRangeData | null>([
      { value: 28, label: 'Mobile' },
      { value: 72, label: 'WIFI' }
    ])

  let imagesCO2Average = 0
  let logoCO2 = 0

  if (format === FormatAPIEnum.IMAGE) {
    imagesCO2Average = co2Details
      ? roundToTwoDecimals(getArrayAverage(co2Details.weight.images) / 1000)
      : 0
    logoCO2 = co2Details ? co2Details.weight.logo / 1000 : 0
  }

  const [logo, setLogo] = useState<string>(logoCO2.toString() || '')
  const [picture, setPicture] = useState<string>(
    imagesCO2Average.toString() || ''
  )

  const proportionsInPercentages: DevicesNumber | null = co2Details
    ? {
        desktop: Math.round(co2Details.details.desktop.portion * 100),
        smartphone: Math.round(co2Details.details.smartphone.portion * 100),
        tablet: Math.round(co2Details.details.tablet.portion * 100),
        tv: Math.round(co2Details.details.tv.portion * 100)
      }
    : null

  useEffect(() => {
    if (proportionsInPercentages != null && devices) {
      const data = getKeysOfDevicesNumber()
        .map((device) => {
          if (devices[device]) {
            return {
              value: proportionsInPercentages[device],
              label: DevicesEnumTranslation[device]
            }
          } else {
            return null
          }
        })
        .filter(
          (deviceData: MultiRangeItem | null): deviceData is MultiRangeItem =>
            deviceData !== null
        )

      setMultiRangeData(data)
    }
  }, [co2Details])

  useEffect(() => {
    if (proportionsInPercentages && devices) {
      let newProportions = proportionsInPercentages

      ;(async function () {
        const result = await getCO2TheoreticalDevicePortions({
          devices
        })
        if (result !== null) {
          newProportions = result.portions
        }

        const newMultiRangeData = getKeysOfDevicesNumber()
          .map((device) => {
            if (devices[device]) {
              return {
                value: newProportions[device],
                label: DevicesEnumTranslation[device]
              }
            } else {
              return null
            }
          })
          .filter(
            (deviceData: MultiRangeItem | null): deviceData is MultiRangeItem =>
              deviceData !== null
          )

        setMultiRangeData(newMultiRangeData)
      })()
    }
  }, [devices])

  if (
    co2Details == null ||
    multiRangeData == null ||
    networkSliderData == null ||
    devices == null
  ) {
    return <AnimatedLoader style={{ margin: 'auto' }} />
  }

  const logoWeight = parseFloat(logo) || 0

  let totalImagesWeight = 0
  let averageImagesWeight = 0

  if (format === FormatAPIEnum.IMAGE) {
    totalImagesWeight =
      parseFloat(picture) * co2Details.weight.images.length || 0
    averageImagesWeight = totalImagesWeight / co2Details.weight.images.length
  }

  const picturesTotalWeight =
    roundToTwoDecimals(logoWeight + averageImagesWeight) || 0

  const handleChangeDevice = (device: string) => {
    if (checkKeyOfDevicesBoolean(device)) {
      setDevices({
        ...devices,
        [device]: !devices[device]
      })
    }
  }

  const handleChangeProportions = (newValues: number[]) => {
    if (multiRangeData) {
      setMultiRangeData(
        multiRangeData.map((data) => {
          const index = multiRangeData.indexOf(data)
          return {
            ...data,
            value: newValues[index]
          }
        })
      )
    }
  }

  const handleNetworkSliderChange = (newValues: number[]) => {
    if (networkSliderData) {
      const newNetworkSliderData = networkSliderData.map((data, index) => ({
        ...data,
        value: newValues[index]
      }))

      setNetworkSliderData(newNetworkSliderData)
    }
  }

  const handleSubmit = async () => {
    if (multiRangeData && networkSliderData) {
      const proportions = {
        desktop:
          multiRangeData.find((v) => v.label === DevicesEnumTranslation.desktop)
            ?.value ?? 0,
        smartphone:
          multiRangeData.find(
            (v) => v.label === DevicesEnumTranslation.smartphone
          )?.value ?? 0,
        tablet:
          multiRangeData.find((v) => v.label === DevicesEnumTranslation.tablet)
            ?.value ?? 0,
        tv:
          multiRangeData.find((v) => v.label === DevicesEnumTranslation.tv)
            ?.value ?? 0
      }

      const mobile =
        networkSliderData.find((v) => v.label === 'Mobile')?.value ?? 0
      const fixed =
        networkSliderData.find((v) => v.label === 'WIFI')?.value ?? 0

      const co2Result = await simulateCampaignCO2Calculation({
        campaignId,
        proportions,
        networkProportions: { mobile, fixed },
        picturesTotalWeight
      })

      if (co2Result !== null && typeof co2Result === 'number') {
        setCo2Calculation(co2Result)
        setHasFetchError(false)
      } else {
        setHasFetchError(true)
      }
    }
  }

  const co2AndUnit = getCO2andUnit(co2Details.co2) ?? { co2: 0, co2Unit: '' }
  const co2CalculationAndUnit = co2Calculation
    ? getCO2andUnit(co2Calculation)
    : null

  const handleLogoChange = (value: string) => {
    setLogo(value)
  }

  const handlePictureChange = (value: string) => {
    setPicture(value)
  }

  return (
    <Container>
      <Content style={{ height: '54vh', overflow: 'scroll' }}>
        <Item withBorder>
          <DefaultText size="big">Périphériques</DefaultText>
          <Value>
            <DefaultText
              size="medium"
              style={{ ...simulationSubtitleStyle, marginTop: Margin.m4 }}
            >
              Périphériques ciblés
            </DefaultText>
            <Devices
              isDesktopSelected={devices.desktop}
              isSmartphoneSelected={devices.smartphone}
              isTabletSelected={devices.tablet}
              isTVSelected={devices.tv}
              handleChangeDevice={handleChangeDevice}
              style={checkboxStyle}
              withBorder={false}
            />
          </Value>
          <Value
            style={{ width: '90%', display: 'flex', flexDirection: 'column' }}
          >
            <DefaultText
              size="medium"
              style={{ ...simulationSubtitleStyle, marginTop: Margin.m6 }}
            >
              Répartition des périphériques
            </DefaultText>
            <MultiRangeSlider
              data={multiRangeData}
              max={100}
              style={{ alignSelf: 'center' }}
              onChange={handleChangeProportions}
            />
          </Value>
        </Item>
        <Item withBorder>
          {format === FormatAPIEnum.TEXT ? (
            <>
              <DefaultText size="big">Poids des données</DefaultText>
              <Value>
                <DefaultText size="medium" style={commentTextStyle}>
                  Négligeable pour les annonces textes
                </DefaultText>
              </Value>
            </>
          ) : (
            <>
              <ItemHeader>
                <DefaultText size="big">Poids des données</DefaultText>
                <Value>
                  <DefaultText size="medium" style={valueTextStyle}>
                    {picturesTotalWeight ? `${picturesTotalWeight} Ko` : '-'}
                  </DefaultText>
                </Value>
              </ItemHeader>
              <RowValue>
                <DefaultText size="medium">Logo</DefaultText>
                <RowValue style={{ justifyContent: 'flex-end' }}>
                  <Button
                    style={substractionButtonStyle}
                    type="primary"
                    onClick={() => {
                      handleLogoChange((parseFloat(logo) - 1).toString())
                    }}
                  >
                    -
                  </Button>
                  <Button
                    style={changeValueButtonStyle}
                    type="primary"
                    onClick={() => {
                      handleLogoChange((parseFloat(logo) + 1).toString())
                    }}
                  >
                    +
                  </Button>
                  <Input
                    type="text"
                    value={logo}
                    onChange={(e) => {
                      if (/^\d*\.?\d*$/.test(e.target.value)) {
                        handleLogoChange(e.target.value)
                      }
                    }}
                    style={inputStyle}
                  />
                  <DefaultText size="medium" style={suffixInputStyle}>
                    Ko
                  </DefaultText>
                </RowValue>
              </RowValue>
              <RowValue style={{ marginTop: '0px' }}>
                <DefaultText
                  size="medium"
                  style={{ textAlign: 'left' }}
                >{`Taille moyenne d'une image`}</DefaultText>
                <RowValue style={{ justifyContent: 'flex-end' }}>
                  <Button
                    style={substractionButtonStyle}
                    type="primary"
                    onClick={() => {
                      handlePictureChange((parseFloat(picture) - 1).toString())
                    }}
                  >
                    -
                  </Button>
                  <Button
                    style={changeValueButtonStyle}
                    type="primary"
                    onClick={() => {
                      handlePictureChange((parseFloat(picture) + 1).toString())
                    }}
                  >
                    +
                  </Button>
                  <Input
                    type="text"
                    value={picture}
                    onChange={(e) => {
                      if (/^\d*\.?\d*$/.test(e.target.value)) {
                        handlePictureChange(e.target.value)
                      }
                    }}
                    style={inputStyle}
                  />
                  <DefaultText size="medium" style={suffixInputStyle}>
                    Ko
                  </DefaultText>
                </RowValue>
              </RowValue>
            </>
          )}
        </Item>
        <Item>
          <DefaultText size="big">Réseaux</DefaultText>
          {format === FormatAPIEnum.TEXT ? (
            <Value>
              <DefaultText size="medium" style={commentTextStyle}>
                Négligeable pour les annonces textes
              </DefaultText>
            </Value>
          ) : (
            <Value
              style={{ width: '90%', display: 'flex', flexDirection: 'column' }}
            >
              <DefaultText
                size="medium"
                style={{ ...simulationSubtitleStyle, marginTop: Margin.m4 }}
              >
                Répartition réseau mobile (4G) / réseau fixe (WIFI) :
              </DefaultText>
              <MultiRangeSlider
                data={networkSliderData}
                max={100}
                style={{ alignSelf: 'center' }}
                onChange={handleNetworkSliderChange}
              />
            </Value>
          )}
        </Item>

        {hasFetchError && (
          <InputError>Une erreur inconnue est survenue.</InputError>
        )}
      </Content>
      <Footer>
        <Button
          onClick={() => {
            handleSubmit()
          }}
        >
          Lancer la simulation
        </Button>
        <CO2Total>
          <DefaultText size="big" style={co2TotalTextStyle}>
            <LeafIcon
              color={Colors.NEW_YETIC_GREEN}
              height="20px"
              style={leafTotalStyle}
            />
            {`Nouvelle estimation de l'empreinte CO2`}
          </DefaultText>
          <ScoreContainer>
            <ScoreComparison
              previousValue={co2AndUnit.co2}
              currentValue={
                co2CalculationAndUnit ? co2CalculationAndUnit.co2 : null
              }
            />
            <DefaultText
              size="big"
              bold
              style={{ fontSize: '40px', lineHeight: '40px' }}
            >
              {co2CalculationAndUnit !== null
                ? `${co2CalculationAndUnit.co2} ${co2CalculationAndUnit.co2Unit}`
                : `${co2AndUnit.co2} ${co2AndUnit.co2Unit}`}
            </DefaultText>
          </ScoreContainer>
        </CO2Total>
        <KnowMoreContainer>
          <DefaultText size="medium" style={knowMoreTextStyle}>
            {`En savoir plus sur le calcul de l'empreinte carbone`}
          </DefaultText>
          <ArrowNext width="8px" color="#737f94" style={arrowStyle} />
        </KnowMoreContainer>
      </Footer>
    </Container>
  )
}

export default SimulationTab
