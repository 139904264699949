import styled from '@emotion/styled'

import Margin from '../../../../../../constants/margin'
import Colors from '../../../../../../constants/colors'
import Padding from '../../../../../../constants/padding'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${Margin.m5} 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 2px #888888;
`

export const Container = styled.div`
  display: flex;
  flex: 12;
`

export const ContainerScroll = styled(Container)`
  max-height: 160px;
  overflow-y: scroll;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.div<{
  column: number
  row: number
  totalRows: number
  totalColumns: number
}>`
  display: flex;
  flex-direction: row;
  border-right: ${(props) =>
    props.column !== props.totalColumns - 1 ? `1px solid #eeeeee` : ''};
  padding: ${Padding.p3};
  padding-top: ${(props) =>
    props.row === 2 || props.row === 4 ? Padding.p5 : Padding.p3};
  padding-bottom: ${(props) =>
    props.row === 2 || props.row === props.totalRows - 1
      ? Padding.p5
      : Padding.p3};
  padding-left: ${(props) => (props.column !== 0 ? Padding.p3 : Padding.p6)};
  padding-right: ${(props) =>
    props.column !== props.totalColumns - 1 && props.row > 1
      ? Padding.p4
      : Padding.p6};
  align-items: center;
  min-height: ${(props) => (props.row === 0 ? '28px' : '20px')};
  max-height: ${(props) => (props.row === 0 ? '28px' : '20px')};

  justify-content: ${(props) =>
    props.row === 0 || props.row === 1 || props.row === 3 || props.column === 0
      ? 'flex-start'
      : 'flex-end'};

  background-color: ${(props) =>
    props.row === 0
      ? Colors.YETIC_GREY_DARK
      : props.row === 1 || props.row === 3
      ? Colors.NEW_YETIC_GREY
      : Colors.YETIC_WHITE};
`

export const ColumnScroll = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;
`
