import { backendURL } from '../../../constants/urls'
import {
  MediaPlanDetailsType,
  checkIfMediaPlanDetails
} from '../../../types/mediaPlan/mediaPlan'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : manage error
export type GetMediaPlanOutputType = {
  mediaPlan: MediaPlanDetailsType
}

export type GetMediaPlanInput = {
  mediaPlanId: string
  limit?: number
  skip?: number
}

export const getMediaPlanById = async ({
  mediaPlanId,
  limit,
  skip
}: GetMediaPlanInput): Promise<GetMediaPlanOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/media-plans/${mediaPlanId}${
        skip ? `?skip=${skip * 5}` : ''
      }${limit ? (skip ? `&limit=${limit}` : `?limit=${limit}`) : ''}`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfMediaPlanDetails(response.data)) {
        return {
          mediaPlan: response.data
        }
      }
    }
    return null
  } catch (_) {
    return null
  }
}
