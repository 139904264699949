import React from 'react'

const ArrowDropUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="#142A4C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 6.66667L8 10L11.3333 6.66667L12
        7.33333L8 11.3333L4 7.33333L4.66667 6.66667Z"
        fill="#142A4C"
        transform="rotate(-90 8 8)"
      />
    </svg>
  )
}

export default ArrowDropUp
