import * as d3 from 'd3'
import { MutableRefObject } from 'react'

type MarginType = {
  top: number
  right: number
  bottom: number
  left: number
}

const initChart = (
  chartRef: MutableRefObject<HTMLDivElement | null>,
  width: number,
  widthWithoutMargin: number,
  height: number,
  margin: MarginType
) => {
  d3.select(chartRef.current).selectAll('svg').remove()

  const svg = d3
    .select(chartRef.current)
    .append('svg')
    .attr('width', widthWithoutMargin + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  return svg
}

export default initChart
