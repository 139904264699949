import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'

export const titleStyle: CSSProperties = {
  marginBottom: Margin.m5
}
export const modalReasonOfRejectStyle: CSSProperties = {
  width: '600px'
}

export const reasonStyle: CSSProperties = {
  marginTop: Margin.m4
}

export const DivReasonOfReject = styled.div`
  text-align: left;
`
