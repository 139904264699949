import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Input from '../../../components/Input'
import DefaultText from '../../../components/DefaultText'
import Margin from '../../../constants/margin'
import { getMaxDate, getMinDate } from '../../../helpers/date'

type PeriodType = {
  startDate: string
  endDate: string
  minStartDate?: string
  maxStartDate?: string
  minEndDate?: string
  maxEndDate?: string
  startDateError?: string
  endDateError?: string
  style?: CSSProperties
  onChangeStartDate: (startDate: string) => void
  onChangeEndDate: (endDate: string) => void
}

const DivContainer = styled.div`
  display: flex;
`
const DivDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 50%;
  margin-bottom: ${Margin.m6};
`

const Period = ({
  startDate,
  endDate,
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
  startDateError,
  endDateError,
  style,
  onChangeStartDate,
  onChangeEndDate
}: PeriodType) => {
  return (
    <div style={style}>
      <DivContainer>
        <DivDate>
          <DefaultText>Date de début</DefaultText>
          <Input
            type="date"
            width="90%"
            value={startDate}
            min={minStartDate}
            max={getMinDate(maxStartDate, endDate)}
            onChange={(e) => {
              onChangeStartDate(e.target.value)
            }}
            error={startDateError}
            withBorder
          />
        </DivDate>
      </DivContainer>
      <DivContainer>
        <DivDate>
          <DefaultText>Date de fin</DefaultText>
          <Input
            type="date"
            width="90%"
            value={endDate}
            min={getMaxDate(minEndDate, startDate)}
            max={maxEndDate}
            onChange={(e) => {
              onChangeEndDate(e.target.value)
            }}
            error={endDateError}
            withBorder
          />
        </DivDate>
      </DivContainer>
    </div>
  )
}

export default Period
