import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearAllCookies, getCookie } from '../helpers/cookies'
import { AppContext } from '../contexts/AppContext'
import handleLogout from '../helpers/queries/auth/logout'
import { getDataFromCookieInHOC } from './helpers/cookies'

const RequiredIfNotAuthenticated = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const { companyName, isEditor, user, setUser } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async function () {
      if (companyName == null || isEditor == null || user == null) {
        const dataFromCookies = getDataFromCookieInHOC()

        if (dataFromCookies != null) {
          const {
            companyId,
            firstName,
            lastName,
            email,
            companyName,
            isEditor,
            isSelfEditor
          } = dataFromCookies

          if (firstName && lastName && companyName && isEditor != null) {
            setUser({
              user: {
                firstName,
                lastName,
                email
              },
              isEditor,
              isSelfEditor,
              companyId,
              companyName
            })
            navigate('/')
          }
          setIsLoading(false)
        } else {
          clearAllCookies()
          setIsLoading(false)
          await handleLogout()
        }
      }
      setIsLoading(false)
    })()
  }, [])

  if (isLoading) {
    // TODO graphism of the UI
    return 'loading'
  }

  return children
}

export default RequiredIfNotAuthenticated
