import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const modalStyle: CSSProperties = {
  maxWidth: '450px'
}

export const modalTextStyle: CSSProperties = {
  margin: `${Margin.m6} 0 ${Margin.m8}`
}

export const ResetPasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f4f6;
  height: fit-content;
  border: 1px solid #8893a4;
  justify-content: center;
  border-radius: 10px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 ${Margin.m10};
  width: 600px;
  text-align: center;
  padding: ${Padding.p10};
`

export const DivPasswordContainer = styled.div`
  width: 600px;
  margin-top: ${Margin.m7};
  text-align: center;
`

export const inputStyle: CSSProperties = {
  marginBottom: Margin.m6
}

export const successMessageStyle: CSSProperties = {
  marginTop: Margin.m6
}
