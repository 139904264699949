import React from 'react'

import ReviewPageTemplate from '../../templates/ReviewPageTemplate'
import {
  ContainerBlock,
  Container
} from '../../templates/ReviewPageTemplate/ReviewContent'
import { getFormatEnum } from '../../types/format'
import { checkIfGoogleCampaignReviewPageDataType } from '../../types/pages/campaignReview'
import Breadcrumb from '../../components/BreadCrumb'
import { StrategyEnum } from '../../types/strategy'
import LabelledItemsList from '../../components/LabelledItemsList'
import Button from '../../components/Button'
import { archiveAd, checkIfArchiveAd } from '../../helpers/queries/ad/archiveAd'
import AddPicto from '../../components/Icon/addPicto'
import { addButtonStyle } from './styles'
import { getAds } from './helpers/getAds'
import { EditorCampaignReviewProps } from './types/editor'
import { PlatformAPIEnum } from '../../types/platform'
import { FlowEnum } from '../../types/flow'

const EditorCampaignReview = ({
  reviewData,
  campaignId,
  startDateCampaign,
  endDateCampaign,
  images,
  flow,
  setIsCO2ModalOpen,
  navigate,
  handleViewMore
}: EditorCampaignReviewProps) => {
  let bottomMessage = ''

  if (reviewData && reviewData.ads.length >= 3) {
    bottomMessage =
      "Vous avez atteint le nombre maximum d'annonces pour cette campagne."
  }

  const container: Container = {
    column1: (
      <LabelledItemsList
        items={[
          {
            label: 'Budget total ',
            value: `${reviewData.campaign.budget.toString()}` + ` €`
          },
          {
            label: 'Période ',
            value: `${startDateCampaign} au ${endDateCampaign}`
          },
          { label: 'Objectif ', value: reviewData.campaign.goal.toString() },
          {
            label: 'Plateforme',
            value: reviewData.campaign.platform
          },
          {
            label: 'Format ',
            value: getFormatEnum(reviewData.campaign.format)
          }
          // ...(checkIfGoogleCampaignReviewPageDataType(reviewData)
          //   ? [{ label: 'Enchères ', value: reviewData.campaign.bidStrategy }]
          //   : [])
        ]}
      />
    ),
    items: getAds(reviewData, images)
  }

  const containerBlock: ContainerBlock = {
    title: reviewData.campaign.name,
    container,
    platform: reviewData.campaign.platform
  }

  const validation: {
    label: string
    action: () => void
    disabled: boolean
    type?: 'primary' | 'secondary'
  } = {
    label: 'Retour au plan média',
    action: () => {
      navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
    },
    disabled: false,
    type: reviewData.ads.length > 0 ? 'primary' : 'secondary'
  }

  const addAds = {
    label: 'Ajouter une annonce',
    action: () => {
      navigate(
        `/campaign/${campaignId}/ad/${reviewData.campaign.platform}/${reviewData.campaign.format}/create/`
      )
      scroll(0, 0)
    },
    disabled: false
  }

  const handleEdit = () => {
    navigate(`/campaign/${campaignId}/edit?flow=${FlowEnum.creation}`)
  }

  const handleDeleteAd = async (id: string) => {
    const result = await archiveAd({
      adId: id
    })
    if (checkIfArchiveAd(result)) {
      window.location.reload()
    } else {
      console.error('Impossible to archive ad')
    }
  }

  const handleEditAd = (id: string) => {
    // TODO : update according to the ad type and platform
    navigate(`/ad/${reviewData.campaign.format}/${id}/edit`)
  }

  const items = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate(`/customers`)
      },
      label: 'Clients'
    },
    {
      onClick: () => {
        navigate(`/customer/${reviewData.navigation.company.id}/details`)
      },
      label: reviewData.navigation.company.name
    },
    {
      onClick: () => {
        if (flow === FlowEnum.view) {
          navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/details`)
        } else {
          navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
        }
      },
      label: reviewData.navigation.mediaPlan.name
    },
    {
      label: `Campagne « ${reviewData.navigation.campaign.name} »`
    }
  ]

  const adCount = reviewData.ads.length

  const AddAd = () => {
    return (
      <Button type="tertiary" style={addButtonStyle} onClick={addAds.action}>
        <AddPicto width="10px" height="10px" />
        {addAds.label}
      </Button>
    )
  }

  return (
    <>
      <Breadcrumb items={items} />
      <ReviewPageTemplate
        name={reviewData.campaign.name}
        title={'Revue de la campagne'}
        type={StrategyEnum.CAMPAIGN}
        validation={validation}
        container={containerBlock}
        handleViewMore={handleViewMore}
        handleDelete={handleDeleteAd}
        handleEdit={handleEdit}
        handleSubEdit={handleEditAd}
        isEditor={true}
        footer={<AddAd />}
        format={reviewData.campaign.format}
        co2={reviewData.campaign.co2}
        co2Header={reviewData.campaign.platform === PlatformAPIEnum.GOOGLE}
        adCount={adCount}
        bottomMessage={bottomMessage}
        handleOpenModal={() => {
          setIsCO2ModalOpen(true)
        }}
      />
    </>
  )
}

export default EditorCampaignReview
