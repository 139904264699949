import { backendURL } from '../../../constants/urls'
import { ViewEnum } from '../../../types/axios/common'
import { CustomerNames, CustomersWithBudgets } from '../../../types/customer'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetCustomerNamesOutputType = CustomerNames[] | null
export type GetCustomersWithBudgetsOutputType = CustomersWithBudgets | null

export type GetCustomersInput = {
  view?: ViewEnum
  skip?: number
}

export const getCustomers = async ({
  view
}: GetCustomersInput): Promise<GetCustomerNamesOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/customers${view ? `?view=${view}` : ''}`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCustomerNames(response.data.customers)) {
        return response.data.customers
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfCustomerNames = (value: any): value is CustomerNames[] => {
  return (
    Array.isArray(value) &&
    value.every(
      (customer: any) =>
        customer &&
        customer.id &&
        typeof customer.id === 'string' &&
        customer.name &&
        typeof customer.name === 'string'
    )
  )
}

export const getCustomersWithBudgets = async ({
  skip
}: GetCustomersInput): Promise<GetCustomersWithBudgetsOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/customers?view=${ViewEnum.BUDGETS}${
        skip ? `&skip=${skip * 5}` : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCustomersWithBudgets(response.data, false)) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const checkIfCustomersWithBudgets = (
  value: any,
  withNav?: boolean
): value is CustomersWithBudgets => {
  return value &&
    value.customers &&
    Array.isArray(value.customers) &&
    value.customers.every(
      (customer: any) =>
        customer &&
        customer.id &&
        typeof customer.id === 'string' &&
        customer.name &&
        typeof customer.name === 'string' &&
        customer.spentBudget != null &&
        typeof customer.spentBudget === 'number' &&
        customer.expectedBudget != null &&
        typeof customer.expectedBudget === 'number' &&
        customer.realizedBenefits != null &&
        typeof customer.realizedBenefits === 'number' &&
        customer.expectedBenefits != null &&
        typeof customer.expectedBenefits === 'number'
    ) &&
    withNav
    ? value.navigation &&
        value.navigation.customersCount != null &&
        typeof value.navigation.customersCount === 'number' &&
        value.navigation.skip != null &&
        typeof value.navigation.skip === 'number'
    : true
}
