import { backendURL } from '../../../constants/urls'
import { DevicesEnum } from '../../../types/campaign/devices'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'

// TODO : manage error
export type UpdateCampaignOutputType = {
  campaignId: string
}

export type UpdateCampaignErrorType = {
  error: string
}

export type UpdateCampaignInput = {
  campaignId: string
  budget: number
  isBudgetPerDay: boolean
  startDate: Date
  endDate: Date
  devices: {
    [key in DevicesEnum]: boolean
  }
  audience: string
  newKeywords: string[]
  deletedKeywords: string[]
}

export const updateCampaign = async ({
  campaignId,
  budget,
  isBudgetPerDay,
  startDate,
  endDate,
  devices,
  audience,
  newKeywords,
  deletedKeywords
}: UpdateCampaignInput): Promise<
  UpdateCampaignOutputType | UpdateCampaignErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/campaigns/${campaignId}`,
      data: {
        budget,
        isBudgetPerDay,
        startDate,
        endDate,
        devices,
        audience,
        newKeywords,
        deletedKeywords
      }
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.campaignId) {
        return {
          campaignId: response.data.campaignId
        }
      }
    }

    return {
      error:
        'Une erreur inconnue est survenue durant la mise à jour de la campagne'
    }
  } catch (_) {
    return {
      error:
        'Une erreur inconnue est survenue durant la mise à jour de la campagne'
    }
  }
}

export const checkIfUpdateCampaign = (
  result: any
): result is UpdateCampaignOutputType => {
  return result.campaignId !== undefined
}
