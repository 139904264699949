import { backendURL } from '../../../constants/urls'
import { ViewEnum } from '../../../types/axios/common'
import { CampaignType } from '../../../types/campaign/campaign'
import { Status } from '../../../types/status'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

// TODO : handle NamedResource Output in case view = ViewEnum.NAME
export type GetCampaignsOutputType = {
  campaigns: CampaignType[]
  navigation: {
    campaignsCount: number
    skip: number
  }
}

type GetCampaignsInputType = {
  status?: Status
  view?: ViewEnum
  skip?: number
}

export const getCampaigns = async ({
  status,
  view,
  skip
}: GetCampaignsInputType): Promise<GetCampaignsOutputType | null> => {
  try {
    const url = `${backendURL}/campaigns${skip ? `?skip=${skip * 5}` : ''}${
      status ? (skip ? `&status=${status}` : `?status=${status}`) : ''
    }${view ? (skip || status ? `&view=${view}` : `?view=${view}`) : ''}`

    const response = await axiosGet({
      url
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfCampaigns(response.data, view)) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}

export const getCampaignsForHeader = async ({
  status,
  view,
  skip
}: GetCampaignsInputType): Promise<{ id: string; name: string }[] | null> => {
  const url = `${backendURL}/campaigns${skip ? `?skip=${skip * 5}` : ''}${
    status ? (skip ? `&status=${status}` : `?status=${status}`) : ''
  }${view ? (skip || status ? `&view=${view}` : `?view=${view}`) : ''}`
  try {
    const response = await axiosGet({ url })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (response.data && Array.isArray(response.data.campaigns)) {
        return response.data.campaigns.map((cp: CampaignType) => ({
          id: cp.id,
          name: cp.name
        }))
      }
    }
    return null
  } catch (error) {
    console.error('Error in getCampaignsForHeader:', error)
    return null
  }
}

export const checkIfCampaigns = (
  value: any,
  view?: ViewEnum
): value is GetCampaignsOutputType => {
  if (view === ViewEnum.NAME) {
    return (
      value &&
      Array.isArray(value.campaigns) &&
      value.campaigns.every((value: any) => {
        return (
          value &&
          value.id &&
          typeof value.id === 'string' &&
          value.name &&
          typeof value.name === 'string'
        )
      })
    )
  }

  return (
    value &&
    Array.isArray(value.campaigns) &&
    value.navigation &&
    typeof value.navigation.campaignsCount === 'number' &&
    typeof value.navigation.skip === 'number'
  )
}
