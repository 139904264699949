import React from 'react'

import { CheckboxListTmp } from '../../../../tmpComponents/CheckboxList'

export type OSProps = {
  isDesktopSelected: boolean
  isAndroidSelected: boolean
  isIOSSelected: boolean
  handleChangeIsDesktopSelected: () => void
  handleChangeIsAndroidSelected: () => void
  handleChangeIsIOSSelected: () => void
}

const OS = ({
  isDesktopSelected,
  isAndroidSelected,
  isIOSSelected,
  handleChangeIsDesktopSelected,
  handleChangeIsAndroidSelected,
  handleChangeIsIOSSelected
}: OSProps) => {
  const values = [
    {
      id: 'desktop',
      label: 'Desktop OS (Windows, Linux, MacOS, etc.)',
      selected: isDesktopSelected
    },
    {
      id: 'android',
      label: 'Android',
      selected: isAndroidSelected
    },
    {
      id: 'ios',
      label: 'iOS',
      selected: isIOSSelected
    }
  ]

  const handleChange = (id: string) => {
    switch (id) {
      case 'desktop':
        handleChangeIsDesktopSelected()
        break
      case 'android':
        handleChangeIsAndroidSelected()
        break
      case 'ios':
        handleChangeIsIOSSelected()
        break
    }
  }

  return <CheckboxListTmp values={values} onChange={handleChange} withBorder />
}

export default OS
