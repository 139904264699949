import styled from '@emotion/styled'

import Colors from '../../../../../constants/colors'
import Margin from '../../../../../constants/margin'

export const PopupMenuContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 5px #142a4c40;
  border-radius: 10px 0px;
  padding: 18px 0px 20px 0px;
  position: fixed;
  left: 210px;
  top: 74px;
  z-index: 100;
  display: ${(props: { isOpen?: boolean }) => (props.isOpen ? 'flex' : 'none')};
`

export const PopupContent = styled.div`
  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: 1px solid #dde0e5;
  }
`

export const PopupMenuContentTitle = styled.h5`
  text-align: center;
  font: bold 14px 'Montserrat';
  text-transform: uppercase;
  color: #142a4c;
  text-transform: uppercase;
  margin: 0 0 10px 0;
`

export const PopupMenuContentList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 190px;
  max-height: 200px;
  overflow-y: ${(props: { scrollable: boolean }) =>
    props.scrollable ? 'scroll' : 'hidden'};
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const PopupMenuContentListLi = styled.li`
  height: 40px;
  color: #142a4c;
  font: 600 12px 'Montserrat';
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  position: relative;
  gap: 15px;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? Colors.YETIC_GREY_DARK : ''};

  &:hover {
    background: ${Colors.YETIC_GREY_DARK} 0% 0% no-repeat padding-box;
    border-radius: 10px;
  }
`

export const PopupMenuContentListSpanLabel = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    overflow: visible;
    text-decoration: ${(props: { clickable: boolean }) =>
      props.clickable ? 'underline' : ''};
  }
`

export const PopupMenuContentListSpanArrow = styled.span`
  width: 30px;
  height: 40px;
  margin-right: -10px;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const PopupMenuContentBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-top: 10px;
  border-top: 1px solid #dde0e5;
  padding-top: 15px;

  a {
    text-decoration: none;
    color: #142a4c;
    font: 12px 'Montserrat';
    font-weight: bold;
  }
`

export const Separator = styled.div`
  margin-top: 16px;
  height: 1px;
  width: 100%;
  background-color: black;
  opacity: 0.2;
`

export const SeeAll = styled.div`
  cursor: ${(props: { route: string | null }) =>
    props.route ? 'pointer' : 'not-allowed'};
  color: #142a4c;
  font: 600 12px 'Montserrat';
  margin: auto;
  margin-top: 12px;

  &:hover {
    text-decoration: ${(props: { route: string | null }) =>
      props.route ? 'underline' : 'none'};
  }
`

export const NoResultContainer = styled.div`
  width: 190px;
  margin-top: ${Margin.m7};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
