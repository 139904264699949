import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'

import { BarChartContainer } from './styles'
import BarChartTooltip from './Tooltip'
import BarChartHeaderSelection from './HeaderSelection'
import { drawHorizontalChart } from './helpers/drawHorizontalChart'
import { drawVerticalChart } from './helpers/drawVerticalChart'
import DefaultText from '../../DefaultText'
import { ChartTitle } from '../styles'

export type Point = {
  category: string
  value: number
}

export type BarChartData<ValueType> = {
  name: string
  color: string
  values: ValueType[]
  unit?: string
  horizontal?: boolean
}

export type FormattedPoint = {
  datasetIndex: number
  index: number
  category: string
  value: number
}

type BarChartProps = {
  title?: string
  data: BarChartData<Point>[]
  horizontal?: boolean
  width?: number
  style?: CSSProperties
}

const BarChart = ({
  title,
  data,
  horizontal = false,
  width = 1000,
  style
}: BarChartProps) => {
  const [selectedDataset, setSelectedDataset] = useState(0)

  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (horizontal || data[selectedDataset].horizontal) {
      drawHorizontalChart({
        chartId,
        chartRef,
        data: formattedData,
        width
      })
    } else {
      drawVerticalChart({
        chartId,
        chartRef,
        data: formattedData,
        width
      })
    }
  }, [data, selectedDataset])

  const handleDatasetClick = (datasetIndex: number) => {
    if (datasetIndex === selectedDataset) {
      return
    }

    setSelectedDataset(datasetIndex)
  }

  const chartId = `id${Math.random().toString(36).substring(2)}`

  const datasetData = data[selectedDataset]

  const formattedData = {
    ...datasetData,
    values: datasetData.values.map((value, index) => ({
      ...value,
      datasetIndex: selectedDataset,
      index
    }))
  }

  if (data.length === 0) {
    return null
  }

  return (
    <BarChartContainer ref={chartRef} width={width} style={style}>
      {title && (
        <ChartTitle>
          <DefaultText size="big" bold>
            {title}
          </DefaultText>
        </ChartTitle>
      )}
      {data.length > 1 && (
        <BarChartHeaderSelection
          data={data}
          selectedDataset={selectedDataset}
          handleDatasetClick={handleDatasetClick}
        />
      )}
      <BarChartTooltip chartId={chartId} data={formattedData} />
    </BarChartContainer>
  )
}

export default BarChart
