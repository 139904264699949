export enum CampaignCreationError {
  START_DATE = `Veuillez choisir une date de début de la campagne`,
  END_DATE = `Veuillez choisir une date de fin de campagne valide`,
  AMOUNT_BUDGET_MIN = `Le montant du budget doit être supérieur à 0`,
  AMOUNT_BUDGET_MAX = `Le budget dépasse le montant disponible pour ce plan média. Veuillez ne pas dépasser`,
  NAME = `Le nom doit faire au moins 2 caractères`,
  GOALS = `Vous devez sélectionner au moins un objectif`,
  AUDIENCE = `Vous devez sélectionner une audience`,
  KEYWORDS = `Vous devez définir au moins un mot-clé`,
  KEYWORDS_MAX = `Vous ne pouvez pas définir plus de 15 mots-clés`
}
