import styled from '@emotion/styled'

export const WidgetWrapper = styled.div<{ right?: string }>`
  position: fixed;
  bottom: 20px;
  right: ${(props) => props.right ?? ''};
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  z-index: 9999;
`
