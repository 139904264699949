import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../../../constants/margin'
import Colors from '../../../../../constants/colors'
import FontWeight from '../../../../../constants/fontWeight'
import FontSize from '../../../../../constants/fontSize'

export const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const DivBudget = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

export const BudgetChoicesDescription = styled.div`
  color: ${Colors.YETIC_BLUE_DARK};
  opacity: 0.5;
  text-align: right;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  font-weight: ${FontWeight.YETIC_MEDIUM};
  margin: ${Margin.m3} 0;
`
export const DivBudgetChoices = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BudgetChoice = styled.div`
  padding: 6px 0;
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  font-weight: ${FontWeight.YETIC_MEDIUM};
  width: 20%;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 2px ${Colors.YETIC_BLUE_DARK};
  cursor: pointer;
  background-color: ${Colors.YETIC_WHITE};
  &:hover {
    background-color: ${Colors.NEW_YETIC_GREY_LIGHT};
  }
`

export const textStyle: CSSProperties = {
  marginTop: Margin.m7
}
