import React from 'react'

import Modal from '../../../../components/Modal'
import { AudienceLocation } from '../../../../helpers/queries/campaign/getCampaignCreationData'
import DefaultText from '../../../../components/DefaultText'
import PanelTitle from '../../../../templates/components/Panel/PanelTitle'
import { ModalContent } from './styles'

type LocationModalProps = {
  locations?: AudienceLocation[]
  onClose: () => void
}

const LocationModal = ({ locations, onClose }: LocationModalProps) => {
  return (
    <Modal onClose={onClose}>
      <PanelTitle style={{ justifyContent: 'center' }}>
        Localisations
      </PanelTitle>
      <ModalContent>
        {locations ? (
          locations.map((location) => (
            <DefaultText
              key={location.name}
              style={
                locations.length === 1 ? { justifySelf: 'center' } : undefined
              }
            >
              {locations.length > 1 && '- '}
              {location.canonicalName.replace(/,/g, ', ')}
            </DefaultText>
          ))
        ) : (
          <DefaultText>
            Veuillez sélectionner une audience pour afficher les localisations
            qui lui sont associées.
          </DefaultText>
        )}
      </ModalContent>
    </Modal>
  )
}

export default LocationModal
