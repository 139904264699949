import React from 'react'

import DisplayAdInfoTemplate from '../../../../../templates/DisplayAdTemplate/DisplayAdInfo'
import {
  AdButton,
  AdContainer,
  AdDescription,
  AdImage,
  AdTitle
} from './styles'
import DisplayAdWebpage from '../../../../../templates/DisplayAdTemplate/DisplayAdWebPage'

type WebpagePreview3Props = {
  title: string
  description: string
  logoImage: string
  buttonColor: string
  adImageSquare: string
}

const WebpagePreview3 = ({
  title,
  description,
  logoImage,
  buttonColor,
  adImageSquare
}: WebpagePreview3Props) => {
  return (
    <DisplayAdWebpage>
      <DisplayAdInfoTemplate
        width={'300px'}
        height={'255px'}
        bgBlur={adImageSquare}
      >
        <AdContainer>
          <AdImage src={logoImage} alt="logo" loading="lazy" />
          <AdTitle>{title}</AdTitle>
          <AdDescription>{description}</AdDescription>
          <AdButton buttonColor={buttonColor}>Ouvrir</AdButton>
        </AdContainer>
      </DisplayAdInfoTemplate>
    </DisplayAdWebpage>
  )
}
export default WebpagePreview3
