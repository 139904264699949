import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'
import Padding from '../../../../../../constants/padding'
import Margin from '../../../../../../constants/margin'

export const WebsiteWindow = styled.div`
  width: 400px;
  background-color: ${Colors.YETIC_WHITE};
`
export const TabBar = styled.div`
  background-color: #dadce0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  position: relative;
  padding: 10px 15px;
  box-sizing: border-box;
`
export const Tab = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 60px;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  background-color: #f0f3f4;
  height: 15px;
  width: 110px;
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
`

export const TabFavicon = styled.div`
  background-color: #bdc1c5;
  height: 8px;
  width: 8px;
`

export const TabText = styled.div`
  background-color: #dadce0;
  height: 5px;
  width: 70%;
  margin-left: 8px;
`

export const Circle = styled.div`
  background-color: white;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  margin: 5px;
`
export const NavBar = styled.div`
  background-color: #f0f3f4;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`

export const NavBarSearchBar = styled.div`
  box-sizing: border-box;
  background-color: white;
  height: 15px;
  width: 75%;
  border: solid 1px #bdc1c5;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-right: 10px;
`

export const OptionCircle = styled.div`
  background-color: #5f6367;
  width: 3px;
  height: 3px;
  border-radius: 50px;
  margin: 2px;
`

export const Website = styled.div`
  padding: 10px 20px;
`

export const WebsiteTitle = styled.div`
  color: #5f6367;
  font-style: italic;
  font-size: 3rem;
  font-family: serif;
  text-align: center;
  margin-bottom: 10px;
`

export const WebsiteNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const Word = styled.div`
  background-color: #dadce0;
  height: 8px;
  width: 30px;
`

export const AdPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WebsiteContent = styled.div`
  flex: 1;
  margin-right: 20px;
`

export const WebsiteImage = styled.div`
  background-color: #e9ebec;
  height: 113px;
  width: 100%;
`
export const Paragraph = styled.div`
  margin-bottom: 15px;
`

export const Sentence = styled.div`
  background-color: #e9ebec;
  height: 8px;
  margin-bottom: 8px;
`
export const SemiSentence = styled.div`
  background-color: #e9ebec;
  height: 8px;
  margin-bottom: 8px;
  width: 80%;
`

export const AdImg = styled.img`
  height: 150px;
  object-fit: cover;
`

export const AdTitle = styled.h3`
  color: ${Colors.YETIC_BLUE_DARK};
  font-size: ${FontSize.YETIC_TITLE};
  text-align: center;
`

export const AdDescription = styled.div`
  text-align: center;
`

export const AdLink = styled.a`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.YETIC_RED};
  border-radius: 50px;
`

export const DivContainer = styled.div`
  text-align: center;
`

export const ImageTop = styled.img`
  width: 100%;
  height: 63px;
`

export const DivContent = styled.div`
  margin: auto;
  padding: 0px ${Padding.p3};
  background-color: ${Colors.YETIC_WHITE};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 300px;
  justify-content: space-around;
  align-items: center;
`

export const Logo = styled.img`
  width: 45px;
  height: 45px;
`

export const Title = styled.h1`
  font-size: ${FontSize.YETIC_TITLE_H2};
`

export const DivButton = styled.div`
  margin-bottom: ${Margin.m6};
`

export const Description = styled.div`
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  color: ${Colors.YETIC_GREY_AD};
  opacity: 0.9;
  margin: ${Margin.m6} 0px;
  line-height: 1.5em;
`

export const ButtonArrow = styled.button`
  background-color: rgba(39, 39, 37, 1);
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.4);
`
