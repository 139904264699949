import { FormatAPIEnum } from '../../../types/format'
import { ReportingAPISectionEnum } from '../../../types/reporting/apiSection'
import {
  ReportingTabItem,
  ReportingTabKeyEnum,
  getReportingTabKeyEnumTranslation
} from '../../../types/reporting/tabs'

export const getReportingTabs = (format: FormatAPIEnum): ReportingTabItem[] => {
  return [
    {
      key: ReportingTabKeyEnum.impact,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.impact),
      fetch: ReportingAPISectionEnum.impact
    },
    {
      key: ReportingTabKeyEnum.overall,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.overall),
      fetch: ReportingAPISectionEnum.overall
    },
    // {
    //   key: ReportingTabKeyEnum.recommendations,
    //   name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.recommendations)
    // },
    {
      key: ReportingTabKeyEnum.clickImpressionCost,
      name: getReportingTabKeyEnumTranslation(
        ReportingTabKeyEnum.clickImpressionCost
      ),
      fetch: ReportingAPISectionEnum.overall
    },
    {
      key: ReportingTabKeyEnum.devices,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.devices),
      fetch: ReportingAPISectionEnum.devices
    },
    {
      key: ReportingTabKeyEnum.audiences,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.audiences),
      fetch: ReportingAPISectionEnum.audiences
    },
    ...(format === FormatAPIEnum.TEXT
      ? [
          {
            key: ReportingTabKeyEnum.keywords,
            name: getReportingTabKeyEnumTranslation(
              ReportingTabKeyEnum.keywords
            ),
            fetch: ReportingAPISectionEnum.keywords
          }
        ]
      : []),
    {
      key: ReportingTabKeyEnum.ads,
      name: getReportingTabKeyEnumTranslation(ReportingTabKeyEnum.ads),
      fetch: ReportingAPISectionEnum.ads
    }
  ]
}
