import React from 'react'

import {
  HeaderSelectionContainer,
  SelectionItem,
  SelectionItemName,
  datasetNameStyle
} from './styles'
import DefaultText from '../../../DefaultText'
import { BarChartData, Point } from '..'

type BarChartHeaderSelectionProps = {
  handleDatasetClick: (datasetIndex: number) => void
  data: BarChartData<Point>[]
  selectedDataset: number
}

const BarChartHeaderSelection = ({
  handleDatasetClick,
  data,
  selectedDataset
}: BarChartHeaderSelectionProps) => {
  return (
    <HeaderSelectionContainer>
      {data.map((dataset, index) => {
        return (
          <SelectionItem
            key={index}
            isSelected={index === selectedDataset}
            color={dataset.color}
            onClick={() => {
              handleDatasetClick(index)
            }}
          >
            <SelectionItemName>
              <DefaultText style={datasetNameStyle} size="big">
                {dataset.name}
              </DefaultText>
            </SelectionItemName>
          </SelectionItem>
        )
      })}
    </HeaderSelectionContainer>
  )
}

export default BarChartHeaderSelection
