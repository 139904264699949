import styled from '@emotion/styled'
import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'
import FontSize from '../../../constants/fontSize'
import Padding from '../../../constants/padding'

export const TagSpan = styled.span`
  background-color: ${Colors.YETIC_BLUE_DARK};
  color: white;
  border-radius: 10px;
  padding: ${Padding.p2} ${Padding.p4};
  min-height: ${Margin.m6};
  margin-right: ${Margin.m2};
  display: flex;
  align-items: center;
  gap: ${Margin.m2};
  white-space: nowrap;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
`

export const LabelSpan = styled.span<{ hasAction: boolean }>`
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'default')};
  &:hover {
    text-decoration: ${({ hasAction }) => (hasAction ? 'underline' : 'none')};
  }
`

export const CloseIcon = styled.span`
  cursor: pointer;
  margin-left: ${Margin.m2};
  font-size: ${FontSize.YETIC_TEXT_MEDIUM};
  margin-bottom: 2px;
  color: white;
`
