import React, { useEffect, useRef, useState } from 'react'

import Input from '../../Input'
import { Tags } from '../../Tag'
import { DivContainer, InputContainer, Item, Label } from './styles'

type MultipleSelectionInputProps = {
  data: string[]
  selectedTags: string[]
  error?: string
  removeItem: (index: number) => void
  addNewItem: (item: string) => void
  label: string
  placeholder: string
  isRequired?: boolean
}

const replaceSpecialCharacters = (string: string) =>
  string.replace(/['-_ /;,\s]+/g, ' ')

const MultipleSelectionInput = ({
  data,
  selectedTags,
  error,
  removeItem,
  addNewItem,
  label,
  placeholder,
  isRequired = false
}: MultipleSelectionInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [showList, setShowList] = useState(true)

  useEffect(() => {
    if (inputValue !== '') {
      setInputValue('')
    }
  }, [selectedTags])

  const inputContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        inputContainerRef.current &&
        !inputContainerRef.current.contains(event.target as Node)
      ) {
        setShowList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setShowList(true)
  }

  const filteredData = data.filter((item) => {
    const itemString = replaceSpecialCharacters(item.toLowerCase())
    const inputRegex = new RegExp(
      replaceSpecialCharacters(inputValue.toLowerCase())
    )
    return inputRegex.test(itemString) && !selectedTags.includes(item)
  })

  // Sort the displayed data so that the ones where the inputValue is at the beginning are displayed first
  filteredData.sort((a, b) => {
    const aItemString = replaceSpecialCharacters(a.toLowerCase())
    const bItemString = replaceSpecialCharacters(b.toLowerCase())
    const inputRegex = new RegExp(
      `^${replaceSpecialCharacters(inputValue.toLowerCase())}`
    )

    const aMatchesInput = inputRegex.test(aItemString)
    const bMatchesInput = inputRegex.test(bItemString)

    return aMatchesInput && !bMatchesInput
      ? -1
      : !aMatchesInput && bMatchesInput
      ? 1
      : a.localeCompare(b)
  })

  return (
    <DivContainer>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        label={label}
        error={error}
        isRequired={isRequired}
        withBorder
      />

      {showList && inputValue && (
        <InputContainer ref={inputContainerRef}>
          {filteredData.map((item, index) => (
            <Item
              key={index}
              onClick={() => {
                addNewItem(item)
                setShowList(false)
              }}
            >
              <Label>{item.replaceAll(',', ', ')}</Label>
            </Item>
          ))}
        </InputContainer>
      )}

      {selectedTags.length > 0 && (
        <Tags labels={selectedTags} removeElements={removeItem} />
      )}
    </DivContainer>
  )
}

export default MultipleSelectionInput
