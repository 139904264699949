import { Point } from '../../../LineChartDrawer'
import { formatDateToFrench } from '../../../../../../helpers/date'
import { formatNumber } from '../../../../../../helpers/rounds'
import insertTextFromClassName from '../../../helpers/drawChart/insertTextFromClassName'
import {
  InputTooltipRenderer,
  TooltipRenderer
} from '../../../../../../types/lineChart'

export const renderDefaultTooltip: TooltipRenderer<Point> = ({
  pointsDataToDisplay,
  fullData,
  tooltip,
  time,
  endTime,
  chartId
}: InputTooltipRenderer<Point>) => {
  tooltip
    .selectAll(`#${chartId}-tooltip-title`)
    .text(
      `${formatDateToFrench(time)}${
        endTime ? ` - ${formatDateToFrench(endTime)}` : ''
      }`
    )

  for (let i = 0; i < pointsDataToDisplay.length; i++) {
    const point = pointsDataToDisplay[i]
    const dataset = fullData[point.datasetIndex]
    const className = `.${chartId}-tooltip-${dataset.name}-value`
    const text = `${formatNumber(point.pointData.value)}${dataset.unit ?? ''}`
    insertTextFromClassName(tooltip, className, text)
  }
}
