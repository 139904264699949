import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const ChartTitle = styled.div`
  margin-bottom: ${Margin.m4};
  text-align: center;
  padding: 0 ${Padding.p6};
  box-sizing: border-box;
`
