import React, { ChangeEvent } from 'react'

import {
  InputListDiv,
  inputContainerStyle,
  inputStyle,
  modalStyle,
  messageStyle
} from './styles'
import { InputError } from '../../../../../components/Input/Styles'
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal'
import Input from '../../../../../components/Input'
import { ExistingAudiences } from '../../../'
import DefaultText from '../../../../../components/DefaultText'

type RenamingSelectedAudiencesModalProps = {
  newNames: Record<string, string>[]
  audiencesData: ExistingAudiences
  onClose: () => void
  handleNameChange: (
    event: ChangeEvent<HTMLInputElement>,
    audienceId: string
  ) => void
  handleSubmit: () => void
  error: string
}

const RenamingSelectedAudiencesModal = ({
  newNames,
  audiencesData,
  onClose,
  handleNameChange,
  handleSubmit,
  error
}: RenamingSelectedAudiencesModalProps) => {
  return (
    <ConfirmationModal
      title="Renommer la sélection"
      onClose={onClose}
      onClickReject={onClose}
      onClickConfirm={handleSubmit}
      textConfirm="Importer"
      style={modalStyle}
    >
      <DefaultText size="small" style={messageStyle}>
        {`Renommer les audiences sélectionnées afin d'assurer leur unicité et de pouvoir les identifier facilement.`}
      </DefaultText>
      <InputListDiv>
        {newNames.map((newName) => (
          <Input
            key={newName[0]}
            type="text"
            label={
              audiencesData.find(
                (audience) => audience.id === Object.keys(newName)[0]
              )?.name ?? ''
            }
            isRequired={false}
            value={Object.values(newName)[0]}
            onChange={(event) => {
              handleNameChange(event, Object.keys(newName)[0])
            }}
            style={inputStyle}
            containerStyle={inputContainerStyle}
          />
        ))}
      </InputListDiv>
      {error && <InputError style={messageStyle}>{error}</InputError>}
    </ConfirmationModal>
  )
}

export default RenamingSelectedAudiencesModal
