import React from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '../../../components/Modal'
import CampaignPlatformChoice from '../CampaignPlatformChoice'
import { ChoiceContainer, modalStyle, textStyle } from './styles'
import Title from '../../../components/Title'
import { PlatformAPIEnum } from '../../../types/platform'
import DefaultText from '../../../components/DefaultText'
import { FlowEnum } from '../../../types/flow'

type CampaignCreationPlatformModalProps = {
  mediaPlanId: string
  onClose: () => void
  flow?: FlowEnum
}

const CampaignCreationPlatformModal = ({
  mediaPlanId,
  onClose,
  flow
}: CampaignCreationPlatformModalProps) => {
  const navigate = useNavigate()

  const handleOnPlatformClick = (platform: PlatformAPIEnum) => {
    navigate(
      `/media-plan/${mediaPlanId}/campaign/create?platform=${platform}${
        flow ? `&flow=${flow}` : ''
      }`
    )
    onClose()
  }

  return (
    <Modal onClose={onClose} style={modalStyle}>
      <Title>Choix de la plateforme</Title>
      <DefaultText size="medium" style={textStyle}>
        Veuillez sélectionner la plateforme sur laquelle vous souhaitez que la
        campagne soit publiée.
      </DefaultText>
      <ChoiceContainer>
        <CampaignPlatformChoice onClickPlatform={handleOnPlatformClick} />
      </ChoiceContainer>
    </Modal>
  )
}

export default CampaignCreationPlatformModal
