import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'
import Margin, { MarginButtonEnum } from '../../../constants/margin'
import FontSize from '../../../constants/fontSize'

export const ReviewPageContainer = styled.div`
  margin: 0 auto;
  width: 80%;
  max-width: 900px;
  margin-bottom: ${Margin.m8};
`

export const DivSummary = styled.div`
  border: 1px solid ${Colors.YETIC_BLUE_DARK};
  border-radius: 10px;
  padding: ${Padding.p6};
`

export const DivSummaryTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

export const DivError = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${Margin.m6};
`

export const Edit = styled.div`
  font-size: ${FontSize.YETIC_TEXT};
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 0;
  text-align: right;
  padding-top: ${Padding.p5};
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Margin.m6};
  margin-top: ${MarginButtonEnum.marginTop};
`

export const DivTipsInfo = styled.div`
  margin-top: ${Margin.m5};
`

export const titleStyle: CSSProperties = {
  textAlign: 'center',
  marginTop: Margin.m8,
  marginBottom: Margin.m6
}

export const DivCO2 = styled.div<{ co2Header?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Padding.p6};
  background-color: ${Colors.NEW_YETIC_GREEN_LIGHT};
  border-radius: 8px;
  margin-top: ${({ co2Header }) => (co2Header ? '-20px' : '0')};
  margin-left: ${({ co2Header }) => (co2Header ? '-20px' : '0')};
  margin-right: ${({ co2Header }) => (co2Header ? '-20px' : '0')};
`

export const DivCO2Value = styled.div`
  display: flex;
  font-size: ${FontSize.YETIC_TITLE_H2};
  font-weight: 700;
  margin-right: ${Margin.m2};
  margin-left: ${Margin.m2};
`

export const DivCO2Text = styled.div`
  display: flex;
  font-size: ${FontSize.YETIC_TEXT};
  font-weight: 500;
  font-family: 'Montserrat';
  align-items: flex-end;
`

export const DivCO2OpenModalText = styled.div`
  display: flex;
  font-size: ${FontSize.YETIC_TEXT};
  font-weight: 500;
  font-family: 'Montserrat';
  align-items: flex-end;
  cursor: pointer;
  text-decoration: underline;
`
