import { backendURL } from '../../../../../constants/urls'
import { GetMediaPlanHighlightReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../../axios/get'

type GetMediaPlanReportingInputType = {
  mediaPlanId: string
  startDate?: string
  endDate?: string
}

export const getMediaPlanHighlightReporting = async ({
  mediaPlanId,
  startDate,
  endDate
}: GetMediaPlanReportingInputType): Promise<GetMediaPlanHighlightReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/media-plan/${mediaPlanId}/sections/highlight${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetMediaPlanHighlightReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetMediaPlanHighlightReporting = (
  val: any
): val is GetMediaPlanHighlightReportingOutputType => {
  return (
    val &&
    val.clicks !== undefined &&
    typeof val.clicks === 'number' &&
    val.impressions !== undefined &&
    typeof val.impressions === 'number' &&
    val.co2 !== undefined &&
    typeof val.co2 === 'number' &&
    val.cost !== undefined &&
    typeof val.cost === 'number' &&
    val.impact !== undefined &&
    typeof val.impact === 'number'
  )
}
