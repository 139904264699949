import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PreviewContainer, TextInputsContainer } from './styles'
import { AppContext } from '../../../../contexts/AppContext'
import AuthenticatedTemplate from '../../../../templates/AuthenticatedTemplate'
import LoadingPage from '../../../LoadingPage'
import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import { PanelType } from '../../../../types/templates/panels'
import Input from '../../../../components/Input'
import ErrorPage from '../../../ErrorPage'
import Breadcrumb from '../../../../components/BreadCrumb'
import { FormatAPIEnum } from '../../../../types/format'
import {
  checkIfGetAdOutputLinkedinImageAdDetails,
  getAdById
} from '../../../../helpers/queries/ad/getAdById'
import { LinkedinImageAdDetails } from '../../../../types/ad/linkedin/imageAd'
import LinkedinSmartphonePreview from '../../../../components/PreviewAds/Google/Display/LinkedinSmartphonePreview'
import getImageById from '../../../../helpers/queries/ad/getImageById'
import { convertBase64ToUrl } from '../../../../helpers/convertBase64ToUrl'
import { PlatformAPIEnum } from '../../../../types/platform'
import { AdNavigation } from '../../../../types/ad/ad'

const LinkedinImageAdReview = () => {
  const [linkedinImageAd, setLinkedinImageAd] =
    useState<LinkedinImageAdDetails | null>(null)
  const [navigation, setNavigation] = useState<AdNavigation | null>(null)
  const [image, setImage] = useState('')
  const [hasFetchError, setHasFetchError] = useState(false)

  const { adId } = useParams()

  const navigate = useNavigate()

  const { isEditor, user } = useContext(AppContext)

  useEffect(() => {
    if (adId) {
      ;(async function () {
        const data = await getAdById({
          adId,
          adType: FormatAPIEnum.IMAGE,
          platform: PlatformAPIEnum.LINKEDIN
        })
        if (data && checkIfGetAdOutputLinkedinImageAdDetails(data)) {
          let imageUrl = ''

          const imageResult = await getImageById(data.ad.imageId)

          if (imageResult) {
            const imageUrlResult = convertBase64ToUrl(imageResult)
            imageUrl = imageUrlResult
          }

          setLinkedinImageAd(data.ad)
          setNavigation(data.navigation)
          setImage(imageUrl)
        } else {
          console.error(
            `Something went wrong during request to get ads/${adId}`
          )
          setHasFetchError(true)
        }
      })()
    } else {
      setHasFetchError(true)
    }

    window.scrollTo(0, 0)
  }, [adId])

  if (hasFetchError) {
    return <ErrorPage />
  }

  if (!adId || !linkedinImageAd || !image || !navigation) {
    return <LoadingPage />
  }

  const breadcrumbItems = [
    {
      label: navigation.company.name
    },
    {
      onClick: () => {
        navigate(`/media-plan/${navigation.mediaPlan.id}/review`)
      },
      label: navigation.mediaPlan.name
    },
    {
      onClick: () => {
        navigate(`/campaign/${navigation.campaign.id}/review`)
      },
      label: `Campagne « ${navigation.campaign.name} »`
    },
    { label: `Annonce « ${linkedinImageAd.name} »` }
  ]

  const namePanel: PanelType = {
    title: 'Nom',
    disabled: true,
    isDropdown: false,
    isShown: true,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="text"
              value={linkedinImageAd.name}
              isRequired={true}
              placeholder="Nom de mon annonce"
              maxLength={20}
              showCharacterCount
              label={`Nom de l'annonce`}
              disabled
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }

  const previewPanel: PanelType = {
    title: 'Aperçu de votre annonce',
    disabled: false,
    isDropdown: false,
    sections: [
      {
        content: (
          <PreviewContainer>
            <LinkedinSmartphonePreview
              companyName={navigation.company.name}
              content={linkedinImageAd.introText}
              image={image}
              headline={linkedinImageAd.headline}
              url={linkedinImageAd.url}
              cta={linkedinImageAd.cta}
            />
          </PreviewContainer>
        )
      }
    ]
  }

  const introTextPanel: PanelType = {
    title: "Texte d'introduction",
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <Input
            type="textarea"
            placeholder="Texte d'introduction"
            label={`Texte d'introduction`}
            value={linkedinImageAd.introText}
            maxLength={600}
            showCharacterCount
            disabled
            style={{ resize: 'vertical' }}
            withBorder
          />
        )
      }
    ]
  }

  const headlinePanel: PanelType = {
    title: 'Titre',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <Input
            type="text"
            label={'Titre'}
            placeholder="Titre"
            maxLength={200}
            showCharacterCount
            value={linkedinImageAd.headline}
            disabled
            withBorder
          />
        )
      }
    ]
  }

  const urlPanel: PanelType = {
    title: 'URL',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <Input
            type="text"
            placeholder="https://example.com"
            isRequired={true}
            label={'URL de destination'}
            value={linkedinImageAd.url}
            disabled
            withBorder
          />
        )
      }
    ]
  }

  const ctaPanel: PanelType = {
    title: 'Intitulé du bouton',
    disabled: true,
    isDropdown: false,
    sections: [
      {
        content: (
          <Input
            type="text"
            placeholder="Intitulé du bouton"
            label={'Intitulé du bouton'}
            value={linkedinImageAd.cta}
            disabled
            withBorder
          />
        )
      }
    ]
  }

  const panels: (JSX.Element | PanelType)[] = [
    namePanel,
    previewPanel,
    introTextPanel,
    headlinePanel,
    urlPanel,
    ctaPanel
  ]

  return (
    <AuthenticatedTemplate
      user={user}
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Revue de l'annonce « ${linkedinImageAd.name} »`}
        panels={panels}
        validation={{
          wording: 'Retour à la campagne',
          action: () => {
            navigate(`/campaign/${navigation.campaign.id}/review`)
          },
          disabled: false
        }}
      />
    </AuthenticatedTemplate>
  )
}

export default LinkedinImageAdReview
