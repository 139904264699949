import React from 'react'
import { useNavigate } from 'react-router-dom'

import Breadcrumb from '../../../../components/BreadCrumb'
import { CreationPageTemplate } from '../../../../templates/CreationPageTemplate'
import { TextAdReviewType } from './types'

const SelfEditorTextAdReview = ({
  textAd,
  adPreview,
  panels
}: TextAdReviewType) => {
  const navigate = useNavigate()

  const breadcrumbItems = [
    {
      label: 'Plan publicitaire'
    },
    {
      label: textAd ? textAd.navigation.mediaPlan.name : '',
      onClick: () => {
        navigate(
          `/media-plan/${textAd ? textAd.navigation.mediaPlan.id : ''}/review`
        )
      }
    },
    {
      label: `Campagne « ${textAd ? textAd.navigation.campaign.name : ''} »`,
      onClick: () => {
        navigate(
          `/campaign/${textAd ? textAd.navigation.campaign.id : ''}/review`
        )
      }
    },
    {
      label: `Publicité « ${textAd ? textAd.name : ''} »`
    }
  ]

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Revue de la publicité « ${textAd ? textAd.name : ''} »`}
        preview={adPreview}
        panels={panels}
        validation={{
          wording: 'Retour à la campagne',
          action: () => {
            navigate(
              `/campaign/${textAd ? textAd.navigation.campaign.id : ''}/review`
            )
          },
          disabled: false
        }}
      />
    </>
  )
}

export default SelfEditorTextAdReview
