import styled from '@emotion/styled'
import Margin from '../../../constants/margin'

export const DetailsPageTemplateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto ${Margin.m10};
  width: 80%;
  max-width: 1000px;
`

export const Page = styled.div`
  width: 100%;
`
