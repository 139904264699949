import { PlatformAPIEnum, checkIfPlatformApiEnum } from '../../platform'
import { FormatAPIEnum, checkIfFormatApiEnum } from '../../format'
import { GoalsEnum, checkIfGoalsEnum } from '../../goals'
import { MediaPlanAudience } from '../../mediaPlanAudience'
import { checkIfDate } from '../../../helpers/nativeTypes'
import {
  BidStrategyAPIEnum,
  checkIfBidStrategyApiEnum
} from '../../bidStrategy'
import { LangAPIEnum, checkIfLangApiEnum } from '../../lang'
import { Status, checkIfStatus } from '../../status'
import { FullTextAdType, checkIfFullTextAd } from '../../ad/google/textAd'
import {
  FullGoogleImageAdType,
  checkIfFullGoogleImageAd
} from '../../ad/google/imageAd'

export type TextCampaignType = Omit<GoogleCampaignType, 'ads'> & {
  ads?: FullTextAdType[]
}

export type ImageCampaignType = Omit<GoogleCampaignType, 'ads'> & {
  ads?: FullGoogleImageAdType[]
}

export type GoogleCampaignType = {
  id: string
  name: string
  platform: PlatformAPIEnum
  format: FormatAPIEnum
  status: Status
  goal: GoalsEnum
  period: {
    start: string
    end: string
  }
  budget: number
  spentBudget: number
  feedback?: string
  isBudgetPerDay: boolean
  audiences: MediaPlanAudience[]
  lang: LangAPIEnum
  os: {
    desktop: boolean
    android: boolean
    ios: boolean
  }
  devices: {
    desktop: boolean
    smartphone: boolean
    tablet: boolean
    tv: boolean
  }
  bidStrategy: BidStrategyAPIEnum
  mediaPlanId: string
  userId: string
  createdAt: string | number
  updatedAt: string | number
  keywordsCount?: number
  ads?: (FullTextAdType | FullGoogleImageAdType)[]
  co2: number
  consumedCo2: number
  isPlatformSynced: boolean
  customerName?: string
  keywords?: string[]
}

export const checkIfGoogleCampaign = (
  value: any
): value is GoogleCampaignType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.platform &&
    checkIfPlatformApiEnum(value.platform) &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    checkIfStatus(value.status) &&
    value.goal &&
    checkIfGoalsEnum(value.goal) &&
    value.period &&
    value.period.start &&
    checkIfDate(value.period.start) &&
    value.period.end &&
    checkIfDate(value.period.end) &&
    value.budget !== undefined &&
    typeof value.budget === 'number' &&
    value.spentBudget !== undefined &&
    typeof value.spentBudget === 'number' &&
    value.lang &&
    value.devices &&
    value.devices.desktop !== undefined &&
    value.devices.smartphone !== undefined &&
    value.devices.tablet !== undefined &&
    value.devices.tv !== undefined &&
    value.co2 !== undefined &&
    typeof value.co2 === 'number' &&
    value.consumedCo2 !== undefined &&
    typeof value.consumedCo2 === 'number' &&
    checkIfLangApiEnum(value.lang) &&
    value.bidStrategy &&
    checkIfBidStrategyApiEnum(value.bidStrategy) &&
    value.mediaPlanId &&
    typeof value.mediaPlanId === 'string' &&
    value.userId &&
    typeof value.userId === 'string' &&
    value.isPlatformSynced !== undefined &&
    value.createdAt &&
    (typeof value.createdAt === 'string' ||
      typeof value.createdAt === 'number') &&
    value.updatedAt &&
    (typeof value.updatedAt === 'string' ||
      typeof value.updatedAt === 'number') &&
    (value.keywordsCount === undefined ||
      (value.keywordsCount !== undefined &&
        typeof value.keywordsCount === 'number'))
  )
}

export const checkIfGoogleCampaignArray = (
  data: any
): data is GoogleCampaignType[] => {
  return (
    Array.isArray(data) &&
    data.every((campaign: any) => checkIfGoogleCampaign(campaign))
  )
}

export const checkIfTextGoogleCampaign = (
  value: any
): value is TextCampaignType => {
  return (
    checkIfGoogleCampaign(value) &&
    (value.ads === undefined ||
      (Array.isArray(value.ads) &&
        value.ads.every((ad: any) => checkIfFullTextAd(ad))))
  )
}

export const checkIfImageGoogleCampaign = (
  value: any
): value is ImageCampaignType => {
  return (
    checkIfGoogleCampaign(value) &&
    (value.ads === undefined ||
      (Array.isArray(value.ads) &&
        value.ads.every((ad: any) => checkIfFullGoogleImageAd(ad))))
  )
}
