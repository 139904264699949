import styled from '@emotion/styled'
import Colors from '../../../constants/colors'
import FontWeight from '../../../constants/fontWeight'
import Margin from '../../../constants/margin'

export const DivCO2Score = styled.div`
  display: flex;
`

export const DivLeaves = styled.div`
  display: flex;
  margin-right: ${Margin.m3};
`

export const DivCO2 = styled.div`
  font-weight: ${FontWeight.YETIC_BOLD};
  color: ${Colors.YETIC_BLUE_DARK};
  align-self: center;
`
