import { backendURL } from '../../../constants/urls'
import { checkIfAdArray, checkIfAdNameOnlyArray } from '../../../types/ad/ad'
import { ViewEnum } from '../../../types/axios/common'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetAdsByCampaignOutputType = { id: string; name: string }[] | null

export type GetAdsByCampaignInput = {
  campaignId: string
  view?: ViewEnum
}

export const getAdsByCampaign = async ({
  campaignId,
  view
}: GetAdsByCampaignInput): Promise<GetAdsByCampaignOutputType> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/campaigns/${campaignId}/ads${
        view ? `?view=${view}` : ''
      }`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (view === ViewEnum.NAME) {
        if (checkIfAdNameOnlyArray(response.data.ads)) {
          return response.data.ads
        }
      }
      if (checkIfAdArray(response.data.ads)) {
        return response.data.ads
      }
    }
    return null
  } catch (_) {
    return null
  }
}
