import React, { CSSProperties, FC, ReactNode, MouseEventHandler } from 'react'
import styled from '@emotion/styled'

import FontSize from '../../constants/fontSize'
import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'
import Colors from '../../constants/colors'
import Margin from '../../constants/margin'

export interface StyleButtonType {
  marginBottom?: string
  marginTop?: string
  width?: string
  heigth?: string
  padding?: string
}

const StyledButton = styled.button`
  background-color: ${Colors.YETIC_BLUE_DARK};
  color: white;

  font-weight: 600;
  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.YETIC_TEXT_SMALL};

  line-height: 1;

  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props: StyleButtonType) => (props.heigth ? props.heigth : '40px')};

  padding: ${(props: StyleButtonType) =>
    props.padding ? props.padding : '12px 15px 12px 15px'};
  margin-bottom: ${(props: StyleButtonType) =>
    props.marginBottom ? props.marginBottom : '0px'};
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}` : '')};

  border-radius: 5px;
  border: none;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: background-color 0.3s ease;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
          background-color: ${Colors.YETIC_BLUE_DARKER};
        }`}
`

const StyledButtonText = styled.button`
  cursor: pointer;

  background: none;
  width: ${(props) => (props.width ? props.width : '100%')};
  border: none;

  text-align: center;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  color: ${Colors.YETIC_BLUE_DARK};
  font-family: ${YETIC_FONT_FAMILY};
  vertical-align: middle;
  font-weight: 600;

  margin-bottom: ${(props: StyleButtonType) =>
    props.marginBottom ? props.marginBottom : Margin.m0};
  ${(props: StyleButtonType) =>
    props.marginTop ? `margin-top: ${props.marginTop};` : ''};
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`

const StyledButtonSecondary = styled.button`
  background: none;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props: StyleButtonType) => (props.heigth ? props.heigth : '40px')};

  border: 1px solid ${Colors.YETIC_BLUE_DARK};
  border-radius: 5px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  line-height: 1;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  text-align: center;
  font-size: ${FontSize.YETIC_TEXT_SMALL};
  color: ${Colors.YETIC_BLUE_DARK};
  font-family: ${YETIC_FONT_FAMILY};
  vertical-align: middle;
  font-weight: 600;
  box-sizing: border-box;

  padding: ${(props: StyleButtonType) =>
    props.padding ? props.padding : '12px 15px 12px 15px'};
  margin-bottom: ${(props: StyleButtonType) =>
    props.marginBottom ? props.marginBottom : Margin.m0};
  ${(props: StyleButtonType) =>
    props.marginTop ? `margin-top: ${props.marginTop};` : ''};
  transition: background-color 0.3s ease;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: ${Colors.YETIC_BLUE_DARK};
      }`}
`

const StyledButtonTertiary = styled.button<StyleButtonType>`
  background-color: ${Colors.YETIC_YELLOW_DARK};
  width: ${(props) => (props.width ? props.width : 'auto')};
  color: ${Colors.YETIC_BLUE_DARK};
  font-family: ${YETIC_FONT_FAMILY};
  font-weight: 500;
  border-radius: 10px;
  padding: ${(props: StyleButtonType) =>
    props.padding ? props.padding : '12px 15px 12px 15px'};
  border: none;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  transition: background-color 0.3s ease;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
        background-color: ${Colors.YETIC_YELLOW_LIGHT};
      }`}
`

const StyledButtonToggle = styled.button<StyleButtonType & { active: boolean }>`
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;

  border-radius: 34px;
  border: none;
  background-color: ${({ active }) =>
    active ? Colors.YETIC_GREEN_DARK : '#d9d9d9'};
  transition: background-color 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    transition: transform 0.3s ease;
    transform: ${({ active }) =>
      active ? 'translateX(26px)' : 'translateX(0)'};
  }
`

export interface ButtonPropsType {
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  width?: string
  heigth?: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'toggle' | 'textButton'
  active?: boolean
  disabled?: boolean
  marginBottom?: string
  marginTop?: string
  style?: CSSProperties
  padding?: string
}

const Button: FC<ButtonPropsType> = ({
  children,
  onClick = () => {},
  width,
  heigth,
  type = 'primary',
  active = false,
  disabled = false,
  marginBottom = undefined,
  marginTop = undefined,
  padding,
  style = {}
}) => {
  if (type === 'textButton') {
    return (
      <StyledButtonText
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
      >
        {children}
      </StyledButtonText>
    )
  } else if (type === 'toggle') {
    return (
      <StyledButtonToggle
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        active={active}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
      />
    )
  } else if (type === 'secondary') {
    return (
      <StyledButtonSecondary
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
      >
        {children}
      </StyledButtonSecondary>
    )
  } else if (type === 'tertiary') {
    return (
      <StyledButtonTertiary
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
      >
        {children}
      </StyledButtonTertiary>
    )
  } else {
    return (
      <StyledButton
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
      >
        {children}
      </StyledButton>
    )
  }
}

export default Button
