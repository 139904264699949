export enum SendToValidateMediaPlanErrorEnum {
  UNKNOWN = 'UNKNOWN',
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_VALIDATED = 'ALREADY_VALIDATED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_MATCHING = 'USER_NOT_MATCHING'
}

export const SendToValidateMediaPlanErrorTranslation = {
  [SendToValidateMediaPlanErrorEnum.UNKNOWN]:
    'Une erreur inconnue est survenue',
  [SendToValidateMediaPlanErrorEnum.NOT_FOUND]: 'Le plan média est introuvable',
  [SendToValidateMediaPlanErrorEnum.ALREADY_VALIDATED]:
    'Ce plan média a déjà été envoyé pour validation',
  [SendToValidateMediaPlanErrorEnum.USER_NOT_FOUND]:
    "Vos données utilisateur n'ont pas été trouvées",
  [SendToValidateMediaPlanErrorEnum.USER_NOT_MATCHING]:
    'Vos données utilisateur ne correspondent pas à celles du média plan'
}

export const checkIfSendToValidateMediaPlanErrorCodeValid = (
  errorCode: string
): errorCode is SendToValidateMediaPlanErrorEnum => {
  return Object.keys(SendToValidateMediaPlanErrorEnum).includes(errorCode)
}
