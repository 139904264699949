import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ReviewPageTemplate from '../../../templates/ReviewPageTemplate'
import { MediaPlanReviewDataType } from '../../../types/pages/mediaPlanReview'
import { StrategyEnum } from '../../../types/strategy'
import { ContainerBlock } from '../../../templates/ReviewPageTemplate/ReviewContent'
import ValidateModal from '../../MediaPlanReview/AdvertiserModals/ValidateModal'
import {
  checkIfValidateMediaPlan,
  validateMediaPlan
} from '../../../helpers/queries/mediaPlan/validateMediaPlan'
import handleExportDownload from '../../../helpers/handleExportDownload'

type SelfEditorPaymentContentProps = {
  mediaPlanId: string
  reviewData: MediaPlanReviewDataType
  tipsEcology: JSX.Element
  containerBlock: ContainerBlock
  handleViewSubItem: (id: string) => void
}

const SelfEditorPaymentContent = ({
  mediaPlanId,
  reviewData,
  tipsEcology,
  containerBlock,
  handleViewSubItem
}: SelfEditorPaymentContentProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paymentType, setPaymentType] = useState(-1)
  const [validateError, setValidateError] = useState('')
  const [validateValidationMessage, setValidateValidationMessage] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (isSubmitLoading) {
      /*       if (checkPaymentType(paymentType)) { */
      ;(async function () {
        const result = await validateMediaPlan({
          mediaPlanId,
          isMonthlyPayment: paymentType === 0
        })
        if (checkIfValidateMediaPlan(result)) {
          setValidateValidationMessage(
            'Le plan publicitaire a bien été validé. Vous allez recevoir un email pour le paiement.'
          )
        } else {
          setValidateError(result.error)
        }
        setIsSubmitLoading(false)
      })()
      /* } else {
        setValidateError('Veuillez renseigner un moyen de paiement')
      } */
    }
  }, [isSubmitLoading])

  const handleChangePaymentType = (value: number) => {
    setPaymentType(value)
  }

  const validation = {
    label: 'Valider et payer',
    action: () => {
      setIsModalOpen(true)
    },
    disabled: false
  }

  const cancel = {
    label: 'Retour au plan publicitaire',
    action: () => {
      navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
    },
    disabled: false
  }

  const tipsInfo = `Valider ce plan publicitaire lancera le processus de paiement. Une fois le paiement effectué par vos soins, le plan publicitaire sera diffusé sur la ou les plateforme(s) concernée(s) à la date souhaitée.`

  const handleCloseModal = () => {
    if (validateValidationMessage) {
      navigate(`/`)
    } else {
      setIsModalOpen(false)
    }
  }

  const checkPaymentType = (value: number) => value >= 0

  const handleSubmitValidate = () => {
    setIsSubmitLoading(true)
  }

  const download = {
    label: 'Télécharger le plan média en PDF',
    action: () => {
      handleExportDownload(mediaPlanId)
    },
    disabled: false
  }

  return (
    <>
      <ReviewPageTemplate
        name={reviewData.mediaPlan.name}
        title={'Validation du plan publicitaire'}
        tipsEcology={reviewData.campaigns.length > 0 ? tipsEcology : null}
        tipsInfo={tipsInfo}
        type={StrategyEnum.MEDIA_PLAN}
        validation={validation}
        download={download}
        cancel={cancel}
        container={containerBlock}
        handleViewMore={handleViewSubItem}
        isSelfEditor={true}
      />
      {isModalOpen && (
        <ValidateModal
          onClose={handleCloseModal}
          handleSubmit={() => {
            handleSubmitValidate()
          }}
          paymentType={paymentType}
          handleChangePaymentType={handleChangePaymentType}
          isLoading={isSubmitLoading}
          error={validateError}
          validationMessage={validateValidationMessage}
          budget={reviewData.mediaPlan.budget}
          name={reviewData.mediaPlan.name}
        />
      )}
    </>
  )
}

export default SelfEditorPaymentContent
