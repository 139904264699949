import React from 'react'

import {
  Comment,
  Item,
  ItemHeader,
  ItemHeaderTextContainer,
  ItemSubheader,
  Value,
  commentTextStyle,
  valueTextStyle
} from './styles'
import DefaultText from '../../DefaultText'
import { CustomTooltip } from '../../CustomToolTip'
import Colors from '../../../constants/colors'

type DetailsTabItemProps = {
  title: string
  description?: string
  comment?: string
  value?: string
  percentage?: number
  withBorder?: boolean
  children?: JSX.Element
}

const DetailsTabItem = ({
  title,
  description,
  comment,
  value,
  percentage,
  withBorder = true,
  children
}: DetailsTabItemProps) => {
  return (
    <Item withBorder={withBorder}>
      <ItemHeader>
        <ItemHeaderTextContainer>
          <DefaultText size="big">{title}</DefaultText>
          {description && <CustomTooltip content={description} hover dark />}
        </ItemHeaderTextContainer>
        <Value style={{ alignItems: 'flex-end' }}>
          {
            <DefaultText size="medium" style={valueTextStyle}>
              {value ?? '-'}
            </DefaultText>
          }
        </Value>
      </ItemHeader>
      <ItemSubheader>
        <Comment>
          <DefaultText size="medium" style={commentTextStyle}>
            {comment}
          </DefaultText>
        </Comment>
        <DefaultText style={{ color: Colors.YETIC_BLUE_LIGHT }} size="medium">
          {percentage} %
        </DefaultText>
      </ItemSubheader>
      {children}
    </Item>
  )
}

export default DetailsTabItem
