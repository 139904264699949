import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'

export const DivContainer = styled.div`
  margin-top: ${Margin.m6};
`

export const TagSpan = styled.span`
  background-color: ${Colors.YETIC_BLUE_DARK};
  color: white;
  border-radius: 5px;
  padding: 4px 8px;
  margin-right: ${Margin.m2};
  display: block;
  white-space: nowrap;
`

export const CloseIcon = styled.span`
  cursor: pointer;
  margin-left: ${Margin.m2};
  font-size: ${FontSize.YETIC_TEXT};
  color: white;

  &:hover {
    color: ${Colors.YETIC_BLUE_DARK};
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.YETIC_WHITE};
  box-shadow: 0px 0px 3px #142a4c;
  border-radius: 10px;
  margin-top: 6px;
  max-height: 200px;
  overflow-y: auto;
`

export const Item = styled.span`
  display: flex;
  align-items: center;
  color: ${Colors.YETIC_BLUE_DARK};
  padding: 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.NEW_YETIC_GREY};
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

export const Label = styled.span`
  margin-right: ${Margin.m2};
`
