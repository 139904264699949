import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../../../constants/padding'
import Colors from '../../../../constants/colors'
import FontSize from '../../../../constants/fontSize'
import Margin from '../../../../constants/margin'

export const HighlightsContainer = styled.div`
  width: 100%;
  padding: ${Padding.p8} ${Padding.p9};
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const HighlightsRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${Colors.YETIC_WHITE};
  height: 100px;
  border: solid;
`

export const loaderStyle: CSSProperties = {
  marginTop: '10px'
}

export const boxStyle: CSSProperties = {
  minWidth: '200px'
}

export const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DownloadHighlightContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
`

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const titleStyle: CSSProperties = {
  margin: '0',
  fontSize: FontSize.YETIC_SUBTITLE,
  fontWeight: '500'
}

export const downloadIconStyle: CSSProperties = {
  marginLeft: '5px'
}

export const buttonDownloadStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center'
}

export const nameCampaignStyle: CSSProperties = {
  margin: '0',
  fontSize: FontSize.YETIC_SUBTITLE,
  fontWeight: '600',
  marginBottom: Margin.m4
}
