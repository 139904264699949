import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Breadcrumb from '../../components/BreadCrumb'
import Dropdown from '../../components/Dropdown'
import { InputError } from '../../components/Input/Styles'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import { FlowEnum } from '../../types/flow'
import { FormatAPIEnum } from '../../types/format'
import { PanelType } from '../../types/templates/panels'
import { AddToPanelsParams, CampaignEditType } from './types'

const EditorCampaignEdit = ({
  campaign,
  mediaPlan,
  nameSection,
  formatSection,
  goalSection,
  periodSection,
  budgetSection,
  keywordsSection,
  langSection,
  devicesSection,
  bidStrategySection,
  errors,
  flow,
  campaignId,
  isSubmitLoading,
  onChange,
  handleSubmitCampaign
}: CampaignEditType) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)

  const navigate = useNavigate()

  const audienceSection = {
    description: 'Sélectionnez une audience',
    content: (
      <>
        <Dropdown
          value={campaign ? campaign.audience : ''}
          options={
            mediaPlan
              ? mediaPlan.audiences.map((a, i) => ({
                  id: i,
                  label: a.name
                }))
              : []
          }
          onChange={onChange}
        />
        {hasBeenSubmitted && errors.audience && (
          <InputError>{errors.audience}</InputError>
        )}
      </>
    )
  }

  const breadcrumbItems = [
    ...(flow === FlowEnum.view
      ? [
          {
            label: 'Accueil',
            onClick: () => {
              navigate(`/`)
            }
          }
        ]
      : []),
    {
      label: mediaPlan ? mediaPlan.navigation.company.name : '',
      onClick:
        flow === FlowEnum.view
          ? () => {
              navigate(
                `/customer/${
                  mediaPlan ? mediaPlan.navigation.company.id : ''
                }/details`
              )
            }
          : undefined
    },
    {
      label: mediaPlan ? mediaPlan.navigation.mediaPlan.name : '',
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(
              `/media-plan/${
                mediaPlan ? mediaPlan.navigation.mediaPlan.id : ''
              }/review`
            )
          : navigate(
              `/media-plan/${
                mediaPlan ? mediaPlan.navigation.mediaPlan.id : ''
              }/details`
            )
      }
    },
    {
      label: `Campagne « ${campaign ? campaign.name : ''} »`,
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(`/campaign/${campaignId}/review`)
          : navigate(`/campaign/${campaignId}/details`)
      }
    },
    {
      label: 'Édition'
    }
  ]

  const panels: PanelType[] = []
  const addToPanels = ({
    title,
    sections,
    isDropdown = false,
    disabled = true
  }: AddToPanelsParams) => {
    panels.push({
      title,
      sections,
      isDropdown,
      disabled
    })
  }

  addToPanels({ title: 'Nom de la campagne', sections: [nameSection] })
  addToPanels({ title: 'Format', sections: [formatSection] })
  addToPanels({ title: 'Objectif', sections: [goalSection] })
  addToPanels({
    title: 'Audience',
    sections: [audienceSection],
    disabled: false
  })
  addToPanels({
    title: 'Diffusion',
    sections: [periodSection, budgetSection],
    disabled: false
  })

  campaign &&
    campaign.format === FormatAPIEnum.TEXT &&
    addToPanels({
      title: 'Mots-clés',
      sections: [keywordsSection],
      disabled: false
    })

  addToPanels({
    title: 'Autres paramètres (facultatifs)',
    sections: [langSection, devicesSection],
    isDropdown: true,
    disabled: false
  })

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Édition de la campagne « ${campaign ? campaign.name : ''} »`}
        subTitle="Mise à jour des caractéristiques globales de votre campagne"
        panels={panels}
        errors={{
          errors: hasBeenSubmitted
            ? Object.values(errors).filter((e) => e !== '')
            : []
        }}
        validation={{
          wording: 'Valider les modifications',
          isLoading: isSubmitLoading,
          action: () => {
            if (Object.values(errors).filter((e) => e).length < 1) {
              handleSubmitCampaign()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled:
            hasBeenSubmitted &&
            Object.values(errors).filter((e) => e).length > 0
        }}
        cancel={{
          wording: 'Retour',
          action: () => {
            flow === FlowEnum.creation
              ? navigate(`/campaign/${campaignId}/review`)
              : navigate(`/campaign/${campaignId}/details`)
          },
          disabled: false
        }}
      />
    </>
  )
}

export default EditorCampaignEdit
