import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'
import FontSize from '../../../../../../constants/fontSize'
import FontWeight from '../../../../../../constants/fontWeight'
import Margin from '../../../../../../constants/margin'
import Padding from '../../../../../../constants/padding'

export const YoutubeWindow = styled.div`
  position: relative;
  width: 320px;
  height: 570px;
  border: solid 1px ${Colors.NEW_YETIC_GREY_LIGHT};
  border-radius: 5px;
  background-color: ${Colors.YETIC_WHITE};
`

export const YoutubeHeader = styled.div`
  position: absolute;
  width: 320px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 40px;
  border-radius: 5px 5px 0 0;
  padding: ${Padding.p4} ${Padding.p4};
  z-index: 1;
`

export const ProfileIcon = styled.div<{
  small?: boolean
}>`
  background-color: ${Colors.YETIC_GREY_DARK};
  height: ${(props) => (props.small ? '28px' : '40px')};
  width: ${(props) => (props.small ? '28px' : '40px')};
  border-radius: 50px;
`

export const AdContent = styled.div`
  width: 300px;
  margin: auto;
`

export const Channel = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 18px 0;
  padding: 0 ${Padding.p4};
`

export const ChannelName = styled.div`
  position: relative;
  background-color: ${Colors.YETIC_GREY_DARK};
  height: 10px;
  width: 150px;
  flex: 1;
  margin: 0 30px 0 20px;
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    background-color: ${Colors.YETIC_GREY_DARK};
    height: 10px;
    width: 120px;
  }
`

export const OptionBtn = styled.div`
  position: relative;
  background-color: ${Colors.YETIC_GREY_DARK};
  height: 5px;
  width: 5px;
  border-radius: 50px;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 8px;
    background-color: ${Colors.YETIC_GREY_DARK};
    height: 5px;
    width: 5px;
    border-radius: 50px;
  }
  &:before {
    top: -20px;
  }
`

export const AdImage = styled.img`
  width: 280px;
  height: 147px;
  margin-bottom: ${Margin.m3};
`

export const AdVideo = styled.div`
  width: 300px;
  height: 150px;
  margin-bottom: ${Margin.m3};
  background-color: ${Colors.YETIC_GREY_DARK};
`

export const AdTitle = styled.div`
  font-weight: 500;
  font-size: ${FontSize.YETIC_HOME_BOX_TITLE};
  color: #363636;
  margin-bottom: ${Margin.m2};
`

export const AdDescription = styled.div`
  font-size: ${FontSize.YETIC_TEXT};
  margin-bottom: ${Margin.m6};
  color: ${Colors.YETIC_GREY_AD};
`

export const AdLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AdContainer = styled.div`
  padding: 0 10px;
  margin-bottom: ${Margin.m7};
`

export const AdCompany = styled.div`
  text-align: left;
`

export const AdIcon = styled.div`
  font-weight: 600;
  font-size: ${FontSize.YETIC_TEXT};
  background-color: #e8c13c;
  color: ${Colors.YETIC_WHITE};
  display: inline-block;
  padding: 0px 5px;
  border-radius: 3px;
`

export const CompanyName = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #363636;
`

export const AdButton = styled.div`
  color: #4385f5;
  border: solid 1px #4385f5;
  background-color: rgba(67, 133, 245, 0.1);
  font-size: ${FontSize.YETIC_TEXT};
  font-weight: 600;
  height: 25px;
  padding: 0 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
`

export const TabBar = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 320px;
  left: 0px;
  bottom: 0;
  background-color: ${Colors.YETIC_WHITE};
  padding-bottom: ${Padding.p2};
`

export const Tab = styled.div<{ color?: string }>`
  text-align: center;
  padding: ${Padding.p2};
  border-bottom: solid 5px
    ${(props) => (props.color ? props.color : Colors.YETIC_GREY_DARK)};
`

export const TabIcon = styled.div`
  background-color: ${Colors.YETIC_GREY_DARK};
  width: 15px;
  height: 15px;
`
