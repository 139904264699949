import React, { CSSProperties, KeyboardEvent, FocusEvent } from 'react'

import {
  Close,
  DivLabel,
  InputError,
  KeywordContainer,
  KeywordLabel,
  StyledInput,
  StyledLabel,
  Textarea,
  PasswordContainer,
  DivEyes,
  InputContainer,
  BudgetCurrency,
  RequiredLabel
} from './Styles'
import InputRange from './InputRange'
import DefaultText from '../DefaultText'
import { EyeIcon, EyeCloseIcon } from '../Icon/eyes'

export type InputPropsBase = {
  width?: string
  placeholder?: string
  style?: CSSProperties
  label?: string | JSX.Element
  isRequired?: boolean
  showCharacterCount?: boolean
  maxLength?: number
  value?: string
  colorLabel?: string
  containerWidth?: string
  containerStyle?: CSSProperties
  error?: string | undefined
  disabled?: boolean
  withBorder?: boolean
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}

export type InputPropsBaseExceptTextarea = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type InputPropsRange = InputPropsBaseExceptTextarea & {
  type: 'range'
  step?: number
  defaultValue?: number
  min?: number
  max?: number
}

export type InputPropsNumber = InputPropsBaseExceptTextarea & {
  type: 'number'
  min?: number
  max?: number
}

export type InputPropsDevise = InputPropsBaseExceptTextarea & {
  type: 'devise'
  min?: number
  max?: number
}

export type InputPropsText = InputPropsBaseExceptTextarea & {
  type: 'text'
  label?: string
}

export type InputsPropsPassword = InputPropsBaseExceptTextarea & {
  type: 'password'
  showPassword: boolean
  setShowPassword: (showPassword: boolean) => void
}

export type InputPropsTextArea = {
  type: 'textarea'
  label?: string
  rows?: number
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
}

export type InputPropsDate = InputPropsBaseExceptTextarea & {
  type: 'date'
  min?: string
  max?: string
  // min & max formatted as YYYY-MM-DD
}

export type InputPropsTime = InputPropsBaseExceptTextarea & {
  type: 'time'
}

export type InputPropsKeyword = InputPropsBaseExceptTextarea & {
  type: 'keyword'
  keywords: string[]
  setKeywords: (keywords: string[]) => void
}

export type InputPropsUrl = InputPropsBaseExceptTextarea & {
  type: 'url'
  label?: string
}

export type InputPropsEmail = InputPropsBaseExceptTextarea & {
  type: 'email'
  label?: string
}

export type InputProps = InputPropsBase &
  (
    | InputPropsRange
    | InputPropsNumber
    | InputPropsDevise
    | InputPropsText
    | InputPropsTextArea
    | InputPropsDate
    | InputPropsTime
    | InputPropsKeyword
    | InputPropsUrl
    | InputsPropsPassword
    | InputPropsEmail
  )

export interface handlerProps {
  event: React.KeyboardEvent<HTMLInputElement>
}

export interface removeProps {
  index: number
}

const isTypeRange = (
  input: InputProps
): input is InputPropsBase & InputPropsRange => input.type === 'range'

const Input = (props: InputProps) => {
  const {
    label,
    isRequired = true,
    showCharacterCount,
    colorLabel,
    containerWidth,
    containerStyle,
    ...inputProps
  } = props
  const characterCount = inputProps.value?.length ?? 0

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (inputProps.type !== 'textarea') return

    if (inputProps.onChange) {
      inputProps.onChange(event)
    }
  }
  const getCharacterCountText = (): React.ReactNode => {
    if (inputProps.maxLength && showCharacterCount) {
      const characterCountText = `${characterCount}
      / ${inputProps.maxLength} car.`
      if (characterCount >= inputProps.maxLength) {
        return <span style={{ color: 'orange' }}>{characterCountText}</span>
      }

      return characterCountText
    }

    return ''
  }

  const styleHandlingError = {
    ...inputProps.style
  }

  if (inputProps.type === 'textarea') {
    return (
      <StyledLabel style={containerStyle}>
        <DivLabel style={{ width: inputProps.width }}>
          {label && (
            <DefaultText>
              {label}
              {isRequired ? '*' : null}
              {isRequired && <RequiredLabel>{' (Obligatoire)'}</RequiredLabel>}
            </DefaultText>
          )}
          {showCharacterCount && (
            <DefaultText style={{ color: '#9AA0AA' }}>
              {getCharacterCountText()}
            </DefaultText>
          )}
        </DivLabel>
        <Textarea
          style={styleHandlingError}
          placeholder={inputProps.placeholder}
          value={inputProps.value}
          onChange={handleTextareaChange}
          maxLength={inputProps.maxLength}
          disabled={inputProps.disabled}
          error={inputProps.error}
          withBorder={inputProps.withBorder}
        />
        {inputProps.error ? <InputError>{inputProps.error}</InputError> : null}
      </StyledLabel>
    )
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputProps.onChange) {
      inputProps.onChange(event)
    }
  }

  const handleKeywordInput = ({ event }: handlerProps) => {
    if (inputProps.type !== 'keyword') return
    const { keywords, setKeywords } = inputProps

    if (event.key === 'Enter') {
      const keyword = event.currentTarget.value.trim()

      if (keyword) {
        setKeywords([...keywords, keyword])
        event.currentTarget.value = ''
      }
    }
  }

  const handleTogglePassword = () => {
    if (inputProps.type === 'password' && inputProps.setShowPassword) {
      inputProps.setShowPassword(inputProps.showPassword)
    }
  }
  const removeKeyword = ({ index }: removeProps) => {
    if (inputProps.type !== 'keyword') return
    const { keywords, setKeywords } = inputProps

    setKeywords(keywords.filter((_, i) => i !== index))
  }

  return (
    <StyledLabel width={containerWidth} style={containerStyle}>
      {(label || showCharacterCount) && (
        <DivLabel style={{ width: inputProps.width }}>
          {label && (
            <DefaultText style={{ color: colorLabel }}>
              {label}
              {isRequired ? '*' : null}
              {isRequired && <RequiredLabel>{' (Obligatoire)'}</RequiredLabel>}
            </DefaultText>
          )}
          {showCharacterCount && (
            <DefaultText style={{ color: '#9AA0AA' }}>
              {getCharacterCountText()}
            </DefaultText>
          )}
        </DivLabel>
      )}
      {isTypeRange(inputProps) ? (
        <>
          <InputRange
            width={inputProps.width}
            style={styleHandlingError}
            step={inputProps.step}
            min={inputProps.min}
            max={inputProps.max}
            error={inputProps.error}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      ) : inputProps.type === 'number' ? (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            type="number"
            min={inputProps.min}
            max={inputProps.max}
            value={inputProps.value}
            onChange={inputProps.onChange}
            disabled={inputProps.disabled}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
            onKeyDown={inputProps.onKeyDown}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      ) : inputProps.type === 'devise' ? (
        <InputContainer>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            type="number"
            min={inputProps.min}
            max={inputProps.max}
            value={inputProps.value}
            onChange={inputProps.onChange}
            disabled={inputProps.disabled}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
          <BudgetCurrency>eur</BudgetCurrency>
        </InputContainer>
      ) : inputProps.type === 'date' ? (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            type="date"
            value={inputProps.value}
            onChange={inputProps.onChange}
            min={inputProps.min}
            max={inputProps.max}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      ) : inputProps.type === 'password' ? (
        <PasswordContainer>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            type={inputProps.showPassword ? 'text' : 'password'}
            value={inputProps.value}
            onChange={inputProps.onChange}
            maxLength={inputProps.maxLength}
            onKeyDown={inputProps.onKeyDown}
            onBlur={inputProps.onBlur}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.showPassword ? (
            <DivEyes>
              <EyeCloseIcon handleClick={handleTogglePassword} />
            </DivEyes>
          ) : (
            <DivEyes>
              <EyeIcon handleClick={handleTogglePassword} />
            </DivEyes>
          )}
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </PasswordContainer>
      ) : inputProps.type === 'time' ? (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            type="time"
            value={inputProps.value}
            onChange={inputProps.onChange}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      ) : inputProps.type === 'keyword' ? (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            onKeyDown={(event) => {
              handleKeywordInput({ event })
            }}
            type="text"
            value={inputProps.value}
            onChange={inputProps.onChange}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
          <KeywordContainer>
            {inputProps.keywords.map((keyword, index) => (
              <KeywordLabel key={index}>
                {keyword}
                <Close
                  onClick={() => {
                    removeKeyword({ index })
                  }}
                >
                  X
                </Close>
              </KeywordLabel>
            ))}
          </KeywordContainer>
        </>
      ) : inputProps.type === 'url' ? (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            type="url"
            value={inputProps.value}
            onChange={inputProps.onChange}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      ) : inputProps.type === 'email' ? (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            value={inputProps.value}
            onChange={handleInputChange}
            maxLength={inputProps.maxLength}
            onKeyDown={inputProps.onKeyDown}
            onBlur={inputProps.onBlur}
            disabled={inputProps.disabled}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
            type="email"
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      ) : (
        <>
          <StyledInput
            style={styleHandlingError}
            placeholder={inputProps.placeholder}
            width={inputProps.width}
            value={inputProps.value}
            onChange={handleInputChange}
            maxLength={inputProps.maxLength}
            onKeyDown={inputProps.onKeyDown}
            onBlur={inputProps.onBlur}
            disabled={inputProps.disabled}
            error={inputProps.error}
            withBorder={inputProps.withBorder}
          />
          {inputProps.error ? (
            <InputError>{inputProps.error}</InputError>
          ) : null}
        </>
      )}
    </StyledLabel>
  )
}

export default Input
