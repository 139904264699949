import React from 'react'

import { ScoreComparisonContainer } from './styles'
import DefaultText from '../../../DefaultText'
import { roundToTwoDecimals } from '../../../../helpers/rounds'
import Colors from '../../../../constants/colors'

type ScoreComparisonProps = {
  previousValue: number
  currentValue: number | null
}

const ScoreComparison = ({
  previousValue,
  currentValue
}: ScoreComparisonProps) => {
  if (currentValue == null) {
    return null
  }

  const score = roundToTwoDecimals(
    (100 * (currentValue - previousValue)) / previousValue
  )

  if (score === 0) {
    return null
  }

  const isPositive = score > 0
  const textColor = isPositive ? Colors.NEW_YETIC_RED : '#2ccab2'

  return (
    <ScoreComparisonContainer isPositive={isPositive}>
      <DefaultText size="medium" bold style={{ color: textColor }}>
        {isPositive ? '+' : '-'} {Math.abs(score)} %
      </DefaultText>
    </ScoreComparisonContainer>
  )
}

export default ScoreComparison
