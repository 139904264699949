import {
  BarChartData,
  Point as BarChartPoint
} from '../../../../../components/Chart/BarChart'
import { ElementItem } from '../../../../../components/Table'
import Colors from '../../../../../constants/colors'
import {
  AgeRangeEnum,
  getAgeRangeEnumList,
  getAgeRangeFromEnum
} from '../../../../../types/reporting/age'
import { GetCampaignImpactReportingOutputType } from '../../../../../types/reporting/campaign/output'

type FormatImpactDataOutput = {
  impactBarChart: BarChartData<BarChartPoint>[]
  impactTableDataCO2: ElementItem[]
  impactBarChartCO2PerAge: BarChartData<BarChartPoint>[]
  impactTableDataCO2PerAge: ElementItem[]
  impactTableDataCO2PerAd: ElementItem[]
  impactBarChartCO2PerAd: BarChartData<BarChartPoint>[]
  co2PerCost: number
}

const formatImpactData = (
  data: GetCampaignImpactReportingOutputType
): FormatImpactDataOutput => {
  const impactBarChart: BarChartData<BarChartPoint>[] = [
    {
      name: 'CO2',
      color: Colors.NEW_YETIC_CO2,
      unit: 'g',
      values: [
        {
          category: 'Smartphone',
          value: data.devices.smartphone.co2
        },
        {
          category: 'Tablette',
          value: data.devices.tablet.co2
        },
        {
          category: 'Ordinateur',
          value: data.devices.desktop.co2
        },
        {
          category: 'TV',
          value: data.devices.tv.co2
        }
      ]
    }
  ]

  const impactTableDataCO2: ElementItem[] = [
    {
      id: 'smartphone',
      device: 'Smartphone',
      co2: data.devices.smartphone.co2
    },
    {
      id: 'tablet',
      device: 'Tablette',
      co2: data.devices.tablet.co2
    },
    {
      id: 'desktop',
      device: 'Ordinateur',
      co2: data.devices.desktop.co2
    },
    {
      id: 'tv',
      device: 'TV',
      co2: data.devices.tv.co2
    }
  ]

  const totalCO2 = Object.values(data.devices).reduce(
    (sum, device) => sum + device.co2,
    0
  )

  const totalImpressions = Object.values(data.genderAndAge.impressions).reduce(
    (sum, impr) => sum + impr.value,
    0
  )

  const CO2PerImpression =
    totalImpressions !== 0 ? totalCO2 / totalImpressions : 0

  const CO2PerAge = getAgeRangeEnumList().map((ageRange) => {
    const ageSliceData = data.genderAndAge.impressions.filter(
      (el) => el.age === ageRange
    )

    const totalImpr = ageSliceData.reduce((sum, el) => sum + el.value, 0)

    const emitedCO2 = Math.round(totalImpr * CO2PerImpression * 100) / 100

    return {
      ageRange,
      ageRangeLabel: getAgeRangeFromEnum(ageRange),
      value: emitedCO2
    }
  })

  const impactBarChartCO2PerAge: BarChartData<BarChartPoint>[] = [
    {
      name: 'CO2',
      color: Colors.NEW_YETIC_CO2,
      unit: 'g',
      values: CO2PerAge.filter(
        (range) => range.ageRange !== AgeRangeEnum.default
      ).map((el) => {
        return {
          category: el.ageRangeLabel,
          value: el.value
        }
      })
    }
  ]

  const impactTableDataCO2PerAge: ElementItem[] = CO2PerAge.map((el) => {
    return {
      id: el.ageRange,
      ageRange: el.ageRangeLabel,
      co2: el.value
    }
  })

  const impactTableDataCO2PerAd: ElementItem[] = data.ads.map((ad) => {
    return {
      id: ad.adName,
      ad: ad.adName,
      co2: Math.round(ad.impressions * CO2PerImpression * 100) / 100
    }
  })

  const impactBarChartCO2PerAd: BarChartData<BarChartPoint>[] = [
    {
      name: 'CO2',
      color: Colors.NEW_YETIC_CO2,
      unit: 'g',
      values: data.ads.map((ad) => {
        return {
          category: ad.adName,
          value: Math.round(ad.impressions * CO2PerImpression * 100) / 100
        }
      })
    }
  ]

  const totalCost =
    data.devices.desktop.cost +
    data.devices.tablet.cost +
    data.devices.smartphone.cost +
    data.devices.tv.cost

  const co2PerCost =
    totalCost !== 0 ? Math.round((totalCO2 / totalCost) * 100) / 100 : 0

  return {
    impactBarChart,
    impactTableDataCO2,
    impactBarChartCO2PerAge,
    impactTableDataCO2PerAge,
    impactTableDataCO2PerAd,
    impactBarChartCO2PerAd,
    co2PerCost
  }
}

export default formatImpactData
