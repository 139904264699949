import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import FontSize from '../../../../constants/fontSize'
import { GOOGLE_FONT_FAMILY } from '../../../../constants/fontFamily'

export const DivContainer = styled.div<{
  width: string
  height: string
  bgColor?: string
  bgBlur?: string
}>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  background-color: ${(props) => props.bgColor || Colors.YETIC_GREY_AD};
  border: 1px solid ${Colors.YETIC_GREY_AD};
  overflow: hidden;
  background-color: ${(props) =>
    props.bgBlur ? 'transparent' : props.bgColor || Colors.YETIC_GREY_AD};
  font-family: ${GOOGLE_FONT_FAMILY};

  ${(props) =>
    props.bgBlur &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${props.bgBlur});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(15px) brightness(0.6) contrast(1.2) saturate(1.3);
      background-color: rgba(0, 0, 0, 0.3);
      transform: scale(1.2);
      z-index: -1;
    }
  `}
`

export const DivInfo = styled.div`
  background-color: ${Colors.YETIC_WHITE};
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
  position: absolute;
`

export const Info = styled.div`
  cursor: pointer;
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  font-weight: bold;
  background-color: ${Colors.YETIC_WHITE};
  color: ${Colors.YETIC_BLUE_INFO_ICON};
  border: 1px solid ${Colors.YETIC_BLUE_INFO_ICON};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1px;
  right: 0;
`
