import { backendURL } from '../../../constants/urls'
// eslint-disable-next-line
import { DuplicateAudienceErrorEnum } from '../../../types/error/audience/duplicateAudience'
import { axiosPost, checkIsAxiosPostOutputSuccess } from '../axios/post'

type DuplicateAudienceInputType = {
  id: string
  newName: string
}

export type DuplicateAudienceOutputType = {
  audienceName: string
}

export type DuplicateAudienceOutputError = {
  error: DuplicateAudienceErrorEnum
}

export const duplicateExistingAudience = async ({
  id,
  newName
}: DuplicateAudienceInputType): Promise<
  DuplicateAudienceOutputType | DuplicateAudienceOutputError
> => {
  const response = await axiosPost({
    url: `${backendURL}/audiences/${id}/duplicate`,
    data: {
      name: newName
    }
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (response.data.audienceName) {
      return {
        audienceName: response.data.audienceName
      }
    } else {
      return {
        error: DuplicateAudienceErrorEnum.UNKNOWN
      }
    }
  } else {
    return {
      error: (response.error as any).data.code
    }
  }
}

export const checkIfDuplicateAudienceOutputType = (
  result: any
): result is DuplicateAudienceOutputType => {
  return result.audienceName && typeof result.audienceName === 'string'
}

export const checkIfDuplicateAudienceOutputTypeArray = (
  result: any
): result is DuplicateAudienceOutputType[] => {
  return (
    result &&
    Array.isArray(result) &&
    result.every((item: any) => checkIfDuplicateAudienceOutputType(item))
  )
}
