import React, { useContext } from 'react'

import EditorGoogleImageAdCreation from './editor'
import SelfEditorGoogleImageAdCreation from './selfEditor'
import { AppContext } from '../../../../contexts/AppContext'
import ErrorPage from '../../../ErrorPage'
import Widget from '../../../parts/Widget'

const ImageAdCreation = () => {
  const { isEditor, isSelfEditor } = useContext(AppContext)

  return (
    <Widget>
      {isEditor ? (
        <EditorGoogleImageAdCreation />
      ) : isSelfEditor ? (
        <SelfEditorGoogleImageAdCreation />
      ) : (
        <ErrorPage />
      )}
    </Widget>
  )
}

export default ImageAdCreation
