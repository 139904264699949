import React from 'react'

import { IconType } from '../../types/icon'
import Colors from '../../constants/colors'

const CheckAuth = ({
  height = '25px',
  width = '25px',
  style,
  color = Colors.NEW_YETIC_GREEN
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      width={width}
      height={height}
      viewBox="0 0 27.537 31.757"
    >
      <path
        d="M-22502.053-5617.235c-.186-.8,1.357-6.91,2.08-6.366s6.746,12.74,7.98,13.429,15.672-25.6,16.66-26.167.887,9.443.617,10.159-15.631,20.822-17.277,21.545S-22501.865-5616.437-22502.053-5617.235Z"
        transform="translate(22502.068 5636.361)"
        fill={color}
      />
    </svg>
  )
}

export default CheckAuth
