import { PlatformAPIEnum, checkIfPlatformApiEnum } from '../../platform'
import { FormatAPIEnum, checkIfFormatApiEnum } from '../../format'
import { GoalsEnum } from '../../goals'
import { MediaPlanAudience } from '../../mediaPlanAudience'
import { checkIfDate } from '../../../helpers/nativeTypes'
import { Status, checkIfStatus } from '../../status'

// TODO : complete once the specs are done
type LinkedinAd = {
  id: string
  name: string
  status?: Status
  format: FormatAPIEnum
}

export type LinkedinCampaignType = {
  id: string
  name: string
  platform: PlatformAPIEnum
  format: FormatAPIEnum
  status: Status
  goal: GoalsEnum
  period: {
    start: string
    end: string
  }
  budget: number
  spentBudget: number
  feedback?: string
  isBudgetPerDay: boolean
  audiences: MediaPlanAudience[]
  mediaPlanId: string
  userId: string
  createdAt: string | number
  updatedAt: string | number
  ads?: LinkedinAd[]
  co2: number
  isPlatformSynced: boolean
  customerName?: string
  keywords?: string[]
}

export const checkIfLinkedinCampaign = (
  value: any
): value is LinkedinCampaignType => {
  return (
    value &&
    value.id &&
    typeof value.id === 'string' &&
    value.name &&
    typeof value.name === 'string' &&
    value.platform &&
    checkIfPlatformApiEnum(value.platform) &&
    value.format &&
    checkIfFormatApiEnum(value.format) &&
    value.status &&
    checkIfStatus(value.status) &&
    value.period &&
    value.period.start &&
    checkIfDate(value.period.start) &&
    value.period.end &&
    checkIfDate(value.period.end) &&
    value.budget !== undefined &&
    typeof value.budget === 'number' &&
    value.spentBudget !== undefined &&
    typeof value.spentBudget === 'number' &&
    value.mediaPlanId &&
    typeof value.mediaPlanId === 'string' &&
    value.userId &&
    typeof value.userId === 'string' &&
    value.isPlatformSynced !== undefined &&
    value.createdAt &&
    (typeof value.createdAt === 'string' ||
      typeof value.createdAt === 'number') &&
    value.updatedAt &&
    (typeof value.updatedAt === 'string' || typeof value.updatedAt === 'number')
  )
}

export const checkIfLinkedinCampaignArray = (
  data: any
): data is LinkedinCampaignType[] => {
  return (
    Array.isArray(data) &&
    data.every((campaign: any) => checkIfLinkedinCampaign(campaign))
  )
}
