import { backendURL } from '../../../constants/urls'
import { MediaPlanGoal } from '../../../types/goals'
import { axiosPost, checkIsAxiosPostOutputSuccess } from '../axios/post'

export type CreateMediaPlanInputType = {
  name: string
  isEditorPaying: boolean
  startDate: string
  endDate: string
  amountBudget: number
  selectedGoals: (keyof typeof MediaPlanGoal)[]
  selectedAudiences: string[]
  companyId: string
}

export const createMediaPlan = async ({
  name,
  isEditorPaying,
  startDate,
  endDate,
  amountBudget,
  selectedGoals,
  selectedAudiences,
  companyId
}: CreateMediaPlanInputType) => {
  const response = await axiosPost({
    url: `${backendURL}/media-plans`,
    data: {
      name,
      isEditorPaying,
      startDate,
      endDate,
      budget: amountBudget,
      goals: selectedGoals.map((goal) => MediaPlanGoal[goal]),
      audiences: selectedAudiences,
      advertiserId: companyId
    }
  })
  if (checkIsAxiosPostOutputSuccess(response)) {
    if (response.data.mediaPlanId) {
      return {
        mediaPlanId: response.data.mediaPlanId
      }
    } else {
      return {
        mediaPlanId: '',
        error: 'UNKNOWN'
      }
    }
  } else {
    return {
      mediaPlanId: '',
      error: (response.error as any).data.code
    }
  }
}
