import { GetCustomerSectionsParams } from '../../types/customerSections'
import { sectionLabels } from '../sectionLabels/editor'

export const getCustomerSections = ({
  sections,
  customers,
  mediaPlans,
  campaigns,
  ads,
  fetchMediaPlans,
  fetchCampaigns,
  fetchAds,
  navigate
}: GetCustomerSectionsParams) => {
  customers !== null &&
    sections.push({
      id: 'customers',
      title: sectionLabels.customers,
      items: customers.map((customer) => {
        return {
          id: customer.id,
          title: customer.name,
          action: () => {
            navigate(`/customer/${customer.id}/details`)
          }
        }
      }),
      fetchData: fetchMediaPlans
    })

  mediaPlans !== null &&
    sections.push({
      id: 'mediaPlan',
      title: sectionLabels.mediaPlan,
      items: mediaPlans.map((mediaPlan) => {
        return {
          id: mediaPlan.id,
          title: mediaPlan.name,
          action: () => {
            navigate(`/media-plan/${mediaPlan.id}/details`)
          }
        }
      }),
      fetchData: fetchCampaigns
    })

  campaigns !== null &&
    sections.push({
      id: 'campaign',
      title: sectionLabels.campaign,
      items: campaigns.map((campaign) => {
        return {
          id: campaign.id,
          title: campaign.name,
          action: () => {
            navigate(`/campaign/${campaign.id}/details`)
          }
        }
      }),
      fetchData: fetchAds
    })

  ads !== null &&
    sections.push({
      id: 'ad',
      title: sectionLabels.ad,
      items: ads.map((ad) => {
        return {
          id: ad.id,
          title: ad.name,
          action: () => {
            navigate(`/ad/${ad.id}/details`)
          }
        }
      })
    })
}
