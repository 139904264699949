import React from 'react'

import Dropdown, { Option } from '../../../../../components/Dropdown'
import Margin from '../../../../../constants/margin'
import { Tags } from '../../../../../components/Tag'

export type AudienceListDropdownProps = {
  addSelectedAudience: (name: string) => void
  audiences: string[]
  selectedAudiences: string[]
  nonRemovableAudiences?: string[]
  removeAudience: (index: number) => void
  onClickTag?: (audienceName: string) => void
}

export const AudienceListDropdown = ({
  addSelectedAudience,
  audiences,
  selectedAudiences,
  nonRemovableAudiences,
  removeAudience,
  onClickTag
}: AudienceListDropdownProps) => {
  const audiencesToSelect = audiences.filter(
    (audience: string) => !selectedAudiences.includes(audience)
  )
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      {audiencesToSelect.length > 0 && (
        <Dropdown
          onChange={(option: Option | null) => {
            addSelectedAudience(option?.label ?? '')
          }}
          options={audiencesToSelect.map((audience: string, index: number) => ({
            id: index,
            label: audience
          }))}
          value={undefined}
          width="250px"
          placeholder="Choisissez une audience"
        />
      )}
      <div style={{ marginLeft: Margin.m5 }}>
        <Tags
          labels={selectedAudiences}
          removeElements={removeAudience}
          nonRemovableElements={nonRemovableAudiences}
          onClickTag={onClickTag}
        />
      </div>
    </div>
  )
}
