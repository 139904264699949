import React from 'react'

import { FilterContainer } from '../styles'
import Input from '../../../components/Input'
import { Campaign, MediaPlan } from '../types'
import { getMinDate } from '../../../helpers/date'

type PropsType = {
  startDate: string | undefined
  endDate: string | undefined
  handleChangeStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  current: Campaign | MediaPlan
  today: string
}

const DatesFilters = ({
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  current,
  today
}: PropsType) => {
  return (
    <FilterContainer>
      <Input
        label="Date de début"
        type="date"
        value={
          startDate ?? new Date(current.startDate).toISOString().split('T')[0]
        }
        onChange={handleChangeStartDate}
        width="140px"
        withBorder
        isRequired={false}
        min={current.startDate}
        max={endDate ?? new Date(current.endDate).toISOString().split('T')[0]}
      />
      <Input
        label="Date de fin"
        type="date"
        value={endDate ?? new Date(current.endDate).toISOString().split('T')[0]}
        onChange={handleChangeEndDate}
        width="140px"
        withBorder
        isRequired={false}
        min={
          startDate ?? new Date(current.startDate).toISOString().split('T')[0]
        }
        max={current.endDate}
      />
    </FilterContainer>
  )
}

export default DatesFilters
