import React, { CSSProperties, FC, ReactNode } from 'react'
import styled from '@emotion/styled'

import Colors from '../../constants/colors'
import FontSize from '../../constants/fontSize'
import Margin from '../../constants/margin'
import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'

const StyledSubTitle = styled.h2`
  color: ${Colors.YETIC_BLUE_DARK};

  font-size: ${FontSize.YETIC_SUBTITLE};
  font-family: ${YETIC_FONT_FAMILY};
  font-weight: 700;

  margin-top: ${Margin.m0};
`

export interface SubtitleProps {
  children: ReactNode
  style: CSSProperties
}

const SubTitle: FC<SubtitleProps> = ({ children, style }) => (
  <StyledSubTitle style={style}>{children}</StyledSubTitle>
)

export default SubTitle
