import {
  ReportingAPISectionEnum,
  checkIfReportingAPISectionEnum
} from '../../../types/reporting/apiSection'
import {
  GetMediaPlanReportingErrorEnum,
  GetMediaPlanReportingErrorEnumTranslation
} from '../../../types/error/reporting/getMediaPlanReporting'
import { checkIfDate } from '../../nativeTypes'
import { getMediaPlanHighlightReporting } from './sections/mediaPlan/highlight'
import { getMediaPlanDownloadReporting } from './sections/mediaPlan/download'
import {
  GetMediaPlanAudiencesReportingOutputType,
  GetMediaPlanCampaignsReportingOutputType,
  GetMediaPlanImpactReportingOutputType,
  GetMediaPlanOverallReportingOutputType,
  GetMediaPlanReportingBySectionOutputType
} from '../../../types/reporting/mediaPlan/output'
import {
  getMediaPlanAudienceReporting,
  getMediaPlanCampaignsReporting,
  getMediaPlanDevicesReporting,
  getMediaPlanGlobalReporting,
  getMediaPlanImpactReporting,
  getMediaPlanOverallReporting
} from './sections/mediaPlan'

export type GetMediaPlanReportingBySectionInputType = {
  mediaPlanId: string
  section: ReportingAPISectionEnum
  startDate?: string
  endDate?: string
}

type GetMediaPlanReportingBySectionErrorType = { error: string }

export const getMediaPlanReportingBySection = async ({
  mediaPlanId,
  section,
  startDate,
  endDate
}: GetMediaPlanReportingBySectionInputType): Promise<
  | GetMediaPlanReportingBySectionOutputType
  | GetMediaPlanReportingBySectionErrorType
  | GetMediaPlanAudiencesReportingOutputType
  | GetMediaPlanImpactReportingOutputType
  | GetMediaPlanOverallReportingOutputType
  | GetMediaPlanCampaignsReportingOutputType
> => {
  if (!mediaPlanId) {
    return {
      error:
        GetMediaPlanReportingErrorEnumTranslation[
          GetMediaPlanReportingErrorEnum.MEDIA_PLAN_ID
        ]
    }
  }

  if (
    (startDate && !checkIfDate(startDate)) ||
    (endDate && !checkIfDate(endDate))
  ) {
    return {
      error:
        GetMediaPlanReportingErrorEnumTranslation[
          GetMediaPlanReportingErrorEnum.INVALID_DATE
        ]
    }
  }
  if (!checkIfReportingAPISectionEnum(section)) {
    return {
      error:
        GetMediaPlanReportingErrorEnumTranslation[
          GetMediaPlanReportingErrorEnum.WRONG_SECTION
        ]
    }
  }

  switch (section) {
    case ReportingAPISectionEnum.highlight: {
      const result = await getMediaPlanHighlightReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.download: {
      const result = await getMediaPlanDownloadReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.campaigns: {
      const result = await getMediaPlanCampaignsReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.impact: {
      const result = await getMediaPlanImpactReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.overall: {
      const result = await getMediaPlanOverallReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.audiences: {
      const result = await getMediaPlanAudienceReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.devices: {
      const result = await getMediaPlanDevicesReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingAPISectionEnum.global: {
      const result = await getMediaPlanGlobalReporting({
        mediaPlanId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
  }

  return {
    error:
      GetMediaPlanReportingErrorEnumTranslation[
        GetMediaPlanReportingErrorEnum.UNKNOWN
      ]
  }
}

export const checkIfGetMediaPlanReportingOutputError = (
  value: any
): value is GetMediaPlanReportingBySectionErrorType => {
  return value && value.error && typeof value.error === 'string'
}
