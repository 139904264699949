import React, { ChangeEvent } from 'react'
import styled from '@emotion/styled'

import DefaultText from '../../../../../components/DefaultText'
import Input from '../../../../../components/Input'
import Margin from '../../../../../constants/margin'
import { InputError } from '../../../../../components/Input/Styles'

const DivAge = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-start;
  gap: ${Margin.m7};
  margin-top: ${Margin.m3};
`

type AgeProps = {
  minAge: number
  maxAge: number
  minAgeError?: string
  maxAgeError?: string
  handleMinAgeChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleMaxAgeChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Age = ({
  minAge,
  maxAge,
  minAgeError,
  maxAgeError,
  handleMinAgeChange,
  handleMaxAgeChange
}: AgeProps) => {
  return (
    <>
      <DefaultText
        style={{ marginTop: Margin.m6 }}
      >{`Tranche d'âge*`}</DefaultText>
      <DivAge>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DefaultText size="small">{'Age minimum'}</DefaultText>
          <Input
            type="number"
            min={18}
            max={99}
            value={minAge.toString()}
            onChange={handleMinAgeChange}
            error={minAgeError ? ' ' : undefined}
            withBorder
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DefaultText size="small">{'Age maximum'}</DefaultText>
          <Input
            type="number"
            min={18}
            max={99}
            value={maxAge.toString()}
            onChange={handleMaxAgeChange}
            error={maxAgeError ? ' ' : undefined}
            withBorder
          />
        </div>
      </DivAge>
      <InputError>{minAgeError}</InputError>
      <InputError>{maxAgeError}</InputError>
    </>
  )
}

export default Age
