import { backendURL } from '../../../../../constants/urls'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../../../axios/get'
import { GetMediaPlanDownloadReportingOutputType } from '../../../../../types/reporting/mediaPlan/output'

type GetMediaPlanDownloadReportingInputType = {
  mediaPlanId: string
  startDate?: string
  endDate?: string
}

export const getMediaPlanDownloadReporting = async ({
  mediaPlanId,
  startDate,
  endDate
}: GetMediaPlanDownloadReportingInputType): Promise<GetMediaPlanDownloadReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reporting/media-plan/${mediaPlanId}/sections/download${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetMediaPlanDownloadReporting(response.data.data)) {
        return response.data.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}

export const checkIfGetMediaPlanDownloadReporting = (
  value: any
): value is GetMediaPlanDownloadReportingOutputType => {
  return (
    Array.isArray(value) &&
    value.every(
      (row) =>
        row &&
        row.date &&
        typeof row.date === 'string' &&
        row.clicks !== undefined &&
        typeof row.clicks === 'number' &&
        row.impressions !== undefined &&
        typeof row.impressions === 'number' &&
        row.cost !== undefined &&
        typeof row.cost === 'number' &&
        row.co2 !== undefined &&
        typeof row.co2 === 'number' &&
        row.impact !== undefined &&
        typeof row.impact === 'number'
    )
  )
}
