import { backendURL } from '../../../constants/urls'
import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'

// TODO : manage error
export type UpdateMediaPlanOutputType = {
  mediaPlanId: string
}

export type UpdateMediaPlanErrorType = {
  error: string
}

export type UpdateMediaPlanInput = {
  mediaPlanId: string
  budget: number
  startDate: Date
  endDate: Date
  newAudiences: string[]
}

export const updateMediaPlan = async ({
  mediaPlanId,
  budget,
  startDate,
  endDate,
  newAudiences
}: UpdateMediaPlanInput): Promise<
  UpdateMediaPlanOutputType | UpdateMediaPlanErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/media-plans/${mediaPlanId}`,
      data: {
        budget,
        startDate,
        endDate,
        newAudiences
      }
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (response.data && response.data.mediaPlanId) {
        return {
          mediaPlanId: response.data.mediaPlanId
        }
      }
    }

    return {
      error:
        'Une erreur inconnue est survenue durant la mise à jour du plan média'
    }
  } catch (_) {
    return {
      error:
        'Une erreur inconnue est survenue durant la mise à jour du plan média'
    }
  }
}

export const checkIfUpdateMediaPlan = (
  result: any
): result is UpdateMediaPlanOutputType => {
  return result.mediaPlanId !== undefined
}
