import React from 'react'

import { ContainerBox, Hole } from './styles'

export type PieChartItem = {
  value: number
  color: string
}

export type PieChartProps = {
  total: PieChartItem
  partial: PieChartItem
  size?: number
}

const ProgressionChart = ({ total, partial, size }: PieChartProps) => {
  const progression = total.value ? partial.value / total.value : 0
  const percentage = Math.round(progression * 100 * 10) / 10
  const totalDeg = 360 * (1 - progression)
  const valueDeg = 360 * progression

  return (
    <ContainerBox
      totalDeg={totalDeg}
      valueDeg={valueDeg}
      totalColor={total.color}
      valueColor={partial.color}
      size={size ?? 60}
    >
      <Hole>{total.value ? percentage : '- '}%</Hole>
    </ContainerBox>
  )
}

export default ProgressionChart
