import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const DivLabelledItems = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m3};
  max-height: 60vh;
  overflow-y: auto;
  padding: ${Padding.p4} ${Padding.p3};
`

export const modalStyle: CSSProperties = {
  width: '450px'
}
