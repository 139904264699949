import styled from '@emotion/styled'

// take into account the height of the header
export const LoadingPageContainer = styled.div`
  width: 100%;
  height: calc(100vh - 74px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NonAuthLoadingPageContainer = styled(LoadingPageContainer)`
  height: 100vh;
`
