import React, { ChangeEvent } from 'react'

import Dropdown, { Option } from '../../../../components/Dropdown'
import Input from '../../../../components/Input'
import DefaultText from '../../../../components/DefaultText'
import {
  BudgetChoice,
  BudgetChoicesDescription,
  DivBudget,
  DivBudgetChoices,
  DivContainer,
  textStyle
} from './styles'
import { floorToTwoDecimals } from '../../../../helpers/rounds'

export interface BudgetTypeOption {
  id: number
  label: string
}

export const budgetTypeOptions: BudgetTypeOption[] = [
  { id: 1, label: 'Budget sur la période' },
  { id: 2, label: 'Budget par jour' }
]

export type BudgetProps = {
  handleBudgetTypeChange: (isBudgetOnPeriod: boolean) => void
  budget: number | null
  isBudgetOnPeriod: boolean
  availableBudget: number
  maxBudgetPerPeriod: number
  error?: string
  handleBudgetChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleBudgetInputChange: (amount: string) => void
  totalBudget: number
}

const Budget = ({
  budget,
  isBudgetOnPeriod,
  availableBudget,
  maxBudgetPerPeriod,
  error,
  handleBudgetTypeChange,
  handleBudgetInputChange,
  handleBudgetChange,
  totalBudget
}: BudgetProps) => {
  const handleClick = (percentage: number) => {
    let newBudget =
      maxBudgetPerPeriod && maxBudgetPerPeriod * (percentage / 100)
    newBudget = floorToTwoDecimals(newBudget)
    handleBudgetInputChange(String(newBudget))
  }

  return (
    <>
      <DivContainer>
        <DivBudget>
          <Dropdown
            onChange={(option: Option | null) => {
              handleBudgetTypeChange(
                option == null || option.label === budgetTypeOptions[0].label
              )
            }}
            // TODO: remove slice when handling period budget will be implemented in the backend
            options={budgetTypeOptions.slice(1)}
            value={
              isBudgetOnPeriod ? budgetTypeOptions[0] : budgetTypeOptions[1]
            }
            label={'Type de budget'}
          />
        </DivBudget>
        <DivBudget>
          <Input
            type="devise"
            placeholder="Budget en €"
            label={'Montant du budget'}
            value={budget ? String(budget) : ''}
            onChange={handleBudgetChange}
            error={error}
            withBorder
          />
          <BudgetChoicesDescription>
            Budget total sur la période: {floorToTwoDecimals(totalBudget)}€
          </BudgetChoicesDescription>
          <BudgetChoicesDescription>
            Budget max: {maxBudgetPerPeriod}€
          </BudgetChoicesDescription>
          <DivBudgetChoices>
            <BudgetChoice
              onClick={() => {
                handleClick(25)
              }}
            >
              25 %
            </BudgetChoice>
            <BudgetChoice
              onClick={() => {
                handleClick(50)
              }}
            >
              50 %
            </BudgetChoice>
            <BudgetChoice
              onClick={() => {
                handleClick(75)
              }}
            >
              75 %
            </BudgetChoice>
            <BudgetChoice
              onClick={() => {
                handleClick(100)
              }}
            >
              Max
            </BudgetChoice>
          </DivBudgetChoices>
        </DivBudget>
      </DivContainer>
      {availableBudget !== undefined && (
        <DefaultText style={textStyle}>
          Budget disponible :{' '}
          <b style={{ color: availableBudget < 0 ? 'red' : '' }}>
            {availableBudget} €
          </b>
        </DefaultText>
      )}
    </>
  )
}

export default Budget
