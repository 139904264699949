import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  DivContainer,
  DivForm,
  DivAuth,
  DivBand,
  logoStyle,
  spanStyle,
  titleStyle,
  CheckList,
  CheckListItem,
  listItemStyle,
  Yeti,
  AroundForm
} from './styles'
import Login from './Login'
import Register from './Register'
import Colors from '../../constants/colors'
import YamLogo from '../../components/Icon/yamLogo'
import Title from '../../components/Title'
import DefaultText from '../../components/DefaultText'
import CheckAuth from '../../components/Icon/checkAuth'

const Authentication = () => {
  const [isRegisterForm, setIsRegisterForm] = useState(false)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const page = searchParams.get('page')

    if (page === 'register') {
      setIsRegisterForm(true)
    }
  }, [])

  const handleSwitchFormClick = () => {
    setIsRegisterForm(!isRegisterForm)
  }

  return (
    <DivAuth>
      <DivBand>
        <YamLogo
          color={Colors.YETIC_WHITE}
          secondaryColor={Colors.NEW_YETIC_BLUE_DARK}
          style={logoStyle}
        ></YamLogo>
        <Title type="big" style={titleStyle}>
          Pour des campagnes publicitaires plus{' '}
          <span style={spanStyle}>durables</span> qui font la différence !
        </Title>
        <CheckList>
          <CheckListItem>
            <CheckAuth />
            <DefaultText style={listItemStyle}>
              Intégrez vos valeurs dans vos publicités
            </DefaultText>
          </CheckListItem>
          <CheckListItem>
            <CheckAuth />
            <DefaultText style={listItemStyle}>
              Centralisez la création et la diffusion
            </DefaultText>
          </CheckListItem>
          <CheckListItem>
            <CheckAuth />
            <DefaultText style={listItemStyle}>Gagnez du temps</DefaultText>
          </CheckListItem>
        </CheckList>
        <picture>
          <source srcSet="/images/Yeti/yeti.webp" type="image/webp" />
          <Yeti width={'300px'} src="/images/Yeti/yeti.png" alt="Yeti" />
        </picture>
      </DivBand>
      <AroundForm>
        <DivContainer>
          <DivForm>
            {isRegisterForm ? (
              <Register onLoginClick={handleSwitchFormClick} />
            ) : (
              <Login onRegisterClick={handleSwitchFormClick} />
            )}
          </DivForm>
        </DivContainer>
      </AroundForm>
    </DivAuth>
  )
}

export default Authentication
