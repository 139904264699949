import { backendURL } from '../../../constants/urls'
import { NamedResource, checkIfNamedResourceArray } from '../../../types/common'
// eslint-disable-next-line
import { GetAudienceCreationDataErrorEnum } from '../../../types/error/audience/getAudienceCreationData'
import { LocationData } from '../../../types/mediaPlanAudience'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetAudienceCreationDataOutputType = {
  locations: LocationData[]
  // interests: string[]
  businessSegments: NamedResource[]
  jobFunctions: NamedResource[]
  jobTitles: NamedResource[]
}

export const getAudienceCreationData = async (): Promise<
  | GetAudienceCreationDataOutputType
  | { error: GetAudienceCreationDataErrorEnum }
> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/audience-creation/data`
    })
    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGetAudienceCreationDataOutputType(response.data)) {
        return response.data
      }
      return { error: GetAudienceCreationDataErrorEnum.WRONG_RESPONSE_FORMAT }
    }
    return { error: (response.error as any).data.code }
  } catch (_) {
    return { error: GetAudienceCreationDataErrorEnum.UNKNOWN }
  }
}

export const checkIfGetAudienceCreationDataOutputType = (
  data: any
): data is GetAudienceCreationDataOutputType => {
  return (
    data &&
    data.locations &&
    Array.isArray(data.locations) &&
    data.locations.reduce(
      (prev: boolean, curr: LocationData) =>
        prev && (curr.criteriaId || curr.canonicalName || curr.name),
      true
    ) &&
    // data.interests &&
    // isStringArray(data.interests)
    data.businessSegments &&
    checkIfNamedResourceArray(data.businessSegments) &&
    data.jobFunctions &&
    checkIfNamedResourceArray(data.jobFunctions) &&
    data.jobTitles &&
    checkIfNamedResourceArray(data.jobTitles)
  )
}
