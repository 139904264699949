import styled from '@emotion/styled'

import FontSize from '../../../../../../constants/fontSize'
import Margin from '../../../../../../constants/margin'
import Padding from '../../../../../../constants/padding'

export const AdHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Margin.m5};
`
export const AdImage = styled.img`
  background-color: #e9ebec;
  width: 63px;
  height: 63px;
`
export const AdTitle = styled.div`
  font-weight: 500;
  font-size: 19px;
  flex: 1;
  color: #363636;
  padding: 5px 15px;
`

export const AdDescription = styled.div`
  font-size: 16px;
  margin-bottom: ${Margin.m4};
  color: #666666;
  font-weight: 500;
`

export const AdCompany = styled.div`
  font-size: 14px;
  margin-bottom: ${Margin.m6};
  color: #999999;
  font-weight: 500;
`
export const AdButton = styled.div`
  background-color: #0088ff;
  font-size: ${FontSize.YETIC_TEXT};
  color: white;
  font-weight: 500;
  width: 255px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
`

export const DivContent = styled.div`
  padding: ${Padding.p6};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
