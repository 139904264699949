import { backendURL } from '../../../constants/urls'
import { axiosDelete, checkIsAxiosDeleteOutputSuccess } from '../axios/delete'

// TODO : manage error
export type ArchiveCampaignOutputType = {
  campaignId: string
}

export type ArchiveCampaignErrorType = {
  error: string
}

export type ArchiveCampaignInput = {
  campaignId: string
}

export const archiveCampaign = async ({
  campaignId
}: ArchiveCampaignInput): Promise<
  ArchiveCampaignOutputType | ArchiveCampaignErrorType
> => {
  try {
    const response = await axiosDelete({
      url: `${backendURL}/campaigns/${campaignId}`
    })

    if (checkIsAxiosDeleteOutputSuccess(response)) {
      if (response.data && response.data.campaignId) {
        return {
          campaignId: response.data.campaignId
        }
      }
    }

    return {
      error:
        "Une erreur inconnue est survenue durant l'archivage de la campagne"
    }
  } catch (_) {
    return {
      error:
        "Une erreur inconnue est survenue durant l'archivage de la campagne"
    }
  }
}

export const checkIfArchiveCampaign = (
  result: any
): result is ArchiveCampaignOutputType => {
  return result.campaignId !== undefined
}
