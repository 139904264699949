import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MediaPlanEditType } from './types'
import { FlowEnum } from '../../types/flow'
import { PanelType } from '../../types/templates/panels'
import { SectionType } from '../../types/templates/section'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import Breadcrumb from '../../components/BreadCrumb'
import AudiencePanel from '../MediaPlanCreation/UI/Audience'

type AddToPanelsParams = {
  title: string
  sections: (SectionType | JSX.Element)[]
  isDropdown?: boolean
  disabled?: boolean
}

const SelfEditorMediaPlanEdit = ({
  mediaPlan,
  nameSection,
  audiences,
  selectedAudiences,
  goalsSection,
  periodSection,
  budgetSection,
  nonRemovableAudiences,
  errors,
  flow,
  mediaPlanId,
  isSubmitLoading,
  addSelectedAudience,
  removeAudience,
  openAudienceCreation,
  onClickTag,
  handleSubmitMediaPlan
}: MediaPlanEditType) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)

  const navigate = useNavigate()

  const breadcrumbItems = [
    ...(flow === FlowEnum.view
      ? [
          {
            label: 'Accueil',
            onClick: () => {
              navigate(`/`)
            }
          }
        ]
      : []),
    {
      label: 'Plan publicitaire'
    },
    {
      label: mediaPlan ? mediaPlan.name : '',
      onClick: () => {
        flow === FlowEnum.creation
          ? navigate(`/media-plan/${mediaPlanId && mediaPlanId}/review`)
          : navigate(`/media-plan/${mediaPlanId && mediaPlanId}/details`)
      }
    },
    {
      label: 'Édition du plan publicitaire'
    }
  ]

  const audienceSection = {
    content: (
      <AudiencePanel
        audiences={audiences}
        selectedAudiences={selectedAudiences}
        addSelectedAudience={addSelectedAudience}
        removeAudience={removeAudience}
        nonRemovableAudiences={nonRemovableAudiences}
        textButtonAudienceCreation="Créer un nouveau public cible"
        openAudienceCreation={openAudienceCreation}
        error={hasBeenSubmitted ? errors.audiences : ''}
        onClickTag={onClickTag}
      />
    )
  }

  const panels: PanelType[] = []
  const addToPanels = ({
    title,
    sections,
    isDropdown = false,
    disabled = true
  }: AddToPanelsParams) => {
    panels.push({
      title,
      sections,
      isDropdown,
      disabled
    })
  }

  addToPanels({ title: 'Nom du plan publicitaire', sections: [nameSection] })
  addToPanels({ title: 'Objectifs', sections: [goalsSection] })
  addToPanels({
    title: 'Période de diffusion',
    sections: [periodSection],
    disabled: false
  })
  addToPanels({
    title: 'Public cible',
    sections: [audienceSection],
    disabled: false
  })
  addToPanels({
    title: 'Budget',
    sections: [budgetSection],
    disabled: false
  })

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <CreationPageTemplate
        title={`Édition du plan publicitaire « ${
          mediaPlan ? mediaPlan.name : ''
        } »`}
        subTitle="Mise à jour des caractéristiques globales de votre plan publicitaire"
        panels={panels}
        errors={{
          errors: hasBeenSubmitted
            ? Object.values(errors).filter((e) => e !== '')
            : []
        }}
        validation={{
          wording: 'Valider les modifications',
          isLoading: isSubmitLoading,
          action: () => {
            if (Object.values(errors).filter((e) => e).length < 1) {
              handleSubmitMediaPlan()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled:
            hasBeenSubmitted &&
            Object.values(errors).filter((e) => e).length > 0
        }}
        cancel={{
          wording: 'Retour',
          action: () => {
            flow === FlowEnum.creation
              ? navigate(`/media-plan/${mediaPlanId && mediaPlanId}/review`)
              : navigate(`/media-plan/${mediaPlanId && mediaPlanId}/details`)
          },
          disabled: false
        }}
      />
    </>
  )
}

export default SelfEditorMediaPlanEdit
