import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'

export const buttonStyle: CSSProperties = {
  width: 'fit-content',
  paddingLeft: Padding.p8,
  paddingRight: Padding.p8
}

export const modalStyle: CSSProperties = {
  width: '420px',
  height: '60%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}

export const listItemStyle: CSSProperties = {
  textAlign: 'left',
  marginBottom: 0
}

export const ItemList = styled.ul<{ isEditable?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-left: ${Padding.p6};
  padding-right: ${Padding.p6};
  margin-top: ${Margin.m4};
  margin-bottom: ${(props) => (props.isEditable ? Margin.m4 : 0)};
  border-bottom: ${(props) => (props.isEditable ? '2px solid #cccbcc' : '')};
`

export const EmptyList = styled(ItemList)`
  align-items: center;
  justify-content: center;
`

export const Item = styled.li<{ isLastItem: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(props) => (props.isLastItem ? '' : '1px solid #cccbcc')};
  padding: ${Padding.p3};
`

export const subtitleStyle: CSSProperties = {
  justifySelf: 'flex-end',
  alignSelf: 'flex-start',
  marginBottom: 0
}

export const inputStyle: CSSProperties = {
  marginTop: Margin.m4,
  marginBottom: Margin.m4,
  width: '100%'
}
