import React from 'react'

import Button from '../../../components/Button'
import DefaultText from '../../../components/DefaultText'
import {
  CampaignPlatformChoiceContainer,
  DivPlatforms,
  PlatformName,
  buttonStyle,
  platformLogoStyle,
  platformTextStyle,
  titleStyle,
  DivButton
} from './styles'
import GoogleIcon from '../../../components/Icon/googleLogo'
import MetaIcon from '../../../components/Icon/metaLogo'
import LinkedinIcon from '../../../components/Icon/linkedinLogo'
import { PlatformAPIEnum, PlatformBoolean } from '../../../types/platform'

type CampaignPlatformChoiceProps = {
  onClickPlatform?: (platform: PlatformAPIEnum) => void
  onClickConnection?: (platform: PlatformAPIEnum) => void
  arePlatformsConnected?: PlatformBoolean
}

const CampaignPlatformChoice = ({
  onClickPlatform,
  onClickConnection,
  arePlatformsConnected
}: CampaignPlatformChoiceProps) => {
  const isLinkedinDisabled = process.env.REACT_APP_DISABLE_LINKEDIN === '1'

  return (
    <CampaignPlatformChoiceContainer>
      <DefaultText style={titleStyle}>Ajouter une campagne sur...</DefaultText>
      <DivPlatforms>
        <DivButton>
          <Button
            type="secondary"
            style={buttonStyle}
            onClick={
              onClickPlatform
                ? () => {
                    onClickPlatform(PlatformAPIEnum.GOOGLE)
                  }
                : undefined
            }
          >
            <PlatformName>
              <GoogleIcon style={platformLogoStyle} />
              <DefaultText size="small" style={platformTextStyle}>
                Google
              </DefaultText>
            </PlatformName>
          </Button>
          {arePlatformsConnected && !arePlatformsConnected.google && (
            <Button
              type="textButton"
              onClick={
                onClickConnection
                  ? () => {
                      onClickConnection(PlatformAPIEnum.GOOGLE)
                    }
                  : undefined
              }
            >
              Connexion à Google
            </Button>
          )}
        </DivButton>
        <DivButton>
          <Button
            type="secondary"
            style={buttonStyle}
            onClick={
              onClickPlatform
                ? () => {
                    onClickPlatform(PlatformAPIEnum.LINKEDIN)
                  }
                : undefined
            }
            disabled={isLinkedinDisabled}
          >
            <PlatformName>
              <LinkedinIcon style={platformLogoStyle} />
              <DefaultText size="small" style={platformTextStyle}>
                {isLinkedinDisabled ? 'Bientôt disponible' : 'LinkedIn'}
              </DefaultText>
            </PlatformName>
          </Button>
          {arePlatformsConnected &&
            !arePlatformsConnected.linkedin &&
            !isLinkedinDisabled && (
              <Button
                type="textButton"
                onClick={
                  onClickConnection
                    ? () => {
                        onClickConnection(PlatformAPIEnum.LINKEDIN)
                      }
                    : undefined
                }
              >
                Connexion à LinkedIn
              </Button>
            )}
        </DivButton>
        <DivButton>
          <Button type="secondary" style={buttonStyle} disabled>
            <PlatformName>
              <MetaIcon style={platformLogoStyle} />
              Bientôt disponible
            </PlatformName>
          </Button>
        </DivButton>
      </DivPlatforms>
    </CampaignPlatformChoiceContainer>
  )
}

export default CampaignPlatformChoice
