export enum SyncCampaignErrorEnum {
  UNKNOWN = 'UNKNOWN',
  AUTH_FAILED = 'AUTH_FAILED',
  MISSING_ID = 'MISSING_ID',
  NOT_FOUND = 'NOT_FOUND',
  CAMPAIGN_ALREADY_SYNCED = 'CAMPAIGN_ALREADY_SYNCED',
  GOOGLE_API_DISABLED = 'GOOGLE_API_DISABLED',
  PLATFORM_SYNC_NOT_SUPPORTED = 'PLATFORM_SYNC_NOT_SUPPORTED'
}

export const SyncCampaignErrorTranslation = {
  [SyncCampaignErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [SyncCampaignErrorEnum.AUTH_FAILED]:
    "L'authentification a échoué. Veuillez vous reconnecter",
  [SyncCampaignErrorEnum.MISSING_ID]:
    "L'identifiant de la campagne n'a pas été reçu",
  [SyncCampaignErrorEnum.NOT_FOUND]: 'La campagne n’a pas été trouvée',
  [SyncCampaignErrorEnum.CAMPAIGN_ALREADY_SYNCED]:
    'La campagne est déjà synchronisée avec la plateforme',
  [SyncCampaignErrorEnum.GOOGLE_API_DISABLED]: 'L’API Google n’est pas activée',
  [SyncCampaignErrorEnum.PLATFORM_SYNC_NOT_SUPPORTED]:
    'La synchronisation avec la plateforme n’est pas encore disponible'
}

export const checkIfSyncCampaignErrorCodeValid = (
  errorCode: string
): errorCode is SyncCampaignErrorEnum => {
  return Object.keys(SyncCampaignErrorEnum).includes(errorCode)
}
