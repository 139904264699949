import { backendURL } from '../../../constants/urls'
import { DevicesEnum } from '../../../types/campaign/devices'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'

export type GetCampaignCO2DetailsOutputType = {
  co2: number
  details: {
    [key in DevicesEnum]: {
      value: number
      portion: number
    }
  }
  weight: {
    images: number[]
    logo: number
  }
  network: number
}

export type GetCampaignCO2DetailsInput = {
  campaignId: string
}

// TODO response.data includes ads, which will be {format: string, id: string, name: string}[]
export const getCampaignCO2Details = async ({
  campaignId
}: GetCampaignCO2DetailsInput): Promise<GetCampaignCO2DetailsOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/campaigns/${campaignId}/co2-details`
    })
    if (checkIsAxiosGetOutputSuccess(response) && response.data) {
      if (checkIfCampaignCO2DetailsOutputType(response.data)) {
        return response.data
      }
    }
    return null
  } catch (_) {
    return null
  }
}

const checkIfCampaignCO2DetailsOutputType = (
  result: any
): result is GetCampaignCO2DetailsOutputType => {
  return (
    result.co2 &&
    result.details &&
    Object.keys(result.details).every((key: any) =>
      Object.values(DevicesEnum).includes(key)
    ) &&
    Object.values(result.details).every(
      (value: any) =>
        value.value !== undefined &&
        value.portion !== undefined &&
        typeof value.value === 'number' &&
        typeof value.portion === 'number'
    )
  )
}
