import { axiosPut, checkIsAxiosPutOutputSuccess } from '../axios/put'
import { backendURL } from '../../../constants/urls'
import {
  SyncCampaignErrorEnum,
  SyncCampaignErrorTranslation,
  checkIfSyncCampaignErrorCodeValid
} from '../../../types/error/campaign/sync'

export type SyncCampaignOutputType = {
  campaignId: string
}

export type SyncCampaignErrorType = {
  error: string
}

export type SyncCampaignInput = {
  campaignId: string
  withoutAdsSync?: boolean
}

export const syncCampaign = async ({
  campaignId,
  withoutAdsSync = false
}: SyncCampaignInput): Promise<
  SyncCampaignOutputType | SyncCampaignErrorType
> => {
  try {
    const response = await axiosPut({
      url: `${backendURL}/campaigns/${campaignId}/sync${
        withoutAdsSync ? '?withoutAdsSync=1' : ''
      }`,
      data: {}
    })

    if (checkIsAxiosPutOutputSuccess(response)) {
      if (checkIfSyncCampaign(response.data)) {
        return response.data
      }
    }

    const errorCode =
      (response as any)?.error.data?.code ?? (response as any)?.error.code
    if (errorCode && checkIfSyncCampaignErrorCodeValid(errorCode)) {
      return { error: SyncCampaignErrorTranslation[errorCode] }
    }

    return {
      error: SyncCampaignErrorTranslation[SyncCampaignErrorEnum.UNKNOWN]
    }
  } catch (_) {
    return {
      error: SyncCampaignErrorTranslation[SyncCampaignErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfSyncCampaign = (
  result: any
): result is SyncCampaignOutputType => {
  return result && result.campaignId && typeof result.campaignId === 'string'
}
