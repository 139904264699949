import React from 'react'

import formatImpactData from './helpers/formatData'
import { ImpactTabContainer } from './styles'
import BarChart from '../../../../components/Chart/BarChart'
import { TableContainer, chartGap } from '../../styles'
import { GetCampaignImpactReportingOutputType } from '../../../../types/reporting/campaign/output'
import Table from '../../../../components/Table'
import { AlignEnum, SortEnum } from '../../../../types/common'
import StatCard from '../../../../components/StatCard'
import LeafIcon from '../../../../components/Icon/leaf'
import Colors from '../../../../constants/colors'
import { formatNumber } from '../../../../helpers/rounds'

type PropsType = {
  reportingData: GetCampaignImpactReportingOutputType
  contentWidth: number
}

const ImpactTab = ({ reportingData, contentWidth }: PropsType) => {
  const {
    impactBarChart,
    impactTableDataCO2,
    impactBarChartCO2PerAge,
    impactTableDataCO2PerAge,
    impactTableDataCO2PerAd,
    impactBarChartCO2PerAd,
    co2PerCost
  } = formatImpactData(reportingData)

  const tableColumnsCO2PerDevice = [
    {
      name: 'device',
      label: 'Appareil',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    },
    {
      name: 'co2',
      label: 'CO₂',
      unit: 'g',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    }
  ]

  const tableColumnsCO2PerAge = [
    {
      name: 'ageRange',
      label: `Tranche d'âge`,
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    },
    {
      name: 'co2',
      label: 'CO₂',
      unit: 'g',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    }
  ]

  const tableColumnsCO2PerAd = [
    {
      name: 'ad',
      label: `Annonce`,
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    },
    {
      name: 'co2',
      label: 'CO₂',
      unit: 'g',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    }
  ]

  const defaultTableSort = {
    name: 'co2',
    order: SortEnum.DESC
  }

  const chartWidth =
    screen.width > 1300 ? (contentWidth - chartGap * 2) / 2 : contentWidth

  return (
    <>
      <ImpactTabContainer>
        <BarChart
          data={impactBarChart}
          title={'Emissions de CO₂ par appareil'}
          width={chartWidth}
          horizontal
        />
        <BarChart
          data={impactBarChartCO2PerAge}
          title={`Emissions de CO₂ par tranche d'âge`}
          width={chartWidth}
        />
        <BarChart
          data={impactBarChartCO2PerAd}
          title={'Emissions de CO₂ par annonce'}
          width={chartWidth}
        />
        <StatCard
          icon={<LeafIcon color={Colors.YETIC_GREEN_DARK} height={'100px'} />}
          value={formatNumber(co2PerCost)}
          unit={'g'}
          width={chartWidth}
          height={'410px'}
          color={Colors.NEW_YETIC_CO2}
          title={'Emissions de CO₂ par euro dépensé'}
        />
        <TableContainer>
          <Table
            title={'Emissions de CO₂ par appareil'}
            columns={tableColumnsCO2PerDevice}
            elements={impactTableDataCO2}
            sort={defaultTableSort}
            download
          />
        </TableContainer>
        <TableContainer>
          <Table
            title={`Emissions de CO₂ par tranche d'âge`}
            columns={tableColumnsCO2PerAge}
            elements={impactTableDataCO2PerAge}
            sort={defaultTableSort}
            download
          />
        </TableContainer>
        <TableContainer>
          <Table
            title={'Emissions de CO₂ par annonce'}
            columns={tableColumnsCO2PerAd}
            elements={impactTableDataCO2PerAd}
            sort={defaultTableSort}
            download
          />
        </TableContainer>
      </ImpactTabContainer>
    </>
  )
}

export default ImpactTab
