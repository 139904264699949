import React, { useState, useEffect } from 'react'
import {
  GetTableCampaignsType,
  getTableData,
  MediaPlanDetailsDataTable
} from './helpers/queries/getTableData'
import { getAmountOfPages } from './helpers/getAmountOfPages'
import TableList from '../../components/TableList/index'
import { PaginationContainer, PaginationItem } from './styles'

type CampaignData = GetTableCampaignsType & { mediaPlanId: string }
type MediaPlanData =
  | {
      id: string
      name: string
      co2: number
      campaignsCount?: number
      skip?: number
      limit?: number
    }[]
  | null

const limit = 5

const Tutorial = () => {
  const [mediaPlans, setMediaPlans] = useState<MediaPlanData | null>(null)
  const [campaigns, setCampaigns] = useState<CampaignData[] | null>(null)
  const [clickedMediaPlanId, setClickedMediaPlanId] = useState<string | null>(
    null
  )
  const [mediaPlansCount, setMediaPlansCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [activePage, setActivePage] = useState<number>(1)

  useEffect(() => {
    ;(async () => {
      const data = await getTableData(skip, limit)

      if (data == null) {
        return
      }

      const mediaPlans = data.mediaPlans
      const count = data.count
      let campaigns: CampaignData[] = []

      for (let i = 0; i < mediaPlans.length; i++) {
        campaigns = campaigns.concat(
          mediaPlans[i].campaigns.map((campaign) => ({
            ...campaign,
            mediaPlanId: mediaPlans[i].id
          }))
        )
      }

      const mediaPlansWithoutCampaigns = mediaPlans.map((mediaPlan) => ({
        id: mediaPlan.id,
        name: mediaPlan.name,
        co2: mediaPlan.co2,
        campaignsCount: mediaPlan.campaigns.length
      }))

      setMediaPlans(mediaPlansWithoutCampaigns)
      setCampaigns(campaigns)
      setMediaPlansCount(count)
    })()
  }, [skip])

  const amountOfPages = getAmountOfPages(limit, mediaPlansCount)
  const pageIndexes = [...new Array(amountOfPages)].map((_, i) => i + 1)

  const handleOnRowClick = (id: string) => {
    if (id !== null) {
      if (clickedMediaPlanId === id) {
        setClickedMediaPlanId(null)
      } else {
        setClickedMediaPlanId(id)
      }
    }
  }

  const handleClick = async (page: number) => {
    const newSkip = page === 0 ? 0 : page * 5 - 5

    setSkip(newSkip)
    setActivePage(page)
  }

  // TODO it's working only if we have at least
  // one campaign in one of the media plan
  if (mediaPlans == null || campaigns == null) {
    return <div>Loading</div>
  }

  const selectedMediaPlan = mediaPlans.find(
    (mediaPlan) => mediaPlan.id === clickedMediaPlanId
  )

  let items: MediaPlanDetailsDataTable[] = mediaPlans
    ? mediaPlans.map((m) => {
        return {
          id: m.id,
          row: [
            m.name,
            m.co2.toString(),
            m.campaignsCount ? m.campaignsCount.toString() : ''
          ],
          details: {
            column1: [],
            column2: []
          },
          subItems: []
        }
      })
    : []

  const selectedCampaigns =
    clickedMediaPlanId == null
      ? []
      : campaigns.filter(
          (campaign) => campaign.mediaPlanId === clickedMediaPlanId
        )

  if (selectedCampaigns.length > 0) {
    items = items.map((item) => {
      const cardDetails: {
        column1: { label: string; value: string }[]
        column2: { label: string; value: string }[]
      } = { column1: [], column2: [] }

      if (item.id === clickedMediaPlanId) {
        cardDetails.column1 = selectedCampaigns.flatMap((campaign) => ({
          label: 'Nom',
          value: campaign.name
        }))
        cardDetails.column2 = selectedCampaigns.flatMap((campaign) => ({
          label: 'CO2',
          value: campaign.co2.toString()
        }))
      }
      return {
        ...item,
        details: cardDetails
      }
    })
  }

  const mediaPlansListData = {
    titles: ['Nom', 'CO2', 'Campagnes'],
    widths: ['33%', '33%', '33%'],
    items
  }

  return (
    <div>
      <TableList
        data={mediaPlansListData}
        noRowOpen={
          selectedMediaPlan?.campaignsCount !== 0 ? selectedMediaPlan?.id : ''
        }
        itemsByPage={5}
        totalItems={amountOfPages}
        currentPage={1}
        handleChangePage={() => {}}
        handleOnRowClick={handleOnRowClick}
        title="Liste des plans média"
      />
      <PaginationContainer>
        {pageIndexes.length > 1 &&
          pageIndexes.map((page) => (
            <PaginationItem
              isActive={activePage === page}
              key={page}
              onClick={() => {
                handleClick(page)
              }}
            >
              {page}
            </PaginationItem>
          ))}
      </PaginationContainer>
    </div>
  )
}

export default Tutorial
