import axios from 'axios'

import { backendURL } from '../../../../constants/urls'
import {
  CreatePasswordErrorEnum,
  CreatePasswordErrorTranslation
} from '../../../../types/error/auth/password'
import { getErrorCode } from '../../../getErrorCode'

type HandleResetPasswordParams = {
  token: string
  email: string
  password: string
}

export const handleResetPassword = async ({
  token,
  email,
  password
}: HandleResetPasswordParams): Promise<
  { userId: string } | { error: string }
> => {
  try {
    const url = `${backendURL}/password/${token}/reset`
    const response = await axios.put(url, { email, password })

    if (checkIfHandleResetPasswordResult(response.data)) {
      return response.data
    }

    return { error: CreatePasswordErrorEnum.UNKNOWN }
  } catch (error) {
    const errorCode = getErrorCode(error) ?? null

    if (
      errorCode &&
      Object.values<string>(CreatePasswordErrorEnum).includes(errorCode)
    ) {
      return {
        error:
          CreatePasswordErrorTranslation[errorCode as CreatePasswordErrorEnum]
      }
    }

    return {
      error: CreatePasswordErrorTranslation[CreatePasswordErrorEnum.UNKNOWN]
    }
  }
}

export const checkIfHandleResetPasswordResult = (
  data: any
): data is { userId: string } => {
  return data && data.userId && typeof data.userId === 'string'
}
