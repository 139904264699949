export const googleLogoRatio = 1
export const googleRectangleRatio = 1.91
export const googleSquareRatio = 1

export const googleLogoMinWidth = 128
export const googleLogoMinHeight = 128
export const googleRectangleMinWidth = 600
export const googleRectangleMinHeight = 314
export const googleSquareMinWidth = 300
export const googleSquareMinHeight = 300

export const googleMaxSize = 1000000

export const linkedinSquareRatio = 1
export const linkedinRectangleRatio = 1.91

export const linkedinMinWidth = 400
export const linkedinMaxWidth = 2400

export const linkedinMaxSize = 1000000
