import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ReviewPageTemplate from '../../../templates/ReviewPageTemplate'
import { MediaPlanReviewDataType } from '../../../types/pages/mediaPlanReview'
import {
  checkIfSendToValidateMediaPlan,
  sendToValidateMediaPlan
} from '../../../helpers/queries/mediaPlan/sendToValidateMediaPlan'
import { StrategyEnum } from '../../../types/strategy'
import { ContainerBlock } from '../../../templates/ReviewPageTemplate/ReviewContent'
import ModalValidation from '../Modals/ModalValidation'
import { checkAdminUserDataNotNull } from '../../../types/mediaPlan/adminUserData'
import handleExportDownload from '../../../helpers/handleExportDownload'

type EditorActionButtonsProps = {
  mediaPlanId: string
  reviewData: MediaPlanReviewDataType
  tipsEcology: JSX.Element
  containerBlock: ContainerBlock
  handleViewSubItem: (id: string) => void
}

const EditorPaymentContent = ({
  mediaPlanId,
  reviewData,
  tipsEcology,
  containerBlock,
  handleViewSubItem
}: EditorActionButtonsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sendToValidateMessage, setSendToValidateMessage] = useState('')
  const [sendToValidateError, setSendToValidateError] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (isSubmitLoading) {
      if (checkAdminUserDataNotNull(advertiserData)) {
        ;(async function () {
          const result = await sendToValidateMediaPlan({
            mediaPlanId,
            mediaPlanName: reviewData.mediaPlan.name,
            receiverEmail: advertiserData.email
          })
          if (checkIfSendToValidateMediaPlan(result)) {
            setIsModalOpen(true)
            setSendToValidateMessage('Votre demande a bien été envoyée.')
          } else {
            setSendToValidateError(result.error)
            setSendToValidateMessage('')
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  const validation = {
    label: 'Envoyer pour validation',
    action: () => {
      setIsModalOpen(true)
    },
    disabled: false
  }

  const cancel = {
    label: 'Retour au plan média',
    action: () => {
      navigate(`/media-plan/${reviewData.navigation.mediaPlan.id}/review`)
    },
    disabled: false
  }

  const tipsInfo = `Vous devez envoyer une demande de validation à votre client afin qu'il puisse examiner puis valider l'intégralité du plan média.
  Une fois validé, le plan média pourra être diffusé sur le ou les plateforme(s) concernée(s).`

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const advertiserData = reviewData.adminUserData

  const handleValidation = () => {
    setIsSubmitLoading(true)
  }

  const download = {
    label: 'Télécharger le plan média en PDF',
    action: () => {
      handleExportDownload(mediaPlanId)
    },
    disabled: false
  }

  return (
    <>
      <ReviewPageTemplate
        name={reviewData.mediaPlan.name}
        title={'Validation du plan média'}
        tipsEcology={reviewData.campaigns.length > 0 ? tipsEcology : null}
        tipsInfo={tipsInfo}
        type={StrategyEnum.MEDIA_PLAN}
        validation={validation}
        download={download}
        cancel={cancel}
        container={containerBlock}
        handleViewMore={handleViewSubItem}
        isEditor={true}
      />
      {isModalOpen && advertiserData && (
        <ModalValidation
          handleCloseModal={handleCloseModal}
          handleConfirm={() => {
            handleValidation()
          }}
          mediaPlanName={reviewData.mediaPlan.name}
          companyName={reviewData.navigation.company.name}
          adminUserData={advertiserData}
          message={sendToValidateMessage}
          error={sendToValidateError}
          isLoading={isSubmitLoading}
        />
      )}
    </>
  )
}

export default EditorPaymentContent
