import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'

import EditorMediaPlanCreation from './editor'
import SelfEditorMediaPlanCreation from './selfEditor'
import ErrorPage from '../ErrorPage'
import { GetCompanyAudiencesOutput } from '../../helpers/queries/audiences/getCompanyAudiences'
import Widget from '../parts/Widget'

export type ExistingAudiences = GetCompanyAudiencesOutput

const MediaPlanCreation = () => {
  const { isEditor, isSelfEditor } = useContext(AppContext)

  return (
    <Widget>
      {isEditor ? (
        <EditorMediaPlanCreation />
      ) : isSelfEditor ? (
        <SelfEditorMediaPlanCreation />
      ) : (
        <ErrorPage />
      )}
    </Widget>
  )
}

export default MediaPlanCreation
