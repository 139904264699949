import { backendURL } from '../../../constants/urls'
import {
  checkIfExportOutput,
  checkIfMediaPlanDetails
} from '../../../types/mediaPlan/mediaPlan'
import { axiosGet, checkIsAxiosGetOutputSuccess } from '../axios/get'
import { GetMediaPlanInput } from './getMediaPlanById'

export const exportMediaPlanById = async ({
  mediaPlanId
}: GetMediaPlanInput): Promise<{ html: string } | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/media-plans/${mediaPlanId}/export`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfExportOutput(response.data)) {
        return response.data
      }
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}
