import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'

export const DivContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100vw;
  flex-direction: column;
`

export const logoStyle = {
  width: '200px',
  marginBottom: Margin.m8
}

export const Content = styled.div`
  display: flex;
  max-width: 900px;
  flex-direction: column;
  background-color: ${Colors.NEW_YETIC_GREY};
  border: 1px solid #8893a4;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: ${Padding.p11};
  gap: 7vh;
`

export const messageStyle: CSSProperties = {
  textAlign: 'center'
}
