import { isValidElement } from 'react'

export type SectionType = {
  title?: string
  cornerAction?: JSX.Element
  description?: string
  content: JSX.Element
  disabled?: boolean
  titleTooltip?: string | JSX.Element
}

export const checkIfSectionType = (element: any): element is SectionType => {
  return (
    element &&
    ((element.title !== undefined && typeof element.title === 'string') ||
      element.title === undefined) &&
    ((element.cornerAction !== undefined &&
      isValidElement(element.cornerAction)) ||
      element.cornerAction === undefined) &&
    ((element.description !== undefined &&
      typeof element.description === 'string') ||
      element.description === undefined) &&
    element.content !== undefined &&
    isValidElement(element.content) &&
    ((element.disabled !== undefined &&
      typeof element.disabled === 'boolean') ||
      element.disabled === undefined) &&
    ((element.titleTooltip !== undefined &&
      (typeof element.titleTooltip === 'string' ||
        isValidElement(element.titleTooltip))) ||
      element.titleTooltip === undefined)
  )
}
