import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import { YETIC_FONT_FAMILY } from '../../../../constants/fontFamily'
import { CSSProperties } from 'react'

export const PanelContainer = styled.div`
  box-shadow: 0px 0px 6px 1px #e7e9ed;
  padding: ${Padding.p5} ${Padding.p6};
  margin: ${Margin.m7} 0;
  margin-top: 0;
  border-radius: 10px;
  color: ${Colors.YETIC_BLUE_DARK};
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled.div`
  margin: ${Margin.m5} 0 ${Margin.m3};
`

export const ToggleButton = styled.button<{ isExpanded: boolean }>`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: ${({ isExpanded }) => (isExpanded ? Margin.m6 : 0)};
  color: ${Colors.YETIC_BLUE_DARK};
  font-size: ${FontSize.YETIC_TEXT};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Span = styled.span`
  margin-left: ${Margin.m5};
  font-weight: 600;
  font-family: ${YETIC_FONT_FAMILY};
`

export const CheckboxSpan = styled.span`
  margin-left: ${Margin.m3};
  font-weight: 600;
  font-size: ${FontSize.YETIC_TEXT};
  font-family: ${YETIC_FONT_FAMILY};
`

export const checkboxContainerStyle: CSSProperties = {
  marginBottom: Margin.m6
}

export const DivTitlePannel = styled.div<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`
export const DivMap = styled.div``

export const TipContainer = styled.div`
  margin-top: ${Margin.m3};
  font-size: ${FontSize.YETIC_TEXT};
  font-family: ${YETIC_FONT_FAMILY};
  color: ${Colors.YETIC_BLUE_DARK};
  margin-bottom: ${Margin.m7};
`
