import React, { ChangeEvent, useState } from 'react'

import { FormContainer, SummaryTextContainer, modalStyle } from './styles'
import { ErrorsType } from '..'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import DefaultText from '../../../components/DefaultText'
import Input from '../../../components/Input'
import { checkIfEmptyErrors } from '../../../helpers/checkers'
import { InputError } from '../../../components/Input/Styles'

type GoogleConnectionFormProps = {
  handleSubmit: () => void
  onClose: () => void
  email: string
  setEmail: (email: string) => void
  formErrors: ErrorsType
  isSubmitLoading: boolean
  hasBeenSubmitted: boolean
  submitValidationMessage?: string
  submitError?: string
}

const GoogleConnectionForm = ({
  handleSubmit,
  onClose,
  email,
  setEmail,
  formErrors,
  isSubmitLoading,
  hasBeenSubmitted,
  submitValidationMessage,
  submitError
}: GoogleConnectionFormProps) => {
  const platformText = 'Google Ads'
  const title = `Connectez-vous à ${platformText}`

  const paragraphs = [
    `Afin de publier vos contenus sur ${platformText}, votre compte ${platformText} devra être relié au YAM.`,
    "Veuillez renseigner votre email afin que l'on vous recontacte suite à votre demande de connexion."
  ]

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  return (
    <ConfirmationModal
      title={title}
      onClose={onClose}
      onClickConfirm={handleSubmit}
      onClickReject={onClose}
      textConfirm="Demander la connexion"
      textReject={submitValidationMessage ? 'Fermer' : 'Annuler'}
      isConfirmLoading={isSubmitLoading}
      style={modalStyle}
      confirmHidden={!!submitValidationMessage}
    >
      <SummaryTextContainer>
        <DefaultText size="medium" bold>
          {paragraphs[0]}
        </DefaultText>
        <DefaultText size="medium">{paragraphs[1]}</DefaultText>
      </SummaryTextContainer>
      <FormContainer
        hasBottomMessage={
          (hasBeenSubmitted && checkIfEmptyErrors(formErrors)) ||
          !!submitValidationMessage ||
          !!submitError
        }
      >
        <Input
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          withBorder={true}
          onChange={handleEmailChange}
          error={hasBeenSubmitted ? formErrors.email : undefined}
        />
      </FormContainer>
      {submitValidationMessage && (
        <DefaultText size="medium" bold style={{ textAlign: 'center' }}>
          {submitValidationMessage}
        </DefaultText>
      )}
      {submitError && (
        <InputError style={{ textAlign: 'center' }}>{submitError}</InputError>
      )}
    </ConfirmationModal>
  )
}

export default GoogleConnectionForm
