export enum Gender {
  ALL = 'all',
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
}

export function isGender(value: any): value is Gender {
  return (
    typeof value === 'string' &&
    ['all', 'male', 'female', 'other'].includes(value)
  )
}

export const GenderTranslation = {
  [Gender.ALL]: 'Tous',
  [Gender.MALE]: 'Homme',
  [Gender.FEMALE]: 'Femme',
  [Gender.OTHER]: 'Autre'
}
