import React from 'react'

import BoxInfo, { BoxInfoProps } from '../BoxInfo'
import { ContainerBox } from '../../styles'
import Dropdown, { Option } from '../../../../components/Dropdown'
import { DropdownContainer } from './styles'

export type KpiFilterType = {
  label: string
  value: string
}

type KPIProps = {
  selectedFilter: string
  setSelectedFilter: (newFilter: string) => void
  boxInfos: BoxInfoProps[]
  handleClick: (daterange: string) => Promise<void>
}

const KPI = ({
  selectedFilter,
  setSelectedFilter,
  boxInfos,
  handleClick
}: KPIProps) => {
  const kpiFilterOptions = [
    { label: 'Année précédente', id: 'PREVIOUS_YEAR' },
    { label: 'Année en cours', id: 'CURRENT_YEAR' },
    { label: 'Mois précédent', id: 'PREVIOUS_MONTH' },
    { label: 'Mois en cours', id: 'CURRENT_MONTH' }
  ]

  const handleDropdownChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      setSelectedFilter(selectedOption.id.toString())
      handleClick(selectedOption.id.toString())
    }
  }

  return (
    <>
      <DropdownContainer>
        <Dropdown
          width="200px"
          options={kpiFilterOptions}
          onChange={handleDropdownChange}
          value={kpiFilterOptions.find(
            (option) => option.id === selectedFilter
          )}
        />
      </DropdownContainer>
      <ContainerBox>
        {boxInfos.map((boxData) => (
          <BoxInfo
            key={Math.random()}
            title={boxData.title}
            titleInfo={boxData.titleInfo}
            percent={boxData.percent}
            content={boxData.content}
            icon={boxData.icon}
            style={boxData.style}
          />
        ))}
      </ContainerBox>
    </>
  )
}

export default KPI
