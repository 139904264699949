import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const PlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Margin.m4};
  padding: ${Padding.p5} ${Padding.p6};
`
