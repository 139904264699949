import React from 'react'

import { IconType } from '../../types/icon'

const SortIcon = ({
  height = '20px',
  width = '20px',
  style,
  color,
  handleClick
}: IconType) => {
  return (
    <svg
      onClick={handleClick}
      stroke={color ?? '#000000'}
      fill="none"
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M4 16L13 16" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 11H13" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 6L13 6" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M17 4L17 20L20 16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SortIcon
