import axios from 'axios'

import { UserActivationErrorTranslation } from './../../../types/error/auth/userActivation'
import { backendURL } from '../../../constants/urls'
import {
  UserActivationErrorEnum,
  checkIfUserActivationErrorCodeValid
} from '../../../types/error/auth/userActivation'

export const handleUserActivation = async (
  token: string
): Promise<{ userId: string } | { error: string }> => {
  try {
    const url = `${backendURL}/user/activation/${token}`
    const response = await axios.post(url)

    if (checkIfHandleUserActivationResult(response.data)) {
      return response.data
    }

    return { error: UserActivationErrorEnum.UNKNOWN }
  } catch (error) {
    const errorCode =
      (error as any)?.response?.data?.code ?? (error as any)?.response?.code
    if (errorCode && checkIfUserActivationErrorCodeValid(errorCode)) {
      return { error: UserActivationErrorTranslation[errorCode] }
    }
    return { error: UserActivationErrorEnum.UNKNOWN }
  }
}

export const checkIfHandleUserActivationResult = (
  data: any
): data is { userId: string } => {
  return data && typeof data.userId === 'string'
}
