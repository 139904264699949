export enum UserActivationErrorEnum {
  UNKNOWN = 'UNKNOWN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  MISSING_PARAMS = 'MISSING_PARAMS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  FAILED_STATUS_UPDATE = 'FAILED_STATUS_UPDATE',
  FAILED_UPDATED_AT_UPDATE = 'FAILED_UPDATED_AT_UPDATE',
  EMAIL_ALREADY_VALIDATED = 'EMAIL_ALREADY_VALIDATED'
}

export const UserActivationErrorTranslation = {
  [UserActivationErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [UserActivationErrorEnum.USER_NOT_FOUND]: "L'utilisateur n'existe pas",
  [UserActivationErrorEnum.MISSING_PARAMS]:
    'Adresse e-mail et mot de passe obligatoires',
  [UserActivationErrorEnum.INTERNAL_SERVER_ERROR]: `Une erreur serveur est survenue pendant l'activation du compte`,
  [UserActivationErrorEnum.TOKEN_EXPIRED]: 'Le token a expiré',
  [UserActivationErrorEnum.FAILED_STATUS_UPDATE]:
    'La mise à jour du status a échoué',
  [UserActivationErrorEnum.FAILED_UPDATED_AT_UPDATE]: `La mise à jour de la date de dernière mise à jour a échoué.`,
  [UserActivationErrorEnum.EMAIL_ALREADY_VALIDATED]: `Cette adresse e-mail a déjà été validée.`
}

export const checkIfUserActivationErrorCodeValid = (
  errorCode: string
): errorCode is UserActivationErrorEnum => {
  return Object.keys(UserActivationErrorEnum).includes(errorCode)
}
