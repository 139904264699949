import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import handleLogout from '../../../helpers/queries/auth/logout'
import { AppContext } from '../../../contexts/AppContext'
import { clearAllCookies } from '../../../helpers/cookies'
import NonAuthLoadingPage from '../../LoadingPage/NonAuthLoadingPage'

const Logout = () => {
  const navigate = useNavigate()
  const { setUser } = useContext(AppContext)

  useEffect(() => {
    clearAllCookies()
    ;(async () => {
      await handleLogout()
      navigate('/auth')
      setUser(null)
    })()
  }, [])

  return <NonAuthLoadingPage />
}

export default Logout
