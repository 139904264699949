import React, { useState } from 'react'

import { Wrapper, widgetBoxButtonComingSoonStyle } from './styles'
import DefaultText from '../../DefaultText'
import Colors from '../../../constants/colors'

type PropsType = {
  Icon: JSX.Element
  label: string
  comingSoon?: boolean
  handleClick: () => void
  width?: string
  height?: string
}

const BoxButton = ({
  Icon,
  label,
  comingSoon,
  handleClick,
  width,
  height
}: PropsType) => {
  const [isHovered, setIsHovered] = useState(false)

  const disabled = comingSoon

  const handleMouseOver = () => {
    !isHovered && setIsHovered(true)
  }

  const handleMouseOut = () => {
    isHovered && setIsHovered(false)
  }

  const handleClickIfNotDisabled = () => {
    if (!disabled) {
      handleClick()
    }
  }

  return (
    <Wrapper
      isHovered={isHovered}
      disabled={disabled}
      width={width}
      height={height}
      onClick={handleClickIfNotDisabled}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      {Icon}
      <DefaultText
        style={{
          textAlign: 'center',
          transition: '.2s',
          marginBottom: 0,
          color:
            isHovered && !disabled ? Colors.YETIC_WHITE : Colors.YETIC_BLUE_DARK
        }}
        bold
        size="medium"
      >
        {label}
      </DefaultText>
      {disabled && (
        <DefaultText style={widgetBoxButtonComingSoonStyle} size="small">
          À venir
        </DefaultText>
      )}
    </Wrapper>
  )
}

export default BoxButton
