import React from 'react'

import {
  CO2,
  CO2Info,
  CO2Number,
  Column1,
  Column2,
  CtaContainer,
  SummaryContainer,
  SummaryContent,
  SummaryHeader,
  SummaryTitle,
  SummaryTitleIcon,
  SummaryType,
  Tooltip
} from './styles'
import LeafIcon from '../../../components/Icon/leaf'
import Colors from '../../../constants/colors'
import EditIcon from '../../../components/Icon/edit'
import { getCO2andUnit } from '../../../helpers/getCO2andUnit'
import LabelledItem from '../../../components/LabelledItem'
import Button, { ButtonPropsType } from '../../../components/Button'

export type SummaryProps = {
  title: string
  type: string
  co2InG?: number | null
  onCO2TooltipClick?: () => void
  column1: JSX.Element
  column2?: JSX.Element
  onEdit?: () => void
  cta?: {
    label: string
    action: () => void
    btnType: ButtonPropsType['type']
  }[]
}

const Summary = ({
  title,
  type,
  co2InG,
  onCO2TooltipClick,
  column1,
  column2,
  onEdit,
  cta
}: SummaryProps) => {
  const { co2, co2Unit } =
    co2InG !== null && co2InG !== undefined
      ? getCO2andUnit(co2InG)
      : { co2: undefined, co2Unit: '' }

  return (
    <SummaryContainer>
      <SummaryHeader>
        <SummaryTitle>
          <SummaryTitleIcon>
            {title}
            {onEdit && (
              <div onClick={onEdit}>
                <EditIcon color={Colors.NEW_YETIC_DARK} />
              </div>
            )}
          </SummaryTitleIcon>
          <SummaryType>{type}</SummaryType>
        </SummaryTitle>
        {cta && (
          <CtaContainer>
            {cta.map((ctaItem, index) => {
              return (
                <Button
                  key={index}
                  type={ctaItem.btnType}
                  onClick={ctaItem.action}
                  style={{ width: 'fit-content' }}
                >
                  {ctaItem.label}
                </Button>
              )
            })}
          </CtaContainer>
        )}
        {co2InG !== undefined && co2InG !== null && (
          <CO2>
            <LeafIcon
              height="30px"
              color={Colors.YETIC_GREEN_DARK}
              style={{ transform: 'scaleX(-1)' }}
            />
            <CO2Info>
              <div>
                <CO2Number>{co2}</CO2Number>
                {co2Unit}
              </div>
              <div>CO₂</div>
              {onCO2TooltipClick && (
                <Tooltip onClick={onCO2TooltipClick}>i</Tooltip>
              )}
            </CO2Info>
          </CO2>
        )}
      </SummaryHeader>
      <SummaryContent>
        <Column1>{column1}</Column1>
        {column2 && <Column2>{column2}</Column2>}
      </SummaryContent>
    </SummaryContainer>
  )
}

export default Summary
