import React, { ChangeEvent, FormEvent, useState } from 'react'

import { StyledForm } from './styles'
import { VerificationEmailErrors } from '../..'
import Title from '../../../../../components/Title'
import Input from '../../../../../components/Input'
import Button from '../../../../../components/Button'
import DefaultText from '../../../../../components/DefaultText'

type PasswordFormProps = {
  password: string
  passwordConfirmation: string
  hasBeenSubmitted: boolean
  errors: VerificationEmailErrors
  errorFromApi: string
  handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void
  handlePasswordConfirmationChange: (
    event: ChangeEvent<HTMLInputElement>
  ) => void
  handleSubmitPassword: (event: FormEvent<HTMLFormElement>) => void
  handleStartCreation: () => void
}

export const PasswordForm = ({
  password,
  passwordConfirmation,
  errors,
  errorFromApi,
  handleSubmitPassword,
  hasBeenSubmitted,
  handlePasswordChange,
  handlePasswordConfirmationChange,
  handleStartCreation
}: PasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  return (
    <StyledForm
      onSubmit={(event) => {
        handleSubmitPassword(event)
      }}
    >
      <Title style={{ textAlign: 'center' }}>
        Création de votre mot de passe
      </Title>
      <Input
        type="password"
        label="Entrez votre mot de passe"
        placeholder="Example123@"
        containerWidth="30vw"
        value={password}
        onChange={handlePasswordChange}
        error={hasBeenSubmitted ? errors.password : ''}
        showPassword={showPassword}
        setShowPassword={handleTogglePassword}
      />
      <Input
        type="password"
        label="Confirmez votre mot de passe"
        placeholder="Example123@"
        containerWidth="30vw"
        value={passwordConfirmation}
        onChange={handlePasswordConfirmationChange}
        error={hasBeenSubmitted ? errors.passwordConfirmation : ''}
        showPassword={showConfirmPassword}
        setShowPassword={handleToggleConfirmPassword}
      />
      <Button width="200px" type="primary" onClick={handleStartCreation}>
        Valider le mot de passe
      </Button>
      <DefaultText size="small" style={{ color: 'red' }}>
        {errorFromApi}
      </DefaultText>
    </StyledForm>
  )
}
