import React from 'react'

import { ImageAdCreationFormDataType } from '../types'
import { ImageAdCreationErrorsType } from '../../../helpers/imageAd/formErrors'
import { PanelType } from '../../../types/templates/panels'
import Input from '../../../components/Input'
import { TextInputsContainer } from '../styles'

export type GetHeadlinesPanelInput = {
  formData: ImageAdCreationFormDataType
  handleInputChange: (
    attribute: keyof ImageAdCreationFormDataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  errors: ImageAdCreationErrorsType
  hasBeenSubmitted: boolean
}

export const getHeadlinesPanel = ({
  formData,
  handleInputChange,
  errors,
  hasBeenSubmitted
}: GetHeadlinesPanelInput): PanelType => {
  return {
    title: 'Titres',
    disabled: false,
    isDropdown: false,
    description: `Renseignez les titres qui s'afficheront sur votre annonce.`,
    sections: [
      {
        content: (
          <TextInputsContainer>
            <Input
              type="text"
              label={'Titre 1'}
              placeholder="Titre 1"
              maxLength={30}
              showCharacterCount
              value={formData.headline1}
              onChange={(e) => {
                handleInputChange('headline1', e)
              }}
              error={hasBeenSubmitted ? errors.headline1 : ''}
              withBorder
            />
            <Input
              type="text"
              label={'Titre 2'}
              placeholder="Titre 2"
              maxLength={30}
              showCharacterCount
              value={formData.headline2}
              onChange={(e) => {
                handleInputChange('headline2', e)
              }}
              isRequired={false}
              error={hasBeenSubmitted ? errors.headline2 : ''}
              withBorder
            />
            <Input
              type="text"
              label={'Titre 3'}
              placeholder="Titre 3"
              maxLength={30}
              showCharacterCount
              value={formData.headline3}
              onChange={(e) => {
                handleInputChange('headline3', e)
              }}
              isRequired={false}
              error={hasBeenSubmitted ? errors.headline3 : ''}
              withBorder
            />
          </TextInputsContainer>
        )
      }
    ]
  }
}
