export enum CreateAdErrorFromApi {
  'CAMPAIGN_ID' = `L'id de la campagne n'a pas été reçu`,
  'AUTH_FAILED' = `L'authentification a échoué`,
  'NOT_ALLOWED' = `Vous n'avez pas les droits requis pour créer cette annonce`,
  'MISSING_VALUE' = `Au moins un des paramètres de l'annonce n'a pas été reçu`,
  'MISSING_FILE' = `Au moins une des images de l'annonce n'a pas été reçue`,
  'CREATE' = `Une erreur est survenue lors de la création de l'annonce`,
  'UNKNOWN' = `Une erreur inconnue est survenue lors de la création de l'annonce`,
  'CAMPAIGN_NOT_FOUND' = `La campagne n'a pas été trouvée`,
  'EXISTING_NAME' = `Le nom de l'annonce est déjà utilisé`,
  'DB_ERROR' = `La base de données n'est pas disponible`,
  'WRONG_FORMAT' = `Le format de l'annonce n'est pas correct`,
  'INPUT_FORMAT' = `Le format d'au moins un des paramètres de l'annonce n'est pas correct`,
  'FILE_FORMAT' = `Le format d'au moins une des images de l'annonce n'est pas correct`,
  'IMAGE_SAVE' = `Au moins une des images de l'annonce n'a pas pu être sauvegardée`
}

export type CreateAdErrorFromApiKeys = keyof typeof CreateAdErrorFromApi

export const checkIfCreateAdErrorCodeValid = (
  errorCode: string
): errorCode is CreateAdErrorFromApiKeys => {
  return Object.keys(CreateAdErrorFromApi).includes(errorCode)
}
