export const convertObjectToFormData = <Data extends Record<string, any>>(
  data: Data
): FormData => {
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof File || value === null) {
      formData.append(key, value as File)
    } else {
      formData.append(key, String(value))
    }
  })

  return formData
}
