import React, { CSSProperties } from 'react'

import { Checkbox, CheckboxLabel, CheckboxList } from '../components/CheckBox'
import { InputError } from '../components/Input/Styles'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'

type CheckboxListProps = {
  values: {
    id: string
    label: string | JSX.Element
    description?: string
    selected: boolean
    tooltipView?: EmotionJSX.Element
  }[]
  onChange: (id: string) => void
  error?: string
  style?: CSSProperties
  withBorder?: boolean
  height?: string
}

export const CheckboxListTmp = ({
  values,
  onChange,
  error,
  style,
  withBorder,
  height
}: CheckboxListProps) => {
  const checkboxes = values.map(
    ({ id, label, description, selected, tooltipView }) => ({
      checkbox: (
        <Checkbox
          onChange={() => {
            onChange(id)
          }}
          selected={selected}
        >
          <CheckboxLabel label={label} description={description} />
          {tooltipView}
        </Checkbox>
      ),
      selected
    })
  )

  return (
    <>
      <CheckboxList
        values={checkboxes}
        style={style}
        withBorder={withBorder}
        height={height}
      />
      {error && <InputError style={{ marginTop: '20px' }}>{error}</InputError>}
    </>
  )
}
