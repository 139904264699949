import React, { useRef, useState } from 'react'

import {
  EmptyList,
  Item,
  ItemList,
  inputStyle,
  listItemStyle,
  modalStyle,
  subtitleStyle
} from './styles'
import Modal from '../../../components/Modal'
import PanelSubTitle from '../../../templates/components/Panel/PanelSubTitle'
import Input from '../../../components/Input'
import { InputError } from '../../../components/Input/Styles'
import DefaultText from '../../../components/DefaultText'
import AnimatedLoader from '../../../components/AnimatedLoader'
import { ActionsButtonIconContainer } from '../../../components/ActionsButton/styles'
import CrossClose from '../../../components/Icon/crossClose'

type CampaignKeyword = {
  value: string
  status: 'initial' | 'deleted' | 'new'
}

type ModalKeywordsType = {
  setIsOpen: (isOpen: boolean) => void
  campaignName: string
  keywords: CampaignKeyword[] | undefined
  onAddKeyword?: (keyword: string) => void
  onRemoveKeyword?: (keyword: string) => void
  isEditable?: boolean
}

const ModalKeywords = ({
  setIsOpen,
  campaignName,
  keywords,
  onAddKeyword,
  onRemoveKeyword,
  isEditable
}: ModalKeywordsType) => {
  const listRef = useRef<HTMLUListElement>(null)
  const [isKeywordAlreadyAdded, setIsKeywordAlreadyAdded] = useState(false)

  const handleAddKeyword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const keyword = event.currentTarget.value.trim()

      if (keyword && keyword.length > 0) {
        if (!keywords?.map((k) => k.value)?.includes(keyword)) {
          setIsKeywordAlreadyAdded(false)
          if (onAddKeyword) {
            onAddKeyword(keyword)
          }
          event.currentTarget.value = ''
        } else {
          setIsKeywordAlreadyAdded(true)
          setTimeout(() => {
            setIsKeywordAlreadyAdded(false)
          }, 3000)
        }
      }
    }
  }
  return (
    <Modal
      onClose={() => {
        setIsOpen(false)
      }}
      style={{ ...modalStyle, height: isEditable ? '60%' : 'auto' }}
    >
      <DefaultText size="medium" bold>
        Mots-clés pour la campagne « {campaignName} »
      </DefaultText>
      {keywords === undefined ? (
        <EmptyList>
          <AnimatedLoader />
        </EmptyList>
      ) : keywords.length > 0 ? (
        <ItemList ref={listRef} isEditable={isEditable}>
          {keywords &&
            keywords.map((keyword, index) => (
              <Item key={index} isLastItem={index === keywords.length - 1}>
                <DefaultText style={listItemStyle}>{keyword.value}</DefaultText>
                {isEditable && (
                  <ActionsButtonIconContainer
                    size={30}
                    onClick={() => {
                      if (onRemoveKeyword) {
                        onRemoveKeyword(keyword.value)
                      }
                    }}
                  >
                    <CrossClose width="20px" color="#868889" />
                  </ActionsButtonIconContainer>
                )}
              </Item>
            ))}
        </ItemList>
      ) : (
        <EmptyList>
          <DefaultText>Aucun mot-clé</DefaultText>
        </EmptyList>
      )}
      {isEditable && (
        <>
          <PanelSubTitle style={subtitleStyle}>
            Ajouter un mot-clé
          </PanelSubTitle>
          <Input
            type="text"
            style={inputStyle}
            placeholder="Ajouter un mot-clé en appuyant sur Entrée"
            onKeyDown={handleAddKeyword}
            withBorder
          />
          <InputError style={{ height: '2px' }}>
            {isKeywordAlreadyAdded && 'Ce mot-clé est déjà ajouté'}
          </InputError>
        </>
      )}
    </Modal>
  )
}

export default ModalKeywords
