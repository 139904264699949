import React from 'react'

import { ModalOverlay, ModalWrapper, CloseButton } from './styles'
import CrossClose from '../Icon/crossClose'
import { ModalType } from '../../types/modal'

const Modal = ({ onClose, style, overlayStyle, children }: ModalType) => {
  return (
    <>
      <ModalOverlay onClick={onClose} style={overlayStyle} />
      <ModalWrapper style={style}>
        {onClose && (
          <CloseButton onClick={onClose}>
            <CrossClose />
          </CloseButton>
        )}
        {children}
      </ModalWrapper>
    </>
  )
}

export default Modal
