import { AudienceDataType } from '..'
import { AudienceCreationErrors } from '../helpers/errors'
import getNamePanel from './getNamePanel'
import getGoogleParamsPanel from './getGoogleParamsPanel'
import { LocationData } from '../../../../types/mediaPlanAudience'
import getLinkedinParamsPanel from './getLinkedinParamsPanel'
import getLocationPanel from './getLocationPanel'

type GetAllPanelsParams = {
  locations: LocationData[]
  /*   interests: string[] */
  businessSegments: string[]
  jobFunctions: string[]
  jobTitles: string[]
  audienceData: AudienceDataType
  handleChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleMinAgeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleMaxAgeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /*   handleAddInterests: (interest: string) => void
  handleRemoveInterests: (index: number) => void */
  handleNewLocationTag: (location: LocationData) => void
  handleRemoveLocationTags: (index: number) => void
  handleChangeGender: (index: number) => void
  handleNewBusinessSegment: (segment: string) => void
  handleRemoveBusinessSegment: (index: number) => void
  handleNewJobFunction: (jobFunction: string) => void
  handleRemoveJobFunction: (index: number) => void
  handleNewJobTitle: (jobTitle: string) => void
  handleRemoveJobTitle: (index: number) => void
  hasBeenSubmitted: boolean
  errors: AudienceCreationErrors
  isGoogleChecked: boolean
  setIsGoogleChecked: (value: boolean) => void
  isLinkedinChecked: boolean
  setIsLinkedinChecked: (value: boolean) => void
}

const getAllPanels = ({
  locations,
  /*   interests, */
  businessSegments,
  jobFunctions,
  jobTitles,
  audienceData,
  handleChangeName,
  handleMinAgeChange,
  handleMaxAgeChange,
  /*   handleAddInterests,
  handleRemoveInterests, */
  handleNewLocationTag,
  handleRemoveLocationTags,
  handleChangeGender,
  handleNewBusinessSegment,
  handleRemoveBusinessSegment,
  handleNewJobFunction,
  handleRemoveJobFunction,
  handleNewJobTitle,
  handleRemoveJobTitle,
  hasBeenSubmitted,
  errors,
  isGoogleChecked,
  setIsGoogleChecked,
  isLinkedinChecked,
  setIsLinkedinChecked
}: GetAllPanelsParams) => {
  const namePanel = getNamePanel({
    audienceData,
    handleChangeName,
    hasBeenSubmitted,
    error: errors.name
  })

  const locationPanel = getLocationPanel({
    locations,
    audienceData,
    handleNewLocationTag,
    handleRemoveLocationTags,
    hasBeenSubmitted,
    error: errors.locations
  })

  const googleParamsPanel = getGoogleParamsPanel({
    /*     interests, */
    audienceData,
    handleMinAgeChange,
    handleMaxAgeChange,
    /*  handleAddInterests,
    handleRemoveInterests, */
    handleChangeGender,
    hasBeenSubmitted,
    errors,
    isChecked: isGoogleChecked,
    setIsChecked: setIsGoogleChecked
  })

  const panels = [namePanel, locationPanel, googleParamsPanel]

  if (process.env.REACT_APP_DISABLE_LINKEDIN !== '1') {
    const linkedinParamsPanel = getLinkedinParamsPanel({
      businessSegments,
      jobFunctions,
      jobTitles,
      audienceData,
      handleNewBusinessSegment,
      handleRemoveBusinessSegment,
      handleNewJobFunction,
      handleRemoveJobFunction,
      handleNewJobTitle,
      handleRemoveJobTitle,
      hasBeenSubmitted,
      errors,
      isChecked: isLinkedinChecked,
      setIsChecked: setIsLinkedinChecked
    })

    panels.push(linkedinParamsPanel)
  }

  return panels
}

export default getAllPanels
