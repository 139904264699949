import React from 'react'

import { AvatarContainer, TeamContainer, TeamMembers } from './styles'
import { Label } from '../LabelledItem/styles'
import Avatar from '../Avatar'
import Colors from '../../constants/colors'
import { UserType } from '../../types/user'

type TeamProps = {
  users: UserType[]
}

const Team = ({ users }: TeamProps) => {
  const colors = [Colors.YETIC_BLUE_DARK, '#016d6d', '#7400a7']
  const initials = users
    .map((user) => user.firstName[0] + user.lastName[0])
    .slice(0, 3)

  return (
    <TeamContainer>
      <Label>Équipe :</Label>
      <TeamMembers>
        {initials.map((initial, index) => (
          <AvatarContainer key={index}>
            <Avatar
              character={initial}
              backgroundColor={colors[index]}
            ></Avatar>
          </AvatarContainer>
        ))}
      </TeamMembers>
    </TeamContainer>
  )
}

export default Team
